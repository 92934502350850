import React, { useState, useEffect } from "react"
import { APIURL } from '../../config'
import axios from 'axios'
import './MessagePage.css'


/* Props Overview

'image' OR 'icon': fontawesome class name or image source
'title': title text to display
'text': text to display
'backgroundColor' (optional): light

*/
export default function MessagePage(props) {

    useEffect(() => {
        
    }, []);

    return (
        <div className={'messagePage-main' + " " + props.backgroundColor}> 
            <div className='messagePage-content'>
                {!!props.image && <img className="messagePage-image" src={props.image} alt='Image'/>}
                {!!props.icon && <i className={props.icon}></i>}
                <h5>{props.title}</h5>
                <p>{props.text}</p>
            </div>
        </div>
    );
}