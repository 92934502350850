import React, { useState, useEffect } from "react";
import { 
	DataGridPro,
	useGridSelector,
	gridFilteredDescendantCountLookupSelector,
	useGridApiContext,
	GridActionsCellItem
} from "@mui/x-data-grid-pro";
import { 
	Box,
	IconButton,
	Link
} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import moment from "moment";
import DocumentViewerModal from "./Modals/DocumentViewerModal";

const DocumentsTable = (props) => {
	const [open, setOpen] = useState(false);
	const [fileUrl, setFileUrl] = useState("");
	const [fileExt, setFileExt] = useState("");

	const onAction = (action, row) => {
		props.handleModalOpen(action, row);

	};

	useEffect(() => {
		console.log(props.expansionDepth);
	}, [props.expansionDepth])

	const isDeletable = (row) => {
		if (row.file_name === null) {
			if(props.rows.filter((r) => r.file_path.slice(0, -1).join('/') === row.file_path.join('/') && r.id !== row.id).length > 0) {
				return false;
			}
			return true;
		}
		return true;
	}

	const getActions = (params) => {
		let actions = [
			<Link href={params.row.file_url}><GridActionsCellItem icon={<DownloadIcon />} label="Download" sx={{ visibility: params.row.file_name === null ? "hidden" : "visible" }}/></Link>,
			<GridActionsCellItem icon={<InfoIcon />} label="Info" onClick={() => props.handlePreviewOpen(params.row)} />,
		]


		if (params.row.locked !== true && params.row.created_by) {
			if((params.row.file_path[0] === "Public")) {
				if(props.user.type === "asd" || params.row.created_by === props.user.emp_id)
					actions.push(<GridActionsCellItem icon={<DriveFileMoveIcon />} label="Move" onClick={() => onAction("move", params.row)} showInMenu/>)
			} else {
				actions.push(<GridActionsCellItem icon={<DriveFileMoveIcon />} label="Move" onClick={() => onAction("move", params.row)} showInMenu/>)
			}
		}

		if (isDeletable(params.row) && params.row.locked !== true && params.row.created_by) {
			if((params.row.file_path[0] === "Public")) {
				if(props.user.type === "Admin" || params.row.created_by === props.user.emp_id)
					actions.push(<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => onAction("delete", params.row)} showInMenu/>)
			} else {
				actions.push(<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => onAction("delete", params.row)} showInMenu/>)
			}
		}

		return actions;
	}
      
    const columns = [
		{
			field: 'id'
		},
		{
			field: 'file_name',
			valueGetter: (params) => params.row.file_name === null ? null : params.row.file_name + "." + params.row.file_ext,
		},
		{ 	
			field: 'client', 
			headerName: 'Client', 
			width: 200, 
			renderHeader: () => <strong>Client</strong>,
			renderCell: (params) => <span style={{ color: "gray" }}>{params.row.client}</span>
		},
		{	
			field: 'owner',
			headerName: 'Owner',
			width: 200,
			renderHeader: () => <strong>Owner</strong>,
			renderCell: (params) => <span style={{ color: "gray" }}>{params.row.owner}</span>
		},
		{
			field: 'time',
			headerName: 'Time',
			width: 100,
			type: 'date',
			renderHeader: () => <strong>Time</strong>,
			renderCell: (params) => <span style={{ color: "gray" }}>{params.row.upload_date ? moment(params.row.upload_date).format("MMM D, YYYY") : null}</span>,
			valueGetter: (params) => params.row.upload_date ? moment(params.row.upload_date).format("MMM D, YYYY") : null
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: 'Actions',
			width: 120,
			renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>),
			getActions: (params) => getActions(params),
		}
    ];

    const getTreeDataPath = (row) => row.file_path;

	const CustomGridTreeDataGroupingCell = (params) => {
		const { id, field, rowNode } = params;
		const apiRef = useGridApiContext();
		const filteredDescendantCountLookup = useGridSelector(
			apiRef,
			gridFilteredDescendantCountLookupSelector,
		);
		const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;
		const isNavigationKey = (key) =>
			key === 'Home' ||
			key === 'End' ||
			key.indexOf('Arrow') === 0 ||
			key.indexOf('Page') === 0 ||
			key === ' ';

		const handleKeyDown = (event) => {
			if (event.key === ' ') {
				  event.stopPropagation();
			}
			if (isNavigationKey(event.key) && !event.shiftKey) {
				  apiRef.current.publishEvent('cellNavigationKeyDown', props, event);
			}
		};
		
		const handleClick = (event) => {
			apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
			apiRef.current.setCellFocus(id, field);
			event.stopPropagation();
		};
		return (
			<Box sx={{ ml: rowNode.depth * 3 }}>
				{params.row.file_name === null ?
					<Box>
						<IconButton
							size="small"
							sx={{ p: 0.25 }}
							onClick={handleClick}
							onKeyDown={handleKeyDown}
							tabIndex={-1}
						>
							{rowNode.childrenExpanded ? (
								<KeyboardArrowDown fontSize="small" />
							) : (
								<KeyboardArrowRight fontSize="small" />
							)}
						</IconButton>
						<FolderIcon sx={{ color: "#007bff", marginRight: 1 }} />
						<span>{params.row.directory_name + " (" + filteredDescendantCount + ")"}</span>
					</Box> :
					<Box sx={{ cursor: "pointer" }} onClick={() => {setOpen(true); setFileUrl(params.row.file_url); setFileExt(params.row.file_ext)}}>
						<InsertDriveFileOutlinedIcon sx={{ marginLeft: 3 , marginRight: 1 }}/>
						<span>{params.row.file_name + "." + params.row.file_ext}</span>
					</Box>
				}
			</Box>
		)
	}  

    const groupingColDef = {
        headerName: 'Name',
		width: 520,
        renderHeader: (params) => (<strong>{params.colDef.headerName}</strong>),
		renderCell : (params) => CustomGridTreeDataGroupingCell(params),
    };

    return (
        <Box sx={{ height: "calc(100vh - 175px)", width: '100%' }}>
            <DataGridPro
                treeData
                rows={props.rows}
                columns={columns}
                getTreeDataPath={getTreeDataPath}
                groupingColDef={groupingColDef}
				rowHeight={40}
				hideFooter
				filterModel={props.filterModel}
				columnVisibilityModel={{
					"id": false,
					"file_name": false
				}}
				apiRef={props.apiRef}
            />
			<DocumentViewerModal
				open={open}
				handleClose={() => setOpen(false)}
				fileUrl={fileUrl}
				fileExt={fileExt}
			/>	
        </Box>
    );
}

export default DocumentsTable;