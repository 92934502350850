import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Typography from '@mui/material/Typography';

const BulkDownload = (props) => {

    return (
        <Modal show={props.show} onHide={props.onClose} centered>
            <Modal.Body style={{ margin: "20px 0" }}>
                <Typography>Do you want to download all approved MTs for this month, or since the last bulk download?</Typography>
            </Modal.Body>
            <Modal.Footer style={{borderTop: "0 none", paddingTop: '0', justifyContent: "space-around"}}>
            <Button style={{width: 100}} variant="primary" onClick={() => {props.saveFiles(false);props.onClose()}}>All</Button>
            <Button style={{width: 100}} variant="primary" onClick={() => {props.saveFiles(true);props.onClose()}}>Since Last</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default BulkDownload