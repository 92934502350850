import makeStyles from '@mui/styles/makeStyles';
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import axios from "axios";
import React, { useState } from "react";
import { APIURL } from '../../config';
import ReaderCSV from "./ReaderCSV";
import "./styles/document-card.css";
import "./styles/documents.css";
import UploadModal from './UploadModal';
import { useTheme } from '@mui/material/styles';
import { PaymentTwoTone } from '@mui/icons-material';
import { palette } from '@mui/system';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: useTheme().spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));

const ClaimCSV = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const classes = useStyles();
  const [uploadFile, setUploadFile] = useState(null);
  const [success, setSuccess] = useState(false);
  const [snackBarType, setSnackBarType] = useState("success");
  const [snackBarText, setSnackBarText] = useState("File successfully uploaded!");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  const handleModalOpen = () => {
    setModalIsOpen(true);
  };

  const clearUploadFile = () => setUploadFile(null);

  const handleModalClose = () => {
    setModalIsOpen(false);
  };

  const onFileChange = (event) => {
    setUploadFile(event)
    // setUploadFile(event.target.files[0]);
  };

  const onFileUpload = async () => {
    if (uploadFile !== null) {
      const accessToken = window.localStorage.getItem('Fw4_access_Token');
      const payload = { data: uploadFile, accessToken }
      try {

        let result = await axios.post(APIURL + "/claimCSV", payload)
        setSuccess(true);
        setSnackBarType("success");
        result.data.foundError ? setSnackBarText(result.data.message) : setSnackBarText("File successfully uploaded!");
        setTimeout(() => {
          setSuccess(false);
          props.toggleCSVAdd();
        }, 2500)
        return result.data

      }
      catch (error) {
        console.log("errrr", error.data);
        setSuccess(true);
        setSnackBarType("error");
        setSnackBarText("Something went wrong! Please try again.");
        setTimeout(() => {
          setSuccess(false);
        }, 1000)
      }
    } else {
      setSnackBarType("error");
      setSuccess(true);
      setSnackBarText("Please select a file to upload!");
      setTimeout(() => {
        setSuccess(false);
      }, 1000)
    }


    // setDocuments((documents) => [
    //   ...documents,
    //   {
    //     title: "October Pre-Auth",
    //     uploadDate: "11/02/2021",
    //   },
    // ]);
  };

  return (
    <>
      <div className="documents-container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={success}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert severity={snackBarType}> {snackBarText} </Alert>
        </Snackbar>
        <UploadModal modalIsOpen={modalIsOpen} uploadFile={uploadFile} handleModalClose={handleModalClose} onFileUpload={onFileUpload} />
        <div className="file-component">
          <ReaderCSV toggleCSVAdd={props.toggleCSVAdd} clearUploadFile={clearUploadFile} onFileChange={onFileChange} onFileUpload={onFileUpload} />
        </div>
        {/* <CsvCard documents={documents} /> */}
      </div>
    </>
  );
};

export default ClaimCSV;
