import { useState, useEffect, useCallback, memo } from "react";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";
import lodash from "lodash";

const FollowUpItemRadioGroup = (props) => {
    const [value, setValue] = useState(null);
    const [error, setError] = useState(false);
    const [read, setRead] = useState(true);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        setError(props.error);
    }, [props.error]);
    
    useEffect(() => {
        setRead(props.read);
    }, [props.read]);

    useEffect(() => {
        if(read !== true) {
            if(props.disabled === true) {
                setValue("");
                if(props.onChange !== undefined) props.onChange({ id: props.item.id, question: "b", value: "" });
                if(isValid(value)) setError(false);
            } else {
                if(!isValid(props.value)) {
                    if(props.dependentValue)
                        props.onChange(props.dependentValue);
                }
                setError(true);
            }
        }
    }, [props.disabled]);

    useEffect(() => {
        if(props.setSectionError !== undefined && props.section !== undefined) 
            error ? props.setSectionError((prev) => { 
                let newPrev = [...prev];
                newPrev[props.section].push('followupradio');
                return newPrev;
            }) : 
            props.setSectionError((prev) => {
                let newPrev = [...prev];
                const index = newPrev[props.section].indexOf('followupradio');
                if (index > -1) {
                    newPrev[props.section].splice(index, 1);
                }
                return newPrev;
            });
    }, [error]);

    useEffect(() => {
        if(!props.disabled) 
            setError(!isValid(value));
    }, [value, props.validate]);

    const isValid = (value) => {
        return !(value === null || value === "");
    }

    return (
        <RadioGroup
            row
            value={value}
            onChange={useCallback((e) => props.onChange({ id: props.item.id, question: "b", value: e.target.value }))}
        >
            {props.options.map((option, index) => {
                return (
                    <FormControlLabel
                        key={index}
                        value={option.value}
                        control={
                            <Radio
                                size="small"
                                inputProps={{ ...props.inputProps }}
                                disabled={props.disabled}
                                sx={{ color: error && props.validate ? "#d32f2f" : "#616161" }}
                            />
                        }
                        label={option.label}
                        sx={{ ...props.sx }}
                    />
                );
            })}
        </RadioGroup>
    );
}

export default memo(FollowUpItemRadioGroup,
    (prevProps, nextProps) => {
        if(lodash.isEqual(prevProps.sx, nextProps.sx) && lodash.isEqual(prevProps.inputProps, nextProps.inputProps) && lodash.isEqual(prevProps.value, nextProps.value) && lodash.isEqual(prevProps.disabled, nextProps.disabled) 
        && prevProps.onChange == nextProps.onChange && lodash.isEqual(prevProps.options, nextProps.options) && lodash.isEqual(prevProps.indexes, nextProps.indexes)
        && lodash.isEqual(prevProps.error, nextProps.error) && lodash.isEqual(prevProps.validate, nextProps.validate) && lodash.isEqual(prevProps.validQuestions, nextProps.validQuestions)) {
            return true;
        } else {
            return false;
        }
    }
);
