import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { APIURL } from '../../../../config';

const HealthNeedForm = (props) => {
    const [healthNeeds, setHealthNeeds] = useState({});

    // Style for the modal content
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        setHealthNeeds(props.healthNeeds || {
            allergies: '',
            dietary: '',
            health_hazards: '',
            self_care: ''
        });
    }, [props.healthNeeds]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let data = {
                allergies: healthNeeds.allergies,
                dietary: healthNeeds.dietary,
                health_hazards: healthNeeds.health_hazards,
                self_care: healthNeeds.self_care,
                customer_id: props.customer_id
            };
            console.log(data);
            if (props.healthNeeds) {
                await axios.put(APIURL + '/customer_health_need/' + props.customer_id, data);
            } else {
                await axios.post(APIURL + '/customer_health_need', data);
            }
            props.setHealthNeeds(data);
            props.handleClose();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="health-form-title"
            aria-describedby="health-form-description"
        >
            <Box sx={style} component="form" onSubmit={handleSubmit}>
                <Typography id="health-form-title" variant="h6" component="h2">
                    Health Needs
                </Typography>
                <TextField
                    fullWidth
                    label="Allergies"
                    margin="normal"
                    name="allergies"
                    variant="outlined"
                    rows={4}
                    multiline
                    value={healthNeeds?.allergies || ''}
                    onChange={(e) => setHealthNeeds({ ...healthNeeds, allergies: e.target.value || '' })}
                />
                <TextField
                    fullWidth
                    label="Dietary"
                    margin="normal"
                    name="dietary"
                    variant="outlined"
                    rows={4}
                    multiline
                    value={healthNeeds?.dietary || ''}
                    onChange={(e) => setHealthNeeds({ ...healthNeeds, dietary: e.target.value || '' })}
                />
                <TextField
                    fullWidth
                    label="Health Hazards"
                    margin="normal"
                    name="health_hazards"
                    variant="outlined"
                    rows={4}
                    multiline
                    value={healthNeeds?.health_hazards || ''}
                    onChange={(e) => setHealthNeeds({ ...healthNeeds, health_hazards: e.target.value || '' })}
                />
                <TextField
                    fullWidth
                    label="Self Care"
                    margin="normal"
                    name="self_care"
                    variant="outlined"
                    rows={4}
                    multiline
                    value={healthNeeds?.self_care || ''}
                    onChange={(e) => setHealthNeeds({ ...healthNeeds, self_care: e.target.value || '' })}
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Save
                </Button>
            </Box>
        </Modal>
    );
}

export default HealthNeedForm;
