import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef, gridClasses } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function ManagementReviewReport(props) {
  const [gridId, setGridId] = useState(40);
  const apiRef = useGridApiRef();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [reportDetails, setReportDetails] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    axios
      .get(APIURL + "/jsreport/getAllReports")
      .then((response) => {
        const report = response?.data?.reports.find((ele) => ele.report_name === "Management Review Report");
        setReportDetails(report);
        setIsFavorite(report ? (report.status === 1 ? true : false) : false);
      });

    axios
      .get(APIURL + "/jsreport/getMTManagmentReviewReports", {
        params: { company_id: props.user.comp_id, emp_id: props.user.emp_id },
      })
      .then((response) => {
        setData(response?.data?.managementReviewReportResults
          ? response?.data?.managementReviewReportResults
          : []);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const columns = [
    {
      field: "cus_id",
      headerName: "Customer ID",
      width: 100,
    },
    {
      field: "cus_name",
      headerName: "Customer Name",
      width: 180,
    },
    {
      field: "support_coordinator",
      headerName: "Support Coordinator",
      width: 180,
    },
    {
      field: "DDDID",
      headerName: "DDD ID",
      width: 100,
    },
    {
      field: "visit_type",
      headerName: "Visit Type",
      width: 100,
    },
    { field: "tas_description", headerName: "Follow up items", flex: 1 },
  ]

  let rows = [];

  data?.map((cus, index) => {
    let temp_Obj1 = {
      cus_id: cus.cus_id,
      cus_name: cus.cus_name,
      DDDID: cus?.DDDID,
      support_coordinator: cus.support_coordinator,
      visit_type: cus?.visit_type,
      hierarchy: [cus?.cus_id + " " + cus?.tas_mm_id],
      rowId:
        cus?.cus_id +
        index +
        Math.random().toString(36).substring(2, 12) +
        index,
    };
    if (cus?.follow_up_items?.length > 0) {
      temp_Obj1.tas_description = "";
      cus?.follow_up_items?.map((tas, ind) => {
        temp_Obj1.tas_description +=
          ind + 1 + ". " + tas?.tas_description + "\n";
      });
    } else {
      temp_Obj1.tas_description = "-";
    }
    rows.push(temp_Obj1);
  });

  const markFavourite = async (data, type) => {
    const payload = {
      report_id: data?.report_id,
      status: type,
    };

    axios
      .put(APIURL + "/jsreport/markFavorite", payload)
      .then((response) => {
        if (response?.status === 200 && response?.data?.code === 200) {
          setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
        } else {
          setErrorText("Something went wrong");
        }

        fetchData();
      });
  }

  const onPageChange = (value) => {
    setPageNo(value);
  };

  const onPageSizeChange = (value) => {
    setPageSize(value);
    setPageNo(0);
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ margin: 10, width: '75vw' }}>
        {rows.length === 0 ?
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "500px", marginTop: 10 }}
          >
            <img
              src="./nosearchresult.png"
              alt="nosearchresult"
              style={{ width: "25rem", height: "15rem", margin: 10 }}
            />
            <h5
              style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
              className="text-center"
            >
              There is currently no data available{" "}
            </h5>
          </div>
          :
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div />
              <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                <img
                  src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                  alt="barchart"
                  style={{
                    fontFamily: "Lato",
                    width: "1.5rem",
                    height: "1.5rem",
                    cursor: "pointer",
                    margin: 5
                  }}
                  onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                />
              </Tip>
            </div>
            <div style={{ height: '80vh' }}>
              <ControlledDataGrid
                gridId={gridId}
                user={props.user}
                initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
                apiRef={apiRef}
                className="table123"
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                disableSelectionOnClick
                // autoHeight
                pagination
                rowsPerPageOptions={[20, 50, 100]}
                onPageSizeChange={onPageSizeChange}
                page={pageNo}
                onPageChange={onPageChange}
                getRowId={(row) => row.rowId}
                getRowHeight={() => "auto"}
                sx={{
                  [`& .${gridClasses.cell}`]: {
                    py: 1,
                    whiteSpace: "pre-line",
                  },
                }}
              />
            </div>
          </div>
        }

        <Snackbar
          open={successText !== ""}
          autoHideDuration={30000}
          onClose={() => setSuccessText("")}
        >
          <Alert
            sx={{ width: "100%" }}
            severity={"success"}
            elevation={6}
            variant="filled"
          >
            {successText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => setSuccessText("")}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorText !== ""}
          autoHideDuration={30000}
          onClose={() => setErrorText("")}
        >
          <Alert
            sx={{ width: "100%" }}
            severity={"error"}
            elevation={6}
            variant="filled"
          >
            {errorText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => setErrorText("")}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
      </div>
    );
  }
}