import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import withStyles from "@mui/styles/withStyles";
import { APIURL } from "../../../config";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import "./UploadForm.css";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#3FA7D6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3FA7D6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "lightblue",
      },
      "&:hover fieldset": {
        borderColor: "#3FA7D6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3FA7D6",
      },
    },
  },
})(TextField);

export default function UploadForm(props) {
  const [file, setFile] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [duplicates, setDuplicates] = useState(0);
  const [missingData, setMissingData] = useState([]);
  const [errorAlert, setErrorAlert] = useState("");
  const handleErrorAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorAlert("");
  };

  const [successAlert, setSuccessAlert] = useState("");
  const handleSuccessAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessAlert("");
  };

  const onFileUpload = async () => {
    setLoading(true)
    if (file !== null) {
      const accessToken = window.localStorage.getItem("Fw4_access_Token");
      const Papa = require("papaparse");
      Papa.parse(file, {
        error: function (error, file) {
          console.log(error)
          setErrorAlert("Something went wrong! Please try again.");
          setLoading(false)
        },
        complete: async function (results, file) {
          let missingDataInRow = 0;
          let missingEntries = [];

          // CSV data cleaning
          let authArray = results.data.filter((row) => {
            const authItem = row
            const isEndDateValid = moment(authItem[6], 'MM/DD/YYYY').isValid()
            const isStartDateValid = moment(authItem[5], 'MM/DD/YYYY').isValid()
            let missingValuePresent = false

            for (let i = 0; i < 9; i++) {
              let val = authItem[i]
              if (!val || val.length === 0 || !isEndDateValid || !isStartDateValid) {
                missingValuePresent = true;
                missingEntries.push(authItem);
                break;
              }
            }

            if (missingValuePresent) {
              missingDataInRow += 1;
              return false;
            }
            else {
              return true;
            }
          });

          // Removing Duplicates from the CSV (OPTIMIZED - Keep first instance)
          // const uniqueData = new Set(authArray.map(ele => ele.slice(0, 6).toString().toUpperCase()));
          // authArray = authArray.filter(ele => {
          //   const uniqueIdentifier = ele.slice(0, 6).toString().toUpperCase();
          //   return uniqueData.has(uniqueIdentifier) && uniqueData.delete(uniqueIdentifier);
          // });

          // Removing Duplicates from the CSV (UNOPTIMIZED)
          // let uniqueData = authArray.map(ele => ele.slice(0, 6).toString().toUpperCase());
          // uniqueData = uniqueData.filter((ele, index) => {
          //   let lastIndex = uniqueData.length - 1 - uniqueData.slice().reverse().findIndex((e) => e === ele);
          //   return index === lastIndex;
          // });

          // authArray = authArray.filter(ele => {
          //   let indexFound = uniqueData.findIndex((e) => e === ele.slice(0, 6).toString().toUpperCase());
          //   if (indexFound !== -1) {
          //     uniqueData[indexFound] = "";
          //     return true;
          //   } else {
          //     return false;
          //   }
          // });

          // Removing Duplicates from the CSV (OPTIMIZED - Keep last instance)
          const uniqueDataMap = new Map();

          authArray.forEach((ele, index) => {
            const key = ele.slice(0, 6).toString().toUpperCase();
            uniqueDataMap.set(key, index);
          });

          const uniqueDataIndices = Array.from(uniqueDataMap.values());
          const uniqueData = authArray.filter((_, index) => uniqueDataIndices.includes(index));

          authArray = uniqueData;

          const dates = authArray.map(entry => ({
            startDate: moment(entry[5], 'MM/DD/YYYY'),
            endDate: moment(entry[6], 'MM/DD/YYYY')
          }));

          const minStartDate = moment.min(dates.map(date => date.startDate)).format('MM/DD/YYYY');
          const maxEndDate = moment.max(dates.map(date => date.endDate)).format('MM/DD/YYYY');

          const payload = {
            data: authArray,
            empCompanyId: props.user.comp_id,
            empId: props.user.emp_id,
            companyName: props.user.company_name,
            empName: props.user.emp_name,
            minStartDate: minStartDate,
            maxEndDate: maxEndDate
          };
          try {
            let result = await axios.post(APIURL + "/preAuth", payload);
            setSuccessAlert("File successfully uploaded!");
            setLoading(false);
            setMissingData(missingEntries);
            setDuplicates(results.data.length - authArray.length - missingDataInRow);
            setOpen(true);
            missingDataInRow > 0 ? console.log('Showing Missing Data') :
              setTimeout(() => {
                props.onSubmit();
              }, 5000);
          } catch (error) {
            console.log("Error: ", error);
            setLoading(false);
            setErrorAlert("Something went wrong! Please try again.");
            setTimeout(() => {
            }, 5000);
          }
        },
      });
    } else {
      setLoading(false)
      setErrorAlert("Please select a file to upload!");
      setTimeout(() => {
      }, 2000);
    }
  };

  let rows = missingData.map((data, index) => {
    let missingData = {
      id: index,
      DDDID: data[0],
      MemberMedicaid: data[1],
      ProviderMedicaid: data[2],
      PriorAuthorization: data[3],
      ProcedureCode: data[4],
      StartDate: data[5],
      EndDate: data[6],
      AuthorizedUnits: data[7],
      AuthorizedAmount: data[8],
      ErrorCode: data[9],
      ErrorDescription: data[10],
    };
    return missingData;
  });

  const columns = [
    { field: 'DDDID', headerName: 'DDDID', flex: 1 },
    { field: 'MemberMedicaid', headerName: 'Member Medicaid', flex: 1 },
    { field: 'ProviderMedicaid', headerName: 'Provider Medicaid', flex: 1 },
    { field: 'PriorAuthorization', headerName: 'Prior Authorization', flex: 1 },
    { field: 'ProcedureCode', headerName: 'Procedure Code', flex: 1 },
    { field: 'StartDate', headerName: 'Start Date', flex: 1 },
    { field: 'EndDate', headerName: 'End Date', flex: 1 },
    { field: 'AuthorizedUnits', headerName: 'Authorized Units', flex: 1 },
    { field: 'AuthorizedAmount', headerName: 'Authorized Amount', flex: 1 },
    { field: 'ErrorCode', headerName: 'Error Code', flex: 1 },
    { field: 'ErrorDescription', headerName: 'Error Description', flex: 1 },
  ];

  return (
    <div className="noteForm-mainDiv">
      <h4>UPLOAD PRE-AUTH CSV</h4>
      <br></br>
      <div>
        <Dropzone
          onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
          accept={{
            "text/csv": [".csv"],
            // "application/vnd.ms-excel": [".xls"],
            // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            //   [".xlsx"],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <Box className="upload-area" {...getRootProps()} display="flex">
              <Typography variant="subtitle1">
                {file ? file.name : "Drop file here or click to upload"}
              </Typography>
              <Typography variant="subtitle3">
                {file ? file.name : "(file types: .csv)"}
              </Typography>
              <input type="file" {...getInputProps()} />
            </Box>
          )}
        </Dropzone>
        <Button
          style={{
            width: "100%",
            padding: "5px",
            margin: "5px",
          }}
          onClick={onFileUpload}
        >
          Upload File
        </Button>
        {!!Loading && (
          <div class="overlay">
            <div class="overlay__inner">
              <div class="overlay__content">
                <span class="spinner"></span>
              </div>
              <div class="overlay__content__text">
                Please be patient while the file is being uploaded. It may take a few minutes for the upload to complete, depending on the size of the file and the speed of your internet connection.
              </div>
            </div>
          </div>
        )}
        {open &&
          <div style={{ width: '1000px' }}>
            <Dialog maxWidth="lg" fullWidth open={open} onClose={() => { setOpen(false); props.onSubmit(); }}>
              <DialogActions>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  sx={{ p: 0.25 }}
                  onClick={() => { setOpen(false); props.onSubmit(); }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogActions>
              <DialogContent>
                <Typography variant="h6" gutterBottom>
                  {duplicates} Duplicate records in uploaded file and have not been added!
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Following {missingData.length} Records in uploaded file have corrupt data and have not been added:
                </Typography>
                {/* <div>
                  <table border="1">
                    <tbody>
                      {missingData.map((row, rowIndex) => (
                        <tr key={rowIndex} style={{ height: '30px' }}>
                          {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
                <div style={{ height: 400, width: '100%' }}>
                  <DataGridPro
                    rows={rows}
                    columns={columns}
                    checkboxSelection={false}
                    disableSelectionOnClick
                    components={{
                      Toolbar: GridToolbar,
                    }}
                  />
                </div>
              </DialogContent>
            </Dialog>
          </div>
        }
      </div>
      <Snackbar
        open={successAlert != ""}
        autoHideDuration={5000}
        onClose={handleSuccessAlertClose}
      >
        <MuiAlert
          sx={{ width: "100%" }}
          severity={"success"}
          elevation={6}
          variant="filled"
          {...props}
        >
          {successAlert}
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.25 }}
            onClick={handleSuccessAlertClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={errorAlert != ""}
        autoHideDuration={5000}
        onClose={handleErrorAlertClose}
      >
        <MuiAlert
          sx={{ width: "100%" }}
          severity={"error"}
          elevation={6}
          variant="filled"
          {...props}
        >
          {errorAlert}
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.25 }}
            onClick={handleErrorAlertClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
