import React, { useState, useEffect } from 'react';
import { Switch, Route, NavLink } from "react-router-dom";
import '../NavBar/NavBar.css';
import axios from 'axios'
import { env, APIURL, CLIENT_ID, MSTENANT, SCOPE  } from '../../config'
import SideBar from '../MenuSideBar/SideBar';
// import MapsBar from '../MapsBar/MapsBar';
import Projects from '../Projects/Updated_Projects'
// import Customers from '../Customers/Customer'
import Customers from '../Customers_V2/Customers'
import Billing from '../../components/Billing/Billing'
import PayrollContainer from '../PayRollSettings/PayrollContainer'
import EmpLocate from '../../components/EmpLocate/EmpLocate'
import EmpSideBar from '../Employee/EmpSideBar/EmpSideBar'
import Contacts from '../../components/Contacts/Contactsv1'
// import Home from '../../components/Home/HomeBox'
import EmployeeHome from '../Employee/EmployeeHome/EmployeeHome'
import EmpAnalytics from '../../components/EmpAnalytics/EmpAnalytics'
import Reports from '../../components/Reports/Reports'
import Reports_V2 from '../../components/Reports_V2/Reports';
import Settings from '../../components/Settings/NewSettings'
//import TimeSummary from '../../container/TimeManagement/TimeSummary'
import TimeSummaryAlt from '../../container/TimeManagement/TimeSummaryAlt'
import TimeSummaryAlt2 from '../../container/TimeManagement/TimeSummaryAlt2'

import Scheduler from '../../container/Scheduler/Scheduler'

// import Onboarding from '../../components/forms/Onboarding/Onboarding'
import EvvV1 from '../../components/Evv/EvvV1'
import EVV_AZ from '../../components/EVV_AZ/EVV_Arizona'
import Notifications from "../../components/Notifications/notifications";
import FormBuilder from '../../container/Forms/FormBuilder';
import Invoice from "../../components/Evv/Invoices/Invoices";
import DebugContainer from "../Debug/DebugContainer"
import PreAuthTask from "../PreAuthTask/PreAuthTask"
import SubscriptionModal from "../Landing/SubscriptionModal";
import BasicCompanySettingsModal from "../Landing/BasicCompanySettingsModal";
import SupportCoordinatorHome from '../../container/SupportCoordinator/SupportCoordinatorHome'
import SupportCoordinatorSupervisorHome from '../../container/Manager/SupportCoordinatorSupervisorHome';
import DayCareEmployeeHome from '../Employee/EmployeeHome/DayCareEmployeeHome';
import Documents from '../Documents/Documents'
import Forms from '../Forms/Forms';
import useAxiosWithMsal from 'src/hooks/useAxiosWithMsal';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { People, Home, IntegrationInstructions, Person, Room, AddCircle, AccessTimeFilled, Map, InsertDriveFile, Receipt, FormatListBulleted, CalendarToday } from '@mui/icons-material';
import MuiDrawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  });
  
const closedMixin = (theme) => ({
transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
}),
overflowX: 'hidden',
width: `calc(${theme.spacing(8)} + 1px)`,
[theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
},
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    zIndex: 100,
    ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
    }),
}),
);

export default function GeneralSidebar(props) {
    const theme = useTheme();
    const [screen, setScreen] = useState('home');
    const [toModule, setToModule] = useState('task');
    const [companySettings, setCompanySettings] = useState([]);
    const [open, setOpen] = useState(true);

    const { error, executeAxiosCall } = useAxiosWithMsal();

    useEffect(() => {
        const getCompanySettings = async() => {
            let response = (await axios.get(APIURL + '/config/settings', { params: { comp_id: props.user.comp_id } })).data
            setCompanySettings(response)
        }

        getCompanySettings();
    }, []);
    
    
    const changeScreen = (newScreenName, newToModule) => {
        setScreen(newScreenName)
        setToModule(newToModule)
    }

    const showButtonHome = () => {
        return (
            <ListItem
                key={'home'}
                disablePadding
                sx={{
                    backgroundColor: screen === 'home' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                    borderRight: '1px solid #3FA7D6'
                }}
            >
                <NavLink onClick={() => { setScreen('home') }} to="/" exact style={{ textDecoration: 'none' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            width: drawerWidth
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Home style={{ color: screen === 'home' ? 'white' : '#3FA7D6' }} fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primary="Home"
                            sx={{
                                opacity: open ? 1 : 0,
                                color: screen === 'home' ? 'white' : '#3FA7D6'
                            }}
                        />
                    </ListItemButton>
                </NavLink>
            </ListItem>
        );
    }
    
    const showButtonProjects = () => {
        return (
            <ListItem
                key={'projects'}
                disablePadding
                sx={{
                    backgroundColor: screen === 'projects' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                    borderRight: '1px solid #3FA7D6'
                }}
            >
                <NavLink onClick={() => { setScreen('projects') }} to="/projects" exact style={{ textDecoration: 'none' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            width: drawerWidth
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <IntegrationInstructions style={{ color: screen === 'projects' ? 'white' : '#3FA7D6' }} fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primary={companySettings.length > 1 && companySettings.filter(setting => setting.feature_id == 105).length > 0 ? 'SDR' : "Projects"}
                            sx={{
                                opacity: open ? 1 : 0,
                                color: screen === 'projects' ? 'white' : '#3FA7D6'
                            }}
                        />
                    </ListItemButton>
                </NavLink>
            </ListItem>
        );
    }
    
    const showButtonCustomers = () => {
        let text = companySettings.length > 1 && companySettings.filter(setting => [101, 102, 104, 105].includes(setting.feature_id)).length > 0 ? 'Clients' : 'Customers'
        return (
            <ListItem
                key={text}
                disablePadding
                sx={{
                    backgroundColor: screen === 'customers' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                    borderRight: '1px solid #3FA7D6'
                }}
            >
                <NavLink onClick={() => { setScreen('customers') }} to="/customers" exact style={{ textDecoration: 'none' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            width: drawerWidth
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <People style={{ color: screen === 'customers' ? 'white' : '#3FA7D6' }} fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primary={text}
                            sx={{
                                opacity: open ? 1 : 0,
                                color: screen === 'customers' ? 'white' : '#3FA7D6'
                            }}
                        />
                    </ListItemButton>
                </NavLink>
            </ListItem>
        );
    }
    
    const showButtonContacts = () => {
        return (
            <ListItem
                key={'contacts'}
                disablePadding
                sx={{
                    backgroundColor: screen === 'contacts' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                    borderRight: '1px solid #3FA7D6'
                }}
            >
                <NavLink onClick={() => { setScreen('contacts') }} to="/contacts" exact style={{ textDecoration: 'none' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            width: drawerWidth
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <Person style={{ color: screen === 'contacts' ? 'white' : '#3FA7D6' }} fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primary="Contacts"
                            sx={{
                                opacity: open ? 1 : 0,
                                color: screen === 'contacts' ? 'white' : '#3FA7D6'
                            }}
                        />
                    </ListItemButton>
                </NavLink>
            </ListItem>
        );
    }
    
    const showButtonLocate = () => {
        if (['Admin', 'Manager', 'Case Manager'].includes(props.user.type) && !(companySettings.length > 1 && companySettings.filter(setting => setting.feature_id == 105).length > 0)) {
            return (
                <ListItem
                    key={'locate'}
                    disablePadding
                    sx={{
                        backgroundColor: screen === 'locate' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                        borderRight: '1px solid #3FA7D6'
                    }}
                >
                    <NavLink onClick={() => { setScreen('locate') }} to="/locate" exact style={{ textDecoration: 'none' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                width: drawerWidth
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <Room style={{ color: screen === 'locate' ? 'white' : '#3FA7D6' }} fontSize="large" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Locate"
                                sx={{
                                    opacity: open ? 1 : 0,
                                    color: screen === 'locate' ? 'white' : '#3FA7D6'
                                }}
                            />
                        </ListItemButton>
                    </NavLink>
                </ListItem>
            );
        }
    }
    
    const showButtonNew = () => {
        if (['Admin', 'Manager', 'Case Manager'].includes(props.user.type)) {
            return (
                <ListItem
                    key={'new'}
                    disablePadding
                    sx={{
                        backgroundColor: screen === 'new' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                        borderRight: '1px solid #3FA7D6'
                    }}
                >
                    <NavLink onClick={() => { setScreen('new') }} to="/new" exact style={{ textDecoration: 'none' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                width: drawerWidth
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <AddCircle style={{ color: screen === 'new' ? 'white' : '#3FA7D6' }} fontSize="large" />
                            </ListItemIcon>
                            <ListItemText
                                primary={companySettings.length > 1 && companySettings.filter(setting => setting.feature_id == 104).length > 0 ? 'Intake' : "New"}
                                sx={{
                                    opacity: open ? 1 : 0,
                                    color: screen === 'new' ? 'white' : '#3FA7D6'
                                }}
                            />
                        </ListItemButton>
                    </NavLink>
                </ListItem>
            );
        }
    }
    
    const showButtonTimesheets = () => {
        if (['Admin', 'Manager', 'Fieldworker', 'Support Coordinator', 'Case Manager'].includes(props.user.type)) {
            if (!!props.user.subs.find((d) => d.feature_id === 12)) {
                return (
                    <ListItem
                        key={'timesheets'}
                        disablePadding
                        sx={{
                            backgroundColor: screen === 'timesheets' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                            borderRight: '1px solid #3FA7D6'
                        }}
                    >
                        <NavLink onClick={() => { setScreen('timesheets') }} to="/timesheets" exact style={{ textDecoration: 'none' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    width: drawerWidth
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <AccessTimeFilled style={{ color: screen === 'timesheets' ? 'white' : '#3FA7D6' }} fontSize="large" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Timesheets"
                                    sx={{
                                        opacity: open ? 1 : 0,
                                        color: screen === 'timesheets' ? 'white' : '#3FA7D6'
                                    }}
                                />
                            </ListItemButton>
                        </NavLink>
                    </ListItem>
                );
            }
        }
    }
    
    const showButtonEVV = () => {
        if (['Admin'].includes(props.user.type)) {
            if (!!props.user.subs.find((d) => d.feature_id === 11) && !!props.user.states.find(state => state.state_id === 'NJ')) {
                return (
                    <ListItem
                        key={'evv'}
                        disablePadding
                        sx={{
                            backgroundColor: screen === 'evv' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                            borderRight: '1px solid #3FA7D6'
                        }}
                    >
                        <NavLink onClick={() => { setScreen('evv') }} to="/evv" exact style={{ textDecoration: 'none' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    width: drawerWidth
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Map style={{ color: screen === 'evv' ? 'white' : '#3FA7D6' }} fontSize="large" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="EVV"
                                    sx={{
                                        opacity: open ? 1 : 0,
                                        color: screen === 'evv' ? 'white' : '#3FA7D6'
                                    }}
                                />
                            </ListItemButton>
                        </NavLink>
                    </ListItem>
                );
            }
        }
    }
    
    const showButtonEVV_AZ = () => {
        if (['Admin'].includes(props.user.type)) {
            if (!!props.user.subs.find((d) => d.feature_id === 11) && !!props.user.states.find(state => state.state_id === 'AZ')) {
                return (
                    <ListItem
                        key={'evvAZ'}
                        disablePadding
                        sx={{
                            backgroundColor: screen === 'evvAZ' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                            borderRight: '1px solid #3FA7D6'
                        }}
                    >
                        <NavLink onClick={() => { setScreen('evvAZ') }} to="/evvAZ" exact style={{ textDecoration: 'none' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                    width: drawerWidth
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Map style={{ color: screen === 'evvAZ' ? 'white' : '#3FA7D6' }} fontSize="large" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="EVV"
                                    sx={{
                                        opacity: open ? 1 : 0,
                                        color: screen === 'evvAZ' ? 'white' : '#3FA7D6'
                                    }}
                                />
                            </ListItemButton>
                        </NavLink>
                    </ListItem>
                );
            }
        }
    }
    
    const showButtonDocuments = () => {
        if (props.user.subs.find((d) => d.feature_id === 14)) {
            return (
                <ListItem
                    key={'documents'}
                    disablePadding
                    sx={{
                        backgroundColor: screen === 'documents' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                        borderRight: '1px solid #3FA7D6'
                    }}
                >
                    <NavLink onClick={() => { setScreen('documents') }} to="/documents" exact style={{ textDecoration: 'none' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                width: drawerWidth
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <InsertDriveFile style={{ color: screen === 'documents' ? 'white' : '#3FA7D6' }} fontSize="large" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Documents"
                                sx={{
                                    opacity: open ? 1 : 0,
                                    color: screen === 'documents' ? 'white' : '#3FA7D6'
                                }}
                            />
                        </ListItemButton>
                    </NavLink>
                </ListItem>
            );
        }
    }
    
    const showButtonForms = () => {
        return (
            <ListItem
                key={'forms'}
                disablePadding
                sx={{
                    backgroundColor: screen === 'forms' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                    borderRight: '1px solid #3FA7D6'
                }}
            >
                <NavLink onClick={() => { setScreen('forms') }} to="/forms" exact style={{ textDecoration: 'none' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            width: drawerWidth
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            <FormatListBulleted style={{ color: screen === 'forms' ? 'white' : '#3FA7D6' }} fontSize="large" />
                        </ListItemIcon>
                        <ListItemText
                            primary="Forms"
                            sx={{
                                opacity: open ? 1 : 0,
                                color: screen === 'forms' ? 'white' : '#3FA7D6'
                            }}
                        />
                    </ListItemButton>
                </NavLink>
            </ListItem>
        );
    }
    
    const showButtonBilling = () => {
        let states = props.user.states
        states = states.map((obj) => obj.state_id)
        let feature = props.user.subs
        feature = feature.map((obj) => obj.feature_id)
        function checkActive() {
            if (String(window.location.href).includes("/billing")) {
                for (var c of document.getElementsByClassName("billing-button-lines")) {
                    c.setAttribute("stroke", "#FFFFFF")
                }
                for (var c of document.getElementsByClassName("billing-button-paths")) {
                    c.setAttribute("fill", "#FFFFFF")
                }
            }
            else {
                for (var c of document.getElementsByClassName("billing-button-lines")) {
                    c.setAttribute("stroke", "#3FA7D6")
                }
                for (var c of document.getElementsByClassName("billing-button-paths")) {
                    c.setAttribute("fill", "#3FA7D6")
                }
            }
            return String(window.location.href).includes("/billing")
        }
        if (['Admin'].includes(props.user.type) && states.includes('NJ') && feature.includes(18)) {
            return (
                <ListItem
                    key={'billing'}
                    disablePadding
                    sx={{
                        backgroundColor: screen === 'billing' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                        borderRight: '1px solid #3FA7D6'
                    }}
                >
                    <NavLink onClick={() => { setScreen('billing') }} to="/billing" exact style={{ textDecoration: 'none' }} isActive={checkActive}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                width: drawerWidth
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <Receipt style={{ color: screen === 'billing' ? 'white' : '#3FA7D6' }} fontSize="large" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Billing"
                                sx={{
                                    opacity: open ? 1 : 0,
                                    color: screen === 'billing' ? 'white' : '#3FA7D6'
                                }}
                            />
                        </ListItemButton>
                    </NavLink>
                </ListItem>
            );
        }
    }
    
    const showButtonScheduler = () => {
        if (props.user.subs.find((d) => d.feature_id === 20)) {
            return (
                <ListItem
                    key={'scheduler'}
                    disablePadding
                    sx={{
                        backgroundColor: screen === 'scheduler' ? '#3FA7D6' : 'rgb(235, 248, 255)',
                        borderRight: '1px solid #3FA7D6'
                    }}
                >
                    <NavLink onClick={() => { setScreen('scheduler') }} to="/scheduler" exact style={{ textDecoration: 'none' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                width: drawerWidth
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                <CalendarToday style={{ color: screen === 'scheduler' ? 'white' : '#3FA7D6' }} fontSize="large" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Scheduler"
                                sx={{
                                    opacity: open ? 1 : 0,
                                    color: screen === 'scheduler' ? 'white' : '#3FA7D6'
                                }}
                            />
                        </ListItemButton>
                    </NavLink>
                </ListItem>
            );
        }
    }
    
    return(
        <div className='sidebar-mainDiv' style={{ display: 'flex', height: '100%',}}>
            <BasicCompanySettingsModal user={props.user}></BasicCompanySettingsModal>
            <SubscriptionModal user={props.user}></SubscriptionModal>
            <Drawer 
                variant="permanent"
                open={open}
                style={{ zIndex: 1 }}
            >
                {/* <div style={{ height: "250px", backgroundColor: "rgb(235, 248, 255)", borderRight: '1px solid #3FA7D6' }} /> */}
                <List sx={{ p: 0, mt: 9 }}>
                    {showButtonHome()}
                    {showButtonProjects()}
                    {showButtonCustomers()}
                    {showButtonContacts()}
                    {showButtonLocate()}
                    {showButtonNew()}
                    {showButtonTimesheets()}
                    {showButtonEVV()}
                    {showButtonEVV_AZ()}
                    {showButtonBilling()}
                    {showButtonDocuments()}
                    {showButtonForms()}
                    {showButtonScheduler()}
                </List>
                <div 
                    style={{ 
                        display: "flex", 
                        flexDirection: "column", 
                        width: '100%', 
                        textAlign: 'center', 
                        height: "100%", 
                        backgroundColor: "rgb(235, 248, 255)", 
                        borderRight: '1px solid #3FA7D6', 
                        justifyContent: 'flex-end' 
                    }}
                    onClick={() => setOpen(!open)}
                >
                    <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: '50px', cursor: 'pointer' }}>
                        {open ? <ChevronLeft style={{ color: '#3FA7D6', cursor: 'pointer' }} fontSize='large' /> : <ChevronRight style={{ color: '#3FA7D6', cursor: 'pointer' }} fontSize='large' />}
                    </div>
                    <div>
                        <a style={{ width: '100%', color: '#3FA7D6', whiteSpace: 'nowrap', fontSize: '12px', marginBottom: '5px' }} href="https://staging.fieldworker.ai/termsandconditions" target="_blank">{open ? 'Terms of Service' : 'Terms'}</a>
                    </div>
                </div>
            </Drawer>
            <div style={{ marginLeft: '10px', marginRight: '10px', marginTop: '15px', width: '100%', height: '100%'/*, overflowY: 'visible', overflowX: 'visible'*/ /*width: '100%', marginLeft: '2px',*/ }}>
                <Switch>
                    {/* Day Care Or Community Center Homepages */}
                        {companySettings.length > 1 ? companySettings.filter(setting => setting.feature_id == 105).length > 0 && 
                            <Route exact path="/home" >
                                <DayCareEmployeeHome {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id}/>
                            </Route>
                        : <></>}
                        {companySettings.length > 1 ? companySettings.filter(setting => setting.feature_id == 105).length > 0 && 
                            <Route exact path="/" >
                                <DayCareEmployeeHome {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id}/>
                            </Route>
                        : <></>}
                    
                    {/* Manager Homes Pages */}
                        {companySettings.length > 1 ? companySettings.filter(setting => setting.feature_id == 101).length > 0 && ['Manager'].includes(props.user.type) &&
                            <Route exact path="/home" >
                                <SupportCoordinatorSupervisorHome {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} mapRender={toModule}/>
                            </Route>
                        : <></>}
                        {companySettings.length > 1 ? companySettings.filter(setting => setting.feature_id == 101).length > 0 && ['Manager'].includes(props.user.type) &&
                            <Route exact path="/" >
                                <SupportCoordinatorSupervisorHome {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} mapRender={toModule}/>
                            </Route>
                        : <></>}
                        {companySettings.length > 1 ? companySettings.filter(setting => setting.feature_id == 104).length > 0 && ['Manager'].includes(props.user.type) &&
                            <Route exact path="/home" >
                                <EmployeeHome {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                            </Route>
                        : <></>}
                        {companySettings.length > 1 ? companySettings.filter(setting => setting.feature_id == 104).length > 0 && ['Manager'].includes(props.user.type) &&
                            <Route exact path="/home" >
                                <EmployeeHome {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                            </Route>
                        : <></>}

                    {/* Misc Homes Pages */}
                        {companySettings.length > 1 ? companySettings.filter(setting => setting.feature_id == 101).length > 0 && ['Support Coordinator'].includes(props.user.type) &&
                        <Route exact path="/home">
                            <SupportCoordinatorHome {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} mapRender={toModule}/>
                        </Route>
                        : <></>}
                        {companySettings.length > 1 ? companySettings.filter(setting => setting.feature_id == 101).length > 0 && ['Support Coordinator'].includes(props.user.type) &&
                        <Route exact path="/" >
                            <SupportCoordinatorHome {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} mapRender={toModule}/>
                        </Route>
                        : <></>}
                    
                    <Route exact path="/home" >
                        <EmployeeHome {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                    </Route>
                    <Route exact path="/" >
                        <EmployeeHome {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                    </Route>
                    <Route exact path="/settings">
                        <Settings {...props} updateSubs={props.updateSubs} checkTokenExpiry={props.checkTokenExpiry} comp_id={props.comp_id} user={props.user} />
                    </Route>
                    <Route exact path="/new">
                        <SideBar {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} mapRender={toModule} />
                    </Route>
                    <Route exact path="/projects">
                        <Projects {...props} changeScreen={changeScreen} checkTokenExpiry={props.checkTokenExpiry} comp_id={props.comp_id} user={props.user} companySettings={companySettings}/>
                    </Route>
                    <Route exact path="/customers">
                        <Customers {...props} changeScreen={changeScreen} checkTokenExpiry={props.checkTokenExpiry} comp_id={props.comp_id} user={props.user} />
                    </Route>
                    <Route exact path="/contacts">
                        <Contacts {...props} changeScreen={changeScreen} checkTokenExpiry={props.checkTokenExpiry} comp_id={props.comp_id} user={props.user} />
                    </Route>
                    <Route exact path="/locate">
                        <EmpLocate {...props} checkTokenExpiry={props.checkTokenExpiry} comp_id={props.comp_id} />
                    </Route>
                    <Route exact path="/reports">
                        <Reports_V2 {...props} checkTokenExpiry={props.checkTokenExpiry} comp_id={props.comp_id} user={props.user} page={'Reports'} />
                    </Route>
                    <Route exact path="/notifications">
                        <Notifications {...props} checkTokenExpiry={props.checkTokenExpiry} comp_id={props.comp_id} user={props.user} page={'Notifications'} />
                    </Route>
                    <Route exact path="/team">
                        <EmpSideBar {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                    </Route>
                    {props.user.type === 'Admin' &&
                    <Route exact path="/payroll">
                        <PayrollContainer {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                    </Route>
                    }
                    <Route exact path="/empDashboards">
                        <EmpAnalytics {...props} checkTokenExpiry={props.checkTokenExpiry} comp_id={props.comp_id} />
                    </Route>
                    <Route exact path="/debug">
                        <DebugContainer />
                    </Route>
                    {!!props.user.role === 'Admin' &&
                        <Route exact path="/preAuthTask">
                            <PreAuthTask {...props}/>
                        </Route>
                    }
                    {!!props.user.role === 'Admin' &&
                        <Route exact path="/invoice">
                        <Invoice {...props} checkTokenExpiry={props.checkTokenExpiry} comp_id={props.comp_id} />
                        </Route>
                    }
                    {!!props.user.subs.find((d)=> d.feature_id === 11) &&
                        <Route exact path="/evv">
                            <EvvV1 {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                        </Route>
                    }
                    {!!props.user.subs.find((d)=> d.feature_id === 11) &&
                        <Route exact path="/evvAZ">
                            <EVV_AZ {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                        </Route>
                    }
                    {!!props.user.subs.find((d)=> d.feature_id === 12) &&
                        <Route exact path="/timesheets">
                            <TimeSummaryAlt2 {...props} company_id={props.comp_id} checkTokenExpiry={props.checkTokenExpiry} user={props.user} />
                        </Route>
                    }
                    {!!props.user.subs.find((d)=> d.feature_id === 15) &&
                        <Route exact path="/formbuilder">
                            <FormBuilder {...props} checkTokenExpiry={props.checkTokenExpiry} comp_id={props.comp_id} />
                        </Route>
                    }
                    {!!props.user.subs.find((d)=> d.feature_id === 18) &&
                        <Route exact path="/billing">
                            <Billing {...props} changeScreen={changeScreen} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                        </Route>
                    }
                    <Route exact path="/documents">
                        <Documents {...props} comp_id={props.comp_id} checkTokenExpiry={props.checkTokenExpiry} user={props.user} />
                    </Route>
                    <Route exact path="/forms">
                        <Forms {...props} changeScreen={changeScreen} comp_id={props.comp_id} checkTokenExpiry={props.checkTokenExpiry} user={props.user} />
                    </Route>
                    <Route exact path="/scheduler">
                        <Scheduler {...props} changeScreen={changeScreen} comp_id={props.comp_id} checkTokenExpiry={props.checkTokenExpiry} user={props.user} />
                    </Route>
                </Switch>
            </div>
        </div >
    );
}