import React, { useState, useEffect } from "react";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Form } from '@formio/react';
import { Box, Link, Dialog, DialogTitle, DialogContent } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import axios from "axios";
import { APIURL } from "../../../config";
import moment from "moment";
import './Forms.css'

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        // color: '#ccc',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        // backgroundColor: 'black',
    },
}));

export default function Forms(props) {
    const [formsData, setFormsData] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [selectedForm, setSelectedForm] = useState(null);
    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await axios.get(APIURL + '/customer_form/customer/' + props.cus_id);
                if (response.data.length > 0) {
                    setFormsData(response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            setLoading(false);
        }
        fetchData();
    }, []);

    const handleSubmit = async (submission) => {
        try {
            const response = await axios.put(APIURL + '/customer_form/' + selectedForm.form_id, {
                data: JSON.stringify(submission),
                emp_id: props.user.emp_id
            });

            setOpen(false);
            setSelectedForm(null);
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    }

    const columns = [
        { field: "id", headerName: "ID", width: 100, hide: true },
        { field: "form_id", headerName: "Form ID", width: 100, hide: true },
        { field: "form_name", headerName: "Form Name", width: 150 },
        {
            field: "view_edit_form",
            headerName: "View/Edit",
            width: 90,
            hide: false,
            renderCell: (params) => {
                const onClickView = async () => {
                    setSelectedForm(params.value);
                    setOpen(true);
                    setIsEdit(false);
                };

                const onClickEdit = async () => {
                    setSelectedForm(params.value);
                    setOpen(true);
                    setIsEdit(true);
                };
                return (
                    <div style={{ display: 'flex' }}>
                        <div style={{ color: '#3FA7D6', marginRight: '10px' }}>
                            <Link href="#" underline="hover" onClick={onClickView}>
                                <i class="fas fa-list-ul" style={{ marginRight: '5px' }}></i>
                            </Link>
                        </div>
                        <div> | </div>
                        <div style={{ color: '#3FA7D6', marginLeft: '10px' }}>
                            <Link href="#" underline="hover" onClick={onClickEdit}>
                                <i class="far fa-edit" style={{ marginRight: '5px' }}></i>
                            </Link>
                        </div>
                    </div>
                )
            }
        },
        { field: "task_title", headerName: "Task Title", width: 150 },
        { field: "task_date", headerName: "Task Date", width: 100 },
        { field: "last_updated", headerName: "Form Last Updated", width: 175 },
        { field: "completed_by", headerName: "Completed By", width: 200 },
    ]

    let rows = formsData.map((form, idx) => {
        let formRow = {
            id: idx,
            form_id: form.form_id,
            form_name: form.form_name,
            task_title: form.tas_title,
            task_date: moment(form.checkoutEmpDate).format('MM/DD/YYYY'),
            last_updated: moment(form.last_updated).format('MM/DD/YYYY hh:mm A'),
            completed_by: form.emp_first_name ? form.emp_first_name + ' ' + form.emp_last_name : '-',
            view_edit_form: form
        };
        return formRow;
    });

    return (
        <div id="forms-main">
            <div className='forms_info'>
                <div style={{ marginBottom: '10px' }}>
                    <h5>Forms</h5>
                </div>
                {isLoading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </div> :
                    <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '100%', height: "calc(100vh - 340px)", background: 'white' }}>
                            <DataGridPro
                                rows={rows}
                                columns={columns}
                                initialState={{ sorting: { sortModel: [{ field: "last_updated", sort: "desc" }] } }}
                                checkboxSelection={false}
                                disableSelectionOnClick
                                width={"100%"}
                                justifyContent="center"
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                            />
                        </Box>
                        <Box>
                            <Dialog
                                open={open}
                                onClose={() => { setOpen(false); setSelectedForm(null); }}
                                maxWidth='md'
                                fullWidth
                                scroll='body'
                                disableRestoreFocus
                            >
                                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #ccc' }}>
                                    <div>
                                        {selectedForm?.form_name}
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ marginRight: 10 }}>
                                            <div>{selectedForm?.tas_title}</div>
                                            <div>{moment(selectedForm?.checkoutEmpDate).format('MM/DD/YYYY hh:mm A')}</div>
                                        </div>
                                        <BootstrapTooltip title={
                                            selectedForm?.cusGoals ?
                                                selectedForm?.cusGoals.map((cg, index) => {
                                                    return (
                                                        <div key={cg.customer_goal_id} style={{ padding: 10 }}>
                                                            <h4>{index + 1}{') '}{cg.goal_title}: </h4>
                                                            <h5>{cg.goal_detail}</h5>
                                                        </div>
                                                    )
                                                }) :
                                                <h4>No goals for this customer yet.</h4>
                                        } placement="right-start">
                                            <div className='customer_goal_icon'>CG</div>
                                        </BootstrapTooltip>
                                    </div>
                                </DialogTitle>
                                <DialogContent>
                                    <Form
                                        form={selectedForm?.json_schema}
                                        submission={selectedForm?.formData}
                                        onSubmit={handleSubmit}
                                        options={{
                                            readOnly: isEdit ? false : true,
                                            hide: {
                                                submit: isEdit ? false : true,
                                            }
                                        }}
                                    />
                                </DialogContent>
                            </Dialog>
                        </Box>
                    </div>
                }
            </div>
        </div>
    )
}