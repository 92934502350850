import LoadingButton from '@mui/lab/LoadingButton';
import { Download } from '@mui/icons-material';
import axios from 'axios';
import { APIURL } from '../../config';

const BulkDownloadButton = (props) => {

    return (
        <LoadingButton
            loading={props.loading}
            loadingPosition="start"
            size="small"
            onClick={props.onClick}
            startIcon={<Download />}
        >
            {props.label || "All"}
        </LoadingButton>
    )
}

export default BulkDownloadButton;