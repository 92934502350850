import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Call, Home, Clear } from '@mui/icons-material';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as FaceToFace } from '../../container/SupportCoordinator/facetoface.svg';
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function SCVisitReport(props) {
    // const [gridId, setGridId] = useState(57);
    // const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [reportDetails, setReportDetails] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports")
            .then((response) => {
                const report = response?.data?.reports.find((ele) => ele.report_name === "SC Visit Report");
                setReportDetails(report);
                setIsFavorite(report ? (report.status === 1 ? true : false) : false);
            });

        axios
            .get(APIURL + "/reports/getSCVisits", {
                params: {
                    comp_id: props.comp_id,
                    emp_id: props.user.emp_id,
                    start: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
                    end: moment().endOf('month').format('YYYY-MM-DDT23:59:59'),
                    callType: 'report'
                },
            })
            .then((response) => {
                setData(response?.data.length !== 0 ? response?.data : []);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const columns = [
        {
            field: 'customer_name',
            headerName: 'Customer Name',
            headerAlign: 'center',
            width: 200,
            hide: false,
            type: "string",
        },
        {
            field: "ddd_id",
            headerName: 'DDD ID',
            headerAlign: 'center',
            width: 133,
            hide: true,
        },
        {
            field: "local_id",
            headerName: 'Local ID',
            headerAlign: 'center',
            width: 133,
            hide: true,
        },
        {
            field: "month1",
            headerName: moment().subtract(2, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return pastMonths(params)
            }
        },
        {
            field: "month2",
            headerName: moment().subtract(1, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return pastMonths(params)
            }
        },
        {
            field: "month3",
            headerName: moment().startOf("month").format('MMMM') + " (Current Month)",
            headerAlign: 'center',
            width: props.isSubscibedToTimeManagement ? 450 : 250,
            hide: false,
            renderCell: (params) => {
                return pastMonths(params)
            }
        },
        {
            field: "month4",
            headerName: moment().subtract(-1, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: "month5",
            headerName: moment().subtract(-2, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: "month6",
            headerName: moment().subtract(-3, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: "month7",
            headerName: moment().subtract(-4, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: "month8",
            headerName: moment().subtract(-5, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: "month9",
            headerName: moment().subtract(-6, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: "month10",
            headerName: moment().subtract(-7, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: "month11",
            headerName: moment().subtract(-8, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: "month12",
            headerName: moment().subtract(-9, "month").startOf("month").format('MMMM'),
            headerAlign: 'center',
            width: 133,
            hide: false,
            renderCell: (params) => {
                return futureMonths(params)
            }
        },
        {
            field: 'service_plan',
            headerName: 'Service Plan',
            width: 140,
            hide: false
        },
        {
            field: 'program',
            headerName: 'Program',
            width: 140,
            hide: false
        },
        {
            field: 'mt_submission_date',
            headerName: 'MT Submit Date',
            width: 140,
            hide: false,
            type: "date",
            valueGetter: (params) => {
                let date = null;

                if (!params.row.month3) {
                    return date;
                }
                params.row.month3.map(tas => {
                    if (tas.tas_mm_id) {
                        if (tas.submitted_on)
                            date = new Date(tas.submitted_on);
                    }
                })
                return date;
            },
            valueFormatter: (params) => params.value ? moment(params.value).format('MM/DD/YYYY') : null
        },
        {
            field: 'plan_approval_date',
            headerName: 'Plan Approval Date',
            type: "date",
            width: 140,
            hide: false,
        },
        {
            field: 'plan_start_date',
            headerName: 'Plan Start Date',
            type: "date",
            width: 140,
            hide: false
        },
        {
            field: 'plan_end_date',
            headerName: 'Plan End Date',
            type: "date",
            width: 140,
            hide: false
        },
        {
            field: 'last_annual_visit',
            headerName: 'Last Annual Visit',
            type: "date",
            width: 140,
            hide: false
        },
        {
            field: 'last_mt_upload',
            headerName: 'Last MT Upload',
            type: "date",
            width: 140,
            hide: false
        }
    ];

    let rows = data.map((customer, index) => {
        return {
            id: index,
            cus_id: customer.cus_id,
            ddd_id: customer.DDDID,
            local_id: customer.cus_local_id,
            customer_name: customer.cus_name,
            month1: customer.month1,
            month2: customer.month2,
            month3: customer.month3,
            month4: customer.month4,
            month5: customer.month5,
            month6: customer.month6,
            month7: customer.month7,
            month8: customer.month8,
            month9: customer.month9,
            month10: customer.month10,
            month11: customer.month11,
            month12: customer.month12,
            mmt: customer.month3,
            service_plan: customer.active_plan_version,
            program: customer.plan_program_name,
            pre_authorization: customer.priorAuth,
            plan_approval_date: customer.plan_approval_date ? moment(customer.plan_approval_date).utc().format('MM/DD/YYYY') : null,
            plan_start_date: customer.pro_starts_on ? moment(customer.pro_starts_on).utc().format('MM/DD/YYYY') : null,
            plan_end_date: customer.pro_ends_on ? moment(customer.pro_ends_on).utc().format('MM/DD/YYYY') : null,
            last_annual_visit: customer.LastAnnualVisitDate ? moment(customer.LastAnnualVisitDate).utc().format('MM/DD/YYYY') : null,
            last_mt_upload: customer.LastMMTUploadDate ? moment(customer.LastMMTUploadDate).utc().format('MM/DD/YYYY') : null,
            last_download_date: customer.month3.find(tas => tas.tas_mm_id !== null)?.last_download_date ? moment(customer.month3.find(tas => tas.tas_mm_id !== null).last_download_date).utc().format('MM/DD/YYYY') : null
        }
    });

    function FaceToFaceIcon(props) {
        return (
            <SvgIcon {...props} viewBox="0 0 256 256" component={FaceToFace} />
        );
    }

    let pastMonths = (params) => {
        let mtTask = params.value.length > 0 ? params.value.reduce((max, tas) => tas.tas_mm_id > max.tas_mm_id ? tas : max, params.value[0]) : undefined;

        if (mtTask === undefined) {
            return (
                <Clear style={{ color: "red", margin: 10, width: '100%' }} />
            )
        }

        let styles = { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" };
        switch (mtTask.tas_type.toLowerCase()) {
            case 'home visit':
                return (<div style={styles}><Home style={{ color: "gray", margin: 2 }} /><span>Home Visit</span></div>)
            case 'visit':
                return (<div style={styles}><FaceToFaceIcon style={{ height: "22px", color: "gray", margin: 2 }} /><span>Face-to-Face</span></div>)
            default:
                return (<div style={styles}><Call style={{ color: "gray", margin: 2 }} /><span>Phone/Video</span></div>)
        }
    }

    let futureMonths = (params) => {
        let styles = { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" };
        switch (params.value.toLowerCase()) {
            case 'home visit':
                return (<div style={styles}><Home style={{ color: "gray", margin: 2 }} /><span>Home Visit</span></div>)
            case 'visit':
                return (<div style={styles}><FaceToFaceIcon style={{ height: "22px", color: "gray", margin: 2 }} /><span>Face-to-Face</span></div>)
            default:
                return (<div style={styles}><Call style={{ color: "gray", margin: 2 }} /><span>Phone/Video</span></div>)
        }
    }

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ margin: 10, width: '75vw' }}>
                {rows.length === 0 ?
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: "500px", marginTop: 10 }}
                    >
                        <img
                            src="./nosearchresult.png"
                            alt="nosearchresult"
                            style={{ width: "25rem", height: "15rem", margin: 10 }}
                        />
                        <h5
                            style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
                            className="text-center"
                        >
                            There is currently no data available{" "}
                        </h5>
                    </div>
                    :
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div />
                            <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                                <img
                                    src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                                    alt="barchart"
                                    style={{
                                        fontFamily: "Lato",
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        cursor: "pointer",
                                        margin: 5
                                    }}
                                    onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                                />
                            </Tip>
                        </div>
                        <div style={{ height: '80vh' }}>
                            <DataGridPro
                                // gridId={gridId}
                                // user={props.user}
                                initialState={{
                                    pinnedColumns: {
                                        left: ['cus_id', 'customer_name']
                                    }
                                }}
                                // apiRef={apiRef}
                                getRowId={(row) => row.cus_id}
                                rows={rows}
                                columns={columns}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                checkboxSelection={false}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>
                }

                <Snackbar
                    open={successText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setSuccessText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"success"}
                        elevation={6}
                        variant="filled"
                    >
                        {successText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setSuccessText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={errorText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setErrorText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"error"}
                        elevation={6}
                        variant="filled"
                    >
                        {errorText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setErrorText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}