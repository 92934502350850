/* THIS PAGE SHOULD BE EMPTY FOR ANY PRODUCTION BUILDS */
import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import "./FWButton.css"
import { gridColumnLookupSelector } from '@mui/x-data-grid';

export default function FWButton(props) {

    const [text, setText] = useState(!!props.text ? props.text : "FWButton Text");

    useEffect(() => {
        
    }, []);

    return(
        <Button className="fieldworkerComponent-FWButton" onClick={() => {
            try{
                props.onClick();
            } catch(err){
                console.log("FWButton missing props.onClick")
            }
        }}>
            {text}
        </Button>
    );
}