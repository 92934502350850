import React, { useState, useEffect } from 'react';
import IconButton from "@mui/material/IconButton";
import UplaodForm from '../../../components/forms/UploadForm/UploadForm';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import UploadForm from './UploadForm';

const Upload = (props) => {

    function toggle(){
        props.toggleCSVAdd();
    }

    return (
        <Dialog open={props.showUpload} onClose={() => {props.setCsvAdd(false)}}>
            <DialogActions>
                <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.25 }}
                    onClick={() => {props.setCsvAdd(false)}}
                    >
                        <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent sx={{ paddingTop: '0px', width: 370 }}>
                <UploadForm onSubmit={() => props.toggleCSVAdd()} user={props.user}/>
            </DialogContent>
        </Dialog>
    )
}

export default Upload