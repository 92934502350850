import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import { APIURL } from '../../../config';
import axios from 'axios';
import moment from 'moment';
import Link from '@mui/material/Link';
import ViewMTModal from './ViewMTModal';
import useAxiosWithMsal from 'src/hooks/useAxiosWithMsal';


const MMTRecord = (props) => {
    const { executeAxiosCall } = useAxiosWithMsal();

    const [mm, setMM] = useState({});
    const [manager, setManager] = useState({});
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const getData = async () => {
        const mm =  await executeAxiosCall('GET', "/monthlymonitoring/" + props.data.task.tas_id);
        if(mm.data) {
            setMM(mm.data[0]);
        }

        console.log(mm.data[0])

        const manager = await executeAxiosCall('GET', "/employees", { params: {emp_id: props.user.emp_manager_id}});
        console.log(manager);
        setManager(manager.data[0]);
    }

    useEffect(() => {
        if(props.data) {
            getData();
        }
    }, [props.data])

    return (
        <Box>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Body>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>MT Record</Modal.Title>
                    </Modal.Header>
                    <Box sx={{margin: 2, width: '100%'}}>
                        <Box sx={{ display: 'flex', marginRight: 2, marginBottom: '10px' }}>
                            <div style={{ flex: 2, color: 'gray' }}>REVIEW RESULT</div>
                            <div style={{ flex: 4 }}>{mm.approved?"Approved":"Rejected"}</div>
                        </Box>
                        <Box sx={{ display: 'flex', marginRight: 2, marginBottom: '10px' }}>
                            <div style={{ flex: 2, color: 'gray' }}>MT FORM</div>
                            <div style={{ flex: 4 }}>
                                <Link href="#" onClick={handleShow} underline="always">{(props.user.emp_name + " " + props.cus_name + " Monitoring Tool").replace(' ', '_')}</Link>
                            </div>
                        </Box>
                        <Box sx={{ display: 'flex', marginRight: 2, marginBottom: '10px' }}>
                            <div style={{ flex: 2, color: 'gray' }}>SUPERVISOR</div>
                            <div style={{ flex: 4 }}>{manager.emp_first_name + " " + manager.emp_last_name}</div>
                        </Box>
                        <Box sx={{ display: 'flex', marginRight: 2, marginBottom: '10px' }}>
                            <div style={{ flex: 2, color: 'gray' }}>SUBMISSION DATE</div>
                            <div style={{ flex: 4 }}>{moment(mm.submitted_on).format("MM/DD/YY hh:mmA")}</div>
                        </Box>
                        <Box sx={{ display: 'flex', marginRight: 2, marginBottom: '10px' }}>
                            <div style={{ flex: 2, color: 'gray' }}>REVIEW DATE</div>
                            <div style={{ flex: 4 }}>{moment(mm.reviewed_on).format("MM/DD/YY hh:mmA")}</div>
                        </Box>
                        <Box sx={{ display: 'flex', marginRight: 2, marginBottom: '10px' }}>
                            <div style={{ flex: 2, color: 'gray' }}>COMMENTS</div>
                            <div style={{ flex: 4 }}>{mm.comment?mm.comment:"No Comment"}</div>
                        </Box>
                    </Box>
                    <Box sx={{width: '100%' }}>
                        <Button style={{width: '100%'}} onClick={props.handleClose}>Close</Button>
                    </Box>
                </Modal.Body>
            </Modal>
            <ViewMTModal 
                user={props.user}
                show={show} 
                handleClose={handleClose} 
                type={mm?.mt_type} 
                data={mm?.data ? JSON.parse(mm.data) : {}}
                mm_id={mm?.monthly_monitoring_id}
                cus_name={props.cus_name} 
                checkoutEmpDate={props.data?.task?.checkoutEmpDate}
                review={false}
            />
        </Box>
    )
}

export default MMTRecord