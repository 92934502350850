import React, { useState, useEffect, useRef } from "react";
import { Typography, Button, TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import axios from "axios";
import { APIURL } from "../../../config";
import moment from "moment";
import { useReactToPrint } from 'react-to-print';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import "./FinancialSummary.css";

export default function FinancialSummary(props) {
    const componentRef = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
    const [totRec, setTotRec] = useState(0);
    const [activePlanRec, setActivePlanRec] = useState(0);
    const [initialPlanRec, setInitialPlanRec] = useState(0);
    const [planChangeRec, setPlanChangeRec] = useState(0);
    const [planTerminationRec, setPlanTerminationRec] = useState(0);
    const [planRenewedRec, setPlanRenewedRec] = useState(0);
    const [priorAuthRec, setPriorAuthRec] = useState(0);
    const [priorAuthVoucherRec, setPriorAuthVoucherRec] = useState(0);
    const [claimVoucherRec, setClaimVoucherRec] = useState(0);
    const [readyToBillRec, setReadyToBillRec] = useState(0);
    const [processedRec, setProcessedRec] = useState(0);
    const [releasedRec, setReleasedRec] = useState(0);
    const [deniedRec, setDeniedRec] = useState(0);
    const [pendingRec, setPendingRec] = useState(0);
    const [NJMMISRec, setNJMMISRec] = useState(0);
    const [VoucherRec, setVoucherRec] = useState(0);

    useEffect(() => {
        async function fetchData() {
            let receivableData = await axios.get(APIURL + "/accountsReceivable", {
                params: { companyID: props.comp_id, month: moment(currentMonth).format('YYYYMM') },
            });

            let totRec = 0;
            let activePlanRec = 0, initialPlanRec = 0, planChangeRec = 0, planTerminationRec = 0, planRenewedRec = 0;
            let priorAuthVoucherRec = 0, priorAuthRec = 0;
            let claimVoucherRec = 0, readyToBillRec = 0, processedRec = 0, releasedRec = 0, deniedRec = 0, pendingRec = 0;
            let NJMMISRec = 0, VoucherRec = 0;

            receivableData.data.forEach((rec) => {
                totRec += rec.total;
                if (rec.account_code === 'ACTIVE PLAN') {
                    activePlanRec += rec.total;
                }
                if (rec.account_code === 'INITIAL PLAN') {
                    initialPlanRec += rec.total;
                }
                if (rec.account_code === 'PLAN CHANGE') {
                    planChangeRec += rec.total;
                }
                if (rec.account_code === 'PLAN TERMINATION') {
                    planTerminationRec += rec.total;
                }
                if (rec.account_code === 'PLAN RENEWED') {
                    planRenewedRec += rec.total;
                }
                if (rec.prior_auth_status === 'On DDD Voucher') {
                    priorAuthVoucherRec += rec.total;
                }
                if (rec.primaryID && rec.prior_auth_status !== 'On DDD Voucher') {
                    priorAuthRec += rec.total;
                }
                if (rec.claim_ID) {
                    if (rec.Status === 'Ready to Bill') {
                        readyToBillRec += rec.total;
                    }
                    if (rec.Status === 'Processed as Primary') {
                        processedRec += rec.total;
                    }
                    if (rec.Status === 'Released') {
                        releasedRec += rec.total;
                    }
                    if (rec.Status === 'Denied') {
                        deniedRec += rec.total;
                    }
                    if (rec.Status === 'Pending') {
                        pendingRec += rec.total;
                    }
                }
                if (rec.payment_status === 'Voucher Payment Received') {
                    claimVoucherRec += rec.total;
                }
                if (rec.prior_auth_status !== 'On DDD Voucher' && rec.payment_status !== 'Voucher Payment Received') {
                    NJMMISRec += rec.total;
                }
                if (rec.prior_auth_status === 'On DDD Voucher' || rec.payment_status === 'Voucher Payment Received') {
                    VoucherRec += rec.total;
                }
            })

            setTotRec(totRec);
            setActivePlanRec(activePlanRec);
            setInitialPlanRec(initialPlanRec);
            setPlanChangeRec(planChangeRec);
            setPlanTerminationRec(planTerminationRec);
            setPlanRenewedRec(planRenewedRec);
            setPriorAuthRec(priorAuthRec);
            setPriorAuthVoucherRec(priorAuthVoucherRec);
            setClaimVoucherRec(claimVoucherRec);
            setReadyToBillRec(readyToBillRec);
            setProcessedRec(processedRec);
            setReleasedRec(releasedRec);
            setDeniedRec(deniedRec);
            setPendingRec(pendingRec);
            setNJMMISRec(NJMMISRec);
            setVoucherRec(VoucherRec);
            setIsLoading(false);
        }

        fetchData();
    }, [currentMonth]);

    function onDateChange(newValue) {
        setCurrMonth(moment(newValue).startOf("month"));
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const pieData = [
        { name: 'Active Plan', value: activePlanRec },
        { name: 'Initial Plan', value: initialPlanRec },
        { name: 'Plan Change', value: planChangeRec },
        { name: 'Plan Termination', value: planTerminationRec },
        { name: 'Plan Renewed', value: planRenewedRec },
    ];

    const barData = [
        { name: 'Voucher', value: claimVoucherRec },
        { name: 'Ready', value: readyToBillRec },
        { name: 'Processed', value: processedRec },
        { name: 'Released', value: releasedRec },
        { name: 'Denied', value: deniedRec },
        { name: 'Pending', value: pendingRec }
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384'];

    const isAllZero = (data) => data.every(item => item.value === 0);

    if (isLoading) {
        return (
            <div className="d-flex flex-row w-100 justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                <Typography
                    style={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        fontFamily: "Lato",
                        marginBottom: 10,
                    }}
                >
                    Financial Summary
                </Typography>
                <div id="claims-main"
                    style={{ width: "100%", backgroundColor: "#fafafa" }}
                    className="d-flex row justify-content-start">
                    <div
                        style={{ maxWidth: "100%", marginTop: 30 }}
                        className="col-lg-12 col-sm-7 d-flex flex-column justify-content-start"
                    >
                        <div className="flex-container" style={{ display: "flex" }}>
                            <div
                                className="flex-item"
                                style={{
                                    flexDirection: "row-reverse",
                                    height: "40px",
                                    marginTop: "5px",
                                    marginBottom: "20px",
                                    marginLeft: "-10px",
                                }}
                            >
                                <Button
                                    size="large"
                                    className="DayButton"
                                    onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                                >
                                    <i className="fas fa-arrow-left"></i>
                                </Button>
                                <LocalizationProvider
                                    class="DatePickerParent"
                                    dateAdapter={AdapterDateFns}
                                >
                                    <DatePicker
                                        label="Select Date"
                                        views={["year", "month"]}
                                        value={currentMonth}
                                        onChange={(newValue) => {
                                            this.onDateChange(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="standard" />
                                        )}
                                    />
                                </LocalizationProvider>
                                <Button
                                    size="large"
                                    className="DayButton"
                                    onClick={() => onDateChange(currentMonth.add(1, "month"))}
                                >
                                    <i className="fas fa-arrow-right"></i>
                                </Button>

                                <Button sx={{ marginTop: 1.5, marginLeft: 5 }} size="large" variant="contained" onClick={handlePrint}><i className="fas fa-print"></i></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={componentRef}>
                    <div style={{ border: "1px solid #ccc", padding: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <div>
                                <div style={{ display: 'flex', marginBottom: 20 }}>
                                    <h4 style={{ fontWeight: 'bold' }}>{props.user.company_name} - {moment(currentMonth).format('MMMM YYYY')}</h4>
                                </div>
                                <div style={{ marginBottom: 20 }}>
                                    <h5 style={{ color: 'gray', textDecoration: 'underline', marginBottom: 5 }}>Total Receivable</h5>
                                    <div style={{ fontWeight: 'bold' }}>$ {totRec.toFixed(2)}</div>
                                </div>
                                <div style={{ marginBottom: 20 }}>
                                    <h5 style={{ color: 'gray', textDecoration: 'underline', marginBottom: 5 }}>Total Receivable By Prior Auth Status</h5>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <div>Prior Auth found</div>
                                            <div style={{ fontWeight: 'bold' }}>$ {priorAuthRec.toFixed(2)}</div>
                                        </div>
                                        <div style={{ marginLeft: 50 }}>
                                            <div>On DDD Voucher</div>
                                            <div style={{ fontWeight: 'bold' }}>$ {priorAuthVoucherRec.toFixed(2)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginBottom: 20 }}>
                                    <h5 style={{ color: 'gray', textDecoration: 'underline', marginBottom: 5 }}>Total Receivable By Payment Source</h5>
                                    <div style={{ display: 'flex' }}>
                                        <div>
                                            <div>NJMMIS</div>
                                            <div style={{ fontWeight: 'bold' }}>$ {NJMMISRec.toFixed(2)}</div>
                                        </div>
                                        <div style={{ marginLeft: 50 }}>
                                            <div>Voucher</div>
                                            <div style={{ fontWeight: 'bold' }}>$ {VoucherRec.toFixed(2)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <PieChart width={550} height={350}>
                                    {isAllZero(pieData) ? (
                                        <>
                                            <circle
                                                cx="225"
                                                cy="175"
                                                r="150"
                                                fill="none"
                                                stroke="#8884d8"
                                                strokeWidth="2"
                                                strokeDasharray="5,5"
                                            />
                                            <text x="225" y="175" textAnchor="middle" dominantBaseline="middle" fill='#8884d8'>
                                                No data to display
                                            </text>
                                        </>
                                    ) : (
                                        <Pie
                                            data={pieData}
                                            cx={225}
                                            cy={175}
                                            labelLine={false}
                                            outerRadius={150}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {pieData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    )}
                                    <Tooltip />
                                </PieChart>
                            </div>
                        </div>
                        <div style={{ marginBottom: 40 }}>
                            <h5 style={{ color: 'gray', textDecoration: 'underline', marginBottom: 5 }}>Total Receivable By Account Code</h5>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <div>ACTIVE PLAN</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {activePlanRec.toFixed(2)}</div>
                                </div>
                                <div style={{ marginLeft: 50 }}>
                                    <div>INITIAL PLAN</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {initialPlanRec.toFixed(2)}</div>
                                </div>
                                <div style={{ marginLeft: 50 }}>
                                    <div>PLAN CHANGE</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {planChangeRec.toFixed(2)}</div>
                                </div>
                                <div style={{ marginLeft: 50 }}>
                                    <div>PLAN TERMINATION</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {planTerminationRec.toFixed(2)}</div>
                                </div>
                                <div style={{ marginLeft: 50 }}>
                                    <div>PLAN RENEWED</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {planRenewedRec.toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginBottom: 20 }}>
                            <h5 style={{ color: 'gray', textDecoration: 'underline', marginBottom: 5 }}>Total Receivable By Payment Status</h5>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <div>Voucher Payment Received</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {claimVoucherRec.toFixed(2)}</div>
                                </div>
                                <div style={{ marginLeft: 50 }}>
                                    <div>Ready to Bill</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {readyToBillRec.toFixed(2)}</div>
                                </div>
                                <div style={{ marginLeft: 50 }}>
                                    <div>Processed as Primary</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {processedRec.toFixed(2)}</div>
                                </div>
                                <div style={{ marginLeft: 50 }}>
                                    <div>Released</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {releasedRec.toFixed(2)}</div>
                                </div>
                                <div style={{ marginLeft: 50 }}>
                                    <div>Denied</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {deniedRec.toFixed(2)}</div>
                                </div>
                                <div style={{ marginLeft: 50 }}>
                                    <div>Pending</div>
                                    <div style={{ fontWeight: 'bold' }}>$ {pendingRec.toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 20, marginLeft: -25 }}>
                        <BarChart width={1000} height={400} data={isAllZero(barData) ? [] : barData}>
                            {isAllZero(barData) ? <CartesianGrid strokeDasharray="3 3" stroke='#8884d8' /> : null}
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            {isAllZero(barData) ? (
                                <text x="500" y="200" textAnchor="middle" dominantBaseline="middle" fill='#8884d8'>
                                    No data to display
                                </text>
                            ) : (
                                <Bar dataKey="value" fill="#8884d8" />
                            )}
                        </BarChart>
                    </div>
                </div>
            </div>
        )
    }
};