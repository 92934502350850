import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { APIURL, CLIENT_ID, MSTENANT, SCOPE } from './config'
import { PublicClientApplication, EventType } from "@azure/msal-browser";


// Add the external resources for Form.io
const addScript = (src) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    document.head.appendChild(script);
};

const addStylesheet = (href) => {
    const link = document.createElement('link');
    link.href = href;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
};

const msalInstance = new PublicClientApplication({
    auth: {
        clientId: CLIENT_ID,
        authority: MSTENANT
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    }
});

await msalInstance.initialize();

// Include external resources
addScript('https://cdn.form.io/formiojs/formio.full.min.js');
addStylesheet('https://cdn.form.io/formiojs/formio.full.min.css');
// addStylesheet('https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css');
// addStylesheet('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');

ReactDOM.render(<BrowserRouter><App instance={msalInstance}/></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
