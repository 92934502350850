import React from 'react';
import FinancialSummary from './FinancialSummary/FinancialSummary';
import Billable from './Billable/Billable';
import PreAuth from './PreAuth/PreAuth';
import ChangeCapture from './ChangeCapture/ChangeCapture';
import Claims from './Claims/Claims';
import AccountsReceivable from './AccountsReceivable/AccountsReceivable';
import Reports from '../Reports/Reports';
import SignatureCap from './SignatureCap/SignatureCap';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';
import Remittances from './Remittances/Remittances';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })
class Billing extends React.Component {
	constructor(props) {
		super(props);
		// key = [(0: Billable), (1: Prior Authorization), (2: Change Capture), (3: Claims), (4: Accounts Receivable), (5: Reports)]
		//this.state = {key: 0}; // By default, Billable is shown.
		this.handleClickFinancialSummary = this.handleClickFinancialSummary.bind(this);
		this.handleClickBillable = this.handleClickBillable.bind(this);
		this.handleClickPriorAuthorization = this.handleClickPriorAuthorization.bind(this);
		this.handleClickChangeCapture = this.handleClickChangeCapture.bind(this);
		this.handleClickClaims = this.handleClickClaims.bind(this);
		this.handleClickAccountsReceivable = this.handleClickAccountsReceivable.bind(this);
		this.handleClickBillingReports = this.handleClickBillingReports.bind(this);
		this.handleClickSignatureCanvas = this.handleClickSignatureCanvas.bind(this);
		this.handleClickRemittances = this.handleClickRemittances.bind(this);
	}

	state = {
		key: 8,
		showModal: false,
	}

	// Handles the onClick event on each of the SideNavBar Nav.Links [(0: Billable), (1: Prior Authorization), (2: Change Capture), (3: Claims), (4: Accounts Receivable), (5: Reports)]
	handleClickFinancialSummary = () => {
		this.setState({ key: 8 });
	}

	handleClickBillable = () => {
		this.setState({ key: 0 });
	}

	handleClickPriorAuthorization = () => {
		this.setState({ key: 1 });
	};

	handleClickRemittances = () => {
		this.setState({ key: 7 });
	}

	handleClickChangeCapture = () => {
		this.setState({ key: 2 });
	};

	handleClickClaims = () => {
		this.setState({ key: 3 });
	};

	handleClickAccountsReceivable = () => {
		this.setState({ key: 4 });
	}

	handleClickBillingReports = () => {
		this.setState({ key: 5 });
	};

	handleClickSignatureCanvas = () => {
		this.setState({ showModal: true });
		this.setState({ key: 6 });
	}

	handleToggleModal = () => {
		this.setState({ showModal: !this.state.showModal });
	}

	render() {
		// SideNavBar Logic for: Billable | Prior Authorization | Change Capture | Claims | Accounts Receivable | Reports
		let renderComponent = '';
		if (this.state.key === 0) {
			renderComponent = <Billable checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
		} else if (this.state.key === 1) {
			renderComponent = <PreAuth checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
		} else if (this.state.key === 2) {
			renderComponent = <ChangeCapture checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
		} else if (this.state.key === 3) {
			renderComponent = <Claims checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
		} else if (this.state.key === 4) {
			renderComponent = <AccountsReceivable checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
		} else if (this.state.key === 5) {
			renderComponent = <Reports {...this.props} comp_id={this.props.comp_id} email={this.props.user.email} source="fromBilling" />;
		} else if (this.state.key == 6) {
			renderComponent = <SignatureCap checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />
		} else if (this.state.key === 7) {
			renderComponent = <Remittances checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
		} else if (this.state.key === 8) {
			renderComponent = <FinancialSummary checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />
		}
		else {
			renderComponent = <Billable checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
		}

		return (
			<div>
				<div>
					<Row>
						{/* Left Sub-Navbar Linking to other Billing pages. */}
						<Col lg={3} md={4} sm={6} style={{ maxWidth: '300px' }}>
							<Tab.Container>
								<Nav className='flex-column'>
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickFinancialSummary}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-file-invoice'
												></i>
											</span>
											Financial Summary
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickAccountsReceivable}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-file-invoice'
												></i>
											</span>
											Accounts Receivable
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickPriorAuthorization}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-file-alt'
												></i>
											</span>
											Prior Authorization
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickChangeCapture}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-file-alt'
												></i>
											</span>
											Change Capture
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickClaims}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
														marginLeft: '-4px'
													}}
													className='fas fa-paperclip'
												></i>
											</span>
											Claims
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickRemittances}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
														marginLeft: '0px'
													}}
													className='fas fa-file-invoice-dollar'
												></i>
											</span>
											Remittances
										</Nav.Link>
									</Nav.Item>
									{/* <Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickAccountsReceivable}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-file-invoice'
												></i>
											</span>
											Accounts Receivable
										</Nav.Link>
									</Nav.Item> */}
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickBillingReports}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-file-pdf'
												></i>
											</span>
											Reports
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Tab.Container>
							{this.state.key === 3 && <div style={{ width: '100%', marginTop: '50px' }}>
								<div style={{ fontFamily: 'Lato', backgroundColor: 'white', border: '1px solid rgb(204, 204, 204)', borderRadius: '15px', padding: '10px 10px 10px 15px' }}>
									<h6><b>How to Upload</b></h6>
									<div>
										<p style={{ display: 'inline', marginRight: '55px' }}>1. Download the template</p>
										<a style={{ display: 'inline', marginRight: '10px', fontSize: '12px' }} href="/files/ClaimsUploadTemplate.csv"><i className='far fa-file'></i> claims.csv</a>
									</div>
									<div>
										<p style={{ display: 'inline', marginRight: '10px' }}>2. Fill out the template</p>
										<p style={{ display: 'inline', fontSize: '13px', color: 'grey', }}><i>If using Excel, make sure to export or save as a .csv</i></p>
									</div>
									<div>
										<p style={{ display: 'inline', marginRight: '10px' }}>3. Upload the file</p>
										<p style={{ display: 'inline', fontSize: '13px', color: 'grey', }}><i>Click the upload button and choose a file</i></p>
									</div>
								</div>
							</div>}
							{this.state.key === 1 && <div style={{ width: '100%', marginTop: '50px' }}>
								<div style={{ fontFamily: 'Lato', backgroundColor: 'white', border: '1px solid rgb(204, 204, 204)', borderRadius: '15px', padding: '10px 10px 10px 15px' }}>
									<h6><b>How to Upload</b></h6>
									<div>
										<p style={{ display: 'inline', marginRight: '55px' }}>1. Download the template</p>
										<a style={{ display: 'inline', marginRight: '10px', fontSize: '12px' }} href="/files/pre_auth.csv"><i className='far fa-file'></i> pre_auth.csv</a>
									</div>
									<div>
										<p style={{ display: 'inline', marginRight: '10px' }}>2. Fill out the template</p>
										<p style={{ display: 'inline', fontSize: '13px', color: 'grey', }}><i>If using Excel, make sure to export or save as a .csv</i></p>
									</div>
									<div>
										<p style={{ display: 'inline', marginRight: '10px' }}>3. Upload the file</p>
										<p style={{ display: 'inline', fontSize: '13px', color: 'grey', }}><i>Click the upload button and choose a file</i></p>
									</div>
								</div>
							</div>}
						</Col>
						<Col>{renderComponent}</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default Billing;