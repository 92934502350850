import React, { Component } from 'react'
import AppointmentCard from './AppointmentCard'
import { APIURL } from '../../config';
import axios from 'axios'
import Calendar from './AppointmentCalendar'
import moment from 'moment'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './top_appointment.css'
import MessagePage from '../MessagePages/MessagePage'
import FontAwesome from 'react-fontawesome'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IconButton } from '@mui/material';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })
var sha1 = require('sha1');

class TopAppointments extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appointments: [],
            selectedDay: '',
            selectedAppointment: 0,
            isLoading: false,
            totalAppointments: 0,
            successStatus: false,
            errorStatus: false,
            warnStatus: false,
            message: '',
            loggedToSegment: false,
            isCollapsed: this.props.isCaseManagement ? true : false
        }
        this.getTop5Appointments = this.getTop5Appointments.bind(this)
        this.selectAppointment = this.selectAppointment.bind(this)
        this.cancelAppoinntment = this.cancelAppoinntment.bind(this)
        this.rescheduleAppointment = this.rescheduleAppointment.bind(this)
        this.checkIn = this.checkIn.bind(this)
        this.checkOut = this.checkOut.bind(this)
    }

    toggleCollapse = () => {
        this.setState(prevState => ({
          isCollapsed: !prevState.isCollapsed
        }));
    };

    logToSegment = async () => {
        if(this.state.loggedToSegment) {
          return;
        }
        let page = String(window.location.href).split("/").pop()
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page,this.props.user.emp_id)
        analytics.page(page + " : Upcoming Appointments", {
            title:page + " : Upcoming Appointments",
            path:page + " : Upcoming Appointments",
        }, {
            userId:String(this.props.user.emp_id),
        })
        console.log("logged to segment successfully")
        this.setState({ loggedToSegment : true });
    }

    componentDidMount() {
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        this.getTop5Appointments()
        this.logToSegment();
        //   this.setState({isLoading: false})
    }



    showWarnMod = () => {
        this.setState({ warnStatus: !this.state.warnStatus, message: 'Already Checked In to a different task. Please Check Out and try again.' })
    }

    componentDidUpdate(prevProps, _) {
        if (prevProps.user !== this.props.user) {
            this.getTop5Appointments()
        }
    }
    async getTop5Appointments() {
        // this.setState({isLoading: true})
        const com_id = this.props.user.emp_company_id
        const emp_id = this.props.user.emp_id
        const lastN = 10
        this.setState({ isLoading: true })
        let appointments = await axios.get(`${APIURL}/task`, { params: { emp_id } })
        let sessionAppointments = await axios.get(`${APIURL}/master_schedule/employee/${emp_id}`)
        sessionAppointments = sessionAppointments.data
        sessionAppointments = sessionAppointments.filter((session) => moment(session.start_time).isAfter(moment()) && moment(session.end_time).diff(moment(), 'days') <= 90)
        sessionAppointments = sessionAppointments.map((session) => {
            return {
                ...session,
                tas_is_active: 0,
                tas_start_date: session.start_time,
                tas_end_date: session.end_time,
                tas_title: session.schedule_name,
                tas_notes: session.notes
            }
        })
        console.log('Session Appointments', sessionAppointments);
        this.setState({ isLoading: false })
        appointments = [...appointments.data, ...sessionAppointments]
        appointments = appointments.filter((tas) => tas.tas_is_active === 0 && moment(tas.tas_start_date).isBetween(moment().subtract(90, 'd'), moment().add(90, 'd'))).sort((a, b) => parseInt(moment(a.tas_start_date).format('YYYYMMDDHHmm')) - parseInt(moment(b.tas_start_date).format('YYYYMMDDHHmm'))).slice(0, lastN)
        console.log('Appointments', appointments)

        if (appointments && appointments.length > 0) {
            let new_appointments = []
            appointments.forEach(item => {
                let date = new Date()
                console.log('AAA', item.tas_start_date);
                let stringDate = item.tas_start_date.split('T')[0]
                let appointmentDate = moment(stringDate).toDate()
                // if(appointmentDate >= date) {

                // }
                let day = appointmentDate === date ? 'Today' : `${this.getFormattedDate(appointmentDate)}`
                let entry_index = new_appointments.findIndex(entryItem => entryItem.day === day)
                if (entry_index < 0) {
                    let entry = {}
                    entry.day = day
                    entry.details = []
                    entry.details.push(item)
                    new_appointments.push(entry)
                } else {
                    let entry = new_appointments[entry_index]
                    entry.details.push(item)
                    new_appointments[entry_index] = entry
                }
            })

            console.log(new_appointments)
            // debugger
            this.setState({
                appointments: new_appointments,
                // isLoading: false,
                selectedDay: new_appointments.length > 0 ? new_appointments[0].day : '',
                selectedAppointment: 0,
                totalAppointments: appointments.length,
                dates: new_appointments.length > 0 ? appointments.map(item => item.tas_start_date.split('T')[0]) : []
            })

        }
    }
    getDayFromDate(date) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const indexOfDay = date.getDay()
        return days[indexOfDay]
    }
    getFormattedDate(date) {
        const day = this.getDayFromDate(date)
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const month = months[date.getMonth()]
        const date_num = date.getDate()
        return `${day}, ${month} ${date_num}`
    }
    selectAppointment(selectedDay, selectedAppointment) {
        this.setState({
            selectedDay,
            selectedAppointment
        })
    }

    async cancelAppoinntment(id) {
        // console.log(this.props)
        // make request to delete 
        let task = this.state.appointments.filter((app) => app.details.filter((tas) => tas.tas_id === id).length !== 0)[0].details.filter((tas) => tas.tas_id === id)[0];
        let tTitle = task.tas_title;
        let tProject = task.tas_pro_id;
        // console.log(tTitle)
        let result = await axios.delete(APIURL + '/task', { data: { id: id, empId: this.props.user.emp_id, empName: this.props.user.emp_name, companyId: this.props.user.comp_id, tTitle: tTitle, tProject: tProject, taskId: id, tDesc: task.tas_description} })
        //  let result = {data: {code : 500}}
        // console.log(result.data)
        if (result.data.code === 200) {
            let appointments = this.state.appointments.map((app) => {
                if (!!app.details.find((tas) => tas.tas_id === id)) {
                    app.details = app.details.filter((task) => task.tas_id !== id)
                    console.log(app)
                }
                // change the checked in status 
                return app
            })
            this.setState({ appointments })
            this.setState({ successStatus: true, message: 'Deleted Task Successfully' })
        } else {
            console.log('Cancel Appointment Failed')
            this.setState({ errorStatus: true, message: 'Failed to Delete Task! Please Try Again!!' })
        }
    }

    async rescheduleAppointment(id, from, to) {
         console.log(this.state)
        let task = this.state.appointments.filter((app) => app.details.filter((tas) => tas.tas_id === id).length !== 0)[0].details.filter((tas) => tas.tas_id === id)[0];
        var data = {
            empId : this.props.user.emp_id,
            empName: this.props.user.emp_name,
            company_id: this.props.user.comp_id,
            tTitle: task.tas_title,
            tDesc: task.tas_description,
            tProject: task.tas_pro_id,
            tPreReq: task.tas_prequisites_task,
            tType: task.tas_type,
            tStrtDate: moment(new Date(from)).format('YYYY-MM-DD HH:mm:ss'),
            tEndDate:  moment(new Date(to)).format('YYYY-MM-DD HH:mm:ss'),
            tStartTime: moment(new Date(from)).format('YYYY-MM-DD HH:mm:ss'),
            tEndTime: moment(new Date(to)).format('YYYY-MM-DD HH:mm:ss'),
            tLoc: task.tas_loc_id,
            tAssTo: task.tas_emp_id,
            tPriority: task.tas_priority,
            tIsBillable: task.tas_billable_by_default,
            tNotes: task.tas_notes,
            taskId: id,
            user_id: this.props.user.emp_id
        };
        let result = await axios.put(APIURL + '/task', data)
        if (result.data.code === 200) {
            let appointments = this.state.appointments.map((app) => {
                if (!!app.details.find((tas) => tas.tas_id === id)) {
                    app.details = app.details.map((task) => {
                        if (task.tas_id === id) {
                            task.tas_start_date = moment(new Date(from)).format('YYYY-MM-DD HH:mm:ss')
                            task.tas_end_date = moment(new Date(to)).format('YYYY-MM-DD HH:mm:ss')
                            task.tas_start_time = moment(new Date(from)).format('YYYY-MM-DD HH:mm:ss')
                            task.tas_end_time = moment(new Date(to)).format('YYYY-MM-DD HH:mm:ss')
                        }
                        return task
                    })
                }
                return app
            })
            this.setState({ appointments })
            this.setState({ successStatus: true, message: 'Rescheduled Task Successfully' })
        } else {
            console.log('Reschedule Appointment Failed')
            this.setState({ errorStatus: true, message: 'Failed to Reschedule Task! Please Try Again!!' })
        }
    }


    async checkIn(id) {
        let app = this.state.appointments.find((app) => !!app.details.find((tas) => tas.tas_id === id))
        let taskTitle = this.state.appointments.find((app) => app.details.find((tas) => tas.tas_id === id)).details[0].tas_title;
        let appindex = app.details.findIndex((task) => task.tas_id === id)
        // console.log(appindex)
        if (!app) { return }
        let data = { projId: app.details[appindex].pro_id, empName: this.props.user.emp_name, empId: app.details[appindex].tas_emp_id, taskTitle:app.details[appindex].tas_title, companyId: this.props.user.comp_id , taskId: app.details[appindex].tas_id, locId: app.details[appindex].tas_loc_id, emploc_lat: app.details[appindex].locInfo.find((ele) => ele.loc_id === app.details[appindex].tas_loc_id).loc_lat, emploc_lon: app.details[appindex].locInfo.find((ele) => ele.loc_id === app.details[appindex].tas_loc_id).loc_lon, date: moment().format('YYYY-MM-DDTHH:mm:ss') }
        let result = await axios.post(APIURL + '/locate/empLoc', { ...data })
        // let result = {data: {code : 200}}
        if (!!result && result.data.code === 200) {
            let appointments = this.state.appointments.map((app) => {
                // console.log(app)
                if (!!app.details.find((tas) => tas.tas_id === id)) {
                    app.details[appindex] = { ...app.details[appindex], checkin: true }
                    console.log(app, data, appindex, result.data)
                }
                // change the checked in status
                return app
            })
            let sessionID = window.localStorage.getItem('Fw4_access_Token')
            var hash = sha1(sessionID);
            var log_data = {
                email: this.props.user.email,
                actionType: 'Task check in', 
                actionSubType: taskTitle + ' - ' + id,
                sessionID: hash
            }
            let msg = await axios.post(APIURL + "/activity_log", log_data);
            //set the final state
            this.setState({ appointments })
            this.setState({ successStatus: true, message: 'Checked In to Task Successfully' })
        } else {
            this.setState({ errorStatus: true, message: 'Something went wrong while Checking you into Task. Please Try Again!!' })
            console.log('Error While Updating Entry')
        }
    }

    async checkOut(id) {
        let app = this.state.appointments.find((app) => !!app.details.find((tas) => tas.tas_id === id))
        let taskTitle = this.state.appointments.find((app) => app.details.find((tas) => tas.tas_id === id)).details[0].tas_title;
        let appindex = app.details.findIndex((task) => task.tas_id === id)
        if (!app) { return }
        let data = { projId: app.details[appindex].pro_id, checkoutLocId:app.details[appindex].locInfo.find((ele) => ele.loc_id === app.details[appindex].tas_loc_id).loc_id ,empName: this.props.user.emp_name, empId: app.details[appindex].tas_emp_id, taskTitle:app.details[appindex].tas_title, companyId: this.props.user.comp_id , taskId: app.details[appindex].tas_id, date: moment().format('YYYY-MM-DDTHH:mm:ss') }
        let result = await axios.put(APIURL + '/locate/empLoc', { ...data });
        // let result = {data: {code : 200}}
        if (!!result && result.data.code === 200) {
            let appointments = this.state.appointments.map((app) => {
                // change the checked in status and the is active status
                if (!!app.details.find((tas) => tas.tas_id === id)) {
                    app.details = app.details.filter((task) => task.tas_id !== id)
                    // app.details[appindex] = {...app.details[appindex], checkin: false, tas_is_active: 1}
                    console.log(app, data, appindex, result.data)
                }
                return app
            })
            let sessionID = window.localStorage.getItem('Fw4_access_Token')
            var hash = sha1(sessionID);
            var log_data = {
                email: this.props.user.email,
                actionType: 'Task check out',
                actionSubType: taskTitle + ' - ' + id,
                sessionID: hash
            }
            let msg = await axios.post(APIURL + "/activity_log", log_data);
            this.setState({ appointments })
            this.setState({ successStatus: true, message: 'Checked Out of Task Successfully' })
        } else {
            this.setState({ errorStatus: true, message: 'Something went wrong while Checking you Out of Task. Please Try Again!!' })
            console.log('Error While Updating Entry')
        }

    }

    render() {
        const { isCollapsed } = this.state;
        return (
            <div className='patient_top_appointments' style={{ marginTop: '5px', maxHeight: isCollapsed ? '100px' : 'none' }}>
                <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={this.toggleCollapse} >
                    <div>
                        <div style={{ fontSize: '20px', whiteSpace: 'nowrap' }}>UPCOMING APPOINTMENTS</div>
                        <div className='patient_top_appointments_number'>Your next {this.state.totalAppointments} appointments.</div>
                    </div>
                    <IconButton aria-label="toggle collapse" style={{ padding: 0 }}>
                        <ExpandMoreIcon style={{ transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)' }} />
                    </IconButton>
                </div>
                {!isCollapsed && (
                    <div style={{ display: 'flex', flexDirection: "row", width: '100%' }}>
                        <div style={{ marginTop: '10px', width: '72%' }}>
                            {!!this.state.isLoading &&
                                <div style={{ height: '100%', width: '100%', margin: 10 }} className='d-flex flex-row justify-content-center align-items-center'>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!this.state.isLoading && this.state.appointments.length === 0 &&
                                <div style={{ margin: 30, width: '10', height: '100%' }} className='d-flex flex-column justify-content-center align-items-center'>
                                    {<MessagePage title="No Appointments Found" text="We could not find any upcoming appointments for this company." icon="fas fa-calendar"></MessagePage>}
                                </div>
                            }
                            {!this.state.isLoading && this.state.appointments.length !== 0 && <AppointmentCard
                                canCheckIN={this.state.appointments.every((tas) => ((tas.details.every((task) => (!task.checkin)))))}
                                appointments={this.state.appointments}
                                selectedDay={this.state.selectedDay}
                                selectedAppointment={this.state.selectedAppointment}
                                selectAppointment={this.selectAppointment}
                                cancelAppoinntment={this.cancelAppoinntment}
                                rescheduleAppointment={this.rescheduleAppointment}
                                checkIn={this.checkIn}
                                checkOut={this.checkOut}
                                showWarnMod={this.showWarnMod}
                                user={this.props.user}
                            />}
                        </div>
                        <div className='calendar_container' style={{ maxWidth: '28%', height: '100%' }}>
                            <Calendar
                                dates={this.state.dates}
                                appointments={this.state.appointments}
                                selectedDay={this.state.selectedDay}
                                selectedAppointment={this.state.selectedAppointment}
                                selectAppointment={this.selectAppointment}
                            />
                        </div>
                    </div>
                )}
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={this.state.successStatus} autoHideDuration={6000} onClose={() => this.setState({ successStatus: false })}>
                    <MuiAlert onClose={() => this.setState({ successStatus: false })} severity="success">
                        {this.state.message}
                    </MuiAlert>
                </Snackbar>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={this.state.errorStatus} autoHideDuration={6000} onClose={() => this.setState({ errorStatus: false })}>
                    <MuiAlert onClose={() => this.setState({ errorStatus: false })} severity="error">
                        {this.state.message}
                    </MuiAlert>
                </Snackbar>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={this.state.warnStatus} autoHideDuration={6000} onClose={() => this.setState({ warnStatus: false })}>
                    <MuiAlert onClose={() => this.setState({ warnStatus: false })} severity="warning">
                        {this.state.message}
                    </MuiAlert>
                </Snackbar>
            </div>
        )
    }
}

export default TopAppointments
