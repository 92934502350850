import React, { Component } from 'react';
// import moment from 'moment';
import InfoBox from './InfoBox'
import {Modal, Button} from 'react-bootstrap'

class ConDetails extends Component {
    state = {
        open: false
    }
    handleDelConfirmModal = () => {
        this.setState({open: !this.state.open})
    }
    render() {
        return (
            <div className="jumbotron jumbotron-fluid bg-white pt-1 pb-1">
                <div className="container m-1">
                    <div style={{ backgroundColor: '#EBF8FF', borderRadius: 10 }} className="d-flex flex-row justify-content-start m-0 p-0">
                        <div style={{ border: '1px solid #3FA7D6', borderRadius: 50, margin: 10, width: '4rem', height: '4rem' }}>
                            <p style={{ fontSize: 30 }} className='text-center mt-2 font-weight-bold'>{this.props.data.con_first_name.split('')[0]}{this.props.data.con_last_name.split('')[0]}</p>
                        </div>
                        <div className="d-flex flex-column justify-content-between mx-3 w-75">
                            <h3 className="m-0 mt-3">{this.props.data.con_first_name} {this.props.data.con_last_name}</h3>
                            <div className="d-flex flex-row m-0">
                                <p className="mr-2">DESIGNATION:</p><p>{this.props.data.con_designation}</p>
                            </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center">
                            <i className="fas fa-edit m-2" style={{ marginLeft: "20px", cursor: "pointer", color: '#3FA7D6' }} onClick={() => { this.props.onEdit() }}></i>
                            <i className="fas fa-trash m-2" style={{ marginLeft: "20px", cursor: "pointer", color: 'red' }} 
                            // onClick={() => { this.props.onDel(this.props.data.con_id) }}
                            onClick={()=> {this.handleDelConfirmModal()}}
                            ></i>
                        </div>
                    </div>
                    <Modal show={this.state.open} onHide={this.handleDelConfirmModal}>
                        <Modal.Header closeButton>
                        <Modal.Title>Delete Contact</Modal.Title>
                        </Modal.Header>
                        <center><Modal.Body>Are you sure you want to delete this contact?</Modal.Body></center>
                        {/* <Modal.Footer> */}
                        <div className='d-flex flex-row justify-content-center m-3'>
                        <Button className='mr-1' variant="secondary" onClick={this.handleDelConfirmModal}>
                            Back
                        </Button>
                        <Button className='mr-1' variant="primary" onClick={()=> { 
                            this.props.onDel(this.props.data.con_id)
                            this.handleDelConfirmModal()
                            }
                        }
                        >
                            Yes
                        </Button>
                        </div>
                        {/* </Modal.Footer> */}
                    </Modal>
                    <InfoBox data={this.props.data} />
                </div>
            </div>
        )
    }
}

export default ConDetails;