import React, { useState, useEffect } from 'react';
import Type from '../Components/Type';
import axios from 'axios';
import { APIURL } from '../../../config';

const EmployeeTaskType = (props) => {
    const [typeList, setTypeList] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);

    useEffect(() => {
        setTypeList(props.typeList.map((type) => {
            return { title: type.EntityValue, id: type.ddID, deletable: type.deletable, is_Active: type.is_Active }
        }));
        setSelectedTypes(props.typeList.filter((type) => type.is_Active === 0 || type.is_Active === 3).map((type) => {
            return { title: type.EntityValue, id: type.ddID, deletable: type.is_Active === 3 || type.is_Active === 4, is_Active: type.is_Active }
        }));
    }, [props.typeList]);

    const handleSave = async (selectedTypes, newTypeList) => {
        try {
            let data = {
                employee_id: props.user_id,
                taskData: newTypeList.map((type) => {
                    return { taskTypeID: type.id, ddID: type.id }
                }),
                status: 1,
                comp_id: props.comp_id,
            }
    
            let response = await axios.delete(APIURL + "/employees/EmployeeTasks", { data: data });

            data.taskData = selectedTypes.map((type) => {
                return { taskTypeID: type.id, ddID: type.id }
            });

            response = await axios.post(APIURL + "/employees/EmployeeTasks", data);
            setSelectedTypes(selectedTypes);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Type
            leftColumnSize={props.leftColumnSize}
            rightColumnSize={props.rightColumnSize}
            typeList={typeList}
            selectedTypes={selectedTypes}
            setTypeList={setTypeList}
            handleSave={handleSave}
            title="Employee Task Type"
            description="What tasks do you usually perform?"
            subTitle="Types"
            disableAddNew
        />
    );
}

export default EmployeeTaskType;
