import * as React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../config";
import moment from "moment";
import { Box, Button, TextField } from "@mui/material";
// import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import './reports.css';

export default function CustomerNotesReport(props) {
    const [gridId, setGridId] = useState(64);
    const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(true);
    const [customerNotesData, setCustomerNotesData] = useState([]);
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));

    useEffect(() => {
        async function fetchData() {
            let customerNotesData = await axios.get(APIURL + "/customers/getAssociatedCustomersAndTaskNotes", {
                params: { compID: props.comp_id, empID: props.user.emp_id, empType: props.user.type, month: moment(currentMonth).format('YYYYMM') },
            });

            let rows = customerNotesData.data.map((data, idx) => {
                let customerNotesData = {
                    id: idx,
                    emp_id: data.emp_id,
                    emp_last_name: data.emp_last_name,
                    emp_first_name: data.emp_first_name,
                    pro_id: data.pro_id,
                    pro_title: data.pro_title,
                    cus_name: data.cus_name,
                    comment_id: data.comment_id,
                    cus_comments: data.tas_is_active !== 2 ?
                        isJSON(data.comment_text) ?
                            JSON.parse(data.comment_text)?.blocks.map(block => block.text).join(' ') :
                            data.comment_text :
                        null,
                    tas_id: data.tas_is_active !== 2 ? data.task_id : null,
                    tas_title: data.tas_is_active !== 2 ? data.tas_title : null,
                    tas_date: data.tas_is_active !== 2 ?
                        data.empLocdate ?
                            moment(data.empLocdate).format('MM/DD/YYYY')
                            : ''
                        : null,
                    taskDuration: data.tas_is_active !== 2 ? data.taskDuration : null,
                };
                return customerNotesData;
            });

            setCustomerNotesData(rows);
            setIsLoading(false);
        }

        fetchData();
    }, [currentMonth]);

    const columns = [
        { field: "id", headerName: "ID", hide: true },
        { field: "cus_name", headerName: "Customer Name", width: 200 },
        { field: "emp_id", headerName: "Employee ID", hide: true },
        { field: "emp_last_name", headerName: "Case Manager Last Name", width: 210 },
        { field: "emp_first_name", headerName: "Case Manager First Name", width: 210 },
        { field: "pro_id", headerName: "Case ID", hide: true },
        { field: "pro_title", headerName: "Case Name", hide: true },
        { field: "tas_id", headerName: "Task ID", hide: true },
        { field: "tas_title", headerName: "Task Title", width: 200 },
        { field: "tas_date", headerName: "Task Date", width: 100 },
        {
            field: "taskDuration",
            width: 75,
            renderHeader: (params) => {
                return (
                    <div
                        style={{
                            maxHeight: "inherit",
                            width: "100%",
                            whiteSpace: "initial",
                            lineHeight: "16px",
                            verticalAlign: "bottom",
                        }}
                    >
                        Duration (Hours)
                    </div>
                );
            },
        },
        { field: "comment_id", headerName: "Comment ID", hide: true },
        {
            field: "cus_comments",
            headerName: "Notes",
            width: 800,
            flex: 1,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    minHeight: '24px',
                    width: '100%',
                    lineHeight: '1.5',
                    padding: '5px',
                    boxSizing: 'border-box',
                }}>
                    {params.value}
                </div>
            )
        },
    ];

    const defaultSettings = {
        "pinnedColumns": {},
        "columns": {
            "columnVisibilityModel": {
                "id": false,
                "emp_id": false,
                "pro_id": false,
                "pro_title": false,
                "tas_id": false,
                "comment_id": false
            },
            "orderedFields": [
                "id",
                "cus_name",
                "emp_id",
                "emp_last_name",
                "emp_first_name",
                "pro_id",
                "pro_title",
                "tas_id",
                "tas_title",
                "tas_date",
                "taskDuration",
                "comment_id",
                "cus_comments"
            ],
            "dimensions": {
                "emp_last_name": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 210
                },
                "emp_first_name": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 210
                },
                "cus_name": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 200
                },
                "tas_title": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 200
                },
                "tas_date": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 100
                },
                "taskDuration": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 75
                },
                "cus_comments": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 800,
                    "flex": 1
                }
            }
        },
        "preferencePanel": {
            "open": false
        },
        "filter": {
            "filterModel": {
                "items": [],
                "linkOperator": "and",
                "quickFilterValues": [],
                "quickFilterLogicOperator": "and"
            }
        },
        "sorting": {
            "sortModel": [
                {
                    "field": "cus_name",
                    "sort": "asc"
                }
            ]
        },
        "pagination": {
            "pageSize": 100,
            "page": 0
        },
        "columnVisibilityModel": {
            "id": false,
            "emp_id": false,
            "pro_id": false,
            "pro_title": false,
            "tas_id": false,
            "comment_id": false
        },
        "selectionModel": [],
        "density": "standard"
    }

    const isJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    function onDateChange(newValue) {
        setCurrMonth(moment(newValue).startOf("month"));
    }

    return (
        <div style={{ height: '80vh', overflow: 'hidden' }}>
            {isLoading ?
                <div className="d-flex flex-row w-100 justify-content-center mt-5">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
                :
                <div>
                    <div id="claims-main"
                        style={{ width: "100%", backgroundColor: "#fafafa" }}
                        className="d-flex row justify-content-start">
                        <div
                            style={{ maxWidth: "100%", marginTop: 15 }}
                            className="col-lg-12 col-sm-7 d-flex flex-column justify-content-start"
                        >
                            <div className="flex-container" style={{ display: "flex" }}>
                                <div
                                    className="flex-item"
                                    style={{
                                        flexDirection: "row-reverse",
                                        height: "40px",
                                        marginTop: "15px",
                                        marginBottom: "20px",
                                        marginLeft: "20px",
                                    }}
                                >
                                    <Button
                                        size="large"
                                        className="DayButton"
                                        onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                                    >
                                        <i className="fas fa-arrow-left"></i>
                                    </Button>
                                    <LocalizationProvider
                                        class="DatePickerParent"
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <DatePicker
                                            label="Select Date"
                                            views={["year", "month"]}
                                            value={currentMonth}
                                            onChange={(newValue) => {
                                                this.onDateChange(newValue);
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="standard" />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    <Button
                                        size="large"
                                        className="DayButton"
                                        onClick={() => onDateChange(currentMonth.add(1, "month"))}
                                    >
                                        <i className="fas fa-arrow-right"></i>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '100%', height: "calc(100vh - 300px)" }}>
                            <ControlledDataGrid
                                apiRef={apiRef}
                                gridId={gridId}
                                user={props.user}
                                defaultSettings={defaultSettings}
                                initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
                                getRowId={(row) => row.id}
                                rows={customerNotesData}
                                columns={columns}
                                // components={{
                                //     Toolbar: GridToolbar,
                                // }}
                                checkboxSelection={false}
                                disableSelectionOnClick
                                getRowHeight={() => 'auto'}
                                sx={{
                                    '& .MuiDataGrid-row:nth-of-type(even)': {
                                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                                        backgroundColor: 'white',
                                    },
                                    '& .MuiDataGrid-cell': {
                                        whiteSpace: 'normal',
                                        lineHeight: 'normal',
                                        display: 'flex',
                                        alignItems: 'center',
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                    },
                                }}
                            />
                        </Box>
                    </div>
                </div>
            }
        </div>
    );
};