import React, { Component } from 'react';
import HandyCard from '../../HandyCard/HandyCard';
import { Form, FormControl, Button } from 'react-bootstrap';
import axios from 'axios'
import moment from 'moment'
import {APIURL} from '../../../config'
import dd from '../../../manifestdd.json'

class Notes extends Component{
    state={
        isLoading:true,
        proj_comments: [],
        comments_display: [],
        comment_to_add: '',
        searchTerm: '',
        filter: {class: null, specifier: null}
    }
    
    async componentDidMount(){
        // this.setState({isLoading: true})
        let commentList = await axios.get(APIURL+"/comments", {params: {pro_id: this.props.projectId}})
        let taskList = await axios.get(APIURL+"/task", {params: {pro_id: this.props.projectId, com_id: this.props.comp_id}});
        taskList = taskList.data.filter(task => {
            if(task.tas_notes === "" || task.tas_notes === null || task.tas_notes === undefined || task.tas_notes === 'undefined' || task.tas_notes === 'N/A') {
                return false
            }
            return true;
        })
        
        let commentData = commentList.data.map((comm)=>{
            const employee = this.props.allEmployees.find(emp => comm.emp_id === emp.id)
            comm = {...comm, employee_name: employee.employee_name, emp_type: employee.emp_type}
            return comm
        })
        
        let taskData = taskList.map((task)=>{
            const employee = this.props.allEmployees.find(emp => task.empInfo[0].emp_id === emp.id)
            console.log(task);
            let tas = {created_at: task.tas_created_at, comment_text: task.tas_notes, employee_name: task.tas_title, emp_type: "Task"}
            return tas
        })

        commentData = [...commentData, ...taskData]
        this.setState({proj_comments: commentData, comments_display: commentData, isLoading: false})
        this.sortBy('dateDsce')
    }

    commentHandler = (e) => {
        console.log(e.target.id, e.target.value)
        if(e.target.id === 'comment_input'){
            this.setState({comment_to_add: e.target.value})
        }else{
            this.setState({searchTerm: e.target.value})
        }
        // console.log(e.target.value)
    }

    addComment = async (e) => {
        e.preventDefault()
        // console.log(this.state.comment_to_add !== '')
        if(this.state.comment_to_add !== '' && this.state.comment_to_add !== ' ' && this.state.comment_to_add.replace(/\s/g, '').length !== 0){
            let data = {comment_text: this.state.comment_to_add, pro_id: this.props.projectId, emp_id: this.props.user.emp_id} 
            let addedComment = await axios.post(APIURL+'/comments', data)
            if(addedComment.data.code === 200){
                let new_Comment = addedComment.data.comment_data
                new_Comment = {...new_Comment, created_at: moment(), emp_type: this.props.user.type , employee_name: this.props.user.emp_name,isDeleted: false}
                this.setState({comment_to_add:'', comments_display: [new_Comment, ...this.state.comments_display]})
            }else{
                console.log('Did not add comment')
            }
        }else{
            console.log('Did not add comment')
            return
        }
    }

    searchCommResults = (e) => {
        e.preventDefault()
        // console.log('Do i run on a button click')
        var searchTerm = new RegExp(this.state.searchTerm, 'i')
        // console.log(searchTerm)
        let commSearchResults = []
        if(this.state.searchTerm === ''){
        commSearchResults = this.state.proj_comments.map((comm)=> {
            if(searchTerm.exec(comm.employee_name) || searchTerm.exec(comm.emp_type)){
                return comm
            }
            else{
                return null   
            }
        }).filter((comm)=> !!comm)
        }else{
            commSearchResults = this.state.comments_display.map((comm)=> {
                if(searchTerm.exec(comm.employee_name) || searchTerm.exec(comm.emp_type)){
                    return comm
                }
                else{
                    return null   
                }
            }).filter((comm)=> !!comm)

        }
        this.setState({comments_display: commSearchResults})
    }

    filterBy = () => {
        let commArr = this.state.comments_display
        if(this.state.filter.class === 'Emp Type'){
            commArr = commArr.filter((comm)=> comm.emp_type === +this.state.filter.specifier)
        }else if(this.state.filter.class === 'Employee'){
            commArr = commArr.filter((comm)=> comm.emp_id === +this.state.filter.specifier)
        }else if(this.state.filter.class === 'All'){
            commArr = this.state.proj_comments.filter((comm)=> !comm.isDeleted)
        }else{
            commArr = commArr.filter((comm)=> comm.taskType === this.state.filter.specifier)
        }
        this.setState({comments_display: commArr, filter: {...this.state.filter,class: null, specifier:null}})
    }

    filterHandler = (e) => {
        let id = e.target.id
        let val = e.target.value
        // console.log(id,val)
        if(id === 'filter1'){
            this.setState({filter: {...this.state.filter, class: val, specifier: null}})
        }else{
            this.setState({filter: {...this.state.filter, specifier: val}})
        }
    }

     // sort function
     sortBy = (param) => {
        let commentArr = this.state.comments_display
        if(param === 'dateAsce'){
            commentArr = commentArr.sort((commA,commB)=> moment(commA.created_at) - moment(commB.created_at))
        }else{
            commentArr = commentArr.sort((commA,commB)=> moment(commB.created_at) - moment(commA.created_at))
        }
        this.setState({comments_display: commentArr})
    }


    render(){
        // console.log(this.props.empType)
        let empList = this.props.allEmployees.map((emp)=> <option key={emp.id} value={emp.id}>{emp.employee_name}</option>)
        let typeList = dd.generic.employeeType.map((type)=> <option key={type.value} value={type.value}>{type.value}</option>)
        let content = this.state.isLoading?<div className='d-flex flex-row justify-content-center'><div className="spinner-border text-primary text-center border border-primary" role="status">
        <span className="sr-only">Loading...</span>
        </div></div>:(<div className='d-flex flex-column justify-content-center align-items-center' style={{height: '300px',margin: 5}}>
                        <img src='./projectgraphic.png' alt='CommentsTab' style={{width: '10rem', height: '10rem'}}/>
                        <h5 style={{fontFamily: 'Lato',fontSize: 18,marginTop: 28,marginBottom: 28}}>No Comments found </h5>
                        <p style={{fontFamily: 'Lato',fontSize: 14}} className='text-muted text-center w-50'>Add comments here keep getting updates from other employees regarding projects progress.</p>
                        </div>)
        if(this.state.comments_display.length !== 0){
            content = this.state.comments_display.map((comment)=>{
                return <HandyCard key={comment.comment_id} data={comment} projectNote={true}/> /* Change based on comment/note type here */
            })
        }
        return (
            <div className='TasksPage'>
                <div className="TopPanel"> {/* reused class from tasks page*/}
                    <Form onSubmit={(e)=> this.searchCommResults(e)} inline>
                        <FormControl style={{width: '400px'}} onChange={(e)=> this.commentHandler(e)} type="text" placeholder="Search" value={this.state.searchTerm} className="mr-sm-2 task_search_form" />
                        {/* TODO: Will style this button later as per design */}
                        <Button type="submit" variant="outline-primary" style={{ border: "solid 1px #95D0EB", marginLeft: "-8px", marginTop: "-5px", borderRadius: '0 5px 5px 0', paddingRight: "15px", paddingLeft: "15px" }}><i className="fas fa-search"></i></Button>
                    </Form>
                </div>
                <div className="Artifacts">
                    <ul>
                        <button style={{outline: 'none'}} type="button" className="btn btn-sm bg-white m-1" data-toggle="modal" data-target="#staticBackdropA">
                        <i className="fas fa-filter"></i> Filters
                        </button>
                        <div className="dropdown m-1">
                        <button style={{outline: 'none'}} className="btn btn-sm dropdown-toggle bg-white" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span><i className="fas fa-sort-amount-up"></i></span> SORT BY
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <button onClick={()=> this.sortBy('dateDsce')} className="dropdown-item" type="button">Latest</button>
                            <button onClick={()=> this.sortBy('dateAsce')} className="dropdown-item" type="button">Older</button>
                        </div>
                        </div>
                    </ul>
                </div>
                <div>
                    <div className='overflow-auto' style={{height: '40rem', margin: 10}}>
                    {content}
                    </div>
                    {/* <div className="input-group mb-3"> */}
                    <Form className='mb-3' inline onSubmit={(e)=> this.addComment(e)}>
                    <input style={{width: '80%'}} onChange={(e)=> this.commentHandler(e)} id="comment_input" value={this.state.comment_to_add} type="text" className="form-control" placeholder={this.props.user.emp_name+" Note"} aria-describedby="button-addon2"/>
                    <div className="input-group-append">
                        <button 
                        // onClick={()=> this.addComment()} 
                        className="btn btn-info" type="submit" id="button-addon2"><i className="fas fa-paper-plane"></i></button>
                    </div>
                    </Form>
                    {/* </div> */}
                </div>
                <div className="modal fade" id="staticBackdropA" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Filter By</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div className="modal-body">
                        <Form.Group controlId="filter1">
                            <Form.Label>Class</Form.Label>
                            <Form.Control onChange={(e)=> this.filterHandler(e)} as="select" value={!this.state.filter.class?'':this.state.filter.class}>
                            <option>Select Class</option>
                            <option>All</option>
                            <option>Employee Type</option>
                            <option>Employee</option>
                            </Form.Control>
                        </Form.Group>

                        {!!this.state.filter.class && this.state.filter.class !== 'All' && <Form.Group controlId="filter2">
                            <Form.Label>Specify</Form.Label>
                            <Form.Control onChange={(e)=> this.filterHandler(e)} as="select" value={!this.state.filter.specifier?'':this.state.filter.specifier}>
                            <option>Select Specifier</option>
                            {this.state.filter.class === 'Employee Type' && typeList}
                            {this.state.filter.class === 'Employee' && empList}
                            </Form.Control>
                        </Form.Group>}
                        </div>
                        <div className="modal-footer">
                        <button onClick={()=> this.filterBy()} type="button" className="btn btn-info" data-dismiss="modal">Save</button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Notes