import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { APIURL } from '../../../../config';

const SupportNeedForm = (props) => {
    const [supportNeeds, setSupportNeeds] = useState({});

    // Style for the modal content
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        setSupportNeeds(props.supportNeeds || {
            behavior: '',
            mobility: '',
            religious_cultural: '',
            support_settings: ''
        });
    }, [props.supportNeeds]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let data = {
                behavior: supportNeeds.behavior,
                mobility: supportNeeds.mobility,
                religious_cultural: supportNeeds.religious_cultural,
                support_settings: supportNeeds.support_settings,
                customer_id: props.customer_id
            };
            let response;
            if (props.supportNeeds) {
                response = await axios.put(APIURL + '/customer_support_need/' + props.customer_id, data);
            } else {
                response = await axios.post(APIURL + '/customer_support_need', data);
            }
            props.setSupportNeeds(data);
            props.handleClose();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="support-form-title"
            aria-describedby="support-form-description"
        >
            <Box sx={style} component="form" onSubmit={handleSubmit}>
                <Typography id="support-form-title" variant="h6" component="h2">
                    Support Needs
                </Typography>
                <TextField
                    fullWidth
                    label="Behavior"
                    margin="normal"
                    name="behavior"
                    variant="outlined"
                    rows={4}
                    multiline
                    value={supportNeeds?.behavior || ''}
                    onChange={(e) => setSupportNeeds({ ...supportNeeds, behavior: e.target.value || '' })}
                />
                <TextField
                    fullWidth
                    label="Mobility"
                    margin="normal"
                    name="mobility"
                    variant="outlined"
                    rows={4}
                    multiline
                    value={supportNeeds?.mobility || ''}
                    onChange={(e) => setSupportNeeds({ ...supportNeeds, mobility: e.target.value || '' })}
                />
                <TextField
                    fullWidth
                    label="Religious/Cultural"
                    margin="normal"
                    name="religious_cultural"
                    variant="outlined"
                    rows={4}
                    multiline
                    value={supportNeeds?.religious_cultural || ''}
                    onChange={(e) => setSupportNeeds({ ...supportNeeds, religious_cultural: e.target.value || '' })}
                />
                <TextField
                    fullWidth
                    label="Support Settings"
                    margin="normal"
                    name="support_settings"
                    variant="outlined"
                    rows={4}
                    multiline
                    value={supportNeeds?.support_settings || ''}
                    onChange={(e) => setSupportNeeds({ ...supportNeeds, support_settings: e.target.value || '' })}
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Save
                </Button>
            </Box>
        </Modal>
    );
}

export default SupportNeedForm;
