import react, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';

const SaveButton = (props) => {
    return (
        <Box sx={{ Width: 200, height: 40 }}>
            <Button variant="contained" color="primary" onClick={props.onClick} size="large" disabled={props.disabled}>
                Save
            </Button>
        </Box>
    );
}

export default SaveButton;