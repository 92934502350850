import React,{Component} from 'react';
// import HeaderInfo from './HeaderInfo'
import EmpTask from './EmpTask'
// import EmpTimeLine from './EmpTimeLine'
// import Home from './Home'
// import ProjectTaskAnalytics from './ProjectTaskAnalytics'


class EmpAnalytics extends Component{
    render(){
        return(
            <div>
                {/* <HeaderInfo/> */}
                <div style={{padding:10}} className="d-flex flex-lg-row flex-md-column flex-sm-column row">
                <div className="d-flex flex-row col-md-12 col-sm-12">
                        <EmpTask token={this.props.token} comp_id={this.props.comp_id}/>
                    </div>
                    {/* <div className="d-flex flex-row col-md-6 col-sm-12 d-none">
                        <div className="mt-1 d-none"><EmpTimeLine token={this.props.token} comp_id={this.props.comp_id}/></div> 
                    </div> */}
                    
                </div>
            </div>
        )
    }
}

export default EmpAnalytics