import React from 'react'
import moment from 'moment'

const NotificationTile = ({data}) => {
    let address = ''
    if(data.notif_type === 'project'){
        address =  !data.locationAddress || data.locationAddress.length === 0?'':`${data.locationAddress[0].addressDetails.loc_addr_line1} ${data.locationAddress[0].addressDetails.loc_addr_line2}, ${data.locationAddress[0].addressDetails.loc_state}, ${data.locationAddress[0].addressDetails.loc_city} ${data.locationAddress[0].addressDetails.loc_zip}`
    }
    if(data.notif_type === 'task'){
        address =  !data.locationAddress?'':`${data.locationAddress.loc_addr_line1} ${data.locationAddress.loc_addr_line2}, ${data.locationAddress.loc_state}, ${data.locationAddress.loc_city} ${data.locationAddress.loc_zip}`
    }
    return (
        <div>
             {data.notif_type === 'comment' && <div>
                {/* // <p className='text-left m-3'>You were assigned to a project {data.projectDetails[0].pro_title}</p> */}
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">User</div>
                    <div className="lato-normal-black-25px">{`${data.user.emp_first_name} ${data.user.emp_last_name}`}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Time</div>
                    <div className="lato-normal-black-25px">{moment(data.commentDetails[0].created_at).isValid()?moment(data.commentDetails[0].created_at).format('MM/DD/YYYY'):'-'}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Project</div>
                    <div className="lato-normal-black-25px">{data.projectDetails[0].pro_title}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Customer</div>
                    <div className="lato-normal-black-25px">{data.customerDetails.cus_name}</div>
                </div>
              
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Comment</div>
                    <div className="lato-normal-black-25px">{data.commentDetails[0].comment_text}</div>
                </div>

                </div>
                
            }
            {data.notif_type === 'timesheet' && <div>
                {/* // <p className='text-left m-3'>You were assigned to a project {data.projectDetails[0].pro_title}</p> */}
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Submitter Name</div>
                    <div className="lato-normal-black-25px">{`${data.user.emp_first_name} ${data.user.emp_last_name}`}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Submitted On</div>
                    <div className="lato-normal-black-25px">{moment(data.notif_date).isValid()?moment(data.notif_date).utc().format('MM/DD/YYYY, HH:mm A'):'-'}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Approver Name</div>
                    <div className="lato-normal-black-25px">{`${data.approverData.emp_first_name} ${data.approverData.emp_last_name}`}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Pay period</div>
                    <div className="lato-normal-black-25px">{moment(data.timesheetData.start_date).isValid()?moment(data.timesheetData.start_date).utc().format('MM/DD/YYYY'):''} to {moment(data.timesheetData.end_date).isValid()?moment(data.timesheetData.end_date).utc().format('MM/DD/YYYY'):''} </div>
                </div>

                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Approval Deadline </div>
                    <div className="lato-normal-black-25px">{moment(data.timesheetData.time_entry_approval_date).isValid()?moment(data.timesheetData.time_entry_approval_date).utc().format('MM/DD/YYYY'):'-'}</div>
                </div>

                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Pay Date</div>
                    <div className="lato-normal-black-25px">{moment(data.pay_date).isValid()?moment(data.pay_date).utc().format('MM/DD/YYYY'):'-'}</div>
                </div>
              
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Status</div>
                    <div className="lato-normal-black-25px">{data.timesheetData.time_sheet_status}</div>
                </div>

                </div>
                
            }
            {data.notif_type === 'project' && <div>
                {/* // <p className='text-left m-3'>You were assigned to a project {data.projectDetails[0].pro_title}</p> */}
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Project</div>
                    <div className="lato-normal-black-25px">{data.projectDetails[0].pro_title}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Customer</div>
                    <div className="lato-normal-black-25px">{data.customerDetails.cus_name}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Address</div>
                    <div className="lato-normal-black-25px">{address}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Phone</div>
                    <div className="lato-normal-black-25px">{data.customerDetails.cus_phone}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Start Date</div>
                    <div className="lato-normal-black-25px">{data.projectDetails[0].pro_starts_on}</div>
                </div>

                </div>
                
            }
            {data.notif_type === 'task' &&
            <div>
                {/* // <p className='text-left m-3'>You were assigned to a task {data.taskDetails[0].tas_title} in Project {data.projectDetails[0].pro_title}</p> */}
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Task</div>
                    <div className="lato-normal-black-25px">{data.taskDetails[0].tas_title}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Project</div>
                    <div className="lato-normal-black-25px">{data.projectDetails[0].pro_title}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Customer</div>
                    <div className="lato-normal-black-25px">{data.customerDetails.cus_name}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Address</div>
                    <div className="lato-normal-black-25px">{address}</div>
                </div>
                {/* <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Phone</div>
                    <div className="lato-normal-black-25px">{data.customerDetails.cus_phone}</div>
                </div> */}
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">Start Time</div>
                    <div className="lato-normal-black-25px">{moment(data.taskDetails[0].tas_start_time).utc().format('MM/DD/YYYY HH:mm A ')}</div>
                </div>
                <div className="mx-3 mb-3">
                    <div className="lato-semi-bold-gray-20px">End Time</div>
                    <div className="lato-normal-black-25px">{moment(data.taskDetails[0].tas_end_time).utc().format('MM/DD/YYYY HH:mm A ')}</div>
                </div>
            </div>
                
            }
        </div>
    )

}

export default NotificationTile