import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import CircularProgress from '@mui/material/CircularProgress';
import EmployeeNeedCard from "./EmployeeNeed/EmployeeNeedCard";
import HealthNeedCard from "./HealthNeed/HealthNeedCard";
import SupportNeedCard from "./SupportNeed/SupportNeedCard";

const Needs = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [employeeNeeds, setEmployeeNeeds] = useState({});
    const [healthNeeds, setHealthNeeds] = useState({});
    const [supportNeeds, setSupportNeeds] = useState({});

    useEffect(() => {
        setIsLoading(true);
        getData();
    }, []);

    const getData = async () => {
        const response = (await axios.get(APIURL + `/customer_empl_need/${props.cus_id}`)).data;
        const response2 = (await axios.get(APIURL + `/customer_health_need/${props.cus_id}`)).data;
        const response3 = (await axios.get(APIURL + `/customer_support_need/${props.cus_id}`)).data;
        setEmployeeNeeds(response.length > 0 ? response[0] : null);
        setHealthNeeds(response2.length > 0 ? response2[0] : null);
        setSupportNeeds(response3.length > 0 ? response3[0] : null);
        console.log(response);  
        setIsLoading(false);
    }

    const displayForms = () => {
        return (
            <div>
                <div style={{ marginBottom: '10px' }}> 
                    <EmployeeNeedCard 
                        employeeNeeds={employeeNeeds} 
                        setEmployeeNeeds={setEmployeeNeeds}
                        customer_id={props.cus_id}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <HealthNeedCard 
                        healthNeeds={healthNeeds} 
                        setHealthNeeds={setHealthNeeds}
                        customer_id={props.cus_id}
                    />
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <SupportNeedCard 
                        supportNeeds={supportNeeds} 
                        setSupportNeeds={setSupportNeeds}
                        customer_id={props.cus_id}
                    />
                </div>
            </div>
        )
    }

    return (
        <div>
            <div style={{ 'border': '1px solid #3fa7d6', backgroundColor: '#f9f9f9', borderRadius: '10px', minHeight: '100px', padding: '15px 20px' }}>
                <h5 style={{ marginBottom: '15px' }}>Needs</h5>
                {isLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}><CircularProgress /></div> : displayForms()}
            </div>
        </div>
    )
}

export default Needs;