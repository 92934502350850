import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import './ProjectCard.css'
import moment from 'moment'

class ProjectCard extends Component {
    render() {
        return (
            <div>
                <Card style={{ height: '6rem', width: '100%' }} className={`cardBody ${this.props.selected}`} onClick={() => this.props.onClick(this.props.id)} >
                    <Card.Body style={{ padding: '2px', }}>
                        <Card.Title style={{ marginLeft: '5px', marginBottom: '0px', marginTop: '15px', cursor: "pointer", fontSize: !this.props.projSearch ? '1.3rem' : '0.7rem' }}>{this.props.title} <em className='text-muted' style={{ fontSize: 14, display: this.props.start && this.props.end ? "inline" : "none" }}>({moment(this.props.start).utc().format('MMM DD, YYYY')} - {moment(this.props.end).utc().format('MMM DD, YYYY')})</em> </Card.Title>
                        {this.props.projSearch && <Card.Text style={{ fontSize: !this.props.projSearch ? '1rem' : '0.7rem' }}>
                            <span>{moment(this.props.start).format('MMM DD')} - {moment(this.props.end).format('MMM DD')}</span>
                        </Card.Text>}
                        <Card.Body style={{ display: 'flex', margin: '0px'}}>
                            <Card.Text style={{ fontSize: !this.props.projSearch ? '0.9rem' : '0.7rem', width: '26%', margin: '0px' }}>
                                <span><i style={{ paddingRight: "15px" }} className="fas fa-briefcase"></i></span>{this.props.customerName}
                            </Card.Text>
                            <Card.Text style={{ fontSize: !this.props.projSearch ? '0.9rem' : '0.7rem', margin: '0px'  }}>
                                <span><i style={{ paddingRight: "20px" }} className="fas fa-map-marker-alt"></i></span>{this.props.location.loc_addr_line1 ? this.props.location.loc_addr_line1 : ''}  {this.props.location.loc_addr_line2 ? this.props.location.loc_addr_line2 : ''}  {this.props.location.loc_city ? this.props.location.loc_city : ''}  {this.props.location.loc_state ? this.props.location.loc_state : ''}  {this.props.location.loc_zip ? this.props.location.loc_zip : ''}
                            </Card.Text>
                        </Card.Body>
                    </Card.Body>
                    {/* {!this.props.projSearch && <Card.Footer className="text-muted cardFooter">
                        {this.props.emp_count} Employees
                    </Card.Footer>} */}
                    <div>
                        {this.props.selected === 'active' && <i className="fas fa-chevron-right fa-2x"></i>}
                    </div>
                </Card>
            </div>
        )
    }
}

export default ProjectCard;