import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FileFolderItem from '../FileFolderItem';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = (props) => {
    const [searchText, setSearchText] = useState('');
    const [file, setFile] = useState(null);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        setFiles(props.rows.filter(row => row.file_name !== null));
    }, [props.rows]);

    useEffect(() => {
        if (searchText === '') {
            props.setFilterModel( prev => {
                let newFilterModel = {...prev};
            
                newFilterModel.items = prev.items.filter(item => item.columnField !== "file_name" && item.columnField !== "id")
                return newFilterModel;
            });
            props.setIsExpanded(false);
        }
    }, [searchText]);

    const onSearch = () => {
        if(searchText.trim() === '') 
            return;

        props.setFilterModel( prev => {
            let newFilterModel = {...prev};
            
            newFilterModel.items = prev.items.filter(item => item.columnField !== "file_name" && item.columnField !== "id")
            file ? newFilterModel.items.push({ columnField: "id", operatorValue: "equals", value: file.id + "", id: 0 }) :
            newFilterModel.items.push({ columnField: "file_name", operatorValue: "contains", value: searchText, id: 1 });
            return newFilterModel;
        });
        props.setIsExpanded(true);
    }


    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', minWidth: "200px", width: "100%" }}>
            <AutoComplete
                freeSolo
                value={file}
                inputValue={searchText}
                onChange={(e, value) => setFile(value)}
                onInputChange={(e, value) => setSearchText(value)}
                options={files}
                sx={{ width: "100%", marginRight: .5 }}
                size="small"
                getOptionLabel={(option) => option.file_name + "." + option.file_ext}
                renderOption={(props, option) => <FileFolderItem listItemProps={props} row={option} />}
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        label="Search" 
                        variant="outlined"
                />}
            />
            <Button variant="outlined" sx={{ height: "40px", minWidth: "40px", padding: 0.25, margin: 0 }}>
                <SearchIcon onClick={onSearch} />
            </Button>
        </Box>
    )
}

export default SearchBar;