import React from 'react';
import './CustomerDetails.css';
import moment from 'moment';

let street, city, state, zip;

const setAddress = (address) => {
    const addressChunk = address.split(':')
    const addressArray = addressChunk[0].split(',')
    zip = addressArray[addressArray.length-1]
    state = addressArray[addressArray.length-2]
    city = addressArray[addressArray.length-3]
    street = (addressArray[addressArray.length-5]?addressArray[addressArray.length-5]:"")+" "+addressArray[addressArray.length-4]
} 


const showPhoneList = (phoneString) => {
    const phoneArray = phoneString.split(",")
    return phoneArray.map((phone, index) => {
        return (
            <div key={index} className="phoneNumberBlock">
                <div className="itemNumber">{index+1}</div>
                <div className="phoneNumber">{phone}</div>
            </div>
        )
    })
}

const CustomerDetails = (props) => {
    setAddress(props.customer_details.cus_address)
    return (
        <div className="customerDetails">
            <div className="navigationHeader">
                <h5 style={{ cursor: "pointer" }}><span style={{ paddingRight: 10, paddingLeft: 0, fontSize: "1.5rem" }}><i className="fas fa-arrow-left"  onClick={() => props.onClickBack(0)}></i></span>BACK</h5>
                <li style={{ cursor: "pointer" }} onClick={() => props.onClickEdit(2)}><i className="fas fa-pencil-alt"></i></li>
            </div>
            <div className="specificDetails">
                <h4>{props.customer_details.cus_name}</h4>
                <span>In {props.projectTitle}</span>
            </div>
            <h6 className="customerEntitlement">{props.customer_details.cus_is_company ? "Company" : "Individual"}</h6>
            <div className="itemBlock">
                <span>Acquired Date</span>
                <h6>{moment(props.customer_details.cus_acquired_date.replace(/Z/g, "")).format('MMMM D, YYYY')}</h6>
            </div>
            <div className="itemBlock">
                <span>Phone</span>
                {showPhoneList(props.customer_details.cus_phone)}
            </div>
            <div className="itemBlock">
                <span>Address</span>
                <h6>{street}</h6>
            </div>
            <div className="itemBlock">
                <span>City</span>
                <h6>{city}</h6>
            </div>
            <div className="itemBlock">
                <span>State</span>
                <h6>{state}</h6>
            </div>
            <div className="itemBlock">
                <span>Zip</span>
                <h6>{zip}</h6>
            </div>
            <div className="itemBlock">
                <span>Status</span>
                <h6>{props.customer_details.cus_status}</h6>
            </div>
            <div className="itemBlock">
                <span>Type</span>
                <h6>{props.customer_details.cus_type}</h6>
            </div>
        </div>
    )
};

export default CustomerDetails;