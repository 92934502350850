import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Divider, TextField, Grid, Autocomplete, Button, Modal, ListItemText } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import SaveButton from '../Components/SaveButton';
import CancelButton from '../Components/CancelButton';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { APIURL } from '../../../config';
import ccs from 'countrycitystatejson';
import normalize from 'us-states-normalize';
import moment from 'moment'

const Office = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [locationDetails, setLocationDetails] = useState([]);
    const [newLocationDetails, setNewLocationDetails] = useState({
        "oloc_addr_line1": "",
        "oloc_addr_line2": "",
        "oloc_city": "",
        "oloc_state": "",
        "oloc_zip": "",
        "oloc_type": null,
        "oloc_status": null,
        "oloc_county": "",
        "oloc_association_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
        "country_code": "+1",
        "oloc_phone_number": "",
        "oloc_is_active": 1,
        "oloc_facilityType": "",
        "oloc_facilityCodeQualifier": ""
    });
    const [errors, setErrors] = useState({ taxId: false });
    const [lastSavedCompanyDets, setLastSavedCompanyDets] = useState({});
    const [open, setOpen] = useState(false);
    const [addressSuggestions, setAddressSuggestions] = useState([]);

    const getData = async () => {
        try {
            const response = await axios.get(APIURL + `/company/officeLocation`, { params: { company_id: props.comp_id } });
            setLocationDetails(response.data)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getData();
    }, [open]);

    const handleClose = () => {
        setNewLocationDetails({
            "oloc_addr_line1": "",
            "oloc_addr_line2": "",
            "oloc_city": "",
            "oloc_state": "",
            "oloc_zip": "",
            "oloc_type": null,
            "oloc_status": null,
            "oloc_county": "",
            "oloc_association_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
            "country_code": "+1",
            "oloc_phone_number": "",
            "oloc_is_active": "",
            "oloc_facilityType": "",
            "oloc_facilityCodeQualifier": ""
        });
        setOpen(false);
        setEditMode(false);
    };

    const handleAddressSuggestions = async (value) => {
        let newAddressSuggestions = await axios.get(APIURL + '/maps', { params: { input: value } })
        setAddressSuggestions(newAddressSuggestions.data.predictions)
    }

    const handleAddressSelect = async (value) => {
        let newAddressDetails = await axios.get(APIURL + '/maps/details', { params: { place_id: value.place_id } })
        const addressDetails = newAddressDetails.data;

        let oldState = { ...newLocationDetails }

        oldState.oloc_addr_line1 = addressDetails.result.address_components.find(component => component.types.includes("street_number")).long_name + " " + addressDetails.result.address_components.find(component => component.types.includes("route")).long_name
        oldState.oloc_city = addressDetails.result.address_components.find(component => component.types.includes("locality")).long_name
        oldState.oloc_state = addressDetails.result.address_components.find(component => component.types.includes("administrative_area_level_1")).short_name
        oldState.oloc_zip = addressDetails.result.address_components.find(component => component.types.includes("postal_code")).long_name

        setNewLocationDetails(oldState);
    }

    const addOfficeLocation = async () => {
        var data = {
            oloc_addr_line1: newLocationDetails.oloc_addr_line1,
            oloc_addr_line2: newLocationDetails.oloc_addr_line2,
            oloc_city: newLocationDetails.oloc_city,
            oloc_state: newLocationDetails.oloc_state,
            oloc_zip: newLocationDetails.oloc_zip,
            oloc_type: newLocationDetails.oloc_type,
            oloc_status: newLocationDetails.oloc_status,
            oloc_county: newLocationDetails.oloc_county,
            oloc_association_date: newLocationDetails.oloc_association_date,
            oloc_phone_number: newLocationDetails.oloc_phone_number,
            oloc_company_id: props.comp_id,
            oloc_is_active: newLocationDetails.oloc_is_active,
            oloc_facilityType: newLocationDetails.oloc_facilityType,
            oloc_facilityCodeQualifier: newLocationDetails.oloc_facilityCodeQualifier
        };

        const msg = await axios.post(APIURL + "/company/officeLocation", data);

        handleClose();
    }

    const editOfficeLocation = async () => {
        var data = {
            oloc_id: newLocationDetails.oloc_id,
            oloc_addr_line1: newLocationDetails.oloc_addr_line1,
            oloc_addr_line2: newLocationDetails.oloc_addr_line2,
            oloc_city: newLocationDetails.oloc_city,
            oloc_state: newLocationDetails.oloc_state,
            oloc_zip: newLocationDetails.oloc_zip,
            oloc_type: newLocationDetails.oloc_type,
            oloc_status: newLocationDetails.oloc_status,
            oloc_county: newLocationDetails.oloc_county,
            oloc_association_date: newLocationDetails.oloc_association_date,
            oloc_phone_number: newLocationDetails.oloc_phone_number,
            oloc_company_id: props.comp_id,
            oloc_is_active: newLocationDetails.oloc_is_active,
            oloc_facilityType: newLocationDetails.oloc_facilityType,
            oloc_facilityCodeQualifier: newLocationDetails.oloc_facilityCodeQualifier
        };

        const msg = await axios.put(APIURL + "/company/officeLocation", data);

        handleClose();
    }

    const statuses = [{ value: 0, title: 'Inactive' }, { value: 1, title: 'Active' }];
    const types = [{ value: 'Type 1', title: 'Type 1' }];
    const code = ccs.getCountryByShort('US');
    const currAddress = newLocationDetails.oloc_addr_line1 + ', ' + (!!newLocationDetails.oloc_addr_line2?.trim() ? newLocationDetails.oloc_addr_line2 + ', ' : '') + newLocationDetails.oloc_city + ', ' + newLocationDetails.oloc_state + ', ' + newLocationDetails.oloc_zip

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h5">Office Locations</Typography>
            </Box>
            {locationDetails.map((formValues, index) => {
                return (
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item xs={2}>
                                <Typography variant="h6" sx={{ mt: 2 }}>Location {index + 1}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <IconButton onClick={() => { setOpen(true); setEditMode(true); setNewLocationDetails(formValues); }}>
                                    <Edit />
                                </IconButton>
                            </Grid>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Office Location
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>
                                    {formValues.oloc_addr_line1 || ''} {formValues.oloc_addr_line2 || ''} {formValues.oloc_city || ''},{' '}
                                    {formValues.oloc_state || ''} {formValues.oloc_zip || ''}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Type
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{formValues.oloc_type}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Status
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{formValues.oloc_status === 1 ? 'Active' : 'Inactive'}</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Association Date
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{moment(formValues.oloc_association_date).format('MM/DD/YYYY')}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Phone Number
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{formValues.oloc_phone_number}</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Facility Type
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{formValues.oloc_facilityType}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Facility Code Qualifier
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{formValues.oloc_facilityCodeQualifier}</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                )
            }
            )}
            <Button
                onClick={() => setOpen(true)}
                sx={{ marginTop: 4 }}
                variant="contained"
                color="primary"
            >
                + Add New Location
            </Button>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        maxWidth: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2
                    }}
                >
                    <Typography variant="h6" component="div" sx={{ mb: 3 }}>
                        {editMode ? 'Edit Location' : 'Add New Location'}
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                        <Autocomplete
                            defaultValue={{ description: /^[, ]+$/.test(currAddress) ? '' : currAddress }}
                            autoSelect
                            selectOnFocus
                            disablePortal
                            id="address_maps"
                            popupIcon={null}
                            options={addressSuggestions}
                            getOptionLabel={(option) => option.description}
                            onChange={(event, value, reason) => {
                                if (reason === 'selectOption') {
                                    handleAddressSelect(value)
                                }
                            }}
                            onInputChange={(event, value, reason) => {
                                if (reason === 'input') {
                                    handleAddressSuggestions(value)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="ADDRESS *"
                                    error={''}
                                />
                            )}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Autocomplete
                            id="status"
                            options={statuses}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Status"
                                    error={''}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.con_id}>
                                    <ListItemText primary={option.title} />
                                </li>
                            )}
                            value={{ value: newLocationDetails.oloc_status, title: newLocationDetails.oloc_status === 1 ? 'Active' : 'Inactive' }}
                            onChange={(event, newValue) => {
                                setNewLocationDetails({ ...newLocationDetails, oloc_status: newValue.value });
                            }}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Autocomplete
                            id="type"
                            options={types}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Type"
                                    error={''}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.con_id}>
                                    <ListItemText primary={option.title} />
                                </li>
                            )}
                            value={{ value: newLocationDetails.oloc_type, title: newLocationDetails.oloc_type }}
                            onChange={(event, newValue) => {
                                setNewLocationDetails({ ...newLocationDetails, oloc_type: newValue.value });
                            }}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid container justifyContent="flex-start">
                                <DatePicker
                                    id="association_date"
                                    label="ASSOCIATION DATE"
                                    value={moment(newLocationDetails.oloc_association_date).format('YYYY-MM-DDTHH:mm:ss')}
                                    onChange={(d) => { setNewLocationDetails({ ...newLocationDetails, oloc_association_date: moment(d).format('YYYY-MM-DDTHH:mm:ss') }) }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="ASSOCIATION DATE"
                                            error={''}
                                        />
                                    )}
                                />
                            </Grid>
                        </LocalizationProvider>
                    </Box>
                    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                        <TextField
                            label="Country Code"
                            inputProps={{ maxLength: 10 }}
                            fullWidth
                            value={`${code.emoji} ${code.name}(${code.phone})`}
                            error={''}
                        />

                        <TextField
                            label="Phone"
                            inputProps={{ maxLength: 10 }}
                            fullWidth
                            value={newLocationDetails.oloc_phone_number}
                            onChange={(e) => setNewLocationDetails({ ...newLocationDetails, oloc_phone_number: e.target.value })}
                            error={''}
                        />
                    </Box>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={editMode ? editOfficeLocation : addOfficeLocation}
                    >
                        {editMode ? 'Save Changes' : 'Add Location'}
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default Office;