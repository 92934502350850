import React, { Component } from 'react'
import Overview from '../Overview/Overview'
import Files from '../Files/Files'
import Notes from '../Notes/Notes'
import People from '../People/People'
import Projects from '../Projects/Projects'
import Tasks from '../Tasks/Tasks'
import Authorization from '../Authorization/Authorization'
import Goals from '../Goals/Goals'
import Needs from '../Needs/Needs'
import Forms from '../Forms/Forms'
import axios from 'axios';
import moment from "moment";
import { APIURL } from '../../../config'
import "./details.css"

class DetailsPane extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 0,
      project: {},
      contactsList: [],
      loading: true,
      edited: false,
      customerType: null,
      customerStatus: null,
      loc_list: null,
      primaryBusinessType: null,
    }
    this.selectTab = this.selectTab.bind(this)
    this.renderDetails = this.renderDetails.bind(this)
    this.fetchData = this.fetchData.bind(this)
  }
  async fetchData() {
    // { "primaryBusinessType": "Support Coordination", "featureValue": 101 },
    // { "primaryBusinessType": "Service Provider", "featureValue": 102 },
    let pBusinessType = this.props.user?.comp_settings.find(setting => setting.feature_value === 'primaryBusinessType') ? this.props.user.comp_settings.filter((setting) => setting.feature_value === "primaryBusinessType")[0].feature_id : 101;

    this.setState({ loading: false, primaryBusinessType: pBusinessType })
  }

  async componentDidMount() {
    this.fetchData()
  }

  componentDidUpdate(prevProps) {
    if (this.props.cust_details !== prevProps.cust_details) {
      this.setState({ loading: true, selectedTab: 0 })
      this.fetchData()
    }
  }

  selectTab(index) {
    this.setState({
      selectedTab: index, edited: true
    })
  }

  isFormValidated = (objForVal) => {
    var missingField = {
      isValidated: true
    }
    let temp = Object.keys(objForVal)
    if (temp[0] === 'selectedEmp') {

      let selectedEm = objForVal.selectedEmp
      for (let i = 0; i < selectedEm.length; i++) {
        for (let objKey in selectedEm[i]) {
          if (
            selectedEm[i][objKey] === "" ||
            selectedEm[i][objKey] === undefined ||
            selectedEm[i][objKey].length === 0
          ) {
            missingField.isValidated = false;
            missingField.missingKey = objKey
            return missingField
          }
        }
      }
    }




    for (let objKey in objForVal) {
      if (
        objForVal[objKey] === "" ||
        objForVal[objKey] === undefined ||
        objForVal[objKey] === null ||
        objForVal[objKey].length === 0
      ) {
        if (
          objKey === "address2" ||
          objKey === "location_addr2" ||
          objKey === "tComment" ||
          objKey === "cus_local_id" ||
          //objKey === "ddd_id" ||
          objKey === "address_line_2" ||
          objKey === "email" ||
          objKey === "pManager" ||
          objKey === "medicaid_id" ||
          objKey === "medicaid_eligible" ||
          objKey === "sign_page_date" ||
          objKey === "last_plan_approval_date" ||
          objKey === "last_assess_date" ||
          objKey === "renewal_date" ||
          //objKey === "DDDID" ||
          objKey === "personal_days_off" ||
          objKey === "vacation_days" ||
          objKey === "DDD_tier" ||
          objKey === "diagnose_code" ||
          objKey === "func_criteria_met" ||
          objKey === "waiver_program" ||
          objKey === "NJCATSelfcare" ||
          objKey === "NJCATBehavioral" ||
          objKey === "NJCATMedical" ||
          objKey === "NJCATFCA" ||
          objKey === "MedicaidEndDate" ||
          objKey === "AgencyAssignDate" ||
          objKey === "County" ||
          objKey === "Race" ||
          objKey === "Ethnicity" ||
          objKey === "TransferOutReason" ||
          objKey === "TransferOutDate" ||
          objKey === "tContacts"
        ) {
          // return true
          continue;
        } else if (objForVal["cus_is_company"] === true && objForVal["company_name"] === "") {
          missingField.isValidated = false;
          missingField.missingKey = "company_name"
          return missingField;
        }
        else {
          console.log(objKey);

          // if (
          //   (objKey === "company_name" &&
          //     objForVal["first_name"] !== "" &&
          //     objForVal["last_name"] !== "") ||
          //   ((objKey === "first_name" || objKey === "last_name") &&
          //     objForVal["company_name"] !== "" && objForVal["company_name"] !== undefined)
          // ) {
          //   continue;
          // } else {
          missingField.isValidated = false;
          missingField.missingKey = objKey
          return missingField;
          // }
        }
      } else if (Array.isArray(objForVal[objKey])) {
        let arrValid = objForVal[objKey].every(function (objTesting) {
          for (let objTestKey in objTesting) {
            console.log(objTestKey);
            if (
              objTesting[objTestKey] === "" ||
              objTesting[objTestKey] === undefined
            ) {
              if (
                objTestKey === "address_line_2" ||
                objTestKey === "location_addr2"
              ) {
                // return true
                continue;
              } else {
                missingField.isValidated = false;
                missingField.missingKey = objKey
                return missingField;
              }
            }
          }
          return true;
        });
        if (!arrValid) return false;
      } else if (objKey === "active_plan_version") {
        for (const digit of objForVal[objKey]) {
          if (Number.isNaN(parseInt(digit)) && digit !== ".") {
            alert("PROJECT ACTIVE PLAN VERSION has to be a number");
            objForVal[objKey] = "";
            return false;
          }
        }
      }
      else if (objKey === "dob") {
        if (!moment(objForVal[objKey]).isValid()) {
          missingField.isValidated = false;
          missingField.missingKey = objKey
          return missingField;
        }
      }
    }

    return missingField;
  };

  isNewFormValidated = (objForVal) => {
    console.log('>>>>>>>>>>> ', objForVal)
    var missingField = {
      isValidated: true
    }
    let temp = Object.keys(objForVal)
    if (temp[0] === 'selectedEmp') {

      let selectedEm = objForVal.selectedEmp
      for (let i = 0; i < selectedEm.length; i++) {
        for (let objKey in selectedEm[i]) {
          if (
            selectedEm[i][objKey] === "" ||
            selectedEm[i][objKey] === undefined ||
            selectedEm[i][objKey].length === 0
          ) {
            missingField.isValidated = false;
            missingField.missingKey = objKey
            return missingField
          }
        }
      }
    }




    for (let objKey in objForVal) {
      if ((objKey !== "Cemail" && objKey !== "designation") &&
        (objForVal[objKey] === "" ||
          objForVal[objKey] === undefined ||
          objForVal[objKey].length === 0)
      ) {
        if (
          objKey === "address2" ||
          objKey === "location_addr2" ||
          objKey === "tComment" ||
          objKey === "cus_local_id" ||
          //objKey === "ddd_id" ||
          objKey === "address_line_2" ||
          // objKey === "email" ||
          objKey === "medicaid_id" ||
          objKey === "medicaid_eligible" ||
          objKey === "sign_page_date" ||
          objKey === "last_plan_approval_date" ||
          objKey === "last_assess_date" ||
          objKey === "renewal_date" ||
          //objKey === "DDDID" ||
          objKey === "personal_days_off" ||
          objKey === "vacation_days" ||
          objKey === "DDD_tier" ||
          objKey === "diagnose_code" ||
          objKey === "func_criteria_met" ||
          objKey === "waiver_program" ||
          objKey === "NJCATSelfcare" ||
          objKey === "NJCATBehavioral" ||
          objKey === "NJCATMedical" ||
          objKey === "NJCATFCA" ||
          objKey === "MedicaidEndDate" ||
          objKey === "AgencyAssignDate" ||
          objKey === "County" ||
          objKey === "Race" ||
          objKey === "Ethnicity" ||
          objKey === "TransferOutReason" ||
          objKey === "TransferOutDate" ||
          objKey === "tContacts"
        ) {
          // return true
          continue;
        } else if (objForVal["cus_is_company"] === true && objForVal["company_name"] === "") {
          missingField.isValidated = false;
          missingField.missingKey = "company_name"
          return missingField;
        }
        else {
          console.log(objKey);

          if (
            (objKey === "company_name" &&
              objForVal["first_name"] !== "" &&
              objForVal["last_name"] !== "") ||
            ((objKey === "first_name" || objKey === "last_name") &&
              objForVal["company_name"] !== "" && objForVal["company_name"] !== undefined) || objKey === "address_maps"
          ) {
            continue;
          } else {
            missingField.isValidated = false;
            missingField.missingKey = objKey
            return missingField;
          }
        }
      } else if (Array.isArray(objForVal[objKey])) {
        let arrValid = objForVal[objKey].every(function (objTesting) {
          for (let objTestKey in objTesting) {
            console.log(objTestKey);
            if (
              objTesting[objTestKey] === "" ||
              objTesting[objTestKey] === undefined
            ) {
              if (
                objTestKey === "address_line_2" ||
                objTestKey === "location_addr2"
              ) {
                // return true
                continue;
              } else {
                missingField.isValidated = false;
                missingField.missingKey = objKey
                return missingField;
              }
            }
          }
          return true;
        });
        if (!arrValid) return false;
      } else if (objKey === "active_plan_version") {
        for (const digit of objForVal[objKey]) {
          if (Number.isNaN(parseInt(digit)) && digit !== ".") {
            alert("PROJECT ACTIVE PLAN VERSION has to be a number");
            objForVal[objKey] = "";
            return false;
          }
        }
      }
    }

    return missingField;
  };

  renderDetails() {
    if (this.state.loading) {
      return (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )
    }

    switch (this.state.selectedTab) {
      case 0:
        return <Overview {...this.props} isFormValidated={this.isFormValidated} isNewFormValidated={this.isNewFormValidated} />
      case 1:
        return <Projects {...this.props} isNewFormValidated={this.isNewFormValidated} />
      case 2:
        return <Tasks {...this.props} isFormValidated={this.isFormValidated} isNewFormValidated={this.isNewFormValidated} />
      case 3:
        return <People {...this.props} />
      case 4:
        return <Files {...this.props} />
      case 5:
        return <Notes {...this.props} />
      case 6:
        return <Authorization {...this.props} />
      case 7:
        return <Goals {...this.props} />
      case 8:
        return <Needs {...this.props} />  
      case 9:
        return <Forms {...this.props} />
      default:
        return <div />
    }
  }

  render() {
    return (
      <div>
        <div className='details_header'>
          <div className={this.state.selectedTab === 0 ? 'selected' : 'unselected'} style={{ width: '120px' }} onClick={() => this.selectTab(0)}>OVERVIEW</div>
          {<>
            <div className={this.state.selectedTab === 1 ? 'selected' : 'unselected'} onClick={() => this.selectTab(1)}>PROJECTS</div>
            <div className={this.state.selectedTab === 2 ? 'selected' : 'unselected'} onClick={() => this.selectTab(2)}>TASKS</div>
            <div className={this.state.selectedTab === 3 ? 'selected' : 'unselected'} onClick={() => this.selectTab(3)}>PEOPLE</div>
            <div className={this.state.selectedTab === 4 ? 'selected' : 'unselected'} onClick={() => this.selectTab(4)}>FILES</div>
            <div className={this.state.selectedTab === 5 ? 'selected' : 'unselected'} onClick={() => this.selectTab(5)}>NOTES</div>
            {(this.state.primaryBusinessType === 102 || this.state.primaryBusinessType === 105) && <div className={this.state.selectedTab === 6 ? 'selected' : 'unselected'} style={{ width: '160px' }} onClick={() => this.selectTab(6)}>AUTHORIZATION</div>}
            {<div className={this.state.selectedTab === 7 ? 'selected' : 'unselected'} onClick={() => this.selectTab(7)}>GOALS</div>}
            {<div className={this.state.selectedTab === 8 ? 'selected' : 'unselected'} onClick={() => this.selectTab(8)}>NEEDS</div>}
            {this.props.user.subs.find((d) => d.feature_id === 15) && <div className={this.state.selectedTab === 9 ? 'selected' : 'unselected'} onClick={() => this.selectTab(9)}>FORMS</div>}

          </>}
        </div>
        <div className='details_body'>
          {this.renderDetails()}
        </div>
      </div>
    )
  }
}

export default DetailsPane
