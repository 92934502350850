import React from "react";
import axios from 'axios';
import { APIURL } from '../../config'
import NotificationsCard from "./notificationsCard";
import NotifDetails from "./NotifDetails";
import moment from "moment";

class Notifications extends React.Component {

    state = {
        notifications: [],
        isLoading: true,
        readAll: false,
        selected : null,
        showClearAlert : false,
        // readOne : false,
        // readText: "Mark all as Read",
        readOneText : "Mark as Read",
        numOfUnreadMessages: 0,
        clearAll: false
    }


    updateList = async(id, isRead) => {
        let notifications = this.state.notifications.map((not)=> {
            if(not.notif_id === id){
                return {...not, isRead : isRead}
            }else{
                return not
            }
        })
        this.setState({numOfUnreadMessages:!isRead?(this.state.numOfUnreadMessages+1):(this.state.numOfUnreadMessages-1),readAll: notifications.every((data) => !!data.isRead)})
        this.setState({notifications})

    }

    updateListforStatus = async(id, status) => {
        let notifications = this.state.notifications.filter((data)=> data.notif_id !== id)
        this.setState({notifications})
    }

    async componentDidMount() {
        let notificationsData = await axios.get(APIURL + '/notifications', {params : {rec_id: this.props.user.emp_id, send_id: this.props.user.emp_id}})
        this.setState({ notifications: notificationsData.data, isLoading: false, readAll : notificationsData.data.every((data)=> !!data.isRead)})
        this.findCount()
        }

    viewOneNotif = async(id) => {
        // debugger
        if(!!id){
            this.setState({isLoading: true})
            let body = { isRead: 'true', status: 'READ'}
            let statusUpdate = await axios.put(`${APIURL}/notifications/${id}`, body)
            if(statusUpdate.data.code === 200){
                let notifDetails = await axios.get(APIURL+`/notifications/${id}`)
                // this.setState({notifDetails})
                let notifications = this.state.notifications.map((data)=> {
                    if(data.notif_id === +id){
                        return {...data, isRead: true, status: "READ"}
                    }else{
                        return data
                    }
                })
                console.log(notifDetails.data)
                await this.setState({notifications: notifications,selected: notifDetails.data,numOfUnreadMessages: this.state.numOfUnreadMessages>0?notifDetails.data.status === 'READ'?this.state.numOfUnreadMessages:this.state.numOfUnreadMessages-1:0,isLoading: false})
            }else{
                await this.setState({selected: null,isLoading: false})     
            }
           
        }else{
            await this.setState({selected: null})
        }
        
    }

    findCount = async () => {
        let numOfUnread = this.state.notifications.filter((value) => !value.isRead)
        if(numOfUnread.length === 0){
            this.setState({ numOfUnreadMessages: numOfUnread.length, readAll : true })
        }
        else{
            this.setState({ numOfUnreadMessages: numOfUnread.length })
        }
    }

    onReadAll = async () => {
        this.setState({isLoading: true,readAll: true})

        let notif_ids = [];
        this.state.notifications.map(async (not)=> {
            notif_ids.push(not.notif_id);
        });

        let body = {isRead : 'true', status: 'READ'};
        let dataRes = await axios.put(`${APIURL}/notifications/${notif_ids}`, body);

        if(dataRes.data.code === 200){
            let newNotifications = this.state.notifications.map((notif)=> {
                return {...notif, isRead: true,status: 'READ'}
            })
            this.setState({notifications: newNotifications, isLoading: false, numOfUnreadMessages: 0})
        }else{
            this.setState({isLoading: false,readAll: false})
        }
    }

    onUnReadAll = async () => {
        this.setState({isLoading: true,readAll: false})

        let notif_ids = [];
        this.state.notifications.map(async (not)=> {
            notif_ids.push(not.notif_id);
        });

        let body = {isRead : 'false', status: 'UNREAD'};
        let dataRes = await axios.put(`${APIURL}/notifications/${notif_ids}`, body);

        if(dataRes.data.code === 200){
            let newNotifications = this.state.notifications.map((notif)=> {
                return {...notif, isRead: false,status: 'UNREAD'}
            })
            this.setState({notifications: newNotifications, isLoading: false, numOfUnreadMessages: newNotifications.length})
        }else{
            this.setState({isLoading: false,readAll: true})
        }
    }

    showClearWarning = async() => {
        this.setState({showClearAlert: !this.state.showClearAlert})
    }

    onClearAll = async () => {
        this.setState({isLoading: true,clearAll:true})

        let notif_ids = [];
        this.state.notifications.map(async (not)=> {
            notif_ids.push(not.notif_id);
        });

        let body = {status: 'ARCH'};
        let dataRes = await axios.put(`${APIURL}/notifications/${notif_ids}`, body);

        this.showClearWarning()
        if(dataRes.data.code === 200){
            this.setState({ isLoading: false, notifications: [], numOfUnreadMessages: 0,readAll:true })
        }else{
            this.setState({ isLoading: false,clearAll: false})
        }
        
    }

    onChange = async () => {
        this.setState({readAll : false})
    }

    render() {
        let dateMap = {}
        let list = this.state.notifications.map((notif) => {
            if(!!dateMap[moment(notif.notif_date).format('YYYY-MM-DD')]){
                return (
                    <NotificationsCard key={notif.notif_id} user={this.props.user} showHeader={false} viewOneNotif={this.viewOneNotif} readAll={this.state.readAll} list={notif} numOfUnreadMessages = {this.state.numOfUnreadMessages} readOne = {this.state.readOne} readOneText = {this.state.readOneText} updateListforStatus={this.updateListforStatus} updateList = {this.updateList} />
                )
            }else{
                dateMap[moment(notif.notif_date).format('YYYY-MM-DD')] = notif
                return (
                    <NotificationsCard key={notif.notif_id} user={this.props.user} showHeader={true} viewOneNotif={this.viewOneNotif} readAll={this.state.readAll} list={notif} numOfUnreadMessages = {this.state.numOfUnreadMessages} readOne = {this.state.readOne} readOneText = {this.state.readOneText} updateListforStatus={this.updateListforStatus} updateList = {this.updateList} />
                )
            }
            
        })
        // console.log(dateMap)

        return (
            <div style={{ height: '60rem', width: '470px' }}>
                <div className="d-flex flex-row" style={{ borderBottom: '1px solid #AAAAAA' }}>
                    <h4 style = {{marginTop: "10px", marginLeft: "10px"}} onLoad = {this.onChange}> Notifications</h4>
                    <h6 style={this.state.numOfUnreadMessages === 0 ? {} : { marginLeft: 10, marginTop: '1rem', color: 'white', textDecoration: 'none', backgroundColor: '#FA7171', width: 30, textAlign: 'center', borderRadius: '25px' }}> {this.state.numOfUnreadMessages === 0 ? '': this.state.numOfUnreadMessages} </h6>
                    <div className="d-flex flex-row" style={{ marginTop: '1rem', marginLeft: "auto", marginRight: 30 }}>
                        {/* eslint-disable-next-line */}
                        {!this.state.selected && <a onClick={this.state.readAll?this.onUnReadAll:this.onReadAll} style={{ color: 'rgba(0, 0, 0, 1)', textDecoration: 'none', marginRight: '5px', cursor:'pointer' }}>Mark all as {this.state.readAll?'Unread':'Read'}</a>}
                        {/* {this.state.clearAll && <a href=" " onClick={this.onReadAll} style={{ color: 'rgba(0, 0, 0, 1)', textDecoration: 'none', marginRight: '5px' }}> </a>} */}
                        {!this.state.selected && <span style={{ marginLeft: 5, marginRight: 5 }}> | </span>}
                        {/* eslint-disable-next-line */}
                        {!this.state.clearAll && !this.state.selected && <a onClick={this.showClearWarning} style={{ color: 'rgba(0, 0, 0, 1)', textDecoration: 'none', marginLeft: '5px', cursor:'pointer' }}>Clear All</a>}
                       
                        
                    </div>
                </div>
                {this.state.isLoading && 
                <div style={{width: '470px' }} className='d-flex flex-row w-100 justify-content-center mt-5'>
                    <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                    </div>
                </div>}
                {!this.state.showClearAlert && !this.state.isLoading && this.state.notifications.length !== 0 && !this.state.selected && <div style={{ height: '50rem' }}>{list}</div>}
                {!this.state.showClearAlert && !this.state.isLoading && this.state.notifications.length === 0 && !this.state.selected && <div className='d-flex flex-column justify-content-center' style={{ marginTop: '20%', marginLeft: '20%', fontWeight: '400px', width: 470 }}>
                    <svg width="60" height="60" viewBox="0 0 39 47" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginLeft: '100px' }}>
                        <path d="M19.2821 47C21.9333 47 24.1026 44.8308 24.1026 42.1795H14.4615C14.4615 44.8308 16.6067 47 19.2821 47ZM33.7436 32.5385V20.4872C33.7436 13.0877 29.7908 6.89333 22.8974 5.25436V3.61538C22.8974 1.61487 21.2826 0 19.2821 0C17.2815 0 15.6667 1.61487 15.6667 3.61538V5.25436C8.74923 6.89333 4.82051 13.0636 4.82051 20.4872V32.5385L0 37.359V39.7692H38.5641V37.359L33.7436 32.5385Z" fill="#666666" />
                    </svg>
                    <p style={{ fontSize: '30px' }}> No Notifications yet. </p>
                </div>}
                {!this.state.isLoading && this.state.notifications.length !== 0 && this.state.selected &&
                <NotifDetails viewOneNotif={this.viewOneNotif} selected={this.state.selected}/>
                }
                {!this.state.isLoading && this.state.showClearAlert && <div style={{borderRadius: 20}} className='border border-dark m-4'>
                        <p className='m-2'>Are you sure you want to clear all notifications?</p>
                        <div className='d-flex flex-row justify-content-around m-2'>
                        <button onClick={this.onClearAll} className='btn btn-sm btn-info m-2'>Yes</button>
                        <button onClick={this.showClearWarning} className='btn btn-sm btn-danger m-2'>No</button>
                        </div>
                    </div>}
            </div>
        );
    }
}

export default Notifications;