import { 
	Card,
	CardHeader,
	CardMedia,
	CardContent,
	Slide,
	Box,
	Typography,
	Stack
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FolderIcon from '@mui/icons-material/Folder';
import IconButton from "@mui/material/IconButton";
import React, { useState, useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import moment from "moment";

const PreviewDetails = (props) => {
	const [docs, setDocs] = useState([]);

	useEffect(() => {
		if (props.data?.file_name !== null) {
			console.log([{ uri: props.data?.file_url, fileType: props.data?.file_ext }]);
			setDocs([{ uri: props.data?.file_url, fileType: props.data?.file_ext }]);
		}
	}, [props.data]);

	return (
		<Box sx={{ height: "calc(100vh - 75px)", maxWidth: "300px", width: "100%", position: "fixed", right: 0, bottom: 0, zIndex: props.open ? 1000 : -1 }}>
			<Slide direction="left" in={props.open} timeout={200} mountOnEnter unmountOnExit>
				<Card variant="outlined" sx={{ height: "100%"}}>
					<CardHeader
						title={
							<Typography sx={{ fontWeight: "bold" }}>
								{props.data?.file_path[props.data?.file_path.length - 1]}
							</Typography>
						}
						action={
							<IconButton onClick={props.handlePreviewClose}>
								<CloseIcon />
							</IconButton>
						}
						sx={{ padding: "10px", margin: "0px" }}
					/>
					{props.data?.file_name === null ?
						<Box sx={ { height: "300px", display: "flex", alignItems: "center", justifyContent: "center", borderBottom: "1px solid gray" }}>
							<FolderIcon sx={{ fontSize: "100px", color: "#007bff", margin: "auto", display: "block" }} />
						</Box> :
						<Box sx={{ height: "300px", overflow: "hidden", borderBottom: "1px solid gray" }}>
							<DocViewer
								key={props.data?.file_url}
								pluginRenderers={DocViewerRenderers}
								documents={docs}
								style={{ height: 300, zIndex: -100 }}
								config={{
									header: {
										disableHeader: true
									}
								}}
							/>
						</Box>
					}
					<CardContent sx={{ mx: "10px" }}>
						<Typography variant="h6" sx={{ fontWeight: "bold" }}>
							{(props.data?.file_name === null ? "Folder" : "File") + " Details"}
						</Typography>
						<Stack spacing={1} sx={{ marginTop: "10px" }}>
							{
								props.data?.file_name === null ?
								<>
									<Box>
										<Typography variant="caption" sx={{ color: "gray" }}>Created Date</Typography>
										<Typography variant="body1" sx={{ fontWeight: "bold" }}>{moment(props.data?.directory_added_date).format("MMM DD YYYY, h:mm a")}</Typography>
									</Box>
									{props.data?.directory_modified_date &&
										<Box>
											<Typography variant="caption" sx={{ color: "gray" }}>Modified Date</Typography>
											<Typography variant="body1" sx={{ fontWeight: "bold" }}>{moment(props.data?.directory_modified_date).format("MMM DD YYYY, h:mm a")}</Typography>
										</Box>
									}
								</> :
								<>
									<Box>
										<Typography variant="caption" sx={{ color: "gray" }}>Type</Typography>
										<Typography variant="body1" sx={{ fontWeight: "bold" }}>{props.data?.file_url.split(".").pop().toUpperCase()}</Typography>
									</Box>
									<Box>
										<Typography variant="caption" sx={{ color: "gray" }}>Upload Date</Typography>
										<Typography variant="body1" sx={{ fontWeight: "bold" }}>{moment(props.data?.upload_date).format("MMM DD YYYY, h:mm a")}</Typography>
									</Box>
									{props.data?.owner &&
										<Box>
											<Typography variant="caption" sx={{ color: "gray" }}>Uploaded By</Typography>
											<Typography variant="body1" sx={{ fontWeight: "bold" }}>{props.data?.owner}</Typography>
										</Box>
									}
									{props.data?.client &&
										<Box>
											<Typography variant="caption" sx={{ color: "gray" }}>Client</Typography>
											<Typography variant="body1" sx={{ fontWeight: "bold" }}>{props.data?.client}</Typography>
										</Box>
									}
								</>
							}
						</Stack>
					</CardContent>
				</Card>
			</Slide>
		</Box>
	);
};


export default PreviewDetails;
