import React, { Component } from "react";
import { Tab, Row, Col, Nav, Accordion, Card } from "react-bootstrap";
import Home from '../Home/Home';
import TasksPage from '../Tasks/TasksPage';
import ContactsPage from '../Contacts/Contacts';
import Employees from "../Employees/Employees";
import Artifacts from "../Artifacts/Artifacts";
import Notes from '../Notes/Notes'
import moment from "moment";
import axios from 'axios';
import {APIURL} from '../../../config'

class ProjectSideBar extends Component {
    state = {
        key: "Home",
        collapse: false,
        project: {}
    }
    
    async componentDidMount(){
        this.props.checkTokenExpiry()
        // console.log("Individual project Details")
        let project = await axios.get(APIURL+"/project",{params: {proj_id:this.props.project_details.pro_id ,comp_id: this.props.comp_id}})
        // console.log(project.data)
        this.setState({project: project.data,key:"Home"})
    }

    // componentDidUpdate(){
    //     console.log('I have just updated')
    //     // this.setState({key:"Home"})
    // }

    onSideBarChange = (key) => {
        if ((key !== "Ongoing" || key !== "Upcoming" || key !== "Completed") && this.state.collapse) {
            this.toggle.click()
        }

        if (key === "Ongoing" || key === "Upcoming" || key === "Completed") {
            this.setState({ key, collapse: true })
            return
        }

        this.setState({ key })
    }

    onToggle = () => {
        const oldState = { ...this.state }
        oldState.collapse = !this.state.collapse
        if (oldState.collapse) {
            oldState.key = "Ongoing"
        }
        this.setState(oldState)
    }

    isFormValidated = (objForVal) => {
        for (let objKey in objForVal) {
          console.log(objKey);
          if ((objForVal[objKey] === "" || objForVal[objKey] === undefined || objForVal[objKey].length === 0)) {
            if((objKey === 'address2' || objKey === 'location_addr2' || objKey === 'tComment' || objKey === 'address_line_2')){
              // return true
              continue
            }else{
              return false 
            }
          } else if (Array.isArray(objForVal[objKey])) {
            let arrValid = objForVal[objKey].every(function (objTesting) {
              for (let objTestKey in objTesting) {
                console.log(objTestKey)
                if (objTesting[objTestKey] === "" || objTesting[objTestKey] === undefined) { 
                  if((objTestKey === 'address_line_2'|| objTestKey === 'location_addr2')){
                    // return true
                    continue
                  }else{
                    return false
                  } 
                }
              }
              return true
            })
            if (!arrValid) return false
          }
        }
        return true
      }

    render() {
        // console.log(this.state.project.pro_id)
        if(this.state.project.pro_id){
        return (<div>
            <p style={{ fontSize: "0.9rem", fontFamily: "Lato", cursor: "pointer" }} onClick={() => this.props.onBack()}><span style={{ paddingRight: 20, paddingLeft: 20, fontSize: "1rem" }}><i className="fas fa-arrow-left"></i></span> PROJECTS</p>
            <Tab.Container id="tab" className='d-flex flex-lg-row flex-md-row flex-sm-column' activeKey={this.state.key}>
                <Row>
                    <Col lg={3} md={4} sm={6} style={{maxWidth:'20rem'}}>
                        <Nav className="flex-column">
                            <Nav.Item >
                                <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} eventKey="Home" onSelect={key => this.onSideBarChange(key)}><span><i style={{ fontSize: "1.5rem", paddingRight: "20px", paddingLeft: "0px" }} className="fas fa-home"></i></span>Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item >
                                <Accordion>
                                    <Accordion.Toggle className="list-group-item list-group-item-action" active={this.state.collapse} style={{ outline: "none" }} as={Nav.Link} eventKey="0" onClick={() => this.onToggle()} ref={(toggleEvent) => this.toggle = toggleEvent}>
                                        <span><i style={{ fontSize: "1.5rem", paddingRight: "20px", paddingLeft: "0px" }} className="fas fa-puzzle-piece"></i></span>
                                        Tasks
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <Nav.Item>
                                                <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} active={(this.state.key === "Ongoing") ? true : false} eventKey="0" onSelect={() => this.onSideBarChange("Ongoing")}>Ongoing</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} active={(this.state.key === "Upcoming") ? true : false} eventKey="0" onSelect={() => this.onSideBarChange("Upcoming")}>Upcoming</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} active={(this.state.key === "Completed") ? true : false} eventKey="0" onSelect={() => this.onSideBarChange("Completed")}>Completed</Nav.Link>
                                            </Nav.Item>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Accordion>
                                {/* <Nav.Link className="list-group-item list-group-item-action" eventKey="Tasks"><span><i style={{ fontSize: "1.5rem", paddingRight: "23px", paddingLeft: "0px" }} className="fas fa-puzzle-piece"></i></span>Tasks</Nav.Link> */}
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} eventKey="Artifacts" onSelect={key => this.onSideBarChange(key)}><span><i style={{ fontSize: "1.5rem", paddingRight: "29px", paddingLeft: "0px" }} className="far fa-file"></i></span>Artifacts</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} eventKey="Notes" onSelect={key => this.onSideBarChange(key)}><span><i style={{ fontSize: "1.5rem", paddingRight: "23px", paddingLeft: "0px" }} className="fas fa-comment-dots"></i></span>Notes</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} eventKey="Contacts" onSelect={key => this.onSideBarChange(key)}><span><i style={{ fontSize: "1.5rem", paddingRight: "23px", paddingLeft: "0px" }} className="fas fa-user-alt"></i></span>Contacts</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} eventKey="Employees" onSelect={key => this.onSideBarChange(key)}><span><i style={{ fontSize: "1.5rem", paddingRight: "27px", paddingLeft: "0px" }} className="fas fa-user-tie"></i></span>Employees</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col lg={8} md={8} sm={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="Home">
                                <Home
                                    {...this.props}
                                    history={this.props.history}
                                    title={this.state.project.pro_title}
                                    id={this.state.project.pro_id}
                                    status={this.state.project.pro_is_active} // its boolean, what if project is upcoming ?
                                    start_date={this.state.project.pro_starts_on}
                                    end_date={this.state.project.pro_ends_on}
                                    customer_details={this.state.project.customer_details}
                                    project_tasks={this.state.project.tasks}
                                    customerStatus={this.props.dropDowns.customerStatus}
                                    customerType={this.props.dropDowns.customerType}
                                    states={this.props.dropDowns.states}
                                    empList={this.state.project.employees}
                                    allEmployees={this.props.dropDowns.employee_list}
                                    locations={this.state.project.locations}
                                    loc_list={this.props.dropDowns.location_list}
                                    task_list={this.props.dropDowns.task_list}
                                    pManagerId={this.state.project.pro_manager_id}
                                    isFormValidated={this.isFormValidated}
                                    comp_id ={this.props.comp_id}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Ongoing">
                                <TasksPage
                                    status="Ongoing"
                                    history={this.props.history}
                                    project_details={
                                        {
                                            pname: this.props.project_details.pro_title,
                                            pid: this.props.project_details.pro_id,
                                            start_date: this.props.project_details.pro_starts_on,
                                            end_date: this.props.project_details.pro_ends_on
                                        }
                                    }
                                    location_list={this.props.project_details.locations}
                                    taskType={this.props.dropDowns.taskType}
                                    project_tasks={!this.props.project_details.tasks?[]:this.props.project_details.tasks.filter((task) => (task.tas_is_active === 0 && moment(task.tas_start_date).isSameOrBefore(new Date())))}
                                    title={this.props.project_details.pro_title}
                                    employees={this.props.project_details.employees}
                                    allEmployees={this.props.dropDowns.employee_list}
                                    comp_id ={this.props.comp_id}
                                    isFormValidated={this.isFormValidated} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Upcoming">
                                <TasksPage
                                    status="Upcoming"
                                    history={this.props.history}
                                    project_details={
                                        {
                                            pname: this.props.project_details.pro_title,
                                            pid: this.props.project_details.pro_id,
                                            start_date: this.props.project_details.pro_starts_on,
                                            end_date: this.props.project_details.pro_ends_on
                                        }
                                    }
                                    location_list={this.props.project_details.locations}
                                    taskType={this.props.dropDowns.taskType}
                                    project_tasks={!this.props.project_details.tasks?[]:this.props.project_details.tasks.filter((task) => (task.tas_is_active === 0 && moment(task.tas_start_date).isAfter(new Date())))}
                                    title={this.props.project_details.pro_title}
                                    employees={this.props.project_details.employees}
                                    allEmployees={this.props.dropDowns.employee_list}
                                    comp_id ={this.props.comp_id}
                                    isFormValidated={this.isFormValidated}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Completed">
                                <TasksPage
                                    status="Completed"
                                    history={this.props.history}
                                    project_details={
                                        {
                                            pname: this.props.project_details.pro_title,
                                            pid: this.props.project_details.pro_id,
                                            start_date: this.props.project_details.pro_starts_on,
                                            end_date: this.props.project_details.pro_ends_on
                                        }
                                    }
                                    location_list={this.props.project_details.locations}
                                    taskType={this.props.dropDowns.taskType}
                                    project_tasks={!this.props.project_details.tasks?[]:this.props.project_details.tasks.filter((task) => (task.tas_is_active === 1))}
                                    title={this.props.project_details.pro_title}
                                    employees={this.props.project_details.employees}
                                    allEmployees={this.props.dropDowns.employee_list}
                                    comp_id ={this.props.comp_id}
                                    isFormValidated={this.isFormValidated}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Artifacts">
                                <Artifacts
                                    projectId={this.props.project_details.pro_id}
                                    project_title={this.props.project_details.pro_title}
                                    taskList={this.props.project_details.tasks}
                                    employeeList={this.props.project_details.employees}
                                    empList={this.state.project.employees}
                                    taskType={this.props.dropDowns.taskType}
                                    user={this.props.user}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Notes">
                                <Notes
                                projectId={this.props.project_details.pro_id}
                                project_title={this.props.project_details.pro_title}
                                empType={this.props.dropDowns.empType}
                                allEmployees={this.props.dropDowns.employee_list}
                                user={this.props.user}
                                comp_id ={this.props.comp_id}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Contacts">
                                <ContactsPage
                                    contacts={!this.props.project_details.contacts ? [] : this.props.project_details.contacts.filter((con)=>(con.con_is_active !== false))}
                                    contactsList={!this.props.project_details.rel_contacts ? [] : this.props.project_details.rel_contacts.filter((con)=>(con.con_is_active !== false))}
                                    project_title={this.props.project_details.pro_title}
                                    projId={this.props.project_details.pro_id}
                                    comp_id ={this.props.comp_id}
                                    isFormValidated={this.isFormValidated}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Employees">
                                <Employees
                                    employees={this.props.project_details.employees}
                                    project_title={this.props.project_details.pro_title}
                                    projId={this.props.project_details.pro_id}
                                    project_manager_id={this.props.project_details.pro_manager_id}
                                    project_start_date={this.props.project_details.pro_starts_on}
                                    project_end_date={this.props.project_details.pro_ends_on}
                                    isFormValidated={this.isFormValidated}
                                    allEmployees={this.props.dropDowns.employee_list}
                                    empStatus={this.props.dropDowns.empStatus}
                                    comp_id ={this.props.comp_id}
                                    frequency={this.props.dropDowns.frequency}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>)
        }else{
            return(
            <div className="d-flex flex-row justify-content-center">
            <div style={{color:'#3FA7D6', height: '10rem', width:'10rem',marginTop: 120}} className="spinner-border text-info" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            </div>
            )
        }
    }
}

export default ProjectSideBar;