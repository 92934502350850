import React, { useState, useEffect } from 'react';
import { JsonForms } from '@jsonforms/react';
import schema from './schema.json';
import uischema from './uischema.json';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import { APIURL } from '../../../config';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './MonthlyMonitering.css';
import moment from 'moment';

const MonthlyMonitering = (props) => {
    const [info, setInfo] = useState({})
    const [data, setData] = useState({});
    const [initArtifact, setInitArtifact] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState(false);

    const getData = async () => {
        const artifact =  await axios.get(APIURL+"/artifacts/json", { params: {task_id: props.task_id}});

        const res = await axios.get(APIURL+"/task/monthlymonitoring", { params: { emp_id: props.user.emp_id, task_id: props.task_id }});
        let data = res.data.data;

        if(artifact.data) {
            setData(artifact.data.json);
            setInitArtifact(artifact.data.id);
        }

        setInfo({
            individual_name: data.cus_name,
            ddd_id: data.ddd_id,
            individual_contact: data.cus_phone,
            date_of_contact: moment(data.tas_start_date).format("MM/DD/YYYY"),
            support_coordinator: data.emp_name,
            support_coordination_agency: data.com_name,
            contact_period: moment(data.tas_start_date).format("MMM YYYY"),
            contact_method: data.tas_type,
            date_of_approved_plan: data.plan_approval_date,
            info_to_support_coordinator: "",
            contact_location: data.location.loc_addr_line1 + " " + data.location.loc_addr_line2 + ", " + data.location.loc_city + ", " + data.location.loc_state + ", " + data.location.loc_zip,
            reporting_period: moment().format("MM/DD/YYYY")
        })
    }

    useEffect(() => {
        getData().then(() => {setIsLoading(false)});
    }, [])

    const onSubmit = async () => {
        let result1 = {data: {code: 200}};

        if(initArtifact) {
            result1 = await axios.delete(APIURL+`/artifacts/json`, { params: {id: initArtifact}});
        }

        let body = {
            json: JSON.stringify({...info, ...data}),
            task_id: props.task_id,
            emp_id: props.user.emp_id
        }

        let result2 = await axios.post(APIURL+`/artifacts/json`, body);

        if(result1.data.code === 200 && result2.data.code === 200) {
            setUpdated(true)
        } else {
            setError(true)
        }
        
        setInitArtifact(result2.data.id)
    }


    return (
        <div style={{maxWidth: '1200px'}}>
            {!isLoading && <div>
                <h4 className="identifying-title">Individual Information</h4>
                <Container className="identifying-information-container p-0">
                    <Row>
                        <Col><div className="identifying-label">Individual Name</div><div>{info.individual_name}</div></Col>
                        <Col><div className="identifying-label">DDD ID</div><div>{info.ddd_id}</div></Col>
                        <Col><div className="identifying-label">Date of Contact</div><div>{info.date_of_contact}</div></Col>
                    </Row>
                    <Row>
                        <Col><div className="identifying-label">Support Coordinator</div><div>{info.support_coordinator}</div></Col>
                        <Col><div className="identifying-label">Support Coordination Agency</div>{info.support_coordination_agency}</Col>
                        <Col><div className="identifying-label">Individual’s Contact</div><div>{info.individual_contact}</div></Col>
                    </Row>
                    <Row>
                        <Col><div className="identifying-label">Contact Period</div><div>{info.contact_period}</div></Col>
                        <Col><div className="identifying-label">Contact Method</div><div>{info.contact_method}</div></Col>
                        <Col><div className="identifying-label">Date of Approved Plan</div><div>{info.date_of_approved_plan}</div></Col>
                    </Row>
                    <Row>
                        <Col><div className="identifying-label">Name/Relationship of Person Providing Information to Support Coordinator</div><div>{"Martin Thomas"}</div></Col>
                        <Col><div className="identifying-label">Contact Location</div><div>{info.contact_location}</div></Col>
                        <Col><div className="identifying-label">Reporting Period</div><div>{info.reporting_period}</div></Col>
                    </Row>
                    <div className="identifying-note">*Please complete all of the following sections based on your observations/conversations. Please include in your 
                               comments the type of service you are commenting about, including but not limited to employment, day, 
                               transportation, individuals supports, etc</div>
                </Container >
                <JsonForms
                    schema={schema}
                    uischema={uischema}
                    data={data}
                    renderers={materialRenderers}
                    cells={materialCells}
                    onChange={({ data, _errors }) => {setData(data)}}
                />
                <div className="mm-submit-container">
                    <Button className="mm-submit-button" onClick={onSubmit}>Save</Button>
                </div>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={updated} autoHideDuration={6000} onClose={()=>{setUpdated(false)}}>
                <MuiAlert onClose={()=>{setUpdated(false)}} severity="success">
                   SUCCESSFULLY UPDATED FORM
                </MuiAlert>
                </Snackbar>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={error} autoHideDuration={6000} onClose={()=>{setError(false)}}>
                  <MuiAlert onClose={()=>{setError(false)}} severity="error">
                    ERROR OCCURRED WHILE UPDATING FORM
                  </MuiAlert>
                </Snackbar>
            </div>}
        </div>
    )
}

export default MonthlyMonitering;