import { Box, Skeleton } from "@mui/material";

const LoadingSkeleton = () => {
    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            {
                [...Array(40)].map((_, i) => (
                    <Skeleton key={i} variant="rectangular" height={21} sx={{ mx: 1, my: 2 }} />
                ))
            }
        </Box>
    )
}

export default LoadingSkeleton;