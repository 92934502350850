import React, { useState, useEffect } from "react"
import { APIURL } from '../../config'
import axios from 'axios'
import moment from 'moment'
import { FormGroup, FormLabel, FormControlLabel, ListItemText, Checkbox, Input, Snackbar, InputLabel, MenuItem, FormControl, Select, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { Alert, Autocomplete } from '@mui/lab';
import MessagePage from "../../components/MessagePages/MessagePage"

export default function BasicCompanySettingsModal(props) {
    const [showModal, setShowModal] = useState(false);
    const [showErrorSnackBar, setShowErrorSnackBar] = useState(false);

    const [primaryBusinessTypeList, setPrimaryBusinessTypeList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [moduleList, setModuleList] = useState([]);

    const [selectedPrimaryBusinessType, setSelectedPrimaryBusinessType] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedModules, setSelectedModules] = useState([]);

    const [currentCompanySettings, setCurrentCompanySettings] = useState([]);

    useEffect(() => {
        const fetch = async() => {
            if(await check()){
                await getData();
                setShowModal(true)
            }
        }
        fetch();
    }, []);

    useEffect(() => {
        if(currentCompanySettings.length < 1){
            return
        }

        try{
            // Primary Business Type
            let primaryBusinessType = currentCompanySettings.find(setting => setting.feature_id >= 101 && setting.feature_id <= 104)
            let currentType = primaryBusinessTypeList.find(businessType => businessType.feature_id === primaryBusinessType.feature_id)
            setSelectedPrimaryBusinessType(currentType)
        } catch (err) {

        }

        // State
        try{
            let firstState = currentCompanySettings.find(setting => setting.feature_id===99)
            let currentState = stateList.find(state => state.isoCode === firstState.state_id)
            setSelectedState(currentState)
        } catch (err) {

        }

        try{
            // Modules
            let modules = currentCompanySettings.filter(setting => setting.feature_value == 'functionality')
            let oldModules = modules.map(module => module.feature_id)
            setSelectedModules(oldModules)
        } catch (err) {

        }
        
    }, [currentCompanySettings])

    async function check(){
        if(props.user.type==='Admin'){
            let currentPrimaryBusinessTypes = await axios.get(APIURL+"/config/settings", {params:{comp_id: props.user.comp_id}})
            currentPrimaryBusinessTypes.data = currentPrimaryBusinessTypes.data.filter(item => item.feature_id > 100 && item.feature_id < 106)
            let currentStates = await axios.get(APIURL+"/config/settings", {params:{comp_id: props.user.comp_id, feature_id: "99"}})
            let currentModules = await axios.get(APIURL+"/config/settings", {params:{comp_id: props.user.comp_id, feature_value: "functionality"}})

            if(currentPrimaryBusinessTypes.data.length===0 || currentStates.data.length===0 || currentModules.data.length===0)
                return true
            return false
        }
    }    

    async function getData(){
        let primaryBusinessTypes = await axios.get(APIURL+"/config/defaults", {params:{feature_type: "primaryBusinessType"}})
        setPrimaryBusinessTypeList(primaryBusinessTypes.data)

        let stateListResponse = await axios.get(APIURL+"/dropdowns/states/v2")
        setStateList(stateListResponse.data.states)

        let modulesDefaults = await axios.get(APIURL+"/config/defaults", {params:{feature_type: "functionality"}})
        setModuleList(modulesDefaults.data)

        let companySettings = await axios.get(APIURL+"/config/settings", {params:{comp_id: props.user.comp_id}})
        setCurrentCompanySettings(companySettings.data)
    }

    async function handleSubmit(){
        if(selectedPrimaryBusinessType === '' || selectedState === undefined || selectedModules.length === 0){
            setShowErrorSnackBar(true)
            return;
        }
        
        console.log(currentCompanySettings)
        for(let i=0; i<currentCompanySettings.length; i++){
            if( currentCompanySettings[i].feature_id === 99 || currentCompanySettings[i].feature_value === 'functionality' || (currentCompanySettings[i].feature_id > 100 && currentCompanySettings[i].feature_id < 106) ){
                let deleteBody = {
                    
                }
                await axios.delete(APIURL + '/config/settings', { data: { settings_id: currentCompanySettings[i].settings_id } } )
            }
        }

        let primaryBusinessTypeData = {
            company_id: props.user.comp_id,
            feature_id: selectedPrimaryBusinessType.feature_id,
            feature_value: 'primaryBusinessType',
            state_id: selectedState.isoCode,
            added_by: props.user.emp_id
        }
        let primaryBusinessTypeResponse = await axios.post(APIURL + '/config/settings', primaryBusinessTypeData)
        
        let stateData = {
            company_id: props.user.comp_id,
            feature_id: 99,
            feature_value: selectedState.name,
            state_id: selectedState.isoCode,
            added_by: props.user.emp_id
        }
        let saveStateResponse = await axios.post(APIURL + '/config/settings', stateData)
        
        for(let i=0; i<selectedModules.length; i++){
            let modulesData = {
                company_id: props.user.comp_id,
                feature_id: selectedModules[i],
                feature_value: 'functionality',
                state_id: selectedState.isoCode,
                added_by: props.user.emp_id
            }
            let modulesResponse = await axios.post(APIURL + '/config/settings', modulesData)
        }
        
        setShowModal(false)
    }
    
    return (
        <div>
            <Snackbar open={showErrorSnackBar} autoHideDuration={6000} onClose={() => setShowErrorSnackBar(false)}>
                <Alert onClose={() => setShowErrorSnackBar(false)} severity="error" sx={{ width: '100%' }}>
                Please make sure all of the fields are filled!
                </Alert>
            </Snackbar>
            <Dialog open={showModal} onClose={() => setShowModal(true)}>
                <DialogTitle>Company Setup</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{}}>
                        Hi {props.user.emp_name}, there's just a few more steps to complete the configuration of {props.user.company_name}!
                    </DialogContentText>
                    <FormControl fullWidth style={{marginTop: '30px', marginBottom: '30px'}}>
                        <Autocomplete
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedPrimaryBusinessType}
                            renderInput={(params) => <TextField {...params} label="What is your company's primary business?" />}
                            options={primaryBusinessTypeList}
                            getOptionLabel={(option) => option === undefined || option === '' ? '' : option.feature_value}
                            onChange={((event, selectedOption) => {
                                setSelectedPrimaryBusinessType(selectedOption)
                            })}
                        >   {primaryBusinessTypeList.length > 0 && primaryBusinessTypeList.map((type, index) => {
                            return <MenuItem key={index} value={type}>{type.feature_value}</MenuItem>
                        })}
                        </Autocomplete>
                    </FormControl>
                    <FormControl fullWidth style={{marginBottom: '30px'}}>
                        <Autocomplete
                            style={{width: '100%'}}
                            id="state-select"
                            disablePortal
                            options={stateList}
                            value = {selectedState}
                            getOptionLabel={(option) => option === undefined || option === '' ? '' : option.name}
                            sx={{ width: 300 }}
                            MenuProps={{variant:'menu'}}
                            onChange={((event, selectedOption) => {
                                setSelectedState(selectedOption)
                            })}
                            renderInput={(params) => <TextField {...params} label="What state is your business in?" />}
                        />
                    </FormControl>
                    
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Customize FieldWorker - select the features you want to use.</FormLabel>
                        <FormGroup>
                            {moduleList.map(module => {
                                return <FormControlLabel
                                        control={<Checkbox onChange={(event) => {
                                            if(event.target.checked){
                                                let newModules = selectedModules;
                                                newModules.push(module.feature_id)
                                                setSelectedModules(newModules)
                                                setSelectedModules([...selectedModules])
                                            } else {
                                                setSelectedModules(selectedModules.filter(selectedModule => selectedModule !== module.feature_id))
                                            }
                                        }} 
                                        checked = {selectedModules.includes(module.feature_id)}
                                        color='primary'
                                        name={module.feature_value} />}
                                        label={module.feature_value}
                                        key={module.feature_id}
                                    />
                            })}
                            
                        </FormGroup>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    {/*<Button onClick={() => setShowModal(false)}>Skip for Now</Button>*/}
                    <Button onClick={() => handleSubmit()}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );

}

