import React, { Component } from "react";
import axios from "axios";
// import './SideBar.css';
// import NewProjForm from './NewProjForm';
import ProjectForm from "../../components/forms/ProjectForm/ProjectForm";
import CustomerForm from "../../components/forms/CustomerForm/CustomerForm";
import ContactForm from "../../components/forms/ContactForm/ContactForm";
import EmployeeForm from "../../components/forms/EmployeeForm/EmployeeForm";
import TaskForm from "../../components/forms/TaskForm/TaskForm";
import LocationForm from "../../components/forms/LocationForm/LocationForm";
// import BulkUpload from '../../components/forms/BulkUploadForm/Bulkupload';
import RecordActivity from "../../components/forms/RecordActivityForm/RecordActivity";
import NoteFormv2 from "../../components/forms/NoteForm/NoteFormv2";
// import TimeSheetListGrid from '../../components/forms/TimeSheetGridForm/TimeSheetListGrid'
import { APIURL } from "../../config";
import BulkUploadv1 from "../../components/forms/BulkUploadForm/Bulkuploadv1";
import BulkUploadv2 from "../../components/forms/BulkUploadForm/Bulkuploadv2";
import MsgModalBox from '../../container/MessageModalBox/MsgModalBox';
import { Redirect } from 'react-router-dom';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })


class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goHome: false,
      showNoteCreated: false,
      isLoading: true,
      employees: {
        list: [{}],
        empType: [],
        empLevel: [],
        empStatus: [],
        currency: [],
        frequency: [],
      },
      loggedToSegment: false,
      customers: { list: [{}], customerType: [], customerStatus: [] },
      projects: { list: [] },
      tasks: { list: [{}], taskType: [] },
      contacts: {
        list: [{}],
        contactType: [],
        designation: [],
        contactStatus: [],
        countryCode: [],
      },
      locations: { list: [{}], states: [], locType: [], locStatus: [] },
      mapRender: this.props.mapRender || this.props.location.state.mapRender || "task",
      renderComponent: null,
    };
  }

  logToSegment = async () => {
    if (this.state.loggedToSegment) {
      return;
    }
    let page = String(window.location.href).split("/").pop()
    if (page.includes("fieldworker") || page === "" || page === "#") {
      page = "Home";
    } else {
      page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
    }
    console.log(page, this.props.user.emp_id)
    analytics.page(page, {
      title: page,
      path: page,
    }, {
      userId: String(this.props.user.emp_id),
    })
    console.log("logged to segment successfully")
    this.setState({ loggedToSegment: true });
  }

  async componentDidMount() {
    this.setState({ mapRender: 'task' })
    await this.props.checkTokenExpiry();
    await this.logToSegment();

    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    // let data = {
    //   employees: {
    //     list: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/employeeslist", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     empType: [],
    //     empLevel: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/empLevel", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     empStatus: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/empStatus", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     currency: [],
    //     frequency: (
    //       await axios.get(APIURL + "/config/defaults", {
    //         params: { feature_type: "payrollFrequency" },
    //       })
    //     ).data,
    //   },
    //   customers: {
    //     list: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/customerslist", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     customerType: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/customerType", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     customerStatus: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/customerStatus", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //   },
    //   projects: {
    //     list: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/projectslist", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //   },
    //   tasks: {
    //     list: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/taskslist", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     taskType: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/taskType", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //   },
    //   contacts: {
    //     list: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/contactslist", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     contactType: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/contactType", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     designation: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/designation", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     contactStatus: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/contactStatus", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     countryCode: [],
    //   },
    //   locations: {
    //     list: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/locationslist", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     states: [],
    //     locType: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/locType", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //     locStatus: (
    //       await axios.get(APIURL + "/dropdowns/dropdownType/locStatus", {
    //         params: { comp_id: this.props.comp_id },
    //       })
    //     ).data,
    //   },
    // };

    // this.setState({ ...data });

    if (this.state.mapRender === 'task') {
      await this.getTaskData();
    }
    if (this.state.mapRender === 'proj') {
      await this.getProjData();
    }
    if (this.state.mapRender === 'con') {
      await this.getContData();
    }
    if (this.state.mapRender === 'cust') {
      await this.getCustData();
    }
    if (this.state.mapRender === 'emp') {
      await this.getEmpData();
    }
    if (this.state.mapRender === 'note') {
      await this.getNoteData();
    }
    if (this.state.mapRender === 'loc') {
      await this.getLocData();
    }
    if (this.state.mapRender === 'bulk') {
      await this.getBulkData();
    }
    if (this.state.mapRender === 'act') {
      await this.getActData();
    }

    this.setState({ isLoading: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.mapRender !== this.state.mapRender) {
      this.setState({ isLoading: true });

      if (this.state.mapRender === 'task') {
        await this.getTaskData();
      }
      if (this.state.mapRender === 'proj') {
        await this.getProjData();
      }
      if (this.state.mapRender === 'con') {
        await this.getContData();
      }
      if (this.state.mapRender === 'cust') {
        await this.getCustData();
      }
      if (this.state.mapRender === 'emp') {
        await this.getEmpData();
      }
      if (this.state.mapRender === 'note') {
        await this.getNoteData();
      }
      if (this.state.mapRender === 'loc') {
        await this.getLocData();
      }
      if (this.state.mapRender === 'bulk') {
        await this.getBulkData();
      }
      if (this.state.mapRender === 'act') {
        await this.getActData();
      }

      this.setState({ isLoading: false });
    }
  }

  getTaskData = async () => {
    let data = {
      employees: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/employeeslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      projects: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/projectslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      tasks: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/taskslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      locations: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/locationslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
    };

    this.setState({ ...data });

    this.setState({
      renderComponent: (<div
        className={`tab-pane fade ${this.state.mapRender === "task" ? "show active" : ""
          }`}
        id="list-home"
        role="tabpanel"
        aria-labelledby="list-home-list"
      >
        <TaskForm
          {...this.props}
          isFormValidated={this.isFormValidated}
          mapRender={this.state.mapRender}
          taskList={this.state.tasks.list}
          empList={this.state.employees.list}
          locList={this.state.locations.list}
          projList={this.state.projects.list}
          updateMapRender={this.state.mapRender}
          comp_id={this.props.comp_id}
        />
      </div>)
    });
  }

  getProjData = async () => {
    let data = {
      employees: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/employeeslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        empStatus: (
          await axios.get(APIURL + "/dropdowns/dropdownType/empStatus", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        currency: [],
        frequency: (
          await axios.get(APIURL + "/config/defaults", {
            params: { feature_type: "payrollFrequency" },
          })
        ).data,
      },
      customers: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/customerslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      contacts: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/contactslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      locations: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/locationslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
    };

    this.setState({ ...data });

    this.setState({
      renderComponent: (<div
        className={`tab-pane fade ${this.state.mapRender === "proj" ? "show active" : ""
          }`}
        id="list-profile"
        role="tabpanel"
        aria-labelledby="list-profile-list"
      >
        <ProjectForm
          {...this.props}
          isFormValidated={this.isFormValidated}
          mapRender={this.state.mapRender}
          customerList={this.state.customers.list}
          empList={this.state.employees.list}
          contactList={this.state.contacts.list}
          locList={this.state.locations.list}
          empStatus={this.state.employees.empStatus}
          currency={this.state.employees.currency}
          frequency={this.state.employees.frequency}
          comp_id={this.props.comp_id}
        />
      </div>)
    });
  }

  getContData = async () => {
    let data = {
      customers: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/customerslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      contacts: {
        contactType: (
          await axios.get(APIURL + "/dropdowns/dropdownType/contactType", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        designation: (
          await axios.get(APIURL + "/dropdowns/dropdownType/designation", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        contactStatus: (
          await axios.get(APIURL + "/dropdowns/dropdownType/contactStatus", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      locations: {
        states: [],
      },
    };

    this.setState({ ...data });

    this.setState({
      renderComponent: (<div
        className={`tab-pane fade ${this.state.mapRender === "con" ? "show active" : ""
          }`}
        id="list-messages"
        role="tabpanel"
        aria-labelledby="list-messages-list"
      >
        <ContactForm
          {...this.props}
          isFormValidated={this.isFormValidated}
          states={this.state.locations.states}
          contactType={this.state.contacts.contactType}
          contactStatus={this.state.contacts.contactStatus}
          designation={this.state.contacts.designation}
          customerList={this.state.customers.list}
          comp_id={this.props.comp_id}
        />
      </div>)
    });
  }

  getCustData = async () => {
    let data = {
      employees: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/employeeslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      customers: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/customerslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        customerType: (
          await axios.get(APIURL + "/dropdowns/dropdownType/customerType", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        customerStatus: (
          await axios.get(APIURL + "/dropdowns/dropdownType/customerStatus", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      locations: {
        states: [],
      },
    };

    this.setState({ ...data });

    this.setState({
      renderComponent: (<div
        className={`tab-pane fade ${this.state.mapRender === "cust" ? "show active" : ""
          }`}
        id="list-settings"
        role="tabpanel"
        aria-labelledby="list-settings-list"
      >
        <CustomerForm
          {...this.props}
          isFormValidated={this.isFormValidated}
          states={this.state.locations.states}
          customerType={this.state.customers.customerType}
          customerStatus={this.state.customers.customerStatus}
          empList={this.state.employees.list}
          customerList={this.state.customers.list}
          comp_id={this.props.comp_id}
        />
      </div>)
    });
  }

  getEmpData = async () => {
    let data = {
      employees: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/employeeslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        empType: [],
        empLevel: (
          await axios.get(APIURL + "/dropdowns/dropdownType/empLevel", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        empStatus: (
          await axios.get(APIURL + "/dropdowns/dropdownType/empStatus", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      locations: {
        states: [],
      },
    };

    this.setState({ ...data });

    this.setState({
      renderComponent: (<div
        className={`tab-pane fade ${this.state.mapRender === "emp" ? "show active" : ""
          }`}
        id="list-employee"
        role="tabpanel"
        aria-labelledby="list-employee-list"
      >
        <EmployeeForm
          {...this.props}
          isFormValidated={this.isFormValidated}
          states={this.state.locations.states}
          empList={this.state.employees.list}
          empStatus={this.state.employees.empStatus}
          empType={this.state.employees.empType}
          empLevel={this.state.employees.empLevel}
          comp_id={this.props.comp_id}
        />
      </div>)
    });
  }

  getLocData = async () => {
    let data = {
      locations: {
        states: [],
        locType: (
          await axios.get(APIURL + "/dropdowns/dropdownType/locType", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        locStatus: (
          await axios.get(APIURL + "/dropdowns/dropdownType/locStatus", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
    };

    this.setState({ ...data });

    this.setState({
      renderComponent: (<div
        className={`tab-pane fade ${this.state.mapRender === "loc" ? "show active" : ""
          }`}
        id="list-location"
        role="tabpanel"
        aria-labelledby="list-location-list"
      >
        <LocationForm
          {...this.props}
          isFormValidated={this.isFormValidated}
          mapRender={this.state.mapRender}
          states={this.state.locations.states}
          locStatus={this.state.locations.locStatus}
          locType={this.state.locations.locType}
          comp_id={this.props.comp_id}
        />
      </div>)
    });
  }

  getActData = async () => {
    let data = {
      employees: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/employeeslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      projects: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/projectslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      tasks: {
        taskType: (
          await axios.get(APIURL + "/dropdowns/dropdownType/taskType", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
      locations: {
        list: (
          await axios.get(APIURL + "/dropdowns/dropdownType/locationslist", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        states: [],
        locType: (
          await axios.get(APIURL + "/dropdowns/dropdownType/locType", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
        locStatus: (
          await axios.get(APIURL + "/dropdowns/dropdownType/locStatus", {
            params: { comp_id: this.props.comp_id },
          })
        ).data,
      },
    };

    this.setState({ ...data });

    this.setState({
      renderComponent: (<div
        className={`tab-pane fade ${this.state.mapRender === "act" ? "show active" : ""
          }`}
        id="list-record-activity"
        role="tabpanel"
        aria-labelledby="list-location-list"
      >
        <RecordActivity
          {...this.props}
          user={this.props.user}
          isFormValidated={this.isFormValidated}
          taskType={this.state.tasks.taskType}
          projects={this.state.projects.list}
          locations={this.state.locations.list}
          employees={this.state.employees.list}
          mapRender={this.state.mapRender === "rec"}
          states={this.state.locations.states}
          locStatus={this.state.locations.locStatus}
          locType={this.state.locations.locType}
          comp_id={this.props.comp_id}
        />
      </div>)
    });
  }

  getNoteData = async () => {
    this.setState({
      renderComponent: (<div
        className={`tab-pane fade ${this.state.mapRender === "note" ? "show active" : ""
          }`}
        id="list-home"
        role="tabpanel"
        aria-labelledby="list-home-list"
      >
        <div style={{ width: '30rem' }}>
          {!this.state.showNoteCreated &&
            <NoteFormv2
              {...this.props}
              user={this.props.user}
              comp_id={this.props.comp_id}
              mode='add'
              onSubmit={(val) => {
                console.log(val)
                this.setState({ showNoteCreated: val })
              }}
            />
          }
          {this.state.showNoteCreated &&
            <MsgModalBox addNew={() => this.setState({ showNoteCreated: false })} goHome={() => this.setState({ goHome: true })} text="Note" showError={null} style={{ position: "absolute" }} />
          }
        </div>
      </div>)
    });
  }

  getBulkData = async () => {
    this.setState({
      renderComponent: (
        <div
          className={`tab-pane fade ${this.state.mapRender === "bulk" ? "show active" : ""
            }`}
          id="list-bulkupload"
          role="tabpanel"
          aria-labelledby="list-bulkupload-list"
        >
          <BulkUploadv2 {...this.props} comp_id={this.props.comp_id} />
        </div>
      )
    });
  }

  isFormValidated = (objForVal) => {
    console.log('>>>>>>>>>>> ', objForVal)
    var missingField = {
      isValidated: true
    }
    let temp = Object.keys(objForVal)
    if (temp[0] === 'selectedEmp') {

      let selectedEm = objForVal.selectedEmp
      for (let i = 0; i < selectedEm.length; i++) {
        for (let objKey in selectedEm[i]) {
          if (
            selectedEm[i][objKey] === "" ||
            selectedEm[i][objKey] === undefined ||
            selectedEm[i][objKey].length === 0
          ) {
            missingField.isValidated = false;
            missingField.missingKey = objKey
            return missingField
          }
        }
      }
    }




    for (let objKey in objForVal) {
      if ((objKey !== "Cemail" && objKey !== "designation") &&
        (objForVal[objKey] === "" ||
          objForVal[objKey] === undefined ||
          objForVal[objKey].length === 0)
      ) {
        if (
          objKey === "address2" ||
          objKey === "location_addr2" ||
          objKey === "tComment" ||
          objKey === "cus_local_id" ||
          //objKey === "ddd_id" ||
          objKey === "address_line_2" ||
          // objKey === "email" ||
          objKey === "medicaid_id" ||
          objKey === "medicaid_eligible" ||
          objKey === "sign_page_date" ||
          objKey === "last_plan_approval_date" ||
          objKey === "last_assess_date" ||
          objKey === "renewal_date" ||
          //objKey === "DDDID" ||
          objKey === "personal_days_off" ||
          objKey === "vacation_days" ||
          objKey === "DDD_tier" ||
          objKey === "diagnose_code" ||
          objKey === "func_criteria_met" ||
          objKey === "waiver_program" ||
          objKey === "NJCATBehavioral" ||
          objKey === "NJCATFCA" ||
          objKey === "NJCATMedical" ||
          objKey === "NJCATSelfcare" ||
          objKey === "County" ||
          objKey === "Race" ||
          objKey === "Ethnicity" ||
          objKey === "MedicaidEndDate" ||
          objKey === "AgencyAssignDate" ||
          objKey === "TransferOutReason" ||
          objKey === "TransferOutDate" ||
          objKey === "tContacts"
        ) {
          // return true
          continue;
        } else if (objForVal["cus_is_company"] === true && objForVal["company_name"] === "") {
          missingField.isValidated = false;
          missingField.missingKey = "company_name"
          return missingField;
        }
        else {
          console.log(objKey);

          if (
            (objKey === "company_name" &&
              objForVal["first_name"] !== "" &&
              objForVal["last_name"] !== "") ||
            ((objKey === "first_name" || objKey === "last_name") &&
              objForVal["company_name"] !== "" && objForVal["company_name"] !== undefined) || objKey === "address_maps"
          ) {
            continue;
          } else {
            missingField.isValidated = false;
            missingField.missingKey = objKey
            return missingField;
          }
        }
      } else if (objKey === "prefCommMode") {
        if (objForVal[objKey] !== "Email" && objForVal[objKey] !== "Phone") {
          missingField.isValidated = false;
          missingField.missingKey = "prefCommMode"
          return missingField;
        } else if (objForVal[objKey] === "Email" && !String(objForVal["Cemail"]).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          missingField.isValidated = false;
          missingField.missingKey = "prefCommMode";
          missingField.invalidKey = "Cemail";
          return missingField;
        } else if (objForVal[objKey] === "Phone") {
          let phoneNumPresent = false;
          objForVal["phnNumList"].forEach((phoneDets) => {
            if (/^\d{10}$/.test(phoneDets.phone)) {
              phoneNumPresent = true;
            }
          })
          if (!phoneNumPresent) {
            missingField.isValidated = false;
            missingField.missingKey = "prefCommMode";
            missingField.invalidKey = "phnNumList";
            return missingField;
          }
        } 
      } else if (Array.isArray(objForVal[objKey])) {
        let arrValid = objForVal[objKey].every(function (objTesting) {
          for (let objTestKey in objTesting) {
            console.log(objTestKey);
            if (
              objTesting[objTestKey] === "" ||
              objTesting[objTestKey] === undefined
            ) {
              if (
                objTestKey === "address_line_2" ||
                objTestKey === "location_addr2"
              ) {
                // return true
                continue;
              } else {
                missingField.isValidated = false;
                missingField.missingKey = objKey
                return missingField;
              }
            }
          }
          return true;
        });
        if (!arrValid) return false;
      } else if (objKey === "active_plan_version") {
        for (const digit of objForVal[objKey]) {
          if (Number.isNaN(parseInt(digit)) && digit !== ".") {
            alert("PROJECT ACTIVE PLAN VERSION has to be a number");
            objForVal[objKey] = "";
            return false;
          }
        }
      }
    }

    return missingField;
  };

  render() {
    return (
      <div
        className="row d-flex flex-md-row"
        style={{ zIndex: 1, maxWidth: "100%", maxHeight: "100%" }}
      >
        <div
          className="col-lg-3 col-md-4 col-sm-6"
          style={{ maxWidth: "20rem" }}
        >
          <h5 style={{ fontsize: '0.75rem', paddingLeft: '5px', fontFamily: "Lato", marginBottom: '5px' }}>
            CREATE NEW
          </h5>
          <div className="list-group" id="list-tab" role="tablist">
            <a
              className={`list-group-item list-group-item-action ${this.state.mapRender === "task" ? "active" : ""
                }`}
              id="list-home-list"
              data-toggle="list"
              href="#list-home"
              role="tab"
              aria-controls="home"
              onClick={() => this.setState({ mapRender: "task" })}
            >
              <span>
                <i
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "23px",
                    paddingLeft: "0px",
                  }}
                  className="fas fa-clipboard-check"
                ></i>
              </span>
              Task
            </a>
            <a
              hidden={this.props.user.type != 'Admin'}
              className={`list-group-item list-group-item-action ${this.state.mapRender === "proj" ? "active" : ""
                }`}
              id="list-profile-list"
              data-toggle="list"
              href="#list-profile"
              role="tab"
              aria-controls="profile"
              onClick={() => this.setState({ mapRender: "proj" })}
            >
              <span>
                <i
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "20px",
                    paddingLeft: "0px",
                  }}
                  className="fas fa-list-alt"
                ></i>
              </span>
              Project / Plan
            </a>
            <a
              className={`list-group-item list-group-item-action ${this.state.mapRender === "con" ? "active" : ""
                }`}
              id="list-messages-list"
              data-toggle="list"
              href="#list-messages"
              role="tab"
              aria-controls="messages"
              onClick={() => this.setState({ mapRender: "con" })}
            >
              <span>
                <i
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "20px",
                    paddingLeft: "0px",
                  }}
                  className="fas fa-user-alt"
                ></i>
              </span>
              Contact / Provider
            </a>
            <a
              hidden={this.props.user.type != 'Admin'}
              className={`list-group-item list-group-item-action ${this.state.mapRender === "cust" ? "active" : ""
                }`}
              id="list-settings-list"
              data-toggle="list"
              href="#list-settings"
              role="tab"
              aria-controls="settings"
              onClick={() => this.setState({ mapRender: "cust" })}
            >
              <span>
                <i
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "20px",
                    paddingLeft: "0px",
                  }}
                  className="fas fa-briefcase"
                ></i>
              </span>
              Customer / Patient
            </a>
            <a
              hidden={true}
              className={`list-group-item list-group-item-action ${this.state.mapRender === "emp" ? "active" : ""
                }`}
              id="list-employee-list"
              data-toggle="list"
              href="#list-employee"
              role="tab"
              aria-controls="settings"
              onClick={() => this.setState({ mapRender: "emp" })}
            >
              <span>
                <i
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "25px",
                    paddingLeft: "0px",
                  }}
                  className="fas fa-user-tie"
                ></i>
              </span>
              Employee{" "}
            </a>
            <a
              className={`list-group-item list-group-item-action ${this.state.mapRender === "note" ? "active" : ""
                }`}
              id="list-home-list"
              data-toggle="list"
              href="#list-home"
              role="tab"
              aria-controls="home"
              onClick={() => this.setState({ mapRender: "note" })}
            >
              <span>
                <i
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "27px",
                    paddingLeft: "0px",
                  }}
                  className="fas fa-clipboard-check"
                ></i>
              </span>
              Notes
            </a>
            <a
              className={`list-group-item list-group-item-action ${this.state.mapRender === "loc" ? "active" : ""
                }`}
              id="list-location-list"
              data-toggle="list"
              href="#list-location"
              role="tab"
              aria-controls="settings"
              onClick={() => this.setState({ mapRender: "loc" })}
            >
              <span>
                <i
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "28px",
                    paddingLeft: "0px",
                  }}
                  className="fas fa-map-marker-alt"
                ></i>
              </span>
              Location
            </a>
            {this.props.user.type === "Admin" && (
              <a
                className={`list-group-item list-group-item-action ${this.state.mapRender === "bulk" ? "active" : ""
                  }`}
                id="list-bulkupload-list"
                data-toggle="list"
                href="#list-bulkupload"
                role="tab"
                aria-controls="settings"
                onClick={() => this.setState({ mapRender: "bulk" })}
              >
                <span>
                  <i
                    style={{
                      fontSize: "1.5rem",
                      paddingRight: "25px",
                      paddingLeft: "0px",
                      marginLeft: -3
                    }}
                    className="fas fa-arrow-circle-up"
                  ></i>
                </span>
                Bulk Upload
              </a>
            )}
            <a
              className={`list-group-item list-group-item-action ${this.state.mapRender === "act" ? "active" : ""
                }`}
              id="list-location-list"
              data-toggle="list"
              href="#list-record-activity"
              role="tab"
              aria-controls="settings"
              onClick={() => this.setState({ mapRender: "act" })}
            >
              <span>
                <i
                  style={{
                    fontSize: "1.5rem",
                    paddingRight: "28px",
                    paddingLeft: "0px",
                  }}
                  className="fas fa-clipboard"
                ></i>
              </span>
              Record Activity
            </a>
          </div>
        </div>
        {this.state.isLoading && <div className="d-flex flex-row w-100 justify-content-center mt-5 col-lg-8 col-md-8 col-sm-12">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>}
        {!this.state.isLoading && <div className="col-lg-8 col-md-8 col-sm-12">
          <div
            className="tab-content"
            style={{ width: "37rem", paddingTop: "2rem" }}
            id="nav-tabContent"
          >
            {this.state.renderComponent}
          </div>
        </div>}
        {this.state.goHome &&
          <Redirect to="/" />
        }
      </div>
    );
  }
}

export default SideBar;
