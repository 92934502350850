import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dropzone from 'react-dropzone';
import InputAdornment from '@mui/material/InputAdornment';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FileFolderItem from '../FileFolderItem';

const UploadFile = (props) => {
    const [file, setFile] = useState(null);
    const [parentDirectory, setParentDirectory] = useState(null);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState("");

    const doesFileNameExistInParent = (fileName, parentDirectory) => {
        let files = props.rows.filter(row => row.file_name !== null);
        let parent = parentDirectory ? parentDirectory : props.rootDirectory;
        let name = fileName.split('.')[0];
        let ext = fileName.split('.')[1];
        let filePath = [...parent.file_path];
        filePath.push(name);
        filePath = filePath.join('/');
        let file = files.find(file => file.file_path.join('/') === filePath && file.file_ext === ext);
        return file ? true : false;
    }

    const onSubmit = () => {
        if (file === null) {
            setError(true);
            setErrorText("Select a file to upload");
        } else if (doesFileNameExistInParent(file.name, parentDirectory)) {
            setError(true);
            setErrorText("File already exists");
        } else {
            setError(false);
            props.onUpload(file, parentDirectory);
        }
    }
        
    return (
        <Box>
            <h4>Upload File</h4>
            <br></br>
            <Box>
                <Dropzone onDrop={acceptedFiles => {setFile(acceptedFiles[0]);setError(false)}}>
                    {({getRootProps, getInputProps}) => (
                        <Box className="upload-area" {...getRootProps()} sx={{ color: error ? "red" : "black", borderColor: error ? "red" : "black" }}>
                            <Typography variant="subtitle1">{error ? errorText : file ? file.name : "Drop a file here or click to select a file"}</Typography>
                            <input type="file" {...getInputProps()} />
                        </Box>
                    )}
                </Dropzone>
                <Autocomplete
                    value={parentDirectory}
                    onChange={(event, newValue) => setParentDirectory(newValue)}
                    sx={{ marginBottom: '20px', width: '100%' }}
                    options={props.rows.filter((r) => r.file_name === null && r.locked !== true)}
                    renderInput={(params) => <TextField
                        {...params} 
                        label="File/Folder" 
                        error={error}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: parentDirectory ?
                                <>
                                    {
                                        parentDirectory.file_name === null ? <InputAdornment position="start"><FolderIcon sx={{ color: "#007bff" }} /></InputAdornment>
                                        : <InputAdornment position="start"><InsertDriveFileOutlinedIcon /></InputAdornment>
                                    }
                                    {params.InputProps.startAdornment}
                                </> : null
                        }}
                    />}
                    getOptionLabel={(option) => option.file_path[option.file_path.length - 1]}
                    renderOption={(props, option) => <FileFolderItem listItemProps={props} row={option} />}
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant="contained" sx={{ marginTop: "20px", marginRight: "10px" }} onClick={() => props.handleModalClose()} color="secondary">Cancel</Button>
                    <LoadingButton 
                        variant="contained" 
                        sx={{ marginTop: "20px" }} 
                        onClick={onSubmit} 
                        color="primary"
                        loading={props.loading}
                    >
                        <span>Upload</span>
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}

export default UploadFile;