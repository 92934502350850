import Button from '@mui/material/Button';
import Save from '@mui/icons-material/Save';
import axios from 'axios';
import { APIURL } from '../../config';

const SaveViewButton = (props) => {

    return (
        <Button
            size="small"
            onClick={props.onClick}
            startIcon={<Save />}
        >
            {props.buttonName ? props.buttonName : 'Save View'}
        </Button>
    )
}

export default SaveViewButton;