import React,{Component} from 'react'
import Chart from 'chart.js';

class HomeCanvas extends Component{
    state = {
        data: !!this.props.data?this.props.data:[42, 19, 30, 50, 20, 30, 67, 78, 60, 32, 59, 73]
    }
    chartRef = React.createRef();
    // var ctx = document.getElementById('myChart');
    async componentDidUpdate(){
        // console.log(this.props.data)
        // await this.setState({data: this.props.data})
        // document.getElementById('myChartBar').remove()
        try{const myChartRef = this.chartRef.current.getContext("2d");
        new Chart(myChartRef, {
            type: 'bar',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept','Oct', 'Nov', 'Dec'],
                datasets: [{
                    label: 'Contacts',
                    data: !!this.props.data?this.props.data:this.state.data,
                    backgroundColor: !!this.props.data?this.props.data.map((rev,indx) => {
                        let maxIndex = this.props.data.indexOf(Math.max(...this.props.data))
                        // console.log(indx)
                        let minIndex = this.props.data.indexOf(Math.min(...this.props.data))
                        // console.log(minIndex)
                        let color = (indx === maxIndex || indx === minIndex)?'#FFD166':'#3FA7D6'
                        return color
                    }):[],
                    borderColor: !!this.props.data?this.props.data.map((rev,indx) => {
                        let maxIndex = this.props.data.indexOf(Math.max(...this.props.data))
                        let minIndex = this.props.data.indexOf(Math.min(...this.props.data))
                        let color = (indx === maxIndex || indx === minIndex)?'#FFD166':'#3FA7D6'
                        return color
                    }):[],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function(value, index, values) {
                                return value;
                            }
                        }
                    }]
                }
            }
        });}catch(e){
            return 
        }
    }
    render(){
        return(
        <div style={{border:'1px solid #3FA7D6', padding: 10, maxWidth: '100%', maxHeight: '100%'}}  className="text-center">
            Monthly Contacts
            <hr style={{background: '#3FA7D6'}} className="m-1"/>
            {(!!this.props.data && !this.props.data.some((num) => num !== 0)) && 
                <div className='d-flex flex-column justify-content-center align-items-center' style={{height: '700px', margin: 10}}>
                    <img src='./bar.png' alt='barchart' style={{width: '20rem', height: '20rem'}}/>
                    <h5 style={{fontFamily: 'Lato',fontSize: 18,marginTop: 48,  marginBottom: 30}}>Your team hasn’t made any contacts yet </h5>
                    <p style={{fontFamily: 'Lato',fontSize: 14}} className='text-muted'>Here you’ll see data about contacts made by your agency each month. <br/>Let’s invite your team to get to work making those contacts!. </p>
                </div>
            }
            {!this.props.data && 
            <div style={{height: '700px', margin: 10}} className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
            </div></div>
            }
            {!!this.props.data && this.props.data.some((num) => num !== 0) && <canvas id="myChartBar" ref={this.chartRef} width="400" height="300"></canvas>}
        </div>
        )
    }
}

export default HomeCanvas