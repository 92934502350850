import React, { useState, useEffect } from 'react';
import IconButton from "@mui/material/IconButton";
import UplaodForm from '../../../components/forms/UploadForm/UploadForm';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

const Upload = (props) => {

    return (
        <Dialog open={props.showUpload}>
            <DialogActions>
                <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.25 }}
                    onClick={() => {props.setShowUpload(false); props.setUploadCustomerID()}}
                    >
                        <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent sx={{ paddingTop: '0px', width: 400 }}>
                <UplaodForm company_id={props.company_id} mode='add' user={{ emp_id: props.user.emp_id}} customer_id={props.customer_id} onSubmit={() => props.setShowUpload(false)}></UplaodForm>
            </DialogContent>
        </Dialog>
    )
}

export default Upload