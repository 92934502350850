export default {
    "section_1": {
        "a": {
            "question": "Individual's Name:",
            "answer": ""
        },
        "b": {
            "question": "DDD ID #:",
            "answer": ""
        },
        "c": {
            "question": "NJCAT Score:",
            "c1": {
                "question": "Self-Care",
                "answer": ""
            },
            "c2": {
                "question": "Behavorial",
                "answer": ""
            },
            "c3": {
                "question": "Medical",
                "answer": ""
            }
        },
        "d": {
            "question": "Tier:",
            "answer": ""
        },
        "e": {
            "question": "Current Program:",
            "answer": ""
        },
        "f": {
            "question": "Current Living Arrangement:",
            "answer": "",
            "options": [{ "label": "Own Home", "value": "own_home" }, { "label": "Provider Managed Residence", "value": "provider_managed_residence" }, { "label": "Other", "value": "other" }]
        },
        "g": {
            "question": "If Other, please describe:",
            "answer": ""
        },
        "h": {
            "question": "Is an Approved ISP in Place?",
            "answer": ""
        },
        "i": {
            "question": "Name of Support Coordination Agency:",
            "answer": ""
        },
        "j": {
            "question": "Name of Assigned Support Coordinator:",
            "answer": ""
        },
        "k": {
            "question": "Contact Date:",
            "answer": ""
        },
        "l": {
            "question": "Contact Location:",
            "answer": "",
            "options": [{ "label": "Home", "value": "home" }, { "label": "Day Hab Site", "value": "day_hab_site" }, { "label": "Job Site", "value": "job_site" }, { "label": "Other", "value": "other" }]            
        },
        "m": {
            "question": "Contact Type:",
            "answer": "",
            "options": [{ "label": "Face-to-Face", "value": "face_to_face" }, { "label": "Phone/Video Contact", "value": "phone/video_contact" }, { "label": "Home Visit", "value": "home_visit" }]
        },
        "n": {
            "question": "Contact Period:",
            "answer": ""
        },
        "o": {
            "question": "If Other, please describe:",
            "answer": ""
        },
        "p": {
            "question": "If Phone, enter the phone #:",
            "answer": ""
        },
        "q": {
            "question": "Who is the primary contact for this Monthly contact?",
            "answer": ""
        },
        "r": {
            "question": "Relationship to the Individual",
            "answer": "",
            "options": [{ "label": "Self", "value": "self" }, { "label": "Parent/and or Guardian", "value": "parent/and_or_guardian" }, { "label": "Residential Provider Staff", "value": "residential_provider_staff" }, { "label": "Day Program Provider Staff", "value": "day_program_provider_staff" }, { "label": "Other Provider Staff", "value": "other_provider_staff" }, { "label": "Other", "value": "other" }]
        },
        "s": {
            "question": "If the Individual is not the primary contact, were they involved in the conversation?",
            "answer": ""
        },
        "t": {
            "question": "Please describe or explain:",
            "answer": ""
        }
    },
    "section_2": [],
    "section_3": {
        "a": {
            "question": "a.	(For the SC) Has a verbal review of outcomes and services been completed?",
            "answer": ""
        },
        "b": {
            "question": "b.	Are you receiving all services as entered in the ISP?",
            "answer": ""
        },
        "c": {
            "question": "c.	Do you feel your services are helping you make progress toward ISP outcomes?",
            "answer": ""
        },
        "d": {
            "question": "d.	Are there outcomes or services you would like to change, add, or remove from your ISP, including changes to any service providers you may use?",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_4": {
        "a": {
            "question": "a.	Is there anything happening now that might require a change with your housing? (Examples: behind on rent, significant problems with housemates, desire to live in another setting/location, etc.)",
            "answer": ""
        },
        "b": {
            "question": "b.	Have any Incident Reports been completed/submitted since last monitoring interaction? (According to iRecord notes, email notification, conversations, etc.)",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_5": {
        "a": {
            "question": "a.	Have there been changes to your health since last month, including medication changes? (Physical, emotional, behavioral, psychological, etc.)",
            "answer": ""
        },
        "b": {
            "question": "b.	Have you been hospitalized/visited emergency room since last month?",
            "answer": ""
        },
        "c": {
            "question": "c.	Have you had medical/dental/specialist appointments since last month?",
            "answer": ""
        },
        "d": {
            "question": "d.	If yes, did the doctor/dentist/specialist make any follow up recommendations that are not yet completed and/or scheduled?",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_6": {
        "a": {
            "question": "a.	Is the Individual Medicaid eligible? (Indicated by green litmus)",
            "answer": ""
        },
        "b": {
            "question": "b.	Is the Individual’s Medicaid scheduled to terminate? (Indicated by yellow litmus)",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_7": {
        "a": {
            "question": "a.	Is this the fourth consecutive month (or more) for which you have not been able to complete a face-to-face visit?",
            "answer": ""
        },
        "b": {
            "question": "b.	Have risk factors been identified for which Division assistance is needed? (Ex: Significant changes in the Individual’s/caregiver’s support needs, behavioral/medical concerns, housing instability, long-term hospitalization/nursing home admission, etc.)",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_8": {
        "a": {
            "question": "Is there anything else you would like me to know right now, or need assistance with?",
            "answer": ""
        }
    },
    "section_9": {
        "a": {
            "question": "Summarize the conversation describing highlights and points of interest or concern for the Individual since the last contact. Include observations and impressions other than information already included above.",
            "answer": ""
        }
    },
    "section_10": {
        "a": {
            "question": "Name:",
            "answer": ""
        },
        "b": {
            "question": "Date:",
            "answer": ""
        },
        "c": {
            "question": "Title:",
            "answer": ""
        },
        "d": {
            "question": "If completed by someone other than the assigned Support Coordinator, please explain:",
            "answer": ""
        }
    },
    "section_11": {
        "a": {
            "question": "Name of SC Supervisor:",
            "answer": ""
        },
        "b": {
            "question": "Review Date:",
            "answer": ""
        }
    }
}