import React, { Component } from "react";
import { Tab, Row, Col, Nav, Accordion, Card } from "react-bootstrap";
import axios from 'axios'
import TeamList from '../../../components/TeamList/TeamList'
import {APIURL} from '../../../config'
import Onboarding from '../../../components/forms/Onboarding/Onboarding'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

class EmpSideBar extends Component {
    constructor(props){
        super(props)
        this.onDesgChange = this.onDesgChange.bind(this)
        this.onRemove = this.onRemove.bind(this)
    }
    state = {
        key: "0",
        collapse: true,
        employee:[],
        designation:[],
        loggedToSegment: false,
    }
    logToSegment = async () => {
        if(this.state.loggedToSegment) {
          return;
        }
        let page = String(window.location.href).split("/").pop()
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page,this.props.user.emp_id)
        analytics.page(page, {
            title:page,
            path:page,
        }, {
            userId:String(this.props.user.emp_id),
        })
        console.log("logged to segment successfully")
        this.setState({ loggedToSegment : true });
    }

    async componentDidMount(){
        this.logToSegment();
        let result = await axios.get(APIURL+'/team/', {params: {comp_id: this.props.comp_id}})
        this.setState({employee: result.data.employeeList, designation: result.data.empType})
        const response = await axios.get(APIURL + `/dropdowns/dropdownType/empType?comp_id=${this.props.comp_id}`)
        this.setState({ designation: [...new Set([...this.state.designation, ...response.data]) ]})
        this.onSideBarChange(this.state.key)
    }

    async onDesgChange(empIdArr, desg){
        let res = await axios.put(APIURL+'/team/', {empList: empIdArr, type: desg})
        console.log(res.data)
        if(res.data.code === 200){
            let employee = this.state.employee
            employee.map((emp)=>{
                if(empIdArr.includes(emp.id)){
                    emp.emp_type = desg
                }
                return emp
            })
            console.log(employee)
            await this.setState({employee})
            if(empIdArr.includes(this.props.user.emp_id)){
                window.location.reload()
            }
            
        }else{
            return
        }
    }

    async onRemove(empIdArr){
        let res = await axios.put(APIURL+'/team/remove', {empList: empIdArr})
        // console.log(res.data)
        if(res.data.code === 200){
            let employee = this.state.employee
            empIdArr.forEach(id => {
                employee = employee.filter((emp) => (emp.id !== id))  
            });
            // console.log(employee)
            this.setState({employee})
        }else{
            return
        }
    }

    onSideBarChange = (key) => {
        if ((key !== "AllMembers" || key !== "Managers" || key !== "FieldWorkers") && this.state.collapse) {
            // this.toggle.click()
            // this.setState
            // (toggleEvent) => this.toggle = toggleEvent
            // this.toggle()
            this.setState({ key, collapse: false })
        }

        if (key === "AllMembers" || key === "Managers" || key === "FieldWorkers" || key==='0') {
            this.setState({ key, collapse: true })
            return
        }

        this.setState({key})
    }

    onToggle = () => {
        const oldState = { ...this.state }
        oldState.collapse = !this.state.collapse
        if (oldState.collapse) {
            oldState.key = this.state.key
        }
        this.setState(oldState)
    }


    render() {
        return (
            <div>
                <Tab.Container id="tab" activeKey={this.state.key==='0'?'AllMembers':this.state.key}>
                    <Row>
                        <Col lg={3} md={4} sm={6} style={{maxWidth: '15rem'}}>
                            <Nav className="flex-column">
                                
                                <Nav.Item >
                                    <Accordion>
                                        <Accordion.Toggle className="list-group-item list-group-item-action" active={this.state.collapse} style={{ outline: "none" }} as={Nav.Link} eventKey="0" onClick={() => {this.onToggle();this.onSideBarChange("0")}} ref={(toggleEvent) => this.toggle = toggleEvent}>
                                            <span><i style={{ fontSize: "1.5rem", paddingRight: "23px", paddingLeft: "0px" }} className="fas fa-users"></i></span>
                                            Members
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <Nav.Item>
                                                    <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} active={(this.state.key === "AllMembers") ? true : false} eventKey="0" onSelect={() => this.onSideBarChange("AllMembers")}>All Members</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} active={(this.state.key === "Managers") ? true : false} eventKey="0" onSelect={() => this.onSideBarChange("Managers")}>Managers</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} active={(this.state.key === "FieldWorkers") ? true : false} eventKey="0" onSelect={() => this.onSideBarChange("FieldWorkers")}>FieldWorkers</Nav.Link>
                                                </Nav.Item>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Accordion>
                                </Nav.Item>
                                {this.props.user.type === 'Admin' && <Nav.Item>
                                    <Nav.Link className="list-group-item list-group-item-action" style={{ outline: "none" }} active={(this.state.key === "Invites") ? true : false} eventKey="Invites" onSelect={() => {this.onSideBarChange('Invites')}}><span><i style={{ fontSize: "1.5rem", paddingRight: "23px", paddingLeft: "0px" }} className="fas fa-door-open"></i></span>Invites</Nav.Link>
                                </Nav.Item>}
                            </Nav>
                        </Col>
                        <Col lg={8} md={8} sm={8}>
                            <Tab.Content>
                                <Tab.Pane eventKey="AllMembers">
                                    <TeamList user={this.props.user} desgList={this.state.designation} onRemove={this.onRemove} onDesgChange={this.onDesgChange} contextName={'All Members'} employeeList={this.state.employee}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Managers">
                                    <TeamList user={this.props.user} desgList={this.state.designation} onRemove={this.onRemove} onDesgChange={this.onDesgChange} contextName={'Managers'} employeeList={this.state.employee.filter((emp)=>(emp.emp_type === 'Manager'))}/> 
                                </Tab.Pane>
                                <Tab.Pane eventKey="FieldWorkers">
                                    <TeamList user={this.props.user} desgList={this.state.designation} onRemove={this.onRemove} onDesgChange={this.onDesgChange} contextName={'FieldWorkers'} employeeList={this.state.employee.filter((emp)=>(emp.emp_type === 'Fieldworker'))}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="Invites">
                                    <Onboarding user={this.props.user}/>
                                    {/* <TeamList user={this.props.user} desgList={this.state.designation} onRemove={this.onRemove} onDesgChange={this.onDesgChange} contextName={'Invites'} employeeList={this.state.employee}/> */}
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        )
    }
}

export default EmpSideBar;