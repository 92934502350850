import react, { useState, useEffect } from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';
import TypeButton from '../Components/TypeButton';
import axios from 'axios';
import { APIURL } from '../../../config';

const PrimaryBusiness = (props) => {
    const [type, setType] = useState({ "title": "", "feature_id": 0 });
    const [originalType, setOriginalType] = useState({ "title": "", "feature_id": 0 });

    let primaryData = [
        { "title": "Support Coordination", "feature_id": 101, "settings_id": props.type?.settings_id },
        { "title": "Service Provider", "feature_id": 102, "settings_id": props.type?.settings_id },
        { "title": "Home Healthcare", "feature_id": 103, "settings_id": props.type?.settings_id },
        { "title": "Case Management", "feature_id": 104, "settings_id": props.type?.settings_id },
        { "title": "Day Care Or Community Center", "feature_id": 105, "settings_id": props.type?.settings_id },
    ]

    useEffect(() => {
        let type = primaryData.find((data) => data.feature_id === props.type?.feature_id);
        setType(type);
        setOriginalType(type);
    }, [props.type]);

    const handleSave = async () => {
        console.log(type);
        try {
            let response = await axios.put(APIURL + "/config/settings", {
                feature_id: type.feature_id,
                settings_id: type.settings_id
            });
            setOriginalType(type);
        } catch (error) {
            console.error(error);
        }   
    }
    
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            Primary Business
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            What is your business type?
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h6">
                            Assign Business Type
                        </Typography>
                        <Grid container spacing={2} sx={{ position: 'relative', left: -16, top: -12 }}>
                            {primaryData.map((data) => {
                                return (
                                    <Grid item xs={4} key={data.feature_id}>
                                        <TypeButton selected={type?.feature_id === data?.feature_id} onClick={() => {setType(data)}} height={80} title={data.title}/>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <SaveButton onClick={handleSave} disabled={type?.feature_id === originalType?.feature_id} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PrimaryBusiness;