import React, {Component} from 'react';
import {APIURL} from '../../../config';
import axios from 'axios';
//import moment from "moment";
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {
	//Tab, 
	Row, Col
	//, Nav
} from 'react-bootstrap';
import moment from 'moment';

// SQL Query is Ordered by (in this order): invoice date(DESC), customer name(ASC), procedure code(ASC), line item number(ASC).

/*
SELECT invoice_id AS id, invoice_date, cus_name, procProcCode, invoice_number, line_item_no, amount
FROM invoice i, customer c
WHERE comp_id = 2002 AND i.customer_id = c.cus_id
ORDER BY invoice_date DESC, cus_name ASC, procProcCode ASC, line_item_no ASC;
*/

const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

// Displayed in the front end with columns: Invoice Date, Customer Name, Procedure Code, Invoice Number, Line Item Number, Amount.
const columns = [
	// invoice_id is Hidden; Used to create a unique id property for Material-UI DataGrid Component.
	{
		field: 'id',
		headerName: 'Invoice ID',
		width: 200,
		hide: true,
	},
	{
		field: 'invoice_date',
		headerName: 'DATE OF INVOICE',
		width: 200,
		sortComparator: dateComparator
	},
	// This Customer_ID will reference cus_id from Fieldworker.dbo.customer DB.
	{
		field: 'cus_name',
		headerName: 'CUSTOMER NAME',
		width: 200,
	},
	{
		field: 'procProcCode',
		headerName: 'PROCEDURE CODE',
		width: 200,
		align: 'center',
	},
	{
		field: 'invoice_number',
		headerName: 'INVOICE NUMBER',
		width: 200,
	},
	{
		field: 'line_item_no',
		headerName: 'LINE ITEM NUMBER',
		width: 200,
	},
	{
		field: 'amount',
		headerName: 'AMOUNT',
		width: 200,
	},
];

// Fetch the data for the rows based on the current company.

// id is the Unique ID from the invoice table. invoice_id.

//const rows = [
//	{
//		id: 1,
//		invoice_date: '10/10/2021 08:01:49.000',
//		customer_id: 'Mark Cruz',
//		procProcCode: '12DD32',
//		invoice_number: 104,
//		line_item_no: 100,
//		amount: '$ 29323.23',
//	},
//];

// API request

// Render the Invoice Table and SideNavBar EVVs.
export default class Invoice extends Component {
	state = {loading:true, invoiceReports: []}
    async componentDidMount(){
        let invoice_reports = await axios.get(APIURL+"/invoice",{params:{ comp_id:this.props.comp_id}})
        // console.log(timesheets_reports.data)
       this.setState({invoiceReports:invoice_reports.data, loading:false })
	   console.log(this.state);
	   console.log(APIURL);
    }
		
	render() {

		console.log(this.props);
		/*
		const rows = [
			{
				id: 1,
				invoice_date: "10/10/2021 08:01:49.000",
				customer_id: "Mark Cruz",
				procProcCode: "12DD32",
				invoice_number: 104,
				line_item_no: 100,
				amount: "$ 29323.23"
			}
		];
		*/

		let rows =  this.state.invoiceReports.map((inv)=> {
			let invDets =  {
				id: inv.id,
				invoice_date: inv.invoice_date,
				cus_name: inv.cus_name,
				procProcCode: inv.procProcCode,
				invoice_number: inv.invoice_number,
				line_item_no: inv.line_item_no,
				amount: inv.amount
			}
			return invDets
		});

		/*
		console.log(data)
        let result = await axios.put(APIURL+'/invoice', data)
        if(result.data.code === 200){
            console.log(result.data)
            let invoiceList = this.state.invoiceList.map((emp)=> {
                    return emp
                }
            })
            // console.log(invoiceList)
            this.setState({invoiceList: invoiceList});
        }else{
            console.log('Error Occured')
        }
		*/

		/*
		let rows =  this.state.empList.map((emp)=> {
			let phnRec = !!emp.emp_phone_number?emp.emp_phone_number.replace(/[.-]/g,'').split('').filter((c)=> c !== ' ').join(''):'-'
			
			// emp.emp_phone_number
			let empDets =  {
				id: emp.emp_id, 
				emp_name:`${emp.emp_first_name} ${emp.emp_last_name}`,
				// emp_phone : phnRec,
				emp_phone: phnRec.length === 10?`${phnRec.split(',')[0].slice(0,3)}-${phnRec.split(',')[0].slice(3,6)}-${phnRec.split(',')[0].slice(6,10)}` :phnRec.length === 12?`${emp.emp_phone_number.split(',')[0].slice(2,5)}-${emp.emp_phone_number.split(',')[0].slice(5,8)}-${emp.emp_phone_number.split(',')[0].slice(8,12)}`:'-',  
				details: {
					onView : this.onView,
					onEdit : this.onEdit
				 },
				emp_status: emp.emp_is_active,
				emp_updated_at: !emp.emp_updated_at?'-':moment(emp.emp_updated_at).format('MM/DD/YYYY'), 
				emp_join_date: !emp.emp_join_date?'-':moment(emp.emp_join_date).format('MM/DD/YYYY')
			}
			return empDets
		})
		// console.log(rows,cols)
		*/

		return (
			<div>
				<Row>
					{/* EVV Invoice Render */}
					<Col>
						{/*<button onClick={this.handleClick}>test for endpoint</button>*/}
						<div style={{margin: 'auto', width: '100%', display: 'flex'}}>
							<DataGrid rows={rows} columns={columns} autoHeight={true} showToolbar components={{Toolbar: GridToolbar}} checkboxSelection={true} pageSize={16} />
						</div>
					</Col>
				</Row>
			</div>
		);
	}
}