import React, { Component } from 'react'
import Card from './Card'
import SessionCard from './SessionCard'
import Carousel from 'react-bootstrap/Carousel'
import './top_appointment.css'

class AppointmentCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            details: [],
            selectedDetail: 0
        }
        this.getDetails = this.getDetails.bind(this)
        this.prevAppointment = this.prevAppointment.bind(this)
        this.nextAppointment = this.nextAppointment.bind(this)
    }
    componentDidMount() {
        this.getDetails()
    }
    componentDidUpdate(prevProps, _) {
        if (prevProps.appointments !== this.props.appointments) {
            this.getDetails()
        }
        if (prevProps.selectedDay !== this.props.selectedDay || prevProps.selectedAppointment !== this.props.selectedAppointment) {
            let index = this.state.details.findIndex(item => item.key === `${this.props.selectedDay}_${this.props.selectedAppointment}`)
            if (index >= 0) {
                this.setState({
                    selectedDetail: index
                })
                if (this.swipeControl) {
                    this.swipeControl.slide(index, 10)
                }
            }
        }
    }
    getDetails() {
        let details = []
        this.props.appointments.forEach((appointment, index) => {
            let day = appointment.day
            appointment.details.forEach((item, index1) => {
                let key = `${day}_${index1}`
                let detail = item
                details.push({ key, detail })
            })
        })
        this.setState({
            details
        })
    }
    prevAppointment() {
        if (this.state.details.length > 0) {
            let index = this.state.selectedDetail
            index = (((index - 1) % this.state.details.length) + this.state.details.length) % this.state.details.length
            let key = this.state.details[index].key
            this.props.selectAppointment(key.split('_')[0], parseInt(key.split('_')[1]))
        }
    }
    nextAppointment() {
        if (this.state.details.length > 0) {
            let index = this.state.selectedDetail
            index = (index + 1) % this.state.details.length
            let key = this.state.details[index].key
            this.props.selectAppointment(key.split('_')[0], parseInt(key.split('_')[1]))
        }
    }
    render() {
        return (
            <div className='appointment_card'>
                <div style={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}>
                    <div className='card_arrow' style={{ display: 'flex', fontSize: 20, margin: 5, justifyContent: 'center', alignItems: 'center', flex: 0.1, cursor: 'pointer' }} onClick={this.prevAppointment}>
                        <i className="fas fa-chevron-left fa-1x"></i>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Carousel
                            activeIndex={this.state.selectedDetail}
                            controls={false}
                            indicators={false}
                            wrap={false}
                            onSelect={(eventKey, event) => {}}
                        >
                            {this.state.details.map((detail, index) => (
                                <Carousel.Item key={index}>
                                    {
                                        detail.detail.schedule_id ? <SessionCard detail={detail} index={index} user={this.props.user} /> :
                                        <Card user={this.props.user} canCheckIN={this.props.canCheckIN} onCheckIn={this.props.checkIn} onCheckOut={this.props.checkOut} showWarnMod={this.props.showWarnMod} onCancel={this.props.cancelAppoinntment} onReschedule={this.props.rescheduleAppointment} detail={detail} index={index} />
                                    }
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </div>
                    <div className='card_arrow' style={{ display: 'flex', fontSize: 20, justifyContent: 'center', margin: 5, alignItems: 'center', flex: 0.1, cursor: 'pointer' }} onClick={this.nextAppointment}>
                        <i className="fas fa-chevron-right fa-1x"></i>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1%' }}>
                    {this.state.details.map((item, index) => (
                        <div
                            key={index}
                            className={`appointment_card_nav_bullet ${item.key.split('_')[0] === this.props.selectedDay && parseInt(item.key.split('_')[1]) === this.props.selectedAppointment ? 'ACTIVE' : 'INACTIVE'}`}
                            onClick={() => this.props.selectAppointment(item.key.split('_')[0], parseInt(item.key.split('_')[1]))}
                        />
                    ))}
                </div>
            </div>
        )
    }
}

export default AppointmentCard
