import React from 'react';
import { useState, useEffect } from "react";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { APIURL } from '../../config';
import axios from 'axios';
import SupportCoordinatorManagement from './SupportCoordinatorManagement';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import NewNote from '../SupportCoordinator/Modals/NewNote';
import NewTask from '../SupportCoordinator/Modals/NewTask';
import NewTimesheet from '../SupportCoordinator/Modals/NewTimesheet';
import Upload from '../SupportCoordinator/Modals/Upload';
import SupportCoordinatorCustomers from '../SupportCoordinator/SupportCoordinatorCustomers';
import Home from '../../components/Home/HomeBox'
import moment from 'moment';

function SupportCoordinatorSupervisorHome(props) {
    const [dataLoading, setDataLoading] = useState(true);
    const [sDataLoading, setSDataLoading] = useState(true);
    const [value, setValue] = useState('1');
    const [data, setData] = useState(null);
    const [sData, setSData] = useState(null);

    const [showAddNote, setShowAddNote] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [showTask, setShowTask] = useState(false);
    const [showTimesheet, setShowTimesheet] = useState(false);

    const [noteCustomerID, setNoteCustomerID] = useState();
    const [uploadCustomerID, setUploadCustomerID] = useState("");
    const [timesheetCustomerID, setTimesheetCustomerID] = useState("")

    const handleShowNote = (id) => {setNoteCustomerID(id); setShowAddNote(true)};
    const handleShowUpload = (id) => {setUploadCustomerID(id); setShowUpload(true)};
    const handleShowTask = () => setShowTask(true);
    const handleCloseTask = () => setShowTask(false);
    const handleShowTimesheet = (id) => {setTimesheetCustomerID(id);setShowTimesheet(true)};
    const handleCloseTimesheet = () => setShowTimesheet(false);

    const isSubscibedToTimeManagement = props.user.subs.find((setting) => setting.feature_id === 12) ? true : false;

    const getEmployees = async () => {
        console.log(props.comp_id);
        console.log(props.user);
        let data = await axios.get(APIURL + "/monthlymonitoring/manager/data", {
            params: {
                comp_id: props.comp_id,
                emp_id: props.user.emp_id,
                start : moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
                end: moment().endOf('month').format('YYYY-MM-DDT23:59:59'),
            }
        });

        setData(data.data);
        console.log(data.data);
        setDataLoading(false);
    }
   
    const getSCH = async () => {
        let data = await axios.get(APIURL + "/customers/SCH", { params: { 
            comp_id: props.comp_id, 
            emp_id: props.user.emp_id,
            start : moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
            end: moment().endOf('month').format('YYYY-MM-DD'),
        } });
        setSData(data.data)
        setSDataLoading(false);
        console.log(data.data)
    }

    useEffect(async () => {
        props.checkTokenExpiry();
        await getEmployees();
        await getSCH();
    }, [props])


    const updateTaskStatus = (cusId, tasStatus, tasId, month) => {
        let newSData = [...sData];
        newSData.forEach((customer, i) => {
            if(customer.cus_id === cusId) {
                customer[month].forEach((task, j) => {
                    if(task.tas_id === tasId) {
                        newSData[i][month][j].tas_mm_status = tasStatus;
                    }
                })
            }
        })
        let newData = [...data];
        newData.forEach((employee, i) => {
            if(employee.customer.cus_id === cusId) 
                newData[i].customer[month].forEach((task, j) => {
                    if(task.tas_id === tasId) {
                        newData[i].customer[month][j].tas_mm_status = tasStatus;
                    }
                })
        })
        setData(newData);
        setSData(newSData);
    }


/*  const updateWithNewTask = (cusId, task, month) => {
        let newSData = sData;
        newSData.forEach((customer, i) => {
            if(customer.cus_id === cusId) {
                newSData[i][month].push(task);
            }
        })
        let newData = data;
        newData.forEach((employee, i) => {
            if(employee.customer.cus_id === cusId) {
                newData[i].customer[month].push(task);
            }
        })
        setData(newData);
        setSData(newSData);
    } */
    
    return (
        <div className="support-coordinator-home-wrapper">
            <div className="support-coordinator-home-wrapper-top-row">
                <Tabs
                    sx={{ width: '100%'}}
                    indicatorColor="primary"
                    textColor="primary"
                    value={value}>
                    <Tab onClick={() => setValue('1')} label="SC MANAGEMENT" value="1" />
                    <Tab onClick={() => setValue('2')} label="CUSTOMERS" value="2" />
                    <Tab onClick={() => setValue('3')} label="DASHBOARD" value="3" />
                    <Box sx={{ display: 'inline-flex', justifyContent: 'flex-end', width: "100%", marginBottom: 1  }}>
                        <ButtonGroup size="small" style={{ marginRight: 10 }}>
                            <Button variant="contained" onClick={() => setShowAddNote(true)}>NEW NOTE</Button>
                            <Button variant="contained" onClick={handleShowTask}>NEW TASK</Button>
                            {isSubscibedToTimeManagement &&
                                <Button variant="contained" onClick={handleShowTimesheet}>NEW TIMESHEET</Button>
                            }
                            <Button size="small" variant="contained" onClick={() => setShowUpload(true)}>UPLOAD</Button>                       
                        </ButtonGroup>
                    </Box>
                </Tabs>
                
                {value === '1' &&
                    <div className="customers-container">
                        <SupportCoordinatorManagement {...props} handleShowNote={handleShowNote} handleShowUpload={handleShowUpload} data={data?data:[]} comp_id={props.comp_id} emp_id={props.user.emp_id} getSCH={getSCH} getEmployees={getEmployees} updateTaskStatus={updateTaskStatus} loading={dataLoading}/>
                    </div>
                }
                {value === '2' &&
                    <div className="upcoming-appointments-container">
                        <SupportCoordinatorCustomers 
                            {...props} 
                            handleShowNote={handleShowNote} 
                            handleShowUpload={handleShowUpload} 
                            handleShowTimesheet={handleShowTimesheet} 
                            data={sData?sData:[]} 
                            comp_id={props.comp_id} 
                            emp_id={props.user.emp_id} 
                            rerender={() => {getSCH(); getEmployees()}} 
                            loading={sDataLoading}
                            isSubscibedToTimeManagement={isSubscibedToTimeManagement}
                        />
                    </div>
                }
                {value === '3' &&
                    <div >
                        <Home {...props} checkTokenExpiry={props.checkTokenExpiry} user={props.user} comp_id={props.comp_id} />
                    </div>
                }
                <div>
                    <NewNote showAddNote={showAddNote} setShowAddNote={setShowAddNote} company_id={props.comp_id} mode='add' user={props.user} customer_id={noteCustomerID} setNoteCustomerID={setNoteCustomerID}></NewNote>
                    <NewTask show={showTask} handleClose={handleCloseTask} user={props.user} emp_id={props.user.emp_id} comp_id={props.comp_id} {...props}/> 
                    {isSubscibedToTimeManagement && 
                        <NewTimesheet show={showTimesheet} handleClose={handleCloseTimesheet} comp_id={props.comp_id} user={props.user} emp_id={props.user.emp_id} customer_id={timesheetCustomerID} setTimesheetCustomerID={setTimesheetCustomerID} rerender={getSCH}></NewTimesheet>
                    }
                    <Upload showUpload={showUpload} setShowUpload={setShowUpload} company_id={props.comp_id} user={props.user} customer_id={uploadCustomerID} setUploadCustomerID={setUploadCustomerID} rerender={getSCH}></Upload>
                </div> 
            </div> 
        </div>
    )
}

export default SupportCoordinatorSupervisorHome;