import * as React from "react";
import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';

export default function TreeViewMTDetailsReports(props) {
  const [gridId, setGridId] = useState(39);
  const apiRef = useGridApiRef();
  const classes = useStyles();
  const [pageSize, setPageSize] = useState(20);
  const [pageNo, setPageNo] = useState(0);

  const onPageChange = (value) => {
    setPageNo(value);
  };
  const onPageSizeChange = (value) => {
    setPageSize(value);
    setPageNo(0);
  };

  const columns = [
    { field: "previous_month", headerName: moment().subtract(1, "month").startOf("month").format("MMM"), },
    { field: "current_month", headerName: moment().format("MMM") },
    { field: "next_month", headerName: moment().add(1, "month").endOf("month").format("MMM"), },
    { field: "local_id", headerName: "Local ID" },
    { field: "ddd_id", headerName: "DDD ID" },
    { field: "task_type", headerName: "Task Type", width: 200 },
    { field: "task_location", headerName: "Task Location", width: 350 },
    {
      field: "contact_date",
      headerName: "Contact Date",
      renderCell: (params) => {
        return (
          <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
        )
      }
    },
    { field: "status", headerName: "MT Status", width: 150 },
    { field: "emp_name", headerName: "Employee Name", width: 150 },
  ]

  let rows = [];

  /* Supervisor => Admins (or Managers) */
  props.data?.map((sv, index) => {
    let svData = {
      hierarchy: [
        sv?.emp_first_name + " " + sv?.emp_last_name + " " + sv?.emp_id,
      ],
      rowId: sv?.emp_id + index + 1 + Math.random().toString(36).substring(2, 12) + index,
      previous_month: sv.previous_month,
      current_month: sv.current_month,
      next_month: sv.next_month,
      contact_date: '',
      emp_name: sv?.emp_first_name + " " + sv?.emp_last_name,
    };
    rows.push(svData);

    /* Customers related to supervisor */
    if (sv?.customers?.length > 0) {
      sv.customers.map((cus, ind) => {
        let sortedTasks = cus?.tasks.sort((a, b) => moment(b.tas_start_date) - moment(a.tas_start_date))
        let svCustomers = {
          hierarchy: [
            sv?.emp_first_name + " " + sv?.emp_last_name + " " + sv?.emp_id,
            cus?.cus_name + " " + cus?.cus_id + '(' + ind + ')',
          ],
          rowId: cus?.cus_id + ind + Math.random().toString(36).substring(2, 12) + index,
          ddd_id: cus?.DDDID,
          local_id: cus?.cus_local_id,
          task_type: sortedTasks[0]?.tas_type,
          task_location: sortedTasks[0]?.location_full_address,
          contact_date: sortedTasks[0]?.tas_start_date,
          status: sortedTasks[0]?.tas_mm_status,
          emp_name: sv?.emp_first_name + " " + sv?.emp_last_name,
        };
        rows.push(svCustomers);
      });
    }

    /* Support Coordinators */
    sv?.suppport_coordinators.map((sc, ind) => {
      let scData = {
        hierarchy: [
          sv?.emp_first_name + " " + sv?.emp_last_name + " " + sv?.emp_id,
          sc?.emp_first_name + " " + sc?.emp_last_name + " " + sc?.emp_id,
        ],
        rowId: sc?.emp_id + ind + Math.random().toString(36).substring(2, 12) + ind,
        previous_month: sc.previous_month,
        current_month: sc.current_month,
        next_month: sc.next_month,
        contact_date: '',
        emp_name: sc?.emp_first_name + " " + sc?.emp_last_name,
      };
      rows.push(scData);

      /* Customers related to Support Coordinators */
      sc?.customers.map((cus, inx) => {
        let sortedTasks = cus?.tasks.sort((a, b) => moment(b.tas_start_date) - moment(a.tas_start_date))
        let scCustomers = {
          hierarchy: [
            sv?.emp_first_name + " " + sv?.emp_last_name + " " + sv?.emp_id,
            sc?.emp_first_name + " " + sc?.emp_last_name + " " + sc?.emp_id,
            cus?.cus_name + " " + cus?.cus_id + " " + cus?.pro_id,
          ],
          rowId: cus?.cus_id + ind + Math.random().toString(36).substring(2, 12) + index,
          ddd_id: cus?.DDDID,
          local_id: cus?.cus_local_id,
          task_type: sortedTasks[0]?.tas_type,
          task_location: sortedTasks[0]?.location_full_address,
          contact_date: sortedTasks[0]?.tas_start_date,
          status: sortedTasks[0]?.tas_mm_status,
          emp_name: sc?.emp_first_name + " " + sc?.emp_last_name,
        };
        rows.push(scCustomers);
      });
    });
  });

  const groupingColDef = {
    headerName: 'Employees',
    valueFormatter: (params) => typeof params.id === 'number' ? '' : params.value
  };

  return (
    <Grid>
      {rows.length === 0 ?
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "500px", marginTop: 10 }}
        >
          <img
            src="./nosearchresult.png"
            alt="nosearchresult"
            style={{ width: "25rem", height: "15rem", margin: 10 }}
          />
          <h5
            style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
            className="text-center"
          >
            There is currently no data available{" "}
          </h5>
        </div>
        :
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ height: '77vh', width: "100%", marginTop: 1 }}>
              <Box sx={{ width: '100%', height: "calc(100vh - 220px)" }}>
                <ControlledDataGrid
                  gridId={gridId}
                  user={props.user}
                  initialState={{ sorting: { sortModel: [{ field: "contact_date", sort: "desc" }] } }}
                  apiRef={apiRef}
                  className={classes.GridTableBox}
                  rows={rows}
                  columns={columns}
                  pagination
                  pageSize={pageSize}
                  rowsPerPageOptions={[20, 50, 100]}
                  disableSelectionOnClick
                  treeData
                  onPageSizeChange={onPageSizeChange}
                  page={pageNo}
                  onPageChange={onPageChange}
                  getTreeDataPath={(row) => row.hierarchy}
                  getRowId={(row) => row.rowId}
                  scrollbarSize={1}
                  groupingColDef={groupingColDef}
                  columnVisibilityModel={{
                    emp_name: false,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      }
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  GridTableBox: {
    "& .MuiDataGrid-columnHeader:first-child": {
      width: "300px !important",
      maxWidth: "300px !important",
    },
    "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:first-child":
    {
      width: "300px !important",
      maxWidth: "300px !important",
    },
  },
}));
