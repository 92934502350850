import Button from '@mui/material/Button';
import { ToggleOff, ToggleOn } from '@mui/icons-material';
import axios from 'axios';
import { APIURL } from '../../config';

const ToggleButton = (props) => {

    return (
        <Button
            size="small"
            onClick={props.onClick}
            startIcon={props.value ? <ToggleOn /> : <ToggleOff />}
        >
            {props.label}
        </Button>
    )
}

export default ToggleButton;