import { useState, useEffect, useCallback, memo } from "react";
import TextField from '@mui/material/TextField';
import lodash from "lodash";

const FollowUpItemTextField = (props) => {
    const [value, setValue] = useState(null);
    const [error, setError] = useState(false);
    const [read, setRead] = useState(true);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if(!props.disabled)
            setError(props.error);
    }, [props.error]);

    useEffect(() => {
        setRead(props.read);
    }, [props.read]);

    useEffect(() => {
        if(read !== true) {
            if(props.disabled === true) {
                setValue("");
                if(props.onChange !== undefined) props.onChange({ id: props.item.id, question: "c", value: "" });
                if(!isValid(value)) setError(false);
            } else {
                if(!isValid(props.value)) {
                    if(props.dependentValue)
                        props.onChange(props.dependentValue);
                }
                setError(true);
            }
        }
    }, [props.disabled]);
    
    useEffect(() => {
        if(props.setSectionError !== undefined && props.section !== undefined) 
            error ? props.setSectionError((prev) => { 
                let newPrev = [...prev];
                newPrev[props.section].push('followuptextfield');
                return newPrev;
            }) : 
            props.setSectionError((prev) => {
                let newPrev = [...prev];
                const index = newPrev[props.section].indexOf('followuptextfield');
                if (index > -1) {
                    newPrev[props.section].splice(index, 1);
                }
                return newPrev;
            });
    }, [error]);

    useEffect(() => {
        if(!props.disabled)  {
            console.log(value);
            setError(!isValid(value));
        }
    }, [value, props.validate]);

    const isValid = (value) => {
        return !(value === null || value === "" || value === undefined);
    }

    return (
        <TextField
            error={error && props.validate && !props.disabled}
            label={props.label}
            multiline
            minRows={props.minRows}
            maxRows={props.maxRows}
            value={value}
            onChange={e => setValue(e.target.value)}
            onBlur={useCallback((e) => props.onChange({ id: props.item.id, question: "c", value: e.target.value }))}
            variant={props.variant}
            sx={{...props.sx}}
            inputProps={{...props.inputProps}}
            InputProps={{...props.InputProps}}
            disabled={props.disabled}
            placeholder={props.placeholder}
        />
    );
}

export default memo(FollowUpItemTextField,
    (prevProps, nextProps) => {
        if(lodash.isEqual(prevProps.sx, nextProps.sx) && lodash.isEqual(prevProps.inputProps, nextProps.inputProps) && lodash.isEqual(prevProps.InputProps, nextProps.InputProps) && lodash.isEqual(prevProps.value, nextProps.value) && lodash.isEqual(prevProps.disabled, nextProps.disabled) && lodash.isEqual(prevProps.item, nextProps.item) && lodash.isEqual(prevProps.placeholder, nextProps.placeholder)
        && lodash.isEqual(prevProps.error, nextProps.error) && lodash.isEqual(prevProps.label, nextProps.label) && lodash.isEqual(prevProps.minRows, nextProps.minRows) && lodash.isEqual(prevProps.maxRows, nextProps.maxRows) && prevProps.onChange == nextProps.onChange && lodash.isEqual(prevProps.variant, nextProps.variant)
        && lodash.isEqual(prevProps.validQuestions, nextProps.validQuestions) && prevProps.setValidQuestions == nextProps.setValidQuestions && lodash.isEqual(prevProps.validate, nextProps.validate) && prevProps.setSectionError == nextProps.setSectionError && lodash.isEqual(prevProps.section, nextProps.section)) {
            return true;
        } else {
            return false;
        }
    }
);
