import React,{Component} from 'react'
import Chart from 'chart.js';
// import axios from 'axios'

class HomePie extends Component{
    // chartRef = React.createRef();

    async componentDidUpdate(){
        try{
        document.getElementById('myChart').remove()
        document.getElementById('pieContainer').innerHTML = `<canvas id="myChart" width="100" height="100"><canvas>`;
        let canvas = document.querySelector('#myChart');
        let ctx = canvas.getContext('2d');
        // const myChartRef = this.chartRef.current.getContext("2d");
        new Chart(ctx, {
            type: 'pie',
            data: {
                datasets: [{
                    data: [!!this.props.data?this.props.data.complete:1,!!this.props.data?this.props.data.upcoming:1,!!this.props.data?this.props.data.ongoing:1],
                    backgroundColor: [
                        '#FFEABA',
                        '#FFD166',
                        '#3FA7D6'
                    ]
                }],
                labels: [
                    'Complete',
                    'Upcoming',
                    'OnGoing'
                ]
            }
        });}
        catch(e){return }
    }

    render(){
        return(
            <div id="pieContainer" className="m-0"> 
             {(this.props.length === 0 && !this.props.isLoading && !!this.props.data && !this.props.data.complete !== 0 && !this.props.data.upcoming !== 0 && !this.props.data.ongoing !== 0) &&
              <div className='d-flex flex-column justify-content-center align-items-center' style={{height: '300px',margin: 5}}>
                <img src='./pie.png' alt='piechart' style={{width: '10rem', height: '10rem'}}/>
                <h5 style={{fontFamily: 'Lato',fontSize: 18,marginTop: 20,  marginBottom: 10}}>You haven’t added any tasks to your projects yet </h5>
                <p style={{fontFamily: 'Lato',fontSize: 12}} className='text-muted'> There’s no time like the present – let’s get some tasks set up now! You’ll see an overview here.</p>
          </div>
             }
             {!!this.props.isLoading && 
            <div style={{height: '400px', margin: 10}} className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
            </div></div>
            }
           {this.props.length !== 0 && !this.props.isLoading && !!this.props.data &&  <canvas id="myChart" ref={this.chartRef} width="10rem" height="10rem"></canvas>}
            </div>
        )
    }
}

export default HomePie