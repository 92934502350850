import React, { Component } from 'react';
import { Button, Form, Col, Alert, Modal } from 'react-bootstrap';
import { Emoji } from 'emoji-mart';
import axios from 'axios';
import moment from "moment";
import { APIURL } from '../../../config'
import { Card, CardActions, CardContent, Autocomplete, TextField } from '@mui/material'
import dd from '../../../manifestdd.json'

class People extends Component {
    state = {
        loading: true,
        show: false,
        timer: null,
        cusTimer: null,
        displayEmp: [],
        deletedEmp: null,
        content: [],
        displayContact: [],
        deletedContact: null,
        contactsList: [],
        contactContent: [],
        conid: null,
        projData: [],
        employee_list: [],
        employeeStatus: [],
        value: null,
        isEdit: false,
        currentEmployee: null,
        empAsgnFormVals: {
            empName: '',
            pEmpStatus: '',
            pEmpBillRate: null,
            pEmpCurrency: 'USD',
            pEmpFrequency: '',
            pEmpAssFromDate: '',
            pEmpAssToDate: '',
            pea_id: ''
        },
        success: 'In Progress',
        formError: false,
        errorMsg: '',
        isLoading: false,
        project: null,
        projId: null,
        trackEmployeeCost: this.props.user.comp_settings.find(setting => setting.feature_id === 54 && setting.feature_value === 'true') ? true : false
    }

    handleShow = (emp) => { this.setState({ currentEmployee: emp, show: true }) };

    handleClose = () => { this.setState({ show: false }) };

    onConSelect = (value) => {
        this.setState({ conid: value })
    }

    onAddContact = async () => {
        //Check if exist in States
        if (!this.state.conid) {
            return
        }
        let conExist = this.state.displayContact.find((con) => con.con_id === +this.state.conid)
        if (!conExist) {
            let msg = await axios.put(APIURL + '/project/updateProjContact', { projId: this.state.projId, contId: this.state.conid })
            console.log(msg.data)
            if (msg.data.code === 200) {
                //find contact in list 
                let addContact = this.state.contactsList.find((con) => ((con.con_id === +this.state.conid) || (con.id === +this.state.conid)))
                // console.log(addContact)
                if (!!addContact.id) {
                    addContact = { ...addContact, con_id: addContact.id, con_first_name: addContact.contact_name.split(' ')[0], con_last_name: addContact.contact_name.split(' ')[1] }
                    // addContact = {con_phone: addContact.con_phone,con_first_name: 'Reload',con_last_name: 'Reload',con_id: addContact.id, con_designation: 'Reload'}
                }
                // push in state
                this.setState({ conid: null, displayContact: [...this.state.displayContact, addContact] })
                alert("Contact Added")
            }
        } else {
            //Alert User
            this.setState({ conid: null })
            return
        }
    }

    async fetchData() {

        const projResponse = await axios.get(APIURL + `/project`, { params: { cus_id: this.props.cus_id } })
        let projects = projResponse.data

        if (projects.length !== 0) {
            let project = await axios.get(APIURL + "/project", { params: { proj_id: projects[0].pro_id, comp_id: this.props.comp_id } })

            this.setState({ projId: project.data.pro_id, displayEmp: !project.data.employees ? [] : project.data.employees.filter((emp) => (emp.pea_is_active !== false)), displayContact: !project.data.contacts ? [] : project.data.contacts.filter((con) => (con.con_is_active !== false)), project: project })

            let employee_list = await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: this.props.comp_id } })
            let empStatus = await axios.get(APIURL + '/dropdowns/dropdownType/empStatus', { params: { comp_id: this.props.comp_id } })
            let contacts = await axios.get(APIURL + "/contacts", { params: { comp_id: this.props.comp_id } })

            this.generateEmpCards(this.state.displayEmp)
            this.generateContactCards(this.state.displayContact)

            this.setState({ contactsList: contacts.data, employee_list: employee_list.data, employeeStatus: empStatus.data })
        }

        this.setState({ loading: false })
    }

    async componentDidMount() {
        this.fetchData()
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.deletedEmp !== this.state.deletedEmp) || (prevState.deletedContact !== this.state.deletedContact) || (prevState.conid !== this.state.conid) || (prevState.success !== this.state.success)) {
            this.fetchData()
        }
    }

    generateContactCard = (contact) => {
        return (
            <Card sx={{ marginBottom: '10px', height: '40px' }}>
                <CardContent sx={{}}>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '18%' }}>{contact.con_first_name} {contact.con_last_name}</div>
                            <div style={{ width: '18%' }}>{contact.con_contact_type}</div>
                            <div style={{ width: '14%' }}>{contact.con_phone}</div>
                            <div style={{ width: '45%' }}>{contact.con_Addr_line1}, {contact.con_Addr_line2},{contact.con_city}, {contact.con_state},{contact.con_zip}</div>
                        </div>
                    </div>
                    <div style={{ float: 'right', marginTop: '-30px' }}>
                        <span>
                            <i
                                onClick={() => this.onDelContact(contact.con_id)}
                                className="fas fa-trash"
                                style={{ cursor: "pointer" }}
                            ></i>
                        </span>
                    </div>
                </CardContent>
                <CardActions>

                </CardActions>
            </Card>
        )
    }

    generateContactCards = (contactList) => {
        let cards = []
        cards = contactList.map((contact) => {
            return this.generateContactCard(contact)
        })
        this.setState({ contactContent: cards })
    }

    onDelContact = async (id) => {
        // console.log(id)
        if (this.state.cusTimer) {
            alert("Another delete is in process, wait for the undo dailogue to disappear")
        } else {
            let oldContactList = [...this.state.displayContact]
            oldContactList = oldContactList.filter(con => con.con_id !== id)
            this.setState({
                deletedContact: this.state.displayContact.find(con => con.con_id === id),
                cusTimer: setTimeout(() => {
                    this.deleteCusRequest(this.state.deletedContact.con_id)
                    clearTimeout(this.state.cusTimer)
                    this.setState({
                        cusTimer: null,
                        deletedContact: null
                    })
                }, 5000),
                displayContact: oldContactList
            })
        }


    }

    deleteCusRequest = (id) => {
        axios.delete(APIURL + '/project/updateProjContact', { data: { projId: this.state.projId, contId: id } })
            .then(res => {
                if (res.status === 200) {
                    alert("Contact Deleted")
                } else {
                    alert("Something went wrong.")
                }
            })
            .catch(error => console.log(error))
    }

    showCusAlert = () => {
        if (this.state.cusTimer) {
            return (
                <Alert variant="info" onClose={() => this.onDismissCusAlert()} style={{ marginTop: "10px" }} dismissible>
                    Contact Deleted. <span className="undo" onClick={() => this.undoCusDelete()}>Undo?</span>
                </Alert>
            )
        }
    }

    onDismissCusAlert = () => {
        this.deleteCusRequest(this.state.deletedContact.con_id)
        clearTimeout(this.state.cusTimer)
        this.setState({
            cusTimer: null,
            deletedContact: null
        })
    }

    undoCusDelete = () => {
        let oldContactList = [...this.state.displayContact]
        oldContactList.push(this.state.deletedContact)
        clearTimeout(this.state.cusTimer)
        this.setState({
            displayContact: oldContactList,
            deletedContact: null,
            cusTimer: null
        })
    }

    generateEmpCard = (emp) => {
        return (
            <Card sx={{ marginRight: '20px', marginTop: '20px', width: '200px', height: '200px', display: 'inline-block' }}>
                <CardContent sx={{}}>
                    <div style={{ textAlign: 'center' }}>
                        <div>
                            <i className="fas fa-user-circle fa-3x text-muted"></i>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            <h6 style={{ marginBottom: '0px' }}>{emp.emp_first_name} {emp.emp_last_name}</h6>
                            <p style={{ marginBottom: '0px', color: 'gray', fontSize: 13 }}>{!!emp.emp_type ? emp.emp_type : <br></br>}</p>
                        </div>
                    </div>
                    <div style={{ marginTop: '45px', marginLeft: '125px' }}>
                        <span>
                            <i
                                onClick={() => this.handleShow(emp)}
                                className="fas fa-edit"
                                style={{ margin: "10px", cursor: "pointer" }}
                            ></i>
                            <i
                                onClick={() => this.onEmpDel(emp.emp_id)}
                                className="fas fa-trash"
                                style={{ cursor: "pointer" }}
                            ></i>
                        </span>
                    </div>
                </CardContent>
            </Card>
        )
    }

    generateEmpCards = (empList) => {
        let cards = []
        cards = empList.map((emp) => {
            return this.generateEmpCard(emp)
        })
        this.setState({ content: cards })
    }

    editForm = (empId) => {
        let selEmp = this.state.displayEmp.find((emp) => (emp.emp_id === empId));
        this.setState({ value: empId, empAsgnFormVals: { ...this.state.empAsgnFormVals, empName: `${selEmp.emp_first_name} ${selEmp.emp_last_name}`, pEmpStatus: selEmp.pea_status, pEmpBillRate: selEmp.pea_bill_rate, pEmpFrequency: selEmp.pea_frequency, pEmpAssFromDate: selEmp.pea_associate_from, pEmpAssToDate: selEmp.pea_associate_to, pea_id: selEmp.pea_id }, isEdit: true })
        this.handleClose();
    }

    onEmpDel = async (id) => {
        if (this.state.timer) {
            alert("Another delete is in process, wait for the undo dailogue to disappear.")
        } else {
            let oldEmpList = [...this.state.displayEmp]
            oldEmpList = oldEmpList.filter(emp => emp.emp_id !== id)
            this.setState({
                deletedEmp: this.state.displayEmp.find(emp => emp.emp_id === id),
                timer: setTimeout(() => {
                    this.deleteRequest(this.state.deletedEmp.pea_id)
                    clearTimeout(this.state.timer)
                    this.setState({
                        timer: null,
                        deletedEmp: null
                    })
                }, 5000),
                employees: oldEmpList
            })
        }
    }

    deleteRequest = (id) => {
        axios.delete(APIURL + '/project/UpdateProjEmp', { data: { id: id } })
            .then(res => {
                if (res.status === 200) {
                    alert("Emp Deleted")
                } else {
                    alert("Something went wrong.")
                }
            })
            .catch(error => console.log(error))
    }

    showAlert = () => {
        if (this.state.timer) {
            return (
                <Alert variant="info" onClose={() => this.onDismissAlert()} style={{ marginTop: "10px" }} dismissible>
                    Employee Deleted. <span className="undo" onClick={() => this.undoDelete()}>Undo?</span>
                </Alert>
            )
        }
    }

    onDismissAlert = () => {
        this.deleteRequest(this.state.deletedEmp.pea_id)
        clearTimeout(this.state.timer)
        this.setState({
            timer: null,
            deletedEmp: null
        })
    }

    undoDelete = () => {
        let oldEmpList = [...this.state.displayEmp]
        oldEmpList.push(this.state.deletedEmp)
        clearTimeout(this.state.timer)
        this.setState({
            displayEmp: oldEmpList,
            deletedEmp: null,
            timer: null
        })
    }

    onChange = (value) => {
        console.log(value)
        if (!value) {
            this.setState({ value: null })
            return
        }
        this.setState({
            value: value,
            empAsgnFormVals: { ...this.state.empAsgnFormVals, empName: this.state.employee_list.find((emp) => (emp.id === +value)).employee_name },
            isEdit: false
        })
    }

    clearStates = () => {
        this.setState({ value: null, empAsgnFormVals: { ...this.state.empAsgnFormVals, empName: '', pEmpStatus: '', pEmpBillRate: '', pEmpFrequency: '', pEmpAssFromDate: '', pEmpAssToDate: '' }, isEdit: false, success: 'In Progress' })
    }

    async empAssignmentReq(e) {
        e.preventDefault()
        this.setState({ isLoading: true })
        var data = {
            id: this.state.empAsgnFormVals.pea_id,
            projId: this.state.projId,
            empId: this.state.value,
            status: this.state.empAsgnFormVals.pEmpStatus,
            billRate: this.state.empAsgnFormVals.pEmpBillRate ? this.state.empAsgnFormVals.pEmpBillRate : null,
            currency: this.state.empAsgnFormVals.pEmpCurrency,
            freq: this.state.empAsgnFormVals.pEmpFrequency,
            assFrom: this.state.empAsgnFormVals.pEmpAssFromDate,
            assTo: this.state.empAsgnFormVals.pEmpAssToDate,
            comp_id: this.props.comp_id
        }
        let msg;
        if (!this.state.isEdit) {
            msg = await axios.put(APIURL + '/project/UpdateProjEmp', data)
        } else {
            msg = await axios.put(APIURL + '/update/projEmp', data)
        }
        console.log(msg.data)
        // check msg and change modal contents
        if (msg.data.code === 200) {
            if (!this.state.isEdit) {
                //find employee from all employee if add
                let selEmp = this.state.employee_list.find((emp) => (emp.id === +this.state.value))
                let addedEmp = { emp_id: selEmp.id, emp_first_name: selEmp.employee_name.split(' ')[0], emp_last_name: selEmp.employee_name.split(' ')[1], emp_type: selEmp.emp_type, pea_id: msg.data.pea_id, pea_status: this.state.empAsgnFormVals.pEmpStatus, pea_bill_rate: this.state.empAsgnFormVals.pEmpBillRate, pea_currency: this.state.empAsgnFormVals.pEmpCurrency, pea_frequency: this.state.empAsgnFormVals.pEmpFrequency, pea_associate_from: this.state.empAsgnFormVals.pEmpAssFromDate, pea_associate_to: this.state.empAsgnFormVals.pEmpAssToDate, pea_is_active: true }
                //add to employees state if add
                this.setState({ displayEmp: [...this.state.displayEmp, addedEmp] })
            } else {
                //find employee from employee state if edit
                let selEmp = this.state.displayEmp.find((emp) => (emp.emp_id === this.state.value))
                //edit in employees state if edit 
                let editEmp = { ...selEmp, pea_status: this.state.empAsgnFormVals.pEmpStatus, pea_bill_rate: this.state.empAsgnFormVals.pEmpBillRate, pea_currency: this.state.empAsgnFormVals.pEmpCurrency, pea_frequency: this.state.empAsgnFormVals.pEmpFrequency, pea_associate_from: this.state.empAsgnFormVals.pEmpAssFromDate, pea_associate_to: this.state.empAsgnFormVals.pEmpAssToDate }
                let newEmpList = this.state.displayEmp.map((emp) => {
                    if (emp.emp_id === this.state.value) {
                        emp = editEmp
                    }
                    return emp
                })
                this.setState({ displayEmp: newEmpList })
            }
            this.setState({ success: 'Pass', isLoading: false })
        } else {
            this.setState({ success: 'Fail', isLoading: false })
        }
    }

    onEmpChange = (evt) => {
        let empAssnFormVals = this.state.empAsgnFormVals;
        if (evt.target.id === 'pEmpAssFromDate') {
            if (moment(evt.target.value).isBetween(moment(this.state.project.pro_starts_on).subtract(1, 'd'), moment(this.state.project.pro_ends_on).add(1, 'd'))) {
                empAssnFormVals[evt.target.id] = evt.target.value
                this.setState({ errorMsg: '', formError: false })
            } else {
                empAssnFormVals[evt.target.id] = evt.target.value
                this.setState({ errorMsg: `Please Enter Valid Date Between ${moment(this.state.project.pro_starts_on).format('MMM DD YYYY')} - ${moment(this.state.project.pro_ends_on).format('MMM DD YYYY')}`, formError: true })
            }
        } else if (evt.target.id === 'pEmpAssToDate') {
            if (!empAssnFormVals.pEmpAssFromDate) {
                empAssnFormVals[evt.target.id] = evt.target.value
                this.setState({ errorMsg: `Please Enter Associate From Field First`, formError: true })
            } else if (empAssnFormVals.pEmpAssFromDate && !moment(evt.target.value).isBetween(moment(empAssnFormVals.pEmpAssFromDate).subtract(1, 'd'), moment(this.state.project.pro_ends_on).add(1, 'd'))) {
                empAssnFormVals[evt.target.id] = evt.target.value
                this.setState({ errorMsg: `Please Enter Associate To Field in range ${moment(empAssnFormVals.pEmpAssFromDate).format('MMM DD YYYY')} - ${moment(this.state.project.pro_ends_on).format('MMM DD YYYY')}`, formError: true })
            } else {
                empAssnFormVals[evt.target.id] = evt.target.value
                this.setState({ errorMsg: '', formError: false })
            }
        }
        // else if(evt.target.value === ''){
        //     this.setState({ errorMsg: `Please Enter Missing Fields First`, formError: true })
        // }
        else {
            empAssnFormVals[evt.target.id] = evt.target.value
            this.setState({ errorMsg: '', formError: false })
        }
        // console.log(empAssnFormVals)
        this.setState({ empAsgnFormVals: empAssnFormVals })
        // this.setState({empAsgnFormVals: {...this.state.empAsgnFormVals, [evt.target.id]: evt.target.value}})
    }


    render() {
        const empStatus = this.state.employeeStatus.map((empSt, indx) => <option key={indx} value={empSt}>{empSt}</option>)
        let frequency = dd.generic.frequency.map((freq, indx) => <option key={indx} value={freq.value}>{freq.value}</option>)

        let employeeList = this.state.employee_list.filter(emp => {
            let empInfo = this.state.displayEmp.some(item => {
                return (emp.id === item.emp_id)
            })
            return !empInfo
        })

        employeeList.sort((a, b) => {
            if (a.employee_name < b.employee_name)
                return -1;
            if (a.employee_name > b.employee_name)
                return 1;
            return 0;
        })

        employeeList = employeeList.map(emp => emp.id)
        console.log(this.state.contactsList);

        let contactList = this.state.contactsList.sort((a, b) => {
            if (a.contact_name < b.contact_name)
                return -1;
            if (a.contact_name > b.contact_name)
                return 1;
            return 0;
        }).map(con => con.id)
        
        let content = null;
        content = (
            <div className="TasksPage" style={{maxWidth:'100vw'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="TopPanel">
                        <div style={{ marginRight: '10px' }}>
                            <Autocomplete
                                id="employee-select-box"
                                sx={{ width: "300px" }}
                                options={employeeList}
                                value={this.state.value}
                                onChange={(e, v) => this.onChange(v)}
                                getOptionLabel={(option) => {
                                    let emp = this.state.employee_list.find((emp) => (emp.id === option))
                                    return `${emp?.employee_name}`
                                }}
                                renderInput={(params) => <TextField {...params} label="Select Employee" variant="outlined" size="small" />}
                                renderOption={(props, option) => { return (<li {...props} key={option.id}>{this.state.employee_list.find((emp) => (emp.id === option)).employee_name}</li>) }}
                            />                            
                        </div>
                        <div>
                            {
                                !this.state.trackEmployeeCost ? 
                                <Button onClick={(e) => this.empAssignmentReq(e)}>
                                    + Add Employee
                                </Button> :
                                <Button data-toggle="modal" data-target="#empEdit">
                                    + Add Employee
                                </Button>
                            }
                        </div>
                        <div className="modal fade" id="empEdit" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Project - Employee Assignment {!this.state.isEdit ? "" : "Edit "}Form</h5>
                                        <button type="button" onClick={() => this.clearStates()} className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>

                                    <div className="modal-body">
                                        <Form
                                            // onSubmit={(e)=> {e.preventDefault(); console.log(this.state.empAsgnFormVals)}}
                                            onSubmit={(e) => this.empAssignmentReq(e)}
                                        >
                                            {this.state.formError &&
                                                <Alert variant="info">
                                                    {!this.state.errorMsg ? 'Please Fill out missing form fields' : this.state.errorMsg}
                                                </Alert>}
                                            {!this.state.value &&
                                                <div className="alert alert-warning text-center" role="alert">
                                                    Please Select Employee to be Assigned
                                                </div>
                                            }
                                            {this.state.value && this.state.success === 'Fail' &&
                                                <div style={{ fontSize: '0.8rem' }} className="alert alert-danger text-center" role="alert">
                                                    Employee {this.state.empAsgnFormVals.empName} was not Assigned to the Project Sucessfully
                                                </div>
                                            }
                                            {this.state.value && this.state.success === 'Pass' &&
                                                <div style={{ fontSize: '0.8rem' }} className="alert alert-success text-center" role="alert">
                                                    Employee {this.state.empAsgnFormVals.empName} Assigned to the Project Sucessfully
                                                </div>
                                            }
                                            {this.state.value && this.state.success === 'In Progress' &&
                                                <div className="d-flex flex-column justify-content-between">
                                                    <p style={{ fontSize: '1.5rem' }}>{this.state.empAsgnFormVals.empName}</p>
                                                    <div>
                                                        <Form.Group controlId="pEmpStatus">
                                                            <Form.Label>EMPLOYEE STATUS</Form.Label>
                                                            <Form.Control value={this.state.empAsgnFormVals.pEmpStatus} as="select" onChange={(e) => this.onEmpChange(e)} required>
                                                                <option value="">Select Status</option>
                                                                {empStatus}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Row>
                                                            <Form.Group as={Col} controlId="pEmpBillRate">
                                                                <Form.Label>BILL RATE</Form.Label>
                                                                <Form.Control value={this.state.empAsgnFormVals.pEmpBillRate} placeholder="Amount" onChange={(e) => this.onEmpChange(e)} required={this.state.trackEmployeeCost} />
                                                            </Form.Group>

                                                            <Form.Group as={Col} controlId="pEmpCurrency">
                                                                <Form.Label>CURRENCY</Form.Label>
                                                                <div className="d-flex flex-row justify-content-around" style={{ width: '12.5rem', height: '2.4rem', border: '1px solid #D6D6D6', borderRadius: '5px', textAlign: 'center' }}>
                                                                    <div style={{ marginLeft: 0, marginTop: 5 }}><Emoji emoji=':flag-us:' size={26} /></div> <div style={{ marginRight: 5, marginTop: 5 }}>USD</div>
                                                                </div>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Group controlId="pEmpFrequency">
                                                            <Form.Label>FREQUENCY</Form.Label>
                                                            <Form.Control value={this.state.empAsgnFormVals.pEmpFrequency} as="select" onChange={(e) => this.onEmpChange(e)} required={this.state.trackEmployeeCost}>
                                                                <option value="">Select Hourly</option>
                                                                {frequency}
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <Form.Group controlId="pEmpAssFromDate">
                                                            <Form.Label>ASSOCIATE FROM</Form.Label>
                                                            <Form.Control value={this.state.empAsgnFormVals.pEmpAssFromDate} type="date" onChange={(e) => this.onEmpChange(e)} required />
                                                        </Form.Group>

                                                        <Form.Group controlId="pEmpAssToDate">
                                                            <Form.Label>ASSOCIATE TO</Form.Label>
                                                            <Form.Control value={this.state.empAsgnFormVals.pEmpAssToDate} type="date" onChange={(e) => this.onEmpChange(e)} required />
                                                        </Form.Group>
                                                    </div>
                                                    <button disabled={this.state.isLoading} type="submit" className="btn btn-primary">{!!this.state.isLoading ? <div className='d-flex flex-row justify-content-center'><div className="spinner-border text-primary text-center border border-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div></div> : 'Save changes'}</button>
                                                </div>
                                            }
                                        </Form>
                                    </div>
                                    {(!this.state.value || this.state.success === 'Fail' || this.state.success === 'Pass') &&
                                        <div style={{ justifyContent: 'center' }} className="modal-footer">
                                            <button type="button" onClick={() => { this.clearStates() }} className="btn btn-primary" data-dismiss="modal">Close</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        {this.state.currentEmployee &&
                            <div>
                                <Modal show={this.state.show} onHide={this.handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Employee Assignment Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body style={{ fontSize: '1.1rem' }}>
                                        <div className="d-flex justify-content-between">
                                            <div className="h3">{this.state.currentEmployee.emp_first_name + " " + this.state.currentEmployee.emp_last_name}</div>
                                            <i className="fas fa-edit" style={{ cursor: "pointer" }} data-toggle="modal" data-target="#empEdit" onClick={() => this.editForm(this.state.currentEmployee.emp_id)}></i>
                                        </div>
                                        <div className="customer-info-block row">
                                            <div className="col-sm-5 pt-4 text-muted">EMPLOYEE STATUS</div>
                                            <div className="col-sm-7 pt-4">{this.state.currentEmployee.pea_status}</div>
                                        </div>
                                        <div className="customer-info-block row">
                                            <div className="col-sm-5 pt-4 text-muted">BILL RATE</div>
                                            <div className="col-sm-7 pt-4">{this.state.currentEmployee.pea_bill_rate}</div>
                                        </div>
                                        <div className="customer-info-block row">
                                            <div className="col-sm-5 pt-4 text-muted">CURRENCY</div>
                                            <div className="col-sm-7 pt-4">{this.state.currentEmployee.pea_currency}</div>
                                        </div>
                                        <div className="customer-info-block row">
                                            <div className="col-sm-5 pt-4 text-muted">FREQUENCY</div>
                                            <div className="col-sm-7 pt-4">{this.state.currentEmployee.pea_frequency}</div>
                                        </div>
                                        <div className="customer-info-block row">
                                            <div className="col-sm-5 pt-4 text-muted">ASSOCIATE FROM</div>
                                            <div className="col-sm-7 pt-4">{moment(this.state.currentEmployee.pea_associate_from).format('MM/DD/YYYY')}</div>
                                        </div>
                                        <div className="customer-info-block row">
                                            <div className="col-sm-5 pt-4 text-muted">ASSOCIATE TO</div>
                                            <div className="col-sm-7 pt-4">{moment(this.state.currentEmployee.pea_associate_to).format('MM/DD/YYYY')}</div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button className="w-100" variant="primary" onClick={this.handleClose}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        }
                    </div>
                    <div className="dropdown m-1">
                        <button style={{ outline: 'none', color: '#1976D2', border: '1px solid #1976D2', height: '38px' }} className="btn btn-sm dropdown-toggle bg-white" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span><i style={{ color: '#1976D2' }} className="fas fa-sort-amount-up"></i></span> SORT BY
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <button className="dropdown-item" type="button">Latest</button>
                            <button className="dropdown-item" type="button">Oldest</button>
                        </div>
                    </div>
                </div>
                <div className='people_team_info'>
                    <div>
                        <h5>Team Contacts</h5>
                        {this.showAlert()}
                    </div>
                    {!this.state.loading && this.state.content?.length === 0 && <div className='d-flex flex-column justify-content-center align-items-center mt-4' style={{ height: '300px', margin: 5 }}>
                        <img src='./line.png' alt='conttAB' style={{ width: '10rem', height: '10rem' }} />
                        <h5 style={{ fontFamily: 'Lato', fontSize: 18, marginTop: 38, marginBottom: 28 }} >No contacts found. </h5>
                        <p style={{ fontFamily: 'Lato', fontSize: 14 }} className='text-muted text-center w-50'>We could not find any contacts in this projects. Please add contacts to your project to view others involved in the projects</p>
                    </div>}
                    {!!this.state.loading &&
                        <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    <div style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
                        {this.state.content}
                    </div>
                </div>
                <br></br>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="TopPanel">
                        <div style={{ marginRight: '10px' }}>
                            <Autocomplete
                                id="contact-select-box"
                                sx={{ width: "300px" }}
                                options={contactList}
                                value={this.state.conValue}
                                onChange={(e, v) => this.onConSelect(v)}
                                getOptionLabel={(option) => {
                                    let con = this.state.contactsList.find((con) => (con.id === option))
                                    return `${con?.contact_name}`
                                }}
                                renderInput={(params) => <TextField {...params} label="Select Contact" variant="outlined" size="small" />}
                                renderOption={(props, option) => { return (<li {...props} key={option.id}>{this.state.contactsList.find((con) => (con.id === option)).contact_name}</li>) }}
                            />    
                        </div>
                        <div>
                            <Button onClick={() => this.onAddContact()}>
                                + Add Contact
                            </Button>
                        </div>
                    </div>
                    <div className="dropdown m-1">
                        <button style={{ outline: 'none', color: '#1976D2', border: '1px solid #1976D2', height: '38px' }} className="btn btn-sm dropdown-toggle bg-white" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span><i style={{ color: '#1976D2' }} className="fas fa-sort-amount-up"></i></span> SORT BY
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                            <button className="dropdown-item" type="button">Latest</button>
                            <button className="dropdown-item" type="button">Oldest</button>
                        </div>
                    </div>
                </div>
                <div className='people_cus_info'>
                    <div>
                        <h5>Customer Contacts</h5>
                        {this.showCusAlert()}
                    </div>
                    <div style={{ display: 'flex'}}>
                        <p style={{ width: '18%' }}>NAME</p>
                        <p style={{ width: '18%' }}>RELATIONSHIP</p>
                        <p style={{ width: '14%' }}>PHONE NUMBER</p>
                        <p style={{ width: '50%' }}>ADDRESS</p>
                    </div>
                    {!this.state.loading && this.state.contactContent?.length === 0 && <div className='d-flex flex-column justify-content-center align-items-center mt-4' style={{ height: '300px', margin: 5 }}>
                        <img src='./line.png' alt='conttAB' style={{ width: '10rem', height: '10rem' }} />
                        <h5 style={{ fontFamily: 'Lato', fontSize: 18, marginTop: 38, marginBottom: 28 }} >No contacts found. </h5>
                        <p style={{ fontFamily: 'Lato', fontSize: 14 }} className='text-muted text-center w-50'>We could not find any contacts in this projects. Please add contacts to your project to view others involved in the projects</p>
                    </div>}
                    {!!this.state.loading &&
                        <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    <div style={{overflow: 'auto'}}>
                        {this.state.contactContent}
                    </div>
                </div>
            </div>
        )
        return (
            <div style={{maxWidth:'70vw'}} >
                {content}
            </div>
        )
    }
}

export default People;