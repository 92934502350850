import React,{Component} from 'react';
import HeaderInfo from './HeaderInfo'
import HomeLine from './HomeLine'
import HomeCanvas from './HomeCanvas'
import axios from 'axios'
import {APIURL} from '../../config'
// import Home from './Home'
import ProjectTaskAnalytics from './ProjectTaskAnalytics'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })


class HomeBox extends Component{
    state = {loggedToSegment:false,}
    logToSegment = async () => {
        if(this.state.loggedToSegment) {
          return;
        }
        let page = String(window.location.href).split("/").pop()
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page,this.props.user.emp_id)
        analytics.page(page + " : Dashboard", {
            title:page + " : Dashboard",
            path:page + " : Dashboard",
        }, {
            userId:String(this.props.user.emp_id),
        })
        console.log("logged to segment successfully")
        this.setState({ loggedToSegment : true });
    }
    async componentDidMount(){
        let res = await axios.get(APIURL+'/home/empDashBoardv2', {params: {comp_id: this.props.comp_id}})
        this.logToSegment();
        this.setState({...res.data})
        this.props.checkTokenExpiry()
    }
    render(){
        console.log(this.state)
        return(
            <div>
                <HeaderInfo data={this.state.headerInfo}/>
                <div className="d-flex flex-lg-row flex-md-row flex-sm-column row">
                    <div 
                    // style={{height: '40rem'}} 
                    className="col-lg-8 col-md-12 col-sm-12 mb-1">
                        <HomeCanvas token={this.props.token} data={this.state.barInfo} comp_id = {this.props.comp_id}/>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 d-flex flex-lg-row flex-md-row flex-sm-row row">
                        <div className='col-lg-12 col-md-6 col-sm-6'><ProjectTaskAnalytics token={this.props.token}  comp_id = {this.props.comp_id} /></div>
                        <div className="mt-2 col-lg-12 col-md-6 col-sm-6"><HomeLine token={this.props.token} data={this.state.lineInfo}  comp_id = {this.props.comp_id}/></div>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default HomeBox