import React, { useState, useEffect } from "react";
import {
	Grid,
	Typography,
	Box,
	FormControl,
	InputLabel,
	Select,
	IconButton,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
} from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import CloseIcon from '@mui/icons-material/Close';
import MoveTo from "./Modals/MoveTo";
import Delete from "./Modals/Delete";
import CreateFolder from "./Modals/CreateFolder";
import UploadFile from "./Modals/UploadFile";
import DocumentsTable from "./DocumentsTable";
import PreviewDetails from "./PreviewDetails";
import SearchBar from "./Filters/SearchBar";
import axios from "axios";
import { APIURL } from "../../config";
import moment from "moment";
import ClientFilter from "./Filters/ClientFilter";
import { set } from "date-fns";

const AllDocuments = (props) => {
	const [rows, setRows] = useState([]);
	const [show, setShow] = useState(false);
	const [modal, setModal] = useState(null);
	const [modalData, setModalData] = useState(null);
	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useState(false);
	const [previewData, setPreviewData] = useState(null);

	const [rootDirectory, setRootDirectory] = useState(null);
	const [largestId, setLargestId] = useState(0);
	const [filterModel, setFilterModel] = useState({ items: [], logicOperator: "and"});
	const [isExpanded, setIsExpanded] = useState(false);

	const apiRef = useGridApiRef();

	const handlePreviewOpen = (data) => {
		setPreviewData(data);
		setOpen(true);
	};

	const handlePreviewClose = () => {
		setPreviewData(null);
		setOpen(false);
	};
	
	const handleModalOpen = (modal, data=null) => {
		setShow(true);
		setModal(modal);
		setModalData(data);
	};
	const handleModalClose = () => {
		setShow(false);
		setTimeout(() => {
			setModal(null);
			setModalData(null);
		}, 500);
	};

	useEffect(() => {
        let data = [...props.data];
		let gapRows = new Map();

		data.forEach((item, index) => {
			if(item.file_name !== null) {
				let newItem = {
					...item,
					file_name: null,
					emp_name: null,
					cus_name: null,
					upload_date: null,
					file_id: null,
					file_url: null,
					file_ext: null,
					created_by: null,
				};
				gapRows.set(item.directory_id, newItem);
			}
		});

		gapRows.forEach((value, key) => {
			if(!data.find((item) => item.file_name === null && item.directory_id === key))
				data.push(value);
		});

        let rows = data.map((item, index) => {
			let pathArray = item.file_path.split('/');
				pathArray.splice(0, 1); // remove root directory
				if(item.file_name) {
					pathArray.push(item.file_name + '.' + item.file_url?.split('.').pop());
				}
			return {
				file_path: pathArray,
				file_name: item.file_name,
				file_id: item.file_id,
				file_url: item.file_url,
				file_ext: item.file_url?.split('.').pop() || null,
				directory_id: item.directory_id,
				directory_name: item.directory_name,
				id: index,
				client: item.cus_name,
				owner: item.emp_name,
				extID: item.extID,
				upload_date: item.upload_date,
				directory_added_date: item.directory_added_date,
				locked: item.locked,
				created_by: item.file_name ? item.uploaded_by : item.created_by,
			}
    	});
		let rootDirectory = rows.filter((item) => item.file_path.length === 0)[0];
		console.log(rows.filter((item) => item.file_path.length === 1));
		setRootDirectory(rootDirectory)
		rows = rows.filter((item) => item.file_path.length > 0);
		if(props.user.type !== "Admin") {
			rows = rows.filter((item) => item.file_path[0] !== "Secure");
		}
		rows.sort((a, b) => a.file_name ? 1 : -1);
		rows = rows.filter((item) => !(!(item.file_path[0] === "Secure" || item.file_path[0] === "Public" || item.file_path[0] === "Artifacts") && item.created_by !== props.user.emp_id));
		setRows(rows);
		setLargestId(rows.length > 0 ? rows.length + 1 : 0);
		console.log(rows);
    }, [props.data]);

	useEffect(() => {
		if (apiRef.current) {
			rows.forEach((item) => {
				apiRef.current.setRowChildrenExpansion(item.id, isExpanded);
			});
		}
	}, [isExpanded]);

	const onMove = async (type, fromRow, toRow=rootDirectory) => {
		setLoading(true);
		if(toRow === null) {
			toRow = rootDirectory;
		}
		if (type === "file") {
			let updateFileDirectory = await axios.put(`${APIURL}/artifacts/${fromRow.file_id}/directory`, { directory_id: toRow.directory_id });
			if(updateFileDirectory.data?.code === 200) {
				let newRows = [...rows];
				newRows.forEach((item, index) => {
					if(item.file_id === fromRow.file_id) {
						newRows[index] = {
							...toRow,
							...fromRow,
							file_path: toRow.file_path.concat(fromRow.file_name)
						}
					}
				});
				setRows(newRows);
				setLoading(false);
				handleModalClose();
			}
		} else {
			let updateDirectory = await axios.put(`${APIURL}/directories/${fromRow.directory_id}`,
			    { 
					parent_id: toRow.directory_id,
					name: fromRow.directory_name,
					extID: fromRow.extID,
				}
			);
			if(updateDirectory.data?.code === 200) {
				await props.getData();
				setLoading(false);
				handleModalClose();
			}
		}
	};

	const onDelete = async (type, row) => {
		setLoading(true);
		if (type === "file") {
			let deleteFile = await axios.delete(APIURL+'/artifacts', { data: { id: row.file_id } })
			if(deleteFile.data?.code === 200) {
				let newRows = rows.filter((item) => item.file_id !== row.file_id);
				setRows(newRows);
				setLoading(false);
				handleModalClose();
			}
		} else {
			let deleteDirectory = await axios.delete(`${APIURL}/directories/${row.directory_id}`)
			if(deleteDirectory.data?.code === 200) {
				let newRows = rows.filter((item) => item.directory_id !== row.directory_id);
				setRows(newRows);
				setLoading(false);
				handleModalClose();
			}
		}
	}

	const createFolder = async (name, parentRow) => {
		setLoading(true);
		if(parentRow === null) {
			parentRow = rootDirectory;
		}
		let createDirectory = await axios.post(`${APIURL}/directories`, {
			name: name,
			parent_id: parentRow.directory_id,
			extID: props.comp_id,
			created_by: props.user.emp_id,
		})
		if(createDirectory.data?.code === 200) {
			let newRows = [...rows];
			newRows.push({
				...parentRow,
				directory_id: createDirectory.data?.id,
				directory_name: name,
				file_path: parentRow.file_path.concat(name),
				extID: props.comp_id,
				id: largestId + 1,
				locked: false,
				created_by: props.user.emp_id,
			});
			setLargestId(largestId + 1);
			setRows(newRows);
			console.log(newRows);
			setLoading(false);
			handleModalClose();
		}
	}

	const onUpload = async (file, parentRow) => {
		setLoading(true);
		if(parentRow === null) {
			parentRow = rootDirectory;
		}
		let formData = new FormData();
		formData.append('file_name', file.name.replace(/(\.[^/.]+)+$/, ""));
		formData.append('directory_id', parentRow.directory_id);
		formData.append('extID', props.comp_id);
		formData.append("cus_id", null);
		formData.append("proj_id", null);
		formData.append("task_id", null);
		formData.append("emp_id", props.user.emp_id);
		formData.append("snapshot", file, file.name);
		let uploadFile = await axios.post(APIURL+"/artifacts", formData, { headers: { "content-type": "multipart/form-data" }});
		if(uploadFile.data?.code === 200) {
			let newRows = [...rows];
			let fileName = file.name.replace(/(\.[^/.]+)+$/, "");
			newRows.push({
				...parentRow,
				file_id: uploadFile.data?.id,
				file_name: fileName,
				file_path: parentRow.file_path.concat(fileName),
				file_url: uploadFile.data?.file_url,
				file_ext: file.name.split('.').pop(),
				upload_date: moment().format("YYYY-MM-DD HH:mm:ss"),
				extID: props.comp_id,
				id: largestId + 1,
			});
			setLargestId(largestId + 1);
			setRows(newRows);
			setLoading(false);
			handleModalClose();
		}
	}

 	const selectModal = (modal) => {
		switch (modal) {
			case "move":
				return <MoveTo rows={rows} fromFileDirectory={modalData} onMove={onMove} handleModalClose={handleModalClose} loading={loading}/>;
			case "delete":
				return <Delete data={modalData} onDelete={onDelete} handleModalClose={handleModalClose} loading={loading}/>;
			case "createFolder":
				return <CreateFolder rows={rows} rootDirectory={rootDirectory} createFolder={createFolder} handleModalClose={handleModalClose} loading={loading}/>;
			case "upload":
				return <UploadFile rows={rows} rootDirectory={rootDirectory} onUpload={onUpload} handleModalClose={handleModalClose} loading={loading}/>;
				default:
				return null;
		}
	};

	return (
		<Box className="m-2" sx={{ width: "100%" }}>
			<Grid container className="mb-4" spacing={2}>
				<Grid item xs={12} sm={12} lg={4}>
					<SearchBar 
						rows={rows} 
						setFilterModel={setFilterModel}
						setIsExpanded={setIsExpanded}
					/>
				</Grid>
				<Grid item xs={6} sm={3} lg={2}>
					<ClientFilter 
						rows={rows}
						setFilterModel={setFilterModel}
					/>
				</Grid>
				<Grid item xs={6} sm={3} lg={2}>
					<FormControl fullWidth size="small" sx={{ minWidth: "100px", width: "100%" }}>
						<InputLabel id="document-center-type-filter-label">Type</InputLabel>
						<Select
							labelId="document-center-type-filter-label"
							label="Type"
							variant="outlined"
						>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} lg={4}>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end', width: "100%" }}>
						<Button variant="contained" sx={{ height: "40px" }} onClick={() => handleModalOpen("createFolder")}>
							Create Folder
						</Button>
						<Button variant="contained" sx={{ height: "40px", marginLeft: 1 }} onClick={() => handleModalOpen("upload")}>
							Upload File
						</Button>
					</Box>
				</Grid>
			</Grid>
			<Grid container>
				<Grid item xs={12}>
					<DocumentsTable
						rows={rows}
						handleModalOpen={handleModalOpen}
						handlePreviewOpen={handlePreviewOpen}
						filterModel={filterModel}
						setFilterModel={setFilterModel}
						apiRef={apiRef}
						user={props.user}
					/>
				</Grid>
			</Grid>
			<PreviewDetails
				open={open}
				handlePreviewClose={handlePreviewClose}
				data={previewData}
			/>
			<Dialog open={show} onClose={handleModalClose}>
				<DialogActions>
					<IconButton
						aria-label="close"
						color="inherit"
						sx={{ p: 0.25 }}
						onClick={handleModalClose}
						>
							<CloseIcon />
					</IconButton>
				</DialogActions>
				<DialogContent sx={{ paddingTop: '0px', width: 370 }}>
					{
						selectModal(modal)
					}
				</DialogContent>
			</Dialog>
		</Box>
  	);
};

export default AllDocuments;
