import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const Delete = (props) => {
    return (
        <Box>
            <h4 variant="h4">Delete</h4>
            <Box>
                <Typography>Are you sure you want to delete "{props.data?.file_name || props.data?.directory_name}"</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant="contained" sx={{ marginTop: "20px", marginRight: "10px" }} onClick={() => props.handleModalClose()} color="secondary">Cancel</Button>
                    <LoadingButton 
                        variant="contained" 
                        sx={{ marginTop: "20px" }} 
                        onClick={() => props.onDelete(props.data?.file_name === null ? "folder" : "file", props?.data)} 
                        color="primary"
                        loading={props.loading}
                    >
                        <span>Delete</span>
                    </LoadingButton>
                </Box>
        </Box>
    );
};

export default Delete;