import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import FileFolderItem from '../FileFolderItem';
import InputAdornment from '@mui/material/InputAdornment';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';

const CreateFolder = (props) => {
    const [folderName, setFolderName] = useState('Untitled Folder');
    const [parentFolder, setParentFolder] = useState(null);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');

    const doesDirectoryNameExistInParent = (directoryName, parentDirectory) => {
        let directories = props.rows.filter(row => row.file_name === null);
        let parent = parentDirectory ? parentDirectory : props.rootDirectory;
        let filePath = [...parent.file_path];
        filePath.push(directoryName);
        filePath = filePath.join('/');
        let directory = directories.find(directory => directory.file_path.join('/') === filePath);
        return directory ? true : false;
    }
        
    const onSubmit = () => {
        if (folderName.trim() === '') {
            setError(true);
            setErrorText('Folder name cannot be empty');
        } else if (folderName.includes('/')) {
            setError(true);
            setErrorText('Folder name cannot contain /');
        } else if (doesDirectoryNameExistInParent(folderName, parentFolder)) {
            setError(true);
            setErrorText('Folder name already exists');
        } else {
            setError(false);
            props.createFolder(folderName, parentFolder);
        }
    }

    return (
        <Box>
            <h4>Create Folder</h4>
            <br></br>
            <Box>
                <TextField
                    sx={{ marginBottom: '20px', width: '100%' }}
                    label="Folder Name"
                    variant="outlined"
                    value={folderName}
                    onChange={(e) => {setFolderName(e.target.value);setError(false)}}
                    error={error}
                    helperText={error ? errorText : ''}
                />
                <AutoComplete
                    sx={{ marginBottom: '20px', width: '100%' }}
                    value={parentFolder}
                    onChange={(e, value) => {setParentFolder(value);setError(false)}}
                    options={props.rows?.filter(row => row.file_name === null && row.locked !== true)}
                    renderInput={(params) => <TextField
                        {...params} 
                        label="File/Folder" 
                        error={error}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: parentFolder ?
                                <>
                                    {
                                        parentFolder.file_name === null ? <InputAdornment position="start"><FolderIcon sx={{ color: "#007bff" }} /></InputAdornment>
                                        : <InputAdornment position="start"><InsertDriveFileOutlinedIcon /></InputAdornment>
                                    }
                                    {params.InputProps.startAdornment}
                                </> : null
                        }}
                    />}
                    getOptionLabel={(option) => option.file_path[option.file_path.length - 1]}
                    renderOption={(props, option) => <FileFolderItem listItemProps={props} row={option} />}
                /> 
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant="contained" sx={{ marginTop: "20px", marginRight: "10px" }} onClick={() => props.handleModalClose()} color="secondary">Cancel</Button>
                    <LoadingButton 
                        variant="contained" 
                        sx={{ marginTop: "20px" }} 
                        onClick={onSubmit} 
                        color="primary"
                        loading={props.loading}
                    >
                        <span>Create</span>
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}

export default CreateFolder;