import React, { useState, useEffect } from "react"
import axios from 'axios'
import { APIURL } from '../../../config';
import Record from './Records/Records';
import moment from 'moment'
import { TextField, Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './Logs.css'
import MessagePage from '../../MessagePages/MessagePage'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })


export default function Logs(props) {

    const [evvReports, setEvvReports] = useState([]);
    const [evvTasks, setEvvTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState();
    const [currentDay, setCurrentDay] = useState(moment().startOf('day'));
    const [currentDayEnd, setCurrentDayEnd] = useState(moment().endOf('day'));

    const [loggedToSegment, setLoggedToSegment] = useState(false);

    const logToSegment = () => {
        if(loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        console.log(page,window.location.href)
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page)
        analytics.page(page.toUpperCase() + " : Activity",{
            title:page.toUpperCase() + " : Activity",
            path:page.toUpperCase() + " : Activity",
        }, {
          userId:String(props.user.emp_id),
        })
        console.log("logged to segment successfully")
        setLoggedToSegment(true);
    }

    useEffect(() => {
        async function fetchData() {
            let evvReport = await axios.get(APIURL + '/evv_AZ', { params: { comp_id: props.comp_id, start: new Date(currentDay), end: new Date(currentDayEnd) } })
            if (!!evvReport) {
                if (!!evvReport.data) {
                    let newEVVReports = evvReport.data.processes.slice();
                    newEVVReports.sort((a, b) => parseInt(moment(b.timestamp).format('YYYYMMDDHHmm')) - parseInt(moment(a.timestamp).format('YYYYMMDDHHmm')))
                    setEvvReports(newEVVReports);
                    setEvvTasks(evvReport.data.tasks.slice());
                    setIsLoading(false);
                }
            }
        }
        logToSegment();
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            let evvReport = await axios.get(APIURL + '/evv_AZ', { params: { comp_id: props.comp_id, start: new Date(currentDay), end: new Date(currentDayEnd) } })
            if (!!evvReport) {
                if (!!evvReport.data) {
                    let newEVVReports = evvReport.data.processes.slice();
                    newEVVReports.sort((a, b) => parseInt(moment(b.timestamp).format('YYYYMMDDHHmm')) - parseInt(moment(a.timestamp).format('YYYYMMDDHHmm')))
                    setEvvReports(newEVVReports);
                    setEvvTasks(evvReport.data.tasks.slice());
                    setIsLoading(false);
                }
            }
        }

        fetchData();
    }, [currentDay]);

    async function updateSelected(id) {
        if (selected === id) {
            this.setState({ selected: null })
        } else {
            // set state trigger the render
            this.setState({ selected: id })
        }
    }

    const getRecordComponents = () => {
        const groupedData = evvReports.reduce((groups, item) => {
            const { processID } = item;
            if (!groups[processID]) {
                groups[processID] = [];
            }
            groups[processID].push(item);
            return groups;
        }, {});

        let final = [], customers = [], employees = []
        for (const processID in groupedData) {
            let objects = groupedData[processID]

            if (objects.length !== 3) {
                continue;
            }

            let cus_total_records_sent = 0, cus_succeededCount = 0, cus_failedCount = 0;
            let emp_total_records_sent = 0, emp_succeededCount = 0, emp_failedCount = 0;
            let visit_total_records_sent = 0, visit_succeededCount = 0, visit_failedCount = 0;
            try {
                let cusData = [], empData = [];
                objects.forEach(obj => {
                    if (obj.payloadType === 'CUSTOMER') {
                        cus_total_records_sent = obj.total_records_sent;
                        cus_succeededCount = obj.succeededCount;
                        cus_failedCount = obj.failedCount;
                        if (obj.persons) {
                            obj.persons.forEach(person => {
                                cusData.push(JSON.parse(person.custPayload))
                            })
                            customers.push(...cusData)
                        }
                    }
                    if (obj.payloadType === 'EMPLOYEE') {
                        emp_total_records_sent = obj.total_records_sent;
                        emp_succeededCount = obj.succeededCount;
                        emp_failedCount = obj.failedCount;
                        if (obj.persons) {
                            obj.persons.forEach(person => {
                                empData.push(JSON.parse(person.empPayload))
                            })
                            employees.push(...empData)
                        }
                    }
                    if (obj.payloadType === 'VISIT') {
                        visit_total_records_sent = obj.total_records_sent;
                        visit_succeededCount = obj.succeededCount;
                        visit_failedCount = obj.failedCount;
                    }
                })
            }
            catch (err) {
                console.log(err, processID)
            }

            let data = {
                timestamp: objects[0].timestamp,
                processID: processID,
                evvLogList: (objects[0].evvLogList ? objects[0].evvLogList : []).concat(objects[1].evvLogList ? objects[1].evvLogList : [], objects[2].evvLogList ? objects[2].evvLogList : []),
                cus_total_records_sent: cus_total_records_sent,
                cus_failedCount: cus_failedCount,
                cus_succeededCount: cus_succeededCount,
                emp_total_records_sent: emp_total_records_sent,
                emp_failedCount: emp_failedCount,
                emp_succeededCount: emp_succeededCount,
                visit_total_records_sent: visit_total_records_sent,
                visit_failedCount: visit_failedCount,
                visit_succeededCount: visit_succeededCount,
                total_records_sent: cus_total_records_sent + emp_total_records_sent + visit_total_records_sent,
                succeededCount: cus_succeededCount + emp_succeededCount + visit_succeededCount,
                failedCount: cus_failedCount + emp_failedCount + visit_failedCount
            }
            final.push(data)
        }

        let list = final.map((evv) => {
            if (evv) {
                let tasks = [];
                if (!!evv.evvLogList) {
                    for (let i = 0; i < evv.evvLogList.length; i++) {
                        if (!!evv.evvLogList[i].visitID) {
                            tasks.push(evvTasks.find(t => t.tas_id.toString() === evv.evvLogList[i].visitID))
                        }
                    }
                }

                return (
                    <Record updateSelected={updateSelected} isSelected={selected === evv.processID} key={evv.processID} list={evv} tasks={tasks} customers={customers} employees={employees} />
                )
            }
        })

        list = list.filter(report => report !== undefined)
        if (list.length === 0) {
            return <MessagePage title="No Logs found" text={"We could not find any EVV Logs for " + currentDay.format("MMMM DD, YYYY") + "."} image="./nosearchresult.png"></MessagePage>
        }

        return list;
    }

    const onDateChange = (newValue) => {
        console.log("start DATE CHANGE")
        setCurrentDay(moment(newValue).startOf('day'));
        setCurrentDayEnd(moment(newValue).endOf('day'));
        console.log("end DATE CHANGE")
        setIsLoading(true)
    }

    return (
        <div id='evvLogs-main' style={{ height: '60rem' }}>

            {isLoading && <div className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div></div>}

            {!isLoading &&
                <div style={{ display: 'flex', width: '700px' }}>
                    <h4 style={{ marginTop: '1rem' }}> EVV Activity</h4>
                    <div style={{ height: '40px', marginTop: '5px', marginBottom: '20px', marginLeft: '20px' }}>
                        <Button size="large" className='DayButton' onClick={() => onDateChange(currentDay.subtract(24, "h"))}><i className="fas fa-arrow-left"></i></Button>
                        <LocalizationProvider class='DatePickerParent' dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Select Date"
                                value={currentDay}
                                onChange={(newValue) => {
                                    onDateChange(newValue)
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="standard"
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        <Button size="large" className='DayButton' onClick={() => onDateChange(currentDay.add(24, "h"))}><i className="fas fa-arrow-right"></i></Button>
                    </div>
                </div>
            }

            {!isLoading &&
                <div id='evvLogs-body' className='overflow-auto' style={{ height: '85vh' }}>
                    {evvReports.length === 0 && <MessagePage title="No Logs Found" text="We could not find any EVV Logs were found for this company." image="./nosearchresult.png"></MessagePage>}
                    {evvReports.length !== 0 && getRecordComponents()}
                </div>
            }
        </div>
    );

}