export default {
    "section_1": {
        "a": {
            "question": "Individual's Name:",
            "answer": ""
        },
        "b": {
            "question": "DDD ID #:",
            "answer": ""
        },
        "c": {
            "question": "NJCAT Score:",
            "c1": {
                "question": "Self-Care",
                "answer": ""
            },
            "c2": {
                "question": "Behavorial",
                "answer": ""
            },
            "c3": {
                "question": "Medical",
                "answer": ""
            }
        },
        "d": {
            "question": "Tier:",
            "answer": ""
        },
        "e": {
            "question": "Current Program:",
            "answer": ""
        },
        "f": {
            "question": "Current Living Arrangement:",
            "answer": "",
            "options": [{ "label": "Own Home", "value": "own_home" }, { "label": "Provider Managed Residence", "value": "provider_managed_residence" }, { "label": "Other", "value": "other" }]
        },
        "g": {
            "question": "If Other, please describe:",
            "answer": ""
        },
        "h": {
            "question": "Is an Approved ISP in Place?",
            "answer": ""
        },
        "i": {
            "question": "Name of Support Coordination Agency:",
            "answer": ""
        },
        "j": {
            "question": "Name of Assigned Support Coordinator:",
            "answer": ""
        },
        "k": {
            "question": "Contact Date:",
            "answer": ""
        },
        "l": {
            "question": "Contact Location:",
            "answer": "",
            "options": [{ "label": "Home", "value": "home" }, { "label": "Day Hab Site", "value": "day_hab_site" }, { "label": "Job Site", "value": "job_site" }, { "label": "Other", "value": "other" }]            
        },
        "m": {
            "question": "Contact Type:",
            "answer": "",
            "options": [{ "label": "Face-to-Face", "value": "face_to_face" }, { "label": "Phone/Video Contact", "value": "phone/video_contact" }, { "label": "Home Visit", "value": "home_visit" }]
        },
        "n": {
            "question": "Contact Period:",
            "answer": ""
        },
        "o": {
            "question": "If Other, please describe:",
            "answer": ""
        },
        "p": {
            "question": "If Phone, enter the phone #:",
            "answer": ""
        },
        "q": {
            "question": "Who is the primary contact for this Quarterly/Annual Face-to-Face Visit?",
            "answer": ""
        },
        "r": {
            "question": "Relationship to the Individual",
            "answer": "",
            "options": [{ "label": "Self", "value": "self" }, { "label": "Parent/and or Guardian", "value": "parent/and_or_guardian" }, { "label": "Residential Provider Staff", "value": "residential_provider_staff" }, { "label": "Day Program Provider Staff", "value": "day_program_provider_staff" }, { "label": "Other Provider Staff", "value": "other_provider_staff" }, { "label": "Other", "value": "other" }]
        },
        "s": {
            "question": "If the Individual is not the primary contact, were they involved in the conversation?",
            "answer": ""
        },
        "t": {
            "question": "Please describe or explain:",
            "answer": ""
        },
        "u": {
            "question": "Does the Individual currently attend a DDD funded provider managed day program?",
            "answer": ""
        },
        "v": {
            "question": "If Yes, please enter name of agency and address of program:",
            "v1": {
                "question": "Agency Name",
                "answer": ""
            },
            "v2": {
                "question": "Address",
                "answer": ""
            }
        },
        "w": {
            "question": "Does the Individual currently reside in a DDD funded provider managed residential setting?",
            "answer": ""
        },
        "x": {
            "question": "If Yes, please enter name of agency and address of program:",
            "x1": {
                "question": "Agency Name",
                "answer": ""
            },
            "x2": {
                "question": "Address",
                "answer": ""
            }
        },
        "y": {
            "question": "Does the Individual currently use Self-Directed Employees (SDEs)?",
            "answer": ""
        },
        "z": {
            "z1": {
                "question": "If Yes, how many active SDEs?",
                "answer": ""
            },
            "z2": {
                "question": "About how many hours/month?",
                "answer": ""
            }
        }
    },
    "section_2": [],
    "section_3": {
        "a": {
            "question": "a.	What have you been doing outside the home/in the community in the past few months? (For example: movies, shopping, going out to eat, etc.)",
            "answer": ""
        },
        "b": {
            "question": "b.	Do you like the things you are currently doing in the community?",
            "answer": ""
        },
        "c": {
            "question": "c.	Do you decide, or with necessary support decide, the community activities you do and with whom you do them (including people you do not live with)?",
            "answer": ""
        },
        "d": {
            "question": "d.	Are there things you are doing now that you would like to do more often, and/or things you are not doing now that would like to do?",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_4": {
        "a": {
            "question": "a.	How have you been connecting (For example: by phone, visits, outings in the community, etc.) with the people important to you?",
            "answer": ""
        },
        "b": {
            "question": "b.	Are you able to have visitors when you want?",
            "answer": ""
        },
        "c": {
            "question": "c.	When you have visitors, do you have privacy?",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_5": {
        "a": {
            "question": "a.	Do you have access to your own money, and are you able to spend it on things you like?",
            "answer": ""
        },
        "b": {
            "question": "b.	Do people respect your privacy in your bedroom and in the bathroom?",
            "answer": ""
        },
        "c": {
            "question": "c.	Do you feel respected by people who support you?",
            "answer": ""
        },
        "d": {
            "question": "d.	Do you feel safe at home, in your neighborhood, at work/day program, and during community activities?",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_6": {
        "a": {
            "question": "a.	Do you like where you live?",
            "answer": ""
        },
        "b": {
            "question": "b.	Can you have snacks/food when you like?",
            "answer": ""
        },
        "c": {
            "question": "c.	Is there anything happening now that might require a change with your housing? (For example: behind on rent, significant problems with housemates, desire to live in another setting/location, etc.)",
            "answer": ""
        },
        "d": {
            "question": "d.	Have there been any changes at home recently?  (For example: People you live with, new staff, problems with your home/apartment)",
            "answer": ""
        },
        "e": {
            "question": "e.	How do you feel about any changes that may have occurred at home in the last month?",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_7": {
        "a": {
            "question": "a.	How do you normally spend your weekdays? (For example: At a job, day program, retired, etc.)",
            "answer": ""
        },
        "b": {
            "question": "b.	Do you like what you do during the day?",
            "answer": ""
        },
        "c": {
            "question": "c.	Are you interested in changing what you do during the day, or do you want to attend/work for more, or less, hours? (For persons at retirement age, discuss retirement as well)",
            "answer": ""
        },
        "d": {
            "question": "d.	If you are not working now, are you interested in work?",
            "answer": ""
        },
        "e": {
            "question": "e.	If you are working now, would you like to explore ways to further career goals and/or work more hours?",
            "answer": ""
        },
        "f": {
            "f1": {
                "question": "f.	(For the SC) If the Individual is not working now, but is interested, has a referral to DVRS or CBVI been completed? (Update the Employment Pathway as needed)",
                "answer": ""
            },
            "f2": {
                "question": "If applicable, explain the referral status:",
                "answer": ""
            }         
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_8": {
        "a": {
            "question": "a.	(For the SC) Has a verbal review of outcomes and services been completed?",
            "answer": ""
        },
        "b": {
            "question": "b.	Are you receiving all services as entered in the ISP?",
            "answer": ""
        },
        "c": {
            "question": "c.	Do you feel your services are helping you make progress toward ISP outcomes?",
            "answer": ""
        },
        "d": {
            "question": "d.	Are there outcomes or services you would like to change, add, or remove from your ISP, including changes to any service providers you may use?",
            "answer": ""
        },
        "e": {
            "question": "e.	(To be answered by the SC): Will the current rate of budget expenditure allow services to continue as needed throughout the entire plan term?",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_9": {
        "q1": {
            "question": "Name of Service Provider:",
            "answer": ""
        },
        "q2": {
            "question": "Name and Title of Staff Member:",
            "q2a": {
                "question": "Name",
                "answer": ""
            },
            "q2b": {
                "question": "Title",
                "answer": ""
            }
        },
        "q3": {
            "question": "Program Name and Type:",
            "answer": ""
        },
        "a": {
            "question": "a.	Date of last annual physical:",
            "answer": ""
        },
        "b": {
            "question": "b.	Date of last dentist checkup:",
            "answer": ""
        },
        "c": {
            "question": "c.	How has the Individual been made aware of their rights in this program, including choice of roommate (If applicable)?",
            "answer": ""
        },
        "d": {
            "question": "d.	Are there any restrictions in this setting affecting the rights of this Individual not supported by a specific assessed need and justified in the ISP? (For example: Access to sharp objects, food, common areas of the home, computer/internet; alarms on doors/windows, privacy locks, etc.)",
            "answer": ""
        },
        "e": {
            "question": "e.	Per the ISP, is all doctor prescribed and/or behavioral data collection occurring?  (For example: BSP data, bowel movements, caloric intake, etc.)",
            "answer": ""
        },
        "f": {
            "question": "f.	Have there been recent changes to the supervision levels for this Individual?",
            "answer": ""
        },
        "g": {
            "question": "g.	Have there been changes to your health since last month, including medication changes? (Physical, emotional, behavioral, psychological, etc.)",
            "answer": ""
        },
        "h": {
            "question": "h.	Have you been hospitalized/visited an emergency room since last month?",
            "answer": ""
        },
        "i": {
            "question": "i.	Have you had medical/dental/specialist appointments since last month?",
            "answer": ""
        },
        "j": {
            "question": "j.	If yes, did the doctor/dentist/specialist make any follow up recommendations that are not yet completed and/or scheduled?",
            "answer": ""
        },
        "k": {
            "question": "k.	For Individuals residing in non-provider managed settings, has the importance of routine/preventative medical/dental/specialist appointments been discussed?",
            "answer": ""
        },
        "l": {
            "question": "l.	For Individuals residing in non-provider managed settings, is there an emergency plan if a caregiver/staff are unexpectedly unable to provide care?",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_10": {
        "a": {
            "question": "a.	Is the Individual Medicaid eligible? (Indicated by green litmus)",
            "answer": ""
        },
        "b": {
            "question": "b.	Is the Individual’s Medicaid scheduled to terminate? (Indicated by yellow litmus)",
            "answer": ""
        },
        "c": {
            "question": "c.	Is representative payee aware that financial changes could jeopardize Medicaid eligibility? (Ex. Retirement of parent, passing of parent, resources over $2,000, etc.)",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_11": {
        "a": {
            "question": "Is there anything else you would like me to know right now, or anything else you need assistance with?",
            "answer": ""
        }
    },
    "section_12": {
        "a": {
            "question": "a.	Are there behaviors or activities happening that planning team members would consider risky, and not adequately addressed in the Individual’s ISP? (For example: Aggression, elopement, drug/alcohol use, other…) ",
            "answer": ""
        },
        "b": {
            "question": "b.	Have any Incident Reports (IR) been completed/submitted since last month’s contact? (According to iRecord notes, email notification, conversations, etc.)",
            "answer": ""
        },
        "c": {
            "question": "c.	Has there been an increase or identified patterns in IRs in the past three months?",
            "answer": ""
        },
        "d": {
            "question": "d.	Based on information received during this contact, are there new incidents or allegations of abuse, neglect or exploitation, which require a new IR?",
            "answer": ""
        },
        "e": {
            "question": "e.	Is the Contacts list in iRecord up to date, including Emergency Contacts?",
            "answer": ""
        },
        "f": {
            "question": "f.	Does the Individual’s NJCAT tier include the acuity factor?",
            "answer": ""
        },
        "g": {
            "question": "g.	Acuity factor/enhanced needs present for:",
            "answer": ""
        },
        "h": {
            "question": "h.	Are all Addressing Enhanced Needs Forms up to date? (CBS, IS, Day Hab, Respite)",
            "answer": ""
        },
        "i": {
            "question": "i.	Does it appear all necessary enhanced supports are in place?",
            "answer": ""
        },
        "j": {
            "question": "j.	If the acuity factor is for Behavioral, is a Behavior Support Plan in place?",
            "answer": ""
        },
        "k": {
            "question": "k.	If yes, is the current Behavior Support Plan uploaded to iRecord?",
            "answer": ""
        },
        "l": {
            "question": "l.	Does the Behavior Support Plan appear to be meeting the Individual’s needs?",
            "answer": ""
        },
        "m": {
            "question": "m.	If for Medical, does the ISP state the diagnosis(es) and describe care needs?",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_13": {
        "a": {
            "question": "a.	Is this the fourth consecutive month (or more) for which you have not been able to complete a face-to-face visit?",
            "answer": ""
        },
        "b": {
            "question": "b.	Have risk factors been identified for which Division assistance is needed? (Ex: Significant changes in the Individual’s/caregiver’s support needs, behavioral/medical concerns, housing instability, long-term hospitalization/nursing home admission, etc.)",
            "answer": ""
        },
        "follow_up": {
            "question": "Comments / Follow Up Items",
            "answer": []
        }
    },
    "section_14": {
        "a": {
            "question": "Summarize the conversation describing highlights and points of interest or concern for the Individual since the last contact. Include observations about the environment and the Individual’s interactions not already in this document. ",
            "answer": ""
        }
    },
    "section_15": {
        "a": {
            "question": "Name:",
            "answer": ""
        },
        "b": {
            "question": "Date:",
            "answer": ""
        },
        "c": {
            "question": "Title:",
            "answer": ""
        },
        "d": {
            "question": "If completed by someone other than the assigned Support Coordinator, please explain:",
            "answer": ""
        }
    },
    "section_16": {
        "a": {
            "question": "Name of SC Supervisor:",
            "answer": ""
        },
        "b": {
            "question": "Review Date:",
            "answer": ""
        }
    }
}