import React, { useState, useEffect } from "react";
import { Typography, TextField, Button, Box, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Snackbar, IconButton, Alert } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from 'dayjs';
import ReportCard from "./ReportCard";
import ServiceAuth from "./ServiceAuth";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

var sha1 = require("sha1");

export default function Billing(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [billingFilter, setBillingFilter] = useState({
        dateRange: [
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
        ],
    });
    const [selectedScreen, setSelectedScreen] = useState(null);
    const [custName, setCustName] = useState([]);
    const [cus_DDDIDs, setCus_DDDIDs] = useState([]);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports")
            .then((response) => {
                setData(response?.data?.reports.filter(function (ele) { return ele.report_group == "BILLING"; }) || []);
            });

        axios
            .get(APIURL + "/customers/v2/associated", {
                params: {
                    company_id: props.user.comp_id,
                    employee_id: props.user.emp_id
                },
            })
            .then((response) => {
                setCustomerList(response?.data.filter((cus) => cus.cus_status === 'Active') || []);
            });

        setIsLoading(false);
    };

    // { "primaryBusinessType": "Support Coordination", "featureValue": 101 },
    // { "primaryBusinessType": "Service Provider", "featureValue": 102 },
    let pBusinessType = props.user?.comp_settings.find(setting => setting.feature_value === 'primaryBusinessType') ? props.user.comp_settings.filter((setting) => setting.feature_value === "primaryBusinessType")[0].feature_id : 101;

    let displayReports = data;
    if (pBusinessType === 101) {
        displayReports = data.filter((report) => report.report_name !== 'Invoice Report');
    }

    const getReport = async (data, type) => {
        setSelectedScreen(null);

        const report_name = data?.report_details?.report_name || data?.report_name;
        switch (report_name) {
            case "Invoice Report":
                getInvoiceReport(type);
                break;
            case "Billing Transaction Report":
                getBillingTrans(type);
                break;
            case "Claim Balance Report":
                getClaimBalance(type);
                break;
            case "Billing Outstanding Balance Report":
                getOutstandingBalance(type);
                break;
            case "Claim Collection Report":
                getClaimCollect(type);
                break;
            case "Prior Auth Utilization Report":
                getPA_Utilization(type)
                break;
            default:
                break;
        }
    }

    const getInvoiceReport = async (type) => {
        axios
            .get(APIURL + "/jsreport/invoice", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    start: moment(billingFilter?.dateRange[0]).format("YYYY-MM-DD"),
                    end: moment(billingFilter?.dateRange[1]).format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Invoice Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getBillingTrans = async (type) => {
        axios
            .get(APIURL + "/jsreport/billingTrans", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    month: moment().month() + 1,
                    cus_DDDIDs: cus_DDDIDs,
                    start: moment(billingFilter?.dateRange[0]).format("YYYY-MM-DD"),
                    end: moment(billingFilter?.dateRange[1]).format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Billing Transaction Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getClaimBalance = async (type) => {
        axios
            .get(APIURL + "/jsreport/claimBalance", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    cutoff: moment().startOf("month").format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Claim Balance Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getOutstandingBalance = async (type) => {
        axios
            .get(APIURL + "/jsreport/outstandingBalance", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    start: moment(billingFilter?.dateRange[0]).format("YYYY-MM-DD"),
                    end: moment(billingFilter?.dateRange[1]).format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Billing Outstanding Balance Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getClaimCollect = async (type) => {
        axios
            .get(APIURL + "/jsreport/claimCollect", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    start: moment(billingFilter?.dateRange[0]).format("YYYY-MM-DD"),
                    end: moment(billingFilter?.dateRange[1]).format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Claim Collection Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getPA_Utilization = async (type) => {
        axios
            .get(APIURL + "/jsreport/PA_Utilization", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    start: moment(billingFilter?.dateRange[0]).format("YYYY-MM-DD"),
                    end: moment(billingFilter?.dateRange[1]).format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "PA Utilization Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const openPDF = (response, reportAction = "open") => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        if (reportAction === "download") {
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = "Report-" + new Date() + ".pdf";
            link.click();
        } else {
            window.open(fileURL, "_blank");
        }
    };

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    const markAllAsFavorite = () => {
        const notFavorite = data.filter((report) => report.status == 0);
        notFavorite.forEach((report) => markFavourite(report, 1));
    }

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setCustName(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleMenuItemClick = (event) => {
        if (cus_DDDIDs.indexOf(event.target.id) === -1) {
            // event.target.id not found in DDDIDs, add it
            setCus_DDDIDs([...cus_DDDIDs, event.target.id]);
        } else {
            // event.target.id found in DDDIDs, remove it
            setCus_DDDIDs(cus_DDDIDs.filter((id) => id !== event.target.id));
        }
    }

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ margin: 10, width: '75vw' }}>
                <div>
                    <div>
                        <Typography
                            style={{
                                fontWeight: "bold",
                                fontSize: "1.5rem",
                                fontFamily: "Lato",
                                marginBottom: 30,
                            }}
                        >
                            Billing Reports
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex' }}>
                            <div>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    localeText={{ start: "From Date", end: "To Date" }}
                                >
                                    <DateRangePicker
                                        value={billingFilter.dateRange}
                                        onChange={(newValue) => {
                                            newValue = newValue.map((d) => dayjs(d).format('YYYY-MM-DD'))
                                            setBillingFilter({
                                                ...billingFilter,
                                                dateRange: newValue,
                                            });
                                        }}
                                        renderInput={(startProps, endProps) => (
                                            <React.Fragment>
                                                <TextField size="medium" {...startProps} />
                                                <Box sx={{ mx: 1 }}> to </Box>
                                                <TextField size="medium" {...endProps} />
                                            </React.Fragment>
                                        )}
                                    />
                                </LocalizationProvider>
                            </div>
                            <div>
                                <FormControl sx={{ marginLeft: 2, width: 300 }}>
                                    <InputLabel id="demo-multiple-name-label">Select Client</InputLabel>
                                    <Select
                                        labelId="select-client-label"
                                        id="select-client"
                                        multiple
                                        value={custName}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Select Client" />}
                                    >
                                        {customerList.map((cus) => (
                                            <MenuItem
                                                id={cus.DDDID}
                                                key={cus.cus_id}
                                                value={cus.cus_name}
                                                onClick={handleMenuItemClick}
                                            >
                                                {cus.cus_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div>
                            <Button variant="contained" onClick={markAllAsFavorite}>Mark all as "Favorite"</Button>
                        </div>
                    </div>
                </div>

                <div style={{ marginTop: 20, marginBottom: 10, width: '70vw' }}>
                    <div>
                        {displayReports.map((e) => (
                            e.report_type === 1 ?
                                <ReportCard
                                    data={e}
                                    openReport={(type, data) => getReport(type, data)}
                                    markFavourite={(type, data) => markFavourite(type, data)}
                                />
                                :
                                <ReportCard
                                    data={e}
                                    openReport={(e) => { setSelectedScreen(e.report_name) }}
                                    markFavourite={(type, data) => markFavourite(type, data)}
                                />
                        ))}
                    </div>
                </div>
                <div style={{ bottom: "10px", width: '70vw' }}>
                    <div>
                        {selectedScreen === "Service Authorization Report" ? <ServiceAuth {...props} billingFilter={billingFilter} screenName={'Service Authorization Report'} /> : null}
                    </div>
                </div>

                <Snackbar
                    open={successText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setSuccessText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"success"}
                        elevation={6}
                        variant="filled"
                    >
                        {successText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setSuccessText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={errorText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setErrorText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"error"}
                        elevation={6}
                        variant="filled"
                    >
                        {errorText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setErrorText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
            </div>
        )
    }
};