import React, { useMemo } from 'react';
import { Navigate } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

function WorkWeekWrapper(days) {
  function WorkWeek({
    date,
    localizer,
    max = localizer.endOf(new Date(), 'day'),
    min = localizer.startOf(new Date(), 'day'),
    scrollToTime = localizer.startOf(new Date(), 'day'),
    ...props
  }) {
    const currRange = useMemo(
      () => WorkWeek.range(date, { localizer, days }),
      [date, localizer, days]
    );

    return (
      <TimeGrid
        date={date}
        eventOffset={15}
        localizer={localizer}
        max={max}
        min={min}
        range={currRange}
        scrollToTime={scrollToTime}
        {...props}
      />
    );
  }

  WorkWeek.range = (date, { localizer, days }) => {
    const dayMap = {
      'Sunday': 0,
      'Monday': 1,
      'Tuesday': 2,
      'Wednesday': 3,
      'Thursday': 4,
      'Friday': 5,
      'Saturday': 6,
    };
  
    const sortedDays = days.sort((a, b) => dayMap[a] - dayMap[b]);
  
    let startOfWeek = localizer.startOf(date, 'week');
    const range = sortedDays
      .map(dayName => {
        const dayIndex = dayMap[dayName];
        let adjustedDate = localizer.add(startOfWeek, dayIndex, 'day');
        if (dayIndex < startOfWeek.getDay()) {
          adjustedDate = localizer.add(adjustedDate, 7, 'day'); // Move to the next week for days before the startOfWeek day
        }
        return adjustedDate;
      })
      .filter(dayDate => localizer.lte(startOfWeek, dayDate, 'day'));
  
    return range;
  };

  WorkWeek.navigate = (date, action, { localizer }) => {
    switch (action) {
      case Navigate.PREVIOUS:
        return localizer.add(date, -7, 'day');
      case Navigate.NEXT:
        return localizer.add(date, 7, 'day');
      default:
        return date;
    }
  };

  WorkWeek.title = (date, { localizer }) => {
    const range = WorkWeek.range(date, { localizer, days });
    const start = range[0];
    const end = range[range.length - 1];
    const startFormat = 'MMMM DD';
    if (localizer.format(start, 'MMMM') === localizer.format(end, 'MMMM')) {
      return `${localizer.format(start, startFormat)} - ${localizer.format(end, 'DD')}`;
    } else {
      return `${localizer.format(start, startFormat)} - ${localizer.format(end, startFormat)}`;
    }
  };

  return WorkWeek;
}

export const createWorkWeek = (days) => {
  return WorkWeekWrapper(days);
}
