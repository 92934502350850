import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import withStyles from "@mui/styles/withStyles";
import { APIURL } from "../../../config";
import TextField from "@mui/material/TextField";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Dropzone from "react-dropzone";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./UploadForm.css";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#3FA7D6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#3FA7D6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "lightblue",
      },
      "&:hover fieldset": {
        borderColor: "#3FA7D6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3FA7D6",
      },
    },
  },
})(TextField);

export default function UploadForm(props) {
  const [file, setFile] = useState(null);

  const [errorAlert, setErrorAlert] = useState("");
  const handleErrorAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorAlert("");
  };

  const [successAlert, setSuccessAlert] = useState("");
  const handleSuccessAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessAlert("");
  };

  const onFileUpload = async () => {
    if (file !== null) {
      const accessToken = window.localStorage.getItem("Fw4_access_Token");
      const Papa = require("papaparse");
      Papa.parse(file, {
        error: function (error, file) {
          console.log(error);
          setErrorAlert("Something went wrong! Please try again.");
        },
        complete: async function (results, file) {
          let nonEmpty = [];
          for (var i = 0; i < results.data.length; i++) {
            let fieldIsEmpty = false;
            let currRow = [];
            for (var j = 0; j < results.data[i].length; j++) {
              if (j != 13 && !results.data[i][j].trim()) {
                fieldIsEmpty = true;
                break;
              } else {
                currRow.push(results.data[i][j].trim());
              }
            }
            if (!fieldIsEmpty) {
              nonEmpty.push(currRow);
            }
          }
          const payload = { data: nonEmpty, accessToken };
          try {
            let result = await axios.post(APIURL + "/claimCSV", payload);
            console.log(result)
            console.log(props)
            if (!result.data.foundError && result.data.success) {
              let sessionID = window.localStorage.getItem("Fw4_access_Token");
              var sha1 = require("sha1");
              var hash = sha1(sessionID);
              var log_data = {
                email: props.user.email,
                actionType: "Claims Uploaded",
                actionSubType: `${props.user.company_name} uploaded new claims`,
                sessionID: hash,
              };
              console.log(log_data)
              let msg = await axios.post(APIURL + "/activity_log", log_data);
              console.log(msg);
              setSuccessAlert(result.data.message);
            }
            else {
              setErrorAlert(result.data.message)
            }
            setTimeout(() => {
              props.onSubmit();
            }, 2500);
          } catch (error) {
            setErrorAlert("Something went wrong! Please try again.");
            setTimeout(() => {}, 1000);
          }
        },
      });
    } else {
      setErrorAlert("Please select a file to upload!");
      setTimeout(() => {}, 2000);
    }
  };

  return (
    <div className="noteForm-mainDiv">
      <h4>Upload File</h4>
      <br></br>
      <div>
        <Dropzone
          onDrop={(acceptedFiles) => setFile(acceptedFiles[0])}
          accept={{
            "text/csv": [".csv"],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <Box className="upload-area" {...getRootProps()} display="flex">
              <Typography variant="subtitle1">
                {file ? file.name : "Drop file here or click to upload"}
              </Typography>
              <Typography variant="subtitle3">
                {file ? "" : "(file type: .csv)"}
              </Typography>
              <input type="file" {...getInputProps()} />
            </Box>
          )}
        </Dropzone>
        <Button
          style={{
            width: "100%",
            padding: "5px",
            margin: "5px",
          }}
          onClick={onFileUpload}
        >
          Upload File
        </Button>
      </div>
      <Snackbar
        open={successAlert != ""}
        autoHideDuration={2000}
        onClose={handleSuccessAlertClose}
      >
        <MuiAlert
          sx={{ width: "100%" }}
          severity={"success"}
          elevation={6}
          variant="filled"
          {...props}
        >
          {successAlert}
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.25 }}
            onClick={handleSuccessAlertClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiAlert>
      </Snackbar>
      <Snackbar
        open={errorAlert != ""}
        autoHideDuration={7000}
        onClose={handleErrorAlertClose}
      >
        <MuiAlert
          sx={{ width: "100%" }}
          severity={"error"}
          elevation={6}
          variant="filled"
          {...props}
        >
          {errorAlert}
          <IconButton
            aria-label="close"
            color="inherit"
            sx={{ p: 0.25 }}
            onClick={handleErrorAlertClose}
          >
            <CloseIcon />
          </IconButton>
        </MuiAlert>
      </Snackbar>
    </div>
  );
}
