import React,{Component} from 'react'
import Chart from 'chart.js';
// import axios from 'axios'

class HomeLine extends Component{
    chartRef = React.createRef();

    async componentDidUpdate(){
        try{const myChartRef = this.chartRef.current.getContext("2d");
        new Chart(myChartRef, {
            type: 'line',
            data: {
                
                datasets: [{
                    label: 'In-Home Contacts',
                    data: !!this.props.data?this.props.data:[10, 5, 30, 20, 10, 60, 12, 43, 21, 51, 11, 7],
                    backgroundColor: 'rgba(63, 167, 214, 0.2)',
                    borderColor: '#3FA7D6'
                }],
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June','July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
            },
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June','July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
                    }]
                }
            }
        });}catch(e){return }
    }

    render(){
        return(
            <div style={{border: '1px solid #3FA7D6', paddingTop: 10}} id="lineContainer" className="mt-0 text-center"> 
            Contacts Information
            <hr style={{background: '#3FA7D6'}} className="m-1"/>
            {(!!this.props.data && !this.props.data.some((num) => num !== 0)) && 
                <div className='d-flex flex-column justify-content-center align-items-center' style={{height: '300px',margin: 5}}>
                    <img src='./line.png' alt='linechart' style={{width: '10rem', height: '10rem'}}/>
                    <h5 style={{fontFamily: 'Lato',fontSize: 18,marginTop: 20,  marginBottom: 10}} >No home visits to show. </h5>
                    <p  style={{fontFamily: 'Lato',fontSize: 12}} className='text-muted'>You’ll see a summary of home visits made by your team here. Still need to invite your team?.</p>
                </div>
            }
            {!this.props.data && 
            <div style={{height: '300px', margin: 10}} className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
            </div></div>
            }
            {!!this.props.data && this.props.data.some((num) => num !== 0) && <canvas id="myChart" ref={this.chartRef} width="10rem" height="5rem"></canvas>}
            </div>
        )
    }
}

export default HomeLine