import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIURL } from '../../../../config'
import { Form, Button } from 'react-bootstrap';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function CustomerTasks(props) {
    const [typeList, setTypeList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [selectedTaskTypes, setSelectedTaskTypes] = useState([]);
    const [selectedDDIds, setSelectedDDIds] = useState([]);
    const [originalSelectedTaskTypes, setOriginalSelectedTaskTypes] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const handleCheckboxChange = (event, taskType) => {
        if (event.target.checked) {
            setSelectedDDIds((prevSelectedDDIds) => [...prevSelectedDDIds, taskType.ddID]);
            setSelectedTaskTypes((prevSelectedTaskTypes) => [...prevSelectedTaskTypes, taskType]);
        } else {
            setSelectedDDIds((prevSelectedDDIds) => prevSelectedDDIds.filter((ddID) => ddID !== taskType.ddID));
            setSelectedTaskTypes((prevSelectedTaskTypes) =>
                prevSelectedTaskTypes.filter((type) => type.ddID !== taskType.ddID)
            );
        }
    };

    useEffect(() => {
        async function fetchData() {
            let custTasks = await axios.get(APIURL + "/customers/CustomerTasks", {
                params: { comp_id: props.comp_id, customer_id: props.cusDets.cus_id },
            });

            setOriginalSelectedTaskTypes(custTasks.data)
            setSelectedTaskTypes(custTasks.data)

            let DDIds = []
            custTasks.data.forEach((task) => {
                DDIds.push(task.ddID)
            })
            setSelectedDDIds(DDIds)

            let taskTypeList = await axios.get(APIURL + "/dropdowns/dropdownType/fullTaskType", {
                params: { comp_id: props.comp_id },
            });

            setTypeList(taskTypeList.data)
            setLoading(false);
        }
        fetchData();
    }, []);

    const handleOnAdd = async () => {
        if (isSaving) {
            return;
        }

        setIsSaving(true);

        let uniqueDdIDs = new Set(originalSelectedTaskTypes.flat().map(item => item.ddID));
        let taskTypesToInsert = selectedTaskTypes.filter(item => !uniqueDdIDs.has(item.ddID));

        let payload = {
            taskData: taskTypesToInsert,
            empid: props.user.emp_id,
            status: 1,
            comp_id: props.comp_id,
            customer_id: props.cusDets.cus_id
        }

        let addResult = await axios.post(APIURL + "/customers/CustomerTasks", payload);

        let taskTypesToUpdate = originalSelectedTaskTypes.filter(itemA => {
            return !selectedTaskTypes.some(itemB => {
                return JSON.stringify(itemA.ddID) === JSON.stringify(itemB.ddID);
            });
        });

        let updatePayload = {
            taskData: taskTypesToUpdate,
            empid: props.user.emp_id,
            status: 1,
            comp_id: props.comp_id,
            customer_id: props.cusDets.cus_id
        }

        let updateResult = await axios.delete(APIURL + "/customers/CustomerTasks", { data: updatePayload });

        setIsSaving(false);
    }

    return (
        <div >
            <div style={{ marginTop: '10px' }}>
                <p>Select the task types to associate with {props.cusDets ? props.cusDets.cus_name : 'customer'}:</p>
            </div>
            {typeList.map((taskType) => (
                <div key={taskType.ddID} style={{ display: 'block' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedDDIds.includes(taskType.ddID)}
                                onChange={(event) => handleCheckboxChange(event, taskType)}
                            />
                        }
                        label={taskType.EntityValue}
                    />
                </div>
            ))}
            <div style={{ marginTop: '10px' }}>
                <Button className='w-100' variant="primary" block type="submit" onClick={() => handleOnAdd()}>
                    {props.edit && "Save Customer"}
                </Button>
            </div>
        </div >
    );
}