import react from 'react';
import { Box, Stack } from '@mui/material';

const Section = (props) => {
    return (
        <Box hidden={props.value !== props.index} sx={{ py: 4, mx: 'auto' }}>
            <Stack spacing={4}>
                {props.children}
            </Stack>
        </Box>
    );
}

export default Section;