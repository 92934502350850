import React, { useMemo, useEffect } from 'react';
import { Navigate } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';
import * as dates from 'date-arithmetic';

const dayMap = {
  'Sunday': 0,
  'Monday': 1,
  'Tuesday': 2,
  'Wednesday': 3,
  'Thursday': 4,
  'Friday': 5,
  'Saturday': 6,
};

function WorkDayWrapper(days) {
    // Map day names to their corresponding indices (0 for Sunday through 6 for Saturday)
    const dayIndices = days.map(day => dayMap[day]);
  
    function WorkDay({
      date,
      localizer,
      ...props
    }) {
      const currRange = useMemo(() => [localizer.startOf(date, 'day')], [date, localizer]);

      useEffect(() => {
        console.log(props);
      }, [props] );
  
      return (
        <TimeGrid
          date={date}
          localizer={localizer}
          range={currRange}
          {...props}
        />
      );
    }
  
    WorkDay.navigate = (date, action, { localizer }) => {
      let increment = action === Navigate.NEXT ? 1 : -1;
      let targetDate = date;
      do {
        targetDate = dates.add(targetDate, increment, 'day');
      } while (!dayIndices.includes(targetDate.getDay()));
  
      return targetDate;
    };
  
    WorkDay.title = (date, { localizer }) => {
      // Dynamically generate the title based on the current date
      return `${localizer.format(date, 'dddd MMM DD')}`;
    };
  
    return WorkDay;
}

export const createWorkDay = (days) => {
    return WorkDayWrapper(days);
  };
  