import React from 'react';
import { useState, useEffect } from "react";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { APIURL } from '../../config';
import axios from 'axios';
import SupportCoordinatorCustomers from './SupportCoordinatorCustomers';
import './SupportCoordinatorHome.css';
import Checklists from './Checklists/Checklists';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import NewNote from './Modals/NewNote';
import NewTask from './Modals/NewTask';
import NewTimesheet from './Modals/NewTimesheet';
import Upload from './Modals/Upload';
import TimeSheetWrapper from './../Employee/EmployeeHome/EmployeeHomeComponents/Timesheets/TimesheetWrapper';
import RecentCalls from '../../components/RecentCalls/index';
import UpcomingAppointments from '../../components/TopAppointments/index';
import moment from 'moment';

function SupportCoordionatorHome(props) {
    const [loading, setLoading] = useState(true)
    const [value, setValue] = useState('1');
    const [data, setData] = useState([]);
    const [taskTypes, setTaskTypes] = useState([]);
    const [compSettings, setCompSettings] = useState({});

    const [showAddNote, setShowAddNote] = useState(false);
    const [showUpload, setShowUpload] = useState(false);
    const [showTask, setShowTask] = useState(false);
    const [showTimesheet, setShowTimesheet] = useState(false);

    const [noteCustomerID, setNoteCustomerID] = useState()
    const [uploadCustomerID, setUploadCustomerID] = useState("")
    const [timesheetCustomerID, setTimesheetCustomerID] = useState("")

    const handleShowNote = (id) => {setNoteCustomerID(id); setShowAddNote(true)};
    const handleShowUpload = (id) => {setUploadCustomerID(id); setShowUpload(true)};
    const handleShowTask = () => setShowTask(true);
    const handleCloseTask = () => setShowTask(false);
    const handleShowTimesheet = (id) => {setTimesheetCustomerID(id);setShowTimesheet(true)};
    const handleCloseTimesheet = () => setShowTimesheet(false);

    const isSubscibedToTimeManagement = props.user.subs.find((setting) => setting.feature_id === 12) ? true : false;
   
    const getSCH = async () => {
        let data = await axios.get(APIURL + "/customers/SCH", { params: { 
            comp_id: props.comp_id, 
            emp_id: props.user.emp_id,
            start : moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
            end: moment().endOf('month').format('YYYY-MM-DDT23:59:59')
        } });
        setData(data.data)
        if (data.data.length === 0) {
            setLoading(false)
        }
        console.log(data.data)
    }

    const getData = async () => {
        props.checkTokenExpiry()
        getSCH();
        let taskTypes = await axios.get(APIURL+"/dropdowns/dropdownType/taskTypeList", {params: {comp_id: props.comp_id}});
        let settings = await axios.get(APIURL + '/config/settings', { params: { comp_id: props.comp_id } });
        setTaskTypes(taskTypes.data);
        setCompSettings(settings.data[0]);
    }

    useEffect(() => {
        props.checkTokenExpiry();
        getData();
    }, [props])

    useEffect(() => {
        if(loading && data.length > 0) {
            setLoading(false);
        }
    }, [data])


    return (
        <div className="support-coordinator-home-wrapper">
            <div className="support-coordinator-home-wrapper-top-row">
                <Tabs
                    sx={{ width: '100%'}}
                    indicatorColor="primary"
                    textColor="primary"
                    value={value}>
                    <Tab onClick={() => setValue('1')} label="Customers" value="1" />
                    <Tab onClick={() => setValue('2')} label="Tasks" value="2" />
                    <Tab onClick={() => setValue('3')} label="Checklists" value="3" />
                    <Box sx={{ display: 'inline-flex', justifyContent: 'flex-end', width: "100%", marginBottom: 1  }}>
                        <ButtonGroup size="small" style={{ marginRight: 10 }}>
                            <Button variant="contained" onClick={() => setShowAddNote(true)}>NEW NOTE</Button>
                            <Button variant="contained" onClick={handleShowTask}>NEW TASK</Button>
                            {isSubscibedToTimeManagement &&
                                <Button variant="contained" onClick={handleShowTimesheet}>NEW TIMESHEET</Button>
                            }
                            <Button size="small" variant="contained" onClick={() => setShowUpload(true)}>UPLOAD</Button>
                        </ButtonGroup>
                    </Box>
                </Tabs>
                {value === '1' &&
                    <div className="customers-container">
                        <SupportCoordinatorCustomers 
                            {...props} 
                            handleShowNote={handleShowNote} 
                            handleShowUpload={handleShowUpload} 
                            handleShowTimesheet={handleShowTimesheet} 
                            data={data} 
                            comp_id={props.comp_id} 
                            emp_id={props.user.emp_id} 
                            rerender={getSCH} 
                            loading={loading}
                            isSubscibedToTimeManagement={isSubscibedToTimeManagement}
                            checkTokenExpiry={props.checkTokenExpiry}
                        />
                    </div>
                }
                {value === '2' &&
                    <div className="upcoming-appointments-container">
                        <div className="upcoming-appointments-top">
                            <UpcomingAppointments user={props.user} />
                        </div>
                        <div className="upcoming-appointments-bottom">
                         
                       {!!props.user.subs.find((d)=> d.feature_id === 12) && <TimeSheetWrapper user={props.user} className="upcoming-appointments-timesheets" />
                            }
                            <RecentCalls user={props.user} className="upcoming-appointments-recentcalls" />
                        </div>
                    </div>
                }
                {value === '3' &&
                    <div>
                        <Checklists user={props.user} comp_id={props.comp_id}/>
                    </div>
                }
            </div>
            {!loading &&
                <div>
                    <NewNote showAddNote={showAddNote} setShowAddNote={setShowAddNote} company_id={props.comp_id} mode='add' user={props.user} customer_id={noteCustomerID} setNoteCustomerID={setNoteCustomerID}></NewNote>
                    <NewTask show={showTask} handleClose={handleCloseTask} user={props.user} emp_id={props.user.emp_id} comp_id={props.comp_id} {...props}/> 
                    {isSubscibedToTimeManagement && 
                        <NewTimesheet show={showTimesheet} handleClose={handleCloseTimesheet} comp_id={props.comp_id} user={props.user} emp_id={props.user.emp_id} comp_settings={compSettings} customer_id={timesheetCustomerID} setTimesheetCustomerID={setTimesheetCustomerID} rerender={getSCH}></NewTimesheet>
                    }
                    <Upload showUpload={showUpload} setShowUpload={setShowUpload} company_id={props.comp_id} user={props.user} customer_id={uploadCustomerID} setUploadCustomerID={setUploadCustomerID} rerender={getSCH}></Upload>
                </div>   
            }
        </div>
    )
}

export default SupportCoordionatorHome;