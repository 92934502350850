import {
  Grid,
  Typography,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";

const FavouriteDocuments = (props) => {
  const classes = useStyles();
  const [documentsData, setDocumentsData] = useState({});
  useEffect(() => {}, []);
  return (
    <div className="m-2" style={{ width: '100%' }}>
      <Typography
      className={classes.HeadingStyle}
      >
        Favorite Documents
      </Typography>
    </div>
  );
};
export default FavouriteDocuments;

// CSS
const useStyles = makeStyles((theme) => ({
  HeadingStyle: {
    fontWeight: "bold",
    fontSize: "1.5rem",
    fontFamily: "Lato",
    marginBottom: 15,
  },
}));
