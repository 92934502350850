import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Form } from '@formio/react';
import Container from '@mui/material/Container';
import { APIURL } from '../../../../config';

//form_id, customer_id, task_id, onSubmit, isEditing
const FormRenderer = (props) => {
    const [json_schema, setJsonSchema] = useState({});
    const [data, setData] = useState({});
    const [errors, setErrors] = useState([]);

    const isEditing = useRef(false);
    const cusForm = useRef(null);

    useEffect(() => {
        setJsonSchema(JSON.parse(props.json_schema));
    }, []);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const response = await axios.get(APIURL + '/customer_form/task/' + props.task_id, { params: { formType: props.form_id } });
            if (response.data.length > 0) {
                isEditing.current = true;
                cusForm.current = response.data[0];
                setData(JSON.parse(response.data[0].data));
                props.onEdit && props.onEdit(response.data[0]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleSubmit = async (submission) => {
        try {
            if (isEditing.current) {
                const response = await axios.put(APIURL + '/customer_form/' + cusForm.current.form_id, {
                    data: JSON.stringify(submission),
                    emp_id: props.user_id
                });
                props?.onSubmit && props.onSubmit({ ...cusForm.current, data: JSON.stringify(submission) });
            } else {
                let form = {
                    customer_id: props.customer_id,
                    task_id: props.task_id,
                    data: JSON.stringify(submission),
                    form_type: props.form_id,
                    emp_id: props.user_id
                }
                const response = await axios.post(APIURL + '/customer_form', form);
                props?.onSubmit && props.onSubmit(form);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    }

    return (
        <Container sx={props.styles}>
            <Form
                form={json_schema}
                submission={data}
                onSubmit={handleSubmit}
                onError={(errors) => {
                    setErrors(errors);
                }}
                options={{
                    readOnly: props.readOnly,
                    hide: {
                        submit: props.readOnly,
                    }
                }}
            />
        </Container>
    )
};

export default FormRenderer;
