/* THIS PAGE SHOULD BE EMPTY FOR ANY PRODUCTION BUILDS */
import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import "./LoadingCircle.css"

export default function LoadingCircle(props) {

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        
    }, []);

    return(
        <CircularProgress className="fieldworkerComponent-LoadingCircle" size="80px" />
    );
}