import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import moment from "moment";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { TextField, Button, Box } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./Billable.css";

export default function Billable(props) {
    const [billableData, setBillableData] = useState([]);
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            let billableData = await axios.get(APIURL + "/accountsReceivable/getBillables", {
                params: { companyID: props.comp_id, month: moment(currentMonth).format('YYYYMM') },
            });

            setBillableData(billableData.data);
            setIsLoading(false);
        }

        fetchData();
    }, [currentMonth]);

    function onDateChange(newValue) {
        setCurrMonth(moment(newValue).startOf("month"));
    }

    const columns = [
        { field: "billable_id", headerName: "Billable ID", hide: true },
        { field: "YYYYMM", headerName: "YYYYMM", width: 100 },
        { field: "customer_id", headerName: "Customer ID", hide: true },
        { field: "customer_name", headerName: "Customer Name", width: 200 },
        {
            field: "dob",
            headerName: "Customer DOB",
            hide: true,
            width: 120,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "plan_approval_date",
            headerName: "Plan Approval Date",
            hide: true,
            width: 120,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "AgencyAssignDate",
            headerName: "Agency Assign Date",
            hide: true,
            width: 120,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "project_id", headerName: "Project ID", hide: true },
        { field: "project_name", headerName: "Project Name", width: 200 },
        {
            field: "pro_starts_on",
            headerName: "Project Start Date",
            hide: true,
            width: 120,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "pro_ends_on",
            headerName: "Project End Date",
            hide: true,
            width: 120,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "plan_version", headerName: "Plan Version", width: 100 },
        {
            field: "post_date",
            headerName: "Post Date",
            width: 120,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "proc_code", headerName: "Proc Code", width: 120 },
        { field: "unit", headerName: "Unit", width: 100 },
        { field: "rate", headerName: "Rate", width: 100 },
        { field: "total", headerName: "Total", width: 100 },
        { field: "account_code", headerName: "Account Code", width: 200 },
    ];

    let rows = billableData.map((data) => {
        let billData = {
            billable_id: data.billable_id,
            YYYYMM: data.YYYYMM,
            customer_id: data.customer_id,
            customer_name: data.cus_name,
            dob: data.dob,
            plan_approval_date: data.plan_approval_date,
            AgencyAssignDate: data.AgencyAssignDate,
            project_id: data.project_id,
            project_name: data.pro_title,
            pro_starts_on: data.pro_starts_on,
            pro_ends_on: data.pro_ends_on,
            plan_version: data.plan_version !== 'null' ? data.plan_version : '',
            post_date: data.post_date,
            proc_code: data.proc_code,
            unit: data.unit,
            rate: data.rate,
            total: data.total,
            account_code: data.account_code,
        };
        return billData;
    });

    if (isLoading) {
        return (
            <div className="d-flex flex-row w-100 justify-content-center mt-5">
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div id="claims-main"
                style={{ height: "100vh", width: "100%", backgroundColor: "#fafafa" }}
                className="d-flex row justify-content-start">
                <div
                    className="flex-item"
                    style={{
                        flexDirection: "row-reverse",
                        height: "40px",
                        marginTop: "5px",
                        marginBottom: "20px",
                        marginLeft: "35px",
                    }}
                >
                    <Button
                        size="large"
                        className="DayButton"
                        onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                    >
                        <i className="fas fa-arrow-left"></i>
                    </Button>
                    <LocalizationProvider
                        class="DatePickerParent"
                        dateAdapter={AdapterDateFns}
                    >
                        <DatePicker
                            label="Select Date"
                            views={["year", "month"]}
                            value={currentMonth}
                            onChange={(newValue) => {
                                this.onDateChange(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" />
                            )}
                        />
                    </LocalizationProvider>
                    <Button
                        size="large"
                        className="DayButton"
                        onClick={() => onDateChange(currentMonth.add(1, "month"))}
                    >
                        <i className="fas fa-arrow-right"></i>
                    </Button>
                </div>
                <div style={{ marginTop: '-170px', marginLeft: '15px', height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '100%', height: "calc(100vh - 195px)" }}>
                        <DataGridPro
                            initialState={{ sorting: { sortModel: [{ field: "post_date", sort: "desc" }] } }}
                            getRowId={(row) => row.billable_id}
                            rows={rows}
                            columns={columns}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            checkboxSelection={false}
                            disableSelectionOnClick
                            density="compact"
                        />
                    </Box>
                </div>
            </div>
        )
    }
}