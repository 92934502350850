import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import { Card, CardContent, Typography, LinearProgress, Box, Grid, Divider } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomerGoalForm from "../../../components/forms/CustomerGoalForm/CustomerGoalForm";
import PermittedTaskForm from "../../../components/forms/PermittedTaskForm/PermittedTaskForm";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


const CustomerGoalStatusMap = {
    0: 'Not Started',
    1: 'In Progress',
    2: 'Completed',
    3: 'Archived',
};

export default function GoalCard(props) {

    const [permittedTaskTypes, setPermittedTaskTypes] = useState([])
    const [openEditGoalDialog, setOpenEditGoalDialog] = useState(false)
    const [openEditPermittedTaskDialog, setOpenEditPermittedTaskDialog] = useState(false)
    const [editedPermittedTask, setEditedPermittedTask] = useState({})

    useEffect(() => {
        async function fetchData() {
            const response = (await axios.get(APIURL + `/customer_goal/permitted_task?customer_goal_id=${props.goal.goalId}`)).data
            setPermittedTaskTypes(response)
        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchData() {
            const response = (await axios.get(APIURL + `/customer_goal/permitted_task?customer_goal_id=${props.goal.goalId}`)).data
            setPermittedTaskTypes(response)
        }
        if(props.refreshData){
            fetchData();
        }
    }, [props.refreshData]);

    const handleArchiveCustomerGoal = async () => {
        const response = await axios.delete(APIURL + `/customer_goal`, { params: {
            customer_goal_id: props.goal.goalId
        }})

        alert(`Customer Goal '${props.goal.goalTitle}' has been archived.`)
        props.setRefreshData(true)
    }

    const handleArchivePermittedTask = async (taskType) => {
        const response = await axios.delete(APIURL + `/customer_goal/permitted_task`, { params: {
            permitted_task_id: taskType.permitted_task_id
        }})
        
        props.setRefreshData(true)
        alert(`Permitted Task has been archived.`)
    }

    return (
        <>
            <Card variant="outlined" style={{ borderRadius: '10px' }}>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={4}>
                            <Typography variant="h5" component="div" display="inline">
                                {props.goal.goalTitle}
                            </Typography>
                            <IconButton sx={{ paddingTop: '0px', paddingBottom: '7px' }} aria-label="edit" size="small" onClick={() => setOpenEditGoalDialog(true)}>
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                            <IconButton sx={{ paddingTop: '0px', paddingBottom: '7px' }} aria-label="delete" size="small" onClick={() => handleArchiveCustomerGoal()}>
                                <DeleteIcon fontSize="inherit" />
                            </IconButton>
                            <Typography color="text.secondary">
                                Goal ID: {props.goal.goalId}
                            </Typography>
                            <Typography color="text.secondary">
                                Customer ID: {props.goal.customerId}
                            </Typography>
                            <Typography sx={{ marginBottom: '5px' }} color="text.secondary">
                                Project ID: {props.goal.projectId}
                            </Typography>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid item xs={12} md={5}>
                            <p>{props.goal.goalDetails}</p>
                        </Grid>
                        <Divider orientation="vertical" flexItem />
                        <Grid sx={{ marginLeft: '5px' }} item xs={12} md={2} >
                            <h6>Task Types</h6>
                            {permittedTaskTypes.map(taskType => {
                                return (
                                    <div style={{ display: 'flex', alignItems: 'center', width: '13vw' }}>
                                        <p style={{ marginBottom: '0px', flex: '1' }} key={taskType.permitted_task_id}>- {taskType.task_type_name}</p>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <IconButton sx={{ paddingTop: '0px' }} aria-label="edit" size="small" onClick={() => { setEditedPermittedTask(taskType); setOpenEditPermittedTaskDialog(true)}}>
                                                <EditIcon fontSize="inherit" />
                                            </IconButton>
                                            <IconButton sx={{ paddingTop: '0px' }} aria-label="delete" size="small" onClick={() => {handleArchivePermittedTask(taskType)}}>
                                                <DeleteIcon fontSize="inherit" />
                                            </IconButton>
                                        </div>
                                    </div>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Divider style={{ marginBottom: '20px', backgroundColor: '#e0e0e0' }} />
                    <Box>
                        <p style={{ marginBottom: '10px' }}>
                            Status: {CustomerGoalStatusMap[props.goal.status]}
                        </p>
                        <LinearProgress variant="determinate" value={props.goal.progress} />
                    </Box>
                </CardContent>
            </Card>
            
            <Dialog open={openEditGoalDialog} onClose={() => setOpenEditGoalDialog(false)} maxWidth="md" fullWidth>
            <DialogTitle>
                Add New Goal
                <IconButton
                    aria-label="close"
                    onClick={() => setOpenEditGoalDialog(false)}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <CustomerGoalForm user={props.user} customer_id={props.goal.customerId} customer_goal_id={props.goal.goalId} onSubmit={() => { setOpenEditGoalDialog(false); props.setRefreshData(true) }}/>
            </DialogContent>
            </Dialog>
            
            <Dialog open={openEditPermittedTaskDialog} onClose={() => setOpenEditPermittedTaskDialog(false)} maxWidth="md" fullWidth>
                <DialogTitle>
                    Add New Permitted Task
                    <IconButton
                        aria-label="close"
                        onClick={() => setOpenEditPermittedTaskDialog(false)}
                        style={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <PermittedTaskForm user={props.user} permitted_task_id={editedPermittedTask?.permitted_task_id} onSubmit={() => {
                            props.setRefreshData(true)
                            setOpenEditPermittedTaskDialog(false);
                            setEditedPermittedTask({});
                        }}/>
                </DialogContent>
            </Dialog>
        </>
    );
}
