import React, { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { withJsonFormsControlProps } from '@jsonforms/react';

const MultipleSelectCheckmarks = (props) => {
  const [items, setItems] = useState([...props.data]);

  const handleChange = (event) => {
    const value = event.target.value;
    setItems(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    let finalVal = value.map(item => {
      return props.schema.enum.find(e => item === e.title);
    })
    
    props.handleChange(props.path, finalVal)
  };

  return (
      <FormControl sx={{ mb: 2, width: "100%", maxWidth: "450px" }}>
        <InputLabel id="state-multi-select-label">{props.label}</InputLabel>
        <Select
          labelId="state-multi-select-label"
          id="state-multi-select"
          multiple
          value={items}
          onChange={handleChange}
          input={<OutlinedInput label={props.label} />}
          renderValue={(selected) => {return selected.join(", ");
          }}
        >
          {props.schema.enum.map((item, i) => (
            <MenuItem key={i} value={item.title}>
              <Checkbox checked={items.indexOf(item.title) > -1} />
              <ListItemText primary={item.title} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{props.description}</FormHelperText>
      </FormControl>
  );
}

export default withJsonFormsControlProps(MultipleSelectCheckmarks)