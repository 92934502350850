import { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Draggable from 'react-draggable';
import { styled } from '@mui/material/styles';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const NotesPopup = (props) => {
    const [expand, setExpand] = useState(true);

    return (
        <Draggable handle=".handle">
            <Card variant="outlined" sx={{ ...props.sx, width: "450px", zIndex: 2 }}>
                <CardHeader
                    className='handle'
                    disableTypography 
                    title={<Typography sx={{ fontWeight: "bold" }}>Notes</Typography>}
                    action={
                        <ExpandMore
                            expand={expand}
                            onClick={() => setExpand(!expand)}
                            sx={{ marginTop: "-7px" }}

                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    }
                    sx={{ height: "50px" }}
                />
                <Box sx={{ padding: "0px 1rem",  overflowY: "auto", height: expand ? "auto" : "inherit"  }}>
                    <Collapse in={expand} timeout="auto" unmountOnExit>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", py: 1 }}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Notes"
                                multiline
                                minRows={10}
                                maxRows={20}
                                fullWidth
                                value={props.note}
                                onChange={(e) => props.setNote(e)}
                                variant="outlined"
                                sx={{ marginBottom: "1rem" }}
                            />
                        </Box>
                    </Collapse>
                </Box>    
            </Card>
        </Draggable>
    );
}

export default NotesPopup;