import React from "react";
// import {Modal, Button} from 'react-bootstrap'
import { APIURL } from "../../config";
import axios from "axios";
import moment, { weekdays } from "moment";
import TimeSheetConfirmModal from "./TimeSheetConfirmModal";
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/lab/Autocomplete';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/lab/Alert';
// import { isArray } from "@material-ui/data-grid"
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";

export default class WeeklyTimesheetAlt extends React.Component {
  state = {
    open:
      moment(this.props.empTimeSheet.start_date).isBefore(
        moment(this.props.preOpen.start)
      ) &&
      moment(this.props.empTimeSheet.end_date).isAfter(
        moment(this.props.preOpen.start)
      )
        ? true
        : false,
    totalHours: 0,
  };

  timeComparator = (v1, v2) => moment(v1, ["h:mm A"]).diff(moment(v2, ["h:mm A"]));

  componentDidMount = async () => {
    // console.log(moment(this.props.preOpen.start));
    // console.log(moment(this.props.empTimeSheet.start_date));
    /* Set Total Hours */
    var totalH = Number(0.0);
    this.props.empData.forEach((taskEntry) => {
      var loc_start_date = moment(
        taskEntry.start_time,
        "DD[ ]MMM[ ]YYYY[, ]kk[:]mm[ ]A",
        true
      );
      // var loc_end_date = moment(taskEntry.end_time, "DD[ ]MMM[ ]YYYY[, ]kk[:]mm[ ]A", true);
      var week_start_date = moment(this.props.empTimeSheet.start_date.replace(/Z/g, "")).startOf('date');
      var week_end_date = moment(this.props.empTimeSheet.end_date.replace(/Z/g, "")).endOf('date');
      if (loc_start_date.isBetween(week_start_date, week_end_date)) {
        totalH = totalH + Number(taskEntry.worked);
      }
    });

    totalH = Math.round((totalH + Number.EPSILON) * 100) / 100;

    // console.log(totalH, typeof totalH);

    // totalH always rounded to two decimal points.
    // So we just need to handle two edge cases.  Numbers with decimnal points .99 and 0.01

    // the below code will round numbers like  1.99 to 2
    if (totalH.toString().split(".").pop() == "99") {
      totalH = totalH + 0.01;
    }

    // the below code will round numbers like  1.01 to 1
    if (totalH.toString().split(".").pop() == "01") {
      totalH = totalH - 0.01;
    }
    await this.setState({ totalHours: totalH.toString() });
  };

  toggle() {
    this.setState({
      open: !this.state.open,
    });
  }

  parseLocDate(dateString) {
    var year = dateString.substring(7, 11);
    var month = dateString.substring(3, 6);
    var day = dateString.substring(0, 2);
    if (month === "Jan") {
      month = "01";
    }
    if (month === "Feb") {
      month = "02";
    }
    if (month === "Mar") {
      month = "03";
    }
    if (month === "Apr") {
      month = "04";
    }
    if (month === "May") {
      month = "05";
    }
    if (month === "Jun") {
      month = "06";
    }
    if (month === "Jul") {
      month = "07";
    }
    if (month === "Aug") {
      month = "08";
    }
    if (month === "Sep") {
      month = "09";
    }
    if (month === "oct") {
      month = "10";
    }
    if (month === "Nov") {
      month = "11";
    }
    if (month === "Dec") {
      month = "12";
    }
    return new Date(year + "-" + month + "-" + day + " 00:00:00 EST");
  }

  getColumns() {
    var columns = [
      { field: "date", headerName: "Date", flex: 0.5, sortComparator: this.timeComparator},
      { field: "hours", headerName: "Hours", flex: 0.3 },
      { field: "totals", headerName: "Totals", flex: 0.35, hide:true },
      { field: "projectName", headerName: "Project Name", flex: 0.7 },
      { field: "taskName", headerName: "Task Name", flex: 0.7 },
      { field: "taskType", headerName: "Task Type", flex: 0.7 },
      { field: "startTime", headerName: "Start Time", flex: 0.3, sortComparator: this.timeComparator, hide: this.props.isSupportCoordinationCompany},
      { field: "endTime", headerName: "End Time", flex: 0.3, sortComparator: this.timeComparator, hide: this.props.isSupportCoordinationCompany},
      { field: "notes", headerName: "Notes", flex: 0.4 },
      {
        field: "actions",
        headerName: " ",
        flex: 0.3,
        headerClassName: "timesheets-summarytable-columnheader",
        renderCell: (params) => {
          if(params.row.hierarchy) {
            if(this.props.empTimeSheet.time_sheet_status == 'ARCHIVED' || this.props.empTimeSheet.time_sheet_status == 'APPROVED'){
              return (<></>);
            }
            return (
              <div>
                <button
                  style={{ borderRadius: 20 }}
                  className="btn btn-sm btn-info"
                  onClick={() => this.props.editRecord(params.row.tas_id)}
                >
                  {" "}
                  <i className="far fa-edit"></i>{" "}
                </button>
                <button
                  style={{
                    borderRadius: 20,
                    backgroundColor: "red",
                    borderColor: "black",
                    marginLeft: "5px",
                  }}
                  className="btn btn-sm btn-info"
                  onClick={() => {
                    if (
                      ["ACTIVE", "OPEN"].includes(
                        this.props.empTimeSheet.time_sheet_status
                      )
                    ) {
                      this.props.deleteTask(params.row.tas_id,params.row.taskName,params.row.tas_pro_id);
                    }
                  }}
                >
                  {" "}
                  <i className="far fa-trash-alt"> </i>
                </button>
              </div>
            );
          };
          return <span></span>
        },
      },
    ];
    return columns;
  }

  getRows() {
    var rows = [];
    if (!!this.props.empData) {
      // Debugging empData
      //console.log("empData: ");
      //console.log(this.props.empData);
      this.props.empData.forEach((taskEntry) => {
        var loc_start_date = moment(
          taskEntry.start_time,
          "DD[ ]MMM[ ]YYYY[, ]kk[:]mm[ ]A",
          true
        );
        // var loc_end_date = moment(taskEntry.end_time, "DD[ ]MMM[ ]YYYY[, ]kk[:]mm[ ]A", true);
        var week_start_date = moment(this.props.empTimeSheet.start_date.replace(/Z/g, "")).startOf('date');
        var week_end_date = moment(this.props.empTimeSheet.end_date.replace(/Z/g, "")).endOf('date');
    
        if (loc_start_date.isBetween(week_start_date, week_end_date)) {
          rows.push({
            hierarchy: [taskEntry.start_time.substring(0, 11), taskEntry.id],
            id: taskEntry.id,
            tas_pro_id: taskEntry.tas_pro_id,
            tas_id: taskEntry.tas_id,
            date: taskEntry.start_time.substring(0, 11),
            startTime: moment(taskEntry.start_time).format('hh:mm a'),
            endTime: moment(taskEntry.end_time).format('hh:mm a'),
            projectName: taskEntry.project_name,
            taskName: taskEntry.task_name,
            taskType: taskEntry.task_type,
            notes: taskEntry.notes,
            hours: parseFloat(taskEntry.worked),
            totals: parseFloat(taskEntry.worked),
            lastUpdated: moment(taskEntry.tas_updated_at).format('MMM D [at] hh:mma'),
            createdAt: moment(taskEntry.tas_created_at).format('MMM D [at] hh:mma')
          });
        }
      });
    }
    rows.sort((a, b) => (a.startTime > b.startTime) ? 1 : -1)
    return rows;
  }

  toggleLock = async () => {
    if (this.props.empTimeSheet.time_sheet_status === "APPROVED") {
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        time_sheet_id: this.props.empTimeSheet.time_sheet_id,
        company_id: this.props.empTimeSheet.company_id,
        emp_id: this.props.empTimeSheet.emp_id,
        pay_period_id: this.props.empTimeSheet.pay_period_id,
        time_sheet_status: "ARCHIVED",
        submitted_on: this.props.empTimeSheet.submitted_on,
        approved_on: this.props.empTimeSheet.approved_on,
        approved_by: this.props.empTimeSheet.approved_by,
      });
      if (empTimeSheetUpdate.data.code === 200) this.props.getEmpData();
    } else if (this.props.empTimeSheet.time_sheet_status === "ARCHIVED") {
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        time_sheet_id: this.props.empTimeSheet.time_sheet_id,
        company_id: this.props.empTimeSheet.company_id,
        emp_id: this.props.empTimeSheet.emp_id,
        pay_period_id: this.props.empTimeSheet.pay_period_id,
        time_sheet_status: "APPROVED",
        submitted_on: "",
        approved_on: "",
        approved_by: null,
      });
      if (empTimeSheetUpdate.data.code === 200) this.props.getEmpData();
    }
  };

  toggleApprove = async () => {
    if (this.props.empTimeSheet.time_sheet_status === "SUBMITTED") {
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        time_sheet_id: this.props.empTimeSheet.time_sheet_id,
        company_id: this.props.empTimeSheet.company_id,
        emp_id: this.props.empTimeSheet.emp_id,
        pay_period_id: this.props.empTimeSheet.pay_period_id,
        time_sheet_status: "APPROVED",
        submitted_on: this.props.empTimeSheet.submitted_on,
        approved_on: new Date().toISOString().slice(0, 10),
        approved_by: this.props.user.emp_id,
      });
      if (empTimeSheetUpdate.data.code === 200) this.props.getEmpData();
    } else if (this.props.empTimeSheet.time_sheet_status === "APPROVED") {
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        time_sheet_id: this.props.empTimeSheet.time_sheet_id,
        company_id: this.props.empTimeSheet.company_id,
        emp_id: this.props.empTimeSheet.emp_id,
        pay_period_id: this.props.empTimeSheet.pay_period_id,
        time_sheet_status: "SUBMITTED",
        submitted_on: this.props.empTimeSheet.submitted_on,
        approved_on: "",
        approved_by: null,
      });
      if (empTimeSheetUpdate.data.code === 200) this.props.getEmpData();
    }
  };

  submitTimeSheet = async () => {
    if (
      this.props.empTimeSheet.time_sheet_status === "ACTIVE" ||
      this.props.empTimeSheet.time_sheet_status === "OPEN"
    ) {
      let empManager = null;
      try {
        empManager = (
          await axios.get(APIURL + "/employees", {
            params: {
              com_id: this.props.empTimeSheet.company_id,
              emp_id: this.props.empTimeSheet.emp_id,
            },
          })
        ).data[0].emp_manager_id;
      } catch (err) {
        console.log(`ERROR: Could not find Manager`);
      }
      console.log(empManager);
      let empTimeSheetUpdate = await axios.put(APIURL + "/time_sheet", {
        emp_manager: empManager,
        time_sheet_id: this.props.empTimeSheet.time_sheet_id,
        company_id: this.props.empTimeSheet.company_id,
        emp_id: this.props.empTimeSheet.emp_id,
        pay_period_id: this.props.empTimeSheet.pay_period_id,
        time_sheet_status: "SUBMITTED",
        submitted_on: new Date().toISOString().slice(0, 10),
        approved_on: "",
        approved_by: null,
      });
      if (empTimeSheetUpdate.data.code === 200) this.props.getEmpData();
    }
  };

  getSubmitButton() {
    if (
      this.props.empTimeSheet.time_sheet_status === "ACTIVE" ||
      this.props.empTimeSheet.time_sheet_status === "OPEN"
    ) {
      return (
        <TimeSheetConfirmModal
          type={"SUBMIT"}
          onClick={this.submitTimeSheet.bind(this)}
        >
          <button
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
            }}
            // onClick={this.submitTimeSheet.bind(this)}
          >
            Submit Timesheet
          </button>
        </TimeSheetConfirmModal>
      );
    }
    return;
  }

  getLockButton() {
    if (this.props.empTimeSheet.time_sheet_status === "APPROVED")
      return (
        <TimeSheetConfirmModal
          type={"LOCK"}
          onClick={this.toggleLock.bind(this)}
        >
          <button
            style={{
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
            }}
          >
            Lock
          </button>
        </TimeSheetConfirmModal>
      );
    else if (this.props.empTimeSheet.time_sheet_status === "ARCHIVED")
      return (
        <TimeSheetConfirmModal
          type={"UNLOCK"}
          onClick={this.toggleLock.bind(this)}
        >
          <button
            style={{
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
            }}
          >
            Unlock
          </button>
        </TimeSheetConfirmModal>
      );
    else
      return (
        <TimeSheetConfirmModal
          disabled
          type={"LOCK"}
          onClick={this.toggleLock.bind(this)}
        >
          <button
            disabled
            style={{
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
              backgroundColor: "rgba(170, 170, 170, 1)",
            }}
          >
            Lock
          </button>{" "}
        </TimeSheetConfirmModal>
      );
  }

  getApproveButton() {
    if (this.props.empTimeSheet.time_sheet_status === "ACTIVE")
      return (
        <TimeSheetConfirmModal
          disabled
          type={"APPROVE"}
          onClick={this.toggleLock.bind(this)}
        >
          <button
            disabled
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
              backgroundColor: "rgba(170, 170, 170, 1)",
            }}
            // onClick={this.toggleApprove.bind(this)}
          >
            Approve
          </button>
        </TimeSheetConfirmModal>
      );
    else if (this.props.empTimeSheet.time_sheet_status === "ARCHIVED")
      return (
        <TimeSheetConfirmModal
          disabled
          type={"APPROVE"}
          onClick={this.toggleLock.bind(this)}
        >
          <button
            disabled
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
              backgroundColor: "rgba(170, 170, 170, 1)",
            }}
          >
            Approve
          </button>
        </TimeSheetConfirmModal>
      );
    else if (this.props.empTimeSheet.time_sheet_status === "SUBMITTED")
      return (
        <TimeSheetConfirmModal
          disabled={["Fieldworker", "Support Coordinator"].includes(
            this.props.user.type
          )}
          type={"APPROVE"}
          onClick={this.toggleApprove.bind(this)}
        >
          <button
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
              backgroundColor: ["Fieldworker", "Support Coordinator"].includes(
                this.props.user.type
              )
                ? "rgba(170, 170, 170, 1)"
                : "",
            }}
            disabled={["Fieldworker", "Support Coordinator"].includes(
              this.props.user.type
            )}
            // onClick={this.toggleApprove.bind(this)}
          >
            Approve
          </button>
        </TimeSheetConfirmModal>
      );
    else if (this.props.empTimeSheet.time_sheet_status === "APPROVED")
      return (
        <TimeSheetConfirmModal
          disabled={["Fieldworker", "Support Coordinator"].includes(
            this.props.user.type
          )}
          type={"UNAPPROVE"}
          onClick={this.toggleApprove.bind(this)}
        >
          <button
            style={{
              marginRight: "10px",
              float: "right",
              border: "2px solid rgba(63, 167, 214, 1)",
              color: "rgba(63, 167, 214, 1)",
              backgroundColor: ["Fieldworker", "Support Coordinator"].includes(
                this.props.user.type
              )
                ? "rgba(170, 170, 170, 1)"
                : "",
            }}
            disabled={["Fieldworker", "Support Coordinator"].includes(
              this.props.user.type
            )}
            // onClick={this.toggleApprove.bind(this)}
          >
            Unapprove
          </button>
        </TimeSheetConfirmModal>
      );
  }

  getStatusTitle() {
    if (this.props.empTimeSheet.time_sheet_status === "OPEN")
      return (
        <p style={{ float: "right", marginRight: "2%", color: "blue" }}>OPEN</p>
      );
    if (this.props.empTimeSheet.time_sheet_status === "ACTIVE")
      return (
        <p style={{ float: "right", marginRight: "2%", color: "blue" }}>
          ACTIVE
        </p>
      );
    else if (this.props.empTimeSheet.time_sheet_status === "SUBMITTED")
      return (
        <p style={{ float: "right", marginRight: "2%", color: "orange" }}>
          SUBMITTED
        </p>
      );
    else if (this.props.empTimeSheet.time_sheet_status === "APPROVED")
      return (
        <p style={{ float: "right", marginRight: "2%", color: "green" }}>
          APPROVED
        </p>
      );
    else if (this.props.empTimeSheet.time_sheet_status === "ARCHIVED")
      return (
        <p style={{ float: "right", marginRight: "2%", color: "gray" }}>
          ARCHIVED
        </p>
      );
  }

  getTreeDataPath = (row) => row.hierarchy;

  groupingColDef = {
    headerName: 'Date',
    valueFormatter: (params) => typeof params.id === 'number' ? '' : params.value,
    flex: 0.55
  };

  render() {
    return (
      <div className="weeklytimesheet">
        <button
          className="weeklytimesheetbutton"
          onClick={this.toggle.bind(this)}
        >
          <p style={{ float: "left", minWidth: 500 }}>
            {moment(this.props.empTimeSheet.start_date.substring(0, 10)).format(
              "dddd[ ]MMMM[ ]DD[ ]"
            ) +
              " - " +
              moment(this.props.empTimeSheet.end_date.substring(0, 10)).format(
                "dddd[ ]MMMM[ ]DD[ ]YYYY"
              )}
          </p>
          <p style={{ float: "left", marginLeft: "3%" }}>
            Total Hours: {this.state.totalHours} hours
          </p>
          <i
            style={{ float: "right" }}
            class="fa fa-chevron-down"
            aria-hidden="true"
          ></i>
          {this.getStatusTitle()}
          <p style={{ float: "right" }}>Approval Status:&nbsp;</p>
        </button>
        <div
          className={
            "week-approval-dropdown week-approval-dropdown-collapse" +
            (this.state.open ? " in" : "")
          }
        >
          <DataGridPro
            rows={this.getRows()}
            columns={this.getColumns()}
            rowHeight={30}
            density="comfortable"
            pageSize={9}
            showToolbar
            components={{ Toolbar: GridToolbar, color: "#3fadf6" }}
          />
          {this.props.empTimeSheet.time_sheet_status === "ACTIVE" && (
            <div className="addedit-div" style={{ minHeight: "40px" }}>
              <button
                style={{
                  float: "right",
                  border: "none",
                  color: "rgba(63, 167, 214, 1)",
                }}
                onClick={() => {
                  this.props.setAdd(
                    this.props.empTimeSheet.emp_id,
                    this.props.empTimeSheet.start_date,
                    this.props.empTimeSheet.end_date
                  );
                }}
              >
                Add a New Entry
              </button>
            </div>
          )}
          <div className="lockapprive-div" style={{ minHeight: "30px" }}>
            {this.getLockButton()}
            {this.getApproveButton()}
            {this.getSubmitButton()}
            <img
              style={{ float: "right", margin: "5px", marginRight: "10px" }}
              alt="time"
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/64/Lock_Icon.svg/768px-Lock_Icon.svg.png"
              width="24"
              height="24"
            ></img>
            <p style={{ margin: "0px", fontSize: "14px" }}>
              {!!this.props.empTimeSheet.submitted_on ? 'Submitted Date: ' + moment(this.props.empTimeSheet.submitted_on.replace(/Z/g, "")).format('YYYY-MM-DD') : ''}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
