import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Button, TextField } from "@mui/material";
import moment from "moment";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./reports.css";

export default function LateMTReport(props) {
    // const [gridId, setGridId] = useState(56);
    // const apiRef = useGridApiRef();
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));

    useEffect(() => {
        props.onMonthChange(currentMonth);
    }, [currentMonth]);

    function onDateChange(newValue) {
        setCurrMonth(moment(newValue).startOf("month"));
    }

    const columns = [
        { field: "tas_id", headerName: "Task ID", hide: true },
        { field: "cus_name", headerName: "Customer Name", width: 200 },
        {
            field: "pro_ends_on",
            headerName: "Plan End Date",
            width: 130,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "SC_name", headerName: "SC Name", width: 200 },
        { field: "SC_supervisor_name", headerName: "SC Supervisor Name", width: 200 },
        { field: "tas_mm_id", headerName: "MM ID", hide: true },
        { field: "tas_mm_status", headerName: "MT Status", width: 200 },
    ]

    let rows = props?.data.map((data, idx) => {
        let SC_name = (data.emp_first_name && data.emp_last_name) ? (data.emp_first_name + ' ' + data.emp_last_name) : '';
        let SC_supervisor_name = data.SC_MANAGER ? data.SC_MANAGER[0].emp_first_name + ' ' + data.SC_MANAGER[0].emp_last_name : '';

        let taskData = {
            id: idx,
            tas_id: data.tas_id,
            cus_name: data.cus_name,
            pro_ends_on: data.pro_ends_on,
            SC_name: SC_name,
            SC_supervisor_name: SC_supervisor_name,
            tas_mm_id: data.tas_mm_id,
            tas_mm_status: data.tas_mm_status,
        };
        return taskData;
    });

    return (
        <div>
            {rows.length === 0 ?
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: "500px", marginTop: 10 }}
                >
                    <img
                        src="./nosearchresult.png"
                        alt="nosearchresult"
                        style={{ width: "25rem", height: "15rem", margin: 10 }}
                    />
                    <h5
                        style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
                        className="text-center"
                    >
                        There is currently no data available{" "}
                    </h5>
                </div>
                :
                <div>
                    <div id="report-main" style={{ marginBottom: 20 }}>
                        <Button
                            size="large"
                            className="DayButton"
                            onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                        >
                            <i className="fas fa-arrow-left"></i>
                        </Button>
                        <LocalizationProvider
                            class="DatePickerParent"
                            dateAdapter={AdapterDateFns}
                        >
                            <DatePicker
                                label="Select Date"
                                views={["year", "month"]}
                                value={currentMonth}
                                onChange={(newValue) => {
                                    onDateChange(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} variant="standard" />
                                )}
                            />
                        </LocalizationProvider>
                        <Button
                            size="large"
                            className="DayButton"
                            onClick={() => onDateChange(currentMonth.add(1, "month"))}
                        >
                            <i className="fas fa-arrow-right"></i>
                        </Button>
                    </div>
                    <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '100%', height: "calc(100vh - 265px)" }}>
                            <DataGridPro
                                // gridId={gridId}
                                // user={props.user}
                                initialState={{ sorting: { sortModel: [{ field: "pro_ends_on", sort: "asc" }] } }}
                                // apiRef={apiRef}
                                getRowId={(row) => row.id}
                                rows={rows}
                                columns={columns}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                checkboxSelection={false}
                                disableSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
            }
        </div>
    );
}