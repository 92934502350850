import React, { useState } from 'react';
import DayCareProviderScheduler from './DayCareProviderScheduler';
import ServiceProviderScheduler from './ServiceProviderScheduler';
import { Box } from '@mui/material';

const Scheduler = (props) => {
  const isServiceProvider = props.user.comp_settings.find(setting => setting.feature_id === 102 && setting.feature_value === 'primaryBusinessType');

  return (
    <Box>
      {isServiceProvider ? <ServiceProviderScheduler {...props} /> : <DayCareProviderScheduler {...props} />}
    </Box>
  )
}

export default Scheduler;