import react from 'react';
import { Button, Box } from '@mui/material';

const TypeButton = (props) => {
    return (
        <Box>
            <Button variant={props.selected ? "contained" : "outlined"} color="primary" onClick={props.onClick} size="large" sx={{ width: '100%', height: props.height, p: 0, borderRadius: 1.5 }}>
                {props.title}
            </Button>
        </Box>
    );
}

export default TypeButton;