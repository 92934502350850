import React, { useState, useEffect } from "react";
import { Grid, Typography, Link, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Tip from "../../utils/fields/Tip";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    button: {
        fontSize: "12px",
        background: " #FFF",
        color: "#3FA7D6",
        fontFamily: "Lato",
        fontWeight: "bold",
        height: "25px",
        borderColor: "#3FA7D6",
        borderRadius: "7.4px",
        "&:hover": {
            background: "#3FA7D6",
            color: "#FFF",
        },
    },
    link: {
        fontSize: 12,
    },
}));

export default function ReportCaseManagerCard(props) {
    const classes = useStyles();

    return (
        <Grid
            style={{
                padding: "9px",
                marginBottom: "12px",
                border: "1px solid #c7c7c7",
                borderRadius: "4px",
            }}
        >
            <Grid container>
                <Grid
                    item
                    xs={4}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Typography
                        variant="subtitle2"
                        style={{ fontSize: 18, fontFamily: "Lato" }}
                    >
                        {/* {props?.data?.status ? (
                            <Tip title="Remove from favorite reports">
                                <img
                                    src="./star-solid.svg"
                                    alt="barchart"
                                    style={{
                                        fontFamily: "Lato",
                                        width: "1.2rem",
                                        height: "1rem",
                                        cursor: "pointer",
                                        bottom: "3px",
                                        position: "relative",
                                    }}
                                    onClick={() => props.markFavourite(props?.data, 0)}
                                />
                            </Tip>
                        ) : (
                            <Tip title="Add to favorite reports">
                                <img
                                    src="./star-regular.svg"
                                    alt="barchart"
                                    style={{
                                        fontFamily: "Lato",
                                        width: "1.2rem",
                                        height: "1rem",
                                        cursor: "pointer",
                                        bottom: "3px",
                                        position: "relative",
                                    }}
                                    onClick={() => props.markFavourite(props?.data, 1)}
                                />
                            </Tip>
                        )} */}
                        Invoice - {moment(props?.data?.cus_created_at).format('DD-MMM-YYYY')} (Q{moment(props?.data?.cus_created_at).utc().quarter()})
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Typography
                        variant="subtitle2"
                        style={{ fontSize: 18, fontFamily: "Lato" }}
                    >
                        <b>{props?.data?.cus_name ? props?.data?.cus_name : '-'}</b>
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Tip title="Click to download">
                        <Button
                            className={`ml-1 ${classes.button} `}
                            size="small"
                            variant="outlined"
                            onClick={() => props.openReport(props?.data, "download")}
                        >
                            PDF
                        </Button>
                    </Tip>
                </Grid>
                <Grid
                    item
                    xs={2}
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Link
                        component="button"
                        variant="body2"
                        onClick={() => props.openReport(props?.data, "open")}
                        underline="none"
                        className={classes.link}
                    >
                        <Typography
                            variant="subtitle2"
                            style={{ fontFamily: "Lato", fontSize: 14 }}
                        >
                            VIEW REPORT <i className="fas fa-angle-right"></i>
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </Grid>
    );
};