import react, { useState, useEffect } from 'react';
import { Box, Typography, Chip, Checkbox, Stack, FormControlLabel, FormGroup } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';
import axios from 'axios';
import { APIURL } from '../../../config';

const States = (props) => {
    const [states, setStates] = useState([]);
    const [originalStates, setOriginalStates] = useState([]);

    const handleCheckBoxChange = (event) => {
        if(event.target.checked){
            setStates([...states, {name: event.target.name, isoCode: props.stateList.find((state) => state.name === event.target.name).isoCode}]);
        }else{
            setStates(states.filter((state) => state.name !== event.target.name));
        }
    }

    const handleChipDelete = (state) => {
        setStates(states.filter((s) => s.name !== state.name));
    }

    const handleSave = async () => {
        try {
            let response = await axios.put(APIURL + "/config/settings/replace", {
                company_id: props.comp_id, 
                feature_id: 99,
                added_by: props.user_id,
                settings: states.map((state) => {
                    return {
                        state_id: state.isoCode,
                        feature_value: state.name,
                        feature_id: 99
                    }
                })
            });
            setOriginalStates(states);
        } catch (error) {
            console.error(error);
        }   
    }
    
    useEffect(() => {
        setStates(props.selectedStates);
        setOriginalStates(props.selectedStates);
    }, [props.selectedStates]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            States
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            What states are you running your business in?
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h6">
                            Selected States
                        </Typography>
                        <Box sx={{ minHeight: 32 }}>
                            <Box sx={{ minHeight: 40 }}>
                                {states.length === 0 && <Typography variant="body1" sx={{ color: 'gray' }}>No States selected</Typography>}
                                {states.map((state, index) => {
                                    return (
                                        <Chip key={index} label={state.name} onDelete={() => handleChipDelete(state)} sx={{ mr: 1, mb: 1 }} color="primary" />
                                    );
                                })}
                            </Box>
                            <Typography variant="h6">
                                Select States
                            </Typography>
                        </Box>
                        <FormGroup>
                            <Grid container rowSpacing={-1} columnSpacing={-1}>
                                {props.stateList.map((state, index) => {
                                    return (
                                        <Grid item xs={3} key={index}>
                                            <FormControlLabel sx={{ height: 25 }} control={<Checkbox size='small' checked={states.find((s) => s.name === state.name) ? true : false} onChange={handleCheckBoxChange} name={state.name} />} label={state.name} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </FormGroup>
                        <SaveButton onClick={handleSave} disabled={JSON.stringify(states) === JSON.stringify(originalStates)} /> 
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

export default States;