import React from 'react';
//import axios from 'axios';
//import {APIURL} from '../../config';
import {Procedure} from './Procedure/Procedure.js';
import Logs from './Logs/Logs_v2.js';
import Invoices from './Invoices/Invoices';
import Reports from '../Reports/Reports';
import Settings from './Settings/Settings';
import MonthlyMonitering from './MonthlyMonitering/MonthlyMonitering';
import EVV_NJ_RESEND from './Resend/EVV_NJ_RESEND'
import EVV_NJ_SUBMISSION_REVIEW from './evv_submission_review.js'
//import moment from 'moment';
import {Tab, Row, Col, Nav} from 'react-bootstrap';

class EvvV1 extends React.Component {
	constructor(props) {
		super(props);
		// key = [(0: Logs), (1: Procedure), (2: Invoices)]
		//this.state = {key: 0}; // By default, EVV Logs are shown.
		this.handleClickLogs = this.handleClickLogs.bind(this);
		this.handleClickProcedure = this.handleClickProcedure.bind(this);
		this.handleClickInvoices = this.handleClickInvoices.bind(this);
	}

    state = {
        key: 0,
    }

	// Handles the onClick event on each of the SideNavBar Nav.Links [(0: Logs), (1: Procedure), (2: Invoices)]
	handleClickLogs = () => {
		this.setState({key: 0});
	};

	handleClickProcedure = () => {
		this.setState({key: 1});
	};

	handleClickInvoices = () => {
		this.setState({key: 2});
	};

	handleClickReports = () => {
		this.setState({key: 3});
	};
	handleClickSettings = () => {
		this.setState({key: 4});
	};
	handleClickMonitering = () => {
		this.setState({key: 5});
	};
  handleClickResend = () => {
		this.setState({key: 6});
	};
	handleClickReview = () => {
		this.setState({key: 7});
	};
	render() {
		// SideNavBar Logic for: Logs | Procedure | Invoices 
		let renderComponent = '';
		if (this.state.key === 0) {
			renderComponent = <Logs checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
		} else if (this.state.key === 1) {
			renderComponent = <Procedure checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />
		} else if (this.state.key === 2) {
			renderComponent = <Invoices checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
		} else if (this.state.key === 3) {
			renderComponent = <Reports {...this.props} source={"fromEVV"} checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
		} else if (this.state.key === 4) {
			renderComponent = <Settings comp_id={this.props.comp_id}/>;
		} else if (this.state.key === 5) {
			renderComponent = <MonthlyMonitering user={this.props.user} task_id={3000281}/>;
		} else if (this.state.key === 6) {
			renderComponent = <EVV_NJ_RESEND user={this.props.user}/>;
		} else if (this.state.key === 7) {
			renderComponent = <EVV_NJ_SUBMISSION_REVIEW user={this.props.user}/>;
		} else {
			renderComponent = '';
		}

		return (
			<div>
				<div>
					<Row>
						{/* Left Sub-Navbar Linking to other EVV pages. */}
						<Col lg={3} md={4} sm={6} style={{maxWidth: '300px'}}>
							<Tab.Container>
								<Nav className='flex-column'>
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{outline: 'none'}} onClick={this.handleClickLogs}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-paperclip'
												></i>
											</span>
											EVV Activity
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{outline: 'none'}} onClick={this.handleClickProcedure}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-clipboard-list'
												></i>
											</span>
											EVV Procedure
										</Nav.Link>
									</Nav.Item>
									{/* <Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{outline: 'none'}} onClick={this.handleClickInvoices}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-receipt'
												></i>
											</span>
											EVV Invoice
										</Nav.Link>
									</Nav.Item> */}
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{outline: 'none'}} onClick={this.handleClickReports}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-receipt'
												></i>
											</span>
											EVV Reports
										</Nav.Link>
									</Nav.Item>
                                    <Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{outline: 'none'}} onClick={this.handleClickResend}>
											<span>
                                                <i
													style={{
														fontSize: '1.5rem',
														paddingRight: '17px',
														paddingLeft: '0px',
													}}
													className='fas fa-envelope'
												></i>
											</span>
											EVV Resend
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{outline: 'none'}} onClick={this.handleClickReview}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-clipboard-list'
												></i>
											</span>
											EVV Submission Review
										</Nav.Link>
									</Nav.Item>
									{/*<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{outline: 'none'}} onClick={this.handleClickSettings}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-receipt'
												></i>
											</span>
											Settings Form
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link className='list-group-item list-group-item-action' style={{outline: 'none'}} onClick={this.handleClickMonitering}>
											<span>
												<i
													style={{
														fontSize: '1.5rem',
														paddingRight: '23px',
														paddingLeft: '0px',
													}}
													className='fas fa-receipt'
												></i>
											</span>
											Monthly Monitering
										</Nav.Link>
                                    </Nav.Item>*/}
								</Nav>
							</Tab.Container>
						</Col>
						{/* EVV Logs/Procedure/Invoices Render */}
						<Col>{renderComponent}</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default EvvV1;