import React, { useState, useEffect } from "react"
import { Form, Button } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import withStyles from "@mui/styles/withStyles";
import { APIURL } from "../../../config";
import TextField from "@mui/material/TextField";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Dropzone from 'react-dropzone'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import "./UploadForm.css";


const CssTextField = withStyles({
    root: {
      "& label.Mui-focused": {
        color: "#3FA7D6",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#3FA7D6",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "lightblue",
        },
        "&:hover fieldset": {
          borderColor: "#3FA7D6",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#3FA7D6",
        },
      },
    },
  })(TextField);

/*
~~~ props ~~~
note_id
mode
user
onSubmit
com_id
*/
export default function UplaodForm(props){

    const [customerList, setCustomerList] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [projectList, setProjectList] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [taskList, setTaskList] = useState([]);
    const [selectedTask, setSelectedTask] = useState(null);
    const [file, setFile] = useState(null);

    const [errorAlert, setErrorAlert] = useState('')
    const handleErrorAlertClose =  (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorAlert('')
    }

    const [successAlert, setSuccessAlert] = useState('')
    const handleSuccessAlertClose =  (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessAlert('')
    }

    useEffect(() => {
        let mounted = true;
        console.log(props.customer_id);
        const fetchData = async () => {
            // Fetch Data Depending on Case
            if(props.tas_id){
                //TODO IF NEEDED
            }
            else if(props.project_id){
                const response = await axios.get(APIURL + `/project?proj_id=${props.project_id}&comp_id=${props.company_id}`)
                
                console.log(response.data)
                const customerResponse = await axios.get(APIURL + `/customers?com_id=${props.company_id}&cust_id=${response.data.pro_customer_id}`)
                setSelectedCustomer(customerResponse.data[0])

                setSelectedProject(response.data)
            }
            else if(props.customer_id){
                const customerResponse = await axios.get(APIURL + `/customers?com_id=${props.company_id}&cust_id=${props.customer_id}`)
                console.log(customerResponse.data[0])
                setSelectedCustomer(customerResponse.data[0])
            } else {
                try{
                    const response = await axios.get(APIURL + `/customers/assigned?emp_id=${props.user.emp_id}`)
                    setCustomerList(response.data.customers)
                }
                catch(err){
                    setErrorAlert('An error occured while loading customers')
                }
            }
        }
        fetchData();
        return () => { mounted = false };
    }, []);

    useEffect(async() => {
        setSelectedProject(null)
        setProjectList([])
        if(selectedCustomer != null){
            await getCustomerProjects()
        }
    }, [selectedCustomer]);

    useEffect(async() => {
        setSelectedTask(null)
        setTaskList([])
        if(selectedProject != null){
            await getProjectTasks()
        }
    }, [selectedProject]);

    const getCustomerProjects = async() => {
        try{
            const response = await axios.get(APIURL + `/project?cus_id=${selectedCustomer.cus_id}`)
            setProjectList(response.data)
        }
        catch(err){
            console.log(err)
            setErrorAlert(`An error while loading projects for the selected customer`)
        } 
    }

    const getProjectTasks = async() => {
        try{
            console.log(selectedProject.pro_com_id)
            const response = await axios.get(APIURL + `/task?pro_id=${selectedProject.pro_id}`)
            setTaskList(response.data)
        }
        catch(err){
            console.log(err)
            setErrorAlert(`An error while loading tasks for the selected project`)
        } 
    }

    const createArtifact = async() => {
        console.log(selectedCustomer, file);
        if(selectedCustomer && file){
            console.log(file.name.replace(/(\.[^/.]+)+$/, ""));
            let formData = new FormData();

            formData.append("file_name", file.name.replace(/(\.[^/.]+)+$/, ""));
            formData.append("directory_id", null);
            formData.append("cus_id", selectedCustomer.cus_id);
            formData.append("proj_id", selectedProject ? selectedProject.pro_id : null);
            formData.append("task_id", selectedTask ? selectedTask.tas_id : null);
            formData.append("emp_id", props.user.emp_id);
            formData.append("snapshot", file, file.name);
    
            await axios.post(APIURL+"/artifacts", formData, { headers: { "content-type": "multipart/form-data" }})
            .then(function (response) {
                setSuccessAlert('File successfully uploaded')
            })
            .catch(function (error) {
                console.log(error);
                setErrorAlert('An error occured while trying to upload the file')
            });
        } else  {
            setErrorAlert(`A selected customer and file is required to upload a file`)
        }

        props.onSubmit()
    }

    const style = {
        maxWidth: '100%',
        margin: '0px 5px 20px 5px',
    }

    return (
        <div className='noteForm-mainDiv'>
            <h4>Upload File</h4>
            <br></br>
            <div>
                <Dropzone onDrop={acceptedFiles => setFile(acceptedFiles[0])}>
                    {({getRootProps, getInputProps}) => (
                        <Box className="upload-area" {...getRootProps({style})}>
                            <Typography variant="subtitle1">{ file ? file.name : "Drop file here or click to upload" }</Typography>
                            <input type="file" {...getInputProps()} />
                        </Box>
                    )}
                </Dropzone>
                <Autocomplete
                    noOptionsText='Loading Customers...'
                    disabled={props.mode === 'edit'}
                    style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                    value={selectedCustomer}
                    onChange={async(e, value, reason) => {
                        console.log(value);
                        if(reason == 'clear'){
                            setSelectedCustomer(null);
                        }
                        else if (value) {
                            setSelectedCustomer(value);
                        }
                    }}
                    options={customerList}
                    getOptionLabel={(option) => option.cus_name}
                    renderInput={(params) => (
                        <CssTextField {...params} label="Customer" variant="outlined" />
                    )}
                />
                <Autocomplete
                    noOptionsText='Loading Projects...'
                    disabled={props.mode === 'edit' || selectedCustomer == null}
                    style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                    value={selectedProject}
                    onChange={(e, value, reason) => {
                        if(reason == 'clear'){
                            setSelectedProject(null);
                        }
                        else if (value) {
                            setSelectedProject(value);
                        }
                    }}
                    options={projectList}
                    getOptionLabel={(option) => option.pro_title}
                    renderInput={(params) => (
                        <CssTextField {...params} label="Project" variant="outlined" />
                    )}
                />
                <Autocomplete
                    noOptionsText='No Tasks Found'
                    disabled={props.mode === 'edit' || selectedProject== null}
                    style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                    value={selectedTask}
                    onChange={(e, value, reason) => {
                        if(reason == 'clear'){
                            setSelectedTask(null);
                        }
                        else if (value) {
                            setSelectedTask(value);
                        }
                    }}
                    options={taskList}
                    getOptionLabel={(option) => option.tas_title}
                    renderInput={(params) => (
                        <CssTextField {...params} label="Task" variant="outlined" />
                    )}
                />
                <Button
                    style={{
                        width: '100%',
                        padding: '5px',
                        margin: '5px',
                    }}
                    onClick={createArtifact}
                >
                    Upload File
                </Button>
            </div>
            <Snackbar open={successAlert != ''} autoHideDuration={30000} onClose={handleSuccessAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"success"} elevation={6} variant="filled" {...props}>
                    {successAlert}
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.25 }}
                    onClick={handleSuccessAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
            <Snackbar open={errorAlert != ''} autoHideDuration={30000} onClose={handleErrorAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"error"} elevation={6} variant="filled" {...props}>
                    {errorAlert}
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.25 }}
                    onClick={handleErrorAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
        </div>
    );
}