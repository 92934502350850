import react from 'react';
import { Box, Paper } from '@mui/material';

const SubSection = ({ children }) => {
    return (
        <Box>
            <Paper sx={{ p: 4 }}>
                {children}
            </Paper>
        </Box>
    );
}

export default SubSection;