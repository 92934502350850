import React, { useState, useEffect } from "react";
import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import ClearIcon from '@mui/icons-material/Clear';
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function MTSummaryReport(props) {
    // const [gridId, setGridId] = useState(57);
    // const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [reportDetails, setReportDetails] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports")
            .then((response) => {
                const report = response?.data?.reports.find((ele) => ele.report_name === "MT Summary Report");
                setReportDetails(report);
                setIsFavorite(report ? (report.status === 1 ? true : false) : false);
            });

        axios
            .get(APIURL + "/reports/getMTSummary", {
                params: { com_id: props.user.comp_id },
            })
            .then((response) => {
                setData(response?.data.length !== 0 ? response?.data : []);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const columns = [
        { field: "cus_id", headerName: "Customer ID", hide: true },
        { field: "cus_name", headerName: "Customer Name", width: 200 },
        { field: "sc_name", headerName: "Support Coordinator", width: 200 },
        {
            field: "current_month",
            headerName: moment().format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.current_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.current_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.current_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_1_month",
            headerName: moment().subtract(1, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_1_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_1_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_1_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_2_month",
            headerName: moment().subtract(2, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_2_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_2_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_2_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_3_month",
            headerName: moment().subtract(3, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_3_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_3_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_3_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_4_month",
            headerName: moment().subtract(4, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_4_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_4_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_4_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_5_month",
            headerName: moment().subtract(5, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_5_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_5_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_5_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_6_month",
            headerName: moment().subtract(6, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_6_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_6_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_6_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_7_month",
            headerName: moment().subtract(7, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_7_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_7_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_7_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_8_month",
            headerName: moment().subtract(8, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_8_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_8_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_8_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_9_month",
            headerName: moment().subtract(9, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_9_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_9_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_9_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_10_month",
            headerName: moment().subtract(10, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_10_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_10_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_10_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_11_month",
            headerName: moment().subtract(11, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_11_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_11_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_11_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
    ];

    let rows = data.map((data, idx) => {
        let taskData = {
            cus_id: data.cus_id,
            cus_name: data.cus_name,
            sc_name: data.emp_first_name ? data.emp_first_name + ' ' + data.emp_last_name : '',
            current_month: data.MTData[moment().format("MMM YYYY")],
            previous_1_month: data.MTData[moment().subtract(1, "month").format("MMM YYYY")],
            previous_2_month: data.MTData[moment().subtract(2, "month").format("MMM YYYY")],
            previous_3_month: data.MTData[moment().subtract(3, "month").format("MMM YYYY")],
            previous_4_month: data.MTData[moment().subtract(4, "month").format("MMM YYYY")],
            previous_5_month: data.MTData[moment().subtract(5, "month").format("MMM YYYY")],
            previous_6_month: data.MTData[moment().subtract(6, "month").format("MMM YYYY")],
            previous_7_month: data.MTData[moment().subtract(7, "month").format("MMM YYYY")],
            previous_8_month: data.MTData[moment().subtract(8, "month").format("MMM YYYY")],
            previous_9_month: data.MTData[moment().subtract(9, "month").format("MMM YYYY")],
            previous_10_month: data.MTData[moment().subtract(10, "month").format("MMM YYYY")],
            previous_11_month: data.MTData[moment().subtract(11, "month").format("MMM YYYY")],
        };
        return taskData;
    });

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ margin: 10, width: '75vw' }}>
                {rows.length === 0 ?
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ height: "500px", marginTop: 10 }}
                    >
                        <img
                            src="./nosearchresult.png"
                            alt="nosearchresult"
                            style={{ width: "25rem", height: "15rem", margin: 10 }}
                        />
                        <h5
                            style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
                            className="text-center"
                        >
                            There is currently no data available{" "}
                        </h5>
                    </div>
                    :
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div />
                            <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                                <img
                                    src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                                    alt="barchart"
                                    style={{
                                        fontFamily: "Lato",
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        cursor: "pointer",
                                        margin: 5
                                    }}
                                    onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                                />
                            </Tip>
                        </div>
                        <div style={{ height: '80vh' }}>
                            <DataGridPro
                                // gridId={gridId}
                                // user={props.user}
                                initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
                                // apiRef={apiRef}
                                getRowId={(row) => row.cus_id}
                                rows={rows}
                                columns={columns}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                checkboxSelection={false}
                                disableSelectionOnClick
                            />
                        </div>
                    </div>
                }

                <Snackbar
                    open={successText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setSuccessText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"success"}
                        elevation={6}
                        variant="filled"
                    >
                        {successText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setSuccessText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={errorText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setErrorText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"error"}
                        elevation={6}
                        variant="filled"
                    >
                        {errorText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setErrorText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
            </div>
        );
    };
}