import { useState, useEffect, memo } from "react";
import { Select, MenuItem, FormControl, TextField, IconButton, InputAdornment } from "@mui/material";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import moment from "moment";
import lodash from "lodash";

const UnknownDatePicker = (props) => {
    const [selectValue, setSelectValue] = useState(null);
    const [textValue, setTextValue] = useState(null);
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [autoFocus, setAutoFocus] = useState(false);
    const [read, setRead] = useState(true);

    useEffect(() => {
        if(props.value === "Unknown" || props.value === "unknown") {
            setSelectValue("unknown");
            setTextValue(null);
        } else {
            if(props.value !== null && props.value !== undefined && props.value !== "") {
                setSelectValue("known");
                setTextValue(props.value );
            } else {
                setSelectValue(null);
            }
        }
    }, [props.value]);

    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    useEffect(() => {
        setRead(props.read);
    }, [props.read]);

    useEffect(() => {
        if(read !== true) {
            if(props.disabled === true) {
                setTextValue(null);
                setSelectValue(null);
                if(props.onChange !== undefined) props.onChange(null);
                if(!isValid()) setError(false);

            } else if(!isPropValid(props.value)) {
                if(props.dependentValue)
                    props.onChange(moment(props.dependentValue).isValid() ? moment(props.dependentValue).format("MM/DD/YYYY") : props.dependentValue)
                setError(true);
            }
        }
    }, [props.disabled]);

    useEffect(() => {
        if(props.setSectionError !== undefined && props.section !== undefined) 
            error ? props.setSectionError((prev) => { 
                let newPrev = [...prev];
                newPrev[props.section].push('unknown');
                return newPrev;
            }) : 
            props.setSectionError((prev) => {
                let newPrev = [...prev];
                const index = newPrev[props.section].indexOf('unknown');
                if (index > -1) {
                    newPrev[props.section].splice(index, 1);
                }
                return newPrev;
            });
    }, [error]);

    useEffect(() => {
        if(!props.disabled) 
            setError(!isValid());
    }, [selectValue, textValue, props.validate]);

    const isValid = () => {
        if(selectValue === null) {
            return false;
        } else if(selectValue === "known") {
            if(textValue === null || textValue === "" || textValue === undefined) {
                return false;
            } else {
                return true;
            }
        } else if(selectValue === "unknown") {
            return true;
        }

        return false;
    }

    const isPropValid = (value) => {
        if(value === null) {
            return false;
        } if(value === "unknown") {
            return true;
        } else {
            if(value === null || value === "" || value === undefined) {
                return false;
            } else {
                return true;
            }
        }
    }

    const onSelectChange = (e) => {
        setSelectValue(e.target.value);
        if(e.target.value === "unknown") {
            props.onChange("Unknown");
            setAutoFocus(false);
        } else {
            setAutoFocus(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleOpen = () => {
        setOpen(true);
    };

    const render = () => {
        if(props.value === undefined) {
            return <></>
        }
        if(selectValue === "known") {
            return (
                <TextField
                    error={error && props.validate && !props.disabled}
                    label={props.label}
                    multiline
                    minRows={1}
                    maxRows={20}
                    helperText={props.helperText}
                    value={textValue}
                    onChange={e => setTextValue(e.target.value)}
                    onBlur={ e => props.onChange(moment(e.target.value).isValid() ? moment(e.target.value).format("MM/DD/YYYY") : e.target.value)}
                    variant="standard"
                    sx={{...props.sx}}
                    inputProps={{...props.inputProps}}
                    disabled={props.disabled}
                    placeholder={props.placeholder}
                    inputRef={input => (autoFocus && input) && input.focus()}
                    onFocus={() => setAutoFocus(false)}
                    InputProps={{
                        endAdornment: 
                            <InputAdornment position="end">
                                <ArrowDropDown sx={{ marginTop: "2px", cursor: "pointer", color: "rgba(0, 0, 0, 0.54)" }} size="small" onClick={() => {
                                    setSelectValue(null); 
                                    setTextValue(null); 
                                    props.onChange(null);
                                    setOpen(true);
                                }}/>
                            </InputAdornment>
                    }}
                />
            )
        }

        return (
            <FormControl error={props.error} sx={{...props.sx}}>
                <Select 
                    error={error && props.validate}
                    inputProps={{...props.inputProps}}
                    value={selectValue}
                    variant="standard" 
                    onChange={onSelectChange} 
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    open={open}
                >
                    <MenuItem key={0} value={"known"}>Known</MenuItem>
                    <MenuItem key={1} value={"unknown"}>Unknown</MenuItem>
                </Select>
            </FormControl> 
        )
    }

    return render();

}

export default memo(UnknownDatePicker,
    (prevProps, nextProps) => {
        if(lodash.isEqual(prevProps.sx, nextProps.sx) && lodash.isEqual(prevProps.inputProps, nextProps.inputProps) && lodash.isEqual(prevProps.value, nextProps.value) && lodash.isEqual(prevProps.disabled, nextProps.disabled) 
        && prevProps.onChange == nextProps.onChange && lodash.isEqual(prevProps.placeholder, nextProps.placeholder) && lodash.isEqual(prevProps.options, nextProps.options)
        && lodash.isEqual(prevProps.error, nextProps.error) && lodash.isEqual(prevProps.validate, nextProps.validate) && lodash.isEqual(prevProps.validQuestions, nextProps.validQuestions) && lodash.isEqual(prevProps.setValidQuestions, nextProps.setValidQuestions) && lodash.isEqual(prevProps.setSectionError, nextProps.setSectionError) && lodash.isEqual(prevProps.section, nextProps.section)) {
            return true;
        } else {
            return false;
        }
    }
);
