import React, { Component } from 'react'
import moment from 'moment'
import Calendar from 'react-calendar'
import TopAppointmentLeftPane from './TopAppointmentsLeftPane'
import 'react-calendar/dist/Calendar.css'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


class AppointmentCalendar extends Component {
    constructor(props) {
        super(props)
        this.tileContent = this.tileContent.bind(this)
        this.state = {
            dates: [],
            toggle: true
        }
        this.toggle = this.toggle.bind(this)
    }
    componentDidMount() {
        this.setState({
            dates: this.props.dates ? this.props.dates : []
        })
    }
    componentDidUpdate(prevProps, _) {
        if (prevProps.dates !== this.props.dates && this.props.dates) {
            this.setState({
                dates: this.props.dates
            })
        }
    }
    tileContent({ activeStartDate, date, view }) {
        if (this.state.dates && this.state.dates.includes(date)) {
            return <div>Selection</div>
        }
    }
    toggle(value) {
        this.setState({
            toggle: value === 'true' ? true : false
        })
    }
    render() {
        return (
            <div className='calendar'>
                <div className='calendar_title'>
                    Calendar
                    <div className='calendar_toggle'>
                        <div>
                            <ToggleButtonGroup
                            color="primary"
                            value={this.state.toggle}
                            exclusive
                            onChange={(event, value) => this.toggle(event.target.value)}
                            aria-label="Platform"
                            >
                                <ToggleButton style={{ 'height': '10px', marginTop: '3px' }} value={true}>Monthly</ToggleButton>
                                <ToggleButton style={{ 'height': '10px', marginTop: '3px' }} value={false}>Daily</ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                    </div>
                </div>
                <div style={{ display: !this.state.toggle ? 'none' : 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Calendar
                        calendarType='US'
                        className='calendar_component'
                        tileClassName={({ date, view }) => {
                            if (this.state.dates.find(x => x === moment(date).format("YYYY-MM-DD"))) {
                                return 'highlight'
                            }
                            if (moment(date).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
                                return 'today'
                            }
                        }}
                    />
                </div>
                <div style={{ display: !this.state.toggle ? 'block' : 'none', height: '290px', overflow: 'auto', color: 'rgba(0,0,0,0.7)' }}>
                    <TopAppointmentLeftPane
                        appointments={this.props.appointments}
                        selectedDay={this.props.selectedDay}
                        selectedAppointment={this.props.selectedAppointment}
                        selectAppointment={this.props.selectAppointment}
                    />
                </div>
            </div>
        )
    }
}

export default AppointmentCalendar
