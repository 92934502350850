import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import moment from "moment";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { TextField, Button, Select, FormControl, MenuItem, InputLabel, Box } from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import './Authorization.css';

export default function ServiceAuthorizationReport(props) {
    const [isLoading, setLoading] = useState(true);
    const [preAuthList, setPreAuthList] = useState([]);
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
    const [currentMonthEnd, setCurrMonthEnd] = useState(moment().endOf("month"));

    function onDateChange(newValue) {
        setCurrMonth(moment(newValue).startOf("month"));
        setCurrMonthEnd(moment(newValue).endOf("month"));
    }

    useEffect(() => {
        async function fetchData() {
            let preAuth = await axios.get(APIURL + "/reports/getPreAuth", {
                params: { companyID: props.comp_id, custID: props.cus_id },
            });

            let preAuthData = preAuth.data;
            let id_num = 0;
            for (var i = 0; i < preAuthData.length; i++) {
                preAuthData[i].id = id_num;
                id_num += 1;
            }

            setPreAuthList(preAuthData)
            setLoading(false);
        }
        fetchData();
    }, []);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 50,
            hide: true,
        },
        {
            field: "DDDID",
            headerName: "DDDID",
            width: 80,
            hide: true,
            editable: false,
        },
        {
            field: "PatientName",
            headerName: "Patient Name",
            width: 150,
            hide: true,
            editable: false,
        },
        {
            field: "DateOfBirth",
            headerName: "DOB",
            width: 100,
            hide: true,
            editable: false,
        },
        {
            field: "project_title",
            headerName: "Project Title",
            width: 100,
            hide: true,
            editable: false,
        },
        {
            field: "memberMedicaid",
            headerName: "Medicaid ID",
            width: 120,
            hide: true,
            editable: false,
        },
        {
            field: "priorAuth",
            headerName: "Prior Auth",
            width: 110,
            hide: false,
            editable: false,
        },
        {
            field: "procedureCode",
            headerName: "Proc Code",
            width: 100,
            hide: false,
        },
        {
            field: "DiagnosisCode",
            headerName: "Diagnosis Code",
            width: 120,
            hide: false,
            editable: false,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Diagnosis Code
            //         </div>
            //     );
            // },
        },
        {
            field: "startDate",
            headerName: "Task Start Date",
            width: 120,
            hide: false,
            editable: false,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Task Start Date
            //         </div>
            //     );
            // },
        },
        {
            field: "endDate",
            headerName: "Task End Date",
            width: 120,
            hide: false,
            editable: false,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Task End Date
            //         </div>
            //     );
            // },
        },
        {
            field: "authUnits",
            headerName: "Auth Units",
            width: 100,
            hide: props.user.type === 'Admin' ? false : true,
            editable: false,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Auth Units
            //         </div>
            //     );
            // },
        },
        {
            field: "authAmount",
            headerName: "Auth Amount",
            width: 100,
            hide: props.user.type === 'Admin' ? false : true,
            editable: false,
            align: "right",
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Auth Amount
            //         </div>
            //     );
            // },
        },
        {
            field: "taskStatus",
            headerName: "Status",
            width: 120,
            hide: true,
        },
        {
            field: "tas_ids",
            headerName: "Task IDs",
            width: 120,
            hide: true,
        },
        {
            field: "cumulativeUnits",
            headerName: "Units used",
            width: 100,
            hide: props.user.type === 'Admin' ? false : true,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Units used
            //         </div>
            //     );
            // },
        },
        {
            field: "cumulativeMinutes",
            headerName: "Minutes spent",
            width: 100,
            hide: true,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Minutes spent
            //         </div>
            //     );
            // },
        },
        {
            field: "remainingUnits",
            headerName: "Units remaining",
            width: 120,
            hide: props.user.type === 'Admin' ? false : true,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Units remaining
            //         </div>
            //     );
            // },
        },
        {
            field: "totalRemainingUnits",
            headerName: "Total Units remaining",
            width: 160,
            hide: props.user.type === 'Admin' ? false : true,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Total Units remaining
            //         </div>
            //     );
            // },
        },
    ]

    let filteredPreAuth = preAuthList.filter(
        (claim) =>
            (claim.startDate &&
                moment(claim.startDate).utc().month() === currentMonth.month() &&
                moment(claim.startDate).utc().year() === currentMonth.year()) ||
            (!claim.startDate &&
                (moment(claim.tas_start_date).utc().month() === currentMonth.month() &&
                    moment(claim.tas_start_date).utc().year() === currentMonth.year()))
    );

    let rows = filteredPreAuth.map((claim) => {
        let claimDets = {
            id: claim.id,
            DDDID: Array.isArray(claim.DDDID) ? claim.DDDID[0] : claim.DDDID,
            PatientName: claim.cus_name,
            DateOfBirth: !!claim.dob ? moment(claim.dob).utc().format("L") : '',
            project_title: claim.pro_title,
            memberMedicaid: claim.memberMedicaid,
            priorAuth: claim.priorAuth,
            procedureCode: claim.procedureCode,
            startDate: moment(claim.startDate).utc().format("L"),
            endDate: moment(claim.endDate).utc().format("L"),
            DiagnosisCode: claim.PrimaryDiagnosisCode,
            authUnits: claim.authUnits,
            authAmount: claim.authAmount.toFixed(2),
            errCode: claim.errCode === 'None' ? '' : claim.errCode,
            errDescription: claim.errDescription === 'Null' ? '' : claim.errDescription,
            taskStatus: claim.taskIds ? 'Tasks Matched' : '',
            tas_ids: claim.taskIds,
            cumulativeUnits: Math.round(claim.cumulativeUnits),
            cumulativeMinutes: claim.cumulativeMinutes,
            remainingUnits: claim.authUnits - Math.round(claim.cumulativeUnits),
            totalRemainingUnits: Math.round(claim.remainingUnits),
        };
        return claimDets;
    });

    return (
        <div id="claims-main">
            <div className="flex-container" style={{ display: "flex" }} >
                <div
                    className="flex-item"
                    style={{
                        flexDirection: "row-reverse",
                        height: "40px",
                        marginBottom: "25px",
                    }}
                >
                    <Button
                        size="large"
                        className="DayButton"
                        onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                        sx={{ backgroundColor: '#007BFF !important' }}
                    >
                        <i className="fas fa-arrow-left"></i>
                    </Button>
                    <LocalizationProvider
                        class="DatePickerParent"
                        dateAdapter={AdapterDateFns}
                    >
                        <DatePicker
                            label="Select Date"
                            views={["year", "month"]}
                            value={currentMonth}
                            onChange={(newValue) => {
                                this.onDateChange(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="standard" />
                            )}
                        />
                    </LocalizationProvider>
                    <Button
                        size="large"
                        className="DayButton"
                        onClick={() => onDateChange(currentMonth.add(1, "month"))}
                        sx={{ backgroundColor: '#007BFF !important' }}
                    >
                        <i className="fas fa-arrow-right"></i>
                    </Button>
                </div>
            </div>
            <div className='tasks_info'>
                <div style={{ marginBottom: '10px' }}>
                    <h5>Service Authorization</h5>
                </div>
                {!!isLoading &&
                    <div className='d-flex flex-row w-100 justify-content-center mt-5 ignore_notes_info'>
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!isLoading && <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '100%', height: "calc(100vh - 340px)", background: 'white' }}>
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            checkboxSelection={false}
                            disableSelectionOnClick
                            width={"100%"}
                            justifyContent="center"
                            components={{
                                Toolbar: GridToolbar,
                            }}
                        />
                    </Box>
                </div>}
            </div>
        </div>
    )
}