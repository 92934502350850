import * as React from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import moment from "moment";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import ClearIcon from '@mui/icons-material/Clear';
import './reports.css';

export default function MTSummaryReport(props) {
    // const [gridId, setGridId] = useState(57);
    // const apiRef = useGridApiRef();

    const columns = [
        { field: "cus_id", headerName: "Customer ID", hide: true },
        { field: "cus_name", headerName: "Customer Name", width: 200 },
        { field: "sc_name", headerName: "Support Coordinator", width: 200 },
        {
            field: "current_month",
            headerName: moment().format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.current_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.current_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.current_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_1_month",
            headerName: moment().subtract(1, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_1_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_1_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_1_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_2_month",
            headerName: moment().subtract(2, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_2_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_2_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_2_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_3_month",
            headerName: moment().subtract(3, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_3_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_3_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_3_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_4_month",
            headerName: moment().subtract(4, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_4_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_4_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_4_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_5_month",
            headerName: moment().subtract(5, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_5_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_5_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_5_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_6_month",
            headerName: moment().subtract(6, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_6_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_6_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_6_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_7_month",
            headerName: moment().subtract(7, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_7_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_7_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_7_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_8_month",
            headerName: moment().subtract(8, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_8_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_8_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_8_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_9_month",
            headerName: moment().subtract(9, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_9_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_9_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_9_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_10_month",
            headerName: moment().subtract(10, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_10_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_10_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_10_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
        {
            field: "previous_11_month",
            headerName: moment().subtract(11, "month").startOf("month").format("MMM YYYY"),
            width: 130,
            renderCell: (params) => {
                if (params.row.previous_11_month) {
                    return (
                        <div className="d-flex flex-row m-1 mt-2" >
                            {<div className="mtDate">
                                {moment(params.row.previous_11_month[0].created_on).format('MM-DD-YYYY')}
                            </div>}
                            {<div className="mtTypeIcon">
                                {params.row.previous_11_month[0].mt_type[0]}
                            </div>}
                        </div >
                    );
                }
                else {
                    return (
                        <ClearIcon className="mtClearIcon" />
                    );
                }
            }
        },
    ];

    let rows = props?.data.map((data, idx) => {
        let taskData = {
            cus_id: data.cus_id,
            cus_name: data.cus_name,
            sc_name: data.emp_first_name ? data.emp_first_name + ' ' + data.emp_last_name : '',
            current_month: data.MTData[moment().format("MMM YYYY")],
            previous_1_month: data.MTData[moment().subtract(1, "month").format("MMM YYYY")],
            previous_2_month: data.MTData[moment().subtract(2, "month").format("MMM YYYY")],
            previous_3_month: data.MTData[moment().subtract(3, "month").format("MMM YYYY")],
            previous_4_month: data.MTData[moment().subtract(4, "month").format("MMM YYYY")],
            previous_5_month: data.MTData[moment().subtract(5, "month").format("MMM YYYY")],
            previous_6_month: data.MTData[moment().subtract(6, "month").format("MMM YYYY")],
            previous_7_month: data.MTData[moment().subtract(7, "month").format("MMM YYYY")],
            previous_8_month: data.MTData[moment().subtract(8, "month").format("MMM YYYY")],
            previous_9_month: data.MTData[moment().subtract(9, "month").format("MMM YYYY")],
            previous_10_month: data.MTData[moment().subtract(10, "month").format("MMM YYYY")],
            previous_11_month: data.MTData[moment().subtract(11, "month").format("MMM YYYY")],
        };
        return taskData;
    });

    return (
        <div>
            {rows.length === 0 ?
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: "500px", marginTop: 10 }}
                >
                    <img
                        src="./nosearchresult.png"
                        alt="nosearchresult"
                        style={{ width: "25rem", height: "15rem", margin: 10 }}
                    />
                    <h5
                        style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
                        className="text-center"
                    >
                        There is currently no data available{" "}
                    </h5>
                </div>
                :
                <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '100%', height: "calc(100vh - 210px)" }}>
                        <DataGridPro
                            // gridId={gridId}
                            // user={props.user}
                            initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
                            // apiRef={apiRef}
                            getRowId={(row) => row.cus_id}
                            rows={rows}
                            columns={columns}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            checkboxSelection={false}
                            disableSelectionOnClick
                        />
                    </Box>
                </div>
            }
        </div>
    );
};