import React, { Component } from 'react';
import { Tabs, Tab, Form, Alert } from 'react-bootstrap';
import PersonalDetails from '../CustomerForm/PersonalDetails/PersonalDetails';
import OtherDetails from './OtherDetails/OtherDetails';
import CustomerTasks from './CustomerTasks/CustomerTasks'
import MsgModalBox from '../../../container/MessageModalBox/MsgModalBox';
import axios from 'axios';
import {APIURL,BINGAPIKEY} from '../../../config'
import moment from 'moment'
import dayjs from 'dayjs';
import dd from '../../../manifestdd.json'
import { Redirect } from 'react-router-dom';

class CustomerForm extends Component {
    state = {
        personal_details: {
            "DDDID": !this.props.edit?"":this.props.cusDets.DDDID,
            "cus_is_company": !this.props.edit? false: this.props.cusDets.cus_is_company,
            "first_name": !this.props.edit?"":(!this.props.cusDets.cus_is_company)?this.props.cusDets.cus_name.split(" ")[0]:" ",
            "last_name": !this.props.edit?"":(!this.props.cusDets.cus_is_company)?this.props.cusDets.cus_name.split(" ")[1]:" ",
            "gender": !this.props.edit || !this.props.cusDets.gender ?"M":this.props.cusDets.gender,
            "dob": !this.props.edit || !this.props.cusDets.dob?new Date ():this.props.cusDets.dob,
            "company_name": !this.props.edit?"":(!this.props.cusDets.cus_is_company)?" ":this.props.cusDets.cus_name,
            "cus_local_id": !this.props.edit?"":this.props.cusDets.cus_local_id,
            "email": !this.props.edit?"":this.props.cusDets.cus_email,
            "addressList": !this.props.edit?[{ addId: 1, address_line_1: "", address_line_2: "", city: "", state: "", zip: "", latitude: "", longitude: "" }]
            :this.props.cusDets.cus_address.split(":").map((add,index)=>{
                let addArr = add.split(",")
                if(addArr.length > 5){
                    addArr = addArr.map((data,i)=> {
                        // let addWrd = data
                        if(i > 1){
                            return data
                        }else if(i < 1){
                            return data + ',' + addArr[i+1]
                        }else{
                            return null 
                        }
                    }).filter((ele)=> !!ele)
                    // addArr[0] = addArr[0] +','+ addArr[1]
                    // addArr
                    // addArr = []
                }
                
                let location = null
                try{
                    if (addArr.length === 4) {
                        location = this.props.loc_list.find((loc)=>(
                            (
                                loc.location_addr1.trim() === addArr[0].trim() && 
                                // loc.location_addr2.trim() === addArr[1].trim() && 
                                loc.location_state.trim() === addArr[2].trim()) && (loc.location_city.trim() === addArr[1].trim()) && (loc.location_zip.trim() === addArr[3].trim())))
                    }
                    if (addArr.length === 5) {
                        location = this.props.loc_list.find((loc)=>(
                            (
                                loc.location_addr1.trim() === addArr[0].trim() && 
                                loc.location_addr2.trim() === addArr[1].trim() && 
                                loc.location_state.trim() === addArr[3].trim()) && (loc.location_city.trim() === addArr[2].trim()) && (loc.location_zip.trim() === addArr[4].trim())))
                    }
                } catch(err){
                    location = ''
                }
                
                if(!!location){
                    let addObj = {addId: ++index, address_line_1: location.location_addr1, address_line_2: location.location_addr2,city: location.location_city.trim() , state: location.location_state.trim(), zip: location.location_zip.trim(), latitude: !!location?location.location_lat:0, longitude: !!location?location.location_lon:0, disableFields: !this.props.tasklist?false:this.props.tasklist.some((task)=> ((task.tas_is_active === 0 || task.tas_is_active === 1) && task.tas_loc_id === location.id)) }
		            return addObj
                }else{
                    return null
                }
                
            }).filter((loc)=> !!loc),
            "phnNumList": !this.props.edit?[{ phnId: 1, country_code: "+1", phone: "" }] : !!this.props.cusDets.cus_phone ? this.props.cusDets.cus_phone.split(",").map((phn,index)=>{
                let phnObj = { phnId: index, country_code: phn.slice(0, 2)!=='+1'?'+1':phn.slice(0, 2), phone: phn.slice(-10) }
                return phnObj
            }) : [{ phnId: 1, country_code: "+1", phone: "" }]
        },
        other_details: {
            "acquired_date": !this.props.edit?moment().format('YYYY-MM-DDTHH:mm:ss'):moment(this.props.cusDets.cus_acquired_date).format('YYYY-MM-DDTHH:mm:ss'),
            "status": !this.props.edit?"":this.props.cusDets.cus_status,
            "customer_type": !this.props.edit?"Individual":!!this.props.cusDets.cus_type?this.props.cusDets.cus_type:'Individual',
            "pManager": !this.props.edit?"":this.props.pManagerId,
            "medicaid_id": !this.props.edit?"":this.props.cusDets.medicaid_id ? this.props.cusDets.medicaid_id  : null,
            "medicaid_eligible": !this.props.edit?"":Number(this.props.cusDets.medicaid_eligible),
            "sign_page_date": !this.props.edit?"":moment(this.props.cusDets.sign_page_date).isValid() && this.props.cusDets.sign_page_date !== undefined  ? moment(this.props.cusDets.sign_page_date).format('YYYY-MM-DD') : '',
            "last_plan_approval_date": !this.props.edit?"":moment(this.props.cusDets.last_plan_approval_date).isValid() && this.props.cusDets.last_plan_approval_date !== undefined  ? moment(this.props.cusDets.last_plan_approval_date).format('YYYY-MM-DD') : '',
            "last_assess_date": !this.props.edit?"":moment(this.props.cusDets.last_assess_date).isValid() && this.props.cusDets.last_assess_date !== undefined  ? moment(this.props.cusDets.last_assess_date).format('YYYY-MM-DD') : '',
            "renewal_date": !this.props.edit?"":moment(this.props.cusDets.renewal_date).isValid() && this.props.cusDets.renewal_date !== undefined  ? moment(this.props.cusDets.renewal_date).format('YYYY-MM-DD') : '',
            "DDD_tier": !this.props.edit?"":this.props.cusDets.DDD_tier ? this.props.cusDets.DDD_tier : null,
            "diagnose_code":!this.props.edit?"":this.props.cusDets.PrimaryDiagnosisCode ? this.props.cusDets.PrimaryDiagnosisCode : null,
            "func_criteria_met":!this.props.edit?"":Number(this.props.cusDets.func_criteria_met),
            "waiver_program":!this.props.edit?"":this.props.cusDets.waiver_program ? this.props.cusDets.waiver_program : null,
            "NJCATSelfcare":!this.props.edit?"":this.props.cusDets.NJCATSelfcare ? this.props.cusDets.NJCATSelfcare : null,
            "NJCATBehavioral":!this.props.edit?"":this.props.cusDets.NJCATBehavioral ? this.props.cusDets.NJCATBehavioral : null,
            "NJCATMedical":!this.props.edit?"":this.props.cusDets.NJCATMedical ? this.props.cusDets.NJCATMedical : null,
            "NJCATFCA":!this.props.edit?"":this.props.cusDets.NJCATFCA ? this.props.cusDets.NJCATFCA : null,
            "MedicaidEndDate": !this.props.edit?"":moment(this.props.cusDets.MedicaidEndDate).isValid() && this.props.cusDets.MedicaidEndDate !== undefined ? moment(this.props.cusDets.MedicaidEndDate).format('YYYY-MM-DD') : '',
            "AgencyAssignDate": !this.props.edit?"":moment(this.props.cusDets.AgencyAssignDate).isValid() && this.props.cusDets.AgencyAssignDate !== undefined ? moment(this.props.cusDets.AgencyAssignDate).format('YYYY-MM-DD') : '',
            "County":!this.props.edit?"":this.props.cusDets.County ? this.props.cusDets.County : null,
            "Race":!this.props.edit?"":this.props.cusDets.Race ? this.props.cusDets.Race : null,
            "Ethnicity":!this.props.edit?"":this.props.cusDets.Ethnicity ? this.props.cusDets.Ethnicity : null,
            "TransferOutReason":!this.props.edit?"":this.props.cusDets.TransferOutReason ? this.props.cusDets.TransferOutReason : null,
            "TransferOutDate":!this.props.edit?"":dayjs(this.props.cusDets.TransferOutDate).isValid() && this.props.cusDets.TransferOutDate !== undefined  ? dayjs(this.props.cusDets.TransferOutDate).format('YYYY-MM-DD') : '',
        },
        customer_tasks: {},
        key: "personal_details",
        showModal: false,
        addError: false,
        personal_details_val: false,
        other_details_val: false,
        customer_tasks_val: false,
        showAlert: false
    }

    async editCustInDB(e){
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        e.preventDefault();
        e.persist();
        var data = {
            empId: this.props.user.emp_id,
            empName: this.props.user.emp_name,
            empEmail: this.props.user.email,
            comp_id: this.props.comp_id,
            cus_id: this.props.cusDets.cus_id,
            DDDID: this.state.personal_details.DDDID,
            cus_name: (this.state.personal_details.company_name === " ") ? this.state.personal_details.first_name + " " + this.state.personal_details.last_name : this.state.personal_details.company_name,
            gender: this.state.personal_details.gender,
            dob: this.state.personal_details.dob,
            email: (this.state.personal_details.email !== 'undefined')? this.state.personal_details.email: this.props.cusDets.cus_email ,
            addList: this.state.personal_details.addressList,
            phonList: this.state.personal_details.phnNumList.map((number) => { return number.country_code + number.phone }).join(),
            status: this.state.other_details.status,
            type: this.state.other_details.customer_type,
            medicaid_id: this.state.other_details.medicaid_id,
            medicaid_eligible: this.state.other_details.medicaid_eligible,
            sign_page_date: this.state.other_details.sign_page_date,
            last_assess_date: this.state.other_details.last_assess_date,
            last_plan_approval_date: this.state.other_details.last_plan_approval_date,
            renewal_date: this.state.other_details.renewal_date,
            proId: this.props.pId,
            DDD_tier: this.state.other_details.DDD_tier,
            diagnose_code:this.state.other_details.diagnose_code,
            func_criteria_met:this.state.other_details.func_criteria_met,
            acquired_date: moment(this.state.other_details.acquired_date).format('YYYY-MM-DDTHH:mm:ss'),
            waiver_program:this.state.other_details.waiver_program,
            cus_local_id: this.state.personal_details.cus_local_id,
            NJCATSelfcare:this.state.other_details.NJCATSelfcare,
            NJCATBehavioral:this.state.other_details.NJCATBehavioral,
            NJCATMedical:this.state.other_details.NJCATMedical,
            NJCATFCA:this.state.other_details.NJCATFCA,
            MedicaidEndDate:this.state.other_details.MedicaidEndDate,
            AgencyAssignDate:this.state.other_details.AgencyAssignDate,
            County:this.state.other_details.County,
            Race:this.state.other_details.Race,
            Ethnicity:this.state.other_details.Ethnicity,
            TransferOutReason: this.state.other_details.TransferOutReason,
            TransferOutDate: this.state.other_details.TransferOutDate
        };
        let msg = await axios.put(APIURL+'/customers', data)
        if (msg.data.code === 200) {
            // debugger;
            e.target.reset();
            // this.setState({ personal_details: newState.personal_details })
            // this.setState({ other_details: newState.other_details })
            // this.props.history.push("/projects")
            this.setState({ showModal: true })
            this.setState({ addError: false })
        } else {
            // debugger
            this.setState({ personal_details: this.state.personal_details })
            this.setState({ other_details: this.state.other_details })
            this.setState({ addError: true })
            this.setState({ showModal: true })
        }
    }
    async addCustomertoDB(e) {
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        const newState = {
            personal_details: {
                "first_name": "",
                "last_name": "",
                "gender": "",
                "dob": "",
                "email": "",
                "cus_local_id":"",
                "company_name": "",
                "DDDID": "",
                "addressList": [{ addId: 1, address_line_1: "", address_line_2: "", city: "", state: "", zip: "", latitude: "", longitude: "" }],
                "phnNumList": [{ phnId: 1, country_code: "+1", phone: "" }]
            },
            other_details: {
                "acquired_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
                "status": "",
                "customer_type": "Individual",
                "pManager": "",
                "medicaid_id": "",
                "medicaid_eligible": "",
                "sign_page_date": "",
                "last_plan_approval_date": "",
                "last_assess_date": "",
                "renewal_date": "",
                "DDD_tier": "",
                "diagnose_code":"",
                "func_criteria_met":"",
                "waiver_program":"",
                "NJCATSelfcare":"",
                "NJCATBehavioral":"",
                "NJCATMedical":"",
                "NJCATFCA":"",
                "MedicaidEndDate":"",
                "AgencyAssignDate":"",
                "County":"",
                "Race":"",
                "Ethnicity":""
            }
        }
        e.preventDefault();
        e.persist();
        var data = {
            empId: this.props.user.emp_id,
            empName: this.props.user.emp_name,
            empEmail: this.props.user.email,
            company_id: this.props.comp_id,
            cus_id: this.props.edit ? this.props.cusDets.cus_id : "",
            DDDID: this.state.personal_details.DDDID,
            is_company: this.state.personal_details.cus_is_company,
            name: (!this.state.personal_details.cus_is_company) ? this.state.personal_details.first_name + " " + this.state.personal_details.last_name : this.state.personal_details.company_name,
            gender: this.state.personal_details.gender,
            dob: this.state.personal_details.dob,
            email: (this.state.personal_details.email !== 'undefined')? this.state.personal_details.email: this.props.cusDets.cus_email ,
            cus_local_id: this.state.personal_details.cus_local_id,
            address: this.state.personal_details.addressList,
            phone: this.state.personal_details.phnNumList.map((number) => { return number.country_code + number.phone }).join(),
            status: this.state.other_details.status,
            customer_type: this.state.other_details.customer_type,
            medicaid_id: this.state.other_details.medicaid_id,
            medicaid_eligible: this.state.other_details.medicaid_eligible,
            sign_page_date: this.state.other_details.sign_page_date,
            last_assess_date: this.state.other_details.last_assess_date,
            last_plan_approval_date: this.state.other_details.last_plan_approval_date,
            medicaid_end_date: this.state.other_details.renewal_date,
            proId: this.props.pId,
            DDD_tier: this.state.other_details.DDD_tier,
            diagnose_code:this.state.other_details.diagnose_code,
            func_criteria_met:this.state.other_details.func_criteria_met,
            waiver_prgm:this.state.other_details.waiver_program,
            acquired_date: this.state.other_details.acquired_date,
            NJCATSelfcare:this.state.other_details.NJCATSelfcare,
            NJCATBehavioral:this.state.other_details.NJCATBehavioral,
            NJCATMedical:this.state.other_details.NJCATMedical,
            NJCATFCA:this.state.other_details.NJCATFCA,
            MedicaidEndDate:this.state.other_details.MedicaidEndDate,
            AgencyAssignDate:this.state.other_details.AgencyAssignDate,
            County:this.state.other_details.County,
            Race:this.state.other_details.Race,
            Ethnicity:this.state.other_details.Ethnicity
        };

        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>> ', data)
        // let msg

            // if(!this.props.edit){
            //     msg = await axios.post( APIURL+"/AddCustomerWithProj", data);

            // }else{
        let msg = await axios.post( APIURL+"/customers", data);
            // }

                    //if logic to check formfield state, yes/no bullet point
            //if yes: hit this endpoint, customer/addcustomer with project
            //else normal endpoint "/"
        if (msg.data.code === 200) {
            // debugger;
            e.target.reset();
            this.setState({ personal_details: newState.personal_details })
            this.setState({ other_details: newState.other_details })
            this.setState({ showModal: true })
            this.setState({ addError: false })
            
            
            //activty log
            var sha1 = require('sha1');
            let sessionID=window.localStorage.getItem('Fw4_access_Token')
            var hash = sha1(sessionID);
            var log_data={
                email:this.props.user.email,
                actionType:'New Customer',
                actionSubType:data.name,
                sessionID:hash
            }                                                                                                                                                                                                                                                               
            msg = await axios.post(APIURL+"/activity_log",log_data );                         
                             
        } else {
            // debugger
            this.setState({ personal_details: this.state.personal_details })
            this.setState({ other_details: this.state.other_details })
            this.setState({ addError: true })
            this.setState({ showModal: true })
        }
    }

    async getlatlong(add) {
        if (add.address_line_1 !== ""
            || add.address_line_2 !== ""
            || add.city !== ""
            || add.state !== ""
            || add.country !== ""
            || add.zip !== "") {
                axios.defaults.headers = {
                    'Content-Type': 'application/json',
                } 
      
                //let geoCord = await axios.get(`https://dev.virtualearth.net/REST/v1/Locations?countryRegion=US&adminDistrict=${add.state}&locality=${add.city}&postalCode=${add.zip}&addressLine=${add.address_line_1}&key=${BINGAPIKEY}`);
    
                const instance = axios.create();
                instance.defaults.headers.common = {};
                instance.defaults.headers.common.accept = 'application/json';
                let geoCord = await instance.get(`https://dev.virtualearth.net/REST/v1/Locations?countryRegion=US&adminDistrict=${add.state}&locality=${add.city}&postalCode=${add.zip}&addressLine=${add.address_line_1}&key=${BINGAPIKEY}`);
    
            try{
                let latlongVal = geoCord.data.resourceSets[0].resources[0].point.coordinates;
                add.latitude = latlongVal[0]
                add.longitude = latlongVal[1]
            } catch(err){
                console.log(err)
            }
        }
    }

    goHome = (e) => {
        this.setState({ addError: false })
        this.setState({ showModal: false })
        this.setState({ showAlert: false })
        this.setState({ personal_details_val: false })
        this.setState({ other_details_val: false })
        this.setState({ customer_tasks_val: false })
        this.setState({goHome: true})
    }

    addNew = (e) => {
        if (this.state.addError) {
            this.setState({ showModal: false })
            this.setState({ showAlert: false })
            if(this.props.edit){this.props.history.push("/projects")}
        } else {
            this.setState({ showModal: false })
            this.setState({ showAlert: false })
            this.setState({ addError: false })
            this.setState({ personal_details_val: false })
            this.setState({ other_details_val: false })
            this.setState({ customer_tasks_val: false })
            this.setState({ key: "personal_details" })
            this.setState({ personal_details: {
                "DDDID": !this.props.edit?"":this.props.cusDets.DDDID,
                "cus_is_company": !this.props.edit? false: this.props.cusDets.cus_is_company,
                "first_name": !this.props.edit?"":(!this.props.cusDets.cus_is_company)?this.props.cusDets.cus_name.split(" ")[0]:" ",
                "last_name": !this.props.edit?"":(!this.props.cusDets.cus_is_company)?this.props.cusDets.cus_name.split(" ")[1]:" ",
                "gender": !this.props.edit || !this.props.cusDets.gender ?"M":this.props.cusDets.gender,
                "dob": !this.props.edit || !this.props.cusDets.dob?new Date ():this.props.cusDets.dob,
                "company_name": !this.props.edit?"":(!this.props.cusDets.cus_is_company)?" ":this.props.cusDets.cus_name,
                "cus_local_id": !this.props.edit?"":this.props.cusDets.cus_local_id,
                "email": !this.props.edit?"":this.props.cusDets.cus_email,
                "addressList": !this.props.edit?[{ addId: 1, address_line_1: "", address_line_2: "", city: "", state: "", zip: "", latitude: "", longitude: "" }]
                :this.props.cusDets.cus_address.split(":").map((add,index)=>{
                    let addArr = add.split(",")
                    if(addArr.length > 5){
                        addArr = addArr.map((data,i)=> {
                            // let addWrd = data
                            if(i > 1){
                                return data
                            }else if(i < 1){
                                return data + ',' + addArr[i+1]
                            }else{
                                return null 
                            }
                        }).filter((ele)=> !!ele)
                        // addArr[0] = addArr[0] +','+ addArr[1]
                        // addArr
                        // addArr = []
                    }
                    
                    let location = null
                    try{
                        if (addArr.length === 4) {
                            location = this.props.loc_list.find((loc)=>(
                                (
                                    loc.location_addr1.trim() === addArr[0].trim() && 
                                    // loc.location_addr2.trim() === addArr[1].trim() && 
                                    loc.location_state.trim() === addArr[2].trim()) && (loc.location_city.trim() === addArr[1].trim()) && (loc.location_zip.trim() === addArr[3].trim())))
                        }
                        if (addArr.length === 5) {
                            location = this.props.loc_list.find((loc)=>(
                                (
                                    loc.location_addr1.trim() === addArr[0].trim() && 
                                    loc.location_addr2.trim() === addArr[1].trim() && 
                                    loc.location_state.trim() === addArr[3].trim()) && (loc.location_city.trim() === addArr[2].trim()) && (loc.location_zip.trim() === addArr[4].trim())))
                        }
                    } catch(err){
                        location = ''
                    }
                    
                    if(!!location){
                        let addObj = {addId: ++index, address_line_1: location.location_addr1, address_line_2: location.location_addr2,city: location.location_city.trim() , state: location.location_state.trim(), zip: location.location_zip.trim(), latitude: !!location?location.location_lat:0, longitude: !!location?location.location_lon:0, disableFields: !this.props.tasklist?false:this.props.tasklist.some((task)=> ((task.tas_is_active === 0 || task.tas_is_active === 1) && task.tas_loc_id === location.id)) }
                        return addObj
                    }else{
                        return null
                    }
                    
                }).filter((loc)=> !!loc),
                "phnNumList": !this.props.edit?[{ phnId: 1, country_code: "+1", phone: "" }] : !!this.props.cusDets.cus_phone ? this.props.cusDets.cus_phone.split(",").map((phn,index)=>{
                    let phnObj = { phnId: index, country_code: phn.slice(0, 2)!=='+1'?'+1':phn.slice(0, 2), phone: phn.slice(-10) }
                    return phnObj
                }) : [{ phnId: 1, country_code: "+1", phone: "" }]
            },
            other_details: {
                "acquired_date": !this.props.edit?moment().format('YYYY-MM-DDTHH:mm:ss'):moment(this.props.cusDets.cus_acquired_date).format('YYYY-MM-DDTHH:mm:ss'),
                "status": !this.props.edit?"":this.props.cusDets.cus_status,
                "customer_type": !this.props.edit?"Individual":!!this.props.cusDets.cus_type?this.props.cusDets.cus_type:'Individual',
                "pManager": !this.props.edit?"":this.props.pManagerId,
                "medicaid_id": !this.props.edit?"":this.props.cusDets.medicaid_id ? this.props.cusDets.medicaid_id  : null,
                "medicaid_eligible": !this.props.edit?"":Number(this.props.cusDets.medicaid_eligible),
                "sign_page_date": !this.props.edit?"":moment(this.props.cusDets.sign_page_date).isValid() && this.props.cusDets.sign_page_date !== undefined  ? moment(this.props.cusDets.sign_page_date).format('YYYY-MM-DD') : '',
                "last_plan_approval_date": !this.props.edit?"":moment(this.props.cusDets.last_plan_approval_date).isValid() && this.props.cusDets.last_plan_approval_date !== undefined  ? moment(this.props.cusDets.last_plan_approval_date).format('YYYY-MM-DD') : '',
                "last_assess_date": !this.props.edit?"":moment(this.props.cusDets.last_assess_date).isValid() && this.props.cusDets.last_assess_date !== undefined  ? moment(this.props.cusDets.last_assess_date).format('YYYY-MM-DD') : '',
                "renewal_date": !this.props.edit?"":moment(this.props.cusDets.renewal_date).isValid() && this.props.cusDets.renewal_date !== undefined  ? moment(this.props.cusDets.renewal_date).format('YYYY-MM-DD') : '',
                "DDD_tier": !this.props.edit?"":this.props.cusDets.DDD_tier ? this.props.cusDets.DDD_tier : null,
                "diagnose_code":!this.props.edit?"":this.props.cusDets.PrimaryDiagnosisCode ? this.props.cusDets.PrimaryDiagnosisCode : null,
                "func_criteria_met":!this.props.edit?"":Number(this.props.cusDets.func_criteria_met),
                "waiver_program":!this.props.edit?"":this.props.cusDets.waiver_program ? this.props.cusDets.waiver_program : null,
                "NJCATSelfcare":!this.props.edit?"":this.props.cusDets.NJCATSelfcare ? this.props.cusDets.NJCATSelfcare : null,
                "NJCATBehavioral":!this.props.edit?"":this.props.cusDets.NJCATBehavioral ? this.props.cusDets.NJCATBehavioral : null,
                "NJCATMedical":!this.props.edit?"":this.props.cusDets.NJCATMedical ? this.props.cusDets.NJCATMedical : null,
                "NJCATFCA":!this.props.edit?"":this.props.cusDets.NJCATFCA ? this.props.cusDets.NJCATFCA : null,
                "MedicaidEndDate": !this.props.edit?"":moment(this.props.cusDets.MedicaidEndDate).isValid() && this.props.cusDets.MedicaidEndDate !== undefined ? moment(this.props.cusDets.MedicaidEndDate).format('YYYY-MM-DD') : '',
                "AgencyAssignDate": !this.props.edit?"":moment(this.props.cusDets.AgencyAssignDate).isValid() && this.props.cusDets.AgencyAssignDate !== undefined ? moment(this.props.cusDets.AgencyAssignDate).format('YYYY-MM-DD') : '',
                "County":!this.props.edit?"":this.props.cusDets.County ? this.props.cusDets.County : null,
                "Race":!this.props.edit?"":this.props.cusDets.Race ? this.props.cusDets.Race : null,
                "Ethnicity":!this.props.edit?"":this.props.cusDets.Ethnicity ? this.props.cusDets.Ethnicity : null,
            }, })
            // if(this.props.edit){this.props.navigationHandler(1)}
        }
    }

    onRadioChangeHandler = (rValue) => {
        const oldState = { ...this.state.personal_details }
        oldState.company_name = (rValue === "Individual")?" ":""
        oldState.first_name = (rValue === "Individual")?"":" "
        oldState.last_name = (rValue === "Individual")?"":" "
        this.setState({
            personal_details: oldState,other_details: {...this.state.other_details, customer_type: rValue}
        })
    }

    onPrimaryFormChangeHandler = (event) => {
        let oldState = { ...this.state.personal_details, [event.target.id]: event.target.value }
        this.setState({
            personal_details: oldState
        })
    }

    onOtherDetailsFormChangeHandler = (event) => {
        let oldState = { ...this.state.other_details, [event.target.id]: event.target.value }
        if(event.target.id === 'status' && event.target.value !== 'Dormant') {
            oldState["TransferOutReason"] = "";
            oldState["TransferOutDate"] = "";
        }
        this.setState({
            other_details: oldState
        })
    }

    handlePhoneListChange = (id, e) => {
        let prevState = { ...this.state.personal_details }
        let newPhnList = prevState.phnNumList.map(phn => {
            if (phn.phnId === id) {
                phn[e.target.id] = e.target.value
            }
            return phn
        })
        prevState.phnNumList = newPhnList
        this.setState({
            personal_details: prevState
        })
    }

    onAddPhone = (event) => {
        let prevState = { ...this.state.personal_details }
        let newPhnObj = { phnId: prevState.phnNumList.length + 1, country_code: "+1", phone: "" }
        prevState.phnNumList.push(newPhnObj)
        this.setState({
            personal_details: prevState
        })
    }

    onDeletePhone = (event) => {
        let delPhnId = +event.target.id.slice(-1)
        let prevState = { ...this.state.personal_details }
        let newPhnList = prevState.phnNumList.filter(phn => phn.phnId !== delPhnId)
        let finalPhnList = newPhnList.map((phn, indx) => ({ ...phn, phnId: ++indx }))
        prevState.phnNumList = finalPhnList
        this.setState({
            personal_details: prevState
        })
    }

    handleAddressListChange = (id, e) => {
        if(e.target.id === 'address_maps'){

            let prevState = { ...this.state.personal_details }
            let newAddList = prevState.addressList.map(add => {
                if (add.addId === id) {
                    add.address_line_1 = e.value.result.address_components.find(component => component.types.includes("street_number")).long_name + " " + e.value.result.address_components.find(component => component.types.includes("route")).long_name
                    add.city = e.value.result.address_components.find(component => component.types.includes("locality")).long_name
                    add.state = e.value.result.address_components.find(component => component.types.includes("administrative_area_level_1")).short_name
                    add.zip = e.value.result.address_components.find(component => component.types.includes("postal_code")).long_name

                    add.latitude = e.value.result.geometry.location.lat
                    add.longitude = e.value.result.geometry.location.lng
                }
                return add
            })

            prevState.addressList = newAddList
            this.setState({
                personal_details: prevState
            })
            return;
        }

        let prevState = { ...this.state.personal_details }
        let newAddList = prevState.addressList.map(add => {
            if (add.addId === id) {
                add[e.target.id] = e.target.id==='state'?e.target.value.toUpperCase():e.target.value
            }
            this.getlatlong(add)
            return add
        })
        prevState.addressList = newAddList
        this.setState({
            personal_details: prevState
        })
    }

    onAddAddress = (event) => {
        let prevState = { ...this.state.personal_details }
        let newAddObj = { addId: prevState.addressList.length + 1, address_line_1: "", address_line_2: "", city: "", state: "", zip: "", latitude: "", longitude: "" }
        prevState.addressList.push(newAddObj)
        this.setState({
            personal_details: prevState
        })
    }

    onDeleteAddress = (event) => {
        let delAddId = +event.target.id.slice(-1)
        let prevState = { ...this.state.personal_details }
        let newAddList = prevState.addressList.filter(add => add.addId !== delAddId)
        let finalAddList = newAddList.map((add, indx) => ({ ...add, addId: ++indx }))
        finalAddList = finalAddList.map((add) => {
            this.getlatlong(add)
            return add
        })
        
        prevState.addressList = finalAddList
        this.setState({
            personal_details: prevState
            // deletedLocationIds: delAddArr
        })
    }

    changeTab = (key) => {
        const conversion = {
            first_name: 'First name',
            last_name: 'Last name',
            DDDID: 'DDD ID',
            cus_is_company: 'Company name',
            gender: 'Gender',
            dob: 'Date of Birth',
            state:'State',
            zip: 'Zip code',
            company_name: 'Company name',
            email: 'E-mail',
            addressList: ' Complete address',
            customer: 'Customer',
            status: 'Status',
            phnNumList: 'Phone number',
            acquired_date: 'Acquired date',
            customer_type:'Customer type',
            pManager: 'Project manager'
            

        }
        const preKey = this.state.key;
        var validation=this.props.isFormValidated(this.state[preKey])
   
        if (!(validation.isValidated)) {
            if(preKey === "personal_details"){
                this.setState({personal_details_val: false})
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            }else if(preKey === "other_details"){
                this.setState({other_details_val: false})
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            }else{
                this.setState({customer_tasks_val: false})
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            }
            this.setState({showAlert: true})
            this.setState({key : preKey})
            return false
        }else{
            if(preKey === "personal_details"){
                this.setState({personal_details_val: true})
            }else if(preKey === "other_details"){
                this.setState({other_details_val: true})
            }else{
                this.setState({customer_tasks_val: true})
            }
            this.setState({showAlert: false})
            this.setState({key})
            return true
        }
    }

    render() {
        let isStateNotNJ = !!this.props.user.states.find(state => state.state_id !== 'NJ')

        return (
            <div style={{ textAlign: "left", marginLeft: "10px" }}>
                {(this.state.showModal) ? (<MsgModalBox edit={this.props.edit} addNew={this.addNew} goHome={this.goHome} text="Customer" showError={this.state.addError} style={{ position: "absolute" }} taskDates={[]} />) : (
                   // <Form onSubmit={(e) => {!this.props.edit?this.addCustomertoDB(e):this.editCustInDB(e)}}>
                        <Form onSubmit={(e) => {
                            if (this.changeTab('customer_tasks') && this.props.edit)
                            { this.editCustInDB(e) }
                            else if (this.changeTab('other_details') && !this.props.edit) 
                            { this.addCustomertoDB(e) }
                            else 
                            { e.preventDefault()}
                    }} >
                        <h4>{this.props.edit ? "" : this.props.customerPage ? "" : "New Customer"} </h4>
                        {this.state.showAlert && 
                     <Alert variant="info">
                     {!this.state.errorMsg ? 'Please Fill out missing form fields' : this.state.errorMsg}
                 </Alert>}
                        <Tabs activeKey={this.state.key} id="uncontrolled-tab-example" onSelect={(key) => this.changeTab(key)}>
                            <Tab eventKey="personal_details" title={(this.state.personal_details_val) ?
                                (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Personal Details</div></div>) :
                                (<div style={{ margin: "-1px" }}>Personal Details</div>)}>
                                <PersonalDetails
                                    states={this.props.states}
                                    customerType={this.props.customerType} 
                                    edit={this.props.edit}
                                    first_name={this.state.personal_details.first_name}
                                    last_name={this.state.personal_details.last_name}
                                    gender={this.state.personal_details.gender}
                                    dob={this.state.personal_details.dob}
                                    company_name={this.state.personal_details.company_name}
                                    cus_local_id={this.state.personal_details.cus_local_id}
                                    email={this.state.personal_details.email}
                                    onContinue={this.changeTab}
                                    formChangeHandler={this.onPrimaryFormChangeHandler}
                                    onRadioChangeHandler={this.onRadioChangeHandler}
                                    phnNumList={this.state.personal_details.phnNumList}
                                    addPhoneNumber={this.onAddPhone}
                                    deletePhoneNumber={this.onDeletePhone}
                                    handlePhoneListChange={this.handlePhoneListChange}
                                    addressList={this.state.personal_details.addressList}
                                    formVals={this.state.personal_details}
                                    customerList={this.props.customerList}
                                    onAddAddress={this.onAddAddress}
                                    onDeleteAddress={this.onDeleteAddress}
                                    is_company = {this.props.edit?this.props.cusDets.cus_is_company:false}
                                    handleAddressListChange={this.handleAddressListChange}
                                    isStateNotNJ = {isStateNotNJ}
                               />
                            </Tab>
                            <Tab eventKey="other_details" title={(this.state.other_details_val) ?
                                (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Other Details</div></div>) :
                                (<div style={{ margin: "-1px" }}>Other Details</div>)}>
                                <OtherDetails
                                    status={this.props.customerStatus}
                                    edit={this.props.edit}
                                    empList={this.props.empList}
                                    // customerType={dd.generic.customerType.map((type)=>{return type.value})}
                                    customerType={this.props.customerType} 
                                    formVals={this.state.other_details}
                                    formChangeHandler={this.onOtherDetailsFormChangeHandler}
                                    customer_status={this.props.customer_status}
                                    isStateNotNJ = {isStateNotNJ}
                                    onContinue={this.changeTab}
                                />
                            </Tab>
                            {this.props.edit && <Tab eventKey="customer_tasks" title={(this.state.customer_tasks_val) ?
                                (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Customer Tasks</div></div>) :
                                (<div style={{ margin: "-1px" }}>Customer Tasks</div>)}>
                                <CustomerTasks
                                    comp_id={this.props.comp_id}
                                    user={this.props.user}
                                    cusDets={this.props.cusDets}
                                    edit={this.props.edit}
                                    formVals={this.state.customer_tasks}
                                />
                            </Tab>}
                        </Tabs>
                    </Form>
                )}
                {this.state.goHome &&
                    <Redirect to="/" />
                }
            </div >
        );
    }
};

export default CustomerForm;
