import react, { useState, useEffect } from 'react';
import Type from '../Components/Type';
import axios from 'axios';
import { APIURL } from '../../../config';

const ContactType = (props) => {
    const [typeList, setTypeList] = useState([]);
    const [selectedTypes, setSelectedTypes] = useState([]);

    useEffect(() => {
        setTypeList(props.typeList.map((type) => {
            return { title: type.EntityValue, id: type.ddID, deletable: type.deletable, is_Active: type.is_Active }
        }));
        setSelectedTypes(props.typeList.filter((type) => type.is_Active === 0 || type.is_Active === 3).map((type) => {
            return { title: type.EntityValue, id: type.ddID, deletable: type.is_Active === 3 || type.is_Active === 4, is_Active: type.is_Active }
        }));
    }, [props.typeList]);

    const handleSave = async (selectedTypes, newTypeList) => {
        try {
            const promises = typeList.map((type) => {
                let selectedType = selectedTypes.find((t) => t.title === type.title);

                if(!newTypeList.find((t) => t.title === type.title)) {
                    console.log("delete", type.id);
                    return axios.put(APIURL + "/dropdowns/company/config", {
                        ddId: type.id
                    });
                }
                if(!type.id) {
                    return axios.post(APIURL + "/dropdowns?comp_id=" + props.comp_id, {
                        entityValue: type.title,
                        entityName: "ContactType",
                        is_Active: selectedType ? 3 : 4
                    });
                }
                if((type.is_Active === 1 || type.is_Active === 4) && selectedType) {
                    return axios.put(APIURL + "/dropdowns", {
                        ddId: type.id,
                        is_Active: type.is_Active - 1
                    });
                } else if((type.is_Active === 0 || type.is_Active === 3) && !selectedType) {
                    return axios.put(APIURL + "/dropdowns", {
                        ddId: type.id,
                        is_Active: type.is_Active + 1
                    });
                } else {
                    return Promise.resolve();
                }
            });

            await Promise.all(promises);
            setSelectedTypes(selectedTypes);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Type
            leftColumnSize={props.leftColumnSize}
            rightColumnSize={props.rightColumnSize}
            typeList={typeList}
            selectedTypes={selectedTypes}
            setTypeList={setTypeList}
            handleSave={handleSave}
            title="Contact Type"
            description="Which Contact types do you have?"
            subTitle="Types"
        />
    );
}

export default ContactType;