import * as React from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import CompanyRenwalDataTabe from "./CompanyRenwalDataTabe";
import { Grid, Typography } from "@mui/material";

export default function TreeViewDataReports(props) {
  return (
    <Grid>
      <Grid container>
        {/* <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              fontFamily: "Lato",
              marginBottom: 5,
            }}
          >
            Plan Renewal Reports
          </Typography>
        </Grid> */}

        <Grid item xs={12}>
          <CompanyRenwalDataTabe
            data={[
              ...props?.data?.current_months_reports,
              ...props?.data?.next_month_reports,
              ...props?.data?.reports_beyond_next_month_in_current_quarter,
            ]}
            user={props.user}
          />
        </Grid>

        {/* <Grid item xs={12}>
          <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ flexGrow: 1, overflowY: "auto" }}
          >
            <TreeItem
              label="Current Month"
              style={{ marginTop: 2, marginBottom: 2 }}
              nodeId="1"
            >
              <CompanyRenwalDataTabe
                data={props?.data?.current_months_reports || []}
              />
            </TreeItem>
            <TreeItem
              nodeId="2"
              label="Next Month"
              style={{ marginTop: 2, marginBottom: 2 }}
            >
              <CompanyRenwalDataTabe
                data={props?.data?.next_month_reports || []}
              />
            </TreeItem>
            <TreeItem
              nodeId="3"
              label="Beyond Next Month in Current Quarter"
              style={{ marginTop: 2, marginBottom: 2 }}
            >
              <CompanyRenwalDataTabe
                data={
                  props?.data?.reports_beyond_next_month_in_current_quarter ||
                  []
                }
              />
            </TreeItem>
          </TreeView>
        </Grid> */}
      </Grid>
    </Grid>
  );
}
