/* THIS PAGE SHOULD BE EMPTY FOR ANY PRODUCTION BUILDS */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap'

export default function EditTimesheetModal(props) {
    useEffect(() => {
        
    }, []);

    return(
        <Modal
            show={props.active}
            onHide={()=> {
                if(!!props.onHide){
                    console.log("GOT TO ONHIDE")
                    props.onHide()
                }
            }}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>EDIT TIMESHEET RECORD</Modal.Title>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
            <Modal.Footer>
            
            </Modal.Footer>
        </Modal>
    );
}