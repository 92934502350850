import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const UserSelectionDialog = ({ users, onSelect, onClose }) => {
  const [selectedUser, setSelectedUser] = useState('');

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleSelect = () => {
    const user = users.find(user => user.emp_id === selectedUser);
    onSelect(user);
  };

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="sm" // Set the maximum width of the dialog
      fullWidth // Ensure the dialog takes the full width
      sx={{ '& .MuiDialog-paper': { minHeight: '300px', minWidth: '300px' } }} // Custom styles for min height and width
    >
      <DialogTitle>Select An Account</DialogTitle>
      <DialogContent>
        <p style={{ marginBottom: '20px' }}>It looks like you have multiple user accounts on Fieldworker. Please select your account.</p>
        <FormControl fullWidth>
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            labelId="user-select-label"
            value={selectedUser}
            onChange={handleUserChange}
          >
            {users.map(user => (
              <MenuItem key={user.emp_id} value={user.emp_id}>
                {user.emp_first_name} {user.emp_last_name} ({user.com_name})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSelect} color="primary" disabled={!selectedUser}>
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSelectionDialog;