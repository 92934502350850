import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';

const LoadingSkeleton = () => {
    return (
        <Box sx={{ width: 'auto', height: "100%", m: 1.25 }} >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1.25 }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Skeleton variant="rounded" animation="wave" width={200} height={40} />
                    <Skeleton variant="rounded" animation="wave" width={300} height={40} />
                </Box>
                <Skeleton variant="rounded" animation="wave" width={160} height={40} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1.25 }}>
                <Skeleton variant="rounded" animation="wave" width={205} height={35} />
                <Skeleton variant="rectangular" animation="wave" width={100} height={35} />
                <Skeleton variant="rounded" animation="wave" width={295} height={35} />
            </Box>
            <Skeleton variant="retangle" animation="wave" height={670} />
        </Box>
    )
}

export default LoadingSkeleton;