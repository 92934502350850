import React, { useState, useEffect } from 'react';
import { Box, Typography, Checkbox, Stack, FormControlLabel, FormGroup, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';
import axios from 'axios';
import { APIURL } from '../../../config';
import { mt } from 'date-fns/locale';

const SecureCommunication2 = (props) => {
    const [mtSubmission, setMtSubmission] = useState(false);
    const [mtRejected, setMtRejected] = useState(false);
    const [mtApproved, setMtApproved] = useState(false);
    const [taskAssigned, setTaskAssigned] = useState(false);
    const [taskStartTimeApproaching, setTaskStartTimeApproaching] = useState(false);
    const [taskEndTimeApproaching, setTaskEndTimeApproaching] = useState(false);

    const handleCheckBoxChange = async (event, featureId, checked) => {
        const { name } = event.target;
        switch (name) {
            case 'mtSubmission':
                setMtSubmission(checked);
                break;
            case 'mtRejected':
                setMtRejected(checked);
                break;
            case 'mtApproved':
                setMtApproved(checked);
                break;
            case 'taskAssigned':
                setTaskAssigned(checked);
                break;
            case 'taskStartTimeApproaching':
                setTaskStartTimeApproaching(checked);
                break;
            case 'taskEndTimeApproaching':
                setTaskEndTimeApproaching(checked);
                break;
            default:
                break;
        }

        const payload = {
            feature_id: featureId,
            feature_value: checked,
            company_id: props.comp_id,
            added_by: props.emp_id
        };

        try {
            let data = await axios.get(`${APIURL}/config/settings/${props.user_id}`, {
                params: { comp_id: props.comp_id, feature_id: featureId },
            });

            if (data.data.length > 0) {
                await axios.put(`${APIURL}/config/settings/${props.user_id}`, payload);
            } else {
                await axios.post(`${APIURL}/config/settings/${props.user_id}`, payload);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        setMtSubmission(props.mtSubmission);
        setMtRejected(props.mtRejected);
        setMtApproved(props.mtApproved);
        setTaskAssigned(props.taskAssigned);
        setTaskStartTimeApproaching(props.taskStartTimeApproaching);
        setTaskEndTimeApproaching(props.taskEndTimeApproaching);
    }, [props]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                           Secure Communication
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Configure your communication settings
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <FormGroup>
                            <Typography variant="h6">Enable messaging for tasks</Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={mtSubmission}
                                                onChange={(e) => handleCheckBoxChange(e, 1, !mtSubmission)}
                                                name="mtSubmission"
                                            />
                                        }
                                        label="MT Submission"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={mtRejected}
                                                onChange={(e) => handleCheckBoxChange(e, 2, !mtRejected)}
                                                name="mtRejected"
                                            />
                                        }
                                        label="MT Rejected"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={mtApproved}
                                                onChange={(e) => handleCheckBoxChange(e, 3, !mtApproved)}
                                                name="mtApproved"
                                            />
                                        }
                                        label="MT Approved"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={taskAssigned}
                                                onChange={(e) => handleCheckBoxChange(e, 4, !taskAssigned)}
                                                name="taskAssigned"
                                            />
                                        }
                                        label="Task assigned"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={taskStartTimeApproaching}
                                                onChange={(e) => handleCheckBoxChange(e, 5, !taskStartTimeApproaching)}
                                                name="taskStartTimeApproaching"
                                            />
                                        }
                                        label="Task start time approaching"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={taskEndTimeApproaching}
                                                onChange={(e) => handleCheckBoxChange(e, 6, !taskEndTimeApproaching)}
                                                name="taskEndTimeApproaching"
                                            />
                                        }
                                        label="Task end time approaching"
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SecureCommunication2;
