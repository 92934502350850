import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { APIURL } from '../../../../config';

const EmployeeNeedForm = (props) => {
    const [employeeNeeds, setEmployeeNeeds] = useState({});

    // Style for the modal content
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        setEmployeeNeeds(props.employeeNeeds || {
            employment_first_plan: '',
            employment_plan: '',
            voting_plan: ''
        });
    }, [props.employeeNeeds]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let data = {
                employment_first_plan: employeeNeeds.employment_first_plan,
                employment_plan: employeeNeeds.employment_plan,
                voting_plan: employeeNeeds.voting_plan,
                customer_id: props.customer_id
            };
            let response;
            if (props.employeeNeeds) {
                response = await axios.put(APIURL + '/customer_empl_need/' + props.customer_id, data);
            } else {
                response = await axios.post(APIURL + '/customer_empl_need', data);
            }
            props.setEmployeeNeeds(data);
            props.handleClose();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="employee-form-title"
            aria-describedby="employee-form-description"
        >
            <Box sx={style} component="form" onSubmit={handleSubmit}>
                <Typography id="employee-form-title" variant="h6" component="h2">
                    Employee Needs
                </Typography>
                <TextField
                    fullWidth
                    label="Employment First Implementation"
                    margin="normal"
                    name="employment_first_implementation"
                    variant="outlined"
                    placeholder='Please note that New Jersey is an Employment First State, meaning that: "Competitve employment in the general workforce is the first and preferred post education outcome for people with any type of disability."In conjunctionwith this policy, at least one plan outcome must be related to employment, the pursuit of employment, or the exploration of employment unless the individual of retirement age'
                    rows={5}
                    multiline
                    value={employeeNeeds?.employment_first_plan || ''}
                    onChange={(e) => setEmployeeNeeds({ ...employeeNeeds, employment_first_plan: e.target.value || '' })}
                />
                <TextField
                    fullWidth
                    label="Employment Plan"
                    margin="normal"
                    name="employment_first_implementation"
                    variant="outlined"
                    placeholder='Determine whether or not emplotment services are needed to maintain current job. If employment services are provided identify areas in which the employee needs support, must improve due to supervisor feedback, wants to improve, etc, and indicate on the Intervention Plan & Service Log. Include these outcomes and any services that are needed to accomplish these outcomes in the Service Plan'
                    rows={5}
                    multiline
                    value={employeeNeeds?.employment_plan || ''}
                    onChange={(e) => setEmployeeNeeds({ ...employeeNeeds, employment_plan: e.target.value || '' })}
                />
                <TextField
                    fullWidth
                    label="Voting Plan"
                    margin="normal"
                    name="employment_first_implementation"
                    variant="outlined"
                    rows={5}
                    multiline
                    value={employeeNeeds?.voting_plan || ''}
                    onChange={(e) => setEmployeeNeeds({ ...employeeNeeds, voting_plan: e.target.value || '' })}
                />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Save
                </Button>
            </Box>
        </Modal>
    );
}

export default EmployeeNeedForm;
