import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import axios from 'axios';
import { APIURL } from '../../config'
import { Form, FormControl, Button } from 'react-bootstrap';
import CustomerCard from './CustomerCard/CustomerCard';
import DetailsPane from './DetailsPane'
import './Customers.css';
import makeStyles from "@mui/styles/makeStyles";
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';


const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

const useStyles = makeStyles((theme) => ({
    projList1: {
        height: "57rem",
        width: "90%",
        overflowX: "hidden",
        overflowY: "auto",
    }
}));

export default function Customer(props) {
    const classes = useStyles();
    const [state, setState] = useState({
        customers: [],
        active: [],
        inactive: [],
        prospect: [],
        dormant: [],
        activeList: 'active',
        searchTerm: '',
        customerSearchResults: [],
        isLoading: true,
        current_customer: null,
        new_customer: false,
        edit: false,
        selectedMenu: '',
    })
    const [loggedToSegment, setLoggedToSegment] = useState(false);

    const location = useLocation()

    const logToSegment = () => {
        if(loggedToSegment) {
            return;
        }
        let page = String(window.location.href).split("/").pop()
        console.log(page,window.location.href)
        if(page.includes("fieldworker") || page === "" || page === "#") {
            page = "Home";
        } else {
            page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
        }
        console.log(page)
        analytics.page(page, {
            title:page,
            path:page,
        }, {
            userId:String(props.user.emp_id),
        })
        console.log("logged to segment successfully")
        setLoggedToSegment(true);
    }

    const getData = async () => {
        props.checkTokenExpiry()
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }

        let cusData = await axios.get(APIURL + "/customers/v2/associated", { params: { company_id: props.comp_id, employee_id: props.user.emp_id } })

        let cusList = cusData.data

        let active = cusList.filter((cus) => cus.cus_status === 'Active');
        let inactive = cusList.filter((cus) => cus.cus_status === 'Inactive');
        let prospect = cusList.filter((cus) => cus.cus_status === "Prospect");
        let dormant = cusList.filter((cus) => cus.cus_status === "Dormant");

        let current_customer;
        current_customer = active.length > 0 ? current_customer = active[0].cus_id : current_customer = null;
        let customerSearchResults = active;
        let searchTerm = '';

        if (location.customersProps) {
            current_customer = location.customersProps.cus_id;
            customerSearchResults = cusList.filter(cus => {
                if (cus.cus_id === location.customersProps.cus_id) {
                    return true;
                }
                return false;
            })
            searchTerm = customerSearchResults[0].cus_name;
            setState(prev => ({ ...prev, edit: location.customersProps.edit }));

        }

        setState(prev => ({
            ...prev,
            customers: cusList,
            active: active,
            inactive: inactive,
            prospect: prospect,
            dormant: dormant,
            customerSearchResults: customerSearchResults,
            isLoading: false,
            current_customer: current_customer,
            searchTerm: searchTerm
        }))
    }

    useEffect(() => {
        getData();
        logToSegment();
        let setting_list = props?.user?.comp_settings.filter(setting => [101,102].includes(setting.feature_id));
        setState(prev => ({
            ...prev,
            selectedMenu: setting_list?.length>0? 'Clients' : 'Customers'
        }))
    }, [])

    const showCustomerDetails = (key) => {
        setState(prev => ({
            ...prev,
            current_customer: key
        }))
    }

    const showNewCustomer = () => {
        setState(prev => ({
            ...prev,
            new_customer: !state.new_customer
        }))
    }

    const showCustomerList = () => {
        if (state.customerSearchResults.length > 0) {
            return state.customerSearchResults.map(
                customers => {
                    return <CustomerCard
                        {...props}
                        key={customers.cus_id}
                        id={customers.cus_id}
                        customer={customers}
                        onClick={showCustomerDetails}
                        selected={state.current_customer === customers.cus_id ? 'active' : 'inactive'}
                    />
                }
            )
        } 
    }

    const searchProjHandler = (e) => {
        // e.preventDefault()
        var searchQuery = e.target.value
        setState(prev => ({
            ...prev,
            searchTerm: searchQuery
        }))
    }

    const searchProjResults = (e) => {
        e.preventDefault()
        var searchQuery = e.target.value
        var searchTerm = new RegExp(searchQuery, 'i')
        let customerSearchResults;

        const filterCustomer = (cusData) => {
            return cusData.filter((proj) => {
                if (searchTerm.exec(proj.cus_name) || searchTerm.exec(proj.DDDID) || searchTerm.exec(proj.cus_local_id)) {
                    return proj
                }
                else {
                    return null
                }
            }).filter((proj) => !!proj)
        }

        if(state.activeList === 'active') {
            customerSearchResults = filterCustomer(state.active)
        } else if(state.activeList === 'inactive') {
            customerSearchResults = filterCustomer(state.inactive)
        } else if(state.activeList === 'prospect') {
            customerSearchResults = filterCustomer(state.prospect)
        } else if(state.activeList === 'dormant') {
            customerSearchResults = filterCustomer(state.dormant)
        }

        setState(prev => ({
            ...prev,
            searchTerm: searchQuery,
            customerSearchResults: customerSearchResults
        }))
    }

    const toggleActive = (screen) => {
        if ((screen === 'active' && state.activeList === 'active') || (screen === 'inactive' && state.activeList === 'inactive') || (screen === 'prospect' && state.activeList === 'prospect') || (screen === 'dormant' && state.activeList === 'dormant')) {

            return
        } else {
            let cusData = [];
            if (screen === 'active') {
                ;
                cusData = state.active;
            } else if (screen === 'inactive') {
                cusData = state.inactive;
            } else if (screen === 'prospect') {
                cusData = state.prospect;
            } else if (screen === 'dormant') {
                cusData = state.dormant;
            }

            let filtered_customer = cusData;
            setState(prev => ({
                ...prev,
                searchTerm: '', activeList: screen, customerSearchResults: filtered_customer, current_customer: filtered_customer.length > 0 ? filtered_customer[0].cus_id : null
            }))

        }
    }

    const sortBy = (param) => {
        let cusData = state.customerSearchResults
        // debugger
        if (param === 'name') {
            // sort by name
            cusData.sort((cusA, cusB) => ('' + cusA.cus_name).localeCompare(cusB.cus_name))
        } else {
            // sort by date
            cusData.sort((cusA, cusB) => moment(cusB.cus_acquired_date) - moment(cusA.cus_acquired_date))
        }
        // debugger
        setState(prev => ({
            ...prev,
            customerSearchResults: cusData
        }))
    }

    let dashBoard = null
    if (!state.isLoading) {
        dashBoard = (
            <div className="customersPage-mainDiv" style={{ display: 'flex', justifyContent: 'start', height: '100%', width: '100%', marginLeft: '10px' }}>
                <div style={{ width: '27%', height: '100%' }}>
                    <div>
                        <Form inline style={{ width: '153%' }} onSubmit={searchProjResults}>
                            <FormControl onChange={searchProjResults} type="text" value={state.searchTerm} placeholder="Search" className="form-control w-50 mr-1" />
                            <Button type="submit" variant="outline-primary" style={{ border: "solid 1px #95D0EB", marginLeft: "-5px", borderRadius: '0 5px 5px 0', paddingRight: "15px", paddingLeft: "15px" }}><i className="fas fa-search"></i></Button>
                        </Form>
                        <Form inline>
                            <ul className="customerListUL">
                                <li className="customerListHeaderItems">
                                    <div className="dropdown">
                                        <button style={{ outline: 'none', color: '#1976D2', border: '1px solid #1976D2', height: '38px' }} className="btn dropdown-toggle bg-white" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span><i className="fas fa-sort-amount-up"></i></span> SORT BY
                                        </button>
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                            <button onClick={() => sortBy('name')} className="dropdown-item" type="button">Name</button>
                                            <button onClick={() => sortBy('date')} className="dropdown-item" type="button">Acquire Date</button>
                                        </div>
                                    </div>
                                </li>
                                <li className="customerListHeaderItems" style={{ marginLeft: '25px' }}>
                                        <Button style={{ marginBottom: '10px' }} onClick={showNewCustomer} variant="primary" className="ml-4">+ New Customer</Button>
                                </li>
                            </ul>
                        </Form>
                        <div>
                            <div >
                                <div className="customerListHeader">
                                    <div className='customer_button_group' style={{ display: 'grid' }}>
                                        <div style={{ display: 'flex', marginBottom: '10px' }}>
                                            <div style={{ opacity: '90%' }} className={`customer_button ${state.activeList === 'active' ? 'active' : 'inactive'}`} onClick={(e) => toggleActive('active')}>
                                                <div>Active</div>
                                                {state.activeList === 'active' && <div className='customer_button_number'>{state.customerSearchResults.length}</div>}
                                            </div>
                                            <div style={{ opacity: '90%', paddingRight: '5px' }} className={`customer_button ${state.activeList === 'inactive' ? 'active' : 'inactive'}`} onClick={(e) => toggleActive('inactive')}>
                                                <div>Inactive</div>
                                                {state.activeList === 'inactive' && <div className='customer_button_number'>{state.customerSearchResults.length}</div>}
                                            </div>
                                            <div style={{ opacity: '90%' }} className={`customer_button ${state.activeList === 'prospect' ? 'active' : 'inactive'}`} onClick={(e) => toggleActive('prospect')}>
                                                <div>Prospects</div>
                                                {state.activeList === 'prospect' && <div className='customer_button_number'>{state.customerSearchResults.length}</div>}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ opacity: '90%' }} className={`customer_button ${state.activeList === 'dormant' ? 'active' : 'inactive'}`} onClick={(e) => toggleActive('dormant')}>
                                                <div>Dormant</div>
                                                {state.activeList === 'dormant' && <div className='customer_button_number'>{state.customerSearchResults.length}</div>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div
                                    className={classes.projList1}
                                >
                                    {showCustomerList()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '75%', height: '100%', marginLeft: '-45px' }}>
                    {state.customerSearchResults.length !== 0 && <DetailsPane
                        status={state.viewOld ? 'Older' : 'Ongoing'}
                        comp_id={props.comp_id}
                        user={props.user}
                        history={props.history}
                        cus_id={state.current_customer}
                        cust_details={state.customers.find(customer => customer.cus_id === state.current_customer)}
                        project_details={state.customerSearchResults.find(customer => customer.cus_id === state.current_customer)}
                        new_customer={state.new_customer}
                        edit={state.edit}
                        selectedMenu={state.selectedMenu}
                        checkTokenExpiry={props.checkTokenExpiry}
                    />}
                    {state.customerSearchResults.length === 0 &&
                        <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '500px', marginTop: 10 }}>
                            <img src={state.customerSearchResults.length === 0 ? './nosearchresult.png' : './projectgraphic.png'} alt='projectgraphic' style={{ width: '15rem', height: '10rem', margin: 10 }} />
                            <h5 style={{ fontFamily: 'Lato', marginTop: 48, marginBottom: 30 }} className='text-center'>{state.customers.length !== 0 ? 'No Customers Found' : props.user.type === 'Fieldworker' || props.user.type === 'Support Coordinator' ? 'You are not assigned any project yet. Please contact your manager.' : 'You dont have any Customers yet'} </h5>
                            <p style={{ fontFamily: 'Lato', fontSize: 12, width: '70%' }} className='text-muted text-center text-wrap'>Customer's related information includes Overviews, Tasks , Employees, Artifacts, Comments and Contacts will show here once you add customers. <br />On the left side, you will see a list of your Ongoing or Previous projects.</p>
                        </div>
                    }
                </div>
            </div>
        )
    }
    else {
        dashBoard = (
            <div className="d-flex flex-row justify-content-center">
                <div style={{ color: '#3FA7D6', height: '10rem', width: '10rem', marginTop: 120 }} className="spinner-border text-info" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }

    return (
        <div style={{ height: '100%', width: '100%' }}>
            {dashBoard}
        </div>
    )
}