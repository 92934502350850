import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import Link from "@mui/material/Link";

import { Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { Theme, useTheme } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "react-bootstrap";
import moment from "moment";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 12,
  },
  link: {
    fontSize: 15,
  },
}));

const formFields = {
  // fromDate: null,
  // toDate: null,
  date: null,
  Time: null,
  servieProvider: "",
  servieReciever: "",
  taskType: [],
  locations: "",
};
const EVVFilter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  
  const [evvFilterData, setevvFilterData] = useState(formFields);
  // const [fromDate, setFromDate] = useState(null);
  // const [toDate, setToDate] = useState(null);
  // const [servieProvider, setServieProvider] = useState("");
  // const [servieReciever, setServieReciever] = useState("");
  const [taskType, setPersonName] = useState([]);
  // const [locations, setLocations] = useState("");

  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  // const handleChangeProvider = (e) => {
  //   console.log(">>>>", e.target.value);
  //   setServieProvider(e.target.value);
  // };
  // const handleChangeReciever = (e) => {
  //   console.log(">>>>", e.target.value);
  //   setServieReciever(e.target.value);
  // };

  // const handleChangeLocations = (e) => {
  //   console.log(">>>>", e.target.value);
  //   setLocations(e.target.value);
  // };

  function getStyles(name, taskType, theme) {
    return {
      fontWeight:
        taskType.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  useEffect(() => {    
  let tempObj = localStorage.getItem("evvFilterData");
  if (!tempObj && props.filterResultsOutput === '') {
    setevvFilterData({...formFields});
  }
  if (tempObj) {
      tempObj = JSON.parse(tempObj);
      setevvFilterData({...tempObj});
      setPersonName(tempObj.taskType);
    }
  }, []);
  
  const handleChangeTaskType = (e) => {
    console.log(">>>>", e.target.value);
    const {
      target: { value },
    } = e;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    setevvFilterData({
      ...evvFilterData,
      taskType: e.target.value
    });
  };

  const handleFieldsData = (e, name, key) => {
    let temp = { ...evvFilterData };
    switch (key) {
      case "date":
        {
          temp[name] = moment(new Date(e)).format("MM/DD/YYYY");
        }
        break;
      case "time":
          {
            temp[name] = moment(new Date(e));
          }
          break;
      case "multi":
        {
          const {
            target: { value },
          } = e;
          // setPersonName(typeof value === "string" ? value.split(",") : value);
          temp[name] = typeof value === "string" ? value.split(",") : value;
        }
        break;
      default:
        {
          temp[name] = e.target.value;
        }
        break;
    }
    setevvFilterData({ ...temp });
  };
  const applyFilters = (e) => {
    console.log("applyFilters Final>>>>", evvFilterData);
    props.onFormSubmission(evvFilterData);
  };
  return (
    <Grid
      container
      justify="center"
      style={{
        height: "85%",
        width: "22%",
        border: "1px solid gery",
        borderRadius: "5px",
        backgroundColor: "#ffffff",
        position: "fixed",
        zIndex: 1,
        right: 0,
        boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%)",
        padding: 12,
        overflowY: "auto",
        top: 80
      }}
    >
      <Grid container>
        <Grid container 
          style={{borderBottom: "1px solid grey", paddingBottom: '0px'}}>
          <Grid item xs={11}
          justifyContent="space-between">
            <Typography
              variant="subtitle2"
              style={{
                fontSize: 12,
                fontStyle: "italic",
                // borderBottom: "1px solid grey",
                paddingBottom: "5px",
                fontFamily: "Lato",
                fontWeight: "bold",
                color: "#495057",
                display: 'inline-block'
              }}
              align="left"
            >
              Select desired fields to run a customized report:
            </Typography>
            {/* <Button
                onClick={(e) => {
                  console.log("Close filter");
                  // props.onFormSubmission(formFields);
                  // setevvFilterData({ ...formFields });
                  props?.closeCustomReport(e);
                }}
                variant="primary"
                size="sm"
                className="font-weight-bold"
                style={{ fontSize: 14, fontFamily: "Lato" }}
              >
                CANCEL
            </Button> */}            
          </Grid>
          <Grid item xs={1} 
            align="right"
          >
            <CancelIcon
              style={{cursor: 'pointer'}}
              alignItems="right"
              onClick={(e) => {
                console.log("Close filter");
                // props.onFormSubmission(formFields);
                // setevvFilterData({ ...formFields });
                props?.closeCustomReport(e);
              }}
              // onMouseDown={(event) => event.stopPropagation()}
            />
            {/* <hr style={{ borderTop: '1px solid #3fa7d6' }}/> */}
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
        </Grid> */}

        {/* <Grid item xs={12} className="mb-3 mt-3">
          <Typography
            variant="h1"
            style={{ fontSize: 15,  fontFamily: "Lato", }}
            mb={2}
            className="font-weight-bold"
          >
            Date Range:
          </Typography>
          <Typography
            variant="h1"
            style={{ fontSize: 14, fontFamily: "Lato", }}
            mb={1}
            className="font-weight-bold"
          >
            From
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Today's date"
              style={{ width: "100%" }}
              value={evvFilterData.fromDate}
              onChange={(newValue) => {
                handleFieldsData(newValue, "fromDate", "date");
              }}
              renderInput={(params) => <TextField style={{ width: "100%" }} size="small" {...params} />}
            />
          </LocalizationProvider>
        </Grid> */}

        {/* <Grid item xs={12} className="mb-1">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography
              variant="h1"
              style={{ fontSize: 14, fontFamily: "Lato", }}
              mb={1}
              className="font-weight-bold"
            >
              To
            </Typography>
            <DatePicker
              label="Today's date"
              value={evvFilterData.toDate}
              onChange={(newValue) => {
                handleFieldsData(newValue, "toDate", "date");
              }}
              renderInput={(params) => <TextField style={{ width: "100%" }} size="small" {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid> */}

        <Grid item xs={12} className="mt-3">
          <Typography
            variant="h1"
            style={{ fontSize: 14, fontFamily: "Lato", }}
            mb={1}
            className="font-weight-bold"
          >
            Service Provider
          </Typography>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={evvFilterData.servieProvider}
              label="Select"
              onChange={(newValue) => {
                handleFieldsData(newValue, "servieProvider", "");
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'Service 1'}>Service 1</MenuItem>
              <MenuItem value={'Service 2'}>Service 2</MenuItem>
              <MenuItem value={'Service 3'}>Service 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid>

        <Grid item xs={12} className="mb-1">
          <Typography
            variant="h1"
            style={{ fontSize: 14 , fontFamily: "Lato",}}
            mb={1}
            className="font-weight-bold"
          >
            Service Reciever
          </Typography>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={evvFilterData.servieReciever}
              label="Select"
              onChange={(newValue) => {
                handleFieldsData(newValue, "servieReciever", "");
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'Service 1'}>Service 1</MenuItem>
              <MenuItem value={'Service 2'}>Service 2</MenuItem>
              <MenuItem value={'Service 3'}>Service 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid>

        <Grid item xs={12} className="mb-1">
          <Typography
            variant="h1"
            style={{ fontSize: 14 , fontFamily: "Lato",}}
            mb={1}
            className="font-weight-bold"
          >
            Type
          </Typography>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={taskType}
              onChange={handleChangeTaskType}
              // onChange={(newValue) => {
              //   handleFieldsData(newValue, "taskType", "multi");
              // }}
              input={
                <OutlinedInput id="select-multiple-chip" label="Select" />
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      onDelete={() => {}}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, taskType, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid>
        <Grid item xs={12} className="mb-1">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Typography
              variant="h1"
              style={{ fontSize: 14, fontFamily: "Lato", }}
              mb={1}
              className="font-weight-bold"
            >
              Date
            </Typography>
            <DatePicker
              label="Today's date"
              value={evvFilterData.date}
              onChange={(newValue) => {
                handleFieldsData(newValue, "date", "date");
              }}
              renderInput={(params) => <TextField style={{ width: "100%" }} size="small" {...params} />}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid>
        <Grid item xs={12} className="mb-1">
          <FormControl size="small" fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Typography
                variant="h1"
                style={{ fontSize: 14, fontFamily: "Lato" }}
                mb={1}
                className="font-weight-bold"
              >
                Time
              </Typography>
              <TimePicker
                size="small"
                label="Today's time"
                value={evvFilterData.Time}
                onChange={(newValue) => {
                  handleFieldsData(newValue, "Time", "time");
                }}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <hr style={{ borderTop: "1px solid #f0f0f0", margin: '5px 0 5px 0' }} />
        </Grid>

        <Grid item xs={12} className="mb-2">
          <Typography
            variant="h1"
            style={{ fontSize: 14 , fontFamily: "Lato",}}
            mb={1}
            className="font-weight-bold"
          >
            Locations
          </Typography>
          <FormControl size="small" fullWidth>
            <InputLabel id="demo-select-small">Select</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={evvFilterData.locations}
              label="Select"
              onChange={(newValue) => {
                handleFieldsData(newValue, "locations", "");
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={'Location 1'}>Location 1</MenuItem>
              <MenuItem value={'Location 2'}>Location 2</MenuItem>
              <MenuItem value={'Location 3'}>Location 3</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} className="mb-1 mt-2">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Button
              onClick={(e) => props?.openCustomReport(e)}
              variant="primary"
              size="sm"
              className="font-weight-bold"
              style={{ fontSize: 14 , fontFamily: "Lato",}}
            >
              RESET
            </Button>
            
            <Button
              onClick={(e) => {
                localStorage.setItem("evvFilterData", JSON.stringify(evvFilterData));
                applyFilters();
                console.log("Applied filters ready for API", evvFilterData);
              }}
              variant="primary"
              size="sm"
              className="font-weight-bold"
              style={{ fontSize: 14 , fontFamily: "Lato",}}
            >
              RUN REPORT
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EVVFilter;
