import {
	Box,
	Tab,
	Tabs
} from "@mui/material";
import InsertDriveFileOutlined from "@mui/icons-material/InsertDriveFileOutlined";
import Star from "@mui/icons-material/Star";
import React, { useState, useEffect } from "react";
import AllDocuments from "./AllDocuments";
import FavouriteDocuments from "./FavouriteDocuments";
import axios from "axios";
import { APIURL } from "../../config";

const Documents = (props) => {
	const [activeKey, setActiveKey] = useState(0);
	const [data, setData] = useState([]);

	useEffect(() => {
		getData();
		getVirtualData();
	}, []);

	const getData = async () => {
		let data = await axios.get(APIURL + "/directories/all/" + props.comp_id);
		if(data.data.length > 0){
			setData(data.data);
		}
		console.log(data.data);
	};

	const getVirtualData = async () => {
		let data = await axios.get(APIURL + "/directories/virtual/" + props.comp_id, {
			params: {
				employee_id: props.user.emp_id,
			}
		});
		if(data.data.length > 0){
			setData(prev => [...prev, ...data.data]);
		}
		console.log(data.data);
	}

	const handleKeyChange = (event, activeKey) => {
		setActiveKey(activeKey);
	};

	return (
		<Box sx={{ width: '100%', height: '100%' }}>
			<Box sx={{ flexGrow: 1, display: 'flex', height: 'calc(100vh - 85px)' }}>
				<Tabs
					orientation="vertical"
					value={activeKey}
					onChange={handleKeyChange}
					sx={{ borderRight: 1, borderColor: 'divider', width: '15%' }}
					
				>
					<Tab icon={<InsertDriveFileOutlined />} iconPosition="start"label="All Documents" />
					<Tab icon={<Star />} iconPosition="start" label="Favorites" />
				</Tabs>
				{activeKey === 0 && <AllDocuments {...props} data={data} getData={getData} />}
				{activeKey === 1 && <FavouriteDocuments />}
			</Box>
		</Box>
	);
};

export default Documents;
