import { useState, useEffect, memo } from "react";
import { RadioGroup, Radio, FormControlLabel } from "@mui/material";
import lodash from "lodash";

const ControlledRadioGroup = (props) => {
    const [value, setValue] = useState(null);
    const [error, setError] = useState(false);
    const [read, setRead] = useState(true);

    useEffect(() => {
        setValue(props.value);
        if(isValid(props.value) && props.choose && props.setSectionError !== undefined && props.section !== undefined){
            props.setSectionError((prev) => { 
                let newPrev = [...prev];
                newPrev[props.section] = newPrev[props.section].filter((error) => error !== 'choose');
                return newPrev;
            })
        }
    }, [props.value]);

    useEffect(() => {
        setError(props.error);
    }, [props.error]);

    useEffect(() => {
        setRead(props.read);
    }, [props.read]);   

    useEffect(() => {
        if(read !== true){
            if(props.disabled === true) {
                setValue("");
                if(props.onChange !== undefined) props.onChange("");
                if(!isValid(value)) setError(false);
            } else {
                if(!isValid(props.value)) {
                    if(props.dependentValue)
                        props.onChange(props.dependentValue);
                }
                setError(true);
            }
        }
    }, [props.disabled]);

    useEffect(() => {
        if(props.setSectionError !== undefined && props.section !== undefined) 
            error ? props.setSectionError((prev) => { 
                let newPrev = [...prev];
                if(!props.choose)
                    newPrev[props.section].push(props.question ? props.question : 'radio');
                else
                    newPrev[props.section].push('choose');
                return newPrev;
            }) : 
            props.setSectionError((prev) => {
                let newPrev = [...prev];
                let index;
                if(!props.choose) {
                    index = newPrev[props.section].indexOf(props.question ? props.question : 'radio');
                    if (index > -1 ) {
                        newPrev[props.section].splice(index, 1);
                    }
                } else
                    newPrev[props.section] = newPrev[props.section].filter((error) => error !== 'choose');
                    
                return newPrev;
            });
    }, [error]);

    useEffect(() => {
        if(props.question === "test")
            console.log(value);
        if(!props.disabled && !props.choose) 
            setError(!isValid(value));
    }, [value, props.validate]);

    const isValid = (value) => {
        return !(value === null || value === "");
    }

    return (
        <>
        {props.value !== undefined ?
            <RadioGroup
                row
                value={value}
                onChange={e => {setValue(e.target.value);props.onChange(e.target.value)}}
            >
                {props.options.map((option, index) => {
                    return (
                        <FormControlLabel
                            key={index}
                            value={option.value}
                            control={
                                <Radio
                                    size="small"
                                    inputProps={{ ...props.inputProps }}
                                    disabled={props.disabled}
                                    sx={{ color: error && props.validate && !props.disabled ? "#d32f2f" : "#616161" }}
                                />
                            }
                            label={option.label}
                            sx={{ ...props.sx }}
                        />
                    );
                })}
            </RadioGroup> :
            <></>
        }
        </>
        
    );
}

export default memo(ControlledRadioGroup,
    (prevProps, nextProps) => {
        if(lodash.isEqual(prevProps.sx, nextProps.sx) && lodash.isEqual(prevProps.inputProps, nextProps.inputProps) && lodash.isEqual(prevProps.value, nextProps.value) && lodash.isEqual(prevProps.disabled, nextProps.disabled) 
        && prevProps.onChange == nextProps.onChange && lodash.isEqual(prevProps.options, nextProps.options) && lodash.isEqual(prevProps.indexes, nextProps.indexes)
        && lodash.isEqual(prevProps.error, nextProps.error) && lodash.isEqual(prevProps.validate, nextProps.validate) && lodash.isEqual(prevProps.validQuestions, nextProps.validQuestions)) {
            return true;
        } else {
            return false;
        }
    }
);
