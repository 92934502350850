import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
    DataGridPro, 
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import {
    CircularProgress,
    Box,
} from '@mui/material';
import axios from 'axios';
import { APIURL } from '../../config';
import SaveViewButton from './SaveViewButton';
import LoadingSkeleton from './LoadingSkeleton';


// This is a custom DataGrid component that allows for the user to save their grid settings
// and have them persist between sessions. It also allows for the user to save their own
// custom views of the grid
// mandatory props:
//      gridId: the id of the grid. This is used to save the grid settings to the database
//      user: the user object
//      columns: the columns of the grid
//      rows: the rows of the grid
//      apiRef: the apiRef of the grid

// Besides the mandatory props, you can use this component just like you would use the DataGridPro component

// If you want to use a Custom Toolbar you can pass it as a function that returns the toolbar along
// with the SaveViewButton component. The function should take in params and spread them into the 
// SaveViewButton component like in the DefaultToolbar function below

// optional props:
//      onLoad: a function or array of functions that will be called when the grid loads. The function(s) will be passed the settings object
//      onSave: a function or array of functions that will be called when the user saves the grid settings. The function(s) will be passed the settings object

const ControlledDataGrid = (props) => {
    const [isLoadingSettings, setIsLoadingSettings] = useState(true);
    const [initialSettings, setInitialSettings] = useState(null);
    const [orderedColumns, setOrderedColumns] = useState(props.columns ? props.columns.reduce((acc, col) => ([...acc, col.field]), []) : []);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [selectionModel, setSelectionModel] = useState([]);
    const [density, setDensity] = useState("standard");
    const [rows, setRows] = useState(props.rows ? props.rows : []);

    const apiRef = props.apiRef;

    useEffect(async () => {
        let getGridSettings = await axios.get(`${APIURL}/datagrid`, { params: { user_id: props.user.emp_id, report_id: props.gridId } });
        if (getGridSettings.data.length > 0) {
            let settings = JSON.parse(getGridSettings.data[0].settings)
            setInitialSettings(settings);
            setColumnVisibilityModel({...props.columnVisibilityModel, ...settings.columnVisibilityModel});
            setSelectionModel(settings.selectionModel ? settings.selectionModel : []);
            setDensity(settings.density ? settings.density : "standard");
            if(props.onLoad) {
                Array.isArray(props.onLoad) ? props.onLoad.forEach((func) => func(settings)) : props.onLoad(settings);
            }
        } else {
            let settings = props.initialState ? props.initialState : null;
            setInitialSettings(settings);
            
            if (props.defaultSettings) {
                settings = props.defaultSettings;
                setInitialSettings(settings);
                setColumnVisibilityModel({...props.columnVisibilityModel, ...settings.columnVisibilityModel});
                setSelectionModel(settings.selectionModel ? settings.selectionModel : []);
                setDensity(settings.density ? settings.density : "standard");
            }
            
            settings = settings ? JSON.stringify(settings) : null;           
            let saveGridSettings = await axios.post(`${APIURL}/datagrid`, { user_id: props.user.emp_id, report_id: props.gridId, settings: settings });
        }
    }, []);

    useEffect(() => {
        if (initialSettings !== null) {
            setIsLoadingSettings(false);
        }
    }, [initialSettings]);

    useEffect(() => {
        if (props.rows) {
            setRows(props.rows);
        }
    }, [props.rows]);

    useEffect(() => {
        if(isLoadingSettings === false) {
            const handleDensityChange = (params) => {
                if(params.density.value !== density) {
                    setDensity(params.density.value);
                }
            }

            return apiRef.current.subscribeEvent('stateChange', handleDensityChange);
        }
    }, [apiRef, isLoadingSettings, density]);

    const handleColumnOrderChange = useCallback((params) => {
        setOrderedColumns((prevOrderedColumns) => {
          const newOrderedColumns = [...prevOrderedColumns];
          const oldIndex = params.oldIndex - props.checkboxSelection ? 1 : 0;
          const targetIndex = params.targetIndex - props.checkboxSelection ? 1 : 0;
          const oldColumn = prevOrderedColumns[oldIndex];
          newOrderedColumns.splice(oldIndex, 1);
          newOrderedColumns.splice(targetIndex, 0, oldColumn);
          return newOrderedColumns;
        });
      }, []);

    const handleColumnVisibilityModelChange = (newModel) => {
        setColumnVisibilityModel(newModel);
    }

    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
    }

    const columns = useMemo(() => {

        let allColumns = props.columns ? props.columns.reduce((acc, col) => ({ ...acc, [col.field]: col }), {}) : {};
        
        if(initialSettings?.columns?.dimensions) {
            Object.keys(initialSettings.columns.dimensions).forEach((field) => {
                try {
                    if(initialSettings.columns.dimensions.hasOwnProperty(field) && allColumns.hasOwnProperty(field))
                        allColumns[field].width = initialSettings.columns.dimensions[field].width;
                }
                catch(e) {
                    console.log('ERROR', e)
                }
            });
        }

        return orderedColumns.reduce((acc, field) => {
            return [...acc, allColumns[field]];
        }, []);
    }, [rows]);

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    };

    const handleSaveSettings = async () => {
        let settings = props.apiRef.current.exportState();
        settings.columnVisibilityModel = columnVisibilityModel;
        settings.selectionModel = selectionModel;
        settings.density = density;
        if(props.onSave) {
            Array.isArray(props.onSave) ? props.onSave.forEach((func) => func(settings)) : props.onSave(settings);
        }
        settings = JSON.stringify(settings);

        let saveGridSettings = await axios.put(`${APIURL}/datagrid`, { user_id: props.user.emp_id, report_id: props.gridId, settings: settings });
    }
    
    const DefaultToolbar = (params) => {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport/>
                <SaveViewButton {...params} />
            </GridToolbarContainer>
        );
    };

    return (
        <>
        {isLoadingSettings ?
            <Box sx={{ display: "flex", justifyContent: "center", height: "50vh", alignItems: "center" }}>
                <CircularProgress />
            </Box> :
            <DataGridPro
                {...props}
                sx={{ backgroundColor: '#F9F9F9', ...props.sx }}
                rows={rows}
                columns={columns}
                density={density}
                initialState={initialSettings}
                components={{
                    LoadingOverlay: LoadingSkeleton,
                    color: '#3fadf6',
                    ...props.components,
                    Toolbar: () => {
                        if(props.components?.Toolbar) {
                            return props.components?.Toolbar({ onClick: debounce(handleSaveSettings, 500) })
                        }
                        return DefaultToolbar({ onClick: debounce(handleSaveSettings, 500) });
                    }
                }}
                apiRef={apiRef}
                onColumnOrderChange={handleColumnOrderChange}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                selectionModel={selectionModel}
                onSelectionModelChange={handleSelectionModelChange}
            />
        }
        </>
    )
}

export default ControlledDataGrid;