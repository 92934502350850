import React, { useState, useEffect } from 'react';
import { Modal, Button, Box, Typography, TextField, Select, Grid, MenuItem, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import { APIURL } from '../../../config';
import moment from 'moment';

const ReccurrenceModal = (props) => {
    const [repeat, setRepeat] = useState(1);
    const [repeatType, setRepeatType] = useState('weeks');
    const [endType, setEndType] = useState('on');
    const [endDate, setEndDate] = useState(moment(props.schedule?.date).add(1, 'weeks').toDate());
    const [endAfter, setEndAfter] = useState(1);
    const [daysOfWeek, setDaysOfWeek] = useState(['Monday']);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        setEndDate(moment(props.schedule?.date).add(1, 'weeks').toDate());
    }, []);

    const handleSubmit = async (repeat, repeatType, daysOfWeek, endType, endDate, endAfter) => {
        let customer_ids = props.sessions?.filter(session => session.schedule_id === props.schedule.schedule_id).map(session => session.customer_id);

        try {
            setSubmitting(true);
            await axios.post(APIURL + '/master_schedule/recurrence/' + props.schedule.schedule_id, {
                schedule_id: props.schedule.schedule_id,
                resource_id: props.schedule.resource_id,
                customer_ids: customer_ids || [],
                repeat,
                repeatType,
                daysOfWeek,
                endType,
                endDate: moment(endDate).utc().format('YYYY-MM-DD'),
                endAfter,
                company_id: props.company_id,
                createdBy: props.user.emp_id
            });
            props.reloadSessionData();
            props.reloadScheduleData();
            props.onClose();
            props.onSuccess('Sessions scheduled successfully');
        } catch (error) {
            if (error.response) {
                if(error.response.data.type === 'overlap') {
                    if(window.confirm(error.response.data.message + '\nDo you want to continue anyway?')) {
                        await axios.post(APIURL + '/master_schedule/recurrence/' + props.schedule.schedule_id, {
                            schedule_id: props.schedule.schedule_id,
                            resource_id: props.schedule.resource_id,
                            customer_ids: customer_ids || [],
                            repeat,
                            repeatType,
                            daysOfWeek,
                            endType,
                            endDate: moment(endDate).utc().format('YYYY-MM-DD'),
                            endAfter,
                            company_id: props.company_id,
                            createdBy: props.user_id,
                            force: true
                        });
                        props.reloadSessionData();
                        props.reloadScheduleData();
                        props.onClose();
                        props.onSuccess('Sessions scheduled successfully');
                    }
                } else {
                    props.onError(error.response.data.message);
                }
            } else {
                props.onError('An error occurred while scheduling sessions');
            }
        }
        setSubmitting(false);
    };

    const getNextOccurrence = () => {
        let nextDate = moment(props.schedule?.date).utc().clone();
        if (repeatType === 'days') {
            nextDate.add(repeat, 'days');
        } else if (repeatType === 'weeks') {
            const dayOfWeekIndex = daysOfWeek.map(day => moment().day(day).day());
            const nextDayOfWeek = dayOfWeekIndex.find(day => moment().day(day).isAfter(nextDate));
            if (nextDayOfWeek !== undefined) {
                nextDate.day(nextDayOfWeek);
            } else {
                nextDate.add(repeat, 'weeks').day(dayOfWeekIndex[0]);
            }
        } else if (repeatType === 'months') {
            nextDate.add(repeat, 'months');
        }
        return nextDate.format('dddd, MMMM Do YYYY');
    };

    const getLastScheduledOccurrence = (startDate, interval, daysArray, endDate) =>{
        const start = moment(startDate);
        const end = moment(endDate);
        let lastOccurrence = null;
    
        const daysOfWeek = daysArray.map(day => day.toLowerCase());
    
        for (let i = 0; start.clone().add(i * interval, 'weeks').isBefore(end); i++) {
            let weekStart = start.clone().add(i * interval, 'weeks');
    
            for (let day of daysOfWeek) {
                let currentDay = weekStart.clone().day(day);
                if (currentDay.isBetween(start, end, null, '[]')) {
                    lastOccurrence = currentDay;
                }
            }
        }
    
        return lastOccurrence ? lastOccurrence.format('YYYY-MM-DD') : null;
    }


    const getLastOccurrence = () => {
        let lastDate = moment(props.schedule?.date).clone();
        if (endType === 'on') {
            while (true) {
                let nextDate = lastDate.clone();
                if (repeatType === 'days') {
                    if(nextDate.add(repeat, 'days').isAfter(endDate)) {
                        break;
                    } else {
                        lastDate = nextDate;
                    }
                } else if (repeatType === 'weeks') {
                    lastDate =  getLastScheduledOccurrence(props.schedule?.date, repeat, daysOfWeek, endDate)
                    break;
                } else if (repeatType === 'months') {
                    if(nextDate.add(repeat, 'months').isAfter(endDate)) {
                        break;
                    } else {
                        lastDate = nextDate;
                    }
                }
            }
        }
        return lastDate ? moment(lastDate).format('dddd, MMMM Do YYYY') : moment(props.schedule?.date).format('dddd, MMMM Do YYYY');
    };

    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                maxHeight: '80vh',
                overflow: 'hidden',
            }}>
                <Typography id="recurrence-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                    Recurrence
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1">
                            Repeat Every
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            size='small'
                            variant="outlined"
                            value={repeat}
                            onChange={(e) => setRepeat(e.target.value)}
                            inputProps={{ type: 'number', min: 1, max: 99 }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            fullWidth
                            size='small'
                            variant="outlined"
                            value={repeatType}
                            onChange={(e) => setRepeatType(e.target.value)}
                        >
                            <MenuItem value="days">Days</MenuItem>
                            <MenuItem value="weeks">Weeks</MenuItem>
                            <MenuItem value="months">Months</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', color: repeatType === 'weeks' ? 'black' : 'gray' }}>
                            Repeat On
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={0}>
                            <FormGroup>
                                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                                    <FormControlLabel
                                        key={index}
                                        label={day}
                                        sx={{ color: repeatType === 'weeks' ? 'black' : 'gray' }}
                                        control={
                                            <Checkbox
                                                checked={daysOfWeek.includes(day) && repeatType === 'weeks'}
                                                size='small'
                                                sx={{ p: .5, mr: 1 }}
                                                disabled={repeatType !== 'weeks'}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setDaysOfWeek([...daysOfWeek, day]);
                                                    } else if (daysOfWeek.length > 1) {
                                                        setDaysOfWeek(daysOfWeek.filter(d => d !== day));
                                                    }
                                                }}
                                            />
                                        }
                                    />
                                ))}
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                            Next occurrence: {getNextOccurrence()}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1">
                            Ends
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Select
                            fullWidth
                            size='small'
                            variant="outlined"
                            value={endType}
                            onChange={(e) => setEndType(e.target.value)}
                        >
                            <MenuItem value="on">On</MenuItem>
                            <MenuItem value="after">After</MenuItem>
                        </Select>
                    </Grid>
                    {endType === 'on' ?
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    value={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    renderInput={(params) => <TextField {...params} size='small' variant="outlined" />}
                                    maxDate={moment(props.schedule?.date).add(1, 'years').toDate()}
                                />
                            </LocalizationProvider>
                        </Grid>
                        :
                        <>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    size='small'
                                    variant="outlined"
                                    type="number"
                                    inputProps={{ min: 1, max: 99 }}
                                    value={endAfter}
                                    onChange={(e) => setEndAfter(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center' }} >
                                <Typography variant="body1">
                                    Times
                                </Typography>
                            </Grid>
                        </>
                    }
                    <Grid item xs={12}>
                        <Typography variant="body2" sx={{ fontStyle: 'italic' }}>
                            Last occurrence: {getLastOccurrence()}
                        </Typography>
                    </Grid>
                </Grid>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={() => handleSubmit(repeat, repeatType, daysOfWeek, endType, endDate, endAfter)} disabled={submitting}>
                    Schedule Sessions
                </Button>
            </Box>
        </Modal>
    );
}

export default ReccurrenceModal;
