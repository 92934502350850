import React, { Component } from 'react';
import { Card,Button } from 'react-bootstrap';
import moment from 'moment'

class MsgModalBox extends Component{
    renderTaskDates = (tasks) => {
        return tasks.map((task, i) => {
            return (
                <Card.Text key={i}>
                    {"Task " + (i + 1) + " Start Date: " + moment(task.tStrtDate).format("M[/]D[/]Y") + " End Date: " + moment(task.tEndDate).format("M[/]D[/]Y")}
                    <br/>
                </Card.Text>
            ) 
        });
    }
    render(){
        return(
            <Card style={{marginLeft: 0, marginRight: 0}}>
                <Card.Body className="overflow-auto">
                    <Card.Title>{this.props.text} {this.props.showError && 'Not '}{!this.props.edit?'Created':'Updated'}</Card.Title>
                    <hr/>
                    <Card.Text>
                        The Details have {this.props.showError && 'Not '}been saved to the database. <br/>
                        {this.props.text === 'Customer' && !!this.props.showError? `A ${this.props.text} with the same DDD_ID exist`: ''}
                        {/* Error: {this.props.showError} */}
                    </Card.Text>
                    <div className="d-flex justify-content-around">
                        {!this.props.showError && <Button style={{height: "40px",width: "10rem"}} variant="outline-info-new" onClick={(e)=> this.props.goHome(e)}>Go Back Home</Button>}
                        {!this.props.edit &&  <Button style={{height: "40px",width: "14rem"}} variant="primary" onClick={(e)=> this.props.addNew(e)}>{(this.props.showError)? `Add ${this.props.text} Again` : `Add Another ${this.props.text}`}</Button>}
                        {/* {this.props.edit && <Button style={{height: "40px",width: "14rem"}} variant="primary" onClick={(e)=> this.props.addNew(e)}>{(this.props.showError)? `Update ${this.props.text} Again` : `Show ${this.props.text} Details`}</Button>} */}
                    </div>
                    {(!this.props.edit && this.props.text === 'Task' &&this.props.taskDates.length !== 0) &&
                        <Card.Text className="mt-3">
                            {this.props.taskDates.length + (this.props.taskDates.length === 1 ? " Task has" : " Tasks have") + " been created"}
                            <br/>
                       </Card.Text> 
                    }
                    {(!this.props.edit && this.props.text === 'Task' && this.props.taskDates.length !== 0) && 
                            this.renderTaskDates(this.props.taskDates)
                    }
                </Card.Body>
            </Card>
        )
    }
}

export default MsgModalBox;