import React, { useEffect, useState } from 'react';
import { FormBuilder } from '@formio/react';
import { Button } from "react-bootstrap";
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import withStyles from "@mui/styles/withStyles";
import TextField from "@mui/material/TextField";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import axios from 'axios'
import { APIURL } from '../../../config';

const CssTextField = withStyles({
    root: {
        "& label.Mui-focused": {
            color: "#3FA7D6",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#3FA7D6",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "lightblue",
            },
            "&:hover fieldset": {
                borderColor: "#3FA7D6",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#3FA7D6",
            },
        },
    },
})(TextField);

export default function FormsBuilder(props) {
    const [open, setOpen] = useState(false);
    const [formTitle, setFormTitle] = useState('');
    const [formDesc, setFormDesc] = useState('');
    const [formType, setFormType] = useState('');
    const [formSchema, setFormSchema] = useState({});
    const [maxFormID, setMaxFormID] = useState(0);
    const [errorAlert, setErrorAlert] = useState('');
    const [successAlert, setSuccessAlert] = useState('');

    // useEffect(() => {
    //     // Add the external resources dynamically
    //     const addScript = (src) => {
    //         const script = document.createElement('script');
    //         script.src = src;
    //         script.async = true;
    //         document.head.appendChild(script);
    //         return script;
    //     };

    //     const addStylesheet = (href) => {
    //         const link = document.createElement('link');
    //         link.href = href;
    //         link.rel = 'stylesheet';
    //         document.head.appendChild(link);
    //         return link;
    //     };

    //     // Include external resources
    //     const scriptElement = addScript('https://cdn.form.io/formiojs/formio.full.min.js');
    //     const linkElement = addStylesheet('https://cdn.form.io/formiojs/formio.full.min.css');
    //     // addStylesheet('https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css');
    //     // addStylesheet('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');

    //     // Cleanup on component unmount
    //     return () => {
    //         // Remove added script and link elements to avoid memory leaks
    //         document.head.removeChild(scriptElement);
    //         document.head.removeChild(linkElement);
    //     };
    // }, []);

    useEffect(async () => {
        const maxFormID = await axios.get(`${APIURL}/task_form/formBuilder`);

        setMaxFormID(maxFormID.data[0][""]);
        setFormTitle('');
        setFormDesc('');
        setFormType('');
    }, [open]);

    const addForm = async () => {
        if (formTitle.replace(/\s/g, '').length !== 0 && formType.replace(/\s/g, '').length !== 0) {
            await axios.post(APIURL + `/task_form/formBuilder`, {
                form_id: maxFormID + 1,
                form_name: formTitle,
                form_description: formDesc,
                form_added_by: props.user.emp_id,
                form_type: formType,
                json_schema: formSchema,
                form_company: props.comp_id,
                public_form: 0,
            })
                .then(function (response) {
                    setSuccessAlert('Form successfully added')
                })
                .catch(function (error) {
                    console.log(error);
                    setErrorAlert('An error occured while trying to add the form')
                });
        } else {
            if (!formTitle) {
                alert(`Form Title is required to add the form`)
            }
            else if (!formType) {
                alert(`Form Type is required to add the form`)
            } else {
                alert(`Please enter the required fields to add the form`)
            }
        }
    };

    const handleAddButtonClick = async () => {
        if (formSchema.components.length <= 1) {
            setErrorAlert('Atleast one component is needed to add the form');
        } else {
            setOpen(true);
        }
    };

    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorAlert('');
        setOpen(false);
    }

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSuccessAlert('');
        setOpen(false);
    }

    return (
        <div style={{ marginRight: '40px' }}>
            <Typography
                style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "Lato",
                    marginBottom: 10,
                }}
            >
                Form Builder
            </Typography>
            <div style={{ marginBottom: 10 }}>
                <h5>Welcome to the Form Builder!</h5>
                <p>
                    With this tool, you can easily create custom forms for your needs.
                </p>
                <p>
                    To get started, simply drag and drop the desired form components from the left panel onto the canvas. To customize a component hover over the component and click the "Edit" button on the top-right to set properties like labels, validation rules, and more. You can also click and hold the "Move" button to drag the component at your desired location. Click the "Remove" button to remove the component from the form.
                </p>
                <p>
                    Once you're satisfied with your form, click the "Add Form" button to add it to your list of forms.
                </p>
            </div>
            <hr style={{ borderTop: '1px solid #ccc', margin: '20px 0' }} />
            <div>
                <FormBuilder form={{ display: 'form' }} options={{ builder: { premium: false } }} onChange={(schema) => setFormSchema(schema)} />
            </div>
            <hr style={{ borderTop: '1px solid #ccc', margin: '20px 0' }} />
            <div>
                <Button
                    style={{
                        width: '10%',
                        marginTop: '30px',
                    }}
                    onClick={() => handleAddButtonClick()}
                >
                    Add Form
                </Button>
            </div>
            <Dialog fullWidth={true} maxWidth={'sm'} open={open} onClose={() => setOpen(false)}>
                <DialogActions>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={() => setOpen(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogActions>
                <DialogContent sx={{ paddingTop: '0px' }}>
                    <h4>Add Form</h4>
                    <CssTextField
                        value={formTitle}
                        onChange={(event) => {
                            setFormTitle(event.target.value)
                        }}
                        style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                        inputProps={{ maxLength: 50 }}
                        label="Form Title *"
                        id="fTitle"
                        error={formTitle ? false : true}
                    />
                    <CssTextField
                        value={formDesc}
                        onChange={(event) => {
                            setFormDesc(event.target.value)
                        }}
                        style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                        inputProps={{ maxLength: 50 }}
                        label="Form Description"
                        id="fDesc"
                    />
                    <CssTextField
                        value={formType}
                        onChange={(event) => {
                            setFormType(event.target.value)
                        }}
                        style={{ margin: '5px', marginBottom: '20px', width: '100%' }}
                        inputProps={{ maxLength: 50 }}
                        label="Form Type *"
                        id="fType"
                        error={formType ? false : true}
                    />
                    <Button
                        style={{
                            width: '100%',
                            padding: '5px',
                            margin: '5px',
                        }}
                        onClick={() => addForm()}
                    >
                        Add Form
                    </Button>
                </DialogContent>
            </Dialog>
            <Snackbar open={successAlert != ''} autoHideDuration={5000} onClose={handleSuccessAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"success"} elevation={6} variant="filled" {...props}>
                    {successAlert}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={handleSuccessAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
            <Snackbar open={errorAlert != ''} autoHideDuration={5000} onClose={handleErrorAlertClose}>
                <MuiAlert sx={{ width: '100%' }} severity={"error"} elevation={6} variant="filled" {...props}>
                    {errorAlert}
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.25 }}
                        onClick={handleErrorAlertClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </MuiAlert>
            </Snackbar>
        </div>
    )
}