import React, { useState, useEffect } from "react"
import { Button } from 'react-bootstrap'
import {APIURL} from '../../config'
import axios from 'axios'
// import moment from "moment"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import { isArray } from "@material-ui/data-grid"
// import { DataGrid, GridToolbar, GridApi
//     // GridRowId
// } from '@mui/x-data-grid';
import {FormBuilder} from '@ginkgo-bioworks/react-json-schema-form-builder';
import Form from "@rjsf/core";
import './FormBuilder.css'


export default function FormBuilderContainer(props) {
    // Declare state variables
    const [schema, setSchema] = useState('');
    const [uischema, setUISchema] = useState('');
    const [previewSchema, setPreviewSchema] = useState({});
    const [taskType, setTaskType] = useState('');

    const [saveFormSuccess, setSaveFormSuccess] = useState(false);
    const [saveFormFail, setSaveFormFail] = useState(false);
// eslint-disable-next-line
    let previewForm;

    useEffect(() => {
        let mounted = true;

        const fetchData = async() =>{
            //const res1 = await getSummaryData(currentWeekDates[0],currentWeekDates[6]);
            if(mounted){
                //setSummaryData(res1);
                //setTimeSheetData(res2);
            }
        }
        fetchData();
        return () => { mounted = false };
        // eslint-disable-next-line
    }, []);

    async function saveForm(){
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
             Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }

        if(schema !== '' && schema !== "{\"type\":\"object\",\"title\":\"\"}" && schema !== "{\"type\":\"object\",\"title\":\"\",\"description\":\"\"}"){
            // eslint-disable-next-line 
            let saveFormApi = await axios.post(
                APIURL+'/forms',
                {
                    value: schema,
                    com_id: props.comp_id,
                    tas_type: (taskType === '' ? null: taskType),
                }
            )
            setSaveFormSuccess(true);
        }else{
            setSaveFormFail(true);
        }

        //Reset Schema
        setSchema('');
        setUISchema('');
        setPreviewSchema({});
    }

    return (
        <div id="formbuilder-main">
            <h2 style={{'textAlign': 'center'}}>Form Builder</h2>
            <FormBuilder
                schema={schema}
                uischema={uischema}
                onChange={(newSchema: string, newUiSchema: string) => {
                    setSchema(newSchema);
                    setUISchema(newUiSchema);
                    setPreviewSchema(JSON.parse(newSchema));
                }}
            />
            <hr></hr>
            <h2 style={{'textAlign': 'center'}}>Form Preview</h2>
            {(schema === '' || schema === "{\"type\":\"object\",\"title\":\"\"}") && 
                <p style={{'textAlign': 'center', color: 'gray'}}>Start Creating Your Form To See a Preview Here</p>
            }
            <Form schema={previewSchema} onSubmit={() => saveForm()}>
                <hr></hr>
                <Autocomplete
                    className="formbuilder-tasktype-autocomplete"
                    options={[{title: 'Checklist', id: 1}, {title: 'Planning', id: 2}, {title: 'Eligibility Determination', id: 3}, {title: 'On-boarding', id: 4}, {title: 'Unusual Incident', id: 5}, {title: 'Home Visit', id: 6}, {title: 'Visit', id: 7}, {title: 'Phone Call', id: 8}, {title: 'teleMedicine', id: 9}]}
                    getOptionLabel={(option) => option.title}
                    style={{ width: '300px', 'marginBottom': '5px'}}
                    renderInput={(params) => <TextField {...params} label="Associate Form With Task Type" variant="outlined" />}
                    onSelect={(e)=> {
                        if(e.target.value !== ''){
                            setTaskType(e.target.value);
                        }
                    }}
                />
                <Button type="submit">Save Form</Button>
            </Form>
            <Snackbar anchorOrigin={{  vertical: 'bottom', horizontal: 'center'  }} open={saveFormSuccess} autoHideDuration={6000} onClose={()=> setSaveFormSuccess(false)}>
                <MuiAlert onClose={()=> setSaveFormSuccess(false)} severity="success">
                    Form Saved!
                </MuiAlert>
            </Snackbar>
            <Snackbar anchorOrigin={{  vertical: 'bottom', horizontal: 'center'  }} open={saveFormFail} autoHideDuration={6000} onClose={()=> setSaveFormFail(false)}>
                <MuiAlert onClose={()=> setSaveFormFail(false)} severity="error">
                    Form Failed To Saved.
                </MuiAlert>
            </Snackbar>
        </div>
    );
}