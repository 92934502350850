import React,{Component} from 'react'
import HomePie from './HomePie'
import {Form,Col} from 'react-bootstrap';
import axios from 'axios'
import {APIURL} from '../../config'


class ProjectTaskAnalytics extends Component{
    state = {
        data: [],
        index: null,
        isLoading : true,
        selected: null
    }
    async componentDidMount(){
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        let data = await axios.get(APIURL+'/home', {params: {comp_id: this.props.comp_id}})
        // console.log(data.data)
        this.setState({data: data.data}) 
        this.setState({isLoading: false}) 
        
    }

    onProSelect(e){
        let pid = e.target.value
        if(pid === ''){
            this.setState({index: null,selected: null})
            return
        }
        let foundIndex = this.state.data.map((pro, indx)=>{
            if(pro.pid === +pid){
                return indx
            }
            return -1
        }).filter((item)=> (item !== -1))[0]
        console.log(foundIndex)
        this.setState({index: foundIndex,selected: pid})
    }

    render(){
        const {data,index} = this.state
        let total = {complete: 0,upcoming: 0,ongoing: 0 }
        data.map((num)=> {
            // console.log(num)
            total = {...total , complete: total.complete + num.complete,upcoming:total.upcoming +  num.upcoming  ,ongoing:total.ongoing + num.ongoing }
            return num
        })
        console.log(total, 'where am i ')
        let proDropDowns = data.map((pro)=> (<option key={pro.pid} value={pro.pid}>{pro.pname}</option>))
        return(
            <div className="text-center" style={{border: '1px solid #3FA7D6', padding: 10}}>
                <div className="d-flex flex-row">
                    <p className="p-1">Project Task Information</p>
                    <Form.Group className="m-0" as={Col} controlId="pContact">
                        <Form.Control value={!this.state.selected?"":this.state.selected} as="select" onChange={(e)=>{this.onProSelect(e)}}>
                            <option value="">Select Project</option>
                            {proDropDowns}
                        </Form.Control>
                    </Form.Group>
                </div>
                <hr style={{background: '#3FA7D6'}} className="m-0"/>
                <HomePie isLoading={this.state.isLoading} length={this.state.data.length} data={index !== null?data[index]:total}/>
            </div>
        )
    }
}
export default ProjectTaskAnalytics