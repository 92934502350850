import axios from "axios";

import { APIURL } from '../config'

const authToken = window.localStorage.getItem('Fw4_access_Token');

axios.interceptors.request.use(function (config) {
  config.headers['Content-Type'] = 'application/json';
  config.headers['Access-Control-Allow-Origin'] = '*';
  config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
  config.headers['ACCESS-CONTROL-ALLOW-CREDENTIAL'] = true
  config.headers.Authorization = `Bearer ${authToken}`;
  return config;
});

const requests = {
  get: async (url) => {
    return axios
      .get(APIURL + url)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        throw res;
      });
  },

  post: async (url, body) => {
    return axios
      .post(APIURL + url, body)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        throw e.response;
      });
  },

  put: async (url, body) => {
    return axios
      .put(APIURL + url, body)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        throw e.response;
      });
  },

  patch: (url, body) => {
    return axios
      .patch(APIURL + url, body)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        throw res.response;
      });
  },

  delete: async (url) => {
    return axios
      .delete(APIURL + url)
      .then((res) => {
        return res;
      })
      .catch((res) => {
        throw res;
      });
  },
};

const PreAuth = {
  getPreAuthTask: async (date) => {
    try {
      return await requests.post("/preAuth/task", { date, authToken });
    } catch (e) {
      throw e;
    }
  }
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  PreAuth,
};
