/* THIS PAGE SHOULD BE EMPTY FOR ANY PRODUCTION BUILDS */
import React, { useState, useEffect } from 'react';
import LoadingCircle from "./LoadingCircle"
import FWButton from "./FWButton"
import EditTimeSheetModal from "./EditTimeSheetModal"

export default function DebugContainer(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);

    useEffect(() => {
        
    }, []);

    const toggleEdit = () =>{
        setShowEdit(!showEdit)
    }

    return(
        <div style={{textAlign: 'center'}}>
            <p>This is a 'LoadingCircle' component</p>
            <LoadingCircle></LoadingCircle>
            <p>This is a 'FWButton' component</p>
            <FWButton onClick={toggleEdit}></FWButton>
            <EditTimeSheetModal active={showEdit} onHide={toggleEdit}></EditTimeSheetModal>
        </div>
    );
}