import React, { useState, useEffect } from "react"
// eslint-disable-next-line
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { APIURL } from '../../config'
import axios from 'axios'
import moment from "moment"
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/lab/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import { isArray } from "@material-ui/data-grid"
// eslint-disable-next-line
import {
    DataGrid, GridToolbar, GridApi, GridCellParams
} from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import "./SummaryTable.css"
import Typography from "@mui/material/Typography";
import CircularProgress from '@mui/material/CircularProgress';


export default function TimesheetsSummaryTable(props) {

    const [rows, setRows] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = [
        { field: 'id', headerName: 'Employee ID', flex: 1, hide:true },
        { field: 'timesheet_id', headerName: 'Timesheet ID', flex: 1, hide:true },
        { field: 'employee_name', headerName: 'Employee Name', flex: 1 },
        { field: 'total_hours', headerName: 'Total Hours', flex: 1 },
        { field: 'timesheet_status', headerName: 'Timesheet Status', flex: 1 },
        { field: 'submitted_on', headerName: 'Submitted On', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => {
                return (
                    <div>
                        <button style={{ borderRadius: 20 }} className='btn btn-sm btn-info' onClick={() => props.setCurrentEmployeeById(params.id)}> <i className="far fa-edit"></i> </button>
                    </div>
                )
            }
        }
    ];

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        setRows([]);
        const fetchTimeSheetSummaryData = async () => {
            await axios.get(`${APIURL}/time_sheet/v2/summary`, {
                params: {
                    company_id: props.company_id,
                    pay_period_id: props.currentPayPeriod.pay_period_id,
                    ...(props.user.type.toLowerCase().includes('manager') && { manager_employee_id: props.user.emp_id })
                }
            }).then(response => {
                setRows(response.data.map((row) => {
                    return {
                        id: row.emp_id,
                        timesheet_id: row.time_sheet_id,
                        employee_name: row.employee_name,
                        total_hours: row.total_hours.toFixed(2),
                        timesheet_status: row.time_sheet_status ? row.time_sheet_status : 'Timesheet Not Created',
                        submitted_on: row.submitted_on ? moment(row.submitted_on.replace(/Z/g, "")).format('MMM DD, YYYY') : 'N/A'
                    }
                }));
            }).catch(error => {
                console.log(error);
            });
        }
        if(props.currentPayPeriod){
            fetchTimeSheetSummaryData();
        }
    }, [props.currentPayPeriod]);

    const getPayrollStatus = () => {
        if(props.currentPayPeriod){
            return <strong>{`Pay period for ${props.currentPayPeriod.payroll_type.toLowerCase()} starting on ${moment(props.currentPayPeriod.start_date.replace(/Z/g, "")).format('ll')}`}</strong>
        }
        return <strong>{`Could Not Find Pay Cycle`}</strong>
    }

    return (
        <>
            <div id="timesheets-summarytable-weeknavigation">
                <Button className="FW-Button" style={{ whiteSpace: 'nowrap', minWidth: '160px', paddingTop: '2px' }} id="previousweekbutton" onClick={() => props.handlePreviousPayPeriod()}> Previous Pay Period </Button>
                {props.currentPayPeriod && 
                    <h2 id="current-week-display">{moment(props.currentPayPeriod.start_date.replace(/Z/g, "")).format("MMM[ ]DD")} - {moment(props.currentPayPeriod.end_date.replace(/Z/g, "")).format("MMM[ ]DD")}, {moment(props.currentPayPeriod.start_date.replace(/Z/g, "")).format("YYYY")}</h2>
                }
                <Button className="FW-Button" style={{ whiteSpace: 'nowrap', minWidth: '160px', paddingTop: '2px' }} id="nextweekbutton" onClick={() => props.handleNextPayPeriod()}> Next Pay Period </Button>
            </div>
            {props.isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', marginTop: '50px' }}>
                    <CircularProgress />
                </div>
            ) : (
                <div id='timesheets-summarytable-main w-full h-96' >
                    <div id="timesheets-summarytable-actions">
                        {getPayrollStatus()}
                        <DropdownButton id="actions-dropdown" title="Bulk Actions">
                            <Dropdown.Item onClick={() => {}}>Approve </Dropdown.Item>
                            <Dropdown.Item onClick={() => {}}>Lock</Dropdown.Item>
                            <Dropdown.Item onClick={() => {}}>Unapprove</Dropdown.Item>
                            <Dropdown.Item onClick={() => {}}>Unlock</Dropdown.Item>
                        </DropdownButton>
                    </div>

                    {(rows != []) &&
                        <div style={{ minHeight: '80vh', width: '100%' }} id="timesheets-summarytable-table">
                            <DataGrid
                                sx={{height: '80vh'}}
                                rows={rows}
                                columns={columns}
                                components={{
                                    Toolbar: GridToolbar,
                                }}
                                checkboxSelection={true}
                                disableSelectionOnClick
                                onSelectionModelChange={(ids) => {
                                    setSelectedRows(ids);
                                }}
                                getCellClassName={(params) => {
                                    if (params.field === 'timesheet_status') {
                                        if (params.value === "ACTIVE") {
                                            return 'active-cell';
                                        }
                                        else if (params.value === "SUBMITTED") {
                                            return 'submitted-cell';
                                        }
                                        else if (params.value === "APPROVED") {
                                            return 'approved-cell';
                                        }
                                        else if (params.value === "OPEN" || params.value === "Timesheet Not Created") {
                                            return 'open-cell';
                                        }
                                        else if (params.value === "ARCHIVED") {
                                            return 'archived-cell';
                                        }
                                    }
                                }
                                }
                            />
                        </div>
                    }
                </div>
            )}
        </>
        
    );
}