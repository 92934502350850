import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';


const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
  })(TextField);

const FinalDets = (props) => {

    const [billableByDefault, setBillableByDefault] = useState(true);

    useEffect(() => {
        let billableByDefault = true;
        let billableByDefaultSetting = props.user ? props.user.comp_settings.find((setting) => setting.feature_id === 56) : false;
        console.log(billableByDefaultSetting)
        if (!!billableByDefaultSetting && !billableByDefaultSetting?.feature_value) {
            billableByDefault = false;
        }
        setBillableByDefault(billableByDefault);
        console.log(props)
    }, [])

    let empList = null
    if (props.edit) {
        // empList = props.empList.map((emp, index) => <option key={index} value={emp.emp_id}>{emp.emp_first_name + " " + emp.emp_last_name}</option>)
        empList = props.empList.map((emp, index) => {return {value:emp.emp_id,title: emp.emp_first_name + " " + emp.emp_last_name}})
    } else {
        // empList = props.empList.map((emp, indx) => <option key={indx} value={emp.id}>{emp.employee_name}</option>)
        empList = props.empList.map((emp, indx) => { 
            // console.log(emp)
            if(!!emp){
                return { value:emp.id,title: emp.employee_name
                }
            }
           else{
                return null
            }
        }).filter((em)=> !!em)
    }
    return (
        <div className='mx-3'>

        <Autocomplete
            id="tAssTo"
            value={empList.find((emp)=> emp.value === props.formVals.tAssTo) || null}
            className={`my-3 w-100`}
                onChange={(e, newValue)=> {
                console.log(newValue)

                let value = empList.find((emp)=> emp.title === newValue.title)
                // console.log(value)
                if(!!value){
                    props.handleProjLoc(value.value, 'tAssTo')
                    // props.handleBasicDetsChange(e)
                }
                return
                
            }}
            options={empList}
            getOptionLabel={(option) => option.title}
            // style={{ width: 450 }}
            renderInput={(params) => <CssTextField {...params} label="ASSIGNED TO *" variant="outlined" />}
        />
            <Form.Group as={Col}
            // onChange={props.handleFinalDetsChange}
            >
                <Form.Label>PRIORITY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Row  className="mx-1" style={{display: 'flex', gap: '10px', textAlign: 'center' }}>
                    <Form.Check
                        type="radio"
                        label="Low"
                        name="tPriority"
                        id="radioLow"
                        required
                        onChange={props.handleFinalDetsChange}
                        checked={props.formVals.tPriority === "Low"}
                    />
                    <Form.Check
                        type="radio"
                        label="Medium"
                        name="tPriority"
                        id="radioMed"
                        required
                        onChange={props.handleFinalDetsChange}
                        checked={props.formVals.tPriority === "Med"}
                    />
                    <Form.Check
                        type="radio"
                        label="High"
                        name="tPriority"
                        id="radioHigh"
                        required
                        onChange={props.handleFinalDetsChange}
                        checked={props.formVals.tPriority === "High"}
                    />
                </Row>
            </Form.Group>

            <Form.Group as={Col}>
                <Form.Label>BILLABLE TO CUSTOMER</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Row className="mx-1" style={{display: 'flex', gap: '10px', textAlign: 'center' }}>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        name="tIsBillable"
                        id="radioBillYes"
                        required
                        onChange={(e) => {
                            console.log(e.target.id)
                            props.handleFinalDetsChange(e)
                            setBillableByDefault(true)
                        }}
                        checked={billableByDefault === true}
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        name="tIsBillable"
                        id="radioBillNo"
                        required
                        onChange={(e) => {
                            props.handleFinalDetsChange(e)
                            setBillableByDefault(false)
                        }}
                        checked={props.formVals.tIsBillable === false}
                    />
                </Row>
            </Form.Group>

            <Form.Group as={Col}>
                <Form.Label>SIGNATURE REQUIRED</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Row className="mx-1" style={{display: 'flex', gap: '10px', textAlign: 'center' }}>
                    <Form.Check
                        type="radio"
                        label="Yes"
                        name="tSigReq"
                        id="radioSigYes"
                        required
                        onChange={props.handleFinalDetsChange}
                        checked={props.formVals.tSigReq === 1}
                    />
                    <Form.Check
                        type="radio"
                        label="No"
                        name="tSigReq"
                        id="radioSigNo"
                        required
                        onChange={props.handleFinalDetsChange}
                        checked={props.formVals.tSigReq === 0}
                    />
                </Row>
            </Form.Group>

            <Autocomplete
                id="tContacts"
                className={`my-3 w-100`}
                multiple
                value={props?.formVals?.tContacts}
                options={props?.contactList || []}
                renderInput={(params) => <CssTextField {...params} label="CONTACTS" variant="outlined" />}
                onChange={(event, value) => props.handleFinalDetsChange({ target: { id: 'tContacts', value: value } })}
                onSelect={(e)=> {console.log(props?.contactList)}}
            />
            <TextField id="tNotes" label="Notes" variant="outlined" style={{ marginBottom: '20px', width: '100%' }}
                value={props.formVals.tComment}
                onChange={(event) => props.handleFinalDetsChange(event)}
                multiline
                minRows={4}
                renderInput={(params) => <CssTextField {...params} label="ASSIGNED TO *" />}
                inputProps={{ maxLength: 4000 }}
            />

            {/*}
             <Form.Group as={Col} controlId="tComment">
                <Form.Label>NOTES</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={props.formVals.tComment} type="text" onChange={props.handleFinalDetsChange} required />
            </Form.Group>
             */}
            {/*<CssTextField
                className={`my-3 w-100`}
                label="NOTES/COMMENTS"
                value={props.formVals.tComment}
                onChange={props.handleFinalDetsChange} 
                variant="outlined"
                id="tComment"
            />*/}

            <Button className={`w-100`} variant="primary" block type="submit">{props.edit ? "Submit" : "Create Task"}</Button>
        </div>
    )
};

export default FinalDets;