import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { APIURL } from "../../config";
import { Typography } from "@mui/material";
import EvvReport from "./EvvReport";
import EvvInvoice from "./EVVInvoice";
import EvvCard from "./EvvCard";

const EVVContainer = (props) => {
  const [searchText, setSearchText] = useState("");

  const [selectedScreen, setSelectedScreen] = useState(null);

  const onSubmitSearch = (e) => {
    console.log("Search Text: ", props);
    e.preventDefault();
  };

  return (
    <Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              fontFamily: "Lato",
              marginBottom: 40,
            }}
          >
            EVV
          </Typography>
        </Grid>

        {/* <Grid item xs={5}>
          <Form inline onSubmit={onSubmitSearch} className="mb-4">
            <FormControl
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              type="text"
              value={searchText}
              placeholder="Search"
              className="form-control w-50 mr-1"
            />
            <Button
              type="submit"
              variant="outline-primary"
              style={{
                border: "solid 1px #95D0EB",
                marginLeft: "-5px",
                borderRadius: "0 5px 5px 0",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <i className="fas fa-search"></i>
            </Button>
          </Form>
        </Grid> */}

        <Grid item xs={5}></Grid>

        {/* <Grid
          item
          xs={4}
          container
          direction="row"
          justifyContent="flex-end"
          className="mb-2"
          alignItems="center"
        >
          <button
            style={{ outline: "none" }}
            type="button"
            className="btn btn-sm"
            data-toggle="modal"
            data-target="#staticBackdropA"
          >
            <i className="fas fa-filter"></i> Filters
          </button>
          <div className="dropdown">
            <button
              style={{ outline: "none" }}
              className="btn dropdown-toggle mb-1"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>
                <i className="fas fa-sort-amount-up"></i>
              </span>{" "}
              SORT BY
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
              <button
                onClick={() => console.log("title")}
                className="dropdown-item"
                type="button"
              >
                Title
              </button>
              <button
                onClick={() => console.log("date")}
                className="dropdown-item"
                type="button"
              >
                Date
              </button>
            </div>
          </div>
        </Grid> */}
      </Grid>

      <Grid container style={{ bottom: "10px", position: "relative" }}>
        <Grid item xs={9}>
          {props.data.map((e) => (
            <EvvCard
              data={e}
              openReport={(e) => {
                console.log("<<<<<<<<<<<<<< ", e);
                setSelectedScreen(e.report_name);
              }}
              markFavourite={props?.markFavourite}
            />
          ))}
        </Grid>
      </Grid>

      <Grid container style={{ bottom: "10px" }}>
        <Grid item xs={12}>
          {selectedScreen === "Reports" ? <EvvReport {...props} screenName={'Reports'} /> : null}
          {selectedScreen === "Invoice" ? <EvvInvoice {...props} screenName={'Invoice'}/> : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EVVContainer;
