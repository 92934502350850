import { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Draggable from 'react-draggable';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const FollowUpItemPopup = (props) => {
    const [expand, setExpand] = useState(false);

    return (
        <Draggable>
            <Card variant="outlined" sx={{ ...props.sx, width: "450px", maxHeight: "600px", zIndex: 2, display: props.read ? "none" : "block" }}>
                <CardHeader 
                    disableTypography 
                    title={<Typography sx={{ fontWeight: "bold" }}>Follow Up Comments</Typography>}
                    action={
                        <ExpandMore
                            expand={expand}
                            onClick={() => setExpand(!expand)}
                            sx={{ marginTop: "-7px" }}

                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    }
                    sx={{ height: "50px" }}
                />
                <Box sx={{ padding: "0px 1rem",  overflowY: "auto", height: expand ? "550px" : "inherit"  }}>
                    <Collapse in={expand} timeout="auto" unmountOnExit>
                        <Stack divider={<Divider />}>
                            {
                                props.comments.length > 0 ? props.comments.map((comment, index) => {
                                    return (
                                        <Stack key={index} spacing={props.spacing} sx={{ width: '100%', my: .5}}>
                                            <Box sx={{ width: '100%', display: "flex", alignItems: "flex-start", marginBottom: 1}}>
                                                <Typography sx={{ marginBottom: "0px !important", marginRight: props.indent }}><Typography sx={{ display: "inline", fontWeight: "bold", marginRight: props.indent }}>{"Question: "}</Typography>{comment?.question}</Typography>
                                            </Box>
                                            <Box sx={{ width: '100%', display: "flex", alignItems: "flex-start"}}>
                                                <Typography sx={{ marginBottom: "0px !important", marginRight: props.indent }}><Typography sx={{ display: "inline", fontWeight: "bold", marginRight: props.indent }}>{"Comment: "}</Typography>{comment?.comment || "No Comment"}</Typography>
                                            </Box>
                                        </Stack>
                                    )
                                }) : <Typography sx={{ textAlign: "center", marginTop: "1rem" }}>No Comments</Typography>
                            }
                        </Stack>
                    </Collapse>
                </Box>    
            </Card>
        </Draggable>
    );
}

export default FollowUpItemPopup;

