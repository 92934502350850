import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import moment from 'moment';


const FilterSortBy = (props) => { 
    const [filterClass, setClass] = useState('');
    const [specifier, setSpecifier] = useState('');

    const sortBy = (param) => {
        let artifactArr = props.artifactsDisplay

        if(param === 'name'){
            artifactArr = artifactArr.sort((a,b)=>a.file_name.localeCompare(b.file_name));
        }else{
            artifactArr = artifactArr.sort((a,b)=> moment(a.submitted) - moment(b.submitted))
        }

        props.setArtifacts([...artifactArr]);
    }

    const filterBy = () => {
        let artifactArr = props.artifacts
        if(filterClass === 'Task'){
            artifactArr = artifactArr.filter((art)=> art.task_id === +specifier)
        }else if(filterClass === 'Employee'){
            artifactArr = artifactArr.filter((art)=> art.employee_id === +specifier)
        }else if(filterClass === 'All'){
            artifactArr = props.artifacts.filter((art)=> !!art.show)
        }else{
            artifactArr = artifactArr.filter((art)=> art.taskType === specifier)
        }
        props.setArtifacts(artifactArr);
        setClass('');
        setSpecifier('');
    }

    const filterHandler = (e) => {
        let id = e.target.id;
        let val = e.target.value;

        if(id === 'filter1'){
            setClass(val);
            setSpecifier('');
        }else{
            setSpecifier(val);
        }
    }

    return (
        <div className='filter-sortby-container'>
            <div style={{display: 'flex'}}>
                <button style={{outline: 'none'}} type="button" className="btn btn-sm bg-white m-1" data-toggle="modal" data-target="#staticBackdrop">
                    <i className="fas fa-filter"></i> Filters
                </button>
                <div className="dropdown m-1">
                    <button style={{outline: 'none', fontSize: 17}} className="btn btn-sm dropdown-toggle bg-white" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span><i className="fas fa-sort-amount-up"></i></span> SORT BY
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                        <button onClick={()=> sortBy('name')} className="dropdown-item" type="button">Name</button>
                        <button onClick={()=> sortBy('date')} className="dropdown-item" type="button">Date</button>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">Filter By</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div className="modal-body">
                    <Form.Group controlId="filter1">
                        <Form.Label>Class</Form.Label>
                        <Form.Control onChange={(e)=> filterHandler(e)} as="select" value={!filterClass?'':filterClass}>
                        <option value=''>Select Class</option>
                        <option>All</option>
                        <option>Task</option>
                        <option>Task Type</option>
                        <option>Employee</option>
                        </Form.Control>
                    </Form.Group>
                    {filterClass !== '' && filterClass !== 'All' && <Form.Group controlId="filter2">
                        <Form.Label>Specify</Form.Label>
                        <Form.Control onChange={(e)=> filterHandler(e)} as="select" value={!specifier?'':specifier}>
                        <option value=''>Select Specifier</option>
                        {filterClass === 'Task' &&  props.taskList}
                        {filterClass === 'Task Type' && props.taskTypeList}
                        {filterClass === 'Employee' && props.empList}
                        </Form.Control>
                    </Form.Group>}
                    </div>
                    <div className="modal-footer">
                    <button onClick={()=> filterBy()} type="button" className="btn btn-info" data-dismiss="modal">Save</button>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default FilterSortBy;