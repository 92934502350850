import React, { useEffect, useState } from 'react'
import { ReactBingmaps } from 'react-bingmaps'
import { BINGAPIKEY } from '../../config'
import axios from 'axios'
import { Modal, Button } from 'react-bootstrap'
import { APIURL } from '../../config'
import './top_appointment.css'
import CheckoutModal from '../Checkout/CheckoutModal'
import SubtasksAddModal from '../Checkout/SubtasksAddModal'
import moment from 'moment'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material' 

const SessionCard = (props) => {
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    const [name, setName] = useState('')
    const [resourceName, setResourceName] = useState('')
    const [description, setDescription] = useState('')
    const [lon, setLon] = useState(0)
    const [lat, setLat] = useState(0)
    const [address, setAddress] = useState('')
    const [contact, setContact] = useState('')

    useEffect(() => {
        console.log(props.user);
        setStartTime(props.detail.detail.start_time)
        setEndTime(props.detail.detail.end_time)
        setName(props.detail.detail.schedule_name)
        setDescription(props.detail.detail.notes)
        setLon(props.detail.detail.loc_lon)
        setLat(props.detail.detail.loc_lat)
        setResourceName(props.detail.detail.resource_name)
        setAddress(props.detail.detail.loc_addr_line1 + ', ' + props.detail.detail.loc_addr_line2 + ', ' + props.detail.detail.loc_city + ', ' + props.detail.detail.loc_state + ', ' + props.detail.detail.loc_zip)
    },[props])

    return (
        <div className='card'>
            <div className='card_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    {!moment(startTime).isValid() ? startTime : moment(startTime).format('YYYY-MM-DD, hh:mm A')}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', whiteSpace: 'nowrap' }}>
                    <i className="far fa-calendar-alt"></i>
                    <div style={{ marginLeft: '2%' }}>{moment(startTime).format('dddd, MMM DD')}</div>
                </div>
                <div />
            </div>
            <div style={{ display: 'flex', width: '100%', margin: '1%' }}>
                <div style={{ width: '70% ', marginRight: '2%' }}>
                    <div className='card_title_card'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className='card_title_card_bullet' />
                            <div>{name}</div>
                        </div>
                        <div className='card_start_time'>
                            <div style={{ marginRight: '1%' }}><i className="far fa-clock"></i></div>
                            <div>{!moment(startTime).isValid() ? startTime : moment(startTime).format('hh:mm A')} - {!moment(endTime).isValid() ? endTime : moment(endTime).format('hh:mm A')}</div>
                        </div>
                    </div>
                    <div className='card_map'>
                        <ReactBingmaps
                            id={props.index}
                            bingmapKey={BINGAPIKEY}
                            center={[lat, lon]}
                            pushPins={[{ location: [lat, lon], option: { color: '#06D6A0' } }]}
                            navigationBarMode={"compact"}
                        />
                    </div>
                    <div className='card_check_in'>
                            <div className='card_check_in_button'
                                style={{ color: 'white', background: '#06D6A0', height:'40px', overflow:'hidden',marginTop: '1%'}}
                            >
                                <div style={{ marginRight: '3%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <i className="fas fa-check-circle"></i> 
                                </div>
                                <div>{resourceName}</div>
                            </div>
                            <div style={{display:'flex', flexDirection:'column', justifyContent:'center', height:'50px', overflow:'hidden', margin:'auto'}}>
                                <div className='card_check_in_location' style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '2%' }}>
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <div>
                                    {address} 
                                    </div>
                                </div>
                            </div>
                            {/*<Button size="sm" style={{ marginLeft: '10%' }} onClick={() => this.setState({ subtasksAddModal: true })}>Schedule a Subtask</Button>*/}
                        </div>
                    </div>
                    <div style={{ width: '30% ', marginRight: '2%' }}>
                        <div className='notice_reminder'>
                            <div style={{ display: 'flex', margin: '0.2%', color: '#3FA7D6' }}>
                                <i style={{ fontSize: 25 }} className="fas fa-user-circle"></i>
                            </div>
                            <div>
                                <div className='notice_reminder_title d-flex flex-column'>
                                    {/* {props.detail.detail.empInfo[0].emp_name} */}
                                    <p className='m-0'>{props.user.emp_name}</p>
                                    <p className='m-0 text-muted'>({props.user.type})</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', margin: '1%' }}>
                                {/* <i className="fas fa-chevron-right fa-xs"></i> */}
                            </div>
                        </div>
                        <div className='contact_card_session'>
                            <div className='contact_card_title' style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    {/* <i className="fas fa-info-circle fa-2x mx-2"></i> */}
                                    <strong style={{ fontSize: 15 }}>SESSION INFO</strong>
                                </div>
                                {/* <div className='contact_call_icon m-0'>
                                    <i className="fas fa-info-circle fa-2x"></i>
                                </div> */}
                            </div>
                            <div style={{ marginTop: 0 }} className='contact'>
                                {/* <div style={{ marginRight: '5%' }}>
                                    <i className="fas fa-info-circle fa-3x"></i>
                                </div> */}
                                <div>
                                    <div className='contact_name mt-1'>
                                        <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-folder mx-2"></i>
                                        : <em className='mx-2'>{props.detail && `${props.detail.detail.schedule_name}`}</em>
                                    </div>
                                    <div className='contact_name mb-1'>
                                        <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-map-marker-alt mx-2"></i>
                                        : <em className='mx-2'>{props.detail && `${props.detail.detail.resource_name}`}</em>
                                    </div>
                                    <div className='contact_name_subtitle mb-1'>
                                        <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-stopwatch mx-2"></i> :
                                        <em className='mx-2'>
                                            {`${!moment(startTime).isValid() ? startTime : moment(startTime).format('hh:mm A')} - ${!moment(endTime).isValid() ? endTime : moment(endTime).format('hh:mm A')}`}
                                        </em>
                                    </div>
                                    <div className='contact_name_subtitle'>
                                        <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-tasks mx-2"></i> :
                                        <em className='mx-2'>
                                            {`${props.detail.detail.task_type !== 'null' ? props.detail.detail.task_type : ''}`}
                                        </em>
                                        {/* {this.state.contact && `${this.state.contact.emp_level}`} */}
                                    </div>
                                </div>
                            </div>
                            <div className='center'>
                            <div className='contact_description'>
                                <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-sticky-note mx-2"></i> :
                                <em className='mx-2'>
                                    {`${props.detail.detail.tas_notes ? props.detail.detail.tas_notes : ''}`}
                                </em>
                                {/* {this.state.type} */}
                            </div>
                            <div className='contact_description-tooltip'>
                                <i style={{ fontSize: 15, color: '#3FA7D6' }} className="fas fa-sticky-note mx-2"></i> :
                                <em className='mx-2'>
                                    {`${props.detail.detail.tas_notes ? props.detail.detail.tas_notes : ''}`}
                                </em>
                                {/* {this.state.type} */}
                            </div>
                            </div>
                        </div>

                </div>
            </div>
        </div>
    )
}

export default SessionCard