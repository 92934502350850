import React,{Component} from 'react'

class HeaderInfo extends Component{
    render(){
        console.log(this.props.data)
        return(
            <div style={{border: '1px solid #3FA7D6'}}  className="jumbotron jumbotron-fluid bg-white p-2">
                <div className="container d-flex flex-lg-row flex-md-row flex-sm-column justify-content-between p-0">
                    <div className="d-flex flex-column">
                        <div className="m-0">
                            <h3><span><i style={{color: '#3FA7D6'}} className="fas fa-briefcase-medical p-1 mx-2"></i></span>{!!this.props.data?this.props.data.custYTD[0].cus_YTD_num:''}</h3>
                        </div>
                        <div className="my-0 ml-1"><p>New Customers Onboarded | YTD</p></div>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="m-0">
                            <h3><span><i style={{background: '#3FA7D6'}} className="far fa-chart-bar text-white p-1 mx-2"></i></span>{!!this.props.data?this.props.data.inHomeMTDCont[0].inhomecountMDT:''}</h3>
                        </div>
                        <div className="my-0 ml-1"><p>Home Vists | MTD</p></div>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="m-0">
                            <h3><span><i style={{color: '#3FA7D6', borderRadius: '50%', padding: 3}} className="fas fa-briefcase mx-2"></i></span>{!!this.props.data?this.props.data.custNum[0].cus_num:''}</h3>
                        </div>
                        <div className="my-0 ml-1"><p>Total Customers</p></div>
                    </div>
                </div>
                <hr style={{background: '#3FA7D6'}} className="m-0"/>
            </div>
        )
    }
}

export default HeaderInfo