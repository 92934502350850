import React, { Component } from 'react';
import moment from 'moment'
class InfoBox extends Component {
    // constructor(){super()}
    state = {
        isOpen: true,
        // data: this.props.data
    }
    showPhoneList = (phoneString) => {
        const phoneArray = phoneString.split(",")
        return phoneArray.map((phone, index) => {
            return (
                <div key={index} className="phoneNumberBlock ml-3">
                    <div style={{ borderRadius: 50, backgroundColor: 'white', border: '1px solid green', width: '2rem', height: '2rem', margin: 5, alignItems: 'center' }} className="itemNumber d-flex flex-row justify-content-center text-center">
                        <i style={{ fontSize: 20, color: 'green' }} className="fas fa-phone text-center"></i>
                    </div>
                    <div className="phoneNumber mb-1">{phone} - {index <= 0 ? 'mobile' : 'work'}</div>
                </div>
            )
        })
    }
    render() {
        return (<div style={{ backgroundColor: '#EBF8FF', borderRadius: 10, marginTop: 20 }}>
            <div className='d-flex flex-row justify-content-between m-2'>
                <h5 className='m-2'>Info</h5>
                <i style={{ fontSize: 20, margin: 10 }} onClick={() => this.setState({ isOpen: !this.state.isOpen })} className="fas fa-info-circle px-2"></i>
            </div>
            {this.state.isOpen && <div style={{ marginTop: -15 }}>
                <hr style={{ background: "#3FA7D6" }} />
                <div className="itemBlock mx-4">
                    <span>PHONE : </span>
                    {this.showPhoneList(this.props.data.con_phone)}
                </div>
                <div className="itemBlock d-flex flex-column mx-3">
                    <span className="mx-2">EMAIL : </span>
                    <div className="phoneNumberBlock ml-4">
                        <div style={{ borderRadius: 50, backgroundColor: 'white', border: '1px solid #3FA7D6', width: '2rem', height: '2rem', margin: 5, alignItems: 'center' }} className="itemNumber d-flex flex-row justify-content-center text-center">
                            <i style={{ fontSize: 20, color: '#3FA7D6' }} className="fas fa-envelope text-center"></i>
                        </div>
                        <h6 style={{ margin: 10 }}>{!!this.props.data.con_email ? this.props.data.con_email : 'No Email ID'}</h6>
                    </div>
                </div>
                <div className="itemBlock d-flex flex-column mx-3">
                    <span className="mx-2">ADDRESS :</span>
                    <div className="phoneNumberBlock ml-4">
                        <div style={{ borderRadius: 50, backgroundColor: 'white', border: '1px solid #3FA7D6', width: '2rem', height: '2rem', margin: 5, alignItems: 'center' }} className="itemNumber d-flex flex-row justify-content-center text-center">
                            <i style={{ fontSize: 20, color: '#3FA7D6' }} className="fas fa-map-marker-alt text-center"></i>
                        </div>
                        <h6 style={{ margin: 10 }}>{this.props.data.con_Addr_line1},{this.props.data.con_Addr_line2},{this.props.data.con_city},{this.props.data.con_state},{this.props.data.con_zip}</h6>
                    </div>
                </div>
                <div className="itemBlock d-flex flex-column mx-3">
                    <span className="mx-2">STATUS :</span>
                    <div className="phoneNumberBlock ml-4">
                        <div style={{ borderRadius: 50, backgroundColor: 'white', border: '1px solid #3FA7D6', width: '2rem', height: '2rem', margin: 5, alignItems: 'center' }} className="itemNumber d-flex flex-row justify-content-center text-center">
                            <i style={{ fontSize: 20, color: '#3FA7D6' }} className="fas fa-suitcase text-center"></i>
                        </div>
                        <h6 style={{ margin: 10 }}>{this.props.data.con_status}</h6>
                    </div>

                </div>
                {/* <div className="d-flex flex-row justify-content-between mx-3 m-1"> */}
                <div className="itemBlock d-flex flex-column mx-4">
                    <span>FIRST CONTACT DATE :</span>
                    <div className="phoneNumberBlock ml-3">
                        <div style={{ borderRadius: 50, backgroundColor: 'white', border: '1px solid #3FA7D6', width: '2rem', height: '2rem', margin: 5, alignItems: 'center' }} className="itemNumber d-flex flex-row justify-content-center text-center">
                            <i style={{ fontSize: 20, color: '#3FA7D6' }} className="fas fa-calendar-day text-center"></i>
                        </div>
                        <h6 style={{ margin: 10 }}>{moment(this.props.data.con_first_contact_date).format('DD MMM YYYY')}</h6>
                    </div>

                </div>
                <div className="itemBlock d-flex flex-column mx-4">
                    <span>LAST CONTACT DATE :</span>
                    <div className="phoneNumberBlock ml-3">
                        <div style={{ borderRadius: 50, backgroundColor: 'white', border: '1px solid #3FA7D6', width: '2rem', height: '2rem', margin: 5, alignItems: 'center' }} className="itemNumber d-flex flex-row justify-content-center text-center">
                            <i style={{ fontSize: 20, color: '#3FA7D6' }} className="fas fa-calendar-day text-center"></i>
                        </div>
                        <h6 style={{ margin: 10 }}>{moment(this.props.data.con_last_contact_date).format('DD MMM YYYY')}</h6>
                    </div>

                </div>
                <div className="itemBlock d-flex flex-column mx-4">
                    <span>PREFERRED COMM. MODE :</span>
                    <div className="phoneNumberBlock ml-3">
                        <div style={{ borderRadius: 50, backgroundColor: 'white', border: '1px solid #3FA7D6', width: '2rem', height: '2rem', margin: 5, alignItems: 'center' }} className="itemNumber d-flex flex-row justify-content-center text-center">
                            <i style={{ fontSize: 20, color: '#3FA7D6' }} className="fas fa-paper-plane text-center"></i>
                        </div>
                        <h6 style={{ margin: 10 }}>{this.props.data.prefCommMode ? this.props.data.prefCommMode : '-'}</h6>
                    </div>

                </div>
                {/* </div> */}
            </div>}
        </div>)
    }
}
export default InfoBox