import React from 'react'
import Button from '@mui/material/Button';
import { CSVReader } from 'react-papaparse'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import makeStyles from '@mui/styles/makeStyles';

const buttonRef = React.createRef();

const useStyles = makeStyles((theme) => ({
  button: {
    color: '#fff',
    backgroundColor: '#3FA7D6',
    "& .MuiButton-label": {
      color: "#fff" // or black
    }
  },
}));


export default function ReaderCSV(props) {
  const classes = useStyles();

  const handleOnDrop = (data) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
  }

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
  }

  const handleOnFileLoad = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      props.clearUploadFile();
      buttonRef.current.removeFile(e);
    }
  };

  return <>
    <CSVReader
      ref={buttonRef}
      onFileLoad={props.onFileChange}
      onError={handleOnError}
      noClick
      noDrag
      onRemoveFile={handleOnRemoveFile}
    >
      {({ file }) => (
        <aside
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 10,
            alignItems: "center"
          }}
        >
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleOpenDialog}
          >
            Choose file
          </Button>
          <div style={{
            display: "flex",
            justifyContent: "center",
            width: "100%"
          }}>
            <div
              style={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: '#ccc',
                height: 45,
                lineHeight: 2.5,
                marginTop: 10,
                marginBottom: 10,
                marginLeft: 5,
                paddingLeft: 13,
                paddingTop: 3,
                width: '60%',
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {file && file.name}
            </div>
            <IconButton aria-label="delete" onClick={handleRemoveFile} size="large">
              <DeleteIcon />
            </IconButton>
          </div>
          <Button
            variant="contained"
            onClick={() => { props.onFileUpload(); handleRemoveFile(); }}
            className={classes.button}
          >
            Upload
          </Button>
        </aside>
      )}
    </CSVReader>
  </>;
}
