import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Divider, TextField, Grid, Autocomplete } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import SaveButton from '../Components/SaveButton';
import CancelButton from '../Components/CancelButton';
import axios from 'axios';
import { APIURL } from '../../../config';
import ccs from 'countrycitystatejson';
import normalize from 'us-states-normalize';
import moment from 'moment';

const CompanyInformation = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [errors, setErrors] = useState({ taxId: false });
    const [lastSavedCompanyDets, setLastSavedCompanyDets] = useState({});

    useEffect(() => {
        setFormValues({ ...props.companyDetail });
        setLastSavedCompanyDets({ ...props.companyDetail });
    }, [props.companyDetail]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormValues({
            ...formValues,
            [id]: value,
        });
        errors[id] = false;
    };

    const handleAutocompleteChange = (id, value) => {
        setFormValues({
            ...formValues,
            [id]: value,
        });
    };

    const handleCancel = () => {
        setFormValues({ ...props.companyDetail });
        setEditMode(false);
    };

    const handleSave = async () => {
        let errors = {
            com_taxid: formValues.com_taxid.split('').filter(c => c !== ' ').join('').length !== 9,
        }
        setErrors(errors);

        if (errors.com_taxid) return;

        try {
            const changes = Object.keys(lastSavedCompanyDets).filter(key => lastSavedCompanyDets[key] !== formValues[key]);

            if (changes.length === 0) {
                setEditMode(false);
                return;
            }

            let data = { ...formValues, company_id: props.companyDetail.company_id };
            let response = await axios.put(APIURL + '/company', data);

            if (response.data.code === 200) {
                setLastSavedCompanyDets({ ...formValues });
                props.setCompanyDetail({ ...formValues });

                // Log activity
                const sha1 = require('sha1');
                let sessionID = window.localStorage.getItem('Fw4_access_Token');
                let hash = sha1(sessionID);
                let log_data = {
                    emp_id: props.emp_id,
                    actionType: 'Company Information Changed',
                    actionSubType: `${changes.join(', ')} ${changes.length === 1 ? 'was' : 'were'} changed`,
                    sessionID: hash
                };
                await axios.post(APIURL + "/activity_log", log_data);

                setEditMode(false);
            } else {
                console.error("Error updating company information");
            }
        } catch (error) {
            console.error(error);
        }
    };

    const payCycleOptions = props.payrollData.map(fre => ({ title: fre.EntityValue, value: fre.EntityValue }));
    const daysoftheweek = [
        { title: 'Sunday', value: '0' },
        { title: 'Monday', value: '1' },
        { title: 'Tuesday', value: '2' },
        { title: 'Wednesday', value: '3' },
        { title: 'Thursday', value: '4' },
        { title: 'Friday', value: '5' },
        { title: 'Saturday', value: '6' }
    ];
    const states = ccs.getStatesByShort('US').filter(state => state.length > 2).map(state => ({ title: normalize(state), value: state }));
    const cities = formValues.loc_state ? ccs.getCities("US", normalize(formValues.loc_state, { returnType: 'name' })) : [];

    const getSubscriptionPlanLabel = (plan) => {
        switch (plan) {
            case 0:
                return "Free Trail";
            case 1:
                return "Trial Extended";
            case 5:
                return "Paid";
            case 10:
                return "Premium";
            default:
                return "No Subscription Found";
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h5">Company Information</Typography>
                {editMode ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <CancelButton onClick={handleCancel} />
                        <SaveButton onClick={handleSave} />
                    </Box>
                ) : (
                    <IconButton onClick={() => setEditMode(true)}>
                        <Edit />
                    </IconButton>
                )}
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Company Name
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body1" sx={{ my: 1 }}>{formValues.com_name || ''}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Company Location
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        {editMode ? (
                            <Box sx={{ display: 'flex', gap: 2, mr: "14px", ml: "-14px" }}>
                                <Box sx={{ flex: "8 1 0" }}>
                                    <TextField
                                        id="loc_addr_line1"
                                        value={formValues.loc_addr_line1 || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        placeholder="Address Line 1"
                                        size="small"
                                    />
                                </Box>
                                <Box sx={{ flex: "8 1 0" }}>
                                    <TextField
                                        id="loc_addr_line2"
                                        value={formValues.loc_addr_line2 || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        placeholder="Address Line 2"
                                        size="small"
                                    />
                                </Box>
                                <Box sx={{ flex: "5 1 0" }}>
                                    <Autocomplete
                                        id="loc_city"
                                        value={formValues.loc_city || ''}
                                        onChange={(e, value) => handleAutocompleteChange("loc_city", value)}
                                        fullWidth
                                        size="small"
                                        options={cities.map(city => ({ title: city, value: city }))}
                                        renderInput={(params) => <TextField {...params} placeholder="City" />}
                                    />
                                </Box>
                                <Box sx={{ flex: "3 1 0" }}>
                                    <Autocomplete
                                        id="loc_state"
                                        value={formValues.loc_state || ''}
                                        onChange={(e, value) => handleAutocompleteChange("loc_state", value)}
                                        fullWidth
                                        size="small"
                                        options={states}
                                        renderInput={(params) => <TextField {...params} placeholder="State" />}
                                        disableClearable
                                    />
                                </Box>
                                <Box sx={{ flex: "4 1 0" }}>
                                    <TextField
                                        id="loc_zip"
                                        value={formValues.loc_zip || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 10 }}
                                        placeholder="ZIP Code"
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>
                                {formValues.loc_addr_line1 || ''} {formValues.loc_addr_line2 || ''} {formValues.loc_city || ''},{' '}
                                {formValues.loc_state || ''} {formValues.loc_zip || ''}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                    <Typography variant="h6" sx={{ mt: 2 }}>Payroll Details</Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Company Tax ID
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="com_taxid"
                                value={formValues.com_taxid || ''}
                                onChange={handleInputChange}
                                fullWidth
                                inputProps={{ maxLength: 9 }}
                                size="small"
                                error={errors.com_taxid}
                                helperText={errors.com_taxid ? 'Invalid Tax ID' : ''}
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.com_taxid || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Pay Cycle
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>{formValues.payCycle || ''}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Start of the Week
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>{daysoftheweek.find(day => parseInt(day.value) === formValues.startoftheWeek)?.title || ''}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                    <Typography variant="h6" sx={{ mt: 2 }}>Subscription Details</Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Subscription Plan
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography variant="body1" sx={{ my: 1 }}>{getSubscriptionPlanLabel(formValues.subscriptionPlan)}</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Plan Start Date
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>
                            {moment(formValues.subscriptionStartDate).isValid() ? moment(formValues.subscriptionStartDate).utc().format('YYYY-MM-DD') : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Plan End Date
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>
                            {moment(formValues.subscriptionEndDate).isValid() ? moment(formValues.subscriptionEndDate).utc().format('YYYY-MM-DD') : ''}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Trial Start Date
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>
                            {moment(formValues.trialStartDate).isValid() ? moment(formValues.trialStartDate).utc().format('YYYY-MM-DD') : ''}
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Trial End Date
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant="body1" sx={{ my: 1 }}>
                            {moment(formValues.trialEndDate).isValid() ? moment(formValues.trialEndDate).utc().format('YYYY-MM-DD') : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default CompanyInformation;
