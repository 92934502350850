import React, { useState, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import "./HandyCard.css";
import moment from "moment";
import { APIURL } from "../../config";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import ArticleIcon from '@mui/icons-material/Article';
import IconButton from "@mui/material/IconButton";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GestureIcon from '@mui/icons-material/Gesture';

const HandyCard = (props) => {
  const [show, setShow] = useState(false);
  const [showSubtasks, setShowSubtasks] = useState(false);
  const [subtaskTempData, setSubtaskTempData] = useState([]);
  const [rows, setRows] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleOpenSubtasks = () => setShowSubtasks(true);
  const handleCloseSubtasks = () => setShowSubtasks(false);

  const [signLoading, setSignLoading] = useState(false);
  const [showSignPopover, setShowSignPopover] = useState(false);
  const [signUrl, setSignUrl] = useState("");
  const [location, setLocation] = useState("-");

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const res1 = await getSubtasksData();
      if (mounted) {
        if (res1.code === 200) {
          setRows(res1);
        }
        setSubtaskTempData(res1);
      }
    };
    fetchData();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
  }, []);

  async function handleShowSignPopover() {
    if (showSignPopover) {
      setShowSignPopover(false);
    } else {
      setSignLoading(true);
      setShowSignPopover(true);
      let signature = await axios
        .get(APIURL + "/signature", {
          params: { task_id: props.task.tas_id },
          responseType: "arraybuffer",
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: "image/png" });
          const url = URL.createObjectURL(blob);
          if (props.task.checkoutLocId) {
            let loc = props.locList.find((loc) => loc.loc_id === props.task.checkoutLocId);
            setLocation(loc.loc_addr_line1 + ", " + (loc.loc_addr_line2 ? loc.loc_addr_line2 + ", " : "") + loc.loc_city + ", " + loc.loc_state + ", " + loc.loc_zip);
          }
          setSignUrl(url);
          setSignLoading(false);
        }).catch((err) => {
          setSignUrl("Something went wrong. Please try again later.");
          setSignLoading(false);
        });
    }
  }

  async function updateCheckin(task_id, checkin) {
    let data = { projId: props.data.tas_pro_id, empName: props.data.emp_first_name + '' + props.data.emp_last_name, empId: props.data.tas_emp_id, taskTitle: props.data.tas_title, companyId: props.user.comp_id, taskId: props.data.tas_id, date: moment().format('YYYY-MM-DDTHH:mm:ss') }
    let empLocResponse = await axios.put(APIURL + '/locate/empLoc', { ...data });
    let tasCheckinResponse = await axios.put(APIURL + '/task/checkin', { tas_id: task_id, checkin: checkin })
    setShow(false);
    setShow(true);
  }

  async function getSubtasksData(start, end) {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
      "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
      Authorization:
        "Bearer " + window.localStorage.getItem("Fw4_access_Token"),
    };

    let subtasksDataApi = await axios.get(APIURL + "/subtasks", {
      params: {
        task_id: props.task_id,
      },
    });
    return subtasksDataApi.data;
  }

  function getSubtaskRows() {
    return rows.map((row) => (
      <div id="subtasks-checkout-modal-row">
        <div style={{ width: "20%" }}>
          <p
            style={{
              "font-weight": "600",
              "margin-top": "15px",
              "padding-left": "10px",
            }}
          >
            {" "}
            {!!row.subtask_name ? row.subtask_name : "Subtask"}
          </p>
        </div>
        <Autocomplete
          className="subtask-autocomplete"
          options={[
            { title: "Subtask Type 1", id: 1 },
            { title: "Subtask Type 2", id: 2 },
          ]}
          getOptionLabel={(option) => option.title}
          onChange={(e,newValue) => {
            if (newValue.title !== "") {
              for (let i = 0; i < subtaskTempData.length; i++) {
                if (newValue.title === "Subtask Type 1") {
                  subtaskTempData[i].reason_code = 1;
                } else if (newValue.title === "Subtask Type 2") {
                  subtaskTempData[i].reason_code = 2;
                }
              }
            }
          }}
          style={{ width: "25%", "font-size": "12px" }}
          renderInput={(params) => (
            <TextField {...params} label="Subtask Type" variant="outlined" />
          )}
        />
        <Autocomplete
          className="subtask-autocomplete"
          options={[
            { title: "Reason Code 1", id: 1 },
            { title: "Reason Code 2", id: 2 },
          ]}
          getOptionLabel={(option) => option.title}
          onSelect={(e) => {
            if (e.target.value !== "") {
              for (let i = 0; i < subtaskTempData.length; i++) {
                if (e.target.value === "Reason Code 1") {
                  subtaskTempData[i].reason_code = 1;
                } else if (e.target.value === "Reason Code 2") {
                  subtaskTempData[i].reason_code = 2;
                } else if (e.target.value === "Reason Code 3") {
                  subtaskTempData[i].reason_code = 3;
                }
              }
            }
          }}
          style={{ width: "25%", "font-size": "12px" }}
          renderInput={(params) => (
            <TextField {...params} label="Reason Code" variant="outlined" />
          )}
        />
        <Autocomplete
          className="subtask-autocomplete"
          options={[
            { title: "Completed", id: 1 },
            { title: "Postponed", id: 2 },
            { title: "Cancelled", id: 2 },
          ]}
          getOptionLabel={(option) => option.title}
          onSelect={(e) => {
            if (e.target.value !== "") {
              for (let i = 0; i < subtaskTempData.length; i++) {
                if (e.target.value === "Completed") {
                  subtaskTempData[i].action_taken = 1;
                } else if (e.target.value === "Postponed") {
                  subtaskTempData[i].action_taken = 2;
                } else if (e.target.value === "Cancelled") {
                  subtaskTempData[i].action_taken = 3;
                }
              }
            }
          }}
          style={{ width: "25%" }}
          renderInput={(params) => (
            <TextField {...params} label="Action Taken" variant="outlined" />
          )}
        />
        <Autocomplete
          className="subtask-autocomplete"
          options={[
            { title: "Completed", id: 1 },
            { title: "Missed", id: 2 },
            { title: "Scheduled", id: 3 },
          ]}
          getOptionLabel={(option) => option.title}
          onSelect={(e) => {
            if (e.target.value !== "") {
              for (let i = 0; i < subtaskTempData.length; i++) {
                if (e.target.value === "Completed") {
                  subtaskTempData[i].status = 1;
                } else if (e.target.value === "Missed") {
                  subtaskTempData[i].status = 2;
                } else if (e.target.value === "Scheduled") {
                  subtaskTempData[i].status = 3;
                }
              }
            }
          }}
          style={{ width: "25%" }}
          renderInput={(params) => (
            <TextField {...params} label="Status" variant="outlined" />
          )}
        />
      </div>
    ));
  }

  // console.log(props.start_time.length,moment(props.start_time.toString().slice(0, props.start_time.length-3)).format(),moment(props.start_time).utc().format(),moment(props.start_time).format('MMM DD, HH:mm A'))
  return (
    <div>
      {props.employeelocate && (
        <div className="EmpLocCard my-3 mr-2">
          <div>
            <i className="fas fa-user m-0 px-2"></i>
          </div>
          <p style={{ width: '10%' }} className="font-weight-normal">
            {props.data.emp_first_name} {props.data.emp_last_name}
          </p>
          <div style={{ borderLeft: '2px solid #95d0eb', width: '35%' }} className="d-flex flex-row justify-content-start mx-1">
            <div>
              <i className="fas fa-map-marker-alt m-0 px-2"></i>
            </div>
            <div>
              <p className="m-0">{props.data.empAddress}</p>
              <small className="text-muted">
                Updated {moment(props.data.UpdatedTime.replace(/Z/g, "")).fromNow()}
              </small>
            </div>
          </div>
          <div style={{ borderLeft: '2px solid #95d0eb', width: '20%' }} className="d-flex flex-row justify-content-start mx-1">
            <div>
              <i className="fas fa-clipboard-check m-0 px-2"></i>
            </div>
            <div>
              <p className="m-0">{props.data.tas_title}</p>
              <small className="text-muted">{props.data.pro_title}</small>
            </div>
          </div>
          <div style={{ borderLeft: '2px solid #95d0eb', width: '30%' }} className="d-flex flex-row justify-content-start mx-1">
            <div>
              <i className="fas fa-clipboard-check m-0 px-2"></i>
            </div>
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <small className="text-muted" style={{ marginTop: 4, marginRight: 10 }}>Last Check In:</small>
                <p className="m-0">{moment(props.data.empLocdate).utc().format('MMMM DD, YYYY hh:mm A')}</p>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <small className="text-muted" style={{ marginTop: 4, marginRight: 10 }}>Last Check Out:</small>
                <p className="m-0">{moment(props.data.checkoutEmpDate).utc().format('MMMM DD, YYYY hh:mm A')}</p>
              </div>
            </div>
          </div>
          <div style={{ borderLeft: '2px solid #95d0eb', width: '15%' }} className="d-flex flex-row justify-content-start mx-1">
            <div>
              <i className="fas fa-check m-0 px-2"></i>
            </div>
            <div style={{}}>
              <p className="m-0">Status: {props.data.checkin ? "Checked In" : "Checked Out"}</p>
              <Button size='sm' onClick={() => props.toggleShowCheckOutDialog(props.data)/*</div>updateCheckin(props.data.tas_id, false)*/} hidden={!props.data.checkin}>Check Out</Button>
            </div>
          </div>
        </div>
      )}
      {props.projectNote && (
        <div className="CommentCard my-3 mr-2 ">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <p className="m-0" style={{ fontWeight: "600" }}>
                {props.data.employee_name} {/* Task Name */}
              </p>
              <p className="font-weight-normal m-0 text-muted">
                {""} {/* Task Type */}
              </p>
              <p className="font-weight-normal m-0 text-muted">
                <small>
                  {moment(props.data.created_at.replace(/Z/g, "")).format('MM/DD/YYYY hh:mm A')}
                </small>{" "}
                created by {props.data.employee_name}
                <small> ({props.data.emp_type})</small>
              </p>
            </div>
            <div>
              <p
                // onClick={() => {}}
                className="fas fa-edit fa-small edit-icon"
                alt="editIconProjNote"
                style={{ cursor: "pointer", color: "gray" }}
              />
            </div>
          </div>
          <hr className="HorizontalLine mt-0 mb-1"></hr>
          <div className="d-flex flex-row justify-content-start mx-1 pt-1">
            <div>
              <p className="m-0" style={{ fontWeight: "600" }}>
                {props.data.comment_text}
              </p>
            </div>
          </div>
        </div>
      )}
      {props.customerNote && (
        <div className="CommentCard my-3 mr-2 ">
          <div>
            <p className="m-0" style={{ fontWeight: "600" }}>
              {props.data.employee_name} {/* Task Name */}
            </p>
            <p className="font-weight-normal m-0 text-muted">
              {""} {/* Task Type */}
            </p>
            <p className="font-weight-normal m-0 text-muted">
              <small>
                {moment(props.data.created_at.replace(/Z/g, "")).format('MM/DD/YYYY hh:mm A')}
              </small>{" "}
              created by {props.data.employee_name}
              <small> ({props.data.emp_type})</small>
            </p>
          </div>
          <hr className="HorizontalLine mt-0 mb-1"></hr>
          <div className="d-flex flex-row justify-content-start mx-1 pt-1">
            <div>
              <p className="m-0" style={{ fontWeight: "600" }}>
                {props.data.comment_text}
              </p>
            </div>
          </div>
        </div>
      )}
      {props.task && (
        <div className="TaskCard">
          {/* {setEmpDetails(props.all_employees, props.emp_id)} */}
          <div className="TaskHeader">
            <h6>{props.title}</h6>
            <li>
              <span>
                Scheduled: {moment(props.start_time.replace(/Z/g, "")).format("MMM DD, hh:mm A")} - {moment(props.end_time.replace(/Z/g, "")).format("MMM DD, hh:mm A")}

              </span>
            </li>
          </div>
          <div className="TaskHeaderTwo">
            <span>{props.task_type}</span>
            <span>Actual: {moment(props.task.empLocdate?.replace(/Z/g, "")).format("MMM DD, hh:mm A")} - {moment(props.task.checkoutEmpDate?.replace(/Z/g, "")).format("MMM DD, hh:mm A")}</span>
          </div>
          <div className="TaskHeaderThree">
            {moment().isAfter(moment(props.end_date).add(1, "d")) &&
              props.is_active === 0 && <span className="overDue" style={{}}>Overdue</span>}
            {props.task.tas_is_active !== 1 && <i
              className="fas fa-trash"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.deleteTask(props.task_id, props.title);
              }}
            ></i>}
            {props.is_active !== 1 && !props.isCheckedIn && (
              <i
                className="fas fa-edit"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.showModal
                    ? props.showModal(props.task)
                    : props.editForm(props.task_id);
                }}
              ></i>
            )}



          </div>
          <hr className="HorizontalLine" style={{}}></hr>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 2 }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <span>
                    <i className="fas fa-user-alt"></i>
                  </span>
                  <span style={{ marginLeft: "10px" }}>
                    {props.employeeDets.emp_first_name}{" "}
                    {props.employeeDets.emp_last_name}
                  </span>
                </div>
                <div>
                  {props.task.tas_required_sig === 1 && props.task.tas_is_active === 1 &&
                    <Tooltip title={"Signed on " + moment(props.task.checkoutEmpDate).utc().format("MMM DD, hh:mm A")} >
                      <IconButton onClick={() => handleShowSignPopover()} size="small">
                        <GestureIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  {props.task?.customerForm && props.task?.customerForm.length > 0 &&
                    <Tooltip title="Forms" >
                      <IconButton size="small" onClick={() => props.handleCusFormsShow(props.task)}>
                        <FormatListBulletedIcon />
                      </IconButton>
                    </Tooltip>}
                  {props.isSupport && props.task?.monthly_monitoring_id &&
                    <Tooltip title="MT Task" >
                      <IconButton size="small" onClick={() => props.handleFormShow(props.task)}>
                        <ArticleIcon />
                      </IconButton>
                    </Tooltip>}
                </div>
              </div>
  {/*             <div>
                <span>
                  <i className="fas fa-map-marker-alt"></i>
                </span>
                <span style={{ marginLeft: "14px" }}>
                  {props.taskLoc.loc_addr_line1 +
                    " " +
                    props.taskLoc.loc_addr_line2 +
                    " " +
                    props.taskLoc.loc_city +
                    " " +
                    props.taskLoc.loc_state +
                    " " +
                    props.taskLoc.loc_zip}
                </span>
              </div> */}
            </div>
          </div>
          {props.is_active === 1 && props?.selectedMenu == 'Clients' &&
            <div style={{ textAlign: 'right' }}>
              <Button size='sm' style={{ 'marginTop': '0px' }} onClick={() => {
                props.showModal
                  ? props.showModal(props.task)
                  : props.editForm(props.task_id);
              }}
              >View details</Button>
            </div>}
          {/*
                    <div>
                        <Button size='sm' style={{'marginTop': '10px'}} onClick={() => handleOpenSubtasks()}>View All Subtasks</Button>
                    </div>
                    */}
        </div>
      )}
      {props.contact && (
        <div className="ContactCard my-3 mr-2">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <p className="font-weight-normal m-0">
                {props.data.con_first_name} {props.data.con_last_name}
              </p>
              <small className="text-muted">{props.data.con_contact_type}</small>
            </div>
            <div>
              {props.contactTab && (
                <span>
                  <i
                    onClick={() => {
                      props.onSelect(props.data.con_id);
                    }}
                    className="fas fa-info-circle px-2"
                  ></i>
                </span>
              )}
              <span>
                <i
                  onClick={() => props.onDel(props.data.con_id)}
                  className="fas fa-trash"
                  style={{ cursor: "pointer" }}
                ></i>
              </span>
            </div>
          </div>
          <hr className="HorizontalLine"></hr>
          <div className="d-flex flex-column">
            <span style={{ color: "rgba(0,0,0,0.6)" }}>
              <i className="fas fa-phone px-2"></i> {props.data.con_phone}
            </span>
            <span style={{ color: "rgba(0,0,0,0.6)" }}>
              <i className="fas fa-map-marker-alt px-2"></i>{" "}
              {props.data.con_Addr_line1}, {props.data.con_Addr_line2},{" "}
              {props.data.con_city}, {props.data.con_state},{" "}
              {props.data.con_zip}
            </span>
          </div>
        </div>
      )}
      {props.contactUpgrade && (
        <div
          onClick={() => {
            props.onSelect(props.data.con_id);
          }}
          style={{
            backgroundColor: !props.selected ? `` : "#EBF8FF",
            borderRadius: 10,
          }}
          className=" my-1 mr-2 p-1"
        >
          <div className="d-flex flex-row justify-content-start">
            <div
              style={{ width: "20rem" }}
              className="d-flex flex-row justify-content-start"
            >
              <div
                style={{
                  border: "1px solid #3FA7D6",
                  borderRadius: 50,
                  margin: 10,
                  width: "2.5rem",
                  height: "2.5rem",
                }}
              >
                <p className="text-center mt-2 font-weight-bold">
                  {props.data.con_first_name.split("")[0]}
                  {props.data.con_last_name.split("")[0]}
                </p>
              </div>
              <div className="m-2">
                <p className="font-weight-normal m-0">
                  {props.data.con_first_name} {props.data.con_last_name}
                </p>
                <small className="text-muted">
                  {props.data.con_designation}
                </small>
              </div>
            </div>

            {/* <hr className="HorizontalLine"></hr> */}
            <div className="d-flex flex-column m-2">
              <span>
                <i className="fas fa-phone-alt px-2"></i>{" "}
                {props.data.con_phone.split(",")[0]}
              </span>
              <span>
                <i className="fas fa-envelope px-2"></i>{" "}
                {!props.data.con_email
                  ? "No Email Id"
                  : props.data.con_email.length < 25
                    ? props.data.con_email
                    : `${props.data.con_email.slice(0, 20)}...`}
              </span>
            </div>
          </div>
        </div>
      )}
      {props.employee && (
        <div className="EmpCard my-3 mr-2">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <p className="font-weight-normal m-0">
                {props.employee_name}{" "}
                {props.empId === props.managerId ? (
                  <small className="text-muted">(Project Manager)</small>
                ) : (
                  ""
                )}
              </p>
              <small className="text-muted">{props.employee_designation}</small>
            </div>
            <div>
              {props.showModal ? (
                <i
                  className="fas fa-edit"
                  style={{ margin: "10px", cursor: "pointer" }}
                  onClick={() => {
                    props.showModal(props.employee);
                  }}
                ></i>
              ) : (
                <i
                  className="fas fa-edit"
                  style={{ margin: "10px", cursor: "pointer" }}
                  data-toggle="modal"
                  data-target="#empEdit"
                  onClick={() => {
                    props.editForm(props.empId);
                  }}
                ></i>
              )}
              {!(props.empId === props.managerId) && (
                <span>
                  <i
                    onClick={() => props.onDel(props.empId)}
                    className="fas fa-trash"
                    style={{ cursor: "pointer" }}
                  ></i>
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      {props.SCemployee && (
        <div className="EmpCard my-3 mr-2">
          <div className="d-flex flex-row justify-content-between">
            <div>
              <p className="font-weight-normal m-0">
                {props.data.emp_first_name} {props.data.emp_last_name}{" "}
                {props.data.emp_id === props.userId ? (
                  <small className="text-muted">(Me)</small>
                ) : (
                  ""
                )}
              </p>
              <small className="text-muted">{props.data.emp_type}</small>
            </div>
            <div>
              <span>
                <i
                  className="fa fa-arrow-circle-right"
                  onClick={() => props.onSelect(props.data.emp_id)}
                  style={{ cursor: "pointer" }}
                ></i>
              </span>
            </div>
          </div>
        </div>
      )}
      {props.artifact && (
        <div>
          {console.log(props)}
          {!props.grid ? (
            <div className="ArtCard my-3 mr-2">
              <div className="d-flex flex-row justify-content-between">
                <div style={{ height: '70px', width: "auto" }}>
                  <p className="font-weight-normal m-0">
                    <a href={'#'} onClick={() => props.openDocViewer(props.url, props.fileType)}>{props.fileName}</a>
                  </p>
                  <small className="text-muted">
                    Artifact Type: {props.fileType}
                  </small>
                </div>
                <div className="d-flex flex-row justify-content-between mx-2">
                  <small className="text-muted px-2">
                    {moment(props.date).format("MMM DD, HH:mm a")}
                  </small>
                  <div>
                    <i
                      onClick={handleShow}
                      className="fas fa-info-circle px-2"
                      style={{ cursor: "pointer" }}
                    ></i>
                  </div>
                  <div>
                    <a href={props.url} class="fas fa-download px-2" style={{ color: 'black' }}></a>
                  </div>
                  <div>
                    <i
                      data-toggle="modal"
                      data-target="#staticBackdropDel"
                      onClick={() => props.del(props.id)}
                      className="fas fa-trash px-2"
                      style={{ cursor: "pointer", display: props.del ? "inline" : "none" }}
                    ></i>
                  </div>
                </div>
              </div>
              <hr className="HorizontalLine"></hr>
              <div className="d-flex flex-column">
                {props.taskName !== "" &&
                  <span>
                    <i className="fas fa-puzzle-piece px-2"></i>
                    {props.taskName}
                  </span>
                }
                <span>
                  <i
                    className="fas fa-user-tie px-2 pr-2"
                    style={{ marginLeft: "2px" }}
                  ></i>
                  {props.empName}
                </span>
              </div>
            </div>
          ) : (
            <div className="ArtGridCard">
              <Card
                style={{
                  width: "19rem",
                  border: "2px solid  #95D0EB",
                  margin: 5,
                }}
              >
                {props.fileType !== "pdf" &&
                  props.fileType !== "jpg" &&
                  props.fileType !== "png" && (
                    <Card.Img
                      style={{ height: "10rem" }}
                      variant="top"
                      src="https://i.pinimg.com/originals/a5/f6/26/a5f6265636c437d65341dd8c1446cf61.jpg"
                    />
                  )}
                {!!props.fileType &&
                  (props.fileType.toLowerCase() === "jpg" ||
                    props.fileType.toLowerCase() === "png") && (
                    <Card.Img
                      style={{ height: "10rem" }}
                      variant="top"
                      src={props.url}
                    />
                  )}
                {
                  props.fileType === "pdf" && (
                    <embed src={props.url} type="application/pdf" />
                  )
                  // <iframe src={props.url} title={props.fileName} type="application/pdf"></iframe>
                }

                <Card.Body>
                  <div className="d-flex flex-row justify-content-between">
                    <div style={{ height: '70px' }}>
                      <p className="font-weight-normal m-0 p-0">
                        <a href={props.url}> {props.fileName}</a>
                      </p>
                      <small className="text-muted m-0 p-0">
                        Artifact Type: {props.fileType}
                      </small>
                    </div>
                    <div className="d-flex flex-row justify-content-between mx-2">
                      <div>
                        <i
                          onClick={handleShow}
                          className="fas fa-info-circle px-2"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>
                      <div>
                        <a href={props.url} class="fas fa-download px-2" style={{ color: 'black' }}></a>
                      </div>
                      <div>
                        <i
                          data-toggle="modal"
                          data-target="#staticBackdropDel"
                          onClick={() => props.del(props.id)}
                          className="fas fa-trash px-2"
                          style={{ cursor: "pointer", display: props.del ? "inline" : "none" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <hr className="HorizontalLine"></hr>
                  <div className="d-flex flex-column">
                    {props.taskName !== "" &&
                      <span>
                        <i className="fas fa-puzzle-piece px-2"></i>
                        {props.taskName}
                      </span>
                    }
                    <span>
                      <i
                        className="fas fa-user-tie px-2 pr-2"
                        style={{ marginLeft: "2px" }}
                      ></i>
                      {props.empName}
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </div>
          )}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>{props.fileName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <small className="text-muted">Task</small>
              <p>{props.taskName}</p>
              <small className="text-muted">Project</small>
              <p>{props.pname}</p>
              <small className="text-muted">Document Type</small>
              <p>{props.fileType}</p>
              <small className="text-muted">Uploaded on</small>
              <p>{moment(props.date).format("MMM DD, hh:mm a")}</p>
              <small className="text-muted">Uploaded by</small>
              <p>{props.empName}</p>
            </Modal.Body>
          </Modal>
        </div>
      )}
      {props.task && <Modal
        dialogClassName={"signature-display-modal"}
        show={showSignPopover}
        onHide={() => setShowSignPopover(false)}
        size="l"
      >
        <Modal.Header closeButton>
          <Modal.Title>Signature details for {props.task.tas_title}</Modal.Title>
        </Modal.Header>
        <Modal.Body dialogClassName={"signature-display-modal"}>
          {signLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
              <span class="spinner"></span>
            </div>
          ) : (
            <div className="signature-info">
              <table>
                <tbody>
                  <tr>
                    <td><strong>Customer Name:</strong></td>
                    <td>{props.customerDets.cus_name}</td>
                  </tr>
                  <tr>
                    <td><strong>Signed at Location:</strong></td>
                    <td>{location}</td>
                  </tr>
                  <tr>
                    <td><strong>Signed on:</strong></td>
                    <td>{moment(props.task.checkoutEmpDate).utc().format("MMM DD, hh:mm a")}</td>
                  </tr>
                  <tr>
                    <td><strong>Signature:</strong></td>
                    <td>{signUrl === 'Something went wrong. Please try again later.' ? signUrl : <img src={signUrl} alt="Signature" className="signature-image" />}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
      </Modal>
      }
      <Modal
        dialogClassName={"handycard-subtasks-modal"}
        show={showSubtasks}
        onHide={handleCloseSubtasks}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title} Subtasks</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p>WORK IN PROGRESS</p>
            {getSubtaskRows()}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HandyCard;
