import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import { Button, Select, MenuItem, TextField, FormControl, InputLabel } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete';


export default function CustomerGoalForm(props) {
    const [formData, setFormData] = useState({
        customer_id: '',
        project_id: '',
        goal_title: '',
        goal_detail: '',
        goal_status: '',
        goal_progress: 0,
    });
    const [customers, setCustomers] = useState([])
    const [projects, setProjects] = useState([])

    useEffect(() => {
        async function fetchCustomers() {
            let customersResponse = (await axios.get(APIURL + `/customers?company_id=${[props.user.comp_id]}`)).data
            customersResponse.sort((a, b) => {
                let nameA = a.customer_name.toUpperCase();
                let nameB = b.customer_name.toUpperCase();
            
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
            
                return 0;
            });
            setCustomers(customersResponse)
        }
        fetchCustomers();
        if(props.customer_id){
            formData.customer_id=props.customer_id
        }
    }, []);

    useEffect(() => {
        async function fetchProjects() {
            const projectsResponse = (await axios.get(APIURL + `/project?cus_id=${[formData.customer_id]}`)).data
            setProjects(projectsResponse)
        }
        fetchProjects();

        async function fetchExistingGoal() {
            const customerGoalResponse = (await axios.get(APIURL + `/customer_goal`, { params: {customer_goal_id: props.customer_goal_id}})).data[0]
            setFormData({
                ...formData,
                goal_detail: customerGoalResponse.goal_detail,
                goal_progress: customerGoalResponse.goal_progress,
                goal_status: customerGoalResponse.goal_status,
                goal_title: customerGoalResponse.goal_title,
                project_id: customerGoalResponse.project_id,
                customer_id: customerGoalResponse.customer_id,
                customer_goal_id: customerGoalResponse.customer_goal_id,
            });
        }
        if(props.customer_goal_id){
            fetchExistingGoal()
        }
        
    }, [formData.customer_id]);

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSave = async() => {
        let response = {};
        if(props.customer_goal_id){
            response = await axios.put( APIURL+"/customer_goal", formData);
        } else {
            response = await axios.post( APIURL+"/customer_goal", formData);
        }

        if(response.status !== 200){
            alert("There was an error saving the goal. Please try again.")
        }
        
        if(props.onSubmit){props.onSubmit()}
    };

    const CustomerGoalStatusMap = {
        0: 'Not Started',
        1: 'In Progress',
        2: 'Completed',
    };

    return (
        <div>
            <FormControl fullWidth margin="normal">
                <Autocomplete
                    id="customer-autocomplete"
                    options={customers}
                    getOptionLabel={(option) => option.customer_name + ` (${option.id})`}
                    value={customers.find(customer => customer.id === formData.customer_id) || null}
                    onChange={(event, newValue) => {
                        setFormData({ ...formData, customer_id: newValue ? newValue.id : '' });
                    }}
                    filterOptions={(options, { inputValue }) => {
                        return options.filter(option => option.customer_name.toLowerCase().includes(inputValue.toLowerCase()));
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Customer" variant="outlined" />
                    )}
                />
            </FormControl>
            <FormControl fullWidth margin="normal" disabled={!formData.customer_id}>
                <InputLabel id="project-label">Project</InputLabel>
                <Select
                    labelId="project-label"
                    name="project_id"
                    value={formData.project_id}
                    label="Project"
                    onChange={handleInputChange}
                >
                    {projects.filter(project => project.pro_customer_id === formData.customer_id).map(project => (
                        <MenuItem key={project.pro_id} value={project.pro_id}>{project.pro_title}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                name="goal_title"
                label="Goal Title"
                value={formData.goal_title}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
                inputProps={{ maxLength: 64 }}
            />
            <TextField
                name="goal_detail"
                label="Goal Details"
                value={formData.goal_detail}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
                multiline
                rows={4}
                inputProps={{ maxLength: 256 }}
            />
            <FormControl fullWidth margin="normal">
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                    labelId="status-label"
                    name="goal_status"
                    value={formData.goal_status}
                    label="Status"
                    onChange={handleInputChange}
                >
                    {Object.entries(CustomerGoalStatusMap).map(([key, value]) => (
                        <MenuItem key={key} value={key}>{value}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                name="goal_progress"
                label="Progress (0-100)"
                type="number"
                value={formData.goal_progress}
                onChange={handleInputChange}
                margin="normal"
                fullWidth
                inputProps={{ min: 0, max: 100 }}
            />
            <Button variant="contained" color="primary" onClick={handleSave} style={{ marginTop: '20px' }}>
                Save
            </Button>
        </div>
    );
}
