import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { APIURL } from '../../../config'
import IconButton from "@mui/material/IconButton";
import Modal from '@mui/material/Modal';
import NoteFormv2 from '../../../components/forms/NoteForm/NoteFormv2'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

const NewNote = (props) => {


    return (
        <Dialog open={props.showAddNote}>
            <DialogActions>
                <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.25 }}
                    onClick={() => {props.setShowAddNote(false); props.setNoteCustomerID()}}
                    >
                        <CloseIcon />
                </IconButton>
            </DialogActions>
            <DialogContent sx={{ paddingTop: '0px', width: 600 }}>
                <NoteFormv2 company_id={props.company_id} mode='add' checkTokenExpiry={props.checkTokenExpiry} user={props.user} customer_id={props.customer_id} onSubmit={() => props.setShowAddNote(false)}></NoteFormv2>
            </DialogContent>
        </Dialog>
    )
}

export default NewNote