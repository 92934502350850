import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

const useStyles = makeStyles((theme) => ({
  GridTableBox: {
    "& .MuiDataGrid-columnHeader:first-child": {
      width: "315px !important",
      maxWidth: "315px !important",
    },
    "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft:first-child":
    {
      width: "315px !important",
      maxWidth: "315px !important",
    },
  },
}));

export default function PlanRenewalReport(props) {
  const classes = useStyles();
  const [gridId, setGridId] = useState(38);
  const apiRef = useGridApiRef();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [reportDetails, setReportDetails] = useState({});
  const [isFavorite, setIsFavorite] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    axios
      .get(APIURL + "/jsreport/getAllReports")
      .then((response) => {
        const report = response?.data?.reports.find((ele) => ele.report_name === "Plan Renewal Report");
        setReportDetails(report);
        setIsFavorite(report ? (report.status === 1 ? true : false) : false);
      });

    axios
      .get(APIURL + `/project/getPlanRenewalReports`, {
        params: { company_id: props.user.comp_id, emp_id: props.user.emp_id },
      })
      .then((response) => {
        setData(response?.data.length !== 0 ?
          [
            ...response?.data?.current_months_reports,
            ...response?.data?.next_month_reports,
            ...response?.data?.reports_beyond_next_month_in_current_quarter,
          ]
          :
          []);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    // {
    //   field: "pro_title",
    //   headerName: "Plan",
    // },
    { field: "ddd_id", headerName: "DDD ID", width: 90 },
    { field: "program", headerName: "Program" },
    { field: "customer_name", headerName: "Customer Name" },
    { field: "coordinator_name", headerName: "Coordinator Name" },
    { field: "dob", headerName: "DOB" },
    {
      field: "plan_approval_date",
      headerName: "Plan Approval Date",
    },
    { field: "plan_start_date", headerName: "Plan Start Date" },
    { field: "plan_end_date", headerName: "Plan End Date" },
  ]

  let rows = data.map((inv, index) => {
    let invDets = {
      rowId: inv?.pro_id + Math.random().toString(36).substring(2, 12) + index,
      hierarchy: [inv.reports_span, inv.pro_title + " - " + inv.pro_id],
      id: inv?.pro_id,
      // pro_title: inv?.pro_title,
      ddd_id: inv?.DDD_ID,
      program: inv?.Program,
      customer_name: inv?.customer_details[0]?.cus_name || "",
      coordinator_name: inv?.coordinator_name || "",
      dob: inv?.customer_details[0]?.dob
        ? moment(inv?.customer_details[0]?.dob).format("MM/DD/YYYY")
        : "",
      plan_approval_date: inv?.plan_approval_date
        ? moment(inv?.plan_approval_date).format("MM/DD/YYYY")
        : "",
      plan_start_date: inv?.pro_starts_on
        ? moment(inv?.pro_starts_on).format("MM/DD/YYYY")
        : "",
      plan_end_date: inv?.pro_ends_on
        ? moment(inv?.pro_ends_on).format("MM/DD/YYYY")
        : "",
    };
    return invDets;
  });

  const markFavourite = async (data, type) => {
    const payload = {
      report_id: data?.report_id,
      status: type,
    };

    axios
      .put(APIURL + "/jsreport/markFavorite", payload)
      .then((response) => {
        if (response?.status === 200 && response?.data?.code === 200) {
          setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
        } else {
          setErrorText("Something went wrong");
        }

        fetchData();
      });
  }

  const onPageChange = (value) => {
    setPageNo(value);
  };

  const onPageSizeChange = (value) => {
    setPageSize(value);
    setPageNo(0);
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ margin: 10, width: '75vw' }}>
        {rows.length === 0 ?
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "500px", marginTop: 10 }}
          >
            <img
              src="./nosearchresult.png"
              alt="nosearchresult"
              style={{ width: "25rem", height: "15rem", margin: 10 }}
            />
            <h5
              style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
              className="text-center"
            >
              There is currently no data available{" "}
            </h5>
          </div>
          :
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div />
              <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                <img
                  src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                  alt="barchart"
                  style={{
                    fontFamily: "Lato",
                    width: "1.5rem",
                    height: "1.5rem",
                    cursor: "pointer",
                    margin: 5
                  }}
                  onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                />
              </Tip>
            </div>
            <div style={{ height: '80vh' }}>
              <ControlledDataGrid
                gridId={gridId}
                user={props.user}
                initialState={{ sorting: { sortModel: [{ field: "customer_name", sort: "asc" }] } }}
                apiRef={apiRef}
                className={classes.GridTableBox}
                rows={rows}
                columns={columns}
                pagination
                pageSize={pageSize}
                rowsPerPageOptions={[20, 50, 100]}
                page={pageNo}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                disableSelectionOnClick
                treeData
                getTreeDataPath={(row) => row.hierarchy}
                getRowId={(row) => row.rowId}
              />
            </div>
          </div>
        }

        <Snackbar
          open={successText !== ""}
          autoHideDuration={30000}
          onClose={() => setSuccessText("")}
        >
          <Alert
            sx={{ width: "100%" }}
            severity={"success"}
            elevation={6}
            variant="filled"
          >
            {successText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => setSuccessText("")}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorText !== ""}
          autoHideDuration={30000}
          onClose={() => setErrorText("")}
        >
          <Alert
            sx={{ width: "100%" }}
            severity={"error"}
            elevation={6}
            variant="filled"
          >
            {errorText}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.25 }}
              onClick={() => setErrorText("")}
            >
              <CloseIcon />
            </IconButton>
          </Alert>
        </Snackbar>
      </div>
    );
  }
}