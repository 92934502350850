import React from 'react';
import { Form, Button } from 'react-bootstrap';
import ccs from 'countrycitystatejson';
import normalize  from 'us-states-normalize';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import {APIURL,BINGAPIKEY} from '../../../../config';

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
  })(TextField);


const PersonalDetails = (props) => {

    const [currentAddress, setCurrentAddress] = React.useState("");
    const [addressSuggestions, setAddressSuggestions] = React.useState([]);

    let states = ccs.getStatesByShort('US').filter( state => { return state.length > 2 });
    let cities = props.formVals.state ? ccs.getCities('US', normalize(props.formVals.state, { returnType: 'name' })) : [];

    states=states.map((state, indx) =>{return {title:normalize(state),value:state}})
    cities=cities.map((city, indx) => {return {value:city, title: city}})

    async function handleAddressSuggestions(value){
        let newAddressSuggestions = await axios.get(APIURL + '/maps', { params: { input: value } })
        setAddressSuggestions(newAddressSuggestions.data.predictions)
        setCurrentAddress(value === undefined  ? '' : value)
    }

    async function handleAddressSelect(value){
        let newAddressDetails = await axios.get(APIURL + '/maps/details', { params: { place_id: value.place_id } })
        props.formChangeHandler({
            target: {
                id: 'address_maps'
            },
            value: newAddressDetails.data
        })
    }

    return (
        <div>
            <Form.Row> 
                <Autocomplete
                    autoSelect
                    selectOnFocus
                    disablePortal
                    id="address_maps"
                    sx={{ width: 436 }}
                    popupIcon={null}
                    options={addressSuggestions}
                    getOptionLabel={(option) => option.description}
                    onChange={(event, value, reason) => {
                        if(reason === 'selectOption'){
                            handleAddressSelect(value)
                        }
                    }}
                    onInputChange={(event, value, reason) => {
                        if(reason === 'input'){
                            handleAddressSuggestions(value)
                        }
                    }}
                    renderInput={(params) => <CssTextField {...params} className={`mx-3 my-3 w-100`} label="ADDRESS *" variant="outlined" id="address_maps_TextField"/> }
                />
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="address_line_1">
                    <Form.Label>ADDRESS LINE 1</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={props.formVals.address_line_1}type="text" onChange={(event) => props.formChangeHandler(event)} />
                </Form.Group> */}
                 {/*
                 <CssTextField
                    disabled
                    className={`mx-3 my-3 w-75`}
                    label="ADDRESS LINE 1"
                    value={props.formVals.address_line_1}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="address_line_1"
                />
                */}
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="address_line_2">
                    <Form.Label>ADDRESS LINE 2</Form.Label>
                    <Form.Control value={props.formVals.address_line_2}type="text" onChange={(event) => props.formChangeHandler(event)}/>
                </Form.Group> */}
                 {/*
                 <CssTextField
                    className={`mx-3 my-3 w-75`}
                    label="ADDRESS LINE 2"
                    value={props.formVals.address_line_2}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="address_line_2"
                />
                */}
            </Form.Row>
            <Form.Row>
                 {/* <Form.Group as={Col} controlId="state">
                    <Form.Label>STATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control as="select" value={props.formVals.state}onChange={(event) => props.formChangeHandler(event)}>
                        <option value="">Select State</option>
                        {states}
                    </Form.Control>
                </Form.Group> */}
                 {/*
                 <Autocomplete
                    disabled
                    id="state"
                    value={states.find((type)=> type.title.toUpperCase() === props.formVals.state.toUpperCase()) || null}
                    className={`mx-3 my-3`}
                    onChange={(e)=> props.formChangeHandler({target:{id:'state', value: e.target.innerHTML}})}
                    disableClearable={true}
                    options={states}
                    getOptionLabel={(option) => option.title}
                    style={{ width: '25%' }}
                    renderInput={(params) => <CssTextField {...params} label="STATE *" variant="outlined" />}
                />
                */}
                {/* <Form.Group as={Col} controlId="city">
                <Form.Label>CITY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control as="select" value={props.formVals.city}onChange={(event) => props.formChangeHandler(event)}>
                        <option value="">Select City</option>
                        {cities}
                    </Form.Control>
                </Form.Group> */}
                {/*
                <CssTextField
                    disabled
                    id="city"
                    value={cities.find((type)=> type.title.toLowerCase() === props.formVals.city.toLowerCase()) || null}
                    className={`mx-3 my-3`}
                    onSelect={props.formChangeHandler}
                    getOptionLabel={(option) => option.title}
                    style={{ width: '45%' }}
                    renderInput={(params) => <CssTextField {...params} label="CITY" variant="outlined" />}
                />
                */}
                {/* <Form.Group as={Col} controlId="city">
                    <Form.Label>CITY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={props.formVals.city}type="text" onChange={(event) => props.formChangeHandler(event)}/>
                </Form.Group> */}
                
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="country">
                    <Form.Label>COUNTY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control type="text" value={props.formVals.country} onChange={(event) => props.formChangeHandler(event)}/>
                </Form.Group> */}
                {/*
                <CssTextField
                    disabled
                    className={`mx-3 my-3 w-75`}
                    label="COUNTY"
                    value={props.formVals.country}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="country"
                />
                */}
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="zip">
                    <Form.Label>ZIP</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control type="text" value={props.formVals.zip} onChange={(event) => props.formChangeHandler(event)}/>
                </Form.Group> */}
                {/*
                <CssTextField
                    disabled
                    className={`mx-3 my-3 w-75`}
                    label="ZIP"
                    value={props.formVals.zip}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="zip"
                />
                */}
            </Form.Row>
            <Form.Row>
                {/* <Form.Group as={Col} controlId="latitude">
                    <Form.Label>LATITUDE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control type="text" value={props.formVals.latitude}
                    // onChange={(event) => props.formChangeHandler(event)}
                    readOnly/>
                </Form.Group> */}
                 {/*
                 <CssTextField
                    style={{width:'35%'}}
                    className={`mx-3 my-3`}
                    label="LATITUDE"
                    value={props.formVals.latitude}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="latitude"
                    disabled={true}
                />
                */}
                {/* <Form.Group as={Col} controlId="longitude">
                    <Form.Label>LONGITUDE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control type="text" value={props.formVals.longitude}
                    // onChange={(event) => props.formChangeHandler(event)}
                    readOnly/>
                </Form.Group> */}
                {/*
                <CssTextField
                    style={{width:'35%'}}
                    className={`mx-3 my-3`}
                    label="LONGITUDE"
                    value={props.formVals.longitude}
                    onChange={props.formChangeHandler} 
                    variant="outlined"
                    id="longitude"
                    disabled={true}
                />
                */}
            </Form.Row>
            <Button className='w-75 ml-3' block type="button" onClick={() => props.onContinue("other_details")}>Continue</Button>
        </div >
    )
};

export default PersonalDetails;