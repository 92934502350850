import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Divider, TextField, Grid, Autocomplete } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import SaveButton from '../Components/SaveButton';
import CancelButton from '../Components/CancelButton';
import axios from 'axios';
import { APIURL } from '../../../config';
import ccs from 'countrycitystatejson';
import normalize from 'us-states-normalize';
import { set } from 'date-fns';

const PayerInformation = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [lastSavedPayerDets, setLastSavedPayerDets] = useState({});
    const [credData, setCredData] = useState({});
    const [lastSavedCredData, setLastSavedCredData] = useState({});

    useEffect(() => {
        if (props.data.payerId) {
            setFormValues({ ...props.payerDetail });
            setLastSavedPayerDets({ ...props.payerDetail });
            setCredData({ ...props.credDetail, emp_id: props.user_id });
            setLastSavedCredData({ ...props.credDetail });
        }
    }, [props.data.payerId, props.payerDetail, props.credDetail, props.user_id]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormValues({
            ...formValues,
            [id]: value,
        });
    };

    const handleAutocompleteChange = (id, value) => {
        setFormValues({
            ...formValues,
            [id]: value,
        });
    };

    const handleCredChange = (e) => {
        const { id, value } = e.target;
        setCredData({
            ...credData,
            [id]: value,
        });
    };

    const handleCancel = () => {
        setFormValues({ ...lastSavedPayerDets });
        setCredData({ ...lastSavedCredData });
        setEditMode(false);
    };

    const handleSave = async () => {
        try {
            const payerChanges = Object.keys(lastSavedPayerDets).filter(key => lastSavedPayerDets[key] !== formValues[key]);
            const credChanges = Object.keys(lastSavedCredData).filter(key => lastSavedCredData[key] !== credData[key]);

            if (payerChanges.length > 0) {
                let payerResponse = await axios.put(APIURL + '/payer', { ...formValues, payer_id: props.data.payerId });
                    
                setLastSavedPayerDets({ ...formValues });
                setFormValues({ ...formValues });
            }

            if (credChanges.length > 0) {
                let credResponse = await axios.put(APIURL + '/creds', { ...credData, cred_id: credData.cred_id });

                setLastSavedCredData({ ...credData });
                setCredData({ ...credData });
            }

            setEditMode(false);
        } catch (error) {
            console.error(error);
        }
    };

    const states = ccs.getStatesByShort('US').filter(state => state.length > 2).map(state => ({ title: normalize(state), value: state }));
    const cities = formValues.payer_state ? ccs.getCities("US", normalize(formValues.payer_state, { returnType: 'name' })) : [];

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h5">Payer Information</Typography>
                {editMode ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <CancelButton onClick={handleCancel} />
                        <SaveButton onClick={handleSave} />
                    </Box>
                ) : (
                    <IconButton onClick={() => setEditMode(true)}>
                        <Edit />
                    </IconButton>
                )}
            </Box>
            <Box>
                {!!props.data.payerId && (
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                                    Payer ID
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant="body1" sx={{ my: 1 }}>{props.data.payerId}</Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 1 }} />
                    </>

                )}
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Payer Name
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="payer_name"
                                value={formValues.payer_name || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.payer_name || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Payer Tax ID
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="payerTaxID"
                                value={formValues.payerTaxID || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14}}
                                inputProps={{ maxLength: 9 }}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.payerTaxID || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Contact First Name
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="con_first_name"
                                value={formValues.con_first_name || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.con_first_name || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Contact Last Name
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="con_last_name"
                                value={formValues.con_last_name || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.con_last_name || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Contact Designation
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="con_designation"
                                value={formValues.con_designation || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.con_designation || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Payer Phone
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="payer_phone"
                                value={formValues.payer_phone || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.payer_phone || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Address
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        {editMode ? (
                            <Box sx={{ display: 'flex', gap: 2, mr: "14px", ml: "-14px" }}>
                                <Box sx={{ flex: "8 1 0" }}>
                                    <TextField
                                        id="payer_Addr_line1"
                                        value={formValues.payer_Addr_line1 || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        placeholder="Address Line 1"
                                        size="small"
                                    />
                                </Box>
                                <Box sx={{ flex: "8 1 0" }}>
                                    <TextField
                                        id="payer_Addr_line2"
                                        value={formValues.payer_Addr_line2 || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        placeholder="Address Line 2"
                                        size="small"
                                    />
                                </Box>
                                <Box sx={{ flex: "5 1 0" }}>
                                    <Autocomplete
                                        id="payer_city"
                                        value={formValues.payer_city || ''}
                                        onChange={(e, value) => handleAutocompleteChange("payer_city", value)}
                                        fullWidth
                                        size="small"
                                        options={cities.map(city => ({ title: city, value: city }))}
                                        renderInput={(params) => <TextField {...params} placeholder="City" />}
                                    />
                                </Box>
                                <Box sx={{ flex: "3 1 0" }}>
                                    <Autocomplete
                                        id="payer_state"
                                        value={formValues.payer_state || ''}
                                        onChange={(e, value) => handleAutocompleteChange("payer_state", value)}
                                        fullWidth
                                        size="small"
                                        options={states}
                                        renderInput={(params) => <TextField {...params} placeholder="State" />}
                                        disableClearable
                                    />
                                </Box>
                                <Box sx={{ flex: "4 1 0" }}>
                                    <TextField
                                        id="payer_zip"
                                        value={formValues.payer_zip || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 10 }}
                                        placeholder="ZIP Code"
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>
                                {formValues.payer_Addr_line1 || ''} {formValues.payer_Addr_line2 || ''} {formValues.payer_city || ''},{' '}
                                {formValues.payer_state || ''} {formValues.payer_zip || ''}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Typography variant="h6" sx={{ mt: 2 }}>Credentials</Typography>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Client ID
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="cred_clientID"
                                value={credData.cred_clientID || ''}
                                onChange={handleCredChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{credData.cred_clientID || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Client Secret
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="cred_clientSecret"
                                value={credData.cred_clientSecret || ''}
                                onChange={handleCredChange}
                                fullWidth
                                size="small"
                                type="password"
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{credData.cred_clientSecret ? '********' : ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Business Entity ID
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="bus_entity_id"
                                value={credData.bus_entity_id || ''}
                                onChange={handleCredChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{credData.bus_entity_id || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Medicaid Identifier
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="bus_entity_Medicaid_identifier"
                                value={credData.bus_entity_Medicaid_identifier || ''}
                                onChange={handleCredChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{credData.bus_entity_Medicaid_identifier || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Contact Email
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                id="cred_con_email"
                                value={credData.cred_con_email || ''}
                                onChange={handleCredChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14}}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{credData.cred_con_email || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default PayerInformation;
