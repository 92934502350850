import React, { useState, useEffect } from "react";
import './CheckoutModal.css';
import CheckoutForms from "./CheckoutForms";
import { Dialog, DialogActions, DialogTitle, DialogContent, Button } from "@mui/material";
import './CheckoutModal.css'
import SignatureModal from "../Billing/SignatureCap/SignatureModal";
import axios from "axios";
import { APIURL } from "../../config";

export default function CheckoutModal(props) {

    const [selectedForm, setSelectedForm] = useState(null);
    const [completedForms, setCompletedForms] = useState([]);
    const [forms, setForms] = useState([]);
    const [allFormsCompleted, setAllFormsCompleted] = useState(false);
    const [signatureModal, setSignatureModal] = useState(false);
    const [signCaptured, setSignCaptured] = useState(false);

    useEffect(async () => {
        setForms(props.forms);
        try {
            const response = await axios.get(APIURL + `/customer_form/task/${props.task_id}`)
            setCompletedForms(response.data.map(form => form.form_type));
        } catch (error) {
            console.log(error);
        }
    }, [props.forms]);

    useEffect(() => {
        if (signCaptured) {
            setSignCaptured(false);
            props.processCheckout();
            props.hide();
        }
    }, [signCaptured])

    useEffect(() => {
        calculateAllFormsCompleted();
    }, [completedForms, forms]);

    async function handleSubmit() {
        if (props.isCheckedIn && !allFormsCompleted) {
            alert('Please complete all forms before checking out.');
            return;
        }
        if (props.task.detail.tas_required_sig === 1 && props.task.detail.checkin) {
            setSignatureModal(true);
            return;
        }
        props.processCheckout();
        props.hide();
    };

    const onFormCreateOrExists = (cusForm) => {
        const formIndex = completedForms.indexOf(cusForm.form_type);
        if (formIndex === -1) {
            setCompletedForms([...completedForms, cusForm.form_type]);
        }
        // else {
        //     completedForms.splice(formIndex, 1);
        //     setCompletedForms([...completedForms]);
        // }
    };

    const calculateAllFormsCompleted = () => {
        if (completedForms.length >= forms.length) {
            setAllFormsCompleted(true);
        } else {
            setAllFormsCompleted(false);
        }
    };

    return (
        <div>
            <Dialog
                open={props.show}
                onClose={props.hide}
                fullWidth
                maxWidth="md"
            >
                <DialogTitle>{props.modalTitle}</DialogTitle>
                <DialogContent>
                    {props.modalBody}
                    <div id="subtasks-checkout-modal-body">
                        {/* Subtask and Form Divs were here*/}
                    </div>
                    {props.isCheckedIn &&
                        <CheckoutForms
                            forms={props.forms}
                            completedForms={completedForms}
                            onSubmit={onFormCreateOrExists}
                            onEdit={onFormCreateOrExists}
                            customer_id={props.cus_id}
                            task_id={props.task_id}
                            selectedForm={selectedForm}
                            setSelectedForm={setSelectedForm}
                            user_id={props.user_id}
                            readOnly={props.readOnly}
                        />
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={props.hide}>
                        Close
                    </Button>
                    <Button
                        className="save-changes-button"
                        variant="contained"
                        onClick={() => handleSubmit()}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <SignatureModal task={props.task.detail} emp_id={props.emp_id} show={signatureModal} toggle={() => { setSignatureModal(!signatureModal) }} captured={() => { setSignCaptured(true) }} />
        </div>
    );
}