import react, { useState, useEffect } from 'react';
import { Box, Typography, Chip, Checkbox, Stack, FormControlLabel, FormGroup } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';
import axios from 'axios';
import { APIURL } from '../../../config';

const ModulesActivated = (props) => {
    const [modules, setModules] = useState([]);
    const [originalModules, setOriginalModules] = useState([]);

    const handleCheckBoxChange = (event) => {
        if(event.target.checked){
            setModules([...modules, {feature_value: event.target.name, feature_id: props.moduleList.find((module) => module.feature_value === event.target.name).feature_id}]);
        } else {
            setModules(modules.filter((module) => module.feature_value !== event.target.name));
        }
    }

    const handleChipDelete = (module) => {
        setModules(modules.filter((m) => m.feature_value !== module.feature_value));
    }

    const handleSave = async () => {
        try {
            let response = await axios.put(APIURL + "/config/settings/replace", {
                company_id: props.comp_id, 
                feature_value: "functionality",
                added_by: props.user_id,
                settings: modules.map((module) => {
                    return {
                        feature_id: module.feature_id,
                        feature_value: "functionality"
                    }
                })
            });
            setOriginalModules(modules);
        } catch (error) {
            console.error(error);
        }   
    }

    useEffect(() => {
        setModules(props.selectedModules);
        setOriginalModules(props.selectedModules);
    }, [props.selectedModules]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            Modules Activated
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            What modules are activated for your business?
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h6">
                            Modules
                        </Typography>
                        <Box sx={{ minHeight: 32 }}>
                            <Box sx={{ minHeight: 40 }}>
                                {modules.length === 0 && <Typography variant="body1" sx={{ color: 'gray' }}>No Modules selected</Typography>}
                                {modules.map((module, index) => {
                                    return (
                                        <Chip key={index} label={module.feature_value} onDelete={() => handleChipDelete(module)} color="primary" sx={{ mr: 1, mb: 1 }} />
                                    );
                                })}
                            </Box>
                            <Typography variant="h6">
                                Select Modules
                            </Typography>
                        </Box>
                        <FormGroup>
                            <Grid container spacing={0}>
                                {props.moduleList.map((module, index) => {
                                    return (
                                        <Grid item xs={4} key={index}>
                                            <FormControlLabel sx={{ height: 30 }} control={<Checkbox checked={modules.find((m) => m.feature_value === module.feature_value) ? true : false} onChange={handleCheckBoxChange} name={module.feature_value} />} label={module.feature_value} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </FormGroup>
                        <SaveButton onClick={handleSave} disabled={JSON.stringify(modules) === JSON.stringify(originalModules)} />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
    
}

export default ModulesActivated;