import * as React from "react";
import { useState, useEffect } from "react";
import { Button, TextField, Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import Tip from "../../utils/fields/Tip";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

export default function CMCustomerNotesReport(props) {
    const [gridId, setGridId] = useState(64);
    const apiRef = useGridApiRef();

    const [isLoading, setIsLoading] = useState(true);
    const [customerNotesData, setCustomerNotesData] = useState([]);
    const [currentMonth, setCurrMonth] = useState(moment().startOf("month"));
    const [reportDetails, setReportDetails] = useState({});
    const [isFavorite, setIsFavorite] = useState(false);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, [currentMonth]);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getAllReports")
            .then((response) => {
                const report = response?.data?.reports.find((ele) => ele.report_name === "Employee Notes Report");
                setReportDetails(report);
                setIsFavorite(report ? (report.status === 1 ? true : false) : false);
            });

        let customerNotesData = await axios.get(APIURL + "/customers/getAssociatedCustomersAndTaskNotes", {
            params: { compID: props.comp_id, empID: props.user.emp_id, empType: props.user.type, month: moment(currentMonth).format('YYYYMM') },
        });

        let rows = customerNotesData.data.map((data, idx) => {
            let customerNotesData = {
                id: idx,
                emp_id: data.emp_id,
                emp_last_name: data.emp_last_name,
                emp_first_name: data.emp_first_name,
                pro_id: data.pro_id,
                pro_title: data.pro_title,
                cus_name: data.cus_name,
                comment_id: data.comment_id,
                cus_comments: data.tas_is_active !== 2 ?
                    isJSON(data.comment_text) ?
                        JSON.parse(data.comment_text)?.blocks.map(block => block.text).join(' ') :
                        data.comment_text :
                    null,
                tas_id: data.tas_is_active !== 2 ? data.task_id : null,
                tas_title: data.tas_is_active !== 2 ? data.tas_title : null,
                tas_date: data.tas_is_active !== 2 ?
                    data.empLocdate ?
                        moment(data.empLocdate).format('MM/DD/YYYY')
                        : ''
                    : null,
                taskDuration: data.tas_is_active !== 2 ? data.taskDuration : null,
            };
            return customerNotesData;
        });

        setCustomerNotesData(rows);
        setIsLoading(false);
    };

    const columns = [
        { field: "id", headerName: "ID", hide: true },
        { field: "cus_name", headerName: "Customer Name", width: 200 },
        { field: "emp_id", headerName: "Employee ID", hide: true },
        { field: "emp_last_name", headerName: "Case Manager Last Name", width: 210 },
        { field: "emp_first_name", headerName: "Case Manager First Name", width: 210 },
        { field: "pro_id", headerName: "Case ID", hide: true },
        { field: "pro_title", headerName: "Case Name", hide: true },
        { field: "tas_id", headerName: "Task ID", hide: true },
        { field: "tas_title", headerName: "Task Title", width: 200 },
        { field: "tas_date", headerName: "Task Date", width: 100 },
        {
            field: "taskDuration",
            width: 75,
            renderHeader: (params) => {
                return (
                    <div
                        style={{
                            maxHeight: "inherit",
                            width: "100%",
                            whiteSpace: "initial",
                            lineHeight: "16px",
                            verticalAlign: "bottom",
                        }}
                    >
                        Duration (Hours)
                    </div>
                );
            },
        },
        { field: "comment_id", headerName: "Comment ID", hide: true },
        {
            field: "cus_comments",
            headerName: "Notes",
            width: 800,
            flex: 1,
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    minHeight: '24px',
                    width: '100%',
                    lineHeight: '1.5',
                    padding: '5px',
                    boxSizing: 'border-box',
                }}>
                    {params.value}
                </div>
            )
        },
    ];

    const defaultSettings = {
        "pinnedColumns": {},
        "columns": {
            "columnVisibilityModel": {
                "id": false,
                "emp_id": false,
                "pro_id": false,
                "pro_title": false,
                "tas_id": false,
                "comment_id": false
            },
            "orderedFields": [
                "id",
                "cus_name",
                "emp_id",
                "emp_last_name",
                "emp_first_name",
                "pro_id",
                "pro_title",
                "tas_id",
                "tas_title",
                "tas_date",
                "taskDuration",
                "comment_id",
                "cus_comments"
            ],
            "dimensions": {
                "emp_last_name": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 210
                },
                "emp_first_name": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 210
                },
                "cus_name": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 200
                },
                "tas_title": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 200
                },
                "tas_date": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 100
                },
                "taskDuration": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 75
                },
                "cus_comments": {
                    "maxWidth": -1,
                    "minWidth": 50,
                    "width": 800,
                    "flex": 1
                }
            }
        },
        "preferencePanel": {
            "open": false
        },
        "filter": {
            "filterModel": {
                "items": [],
                "linkOperator": "and",
                "quickFilterValues": [],
                "quickFilterLogicOperator": "and"
            }
        },
        "sorting": {
            "sortModel": [
                {
                    "field": "cus_name",
                    "sort": "asc"
                }
            ]
        },
        "pagination": {
            "pageSize": 100,
            "page": 0
        },
        "columnVisibilityModel": {
            "id": false,
            "emp_id": false,
            "pro_id": false,
            "pro_title": false,
            "tas_id": false,
            "comment_id": false
        },
        "selectionModel": [],
        "density": "standard"
    }

    const isJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    function onDateChange(newValue) {
        setCurrMonth(moment(newValue).startOf("month"));
    }

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ marginLeft: 10, marginTop: 10, marginBottom: 10, width: '75vw' }}>
                <div style={{ marginTop: 20 }}>
                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                        <div id="report-main" style={{ marginBottom: 20 }}>
                            <Button
                                size="large"
                                className="DayButton"
                                onClick={() => onDateChange(currentMonth.subtract(1, "month"))}
                            >
                                <i className="fas fa-arrow-left"></i>
                            </Button>
                            <LocalizationProvider
                                class="DatePickerParent"
                                dateAdapter={AdapterDateFns}
                            >
                                <DatePicker
                                    label="Select Date"
                                    views={["year", "month"]}
                                    value={currentMonth}
                                    onChange={(newValue) => {
                                        onDateChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                />
                            </LocalizationProvider>
                            <Button
                                size="large"
                                className="DayButton"
                                onClick={() => onDateChange(currentMonth.add(1, "month"))}
                            >
                                <i className="fas fa-arrow-right"></i>
                            </Button>
                        </div>
                        <div>
                            <Tip title={isFavorite ? "Remove from favorite reports" : "Add to favorite reports"}>
                                <img
                                    src={isFavorite ? "./star-solid.svg" : "./star-regular.svg"}
                                    alt="barchart"
                                    style={{
                                        fontFamily: "Lato",
                                        width: "1.5rem",
                                        height: "1.5rem",
                                        cursor: "pointer",
                                        margin: 5
                                    }}
                                    onClick={() => markFavourite(reportDetails, isFavorite ? 0 : 1)}
                                />
                            </Tip>
                        </div>
                    </div>
                    <div style={{ height: '80vh' }}>
                        <ControlledDataGrid
                            apiRef={apiRef}
                            gridId={gridId}
                            user={props.user}
                            defaultSettings={defaultSettings}
                            initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
                            getRowId={(row) => row.id}
                            rows={customerNotesData}
                            columns={columns}
                            // components={{
                            //     Toolbar: GridToolbar,
                            // }}
                            checkboxSelection={false}
                            disableSelectionOnClick
                            getRowHeight={() => 'auto'}
                            sx={{
                                '& .MuiDataGrid-row:nth-of-type(even)': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                                '& .MuiDataGrid-row:nth-of-type(odd)': {
                                    backgroundColor: 'white',
                                },
                                '& .MuiDataGrid-cell': {
                                    whiteSpace: 'normal',
                                    lineHeight: 'normal',
                                    display: 'flex',
                                    alignItems: 'center',
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                },
                            }}
                        />
                    </div>
                </div>

                <Snackbar
                    open={successText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setSuccessText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"success"}
                        elevation={6}
                        variant="filled"
                    >
                        {successText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setSuccessText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={errorText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setErrorText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"error"}
                        elevation={6}
                        variant="filled"
                    >
                        {errorText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setErrorText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
            </div >
        );
    };
}