import React from 'react';
import { Card } from 'react-bootstrap';
import './CustomerCard.css'

function CustomerCard(props) {
    return (
        <div>
            <Card style={{ height: '40px', width: '100%' }} className={`cardBody ${props.selected}`} onClick={() => props.onClick(props.id)} >
                <Card.Body style={{ padding: 10 }}>
                    <Card.Title style={{ cursor: "pointer", fontSize: '1rem', marginBottom: '5px', paddingTop: '3px' }}>{props.customer.cus_name}</Card.Title>
                </Card.Body>
                <div>
                    {props.selected === 'active' && <i className="fas fa-chevron-right fa-2xs"></i>}
                </div>
            </Card>
        </div>
    )
}

export default CustomerCard;