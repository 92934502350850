import React, { Component } from 'react'
import './recent_call.css'

class ContactTile extends Component {
    render() {
        return (
            <div className='contact_tile'>
                <div><i style={{color:'#3FA7D6'}} className="fas fa-user-circle fa-3x"></i></div>
                <div style={{ marginLeft: '5%' }}>
                    <div className='contact_tile_name'>{this.props.name}</div>
                    <div className='contact_tile_subtitle'>
                        <div className='contact_title_subtitle_title'>{this.props.subtitle}</div>|
                        <div className='contact_title_subtitle_phone'>{this.props.phone.split(',')[0]}</div>|
                        <div className='contact_title_subtitle_duration'>
                            <i className="fas fa-clock"></i>
                            <div style={{ marginLeft: '5%' }}>{`${this.props.duration}`}</div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', color: '#06D6A0', fontSize: '11px', opacity: '87%', whiteSpace: 'nowrap', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {
                                this.props.incoming && <div style={{ display: 'flex' }}><i className="fas fa-arrow-down"></i> <div style={{ color: '#585757', marginLeft: '2%' }}>Incoming </div></div>
                            }
                            {
                                this.props.outgoing && <div style={{ display: 'flex' }}><i className="fas fa-arrow-up"></i> <div style={{ color: '#585757', marginLeft: '2%' }}>Outgoing </div></div>
                            }
                        </div>
                        <div style={{ color: "#585757", opacity: '87%', marginLeft: '3%' }}>
                            {this.props.dateTime}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ContactTile
