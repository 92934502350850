import React, { useState, useEffect } from "react";
import { Typography, Snackbar, IconButton, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReportFavoriteCard from "./ReportFavoriteCard";
import moment from "moment";
import axios from "axios";
import { APIURL } from "../../config";

var sha1 = require("sha1");

export default function Favorites(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [billingFilter, setBillingFilter] = useState({
        dateRange: [
            moment().startOf("month").format("YYYY-MM-DD"),
            moment().endOf("month").format("YYYY-MM-DD"),
        ],
    });
    const [cus_DDDIDs, setCus_DDDIDs] = useState([]);
    const [successText, setSuccessText] = useState("");
    const [errorText, setErrorText] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        axios
            .get(APIURL + "/jsreport/getFavorites", {
                params: {},
            })
            .then((response) => {
                setData(response?.data?.favorites || []);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const getReport = async (data, type) => {
        const report_name = data?.report_details?.report_name || data?.report_name;
        switch (report_name) {
            case "Invoice Report":
                getInvoiceReport(type);
                break;
            case "Billing Transaction Report":
                getBillingTrans(type);
                break;
            case "Claim Balance Report":
                getClaimBalance(type);
                break;
            case "Billing Outstanding Balance Report":
                getOutstandingBalance(type);
                break;
            case "Claim Collection Report":
                getClaimCollect(type);
                break;
            case "Prior Auth Utilization Report":
                getPA_Utilization(type)
                break;
            case "Payroll Report":
                getPayrollReport(type);
                break;
            case "Debarment Report":
                getDebarReport(type);
                break;
            case "NJ Consolidated Debarment Report":
                getConsolidatedDebarReport(type);
                break;
            case "Service Authorization Report":
                props.navigateToFavoriteReport('billing', 'serviceAuthorization');
                break;
            case "Time Management Report":
                props.navigateToFavoriteReport('timeManagement', 'timeManagement');
                break;
            case "EVV Report":
                props.navigateToFavoriteReport('evv', 'evvReport');
                break;
            case "EVV Invoice":
                props.navigateToFavoriteReport('evv', 'evvInvoice');
                break;
            case "Plan Renewal Report":
                props.navigateToFavoriteReport('administration', 'planRenewal');
                break;
            case "Active Customer Report":
                props.navigateToFavoriteReport('administration', 'activeCustomer');
                break;
            case "Tasks Report":
                props.navigateToFavoriteReport('administration', 'tasks');
                break;
            case "MT Summary Report":
                props.navigateToFavoriteReport('administration', 'mtSummary');
                break;
            case "SC Visit Report":
                props.navigateToFavoriteReport('administration', 'scVisit');
                break;
            case "MT Completion Detail Report":
                props.navigateToFavoriteReport('administration', 'mtCompletionDetail');
                break;
            case "Management Review Report":
                props.navigateToFavoriteReport('administration', 'managementReview');
                break;
            case "MT Rejection Report":
                props.navigateToFavoriteReport('administration', 'mtRejection');
                break;
            case "Late MT Report":
                props.navigateToFavoriteReport('administration', 'lateMT');
                break;
            case "Employee Notes Report":
                props.navigateToFavoriteReport('administration', 'employeeNotes');
                break;
            case "Client Attendance Report":
                props.navigateToFavoriteReport('scheduleManagement', 'clientAttendance');
                break;
            case "Daily Attendance Report":
                props.navigateToFavoriteReport('scheduleManagement', 'dailyAttendance');
                break;
            default:
                break;
        }
    }

    const getInvoiceReport = async (type) => {
        axios
            .get(APIURL + "/jsreport/invoice", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    start: moment(billingFilter?.dateRange[0]).format("YYYY-MM-DD"),
                    end: moment(billingFilter?.dateRange[1]).format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Invoice Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getBillingTrans = async (type) => {
        axios
            .get(APIURL + "/jsreport/billingTrans", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    month: moment().month() + 1,
                    cus_DDDIDs: cus_DDDIDs,
                    start: moment(billingFilter?.dateRange[0]).format("YYYY-MM-DD"),
                    end: moment(billingFilter?.dateRange[1]).format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Billing Transaction Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getClaimBalance = async (type) => {
        axios
            .get(APIURL + "/jsreport/claimBalance", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    cutoff: moment().startOf("month").format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Claim Balance Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getOutstandingBalance = async (type) => {
        axios
            .get(APIURL + "/jsreport/outstandingBalance", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    start: moment(billingFilter?.dateRange[0]).format("YYYY-MM-DD"),
                    end: moment(billingFilter?.dateRange[1]).format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Billing Outstanding Balance Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getClaimCollect = async (type) => {
        axios
            .get(APIURL + "/jsreport/claimCollect", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    start: moment(billingFilter?.dateRange[0]).format("YYYY-MM-DD"),
                    end: moment(billingFilter?.dateRange[1]).format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Claim Collection Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getPA_Utilization = async (type) => {
        axios
            .get(APIURL + "/jsreport/PA_Utilization", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    start: moment(billingFilter?.dateRange[0]).format("YYYY-MM-DD"),
                    end: moment(billingFilter?.dateRange[1]).format("YYYY-MM-DD"),
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "PA Utilization Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getPayrollReport = async (type) => {
        axios
            .get(APIURL + "/jsreport/timesheet", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Payroll Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getDebarReport = async (type) => {
        axios
            .get(APIURL + "/jsreport/debarment", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Debar Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    };

    const getConsolidatedDebarReport = async (type) => {
        axios
            .get(APIURL + "/jsreport/consolidatedDebarment", {
                params: {
                    com_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                },
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/pdf",
                },
            })
            .then((response) => openPDF(response, type));

        //Activty Log
        let sessionID = window.localStorage.getItem("Fw4_access_Token");
        var hash = sha1(sessionID);
        var log_data = {
            email: props.user.email,
            actionType: "Report Generated",
            actionSubType: "Consolidated Debar Report",
            sessionID: hash,
        };
        await axios.post(APIURL + "/activity_log", log_data);
    }

    const openPDF = (response, reportAction = "open") => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);

        if (reportAction === "download") {
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = "Report-" + new Date() + ".pdf";
            link.click();
        } else {
            window.open(fileURL, "_blank");
        }
    };

    const markFavourite = async (data, type) => {
        const payload = {
            report_id: data?.report_id,
            status: type,
        };

        axios
            .put(APIURL + "/jsreport/markFavorite", payload)
            .then((response) => {
                if (response?.status === 200 && response?.data?.code === 200) {
                    setSuccessText(response?.data?.status_message || "Favorite Modifed Succesfully");
                } else {
                    setErrorText("Something went wrong");
                }

                fetchData();
            });
    }

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10, marginTop: 50, width: '75vw' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    } else {
        return (
            <div style={{ margin: 10, width: '75vw' }}>
                <div>
                    <Typography
                        style={{
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                            fontFamily: "Lato",
                            marginBottom: 30,
                        }}
                    >
                        Favorite Reports
                    </Typography>
                </div>

                <div style={{ marginTop: 20, marginBottom: 10, width: '70vw' }}>
                    {data?.length ? (
                        <div>
                            {data.map((e) => (
                                <ReportFavoriteCard
                                    data={e}
                                    openReport={(type, data) => getReport(type, data)}
                                    markFavourite={(type, data) => markFavourite(type, data)}
                                />
                            ))}
                        </div>
                    ) : (
                        <div style={{ marginTop: 100 }}>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <div>
                                    <img
                                        src="./EVV_reports.png"
                                        alt="EVV_reports"
                                        style={{ width: "10rem", height: "10rem", margin: 10 }}
                                    />
                                </div>
                                <div>
                                    <h5
                                        style={{
                                            fontFamily: "Lato",
                                            marginTop: 48,
                                            marginBottom: 30,
                                        }}
                                        className="text-center"
                                    >
                                        No favorites so far.{" "}
                                    </h5>
                                    <p
                                        style={{ fontSize: 14, width: "100%", fontFamily: "Lato" }}
                                        className="text-muted text-center text-wrap"
                                    >
                                        You’ll see an overview all favorites here.
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <Snackbar
                    open={successText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setSuccessText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"success"}
                        elevation={6}
                        variant="filled"
                    >
                        {successText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setSuccessText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
                <Snackbar
                    open={errorText !== ""}
                    autoHideDuration={30000}
                    onClose={() => setErrorText("")}
                >
                    <Alert
                        sx={{ width: "100%" }}
                        severity={"error"}
                        elevation={6}
                        variant="filled"
                    >
                        {errorText}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => setErrorText("")}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Alert>
                </Snackbar>
            </div>
        );
    };
}