import React, { Component } from 'react';
import { Tabs, Tab, Form, Alert } from 'react-bootstrap';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import OtherDetails from './OtherDetails/OtherDetails';
import MsgModalBox from '../../../container/MessageModalBox/MsgModalBox';
import axios from 'axios';
import moment from 'moment';
import {APIURL} from '../../../config'
import { Redirect } from 'react-router-dom';
  
class ContactForm extends Component {
    state = {
        goHome: false,
        personal_details: {
            "first_name": this.props.edit?this.props.data.con_first_name:"",
            "last_name": this.props.edit?this.props.data.con_last_name:"",
            "address_line_1": this.props.edit?this.props.data.con_Addr_line1:"",
            "address_line_2": this.props.edit?this.props.data.con_Addr_line2:"", 
            "city": this.props.edit?this.props.data.con_city:"",
            "state": this.props.edit?this.props.data.con_state:"",
            "zip": this.props.edit?this.props.data.con_zip:"",
            "Cemail": this.props.edit?this.props.data.con_email:"",
            "prefCommMode": this.props.edit?this.props.data.prefCommMode: "",
            "phnNumList": this.props.edit?this.props.data.con_phone.split(',').map((phn,indx)=> {
                let phone = {phnId: indx, country_code: "+1", phone: phn.slice(2,phn.length)}
                return phone
            }):[{phnId: 1, country_code: "+1", phone: ""}]
        },
        other_details: {
            "designation": this.props.edit?this.props.data.con_designation:"",
            "customer":this.props.edit?(!this.props.data.con_customer_id?'NULL':this.props.data.con_customer_id):"NULL",
            "status": this.props.edit?this.props.data.con_status:"",
            "contact_type": this.props.edit?this.props.data.con_contact_type:"",
            "first_contact_date": this.props.edit?moment(this.props.data.con_first_contact_date).format('YYYY-MM-DDTHH:mm:ss'):moment().format('YYYY-MM-DDTHH:mm:ss'),
            "last_contact_date": this.props.edit?moment(this.props.data.con_last_contact_date).format('YYYY-MM-DDTHH:mm:ss'):moment().format('YYYY-MM-DDTHH:mm:ss')
        },
        key: "personal_details",
        showModal: false,
        addError: false,
        personal_details_val: false,
        other_details_val: false,
        showAlert: false
    }

    async addContactToDB(e){
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": '*',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
             Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
          }
        e.preventDefault();
        e.persist()
        const newState = {
            personal_details: {
                "first_name": "",
                "last_name": "",
                "address_line_1": "",
                "address_line_2": "", 
                "city": "",
                "state": "",
                "zip": "",
                "Cemail": "",
                "phnNumList": [{phnId: 1, country_code: "+1", phone: ""}]
            },
            other_details: {
                "designation": "",
                "customer":"",
                "status": "",
                "contact_type": "",
                "first_contact_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
                "last_contact_date": moment().format('YYYY-MM-DDTHH:mm:ss')
            }
        }
        var data = {
            company_id: this.props.comp_id,
            cont_id: !this.props.edit?null:this.props.data.con_id,
            first_name: this.state.personal_details.first_name, 
            last_name: this.state.personal_details.last_name, 
            address_line_1:this.state.personal_details.address_line_1, 
            address_line_2:this.state.personal_details.address_line_2,
            city: this.state.personal_details.city, 
            state: this.state.personal_details.state, 
            zip: this.state.personal_details.zip,
            email: this.state.personal_details.Cemail,
            prefCommMode: this.state.personal_details.prefCommMode,
            phoneList: this.state.personal_details.phnNumList.map((number) => {return number.country_code+number.phone}).join(),
            designation: this.state.other_details.designation,
            customer_id: this.state.other_details.customer === 'NULL'?null:this.state.other_details.customer,
            status: this.state.other_details.status,
            contact_type: this.state.other_details.contact_type,
            first_contact_date: this.state.other_details.first_contact_date,
            last_contact_date: this.state.other_details.last_contact_date
        };
        console.log(data);
        let msg
        if(!this.props.edit){
            msg = await axios.post( APIURL+"/contacts", data);

        }else{
            msg = await axios.put( APIURL+"/contacts", data);
        }
        if(msg.data.code === 200){
            // debugger;
            e.target.reset(); 
            if(this.props.edit){
                this.props.onUpdate(this.state.personal_details,this.state.other_details)
            }   
            this.setState({personal_details: newState.personal_details})
            this.setState({other_details: newState.other_details})
            this.setState({showModal: true})
            this.setState({addError: false})    
        }else{
            // debugger
            this.setState({personal_details: this.state.personal_details})
            this.setState({other_details: this.state.other_details})
            this.setState({addError: true})
            this.setState({showModal: true})
        }
    }

    goHome = (e) => {
        this.setState({addError: false})
        this.setState({showModal: false})
        this.setState({showAlert: false})
        this.setState({personal_details_val: false})
        this.setState({other_details_val: false})
        this.setState({goHome: true})
    }

    addNew = (e) => {
        if(this.state.addError){
            this.setState({showModal: false})    
            this.setState({showAlert: false})
        }else{
            this.setState({showModal: false})
            this.setState({showAlert: false})
            this.setState({addError: false})
            this.setState({personal_details_val: false})
            this.setState({other_details_val: false})
            this.setState({key: "personal_details"})
        }
    }

    onPrimaryFormChangeHandler = (event) => {
        if(event.target.id === 'address_maps'){
            let oldState = { ...this.state.personal_details}
            console.log(event.value.result.address_components)
            
            oldState.address_line_1 = event.value.result.address_components.find(component => component.types.includes("street_number"))?.long_name + " " + event.value.result.address_components.find(component => component.types.includes("route"))?.long_name
            oldState.city = event.value.result.address_components.find(component => component.types.includes("locality"))?.long_name
            oldState.state = event.value.result.address_components.find(component => component.types.includes("administrative_area_level_1"))?.short_name
            oldState.zip = event.value.result.address_components.find(component => component.types.includes("postal_code"))?.long_name

            this.setState({
                personal_details: oldState
            })

            return;
        }

        let oldState = { ...this.state.personal_details, [event.target.id]: event.target.id==='state'?event.target.value.toUpperCase():event.target.value }
        this.setState({
            personal_details: oldState
        })
    }

    onOtherDetailsFormChangeHandler = (event) => {
        // if(event.target.value === ''){return}
        let oldState = { ...this.state.other_details, [event.target.id]: event.target.value }
        this.setState({
            other_details: oldState
        })
    }
    
    handlePhoneListChange = (id, e) =>{
        let prevState = {...this.state.personal_details}
        let newPhnList = prevState.phnNumList.map(phn => {
            if(phn.phnId === id){
                phn[e.target.id] = e.target.value
            }
            return phn
        })
        prevState.phnNumList = newPhnList
        this.setState({
            personal_details: prevState
        })
    }

    onAddPhone = (event) => {
        let prevState = {...this.state.personal_details}
        let newPhnObj = {phnId: prevState.phnNumList.length+1, country_code: "+1", phone: ""}
        prevState.phnNumList.push(newPhnObj)
        this.setState({
            personal_details: prevState
        })
    }

    onDeletePhone = (event) => {
        let delPhnId = +event.target.id.slice(-1)
        let prevState = {...this.state.personal_details}
        let newPhnList = prevState.phnNumList.filter(phn => phn.phnId !== delPhnId)
        let finalPhnList = newPhnList.map((phn,indx) => ({...phn, phnId: ++indx}))
        prevState.phnNumList = finalPhnList
        this.setState({
            personal_details: prevState
        })
    }

    onChangeTab = (key) =>{
        const conversion = {
            first_name: 'First name',
            last_name: 'Last name',
            address_line_1: 'Address line 1',
            city: 'City',
            state:'State',
            zip: 'Zip code',
            Cemail: 'E-mail',
            phnNumList: 'Phone number',
            designation: 'Designation',
            customer: 'Customer',
            status: 'Contact status',
            contact_type: 'Contact type',
            prefCommMode: 'Preferred comm. mode'
        }
        const preKey = this.state.key;
        var validation=this.props.isFormValidated(this.state[preKey])
   
        if (!(validation.isValidated)) {
            if(preKey === "personal_details" && validation.missingKey !== "prefCommMode"){
                this.setState({personal_details_val: false})
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            } else if (preKey === "personal_details" && validation.missingKey === "prefCommMode" && validation.invalidKey !== undefined) {
                this.setState({personal_details_val: false})
                this.setState({errorMsg: "Please enter a valid " + (validation.invalidKey === "Cemail" ? "email" : "phone number")});
            } else{
                this.setState({other_details_val: false})
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            }
            this.setState({showAlert: true})
            this.setState({key : preKey})
            return false
        }else{
            if(preKey === "personal_details"){
                this.setState({personal_details_val: true})
            }else{
                this.setState({other_details_val: true})
            }
            this.setState({showAlert: false})
            this.setState({key})
            return true
        }
    }

    render() {
        return (
            <div style={{ textAlign: "left", marginLeft: "10px" }}>
                {(this.state.showModal)? (<MsgModalBox edit={this.props.edit} addNew={this.addNew} goHome={this.goHome} text="Contact" showError={this.state.addError} style={{position: "absolute"}}/>) : (
                //<Form onSubmit={(e)=> this.addContactToDB(e)}>
                <Form onSubmit={(e) => {if (this.onChangeTab('other_details')){ this.addContactToDB(e)}
                    else { e.preventDefault()}
                    }} >
                    <h4>{!this.props.edit?"New":"Edit"} Contact</h4>
                    {this.state.showAlert && 
                     <Alert variant="info">
                     {!this.state.errorMsg ? 'Please Fill out missing form fields' : this.state.errorMsg}
                 </Alert>}
                    <Tabs activeKey={this.state.key} id="uncontrolled-tab-example" onSelect={key => this.onChangeTab(key)}>
                        <Tab eventKey="personal_details" title={(this.state.personal_details_val)? 
                            (<div className="d-flex flex-column"><i style={{fontSize: "0.7rem"}} className="fas fa-check text-center"></i><div style={{margin: "-2px",fontSize: "0.9rem"}}>Personal Details</div></div>): 
                            (<div style={{margin: "-1px"}}>Personal Details</div>)}>
                            <PersonalDetails
                                edit={this.props.edit}
                                states = {this.props.states}
                                formChangeHandler={this.onPrimaryFormChangeHandler}
                                totalPhones={this.state.personal_details.noOfPhones}
                                phnNumList = {this.state.personal_details.phnNumList}
                                addPhoneNumber={this.onAddPhone}
                                deletePhoneNumber={this.onDeletePhone}
                                handlePhoneListChange={this.handlePhoneListChange}
                                onContinue={this.onChangeTab}
                                formVals = {this.state.personal_details}
                            />
                        </Tab>
                        <Tab eventKey="other_details" title={(this.state.other_details_val)? 
                            (<div className="d-flex flex-column"><i style={{fontSize: "0.7rem"}} className="fas fa-check text-center"></i><div style={{margin: "-2px",fontSize: "0.9rem"}}>Other Details</div></div>): 
                            (<div style={{margin: "-1px"}}>Other Details</div>)}>
                            <OtherDetails 
                                edit={this.props.edit}
                                designation = {this.props.designation}
                                customerList = {this.props.customerList}
                                contactStatus = {this.props.contactStatus}
                                contactType = {this.props.contactType}     
                                formVals = {this.state.other_details}  
                                formChangeHandler={this.onOtherDetailsFormChangeHandler}/>
                        </Tab>
                    </Tabs>
                </Form>
            )}
                {this.state.goHome &&
                    <Redirect to="/" />
                }
            </div>
        );
    }
};

export default ContactForm;