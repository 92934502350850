import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { APIURL } from '../../../config';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Typography, Box, IconButton, Button, Tabs, Tab, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, MenuItem } from '@mui/material';
import PersonalInformation from '../General/PersonalInformation';
import EmploymentInformation from '../General/EmploymentInformation';
import EmployeeTaskType from '../General/EmployeeTaskType';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import Switch from '@mui/material/Switch';

const EmpSettings = (props) => {
    const [empList, setEmpList] = useState([]);
    const [selectedEmp, setSelectedEmp] = useState(null);
    const [pageSize, setPageSize] = useState(13);
    const [viewMode, setViewMode] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [empToDelete, setEmpToDelete] = useState(null);
    const [empTypeDialogOpen, setEmpTypeDialogOpen] = useState(false);
    const [newEmpType, setNewEmpType] = useState('');
    const [empTypeList, setEmpTypeList] = useState([]);
    const [employeeTaskTypeList, setEmployeeTaskTypeList] = useState([]);

    const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

    async function handleSosToggle(id,status) {
        const empUpdateResp = await axios.put(APIURL + '/employees', {
            emp_id: id,
            sosUpdate: true,
            emp_sos_status: status ? 1 : 0,
        })
        setEmpList(empList.map((emp) => {
            return {
                ...emp,
                emp_sos_status: emp.emp_id === id ? (status ? 1 : 0) : emp.emp_sos_status,
            }
        }))
    }
    
    const getEmployeeTaskTypes = async (emp_id, comp_id) => {
        let empTasks = await axios.get(APIURL + "/employees/EmployeeTasks", {
            params: { comp_id: props.comp_id, employee_id: emp_id },
        });

        let taskTypeList = await axios.get(APIURL + "/dropdowns/dropdownType/fullTaskType", {
            params: { comp_id: comp_id },
        });

        taskTypeList.data.forEach((type) => {
            if(empTasks.data.find((task) => task.ddID === type.ddID)){
                taskTypeList.data.find((task) => task.ddID === type.ddID).is_Active = 0
            } else {
                taskTypeList.data.find((task) => task.ddID === type.ddID).is_Active = 1
            }
        })
        setEmployeeTaskTypeList(taskTypeList.data)
    }

    useEffect(() => {
        const fetchEmpTypes = async () => {
            try {
                const response = await axios.get(APIURL + `/dropdowns/dropdownType/empType?comp_id=${props.comp_id}`);
                setEmpTypeList(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchEmpTypes();
    }, [props.comp_id]);

    useEffect(() => {
        setEmpList(props.empList);
        console.log(props.empList);
    }, [props.empList]);

    useEffect(() => {
        if (!selectedEmp) return;
        let tempEmpList = [...empList];
        let index = tempEmpList.findIndex((emp) => emp.emp_id === selectedEmp.emp_id);
        tempEmpList[index] = selectedEmp;

        setEmpList(tempEmpList);

        getEmployeeTaskTypes(selectedEmp.emp_id, props.comp_id);
    }, [selectedEmp]);

    if (!empList || empList.length === 0) {
        return (
            <Box display="flex" justifyContent="center" width="50%">
                <Typography>No Employee Data</Typography>
            </Box>
        );
    }

    const columns = [
        {
            field: 'emp_name',
            headerName: 'NAME',
            flex: 0.75,
            renderCell: (params) => (
                <Typography style={{ lineHeight: "1", marginTop: "10px" }}>{params.value}</Typography>
            )
        },
        {
            field: 'emp_phone',
            headerName: 'PHONE',
            flex: 0.5,
        },
        {
            field: 'emp_type',
            headerName: 'DESIGNATION',
            flex: 0.7,
        },
        {
            field: 'emp_status',
            headerName: 'STATUS',
            flex: 0.5,
            renderCell: (params) => (
                <Box display="flex" alignItems="center">
                    <Box
                        sx={{
                            border: `1px solid ${params.value ? '#3FA7D6' : 'red'}`,
                            backgroundColor: params.value ? '#3FA7D6' : 'red',
                            borderRadius: '50%',
                            width: '0.5rem',
                            height: '0.5rem',
                            mr: 1
                        }}
                    />
                    <Typography>{params.value ? 'Active' : 'Terminated'}</Typography>
                </Box>
            )
        },
        {
            field: 'emp_join_date',
            headerName: 'JOIN DATE',
            flex: 0.5,
            sortComparator: dateComparator,
        },
        {
            field: 'emp_updated_at',
            headerName: 'LAST UPDATED',
            flex: 0.5,
            sortComparator: dateComparator,
        },
        {
            field: 'sos_eligible',
            headerName: 'SOS STATUS',
            flex: 0.5,
            renderCell: (params) => (<Switch checked={params.value} onChange={(e) => handleSosToggle(params.id, e.target.checked)}/>),
        },
        {
            field: 'details',
            headerName: '',
            flex: 0.5,
            renderCell: (params) => (
                <Box>
                    <IconButton
                        onClick={() => {
                            setSelectedEmp(empList.find((emp) => emp.emp_id === params.row.id));
                            setViewMode(true);
                        }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            setSelectedEmp(empList.find((emp) => emp.emp_id === params.row.id));
                            setEmpTypeDialogOpen(true);
                        }}
                    >
                        <PersonIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            setEmpToDelete(params.row.id);
                            setDeleteDialogOpen(true);
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            )
        }
    ];

    const rows = empList.map((emp) => {
        let phnRec = emp.emp_phone_number ? emp.emp_phone_number.replace(/[.-]/g, '').split('').filter((c) => c !== ' ').join('') : '-';

        return {
            id: emp.emp_id,
            sos_eligible: emp.emp_sos_status === 1 ? 1 : 0,
            emp_name: `${emp.emp_first_name} ${emp.emp_last_name}`,
            emp_phone: phnRec.length === 10 ? `${phnRec.slice(0, 3)}-${phnRec.slice(3, 6)}-${phnRec.slice(6)}` : phnRec.length === 12 ? `${phnRec.slice(2, 5)}-${phnRec.slice(5, 8)}-${phnRec.slice(8)}` : '-',
            emp_type: emp.emp_type,
            emp_status: emp.emp_is_active,
            emp_updated_at: emp.emp_updated_at ? moment(emp.emp_updated_at).format('MM/DD/YYYY') : '-',
            emp_join_date: emp.emp_join_date ? moment(emp.emp_join_date).format('MM/DD/YYYY') : '-'
        };
    });

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleDelete = async () => {
        try {
            await axios.delete(APIURL + '/employees', { data: { bval: 2, id: empToDelete } });
            let tempEmpList = [...empList];
            let index = tempEmpList.findIndex((emp) => emp.emp_id === empToDelete);
            tempEmpList.splice(index, 1);
            setEmpList(tempEmpList);
            setSelectedEmp(null);
            setViewMode(false);
            setDeleteDialogOpen(false);
        } catch (error) {
            console.error(error);
        }
    };

    const handleEmpTypeChange = async () => {
        try {
            let res = await axios.put(APIURL+'/team/', {empList: [selectedEmp.emp_id], type: newEmpType});
            
            let employee = empList.map((emp) => {
                if (emp.emp_id === selectedEmp.emp_id) {
                    emp.emp_type = newEmpType;
                }
                return emp;
            }
            );
            setEmpList(employee);
            setSelectedEmp(null);
            setViewMode(false);
            setEmpTypeDialogOpen(false);

            if (selectedEmp.emp_id === props.user_id) {
                window.location.reload();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minHeight: 650 }}>
            {viewMode ? (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Tabs value={activeTab} onChange={handleTabChange} aria-label="Employee Details Tabs">
                            <Tab label="Personal Information" />
                            <Tab label="Employment Information" />
                            <Tab label="Employee Tasks" />
                        </Tabs>
                        <IconButton
                            onClick={() => {
                                setViewMode(false);
                                setSelectedEmp(null);
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    </Box>
                    {activeTab === 0 && <PersonalInformation employee={selectedEmp} setEmployee={setSelectedEmp} user_id={selectedEmp.emp_id} comp_id={props.comp_id} />}
                    {activeTab === 1 && <EmploymentInformation employee={selectedEmp} setEmployee={setSelectedEmp} empList={empList} levelList={props.levelList} compensationType={props.compensationType} statusList={props.statusList} user_id={selectedEmp.emp_id} comp_id={props.comp_id} type={props.type} />}
                    {activeTab === 2 && <EmployeeTaskType leftColumnSize={props.leftColumnSize} rightColumnSize={props.rightColumnSize} user_id={selectedEmp.emp_id} comp_id={props.comp_id} typeList={employeeTaskTypeList} />}
                </Box>
            ) : (
                <Box>
                    <Typography variant="h5" sx={{ marginBottom: 2 }}>
                        Employees
                    </Typography>
                    <DataGridPro
                        density="compact"
                        rows={rows}
                        columns={columns}
                        components={{ Toolbar: GridToolbar }}
                        pagination
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[pageSize, pageSize * 2, pageSize * 3]}
                        sx={{ height: 600, width: '100%' }}
                    />
                </Box>
            )}

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this employee?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={empTypeDialogOpen}
                onClose={() => setEmpTypeDialogOpen(false)}
            >
                <DialogTitle>Choose Designation</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        label="Employee Type"
                        value={newEmpType}
                        onChange={(e) => setNewEmpType(e.target.value)}
                        fullWidth
                        sx={{ mt: 2, width: 300 }}
                    >
                        {empTypeList.map((type, i) => (
                            <MenuItem key={i} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEmpTypeDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleEmpTypeChange} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EmpSettings;
