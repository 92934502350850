import React,{Component} from 'react'
import Chart from 'chart.js';

class EmpDoubleBar extends Component{
    chartRef = React.createRef();
    componentDidUpdate(){
        try{
        document.getElementById('myChartBar').remove()
        document.getElementById('barContainer').innerHTML = `<canvas id="myChartBar" width="1100" height="365"><canvas>`;
        let canvas = document.querySelector('#myChartBar');
        let ctx = canvas.getContext('2d');
        // const myChartRef = this.chartRef.current.getContext("2d");
        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: this.props.labels,
                datasets: [{
                    label: 'Completed',
                    data: this.props.compdata,
                    backgroundColor: this.props.compdata.map((data)=>('#FFD166')),
                    borderWidth: 1
                },
                {
                    label: 'Ongoing',
                    data: this.props.ondata,
                    backgroundColor: this.props.ondata.map((data)=> ('#3FA7D6')),
                    borderWidth: 1
                }    
            ],
                
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function(value, index, values) {
                                return value + ' Tasks';
                            }
                        }
                    }]
                }
            }
        });
    }catch(e){
            console.log(e)
            return
        }
    }
    render(){
        return(
        <div style={{border:'1px solid #3FA7D6', padding: 10}}  className="text-center">
            <div className='d-flex flex-row justify-content-center'>
            <div onClick={()=> this.props.traverseGraph(0, this.props.pageNum)} ><i className="fas fa-chevron-circle-left mx-3"></i></div> 
            Task Analytics
            <div onClick={()=> this.props.traverseGraph(1, this.props.pageNum)}><i className="fas fa-chevron-circle-right mx-3"></i> </div>
            <div>
                <select onChange={(e) => this.props.changeSize(e.target.value)} value={this.props.pageNum}>
                    <option key='10' value='10'>10</option>
                    <option key='15' value='15'>15</option>
                    <option key='25' value='25'>25</option>
                    <option key='50' value='50'>50</option>
                </select>
            </div>
            </div>
            <hr style={{background: '#3FA7D6'}} className="m-1"/>
            <div id="barContainer">
            {!!this.props.isLoading &&  <div style={{height: '400px', margin: 10}} className='d-flex flex-row w-100 justify-content-center mt-5'><div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
            </div></div>}
             {!this.props.isLoading && this.props.labels.length === 0 &&
                <div className='d-flex flex-column justify-content-center align-items-center' style={{height: '300px',margin: 5}}>
                    <img src='./projectgraphic.png' alt='projectgraphic' style={{width: '15rem', height: '10rem'}}/>
                    <h5 style={{fontFamily: 'Lato',fontSize: 18}}>You dont have any Tasks yet </h5>
                    <p style={{fontFamily: 'Lato',fontSize: 12}} className='text-muted'> Add new tasks and Check-in/out of tasks for graphical analysis of number of completed , ongoing for all your employees to date .</p>
                </div>
                }
             {!this.props.isLoading && this.props.labels.length !== 0 && <canvas id="myChartBar" ref={this.chartRef} width='1100' height="365"></canvas>}
            </div>
        </div>
        )
    }
}

export default EmpDoubleBar