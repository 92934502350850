import React from 'react';
import { Form, Button } from 'react-bootstrap';
// import { Emoji } from 'emoji-mart'
// import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import withStyles from '@mui/styles/withStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import Grid from '@mui/material/Grid';
import moment from 'moment';
import ccs from 'countrycitystatejson';
import './FinalDets.css'
import dayjs from 'dayjs'

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
  })(TextField);

const FinalDets  = (props) =>{
    // let empStatus = props.empStatus.map((empSt,indx)=> <option key={indx} value={empSt}>{empSt}</option>)
    let empStatus = props.empStatus.map((empSt,indx)=>{return {value:empSt, title : empSt}})
    // let currency = props.currency.map((cval,indx)=> <option key={indx} value={cval}>{cval}</option>)
    // let frequency = props.frequency.map((freq,indx)=> <option key={indx} value={freq}>{freq}</option>)
    let frequency = props.frequency.map((freq,indx)=>{return{value:freq,title: freq}})
    let code = ccs.getCountryByShort('US')
    let empAssign = props.selectedEmp.map((emp,indx) =>(
        <div key={emp.empId} className="d-flex flex-column justify-content-between w-75">
            <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row"><div style={{background: "#EBF8FF",width:"2rem", height: "2rem"}} className="text-center rounded-circle">
                    <center>{++indx}</center>
                </div><p style={{paddingLeft: 20}}>{emp.empName}</p></div>
                {(+props.pManagerId !== emp.empId) &&
                <span onClick={props.onDelete.bind(this,emp.empId)}><i className="fas fa-trash"></i></span>
                }
            </div>
            <div style={{paddingLeft: 50}}>
            {/* <Form.Group controlId="pEmpStatus">
                <Form.Label>EMPLOYEE STATUS</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={emp.pEmpStatus} as="select" onChange={props.handleChangeEmp.bind(this,emp.empId)} required>
                    <option value="">Select Status</option>
                    {empStatus}
                </Form.Control>
            </Form.Group> */}
            <Autocomplete
                id="pEmpStatus"
                value={empStatus.find((type)=> type.title === emp.pEmpStatus) || null}
                className={`mx-1 my-3 w-100`}
                onChange={(e, newValue) => {
                    props.handleChangeEmp(emp.empId, {target: {id: "pEmpStatus", value: newValue.title}});
                }}
                options={empStatus}
                getOptionLabel={(option) => option.title}
                // style={{ width: 450 }}
                renderInput={(params) => <CssTextField {...params} label="EMPLOYEE STATUS *"  variant="outlined" />}
            />
            <Form.Row>
                {/* <Form.Group as={Col} controlId="pEmpBillRate">
                    <Form.Label>BILL RATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={emp.pEmpBillRate} placeholder="Amount" onChange={props.handleChangeEmp.bind(this,emp.empId)} required/>
                </Form.Group> */}
                <CssTextField
                    style={{width:'40%'}}
                    // required
                    className={`mx-2 my-3`}
                    label="BILL RATE *"
                    value={emp.pEmpBillRate}
                    onChange={props.handleChangeEmp.bind(this,emp.empId)} 
                    variant="outlined"
                    id="pEmpBillRate"
                    
                    // disabled={!!this.props.edit?true:false}
                />

                {/* <Form.Group as={Col} controlId="pEmpCurrency">
                    <Form.Label>CURRENCY</Form.Label>
                    <Form.Control value={emp.pEmpCurrency} as="select" onChange={props.handleChangeEmp.bind(this,emp.empId)} required>
                        <option value="">Select Currency</option>
                        {currency}
                    </Form.Control>
                </Form.Group> */}
                {/* <Form.Group as={Col} controlId="pEmpCurrency">
                    <Form.Label>CURRENCY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <div className="d-flex flex-row justify-content-around" style={{width: '12.5rem',height:'2.4rem',border:'1px solid #D6D6D6',borderRadius:'5px', textAlign:'center'}}>
                        <div style={{marginLeft: 0, marginTop:5}}><Emoji emoji=':flag-us:' size={26}/></div> <div style={{marginRight: 5, marginTop:5}}>USD</div>
                    </div>
                </Form.Group> */}
                <CssTextField
                  style={{width: '50%'}}
                    className={`mx-1 my-3`}
                    label="CURRENCY"
                    value={`${code.emoji} ${code.name}(${code.currency})`}
                    // onChange={props.formChangeHandler} 
                    variant="outlined"
                    required
                    id="pEmpCurrency"
                />
            </Form.Row>
            {/* <Form.Group controlId="pEmpFrequency">
                <Form.Label>FREQUENCY</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={emp.pEmpFrequency}as="select" onChange={props.handleChangeEmp.bind(this,emp.empId)} required>
                    <option value="">Select Hourly</option>
                    {frequency}
                </Form.Control>
            </Form.Group> */}
            <Autocomplete
                
                id="pEmpFrequency"
                value={frequency.find((type)=> type.title === emp.pEmpFrequency) || null}
                className={`mx-1 my-3 w-100`}
                onChange={(e, newValue) => {
                    props.handleChangeEmp(emp.empId, {target: {id: "pEmpFrequency", value: newValue.title}});
                }}
                options={frequency}
                getOptionLabel={(option) => option.title}
                // style={{ width: 450 }}
                renderInput={(params) => <CssTextField {...params} label="FREQUENCY *"  variant="outlined" />}
            />
                {/* <Form.Group controlId="pEmpAssFromDate">
                <Form.Label>ASSOCIATE FROM</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={emp.pEmpAssFromDate} type="date" onChange={props.handleChangeEmp.bind(this,emp.empId)} required/>
            </Form.Group> */}



            {/* <Form.Group controlId="pEmpAssToDate">
                <Form.Label>ASSOCIATE TO</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control value={emp.pEmpAssToDate} type="date" onChange={props.handleChangeEmp.bind(this,emp.empId)} required/>
            </Form.Group> */}
            <Form.Row>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                    style={{width:'45%'}}
                    className={'mx-2'}
                    id="pEmpAssFromDate"
                    label="START DATE"
                    // disabled={props.edit}
                    value={dayjs(emp.pEmpAssFromDate).format('MM/DD/YYYY')}
                    onChange={(d) => {props.handleChangeEmp(emp.empId,{target: {value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss'),id : 'pEmpAssFromDate'}})}}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            className='projectform-finaldets-datepicker'
                        />
                    )}
                    />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                    style={{width:'45%'}}
                    className={'mx-2'}
                    id="pEmpAssToDate"
                    label="ASSOCIATE TO"
                    // disabled={props.edit}
                    value={dayjs(emp.pEmpAssToDate).format('MM/DD/YYYY')}
                    onChange={(d) => {props.handleChangeEmp(emp.empId,{target: {value: dayjs(d).format('YYYY-MM-DDTHH:mm:ss'),id : 'pEmpAssToDate'}},emp.empId)}}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            className='projectform-finaldets-datepicker'
                        />
                    )}
                    />
                
            </LocalizationProvider>
            
            </Form.Row>

            </div>
        </div>
        
    ));
    // let empList = props.empList.map((emp,indx)=><option key={indx} value={emp.id}>{emp.employee_name}</option>)
    let empList = props.empList.map((emp,indx)=>{return {value:emp.id, title: emp.employee_name}})
    
    return(
        <div>
            <Form.Label>EMPLOYEES</Form.Label>
            {empAssign}
            {/* <Form.Group controlId="pEmp">
                <Form.Label>ADD EMPLOYEE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Control as="select" onChange={props.handleSelectedEmp.bind(this)}>
                    <option value="">Select Employee</option>
                    {empList}
                </Form.Control>
            </Form.Group> */}
            <Autocomplete
                id="pEmp"
                value={null}
                className={`mx-1 my-3 w-75`}
                onChange={(e,newValue)=> {
                    // console.log(e.target.value)

                    let value = empList.find((loc)=> loc.title === newValue.title)
                    // console.log(value)
                    if(!!value){
                        props.handleSelectedEmp({target: {value: value.value, id: 'pEmp'}})
                    }
                    // return
                    
                }}
                options={empList}
                getOptionLabel={(option) => option.title}
                // style={{ width: 450 }}
                renderInput={(params) => <CssTextField {...params} label="ADD EMPLOYEE" variant="outlined" />}
            />
             <Button className='w-75' variant="primary"
                // onClick={(e) => {
                    
                //     if(!!props.validationCheck1 && !!props.validationCheck2 && !!props.validationCheck3){
                //         props.addProjectToDB(e)
                //     }
                //     else{
                //         props.onContinue('finalDetails')
                //     }
                
                // }}
                block type="submit" 
                disabled={props.isSubmitting}
                >
                 Create Project
            </Button>
        </div>
    )
}

export default FinalDets;