import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import AutoComplete from '@mui/material/Autocomplete';

const ClientFilter = (props) => {
    const [client, setClient] = useState(null);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        let clients = new Set();
        props.rows.forEach(row => {
            if (row.client) {
                clients.add(row.client);
            }
        });
        setClients([...clients].map(client => ({ client: client })));
    }, [props.rows]);

    useEffect(() => {
        if (client === null) {
            props.setFilterModel( prev => {
                let newFilterModel = {...prev};
            
                newFilterModel.items = prev.items.filter(item => item.columnField !== "client")
                console.log(newFilterModel);
                return newFilterModel;
            });
        } else {
            props.setFilterModel( prev => {
                let newFilterModel = {...prev};
            
                newFilterModel.items = prev.items.filter(item => item.columnField !== "client")
                newFilterModel.items.push({ columnField: "client", operatorValue: "equals", value: client.client, id: 3 });
                console.log(newFilterModel);
                return newFilterModel;
            });
        }
    }, [client]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end', minWidth: "100px", width: "100%" }}>
            <AutoComplete
                value={client}
                onChange={(e, value) => setClient(value)}
                options={clients}
                sx={{ width: "100%" }}
                size="small"
                getOptionLabel={(option) => option.client}
                renderInput={(params) => 
                    <TextField 
                        {...params} 
                        label="Client" 
                        variant="outlined"
                />}
            />
        </Box>
    )
}

export default ClientFilter;