import React, { Component } from 'react'
import './top_appointment.css'

class TopAppointmentsLeftPane extends Component {
    render() {
        return (
            <div className='top_appointment_left_pane'>
                {
                    this.props.appointments.map((item, index) => (
                        <div key={index}>
                            <div 
                                className={`left_pane_day_title ${item.day === this.props.selectedDay ? 'active' : 'inactive'}`}
                                onClick={() => this.props.selectAppointment(item.day, 0)}
                            >
                                {item.day}
                            </div>
                            {
                                item.details.map((detail, detail_index) => (
                                    <div 
                                        key={detail_index} 
                                        className={`left_pane_appointment ${detail_index === this.props.selectedAppointment && item.day === this.props.selectedDay ? 'active' : 'inactive'}`}
                                        onClick={() => this.props.selectAppointment(item.day, detail_index)}
                                    >
                                        <div className='left_pane_appintment_bullet'/>
                                        <div className='left_pane_appintment_title'>{detail.tas_title}</div>
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        )
    }
}

export default TopAppointmentsLeftPane