import React from 'react';
import { Form, Col, Button } from 'react-bootstrap';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
  })(TextField);

const BasicDets = (props) => {
    // let projList = props.projList.map((proj, indx) => <option key={indx} value={proj.pid}>{proj.pname}</option>)
    console.log(props.projList);
    let unexpiredProjList = props.projList.filter(proj => {
        return (Date.parse(proj.end_date) > Date.now()) || (props.isSupport && (!proj.start_date || !proj.end_date || moment(proj.pro_starts_on).isSameOrBefore("1900-01-01") || moment(proj.pro_ends_on).isSameOrBefore("1900-01-01"))) 
    })
    let projList = unexpiredProjList.map((proj, indx) => ({value:proj.pid ,title: proj.pname, cus_id: proj.cus_id}))
    
    // let taskType = props.taskType.map((task, indx) => <option key={indx} value={task}>{task}</option>)
    let taskType = props.taskType.map((task, indx) => {return {value:task,title:task}})
    let empList = props.empList.map((emp, indx) => <option key={indx} value={emp.id}>{emp.employee_name}</option>)
    // checkboxes solution
    // let empList = props.empList.map((emp, indx) => 
    // <Form.Check 
    //     key={indx}
    //     type='checkbox' 
    //     label={emp.employee_name} 
    //     value={emp.employee_name}
    // >
    // </Form.Check>)
    let taskList = null
    if(!!props.edit){
        // taskList = props.taskList.filter(task => (+task.tas_pro_id === +props.formVals.tProject && task.tas_is_active === 0)).map((task, indx) => <option key={indx} value={task.tas_id}>{task.tas_title}</option>)
        taskList = props.taskList.filter(task => (+task.tas_pro_id === +props.formVals.tProject && task.tas_is_active === 0)).map((task, indx) => {return { value:task.tas_id,title:task.tas_title}})
    }else{
        // taskList = props.taskList.filter(task => (+task.tas_pro_id === +props.formVals.tProject && task.tas_is_active === 0)).map((task, indx) => <option key={indx} value={task.id}>{task.task_name}</option>)
        taskList = props.taskList.filter(task => (+task.tas_pro_id === +props.formVals.tProject && task.tas_is_active === 0)).map((task, indx) => {return {value:task.id,title:task.task_name}})
        taskList = [{value:"No Pre Req",title:'No Pre Req'},...taskList]
    }
    let toggleSelfRadio = false
    let toggleSelectWorkerRadio = false

    if (props.showSelectWorkerField) {
        toggleSelfRadio = false
        toggleSelectWorkerRadio = true
    } else {
        toggleSelfRadio = true
        toggleSelectWorkerRadio = false
    }

    // console.log('props: ', taskList)
    return (
        <div className='mx-3'>

            <CssTextField
                className={`my-3 w-100`}
                label="TASK TITLE"
                value={props.formVals.tTitle}
                onChange={(e) => props.handleBasicDetsChange(e)}
                inputProps={{ maxLength: 50 }}
                variant="outlined"
                id="tTitle"
                required
            />

            <CssTextField
                className={`my-3 w-100`}
                label="TASK DESCRIPTION"
                value={props.formVals.tDesc}
                onChange={(e) => props.handleBasicDetsChange(e)} 
                variant="outlined"
                id="tDesc"
                required
                inputProps={{ maxLength: 1000 }}

            />

            <Autocomplete
                id="tProject"
                value={projList.find((pro)=> pro.value === props.formVals.tProject)}
                className={`my-3 w-100`}

                onChange={(e, newValue) => {
                
                    let value = projList.find((proj) => proj.title === newValue.title);
                    console.log(value);
                    if (!!value) {
                        props.handleProjLoc(value.value, 'tProject');
                        props.handleProjChange(value.cus_id, value.value)
                    }
                    return;
                }}

                options={projList}
                getOptionLabel={(option) => option.title !== undefined ? option.title : option}
                renderInput={(params) => <CssTextField {...params} label="PROJECT *" variant="outlined" />}
            />

            <Autocomplete
                id="tPreReq"
                value={taskList.find((tas)=> tas.value === props.formVals.tPreReq) || null}
                className={`my-3 w-100`}
                onChange={(e, newValue)=> {
                    console.log(newValue)

                    let value = taskList.find((tas)=> tas.title === newValue.title)
                    console.log(value)
                    if(!!value){
                        props.handleProjLoc(value.value, 'tPreReq')
                    }
                    return
                    
                }}
                options={taskList}
                getOptionLabel={(option) => option.title}
                // style={{ width: 450 }}
                renderInput={(params) => <CssTextField {...params} label="TASK PREREQUISITE" variant="outlined" />}
            />

            <Autocomplete
                id="tType"
                value={props.formVals.tType}
                className={`my-3 w-100`}
                onChange={(e, newValue) => {
                    console.log(newValue);
                    if (newValue && newValue.title) {
                        props.handleBasicDetsChange({ target: { id: 'tType', value: newValue.title } });
                    } else {
                        props.handleBasicDetsChange(e);
                    }
                }}
                options={taskType}
                getOptionLabel={(option) => option.title !== undefined ? option.title : option}
                // style={{ width: 450 }}
                renderInput={(params) => <CssTextField {...params} label="TYPE OF TASK *" variant="outlined" />}
            />

            {false && <Form.Group as={Col} controlId="tAssignedTo">
                <Form.Label>ASSIGNED TO</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                <Form.Check
                    type='radio'
                    label='self'
                    value='self'
                    onChange={(e) => props.handleRadioButtonToggle(e)}
                    checked={toggleSelfRadio}
                />
                <Form.Check
                    type='radio'
                    label='Other (Select workers)'
                    value='select worker'
                    onChange={(e) => props.handleRadioButtonToggle(e)}
                    checked={toggleSelectWorkerRadio}
                />
            </Form.Group>}
            {props.showSelectWorkerField ? 
                <Form.Group as={Col} controlId="tAssignedTo">
                    <Form.Label>EMPLOYEE NAME</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                    <Form.Control value={props.formVals.tAssignedTo} as="select" onChange={(e) => props.handleBasicDetsChange(e)}>
                        <option value="">Select employee</option>
                        {empList}
                    </Form.Control>
                </Form.Group>
            : null
        }

            <Button className={`w-100`} variant="primary" block type="button" onClick={() => props.onContinue("dateLocation")}>
                Continue
            </Button>
        </div>
    )
};

export default BasicDets;