import react, { useState, useEffect } from 'react';
import { Box, Typography, Chip, Checkbox, Stack, FormControlLabel, FormGroup, TextField, Button, IconButton } from '@mui/material';
import { Cancel } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import SaveButton from '../Components/SaveButton';
import axios from 'axios';
import { APIURL } from '../../../config';

const Form = (props) => {
    const [allForms, setAllForms] = useState([]);
    const [subscribedForms, setSubscribedForms] = useState([]);
    const [originalForms, setOriginalForms] = useState([]);

    useEffect(() => {
        setAllForms(props.allForms);
    }, [props.allForms]);

    useEffect(() => {
        setSubscribedForms(props.subscribedForms);
        setOriginalForms(props.subscribedForms);
    }, [props.subscribedForms]);

    const handleCheckBoxChange = (event, form) => {
        if(event.target.checked) {
            setSubscribedForms([...subscribedForms, form]);
        } else {
            setSubscribedForms(subscribedForms.filter((f) => f.form_id !== form.form_id));
        }
    }

    const handleChipDelete = (form) => {
        setSubscribedForms(subscribedForms.filter((f) => f.form_id !== form.form_id));
    }

    const handleDeleteForm = (form) => {
        setAllForms(allForms.filter((f) => f.form_id !== form.form_id));
        setSubscribedForms(subscribedForms.filter((f) => f.form_id !== form.form_id));
    }

    const handleSave = async () => {
        try {
            let formsToAdd = subscribedForms.filter((f) => !originalForms.find((of) => of.form_id === f.form_id));
            let formsToDelete = originalForms.filter((of) => !subscribedForms.find((f) => f.form_id === of.form_id));

            let promises = [];

            formsToAdd.forEach((form) => {
                promises.push(axios.post(`${APIURL}/task_form/subscribed`, {
                    company_id: props.comp_id,
                    form_id: form.form_id,
                    created_by: props.user_id
                }));
            });

            formsToDelete.forEach((form) => {
                promises.push(axios.delete(`${APIURL}/task_form/subscribed`, {
                    data: {
                        company_id: props.comp_id,
                        form_id: form.form_id
                    }
                }));
            });
            
            await Promise.all(promises);
            setOriginalForms(subscribedForms);
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            Forms
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Which forms do you use?
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h6">
                            Forms
                        </Typography>
                        <Box sx={{ minHeight: 32 }}>
                            <Box sx={{ minHeight: 40 }}>
                                {subscribedForms.length === 0 && <Typography variant="body1" sx={{ color: 'gray' }}>No Forms selected</Typography>}
                                {subscribedForms.map((form, index) => {
                                    return (
                                        <Chip key={index} label={form.form_name} onDelete={() => handleChipDelete(form)} sx={{ mr: 1, mb: 1 }} color="primary" />
                                    );
                                })}
                            </Box>
                            <Typography variant="h6">
                                Select Forms
                            </Typography>
                        </Box>
                        <FormGroup>
                            <Grid container spacing={0}>
                                {allForms.map((form, index) => (
                                    <Grid item xs={4} key={index}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                            <FormControlLabel
                                                sx={{ height: 25 }}
                                                control={<Checkbox checked={subscribedForms.find((f) => f.form_id === form.form_id) ? true : false} onChange={(e) => handleCheckBoxChange(e, form)} name={form.form_id} />}
                                                label={form.form_name}
                                            />
                                            {(form.is_Active === 3 || form.is_Active === 4) &&
                                                <IconButton onClick={() => handleDeleteForm(form)} sx={{ ml: 1, bottom: 8 }}>
                                                    <Cancel />
                                                </IconButton>
                                            }
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </FormGroup>
                        <SaveButton onClick={() => handleSave()} disabled={JSON.stringify(subscribedForms) === JSON.stringify(originalForms)} />
                    </Stack>
                </Grid>
            </Grid>             
        </Box>
    )
}

export default Form;