import React from 'react'
import Modal from '@mui/material/Modal';
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: useTheme().palette.background.paper,
    border: '2px solid #000',
    boxShadow: useTheme().shadows[5],
    padding: useTheme().spacing(2, 4, 3),
  },
}));

export default function UploadModal(props) {
  const classes = useStyles();

  return (
    <Modal open={props.modalIsOpen} onClose={props.handleModalClose} className={classes.modal}>
      <div className={classes.paper}>
        <span className="close-modal" onClick={props.handleModalClose}>
          <CloseIcon></CloseIcon>
        </span>
        <label>File Details:</label>
        {props.uploadFile && <>
          <p>File Name: {props.uploadFile.name}</p>
          <p>File Type: {props.uploadFile.type}</p>
        </>}
        <button onClick={props.onFileUpload}>
          Upload!
        </button>
      </div>
    </Modal>
  )
}
