import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, LinearProgress, Box, Grid, Divider } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import HealthNeedForm from "../Forms/HealthNeedForm";

const HealthNeedCard = (props) => {
    const [open, setOpen] = useState(false);
    
    const handleClose = () => setOpen(false);
    const handleSubmit = (needs) => {
        props.setHealthNeeds(needs);
    };

    return (
        <Box>
            <Card variant="outlined" style={{ borderRadius: '10px' }}>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={9}>
                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <Typography variant="h5" component="div" >
                                    Health Needs
                                </Typography>
                                <IconButton sx={{ mr : 1 }} onClick={() => setOpen(true)}>
                                    <EditIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h6" component="div" display="inline" sx={{ marginRight: '5px' }}> 
                                    Status: {props.healthNeeds?.allergies && props.healthNeeds?.dietary && props.healthNeeds?.health_hazards && props.healthNeeds?.self_care ? 'Completed' : 'Not Completed'}
                                </Typography>
                                {props.healthNeeds?.allergies && props.healthNeeds?.dietary && props.healthNeeds?.health_hazards && props.healthNeeds?.self_care ? <CheckCircleOutlineIcon sx={{ color: '#3fa7d6' }} fontSize="large" /> : <LoopIcon sx={{ color: '#3fa7d6' }} fontSize="large" />}
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="div" sx={{ marginRight: '5px' }}>
                                Allergies: {props.healthNeeds?.allergies ? <CheckCircleOutlineIcon sx={{ color: '#3fa7d6' }} /> : <LoopIcon sx={{ color: '#3fa7d6' }} />}
                            </Typography>
                            {props.healthNeeds?.allergies ?
                                <Typography ariant="body2" component="div" sx={{ marginLeft: '20px', whiteSpace: 'pre-line' }}>{props.healthNeeds?.allergies}</Typography> :
                                ''
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="div" sx={{ marginRight: '5px' }}>
                                Dietary: {props.healthNeeds?.dietary ? <CheckCircleOutlineIcon sx={{ color: '#3fa7d6' }} /> : <LoopIcon sx={{ color: '#3fa7d6' }} />}
                            </Typography>
                            {props.healthNeeds?.dietary ?
                                <Typography ariant="body2" component="div" sx={{ marginLeft: '20px', whiteSpace: 'pre-line' }}>{props.healthNeeds?.dietary}</Typography> :
                                ''
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="div" sx={{ marginRight: '5px' }}>
                                Health Hazards: {props.healthNeeds?.health_hazards ? <CheckCircleOutlineIcon sx={{ color: '#3fa7d6' }} /> : <LoopIcon sx={{ color: '#3fa7d6' }} />}
                            </Typography>
                            {props.healthNeeds?.health_hazards ?
                                <Typography ariant="body2" component="div" sx={{ marginLeft: '20px', whiteSpace: 'pre-line' }}>{props.healthNeeds?.health_hazards}</Typography> :
                                ''
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="div" sx={{ marginRight: '5px' }}>
                                Self Care: {props.healthNeeds?.self_care ? <CheckCircleOutlineIcon sx={{ color: '#3fa7d6' }} /> : <LoopIcon sx={{ color: '#3fa7d6' }} />}
                            </Typography>
                            {props.healthNeeds?.self_care ?
                                <Typography ariant="body2" component="div" sx={{ marginLeft: '20px', whiteSpace: 'pre-line' }}>{props.healthNeeds?.self_care}</Typography> :
                                ''
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <HealthNeedForm
                open={open}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                healthNeeds={props.healthNeeds}
                setHealthNeeds={props.setHealthNeeds}
                customer_id={props.customer_id}
            />
        </Box>
    );
}

export default HealthNeedCard;