import React from 'react';
import { Badge } from 'react-bootstrap';
import './TaskInfo.css';
import moment from 'moment';

const TaskInfo = (props) => {
    let {is_active} = props;
    return(
         <div className="taskItem">
             <div className="taskHeading">
                <span className="taskName">{props.name}</span>
                <span>{moment(props.start_date.replace(/Z/g, "")).format('D MMM YYYY')} - {moment(props.end_date.replace(/Z/g, "")).format('D MMM YYYY')}</span>
             </div>
             {/* <span className="taskTime">{moment(new Date()).toString()}</span> */}
             {(is_active === 1) && 
                <div className='d-flex flex-row justify-content-between'>
                    {/* {props.momentCode.taskId} */}
                    <span className="taskTime">{props.momentCode && props.momentCode.CheckoutTime.fromNow()}</span>
                    <Badge 
                    style={{margin:2}} 
                    pill variant="success">
                        Completed
                    </Badge>
                </div>
             }
            {(moment(new Date()).isBetween(props.start_date,moment(props.end_date).add(1,'days')) && is_active === 0) && 
                <div className='d-flex flex-row justify-content-between'>
                    <span className="taskTime">{!props.momentCode?`for ${moment.duration((moment(props.end_date).add(1,'d')).diff(moment())).humanize()}`:`checked-in ${moment.duration(moment(props.momentCode.CheckInTime).diff(moment())).humanize()} ago`}</span>
                    <Badge style={{margin:2}} pill variant="info">
                        Active
                    </Badge>
                </div>
            } 
            {(moment(new Date()).isAfter(moment(props.end_date).add(1,'d')) && is_active === 0) && 
                <div className='d-flex flex-row justify-content-between'>
                    <span className="taskTime">{moment(props.end_date.replace(/Z/g, "")).fromNow()}</span>
                    <Badge style={{margin:2}} pill variant="warning">
                        Overdue
                    </Badge>
                </div>
            }
            {(moment(props.start_date).isAfter(new Date()) && is_active === 0) && 
                <div className='d-flex flex-row justify-content-between'>
                    <span className="taskTime">{moment().to(moment(props.end_date.replace(/Z/g, "")))}</span>
                    <Badge style={{margin:2}} pill variant="danger">
                        Upcoming
                    </Badge>
                </div>
            }
         </div>
    )
};

export default TaskInfo;
