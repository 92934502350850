import React, { useEffect, useState } from 'react';
import { Typography, Select, FormControl, MenuItem, InputLabel, Snackbar, Alert } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledFormDataGrid from "../../../components/DataGrid/ControlledFormDataGrid";
import moment from "moment";
import axios from 'axios'
import { APIURL } from '../../../config';

export default function ReportBuilder(props) {
    const apiRef = useGridApiRef();

    const [subscribedForms, setSubscribedForms] = useState([]);
    const [Form_Reports, setForm_Reports] = useState([]);
    const [formID, setFormID] = useState('');
    const [formName, setFormName] = useState('');
    const [reportID, setReportID] = useState('');
    const [reportFormID, setReportFormID] = useState('');
    const [reportName, setReportName] = useState('');
    const [formData, setFormData] = useState([]);
    const [formColumns, setFormColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbar, setSnackbar] = useState(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(async () => {
        const subscribedForms = await axios.get(`${APIURL}/task_form/subscribed?company_id=${props.comp_id}`)
        const Form_Reports = await axios.get(APIURL + '/forms/form_report', { params: { report_company_id: props.comp_id } });

        let subscribed_forms = subscribedForms.data;

        setSubscribedForms(subscribed_forms);
        setForm_Reports(Form_Reports.data);
        setLoading(false);
    }, []);

    useEffect(async () => {
        if (formID || reportFormID) {
            try {
                const formsWithData = await axios.get(APIURL + '/customer_form/formsWithData/' + 0, { params: { compID: props.comp_id, formID: formID || reportFormID } });

                if (formsWithData.data.length === 0) {
                    setSnackbar({
                        children: "No data available!",
                        severity: "error",
                    });
                    return;
                }

                let formComponents;
                let rows = formsWithData.data.map((data, idx) => {
                    formComponents = data.json_schema.components;

                    let formRows = {
                        id: idx,
                        cus_name: data.cus_name,
                        emp_name: data.emp_first_name ? data.emp_first_name + ' ' + data.emp_last_name : '-',
                        tas_title: data.tas_title,
                        tas_start_time: moment(data.empLocdate).utc().format('MM/DD/YYYY hh:mm A'),
                        tas_end_time: moment(data.checkoutEmpDate).utc().format('MM/DD/YYYY hh:mm A'),
                        tas_loc: data.locInfo[0].loc_addr_line1 + ', ' + data.locInfo[0].loc_addr_line2 + ', ' + data.locInfo[0].loc_city + ', ' + data.locInfo[0].loc_state + ' ' + data.locInfo[0].loc_zip,
                        ...data.formData.data
                    }
                    return formRows;
                });

                // Case: If Select Boxes component is used from Form Builder
                rows = rows.map(row => {
                    for (let key in row) {
                        if (typeof row[key] === 'object' && row[key] !== null) {
                            row[key] = Object.keys(row[key])
                                .filter(k => row[key][k])
                                .join(', ');
                        }
                    }
                    return row;
                });

                let columns = [
                    { field: "id", headerName: "ID", hide: true },
                    { field: "cus_name", headerName: "Customer Name", width: 150 },
                    { field: "emp_name", headerName: "Employee Name", width: 150 },
                    { field: "tas_title", headerName: "Task Name", width: 200 },
                    { field: "tas_start_time", headerName: "Task Start Date", width: 150, },
                    { field: "tas_end_time", headerName: "Task End Date", width: 150, },
                    { field: "tas_loc", headerName: "Task Location", width: 200, wrap: true },
                ];

                if (formComponents) {
                    formComponents.forEach((component) => {
                        if (component.key !== 'submit' && component.input) {
                            columns.push({ field: component.key, headerName: component.label });
                        }
                    })
                }

                setFormColumns(columns);
                setFormData(rows);
            } catch (error) {
                setSnackbar({
                    children: error.message,
                    severity: "error"
                });
            }
        }
    }, [formID, reportFormID]);

    const handleFormChange = (event) => {
        setFormData([]);
        setFormColumns([]);
        setReportID('');
        setReportFormID('');
        setReportName('');

        setFormName(event.target.value);
    };

    const handleFormMenuItemClick = (event) => {
        setFormData([]);
        setFormColumns([]);
        setReportID('');
        setReportFormID('');
        setReportName('');

        setFormID(event.target.id);
    }

    const handleReportChange = (event) => {
        setFormData([]);
        setFormColumns([]);
        setFormID('');
        setFormName('');

        setReportName(event.target.value);
    };

    const handleReportMenuItemClick = (event) => {
        setFormData([]);
        setFormID('');
        setFormName('');

        setReportFormID(Form_Reports.filter((report) => report.report_id === parseInt(event.target.id))[0].report_form_id)
        setReportID(event.target.id);
    }

    return (
        <div style={{ marginRight: '40px' }}>
            <Typography
                style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "Lato",
                    marginBottom: 10,
                }}
            >
                Report Builder
            </Typography>
            <div>
                {!!loading && (
                    <div className="d-flex flex-row w-100 justify-content-center mt-5">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {!loading &&
                    <div>
                        <div style={{ display: 'flex', marginBottom: 20 }}>
                            <div style={{ marginRight: 40 }}>
                                <FormControl sx={{ minWidth: 210 }}>
                                    <InputLabel id="employeeSelectLabel">Create a new report</InputLabel>
                                    <Select
                                        labelId="formSelectLabel"
                                        id="formSelect"
                                        value={formName}
                                        label="Create a new report"
                                        onChange={handleFormChange}
                                    >
                                        {subscribedForms.map((form) => (
                                            <MenuItem
                                                id={form.form_id}
                                                key={form.form_id}
                                                value={form.form_name}
                                                onClick={handleFormMenuItemClick}
                                            >
                                                {form.form_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <h4 style={{ marginRight: 40, alignContent: 'center' }}>or</h4>
                            <div style={{ marginRight: 40 }}>
                                <FormControl sx={{ minWidth: 210 }}>
                                    <InputLabel id="employeeSelectLabel">Edit an existing report</InputLabel>
                                    <Select
                                        labelId="custSelectLabel"
                                        id="custSelect"
                                        value={reportName}
                                        label="Edit an existing report"
                                        onChange={handleReportChange}
                                    >
                                        {Form_Reports.map((report) => (
                                            <MenuItem
                                                id={report.report_id}
                                                key={report.report_id}
                                                value={report.report_name}
                                                onClick={handleReportMenuItemClick}
                                            >
                                                {report.report_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                            {formData.length !== 0 && <ControlledFormDataGrid
                                isReportBuilder={true}
                                formID={formID}
                                reportID={reportID}
                                user={props.user}
                                apiRef={apiRef}
                                getRowId={(row) => row.id}
                                rows={formData}
                                columns={formColumns}
                                checkboxSelection={false}
                                disableSelectionOnClick
                            />}
                        </div>
                        {!!snackbar && (
                            <Snackbar
                                open
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                onClose={handleCloseSnackbar}
                                autoHideDuration={6000}
                            >
                                <Alert {...snackbar} onClose={handleCloseSnackbar} />
                            </Snackbar>
                        )}
                    </div>
                }
            </div>
        </div>
    )
};