import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Chip,
    Stack,
    Modal,
    TextField,
    Button,
    ListItemText,
    Autocomplete,
    IconButton,
    Grid
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SaveButton from '../Components/SaveButton';
import axios from 'axios';
import { APIURL } from '../../../config';

const Resource = (props) => {
    const [resources, setResources] = useState([]);
    const [originalResources, setOriginalResources] = useState([]);
    const [locations, setLocations] = useState([]);
    const [contacts, setContacts] = useState([]); // New state for contacts
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [selectedResource, setSelectedResource] = useState(null);

    const [resourceName, setResourceName] = useState('');
    const [capacity, setCapacity] = useState('');
    const [location, setLocation] = useState(null);
    const [notes, setNotes] = useState('');
    const [contact, setContact] = useState(null); // New state for selected contact
    const [editMode, setEditMode] = useState(false); // New state for edit mode
    const [submitted, setSubmitted] = useState(false);
    const [errors, setErrors] = useState({
        resourceName: false,
        location: false,
        capacity: false,
        contact: false // No longer required
    });

    const validate = () => {
        let errors = {
            resourceName: resourceName === '',
            location: location === null,
            capacity: capacity === ''
            // Removed contact validation
        };
        setErrors(errors);
        setSubmitted(true);
        return !Object.values(errors).some((err) => err);
    };

    const handleClose = () => {
        setOpen(false);
        setEditMode(false);
    };

    useEffect(() => {
        setResources(props.resources || []);
        setOriginalResources(props.resources || []);
        setLocations(props.locations || []);
        // Fetch or define your contacts here
        setContacts(props.contacts || []);
    }, [props.resources, props.locations, props.contacts]); // Add contacts to the dependency array

    useEffect(() => {
        if (!open) {
            setResourceName('');
            setCapacity('');
            setLocation(null);
            setNotes('');
            setContact(null); // Reset contact
            setSubmitted(false);
            setErrors({
                resourceName: false,
                location: false,
                capacity: false,
                contact: false // Reset contact error
            });
        }
    }, [open]);

    const addResource = async () => {
        if (!validate()) {
            return;
        }
        setResources([
            ...resources,
            {
                resource_name: resourceName,
                location_id: location ? location.loc_id : null,
                capacity: capacity,
                notes: notes,
                contact_id: contact ? contact.con_id : null // Optional contact ID
            }
        ]);
        setResourceName('');
        setCapacity('');
        setLocation(null);
        setNotes('');
        setContact(null); // Reset contact
        setOpen(false);
    };

    const editResource = async () => {
        if (!validate()) {
            return;
        }
        const updatedResources = resources.map(resource =>
            resource.resource_id === selectedResource.resource_id
                ? { ...resource, resource_name: resourceName, location_id: location.loc_id, capacity, notes, contact_id: contact ? contact.con_id : null, edited: true }
                : resource
        );
        setResources(updatedResources);
        setResourceName('');
        setCapacity('');
        setLocation(null);
        setNotes('');
        setContact(null);
        setOpen(false);
        setEditMode(false);
    };

    const handleSave = async () => {
        const newResources = resources.filter(resource => !originalResources.some(original => original.resource_id === resource.resource_id));
        const removedResources = resources.filter(resource => resource.markedForDeletion);
        const editedResources = resources.filter(resource => resource.edited);

        try {
            const promises = [];
            for (const resource of newResources) {
                promises.push(axios.post(`${APIURL}/resources`, resource));
            }
            for (const resource of removedResources) {
                promises.push(axios.delete(`${APIURL}/resources/${resource.resource_id}`));
            }
            for (const resource of editedResources) {
                promises.push(axios.put(`${APIURL}/resources/${resource.resource_id}`, resource));
            }
            await Promise.all(promises);
            setResources(resources.filter(resource => !resource.markedForDeletion));
            setOriginalResources(resources.filter(resource => !resource.markedForDeletion));
        } catch (error) {
            console.log(error);
        }
    };

    const handleEdit = (resource) => {
        setSelectedResource(resource);
        setResourceName(resource.resource_name);
        setCapacity(resource.capacity);
        setLocation(locations.find(loc => loc.loc_id === resource.location_id));
        setContact(contacts.find(contact => contact.con_id === resource.contact_id));
        setNotes(resource.notes);
        setEditMode(true);
        setOpen(true);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">Resources</Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Resources available when scheduling a session
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <Box sx={{ minHeight: 32 }}>
                            <Box sx={{ minHeight: 40 }}>
                                {resources.length === 0 && (
                                    <Typography variant="body1" sx={{ color: 'gray' }}>
                                        No Resources selected
                                    </Typography>
                                )}
                                {resources.map((resource, index) => (
                                    <Chip
                                        key={index}
                                        label={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {resource.resource_name}
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedResource(resource);
                                                        setOpenInfo(true);
                                                    }}
                                                    sx={{ mr: -1 }}
                                                >
                                                    <InfoOutlinedIcon sx={{ color: 'white' }} />
                                                </IconButton>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleEdit(resource)}
                                                    sx={{ mr: -1 }}
                                                >
                                                    <EditIcon sx={{ color: 'white' }} fontSize='small' />
                                                </IconButton>
                                            </div>
                                        }
                                        onDelete={() => {
                                            const newResources = resources.map(r => {
                                                if (r.resource_id === resource.resource_id) {
                                                    return { ...r, markedForDeletion: !r.markedForDeletion };
                                                }
                                                return r;
                                            });
                                            setResources(newResources);
                                        }}
                                        color={resource.markedForDeletion ? 'error' : 'primary'}
                                        sx={{ mr: 1, mb: 1 }}
                                    />
                                ))}
                            </Box>
                        </Box>
                        <Button
                            onClick={() => setOpen(true)}
                            sx={{ marginTop: 4 }}
                            variant="contained"
                            color="primary"
                        >
                            + Add New Resource
                        </Button>
                        <SaveButton
                            onClick={handleSave}
                            disabled={JSON.stringify(resources) === JSON.stringify(originalResources)}
                        />
                    </Stack>
                </Grid>
            </Grid>
            <Modal open={open} onClose={handleClose}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        maxWidth: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2
                    }}
                >
                    <Typography variant="h6" component="div" sx={{ mb: 3 }}>
                        {editMode ? 'Edit Resource' : 'Add New Resource'}
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                        <TextField
                            label="Resource Name"
                            inputProps={{ maxLength: 50 }}
                            fullWidth
                            value={resourceName}
                            onChange={(e) => setResourceName(e.target.value)}
                            error={errors.resourceName && submitted}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Autocomplete
                            id="location"
                            options={locations}
                            getOptionLabel={(option) =>
                                `${option.loc_addr_line1}, ${option.loc_addr_line2}, ${option.loc_city}, ${option.loc_state}, ${option.loc_zip}`
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Location"
                                    error={errors.location && submitted}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.loc_id}>
                                    <ListItemText
                                        primary={`${option.loc_addr_line1}, ${option.loc_addr_line2}, ${option.loc_city}, ${option.loc_state}, ${option.loc_zip}`}
                                    />
                                </li>
                            )}
                            value={location}
                            onChange={(event, newValue) => {
                                setLocation(newValue);
                            }}
                        />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                        <Autocomplete
                            id="contact"
                            options={contacts}
                            getOptionLabel={(option) => option.con_first_name + ' ' + option.con_last_name}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Contact"
                                    error={errors.contact && submitted}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props} key={option.con_id}>
                                    <ListItemText primary={option.con_first_name + ' ' + option.con_last_name} />
                                </li>
                            )}
                            value={contact}
                            onChange={(event, newValue) => {
                                setContact(newValue);
                            }}
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            label="Capacity"
                            type="number"
                            fullWidth
                            value={capacity}
                            onChange={(e) => setCapacity(e.target.value)}
                            inputProps={{ max: 1000, min: 1 }}
                            error={errors.capacity && submitted}
                        />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            label="Notes"
                            fullWidth
                            value={notes}
                            onChange={(e) => setNotes(e.target.value)}
                            multiline
                            inputProps={{ maxLength: 500 }}
                            rows={4}
                        />
                    </Box>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={editMode ? editResource : addResource}
                    >
                        {editMode ? 'Save Changes' : 'Add Resource'}
                    </Button>
                </Box>
            </Modal>
            <Modal open={openInfo} onClose={() => setOpenInfo(false)}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        maxWidth: '90%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2
                    }}
                >
                    <Typography variant="h6" component="div" sx={{ mb: 3 }}>
                        Resource Information
                    </Typography>
                    {selectedResource && (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" component="div" sx={{ mb: 1, flex: 1 }}>
                                    Resource name
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mb: 2, flex: 2 }}>
                                    {selectedResource.resource_name}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" component="div" sx={{ mb: 1, flex: 1 }}>
                                    Location
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mb: 2, flex: 2 }}>
                                    {locations.find((location) => location.loc_id === selectedResource.location_id)?.loc_addr_line1}, 
                                    {locations.find((location) => location.loc_id === selectedResource.location_id)?.loc_addr_line2}, 
                                    {locations.find((location) => location.loc_id === selectedResource.location_id)?.loc_city}, 
                                    {locations.find((location) => location.loc_id === selectedResource.location_id)?.loc_state}, 
                                    {locations.find((location) => location.loc_id === selectedResource.location_id)?.loc_zip}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" component="div" sx={{ mb: 1, flex: 1 }}>
                                    Contact
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mb: 2, flex: 2 }}>
                                    {contacts.find((contact) => contact.con_id === selectedResource.contact_id)?.con_first_name}{' '}{contacts.find((contact) => contact.con_id === selectedResource.contact_id)?.con_last_name}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" component="div" sx={{ mb: 1, flex: 1 }}>
                                    Capacity
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mb: 2, flex: 2 }}>
                                    {selectedResource.capacity}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Typography variant="subtitle1" component="div" sx={{ mb: 1, flex: 1 }}>
                                    Notes
                                </Typography>
                                <Typography variant="body1" component="div" sx={{ mb: 2, flex: 2 }}>
                                    {selectedResource.notes}
                                </Typography>
                            </Box>
                        </>
                    )}
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={() => setOpenInfo(false)}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default Resource;
