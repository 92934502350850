import React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import axios from 'axios';
// import { APIURL } from '../../../../config';
//import axios from "axios";

const columns = [
    
    { field: 'id', headerName: 'id', width: 170, hide:true },
    { 
        field: 'description', 
        headerName: 'DESCRIPTION', 
        flex:1,
        renderCell : (params) =>{
         return(
            <div>
                <p className="text-wrap" style={{lineHeight:"1"}}>{params.value} </p>
            </div>
        )}
    },
    { 
        field: 'unit', 
        headerName: 'UNIT', 
        width:110,
    },
    {
        field: 'code',
        headerName: 'CODE',
        width:115,
        align:"center"
    },
    {
        field: 'modifierCode',
        headerName: 'MODIFIER',
        width:150,
    },
    
    {
        field: 'taskType',
        headerName: 'TYPE',
        width:135,
    }
    
]



// Hardcoded Data only for front end ----
// const rows = [
//     { id: 1, description: 'Personal Care Assistance', unit: '15 Minutes', code: 'T1019', modifierCode:'', taskType:'Visit'},
//     { id: 2, description: 'Personal Care Assistance Group', unit: '15 Minutes', code: 'T1019', modifierCode:':HQ', taskType:''},
//     { id: 3, description: 'Personal Care Assistance_PD', unit: 'Per Diem', code: 'T1020', modifierCode:'', taskType:''},
//     { id: 4, description: 'Personal Care Assistance (Self Directed) Individual', unit: '15 Minutes', code: 'T1019', modifierCode:':SE', taskType:''},
//     { id: 5, description: 'Personal Care Assistance (Self Directed) Individual - Agency', unit: '15 Minutes', code: 'T1019', modifierCode:':SE:UI', taskType:''},
//     { id: 6, description: 'Personal Care Assistance (Self Directed) Group', unit: '15 Minutes', code: 'S5125', modifierCode:':SE:HQ', taskType:''},
//     { id: 7, description: 'Personal Care Assistance (Self Directed) Group - Agency', unit: '15 Minutes', code: 'S5125', modifierCode:':SE:U3', taskType:''},
//     { id: 8, description: 'MLTSS Home Based Supportive Care', unit: '15 Minutes', code: 'S5130', modifierCode:'', taskType:''},
//     { id: 9, description: 'MLTSS Home Based Supportive Care (Self Directed)', unit: '15 Minutes', code: 'S5130', modifierCode:':HQ', taskType:''},
//     { id: 10, description: 'MLTSS In Home Respite', unit: '15 Minutes', code: 'T1005', modifierCode:'', taskType:''},
//     { id: 11, description: 'DDD Individual Supports', unit: '15 Minutes', code: 'H2016', modifierCode:':HI', taskType:'IS Visit'},
//     { id: 12, description: 'DDD Individual Supports', unit: '15 Minutes', code: 'H2016', modifierCode:':HI:22', taskType:''},
//     { id: 13, description: 'DDD Individual Supports', unit: '15 Minutes', code: 'H2016', modifierCode:':HI:U8', taskType:''},
//     { id: 14, description: 'DDD In Home Respite', unit: '15 Minutes', code: 'T1005', modifierCode:':HI', taskType:''},
//     { id: 15, description: 'DDD In Home Respite', unit: '15 Minutes', code: 'T1005', modifierCode:':HI:U8', taskType:''},
//     { id: 16, description: 'DDD Community Based Supports', unit: '15 Minutes', code: 'H2021', modifierCode:':HI', taskType:'CBS Visit'},
//     { id: 17, description: 'DDD Community Based Supports', unit: '15 Minutes', code: 'H2021', modifierCode:':HI:22', taskType:'CBS Acuity Visit'},
//     { id: 18, description: 'DDD Community Based Supports', unit: '15 Minutes', code: 'H2021', modifierCode:':HI:52', taskType:''},
//     { id: 19, description: 'Chore Services', unit: '15 Minutes', code: 'S5120', modifierCode:'', taskType:''},
//     { id: 20, description: 'Chore Services', unit: 'Per Diem', code: 'S5121', modifierCode:'', taskType:''},
//     { id: 21, description: 'Respiratory Therapy', unit: 'Per Visit', code: 'S5181', modifierCode:'', taskType:''},
//     { id: 22, description: 'HHA/CAN PROV CARE', unit: '1 Hour', code: 'S9122', modifierCode:'', taskType:''},
//     { id: 23, description: 'RN/HR/PDN/EPSDT', unit: '1 Hour', code: 'S9123', modifierCode:'', taskType:''},
//     { id: 24, description: 'LPN/HR/PDN/EPSDT', unit: '1 Hour', code: 'S9124', modifierCode:'', taskType:''},
//     { id: 25, description: 'Speech therapy', unit: 'Per Diem', code: 'S9128', modifierCode:'', taskType:''},
//     { id: 26, description: 'Occupational Therapy', unit: 'Per Diem', code: 'S9129', modifierCode:'', taskType:''},
//     { id: 27, description: 'Physical therapy(Individual)', unit: 'Per Diem', code: 'S9131', modifierCode:'', taskType:''},
//     { id: 28, description: 'Nutritional Counseling', unit: 'Per Visit', code: 'S9470', modifierCode:'', taskType:''},
//     { id: 29, description: 'Private Duty/Independent Nursing', unit: '15 Minutes', code: 'T1002', modifierCode:':UA', taskType:''},
//     { id: 30, description: 'Personal Care SVC', unit: '15 Minutes', code: 'T1019', modifierCode:':TN', taskType:''},
//     { id: 31, description: 'Personal Care Assistance', unit: '15 Minutes', code: 'T1019', modifierCode:'', taskType:'Home Visit'},
//     { id: 32, description: 'Private duty/independent nursing service (S)', unit: '15 Minutes', code: 'T1000', modifierCode:':UA', taskType:''},
//     { id: 33, description: 'LPN Only Private Duty/Independent Nursing Services', unit: '15 Minutes', code: 'T1003', modifierCode:':UA', taskType:''}
// ]

class EvvProcedure extends React.Component {

    state = {
        rows: []
    }
    
    // --- Interacting with API ---
    // async componentDidMount() {
    //     let countMIS_reports = await axios.get(this.props.apiurl+'/employees')
    //     this.setState({countMIS:countMIS_reports.data, loading:false })
    //     console.log(this.state.countMIS)
    // }
    // state = {
    //     data: this.props.data || []
    // }

    // async componentDidUpdate(prevProps, prevState){
    //     if (prevState.data !== this.state.data) {
    //         let dataMaps = await axios.get(APIURL+'/procedureCodes/handleMappingForTaskProcCode', {params : {comp_id: this.props.comp_id}})
    //         this.setState({data: dataMaps.data})
    //     }
       
    // }
    // async componentDidMount(){
    //     // if (prevState.data !== this.state.data) {
    //         let dataMaps = await axios.get(APIURL+'/procedureCodes/handleMappingForTaskProcCode', {params : {comp_id: this.props.comp_id}})
    //         this.setState({data: dataMaps.data})
    //     // }
       
    // }

    componentDidMount(){
        this.setState({rows: this.props.data?.map((rec)=> {
            return { id: rec.procCode_id, description: rec.procCode_description, unit: rec.procUnitOfService, code: rec.procProcCode, modifierCode:rec.procModifierCode, taskType:rec.procTaskType, state: rec.state_id} 
        })})
    }

    componentDidUpdate(prevProps){
        if(prevProps.data !== this.props.data){
            this.setState({rows: this.props.data?.map((rec)=> {
                return { id: rec.procCode_id, description: rec.procCode_description, unit: rec.procUnitOfService, code: rec.procProcCode, modifierCode:rec.procModifierCode, taskType:rec.procTaskType, state: rec.state_id} 
            })})
        }
    }

    render(){
        return(
            <div style={{margin:"auto", width:'100%', display: 'flex'}}>
                <DataGrid 
                    rows={this.state.rows} 
                    columns={columns} 
                    autoHeight={true}
                    showToolbar components={{Toolbar: GridToolbar, }} 
                    pageSize={12} />
            </div>
        )
    }
}
 
export default EvvProcedure;