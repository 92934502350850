import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, LinearProgress, Box, Grid, Divider } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import SupportNeedForm from "../Forms/SupportNeedForm";

const SupportNeedCard = (props) => {
    const [open, setOpen] = useState(false);
    
    const handleClose = () => setOpen(false);
    const handleSubmit = (needs) => {
        props.setSupportNeeds(needs);
    };

    return (
        <Box>
            <Card variant="outlined" style={{ borderRadius: '10px' }}>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={9}>
                            <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <Typography variant="h5" component="div" >
                                    Support Needs
                                </Typography>
                                <IconButton sx={{ mr : 1 }} onClick={() => setOpen(true)}>
                                    <EditIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                                <Typography variant="h6" component="div" display="inline" sx={{ marginRight: '5px' }}>
                                    Status: {props.supportNeeds?.behavior && props.supportNeeds?.mobility && props.supportNeeds?.religious_cultural && props.supportNeeds?.support_settings ? 'Completed' : 'Not Completed'}
                                </Typography>
                                {props.supportNeeds?.behavior && props.supportNeeds?.mobility && props.supportNeeds?.religious_cultural && props.supportNeeds?.support_settings ? <CheckCircleOutlineIcon sx={{ color: '#3fa7d6' }} fontSize="large" /> : <LoopIcon sx={{ color: '#3fa7d6' }} fontSize="large" />}
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="div" sx={{ marginRight: '5px' }}>
                                Behavior: {props.supportNeeds?.behavior ? <CheckCircleOutlineIcon sx={{ color: '#3fa7d6' }} /> : <LoopIcon sx={{ color: '#3fa7d6' }} />}
                            </Typography>
                            {props.supportNeeds?.behavior ?
                                <Typography ariant="body2" component="div" sx={{ marginLeft: '20px', whiteSpace: 'pre-line' }}>{props.supportNeeds?.behavior}</Typography> :
                                ''
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="div" sx={{ marginRight: '5px' }}>
                                Mobility: {props.supportNeeds?.mobility ? <CheckCircleOutlineIcon sx={{ color: '#3fa7d6' }} /> : <LoopIcon sx={{ color: '#3fa7d6' }} />}
                            </Typography>
                            {props.supportNeeds?.mobility ?
                                <Typography ariant="body2" component="div" sx={{ marginLeft: '20px', whiteSpace: 'pre-line' }}>{props.supportNeeds?.mobility}</Typography> :
                                ''
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="div" sx={{ marginRight: '5px' }}>
                                Religious/Cultural: {props.supportNeeds?.religious_cultural ? <CheckCircleOutlineIcon sx={{ color: '#3fa7d6' }} /> : <LoopIcon sx={{ color: '#3fa7d6' }} />}
                            </Typography>
                            {props.supportNeeds?.religious_cultural ?
                                <Typography ariant="body2" component="div" sx={{ marginLeft: '20px', whiteSpace: 'pre-line' }}>{props.supportNeeds?.religious_cultural}</Typography> :
                                ''
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" component="div" sx={{ marginRight: '5px' }}>
                                Support Settings: {props.supportNeeds?.support_settings ? <CheckCircleOutlineIcon sx={{ color: '#3fa7d6' }} /> : <LoopIcon sx={{ color: '#3fa7d6' }} />}
                            </Typography>
                            {props.supportNeeds?.support_settings ?
                                <Typography ariant="body2" component="div" sx={{ marginLeft: '20px', whiteSpace: 'pre-line' }}>{props.supportNeeds?.support_settings}</Typography> :
                                ''
                            }
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <SupportNeedForm
                open={open}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                supportNeeds={props.supportNeeds}
                setSupportNeeds={props.setSupportNeeds}
                customer_id={props.customer_id}
            />
        </Box>
    );
}

export default SupportNeedCard;