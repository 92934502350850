import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIRichTextEditor from 'mui-rte';
import './recent_note.css'

const myTheme = createTheme({
    overrides: {
        MUIRichTextEditor: {
            anchorLink: {
                color: "lightblue",
                textDecoration: "underline",
            },
        },
    },
});

class ContactTile extends Component {
    isJSON = (str) => {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }

    render() {
        return (
            <div className='note_tile'>
                <div style={{ marginLeft: '2%' }}>
                    <div className='note_tile_main'>
                        <div className='note_tile_name'>
                            <NavLink to={{
                                pathname: '/customers',
                                customersProps: {
                                    cus_id: this.props.cus_id,
                                    edit: false
                                }
                            }} className='navlink_name_color'>{this.props.name}</NavLink>
                        </div>
                        <div className='note_tile_created'>{this.props.time}</div>
                    </div>

                    <ThemeProvider theme={myTheme}>
                        <MUIRichTextEditor
                            defaultValue={this.isJSON(this.props.note) ? this.props.note : `{"blocks":[{"key":"block1","text":"${this.props.note.replace(/\n/g, ' ').replace(/\t/g, ' ').replace(/"/g, '\\"')}","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`}
                            readOnly={true}
                            toolbar={false}
                        />
                    </ThemeProvider>
                    {/* <div className='note_tile_desc'>{this.props.note}</div> */}
                </div>
            </div>
        )
    }
}

export default ContactTile
