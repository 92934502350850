import React from 'react'
import './styles/timesheet.css'
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import IconButton from '@mui/material/IconButton';

export default class TimeSheet extends React.Component {
    render() {
        return (
            <div className="timesheet" style={{ border: '1px solid #3FA7D6' }}>
                <div style={{ width: '0.5rem', backgroundColor: '#3FA7D6', marginRight: 10 }} className='border border-5'>
                </div>
                <div className="timesheet-content mx-3">
                    {!this.props.user.comp_settings.some(item => item.feature_value === "primaryBusinessType" && item.feature_id === 101) &&
                        <p className="timesheet-date">{moment(this.props.data.start_time).utc().format('DD MMM, hh:mm A')} - {moment(this.props.data.end_time).utc().format('DD MMM, hh:mm A')}</p>
                    }
                    {this.props.user.comp_settings.some(item => item.feature_value === "primaryBusinessType" && item.feature_id === 101) &&
                        <p className="timesheet-date">{moment(this.props.data.start_time).utc().format('DD MMM')}</p>
                    }
                    <p className="timesheet-time">{(!!this.props.data.taskDets && !!this.props.data.locDets) ? this.props.data.taskDets.tas_title : (!this.props.data.locDets && !!this.props.data.taskDets) ? this.props.data.taskDets.tas_title.length < 20 ? `${this.props.data.taskDets.tas_title} - Call Record` : `${this.props.data.taskDets.tas_title.substr(0, 20)}... - Call Record` : 'Call Record'}</p>
                    <p className="timesheet-time">{!!this.props.data.projDets ? 'Project: ' + this.props.data.projDets.pro_title : 'Project Could Not Be Found'}</p>
                </div>
                <div className='d-flex flex-column'
                    style={{ width: '25%' }}
                >
                    <p className="timesheet-hours"> {moment.duration(moment(this.props.data.start_time).diff(moment(this.props.data.end_time))).humanize({ m: 1000 })}</p>
                    {/* <CheckCircleIcon className="timesheet-hours mt-0"></CheckCircleIcon> */}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: 10 }}>
                        {this.props.data.task_form && <IconButton onClick={() => this.props.setCurrentTask(this.props.data)} style={{ alignSelf: 'flex-end', color: '#3FA7D6' }} fontSize="small">
                            <FormatListBulletedIcon />
                        </IconButton>}
                        <IconButton onClick={() => { this.props.onEdit(this.props.data.id) }} style={{ alignSelf: 'flex-end', marginRight: 5, color: '#3FA7D6' }} fontSize="small">
                            <EditIcon />
                        </IconButton>
                    </div>
                </div>
            </div>
        )
    }
}