import React, { Component } from 'react';
import { Tabs, Tab, Form, Alert } from 'react-bootstrap';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import OtherDetails from './OtherDetails/OtherDetails';
import MsgModalBox from '../../../container/MessageModalBox/MsgModalBox';
import axios from 'axios';
import {APIURL} from '../../../config'
import moment from 'moment'
import dd from '../../../manifestdd.json'

class EmployeeForm extends Component {
    state = {
        personal_details: {
            // "employee_id": "",
            "first_name": "",
            "last_name": "",
            "address1": "",
            "address2": "",
            "city": "",
            "state": "",
            "email": "",
            "zip": "",
            "phnNumList": [{phnId: 1, country_code: "+1", phone: ""}]
        },
        other_details: {
            "join_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
            "status": "",
            "employee_type": "",
            "employee_level": "",
            "manager": "",
            "vacation_days": "",
            "personal_days_off": ""
        },
        key: "personal_details",
        showModal: false,
        addError: false,
        personal_details_val: false,
        other_details_val: false,
        showAlert: false,
        empTypes: [],
    }

    async componentDidMount(){
        const response = await axios.get(APIURL + `/dropdowns/dropdownType/empType?comp_id=${this.props.comp_id}`)
        this.setState({ empTypes: response.data })
    }

    async addEmptoDB(e){

        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": '*',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        e.preventDefault();
        e.persist()
        const newState = {
            personal_details: {
                // "employee_id": "",
                "first_name": "",
                "last_name": "",
                "address1": "",
                "address2": "",
                "city": "",
                "state": "",
                "zip": "",
                "email": "",
                "phnNumList": [{phnId: 1, country_code: "+1", phone: ""}]
            },
            other_details: {
                "join_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
                "status": "",
                "employee_type": "",
                "employee_level": "",
                "manager": "",
                "vacation_days": "",
                "personal_days_off": ""
            }
        }
        var data = {
            company_id: this.props.comp_id,
            first_name: this.state.personal_details.first_name, 
            last_name: this.state.personal_details.last_name, 
            address1:this.state.personal_details.address1, 
            address2:this.state.personal_details.address2,
            city: this.state.personal_details.city, 
            state: this.state.personal_details.state, 
            country:this.state.personal_details.country, 
            zip: this.state.personal_details.zip,
            empEmail: this.state.personal_details.email,
            phoneList: this.state.personal_details.phnNumList.map((number) => {return number.country_code+number.phone}).join(),
            join_date: this.state.other_details.join_date,
            status: this.state.other_details.status,
            employee_type: this.state.other_details.employee_type,
            employee_level: this.state.other_details.employee_level,
            manager: parseInt(this.state.other_details.manager),
            vacation_days: parseInt(this.state.other_details.vacation_days),
            personal_days_off: parseInt(this.state.other_details.personal_days_off) 
        };
        let msg = await axios.post(APIURL+"/employees", data);
        console.log(msg.data);
        
        if(msg.data.code === 200){
            // debugger;
            e.target.reset();    
            this.setState({personal_details: newState.personal_details})
            this.setState({other_details: newState.other_details})
            this.setState({showModal: true})
            this.setState({addError: false})    
        }else{
            // debugger
            this.setState({personal_details: this.state.personal_details})
            this.setState({other_details: this.state.other_details})
            this.setState({addError: true})
            this.setState({showModal: true})
        }
    }

    goHome = (e) => {
        this.setState({addError: false})
        this.setState({showModal: false})
        this.setState({showAlert: false})
        this.setState({personal_details_val: false})
        this.setState({other_details_val: false})
        this.props.history.push("/")
    }

    addNew = (e) => {
        if(this.state.addError){
            this.setState({showModal: false})    
            this.setState({showAlert: false})
        }else{
            this.setState({showModal: false})
            this.setState({showAlert: false})
            this.setState({addError: false})
            this.setState({personal_details_val: false})
            this.setState({other_details_val: false})
            this.setState({key: "personal_details"})
        }
    }

    onPrimaryFormChangeHandler = (event) => {
        if(event.target.id === 'address_maps'){
            let oldState = { ...this.state.personal_details}
            
            oldState.address1 = event.value.result.address_components.find(component => component.types.includes("street_number")).long_name + " " + event.value.result.address_components.find(component => component.types.includes("route")).long_name
            oldState.city = event.value.result.address_components.find(component => component.types.includes("locality")).long_name
            oldState.state = event.value.result.address_components.find(component => component.types.includes("administrative_area_level_1")).short_name
            oldState.zip = event.value.result.address_components.find(component => component.types.includes("postal_code")).long_name

            this.setState({
                personal_details: oldState
            })
            return;
        }

        let oldState = { ...this.state.personal_details, [event.target.id]: event.target.id==='state'?event.target.value.toUpperCase():event.target.value }
        this.setState({
            personal_details: oldState
        })
    }

    onOtherDetailsFormChangeHandler = (event) => {
        let oldState = { ...this.state.other_details, [event.target.id]: event.target.value }
        this.setState({
            other_details: oldState
        })
    }

    handlePhoneListChange = (id, e) =>{
        let prevState = {...this.state.personal_details}
        let newPhnList = prevState.phnNumList.map(phn => {
            if(phn.phnId === id){
                phn[e.target.id] = e.target.value
            }
            return phn
        })
        prevState.phnNumList = newPhnList
        this.setState({
            personal_details: prevState
        })
    }

    onAddPhone = (event) => {
        let prevState = {...this.state.personal_details}
        let newPhnObj = {phnId: prevState.phnNumList.length+1, country_code: "+1", phone: ""}
        prevState.phnNumList.push(newPhnObj)
        this.setState({
            personal_details: prevState
        })
    }

    onDeletePhone = (event) => {
        let delPhnId = +event.target.id.slice(-1)
        let prevState = {...this.state.personal_details}
        let newPhnList = prevState.phnNumList.filter(phn => phn.phnId !== delPhnId)
        let finalPhnList = newPhnList.map((phn,indx) => ({...phn, phnId: ++indx}))
        prevState.phnNumList = finalPhnList
        this.setState({
            personal_details: prevState
        })
    }

    onChange = (key) => {
        const conversion = {
            first_name: 'First name',
            last_name: 'Last name',
            address1: 'Address line 1',
            city: 'City',
            state:'State',
            zip: 'Zip code',
            email: 'E-mail',
            phnNumList: 'Phone number',
            status: 'Employee status',
            employee_type: 'Employee type',
            employee_level: 'Employee level',
            manager: 'Employee Manager',
            vacation_days: 'Vacation days',
            personal_days_off: 'Personal days off'
            
        }
        const preKey = this.state.key;
        var validation=this.props.isFormValidated(this.state[preKey])
   
        if (!(validation.isValidated)) {
            if(preKey === "personal_details"){
                this.setState({personal_details_val: false})
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            }else{
                this.setState({other_details_val: false})
                this.setState({errorMsg: "Please enter "+conversion[validation.missingKey]})
            }
            this.setState({showAlert: true})
            this.setState({key : preKey})
            return false
        }else{
            if(preKey === "personal_details"){
                this.setState({personal_details_val: true})
            }else{
                this.setState({other_details_val: true})
            }
            this.setState({showAlert: false})
            this.setState({key})
            return true
        }
    }

    render() {
        return (
            <div style={{ textAlign: "left", marginLeft: "10px" }}>
                {(this.state.showModal)? (<MsgModalBox addNew={this.addNew} goHome={this.goHome} text="Employee" showError={this.state.addError} style={{position: "absolute"}}/>) : (
                //<Form onSubmit={(e)=> this.addEmptoDB(e)}>
                <Form onSubmit={(e) => {if (this.onChange('other_details')){ this.addEmptoDB(e)}
                    else { e.preventDefault()}
                    }} >
                    <h4>New Employee</h4>
                    {this.state.showAlert && 
                    <Alert variant="info">
                    {!this.state.errorMsg ? 'Please Fill out missing form fields' : this.state.errorMsg}
                </Alert>}
                    <Tabs activeKey={this.state.key} id="uncontrolled-tab-example" onSelect={key => this.onChange(key)}>
                        <Tab eventKey="personal_details" title={(this.state.personal_details_val)? 
                            (<div className="d-flex flex-column"><i style={{fontSize: "0.7rem"}} className="fas fa-check text-center"></i><div style={{margin: "-2px",fontSize: "0.9rem"}}>Personal Details</div></div>): 
                            (<div style={{margin: "-1px"}}>Personal Details</div>)}>
                            <PersonalDetails
                                states={this.props.states}
                                formChangeHandler={this.onPrimaryFormChangeHandler}
                                phnNumList = {this.state.personal_details.phnNumList}
                                addPhoneNumber={this.onAddPhone}
                                deletePhoneNumber={this.onDeletePhone}
                                handlePhoneListChange={this.handlePhoneListChange}
                                onContinue={this.onChange} 
                                formVals = {this.state.personal_details} 
                            />
                        </Tab>
                        <Tab eventKey="other_details" title={(this.state.other_details_val)? 
                            (<div className="d-flex flex-column"><i style={{fontSize: "0.7rem"}} className="fas fa-check text-center"></i><div style={{margin: "-2px",fontSize: "0.9rem"}}>Other Details</div></div>): 
                            (<div style={{margin: "-1px"}}>Other Details</div>)}>
                            <OtherDetails
                                empList={this.props.empList}
                                empStatus={this.props.empStatus}
                                empType={this.state.empTypes}
                                empLevel={this.props.empLevel}
                                formChangeHandler={this.onOtherDetailsFormChangeHandler}
                                formVals = {this.state.other_details} 
                            />
                        </Tab>
                    </Tabs>
                </Form>
                )}
            </div>
        );        
    }
};


export default EmployeeForm;