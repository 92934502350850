import React from 'react';
import Logs from './EVV_AZ_Logs/Logs.js';
import { Procedure } from './Procedure/Procedure.js'
import Reports from '../Reports/Reports.js';
import { Tab, Row, Col, Nav } from 'react-bootstrap';

class EVV_Arizona extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickLogs = this.handleClickLogs.bind(this);
        this.handleClickProcedure = this.handleClickProcedure.bind(this);
    }

    state = {
        key: 0,
    }

    // Handles the onClick event on each of the SideNavBar Nav.Links [(0: Logs), (1: Procedure), (2: Reports)]
    handleClickLogs = () => {
        this.setState({ key: 0 });
    };

    handleClickProcedure = () => {
        this.setState({ key: 1 });
    };

    handleClickReports = () => {
        this.setState({ key: 2 });
    };

    render() {
        // SideNavBar Logic for: Logs | Procedure | Reports 
        let renderComponent = '';
        if (this.state.key === 0) {
            renderComponent = <Logs checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
        } else if (this.state.key === 1) {
            renderComponent = <Procedure checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />
        } else if (this.state.key === 2) {
            renderComponent = <Reports {...this.props} source={"fromEVVAZ"} checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
        } else {
            renderComponent = '';
        }

        return (
            <div>
                <div>
                    <Row>
                        {/* Left Sub-Navbar Linking to other EVV pages. */}
                        <Col lg={3} md={4} sm={6} style={{ maxWidth: '300px' }}>
                            <Tab.Container>
                                <Nav className='flex-column'>
                                    <Nav.Item>
                                        <Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickLogs}>
                                            <span>
                                                <i
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        paddingRight: '23px',
                                                        paddingLeft: '0px',
                                                    }}
                                                    className='fas fa-paperclip'
                                                ></i>
                                            </span>
                                            EVV Activity
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickProcedure}>
                                            <span>
                                                <i
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        paddingRight: '23px',
                                                        paddingLeft: '0px',
                                                    }}
                                                    className='fas fa-clipboard-list'
                                                ></i>
                                            </span>
                                            EVV Procedure
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickReports}>
                                            <span>
                                                <i
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        paddingRight: '23px',
                                                        paddingLeft: '0px',
                                                    }}
                                                    className='fas fa-receipt'
                                                ></i>
                                            </span>
                                            EVV Reports
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                        </Col>
                        {/* EVV Logs/Procedure/Reports Render */}
                        <Col>{renderComponent}</Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default EVV_Arizona;