import React, { useState, useEffect } from "react"
import { Button, Modal } from 'react-bootstrap'
import {APIURL} from '../../config'
import axios from 'axios'
import moment from "moment"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/lab/Autocomplete';
// import Snackbar from '@mui/material/Snackbar';
// import MuiAlert from '@mui/lab/Alert';
// import { isArray } from "@material-ui/data-grid"
// import { DataGrid, GridToolbar, GridApi
//     // GridRowId
// } from '@mui/x-data-grid';
import './SubtasksAddModal.css'


export default function SubtasksCheckoutModal(props) {
    // Declare state variables
    const [name, setName] = useState('');
    const [category, setCategory] = useState(0);
    // eslint-disable-next-line
    const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DDTHH:mm'));
    // eslint-disable-next-line
    const [endDate, setEndDate] = useState(moment().add(1, "minutes").format('YYYY-MM-DDTHH:mm'));

    useEffect(() => {
        let mounted = true;

        const fetchData = async() =>{
            //const res1 = await getSummaryData(currentWeekDates[0],currentWeekDates[6]);
            if(mounted){
                //setSummaryData(res1);
                //setTimeSheetData(res2);
            }
        }
        fetchData();
        return () => { mounted = false };
        // eslint-disable-next-line
    }, []);

    async function handleSubmit(){
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
             Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        //TODO validate information before posting
        //TODO add feedback messages
        // eslint-disable-next-line
        let subtaskUpdate = await axios.post(
            APIURL+'/subtasks',
            {
                task_id: props.task_id,
                subtask_category_id: category,
                reason_code: 0,
                action_taken: 0,
                status: 0,
                subtask_name: name,
            }
        );
        props.hide();
    };
    // eslint-disable-next-line 
    async function getSummaryData(start, end){
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
             Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }

        let summaryDataApi = await axios.get(
            APIURL+'/timesheets',
            {params: {
                comp_id: props.user.comp_id,
                emp_id: props.user.emp_id,
        }})
        return summaryDataApi.data;
    }

    function getSubtaskRows(){
        return(
            <div id="subtasks-add-modal-row">
                <div>
                    <p style={{'fontWeight': '600'}}>Task: {props.modalBody}</p>
                </div>
                <TextField
                    className="subtask-autocomplete"
                    style={{ width: '100%' }}
                    label="Subtask Name"
                    variant="outlined"
                    onChange={(d)=>{
                        setName(d.target.value);
                    }}
                />
                <Autocomplete
                    className="subtask-autocomplete"
                    options={[{title: 'Subtask Type 1', id: 1}, {title: 'Subtask Type 2', id: 2}]}
                    getOptionLabel={(option) => option.title}
                    style={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} label="Category ID" variant="outlined" />}
                    onChange={(e,newValue)=> {
                        if(newValue.title !== ''){
                            if(newValue.title === 'Subtask Type 1'){
                                setCategory(1);
                            }
                            else if(newValue.title === 'Subtask Type 2'){
                                setCategory(2);
                            }
                        }
                    }}
                />
                <TextField
                    id="subtask-add-modal-checkin-datetime"
                    label="CHECK-IN TIME"
                    type="datetime-local"
                    defaultValue={moment().format('YYYY-MM-DDTHH:mm')}
                    onChange={(d)=>{
                        setStartDate(d.target.value);
                    }}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
                <TextField
                    id="subtask-add-modal-checkout-datetime"
                    label="CHECK-OUT TIME"
                    type="datetime-local"
                    defaultValue={moment().add(1, "minutes").format('YYYY-MM-DDTHH:mm')}
                    onChange={(d)=>{
                        setEndDate(d.target.value);
                    }}
                    InputLabelProps={{
                    shrink: true,
                    }}
                />
            </div>
        );
    }

    return(
        <div id="subtasks-add-modal">
            <Modal dialogClassName="modal-90percent" id="subtasks-add-modal" show={props.show} onHide={props.hide}>
                <Modal.Header closeButton>
                    <Modal.Title>Schedule a Subtask</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="subtasks-add-modal-body">
                        {getSubtaskRows()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hide}>
                        Close
                    </Button>
                    <Button className="save-changes-button" variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}