import React, { useState, useEffect } from 'react';
import { Box, Typography, Slider, Checkbox, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import axios from 'axios';
import { APIURL } from '../../../config';

const AgencySettings = (props) => {
    const [tasksBillableByDefault, setTasksBillableByDefault] = useState(props.tasksBillableByDefault);
    const [toleranceVal, setToleranceVal] = useState(props.toleranceVal);

    useEffect(() => {
        setTasksBillableByDefault(props.tasksBillableByDefault);
        setToleranceVal(props.toleranceVal);
    }, [props]);

    const handleCheckBoxChange = async (field, feature_id) => {
        const newValue = !eval(field);
        eval(`set${field.charAt(0).toUpperCase() + field.slice(1)}(newValue)`);

        try {
            let settingsData = (await axios.get(APIURL+"/config/settings", { params: { comp_id: props.comp_id, feature_id: feature_id } })).data;
            if (settingsData.length > 0) {
                let settingsPayload = {
                    settings_id: settingsData[0].settings_id, feature_value: newValue ? 'true' : 'false', feature_id: feature_id,
                }
                await axios.put(APIURL+`/config/settings`, {...settingsPayload});
            } else {
                let settingsPayload = {
                    feature_value: newValue ? 'true' : 'false', 
                    feature_id: feature_id, 
                    company_id: props.comp_id,
                    added_by: props.user_id
                }
                await axios.post(APIURL+`/config/settings`, {...settingsPayload});
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleSliderChange = (event, newValue) => {
        setToleranceVal(Number(newValue));
    }

    const handleSliderCommit = async (field, feature_id) => {
        try {
            let settingsData = (await axios.get(APIURL+"/config/settings", { params: { comp_id: props.comp_id, feature_id: feature_id } })).data;
            if (settingsData.length > 0) {
                let settingsPayload = {
                    settings_id: settingsData[0].settings_id, feature_value: String(toleranceVal), feature_id: feature_id,
                }
                await axios.put(APIURL+`/config/settings`, {...settingsPayload});
            } else {
                let settingsPayload = {
                    feature_value: toleranceVal, 
                    feature_id: feature_id, 
                    company_id: props.comp_id,
                    added_by: props.user_id
                }
                await axios.post(APIURL+`/config/settings`, {...settingsPayload});
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            EVV Settings
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Set configuration for tasks requiring EVV
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6" sx={{ whiteSpace: 'nowrap' }}>
                            Tasks Billable By Default
                        </Typography>
                        <Checkbox
                            checked={tasksBillableByDefault}
                            onChange={() => handleCheckBoxChange('tasksBillableByDefault', 56)}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant="h6">
                            Check In/Out Tolerance Range
                        </Typography>
                        <Slider
                            value={toleranceVal || 100}
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                            style={{ width: '50%' }}
                            step={50}
                            min={100}
                            max={1000}
                            onChangeCommitted={() => handleSliderCommit('toleranceVal', 57)}
                        />
                        <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                            {toleranceVal} feet
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AgencySettings;
