import React, { useState, useEffect } from 'react';
import { Box, Typography, Checkbox, Stack, FormControlLabel, FormGroup, Divider, Grid } from '@mui/material';
import axios from 'axios';
import { APIURL } from '../../../config';

const SecureCommunication = (props) => {
    const [enableMessagingToClients, setEnableMessagingToClients] = useState(false);
    const [enableMessagingToEmployees, setEnableMessagingToEmployees] = useState({
        mtSubmission: props.mtSubmission,
        mtRejected: props.mtRejected,
        mtApproved: props.mtApproved,
        taskAssigned: props.taskAssigned,
        taskStartTimeApproaching: props.taskStartTimeApproaching,
        taskEndTimeApproaching: props.taskEndTimeApproaching,
    });
    const [enableSoSMessaging, setEnableSoSMessaging] = useState(false);
    const [enableMessagingToContacts, setEnableMessagingToContacts] = useState(false);

    useEffect(() => {
        setEnableMessagingToClients(props.enableMessagingToClients);
        setEnableMessagingToEmployees({
            mtSubmission: props.mtSubmission,
            mtRejected: props.mtRejected,
            mtApproved: props.mtApproved,
            taskAssigned: props.taskAssigned,
            taskStartTimeApproaching: props.taskStartTimeApproaching,
            taskEndTimeApproaching: props.taskEndTimeApproaching,
        });
        setEnableSoSMessaging(props.enableSoSMessaging);
        setEnableMessagingToContacts(props.enableMessagingToContacts);
    }, [props]);

    const handleSave = async (featureId, value) => {
        try {
            const { data } = await axios.get(`${APIURL}/config/settings`, {
                params: { comp_id: props.comp_id, feature_id: featureId },
            });

            const settingsPayload = {
                feature_value: value ? 'true' : 'false',
                feature_id: featureId,
                company_id: props.comp_id,
                added_by: props.user_id,
            };

            if (data.length > 0) {
                settingsPayload.settings_id = data[0].settings_id;
                await axios.put(`${APIURL}/config/settings`, settingsPayload);
            } else {
                await axios.post(`${APIURL}/config/settings`, settingsPayload);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleCheckBoxChange = (event, featureId, setState) => {
        const { checked } = event.target;
        setState(checked);
        handleSave(featureId, checked);
    };

    const handleEmployeeMessagingChange = (event, featureId, key) => {
        const { checked } = event.target;
        setEnableMessagingToEmployees((prevState) => ({
            ...prevState,
            [key]: checked,
        }));
        handleSave(featureId, checked);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={props.leftColumnSize}>
                    <Stack spacing={1}>
                        <Typography variant="h5">
                            Secure Communication
                        </Typography>
                        <Typography variant="body1" sx={{ color: 'gray' }}>
                            Configure your secure communication settings.
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={props.rightColumnSize}>
                    <Stack spacing={1}>
                        <FormGroup>
                            <Typography variant="h6">Enable messaging to employees</Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={enableMessagingToEmployees.mtSubmission}
                                                onChange={(e) => handleEmployeeMessagingChange(e, 60, 'mtSubmission')}
                                                name="mtSubmission"
                                            />
                                        }
                                        label="MT Submission"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={enableMessagingToEmployees.mtRejected}
                                                onChange={(e) => handleEmployeeMessagingChange(e, 61, 'mtRejected')}
                                                name="mtRejected"
                                            />
                                        }
                                        label="MT Rejected"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={enableMessagingToEmployees.mtApproved}
                                                onChange={(e) => handleEmployeeMessagingChange(e, 62, 'mtApproved')}
                                                name="mtApproved"
                                            />
                                        }
                                        label="MT Approved"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={enableMessagingToEmployees.taskAssigned}
                                                onChange={(e) => handleEmployeeMessagingChange(e, 63, 'taskAssigned')}
                                                name="taskAssigned"
                                            />
                                        }
                                        label="Task assigned"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={enableMessagingToEmployees.taskStartTimeApproaching}
                                                onChange={(e) => handleEmployeeMessagingChange(e, 64, 'taskStartTimeApproaching')}
                                                name="taskStartTimeApproaching"
                                            />
                                        }
                                        label="Task start time approaching"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={enableMessagingToEmployees.taskEndTimeApproaching}
                                                onChange={(e) => handleEmployeeMessagingChange(e, 65, 'taskEndTimeApproaching')}
                                                name="taskEndTimeApproaching"
                                            />
                                        }
                                        label="Task end time approaching"
                                    />
                                </Grid>
                            </Grid>
                            <Divider sx={{ my: 1 }} />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={enableMessagingToClients}
                                        onChange={(e) => handleCheckBoxChange(e, 66, setEnableMessagingToClients)}
                                        name="enableMessagingToClients"
                                    />
                                }
                                label="Enable messaging to clients"
                            />
                            <Divider sx={{ my: 1 }} />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={enableSoSMessaging}
                                        onChange={(e) => handleCheckBoxChange(e, 67, setEnableSoSMessaging)}
                                        name="enableSoSMessaging"
                                    />
                                }
                                label="Enable SoS messaging"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={enableMessagingToContacts}
                                        onChange={(e) => handleCheckBoxChange(e, 68, setEnableMessagingToContacts)}
                                        name="enableMessagingToContacts"
                                    />
                                }
                                label="Enable messaging to Contacts"
                            />
                        </FormGroup>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SecureCommunication;
