import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { Form, FormControl, Button } from "react-bootstrap";
import ReportFavoriteCard from "./ReportFavoriteCard";
import axios from "axios";
import { APIURL } from "../../config";
import { Typography } from "@mui/material";

const Favorites = (props) => {
  const [searchText, setSearchText] = useState("");

  const onSubmitSearch = () => {
    alert("Search Text: " + searchText);
  };

  return (
    <Grid>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "1.5rem",
              fontFamily: "Lato",
              marginBottom: 40,
            }}
          >
            Favorite Reports
          </Typography>
        </Grid>

        {/* <Grid item xs={5}>
          <Form inline onSubmit={onSubmitSearch} className="mb-4">
            <FormControl
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              type="text"
              value={searchText}
              placeholder="Search"
              className="form-control w-50 mr-1"
            />
            <Button
              type="submit"
              variant="outline-primary"
              style={{
                border: "solid 1px #95D0EB",
                marginLeft: "-5px",
                borderRadius: "0 5px 5px 0",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <i className="fas fa-search"></i>
            </Button>
          </Form>s
        </Grid> */}

        { props?.data?.length ? <Grid item xs={5}></Grid> : null }

        {/* {props?.data?.length ? <Grid
          item
          xs={4}
          container
          direction="row"
          justifyContent="flex-end"
          className="mb-2"
          alignItems="center"
        >
          <button
            style={{ outline: "none" }}
            type="button"
            className="btn btn-sm"
            data-toggle="modal"
            data-target="#staticBackdropA"
          >
            <i className="fas fa-filter"></i> Filters
          </button>
          <div className="dropdown">
            <button
              style={{ outline: "none" }}
              className="btn dropdown-toggle mb-1"
              type="button"
              id="dropdownMenu2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span>
                <i className="fas fa-sort-amount-up"></i>
              </span>{" "}
              SORT BY
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
              <button
                onClick={() => console.log("title")}
                className="dropdown-item"
                type="button"
              >
                Title
              </button>
              <button
                onClick={() => console.log("date")}
                className="dropdown-item"
                type="button"
              >
                Date
              </button>
            </div>
          </div>
        </Grid> : null} */}
      </Grid>

      <Grid container style={{ bottom: "10px", position: "relative" }}>
        {props?.data?.length ? (
          <Grid item xs={9}>
            {props?.data.map((e) => (
              <ReportFavoriteCard
                data={e}
                openReport={props?.openReport}
                isFavorite={props?.isFavorite ?? false}
                markFavourite={props?.markFavourite}
              />
            ))}
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent={"center"}
              style={{ marginTop: 100 }}
              // className="d-flex flex-row w-100 justify-content-center align-items-center"
            >
              <div>
                <img
                  src="./EVV_reports.png"
                  alt="EVV_reports"
                  style={{ width: "10rem", height: "10rem", margin: 10 }}
                />
              </div>
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h5
                  style={{
                    fontFamily: "Lato",
                    marginTop: 48,
                    marginBottom: 30,
                  }}
                  className="text-center"
                >
                  No favorites so far.{" "}
                </h5>
                <p
                  style={{ fontSize: 14, width: "100%", fontFamily: "Lato" }}
                  className="text-muted text-center text-wrap"
                >
                  You’ll see an overview all favorites here.
                </p>
              </div>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Favorites;
