import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../config";
import moment from "moment";
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';

export default function ServiceAuth(props) {

    const [isLoading, setLoading] = useState(true);
    const [serviceAuthData, setServiceAuthData] = useState([]);

    useEffect(() => {
        async function fetchData() {
            let serviceAuth = await axios.get(APIURL + "/reports/getServiceAuth", {
                params: {
                    companyID: props.comp_id,
                    start:
                        props?.billingFilterData?.dateRange[0] &&
                            moment(
                                props?.billingFilterData?.dateRange[0]
                            ).isValid()
                            ? moment(
                                props?.billingFilterData?.dateRange[0]
                            ).format("YYYY-MM-DD")
                            : moment().startOf("month").format("YYYY-MM-DD"),
                    end:
                        props?.billingFilterData?.dateRange[1] &&
                            moment(
                                props?.billingFilterData?.dateRange[1]
                            ).isValid()
                            ? moment(
                                props?.billingFilterData?.dateRange[1]
                            ).format("YYYY-MM-DD")
                            : moment().endOf("month").format("YYYY-MM-DD"),
                },
            });

            let serviceAuthData = serviceAuth.data;
            let id_num = 0;
            for (var i = 0; i < serviceAuthData.length; i++) {
                serviceAuthData[i].id = id_num;
                id_num += 1;
            }

            setServiceAuthData(serviceAuthData)
            setLoading(false);
        }
        fetchData();
    }, [props?.billingFilterData]);

    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 50,
            hide: true,
        },
        {
            field: "DDDID",
            headerName: "DDDID",
            width: 80,
            hide: false,
            editable: false,
        },
        {
            field: "PatientName",
            headerName: "Patient Name",
            width: 150,
            hide: false,
            editable: false,
        },
        {
            field: "DateOfBirth",
            headerName: "DOB",
            width: 100,
            hide: true,
            editable: false,
        },
        {
            field: "project_title",
            headerName: "Project Title",
            width: 100,
            hide: false,
            editable: false,
        },
        {
            field: "memberMedicaid",
            headerName: "Medicaid ID",
            width: 120,
            hide: false,
            editable: false,
        },
        {
            field: "priorAuth",
            headerName: "Prior Auth",
            width: 110,
            hide: false,
            editable: false,
        },
        {
            field: "procedureCode",
            headerName: "Proc Code",
            width: 100,
            hide: false,
        },
        {
            field: "DiagnosisCode",
            headerName: "Diagnosis Code",
            width: 120,
            hide: false,
            editable: false,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Diagnosis Code
            //         </div>
            //     );
            // },
        },
        {
            field: "startDate",
            headerName: "Task Start Date",
            width: 120,
            hide: false,
            editable: false,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Task Start Date
            //         </div>
            //     );
            // },
        },
        {
            field: "endDate",
            headerName: "Task End Date",
            width: 120,
            hide: false,
            editable: false,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Task End Date
            //         </div>
            //     );
            // },
        },
        {
            field: "authUnits",
            headerName: "Auth Units",
            width: 100,
            hide: false,
            editable: false,
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Auth Units
            //         </div>
            //     );
            // },
        },
        {
            field: "authAmount",
            headerName: "Auth Amount",
            width: 100,
            hide: false,
            editable: false,
            align: "right",
            // renderHeader: (params) => {
            //     return (
            //         <div
            //             style={{
            //                 maxHeight: "inherit",
            //                 width: "100%",
            //                 whiteSpace: "initial",
            //                 lineHeight: "16px",
            //                 verticalAlign: "bottom",
            //             }}
            //         >
            //             Auth Amount
            //         </div>
            //     );
            // },
        },
    ]

    let rows = serviceAuthData.map((claim) => {
        let claimDets = {
            id: claim.id,
            DDDID: Array.isArray(claim.DDDID) ? claim.DDDID[0] : claim.DDDID,
            PatientName: claim.cus_name,
            DateOfBirth: !!claim.dob ? moment(claim.dob).utc().format("L") : '',
            project_title: claim.pro_title,
            memberMedicaid: claim.memberMedicaid,
            priorAuth: claim.priorAuth,
            procedureCode: claim.procedureCode,
            startDate: moment(claim.startDate).utc().format("L"),
            endDate: moment(claim.endDate).utc().format("L"),
            DiagnosisCode: claim.PrimaryDiagnosisCode,
            authUnits: claim.authUnits,
            authAmount: claim.authAmount.toFixed(2),
            errCode: claim.errCode === 'None' ? '' : claim.errCode,
            errDescription: claim.errDescription === 'Null' ? '' : claim.errDescription,
        };
        return claimDets;
    });

    return (
        <div id="claims-main">
            <div style={{ marginBottom: '10px', marginTop: '10px' }}>
                <h5>Service Authorization</h5>
            </div>
            <div>
                <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '100%', height: "calc(100vh - 340px)", background: 'white' }}>
                        <DataGridPro
                            rows={rows}
                            columns={columns}
                            checkboxSelection={false}
                            disableSelectionOnClick
                            width={"100%"}
                            justifyContent="center"
                            components={{
                                Toolbar: GridToolbar,
                            }}
                        />
                    </Box>
                </div>
            </div>
        </div>
    )

}