import React, { useState, useEffect } from "react"
import { APIURL } from '../../config'
import axios from 'axios'
import moment from 'moment'
import { Modal, Button, Dropdown, DropdownButton } from 'react-bootstrap'
import MessagePage from "../../components/MessagePages/MessagePage"


export default function SubscriptionModal(props) {

    const [showModal, setShowModal] = useState(false);
    const [daysRemaining, setdaysRemaining] = useState(0);
    const [modaltest,setModaltest] = useState();
    useEffect(() => {
        subscriptionDays()
    
    }, []);

    async function subscriptionDays() {

        
        let companyData = await axios.get(`${APIURL}/company`,{ params: { comp_id:props.user.comp_id}})
        console.log(companyData)
    
        let today=moment();
       
        let subDate=moment(companyData.data[0].subscriptionEndDate);
        let trialDate=moment(companyData.data[0].trialEndDate)
        let subdaysRemaining = subDate.diff(today, 'days')
        let trialdaysRemaining = trialDate.diff(today, 'days')
        let GraceEndDate=moment(subDate).add(3,'days')
        let GraceRemain = GraceEndDate.diff(today, 'days')

        if(subdaysRemaining<=5 && subdaysRemaining>0)
        {
            setShowModal(true)
            setdaysRemaining(subdaysRemaining)
            setModaltest(true)
            
        }
        else if(trialdaysRemaining<=5 && trialdaysRemaining>0){
            setShowModal(true)
            setdaysRemaining(trialdaysRemaining)
            setModaltest(true)
        }
        else if(GraceRemain<=3 && GraceRemain>0){
            setShowModal(true)
            setdaysRemaining(GraceRemain)
            setModaltest(false)
        }
        else{
            setShowModal(false)
        }
      
      }
      
    return (

        <div>
            {/* Subscription remaining days modal */}
            <Modal
            show={showModal}
            onHide={() => { setShowModal(false)}}
            backdrop="static"
            keyboard={false}
            >

            <Modal.Header>  
                <div style={{ paddingLeft: '150px', color: '#0288D1' }}>
                <h3>IMPORTANT!  </h3>
                </div>
            
            </Modal.Header>
            <Modal.Body>
            
                
                {modaltest==false ? <MessagePage title={`Your grace period expires in ${daysRemaining} days.`}></MessagePage> : <MessagePage title={`Your subscription expires in ${daysRemaining} days`}></MessagePage> }
               
                

                {modaltest==false ? <div style={{ textAlign: 'center', paddingBottom: '10px'}}>You subscription has expired. You can go to <a href="https://fieldworker.ai/pricing/" target="_blank">www.fieldworker.ai</a> to upgrade your plan. </div> : <div style={{ textAlign: 'center', paddingBottom: '10px'}}> You can go to <a href="https://fieldworker.ai/pricing/" target="_blank">www.fieldworker.ai</a> to upgrade your plan. </div>}
            </Modal.Body>
            <Button onClick={() => { setShowModal(false) }}>Okay</Button>
            </Modal>
        </div>

    );

}

