import React, { Component } from 'react'
import { APIURL } from '../../config';
import axios from 'axios'
import ContactTile from './ContactTile'
import './recent_call.css'
import moment from 'moment'
import MessagePage from '../MessagePages/MessagePage'

class RecentCalls extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contacts: [],
            isLoading: false
        }
    }
    componentDidMount() {
        if (this.props.user) {
            this.fetchRecentCalls()
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user && this.props.user) {
            this.fetchRecentCalls()
        }
    }
    async fetchRecentCalls() {
        this.setState({ isLoading: true })
        let result = []
        const emp_id = this.props.user.emp_id
        const incoming_call = await axios.get(`${APIURL}/calls`, { params: { com_id: this.props.user.comp_id, emp_id: this.props.user.emp_id, lastN: 50 } })
        // const outgoing_call = await axios.get(`${APIURL}/customer_calls`, {params: {com_id: this.props.user.cus_company_id, customer_id: cust_id}})
        if (incoming_call.data.length > 0) {
            let calls = incoming_call.data || []
            calls = calls.filter(call => (!!call.recpDetails && (call.recpDetails.id === emp_id || call.callerDetails.id === emp_id)))
            calls.forEach(call => {
                let result_obj = {}
                let isIncoming = call.recpDetails.id === emp_id
                result_obj.call_type = isIncoming ? 'incoming' : 'outgoing'
                result_obj.name = isIncoming ? call.callerDetails.name : call.recpDetails.name
                result_obj.phone = isIncoming ? call.callerDetails.phone : call.recpDetails.phone
                result_obj.subtitle = isIncoming ? call.callerDetails.desg : call.recpDetails.desg
                result_obj.date = call.endDateOfCall
                result_obj.duration = moment.duration(moment(call.endDateOfCall).diff(moment(call.startDateOfCall))).humanize()
                result.push(result_obj)
            })
        }
        // if(outgoing_call.data.length > 0) {
        //     let calls = outgoing_call.data
        //     calls.forEach(call => {
        //         let result_obj = {}
        //         result_obj.call_type = 'outgoing'
        //         result_obj.name = call.employee[0].name
        //         result_obj.phone = call.employee[0].phone
        //         result_obj.subtitle = call.employee[0].desg
        //         result_obj.date = call.endDateOfCall
        //         result_obj.duration = Math.ceil((new Date(call.endDateOfCall).getTime() - new Date(call.startDateOfCall).getTime()) / 60000)
        //         result.push(result_obj)
        //     })
        // }
        result = result.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 10)
        this.setState({
            contacts: result, isLoading: false
        })
    }
    render() {
        // console.log('contacts', this.state.contacts)
        return (
            <div className='recent_calls'>
                <div className="recent_calls_title" style={{  }}>
                    <div className='recent_calls_title_txt'>RECENT CALLS</div>
                    <div className='contact_call_icon'>
                        <i className="fas fa-phone-alt"></i>
                    </div>
                </div>
                <div className='recent_calls_subtitle' style={{  }}>
                    Your last 10 calls:
                </div>
                <div style={{ overflowY: 'auto', marginTop: '20px', }}>
                    <div style={{ height: '%50' }}>

                        {!!this.state.isLoading &&
                            <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!this.state.isLoading && this.state.contacts.length === 0 &&
                            <div style={{ margin: 30, height: '100%' }} className='d-flex flex-column justify-content-center align-items-center'>
                                {/* <p style={{ fontFamily: 'Lato', fontSize: 20, width: '70%' }} className='text-muted text-center text-wrap'>No Calls Yet</p> */}
                                {<MessagePage title="No Recent Calls Found" icon="fas fa-phone-alt"></MessagePage>}
                            </div>
                        }
                        {
                            this.state.contacts.map((item, index) => (
                                <div key={index} className='contact_tile_container'>
                                    <ContactTile
                                        name={item.name}
                                        phone={item.phone}
                                        subtitle={item.subtitle}
                                        incoming={item.call_type === 'incoming'}
                                        outgoing={item.call_type === 'outgoing'}
                                        dateTime={`${item.date.split('T')[0]} | ${item.date.split('T')[1]}`}
                                        duration={item.duration}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default RecentCalls
