import axios from 'axios';
import { useState, useCallback, useEffect } from 'react';
import { InteractionType, PopupRequest, InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { SCOPE, APIURL } from 'src/config';


const useAxiosWithMsal = () => {
    const { instance } = useMsal();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const axiosInstance = axios.create({
        baseURL: APIURL,
        headers: {
          'Content-Type': 'application/json',
        },
    });

    const executeAxiosCall = useCallback(async (method, endpoint, data = null, options = {}) => {
        console.log('useAxiosWithMsal executeAxiosCall started');
        let response = null;

        try {
            const silentResult = await instance.acquireTokenSilent({ scopes: [SCOPE]});
            console.log(silentResult)
            console.log(silentResult.accessToken.slice(-16));
            let headers = {
                "Authorization": `Bearer ${silentResult.accessToken}`,
                ...(data && { 'Content-Type': 'application/json' })
            };
            headers = { ...headers, ...options?.headers };
            window.localStorage.setItem('Fw4_access_Token', silentResult.accessToken);

            setIsLoading(true);

            try {
                response = await axiosInstance({
                    method: method,
                    url: endpoint,
                    headers: headers,
                    ...options,
                    ...(method === 'GET' ? { params: data } : { data: data })
                });
            } catch (error) {
                console.log(error)
                const interactiveResult = await instance.acquireTokenPopup({ scopes: [SCOPE]});
                console.log(interactiveResult)
                let headers = {
                    "Authorization": `Bearer ${interactiveResult.accessToken}`,
                    ...(data && { 'Content-Type': 'application/json' })
                };
                headers = { ...headers, ...options?.headers };
                window.localStorage.setItem('Fw4_access_Token', interactiveResult.accessToken);

                setIsLoading(true);

                try {
                    response = await axiosInstance({
                        method: method,
                        url: endpoint,
                        headers: headers,
                        ...options,
                        ...(method === 'GET' ? { params: data } : { data: data })
                    });
                } catch (error) {
                    setError(error);
                    throw error;
                } finally {
                    setIsLoading(false);
                }
            } finally {
                console.log('')
                setIsLoading(false);
                return response;
            }
        } catch (error) {
            console.log(error)
        }
    }, [instance]);
    
    return {
        isLoading,
        error,
        data,
        executeAxiosCall
    };
};

export default useAxiosWithMsal;