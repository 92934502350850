import React, { Component } from "react";
import { Form, Col, Modal, Button } from "react-bootstrap";
// import TeamItem from '../../container/Employee/TeamItem/TeamItem'
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

class TeamList extends Component {
    constructor(props) {
        super(props)
        this.toggleCheckBox = this.toggleCheckBox.bind(this)
    }
    state = {
        selected: [],
        // showModal: false
        chosenDesg: '',
        open: false
    }
    handleDelConfirmModal = () => {
        this.setState({open: !this.state.open})
    }

    toggleCheckBox(id) {
        if (!this.state.selected.includes(id)) {
            this.setState({ selected: [...this.state.selected, id] })
        } else {
            let selected = this.state.selected.filter((selId) => (selId !== id))
            this.setState({ selected })
        }
    }
    render() {
        // let empList = this.props.employeeList.map((emp)=>(<TeamItem key={emp.id} onClick={this.toggleCheckBox} selected={this.state.selected.includes(emp.id)} data={emp}/>))
        let cols = [
            {
                field: 'id',
                headerName: ' ',
                width: 50,
                hide: false,
                renderCell: (params) => {
                    // console.log('from render cell ', params.row.id)
                    return (
                        <div>
                            <i style={{ color: '#3FA7D6', margin: 10 }} onClick={() => this.toggleCheckBox(params.value)} className={this.state.selected.includes(params.value) ? "fas fa-check-square" : "far fa-square"}></i>

                        </div>
                    )
                }
            },
            {
                field: 'employee_name',
                headerName: 'NAME',
                width: 180,
                hide: false
            },
            {
                field: 'userPrincipalName',
                headerName: 'USER PRINCIPAL NAME',
                width: 250,
                hide: false
            },
            {
                field: 'emp_email',
                headerName: 'EMAIL',
                width: 180,
                hide: false
            },
            {
                field: 'emp_type',
                headerName: 'DESIGNATION',
                width: 180,
                hide: false
            }

        ]
        let dataTarget = "#" + this.props.contextName.replace(/ /g, "")
        let selectedEmp = this.state.selected.map((emp) => {
            let employeeObj = this.props.employeeList.find((empl) => (empl.id === emp))
            return <div key={employeeObj.id}><span style={{ background: '#EBF8FF', fontSize: '1rem', fontFamily: 'Lato' }} className="badge badge-pill m-1">{employeeObj.employee_name}</span></div>
        });
        let empType = this.props.desgList.map((desg, i) => (<option key={i} value={desg}>{desg}</option>))
        return (
            <div style={{ width: '60rem' }}>
                <h5>{this.props.contextName}</h5>
                {this.state.selected.length !== 0 &&
                    <div className="d-flex flex-row justify-content-start">
                        {(this.props.user.type === 'Admin' || this.props.user.type === 'Manager' || !!this.props.user.emp_is_admin) && <button onClick={(e) => { this.handleDelConfirmModal(); }} type="button" style={{ background: 'white', fontSize: '1rem', fontFamily: 'Lato' }} className="btn mx-1">
                            <i style={{ fontSize: '1rem', margin: 5 }} className="fas fa-trash"></i>REMOVE
                        </button>}
                        <Modal show={this.state.open} onHide={this.handleDelConfirmModal}>
                            <Modal.Header closeButton>
                            <Modal.Title>Delete Team Members</Modal.Title>
                            </Modal.Header>
                            <center><Modal.Body>Are you sure you want to delete these team members?</Modal.Body></center>
                            {/* <Modal.Footer> */}
                            <div className='d-flex flex-row justify-content-center m-3'>
                            <Button className='mr-1' variant="secondary" onClick={() => {this.handleDelConfirmModal()}}>
                                Back
                            </Button>
                            <Button className='mr-1' variant="primary" onClick={()=> { 
                                this.props.onRemove(this.state.selected); 
                                this.setState({ selected: [] });
                                this.handleDelConfirmModal()
                                }
                            }
                            >
                                Yes
                            </Button>
                            </div>
                            {/* </Modal.Footer> */}
                        </Modal>
                        {(this.props.user.type === 'Admin' || !!this.props.user.emp_is_admin) && <button type="button" style={{ background: 'white', fontSize: '1rem', fontFamily: 'Lato' }} className="btn mx-1" data-toggle="modal" data-target={dataTarget}>
                            <i style={{ fontSize: '1rem', margin: 5 }} className="fas fa-portrait"></i>CHANGE DESIGNATION
                        </button>}
                    </div>
                }
                {this.state.selected.length === 0 &&
                    <p className="text-muted">
                        Select one or more entries for actions. Click on an entry to edit
                    </p>}


                <DataGrid
                    density={'compact'}
                    style={{ color: '#000000' }}
                    rows={this.props.employeeList}
                    columns={cols}
                    onRowSelected={(params) => this.toggleCheckBox(params.data.id)}
                    hideFooterSelectedRowCount={true}
                    showToolbar
                    components={{ Toolbar: GridToolbar, color: '#3fadf6' }}
                    autoHeight={true}
                    pageSize={!this.props.pagesize ? 20 : this.props.pagesize}
                    onCellClick={(params) => this.toggleCheckBox(params.id)} />


                <div className="modal fade" id={this.props.contextName.replace(/ /g, "")} tabIndex="1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Employee Update</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row m-1">
                                    {selectedEmp}
                                </div>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="employee_type">
                                        <Form.Label>CHOOSE DESIGNATION</Form.Label>
                                        <Form.Control as="select" value={this.state.chosenDesg} onChange={(e) => this.setState({ chosenDesg: e.target.value })} required>
                                            <option value="">Select Type</option>
                                            {empType}
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={(e) => { this.props.onDesgChange(this.state.selected, this.state.chosenDesg); this.setState({ selected: [], chosenDesg: '' }) }} className="btn btn-primary" data-dismiss="modal">Save changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TeamList;