import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton, Divider, TextField, Grid } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import SaveButton from '../Components/SaveButton';
import CancelButton from '../Components/CancelButton';
import axios from 'axios';
import { APIURL } from '../../../config';

const BillingInformation = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [formValues, setFormValues] = useState({
        NPI: '',
        TaxonomyCode: '',
        ZIP9: '',
    });

    useEffect(() => {
        setFormValues({
            NPI: props.billingDetail.NPI,
            TaxonomyCode: props.billingDetail.TaxonomyCode,
            ZIP9: props.billingDetail.ZIP9,
        });
    }, [props.billingDetail]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormValues({
            ...formValues,
            [id]: value,
        });
    };

    const handleCancel = () => {
        setFormValues({
            NPI: props.billingDetail.NPI,
            TaxonomyCode: props.billingDetail.TaxonomyCode,
            ZIP9: props.billingDetail.ZIP9,
        });
        setEditMode(false);
    };

    const handleSave = async () => {
        try {
            await axios.put(APIURL + `/company/v2/billinginfo?company_id=${props.comp_id}`, {
                NPI: formValues.NPI,
                TaxonomyCode: formValues.TaxonomyCode,
                ZIP9: formValues.ZIP9,
            });
            setEditMode(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h5">Billing Information</Typography>
                {editMode ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <CancelButton onClick={handleCancel} />
                        <SaveButton onClick={handleSave} />
                    </Box>
                ) : (
                    <IconButton onClick={() => setEditMode(true)}>
                        <Edit />
                    </IconButton>
                )}
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            NPI
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        {editMode ? (
                            <TextField
                                id="NPI"
                                value={formValues.NPI || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                sx={{ l: -14 }}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.NPI || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Taxonomy Code
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        {editMode ? (
                            <TextField
                                id="TaxonomyCode"
                                value={formValues.TaxonomyCode || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                sx={{ l: -14 }}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.TaxonomyCode || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            ZIP9
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        {editMode ? (
                            <TextField
                                id="ZIP9"
                                value={formValues.ZIP9 || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                sx={{ l: -14 }}
                                inputProps={{ maxLength: 10 }}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.ZIP9 || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default BillingInformation;
