import React, { Component } from 'react';
import { Form, Col, Button, Alert } from 'react-bootstrap';
import HandyCard from '../../HandyCard/HandyCard';
import axios from 'axios';
import { APIURL } from '../../../config'
import "./Contacts.css"


class ContactsPage extends Component {
    state = {
        contacts: [],
        contactsList: [],
        deletedContact: null,
        timer: null,
        conid: null
    }

    async componentDidMount() {
        let projCont = await axios.get(APIURL + "/project/projCont", { params: { proj_id: this.props.projId } })
        let contacts = await axios.get(APIURL + "/contacts", { params: { comp_id: this.props.comp_id } })
        this.setState({ contacts: projCont.data, contactsList: contacts.data.filter((con) => (!!con.con_is_active)) })
    }

    onConSelect = (e) => {
        this.setState({ conid: e.target.value })
    }

    onAddContact = async () => {
        //Check if exist in States
        if (!this.state.conid) {
            return
        }
        let conExist = this.state.contacts.find((con) => con.con_id === +this.state.conid)
        if (!conExist) {
            let msg = await axios.put(APIURL + '/project/updateProjContact', { projId: this.props.projId, contId: this.state.conid })
            console.log(msg.data)
            if (msg.data.code === 200) {
                //find contact in list 
                let addContact = this.state.contactsList.find((con) => ((con.con_id === +this.state.conid) || (con.id === +this.state.conid)))
                console.log(addContact)
                if (!!addContact.id) {
                    addContact = { ...addContact, con_id: addContact.id, con_first_name: addContact.contact_name.split(' ')[0], con_last_name: addContact.contact_name.split(' ')[1] }
                    // addContact = {con_phone: addContact.con_phone,con_first_name: 'Reload',con_last_name: 'Reload',con_id: addContact.id, con_designation: 'Reload'}
                }
                // push in state
                this.setState({ conid: null, contacts: [...this.state.contacts, addContact] })
            }
        } else {
            //Alert User
            this.setState({ conid: null })
            return
        }
    }

    onDelContact = async (id) => {
        // console.log(id)
        if (this.state.timer) {
            alert("Another delete is in process, wait for the undo dailogue to disappear")
        } else {
            let oldContactList = [...this.state.contacts]
            oldContactList = oldContactList.filter(con => con.con_id !== id)
            this.setState({
                deletedContact: this.state.contacts.find(con => con.con_id === id),
                timer: setTimeout(() => {
                    this.deleteRequest(this.state.deletedContact.con_id)
                    clearTimeout(this.state.timer)
                    this.setState({
                        timer: null,
                        deletedContact: null
                    })
                }, 5000),
                contacts: oldContactList
            })
        }


    }

    deleteRequest = (id) => {
        axios.delete(APIURL + '/project/updateProjContact', { data: { projId: this.props.projId, contId: id } })
            .then(res => {
                if (res.status === 200) {
                    alert("Contact Deleted")
                } else {
                    alert("Something went wrong.")
                }
            })
            .catch(error => console.log(error))
    }

    showAlert = () => {
        if (this.state.timer) {
            return (
                <Alert variant="info" onClose={() => this.onDismissAlert()} style={{ marginTop: "10px" }} dismissible>
                    Contact Deleted. <span className="undo" onClick={() => this.undoDelete()}>Undo?</span>
                </Alert>
            )
        }
    }

    onDismissAlert = () => {
        this.deleteRequest(this.state.deletedContact.con_id)
        clearTimeout(this.state.timer)
        this.setState({
            timer: null,
            deletedContact: null
        })
    }

    undoDelete = () => {
        let oldContactList = [...this.state.contacts]
        oldContactList.push(this.state.deletedContact)
        clearTimeout(this.state.timer)
        this.setState({
            contacts: oldContactList,
            deletedContact: null,
            timer: null
        })
    }

    render() {
        let contact_card = this.state.contacts.map((conData) => <HandyCard key={conData.con_id} onDel={this.onDelContact} contact data={conData} />)
        let contDropDowns = this.state.contactsList.map((con) => {
            if (!con.id) {
                return <option key={con.con_id} value={con.con_id} >{con.con_first_name} {con.con_last_name}</option>
            } else {
                return <option key={con.id} value={con.id} >{con.contact_name}</option>
            }

        })
        return (
            <div className="ConPage">
                <div className="ConTopPanel d-flex flex-row">
                    <Form.Group as={Col} controlId="pContact">
                        <Form.Control value={!this.state.conid ? "" : this.state.conid} as="select" onChange={(e) => { this.onConSelect(e) }} className='select_contact'>
                            <option value="">Select Contact</option>
                            {/* <option value="No Contact">No Contact</option> */}
                            {contDropDowns}
                        </Form.Control>
                    </Form.Group>
                    <Button variant="primary" onClick={() => this.onAddContact()} style={{ color: 'white', border: "solid 1px #95D0EB", height: '2.5rem' }}>Add Contact</Button>
                </div>
                <div>
                    <h5>Contacts</h5>
                    <span>{this.props.project_title}</span>
                    {this.showAlert()}
                </div>
                <div className='contact-list'>
                    {contact_card.length !== 0 && contact_card}
                    {contact_card.length === 0 && <div className='d-flex flex-column justify-content-center align-items-center mt-4' style={{ height: '300px', margin: 5 }}>
                        <img src='./line.png' alt='conttAB' style={{ width: '10rem', height: '10rem' }} />
                        <h5 style={{ fontFamily: 'Lato', fontSize: 18, marginTop: 38, marginBottom: 28 }} >No contacts found. </h5>
                        <p style={{ fontFamily: 'Lato', fontSize: 14 }} className='text-muted text-center w-50'>We could not find any contacts in this projects. Please add contacts to your project to view others involved in the projects</p>
                    </div>}
                </div>

            </div>
        )
    }
};

export default ContactsPage;