import React, { useState, useEffect } from 'react';
import { JsonForms } from '@jsonforms/react';
import schema from './schema.json';
import uischema from './uischema.json';
import { materialRenderers, materialCells } from '@jsonforms/material-renderers';
import MultiSelect from './renderers/MultiSelect';
import MultiSelectTester from './renderers/MultiSelectTester';
import { APIURL } from '../../../config';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './Settings.css'

const Settings = (props) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [initData, setInitData] = useState([]);
  const [states, setStates] = useState([]);
  const [modules, setModules] = useState([]);
  const [primary, setPrimary] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);

  const renderers = [
    ...materialRenderers,
    { tester: MultiSelectTester, renderer: MultiSelect },
  ];

  const getData = async () => {
    let companyDets = await axios.get(APIURL+"/company", {params:{comp_id: props.comp_id}})
    companyDets = companyDets.data[0];

    let AltStatesdata = await axios.get(APIURL+"/config/settings", {params: {comp_id: props.comp_id, feature_id: 99}})
    AltStatesdata.data = AltStatesdata.data.map((s)=> {
      return{...s}
    })
             
    let initStates = AltStatesdata.data.map((state) => {
      return state.feature_value;
    })

    let modulesDefaults = await axios.get(APIURL+"/config/defaults", {params:{feature_type: "functionality"}})
    modulesDefaults.data = modulesDefaults.data.map((s)=> {
        return  {...s, EntityValue: s.feature_value, 
            isSelected:false, 
            ddID:s.feature_id}
    })

    let altmoduleData = await axios.get(APIURL+"/config/settings", {params:{comp_id: props.comp_id, feature_value: "functionality"}})
      altmoduleData.data = altmoduleData.data.map((s)=> {
          return {...s}
      })

    let initModules = altmoduleData.data.map((module) => {
      let featureVal =  modulesDefaults.data.find(element => element.feature_id === module.feature_id).feature_value
      return featureVal;
    })

    let primaryDefaults = await axios.get(APIURL+"/config/defaults", {params:{feature_type: "primaryBusinessType"}});
    let initPrimary = await axios.get(APIURL+"/config/settings", {params:{comp_id: props.comp_id, feature_value: "primaryBusinessType"}})

    console.log(initPrimary);


    const newVals = {};

    if(companyDets.subscriptionStartDate) { newVals.subscriptionStartDate = companyDets.subscriptionStartDate };
    if(companyDets.subscriptionEndDate) { newVals.subscriptionEndDate = companyDets.subscriptionEndDate };
    if(companyDets.trialStartDate) { newVals.trialStartDate = companyDets.trialStartDate };
    if(companyDets.trialEndDate) { newVals.trialEndDate = companyDets.trialEndDate };

    newVals["state-multi"] = initStates;
    newVals["module-multi"] = initModules;
    newVals["primary-business"] = initPrimary.data[0].feature_id; 

    delete companyDets.subscriptionStartDate; delete companyDets.subscriptionEndDate; delete companyDets.trialStartDate; delete companyDets.trialEndDate;
    setInitData({...companyDets, ...newVals})
    setStates(AltStatesdata.data);
    setModules(altmoduleData.data);
    setData({ ...companyDets, ...newVals });
  }

  useEffect(() => {
    getData().then(() => {setIsLoading(false)});
  }, [])

  const onSubmit = async () => {
    if(JSON.stringify(initData) === JSON.stringify(data) ) {
      return;
    }

    let results = [];

    let result = await axios.put(APIURL+'/company', { ...data, com_id: props.comp_id});
    results.push(result);

    if(data["state-multi"] !== initData["state-multi"]) {
      for(const state of states) {
        let data = { settings_id: state.settings_id }
        let result = await axios.delete(APIURL+`/config/settings`, {data});
        results.push(result);
      }
      for(const state of data["state-multi"]) {
        let result = await axios.post(APIURL+`/config/settings`, { feature_id: 99, state_id: state.const, company_id: props.comp_id, feature_value: state.title} )
        results.push(result);
      }
    }

    if(data["module-multi"] !== initData["module-multi"]) {
      for(const module of modules) {
        let data = { settings_id: module.settings_id };
        let result = await axios.delete(APIURL+`/config/settings`, {data});
        results.push(result);
      }
      for(const module of data["module-multi"]) {
        let result = await axios.post(APIURL+`/config/settings`, { feature_id: module.const, company_id: props.comp_id, feature_value: "functionality"})
        results.push(result);
      }
    }

    if(data["primary-business"]) {
      let result = await axios.post(APIURL+`/config/settings`, { feature_id: data["primary-business"], company_id: props.comp_id, feature_value: "primaryBusinessType"});
      results.push(result);
    }

    if(results.filter(result => result.data.code !== 200).length === 0) {
      setUpdated(true);
    } else {
      setError(true);
    }
    getData();
  }
  
  return (
    <div style={{maxWidth: '500px'}}>
      {!isLoading && <div>
        <JsonForms
          schema={schema}
          uischema={uischema}
          data={data}
          renderers={renderers}
          cells={materialCells}
          onChange={({ data, _errors }) => {setData(data); console.log(data);}}
        />
        <div className='sub-submit-container'>
          <Button onClick={onSubmit}>Submit</Button>
        </div>
      </div>}
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={updated} autoHideDuration={6000} onClose={()=>{setUpdated(false)}}>
        <MuiAlert onClose={()=>{setUpdated(false)}} severity="success">
           SUCCESSFULLY UPDATED SETTINGS
        </MuiAlert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={error} autoHideDuration={6000} onClose={()=>{setError(false)}}>
          <MuiAlert onClose={()=>{setError(false)}} severity="error">
            ERROR OCCURRED WHILE UPDATING SETTINGS
          </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default Settings;