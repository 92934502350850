import React from 'react';
import moment from 'moment'
import axios from 'axios';
import { APIURL } from '../../config'

class NotificationsCard extends React.Component {

    state = {
        readone: this.props.list.isRead,
        readOneText: this.props.list.isRead?"Mark as Unread":"Mark as Read",
        notifDetails: null,
        // loading: false
    }

    async componentDidMount() {
        let notifDetails = await axios.get(APIURL+`/notifications/${this.props.list.notif_id}`)
        this.setState({notifDetails: notifDetails.data || null})
        if (this.props.list.isRead) {
            this.setState({ readOne: true, readOneText: "Mark as Unread" })
        }
        else {
            this.setState({ readone: false, readOneText: "Mark as Read" })
        }
    }

    onReadOne = async () => {
            let body = { isRead: !this.props.list.isRead ? 'true' : 'false', status: !this.props.list.isRead?'READ':'UNREAD'}
            let res = await axios.put(`${APIURL}/notifications/${this.props.list.notif_id}`, body)
            if(res.data.code === 200){
                this.props.updateList(this.props.list.notif_id,body.isRead)
                this.setState({ readOne: body.isRead, readOneText: !body.isRead?"Mark as Read":"Mark as Unread"})        
            }else{
                console.log(res.data)
            }
    }


    onUpdateOne = async (typeAction) => {
        let body = { status: typeAction === 'ARCH'?'ARCH':'DEL'}
        let res = await axios.put(`${APIURL}/notifications/${this.props.list.notif_id}`, body)
        if(res.data.code === 200){
            this.props.updateListforStatus(this.props.list.notif_id,body.status)
            // this.setState({ readOne: body.isRead, readOneText: !body.isRead?"Mark as Read":"Mark as Unread"})        
        }else{
            console.log(res.data)
        }
}

    render() {
        let { list, readOne, readOneText } = this.props
        return (
            <div id={`evv-${list.notif_id}`} className='d-flex flex-column' style={{width: '450px'}}>
                {this.props.showHeader && <p style={{ margin: 15, marginBottom: -10, fontWeight: 450, fontFamily: 'Lato', fontSize: '15px', color: '#AAAAAA', marginBottom: '10px' }}>{moment(list.notif_date).format('MMMM Do YYYY')}</p>}
                <div style={{ borderBottom: '1px solid #D6D6D6' }} className='d-flex flex-row justify-content-between'>
                    <div className='d-flex flex-row'>
                        <div className='d-flex flex-column' style={{ margin: 20 }}>
                            {/* {!!readAll && <span className="fas fa-circle" style={{ color: '#bbb'}}></span>} */}
                            { !this.state.readOne && <span className="fas fa-circle" style={{ color: 'rgb(63, 167, 214)' /*if unread color : blue*/ }}></span>}
                            { !!this.state.readOne && <span className="fas fa-circle" style={{ color: '#bbb' /*if read color : grey*/ }}></span>}
                        </div>
                        <div className='d-flex flex-column justify-content-center'>
                            <div className='d-flex flex-row'>
                                <p style={{ margin: 10, fontWeight: 900, fontFamily: 'Lato' }}>{list.notif_text} </p>
                                <p style={{ marginTop: 14, fontWeight: 450, fontFamily: 'Lato', fontSize: '12px', color: '#AAAAAA' }}> at {moment(list.notif_date).format('hh:mm')}</p>
                            </div>
                            {list.notif_type === 'task' && <div className='d-flex flex-row justify-content-left'>
                                {/* <p style={{ margin: 10, marginTop:-10, fontFamily: 'Lato' }}> 
                                <strong style={{color: '#3fa7d6'}}>{this.state.notifDetails && this.state.notifDetails.taskDetails[0].tas_title} </strong> is assigned by {this.state.notifDetails && this.state.notifDetails.user && `${this.state.notifDetails.user.emp_id === this.props.user.emp_id?'you':this.state.notifDetails.user.emp_first_name} ${this.state.notifDetails.user.emp_id === this.props.user.emp_id?'':this.state.notifDetails.user.emp_last_name}`}</p> */}
                                                                <p style={{ margin: 10, marginTop:-10, fontFamily: 'Lato' }}> 
                                <strong style={{color: '#3fa7d6'}}>{this.state.notifDetails && this.state.notifDetails.taskDetails[0].tas_title} </strong> is assigned by {this.state.notifDetails && this.state.notifDetails.user && `${this.state.notifDetails.user.emp_first_name} ${this.state.notifDetails.user.emp_last_name}`}</p>
                            </div>}
                            {list.notif_type === 'timesheet' && <div className='d-flex flex-row justify-content-left'>
                                {/* <p style={{ margin: 10, marginTop:-10, fontFamily: 'Lato' }}> 
                                <strong style={{color: '#3fa7d6'}}>{this.state.notifDetails && this.state.notifDetails.taskDetails[0].tas_title} </strong> is assigned by {this.state.notifDetails && this.state.notifDetails.user && `${this.state.notifDetails.user.emp_id === this.props.user.emp_id?'you':this.state.notifDetails.user.emp_first_name} ${this.state.notifDetails.user.emp_id === this.props.user.emp_id?'':this.state.notifDetails.user.emp_last_name}`}</p> */}
                                                                <p style={{ margin: 10, marginTop:-10, fontFamily: 'Lato' }}> 
                                New Timesheet was submitted by <strong style={{color: '#3fa7d6'}}> {this.state.notifDetails && this.state.notifDetails.user && `${this.state.notifDetails.user.emp_first_name} ${this.state.notifDetails.user.emp_last_name}`}</strong></p>
                            </div>}
                            {list.notif_type === 'comment' && <div className='d-flex flex-row justify-content-left'>
                                {/* <p style={{ margin: 10, marginTop:-10, fontFamily: 'Lato' }}> 
                                <strong style={{color: '#3fa7d6'}}>{this.state.notifDetails && this.state.notifDetails.taskDetails[0].tas_title} </strong> is assigned by {this.state.notifDetails && this.state.notifDetails.user && `${this.state.notifDetails.user.emp_id === this.props.user.emp_id?'you':this.state.notifDetails.user.emp_first_name} ${this.state.notifDetails.user.emp_id === this.props.user.emp_id?'':this.state.notifDetails.user.emp_last_name}`}</p> */}
                                                                <p style={{ margin: 10, marginTop:-10, fontFamily: 'Lato' }}> 
                                 {this.state.notifDetails && this.state.notifDetails.user && `${this.state.notifDetails.user.emp_first_name} ${this.state.notifDetails.user.emp_last_name}`} commented in <strong style={{color: '#3fa7d6'}}> {this.state.notifDetails && this.state.notifDetails.projectDetails[0].pro_title} </strong> </p>
                            </div>}
                            {list.notif_type === 'project' && <div className='d-flex flex-row justify-content-left'>
                                <p style={{ margin: 10, marginTop:-10, fontFamily: 'Lato' }}>
                                <strong style={{color: '#3fa7d6'}}>{this.state.notifDetails && this.state.notifDetails.projectDetails[0].pro_title} </strong> is created by {this.state.notifDetails && this.state.notifDetails.user && `${this.state.notifDetails.user.emp_first_name} ${this.state.notifDetails.user.emp_last_name}`}</p>
                            </div>}
                            {!['task','project','comment','timesheet'].includes(list.notif_type) &&  <div className='d-flex flex-row justify-content-left'>
                                <p style={{ margin: 10, marginTop:-10, fontFamily: 'Lato' }}>Notification Type : {list.notif_type}</p>
                            </div>}
                        </div>
                    </div>
                    <div className='d-flex flex-column justify-content-right' style={{ marginTop: ".5rem", marginLeft: "auto", marginRight: 20 }}>
                        <div className='d-flex flex-row'>
                            <div>
                                {/* eslint-disable-next-line */}  
                                <a onClick={()=> this.props.viewOneNotif(list.notif_id)} style={{ cursor:'pointer', marginRight: 20, marginTop: 11, fontWeight: 450, fontFamily: 'Lato', fontSize: '15px', color: '#3FA7D6', textDecoration: 'none' }}>View</a>
                            </div>
                            <div className="dropdown show">
                                <a className="dropdown" href=" " data-toggle="dropdown" aria-haspopup="true" style={{ color: 'rgba(0, 0, 0, 1)' }} >
                                    <i className="fas fa-ellipsis-v" ></i>
                                </a>
                                <div style={{margin:10, marginRight:30}} className="dropdown-menu" aria-labelledby="actions">
                                    {/* eslint-disable-next-line */}
                                    {/* <a className="dropdown-item" onClick={()=> this.props.viewOneNotif(list.notif_id)}>View</a> */}
                                    {/* eslint-disable-next-line */}
                                    <a onClick={this.onReadOne} className="dropdown-item">{!!readOne ? readOneText : this.state.readOneText}</a>
                                    {/* eslint-disable-next-line */}
                                    <a onClick={()=> this.onUpdateOne('ARCH')} className="dropdown-item">Archive</a>
                                    {/* eslint-disable-next-line */}
                                    <a onClick={()=> this.onUpdateOne('DEL')} className="dropdown-item">Delete</a>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotificationsCard;