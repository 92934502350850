import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import SignatureCanvas from "react-signature-canvas";
import {
  Button,
  Box,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

export default function SignatureModal(props) {
  var Buffer = require('buffer/').Buffer;
  const padRef = useRef(null);
  const location = props.task.locInfo ? (props.task.locInfo.loc_addr_line1 ? (props.task.locInfo.loc_addr_line1 + ", " ): "") + (props.task.locInfo.loc_addr_line2 ? (props.task.locInfo.loc_addr_line2 + ", " ): "") + (props.task.locInfo.loc_addr_line2 ? (props.task.locInfo.loc_addr_line2 + ", " ): "") + (props.task.locInfo.loc_city ? (props.task.locInfo.loc_city + ", " ): "") + (props.task.locInfo.loc_state ? (props.task.locInfo.loc_state + ", " ): "") + (props.task.locInfo.loc_zip ? (props.task.locInfo.loc_zip): "") : null;

  useEffect(() => {
    console.log(props);
  }, []);

  const handleSave = async () => {
    if (padRef.current.isEmpty()) {
      alert("No signature found. Please provide a signature to save.");
      return;
    }
    const dataURL = padRef.current.getTrimmedCanvas().toDataURL();
    const [arrayBuffer, blob] = dataURLToBlob(dataURL);
    console.log(blob);
    const formData = new FormData();
    formData.append("signature", blob, "signature.png");
    formData.append("emp_id", props.emp_id);
    formData.append("project_id", props.task.tas_pro_id);
    formData.append("task_id", props.task.tas_id);
    formData.append("customer_id", props.task.cusInfo[0].cus_id);
    let resp = await axios
      .post(APIURL + "/signature", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async (response) => {
        console.log(response);
        props.toggle();
        props.captured();
      })
      .catch((err) => {
        console.log(err);
      });
    // .then(
    //   (response) => {
    //     const blob = new Blob([response.data], { type: 'image/png' });
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = 'signature.png';
    //     document.body.appendChild(link);
    //     link.click();
    // });
    // console.log(resp.data.resp)
    // const blobRec = new Blob([resp.data.resp], {
    //   type: 'image/png'
    // });
    // console.log(blobRec)
    // const url = URL.createObjectURL(blobRec);

    // // Create a link element with the URL as the `href` attribute and the `download` attribute set
    // const link = document.createElement("a");
    // link.href = url;
    // link.download = "signature.png";

    // // Click the link to initiate the download
    // document.body.appendChild(link);
    // link.click();

    // // Clean up the URL object
    // URL.revokeObjectURL(url);
  };

  const dataURLToBlob = (dataURL) => {
    const byteString = Buffer.from(dataURL.split(",")[1], "base64");
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString[i];
    }
    return [byteString, new Blob([arrayBuffer], { type: mimeString })];
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick') {
        props.toggle();
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      PaperProps={{
        style: {
          margin: "auto",
          width: "550px",
          height: "350px",
        },
      }}
      fullWidth
      // maxWidth="lg"
    >
      <DialogTitle>
        <Typography variant="h5" align="center" style={{fontWeight:"bold"}}>
          Sign Here
        </Typography>
        <IconButton
          onClick={() => props.toggle()}
          aria-label="close"
          color="inherit"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingTop: "0px",
          height: "100%",
          width: "100%",
          alignContent: "center",
          align: "center",
        }}
      >
        <SignatureCanvas
          ref={padRef}
          style={{ position: "relative" }}
          canvasProps={{
            style: {
              height: "100%",
              width: "95%",
              border: "1px solid black",
              padding: 0,
              margin: "auto",
              display: "block",
            },
            className: "sigPad",
          }}
        />
      </DialogContent>
      <Typography variant="h7" align="center">Signed by / for {props.task.cusInfo[0].cus_name}</Typography>
      <Typography variant="h7" align="center">{location ? `at ${location}` : ``}</Typography>
      <Typography variant="h7" align="center" paddingBottom={2}>on {moment().format("MM/DD/YYYY, hh:mm a")}</Typography>
      <Button
        variant="contained"
        onClick={() => padRef.current.clear()}
        style={{
          backgroundColor: "grey",
        }}
      >
        Clear
      </Button>
      <Box style={{ width: "30px" }}></Box>
      <Button
        variant="contained"
        onClick={handleSave}
      >
        Save
      </Button>
    </Dialog>
  );
}
