import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, Divider, TextField, MenuItem, Select, Autocomplete } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Edit from '@mui/icons-material/Edit';
import SaveButton from '../Components/SaveButton';
import CancelButton from '../Components/CancelButton';
import Grid from '@mui/material/Unstable_Grid2';
import moment from 'moment';
import axios from 'axios';
import { APIURL } from '../../../config';
import ccs from 'countrycitystatejson';
import normalize  from 'us-states-normalize'
import dd from "../../../manifestdd.json";

const PersonalInformation = (props) => {
    const [editMode, setEditMode] = useState(false);
    const [formValues, setFormValues] = useState({ ...props.employee });
    const [errors, setErrors] = useState({
        ssn: false,
        emp_phone_number: false,
        sec_email: false,
    });

    useEffect(() => {
        setFormValues({ ...props.employee, dob: moment(props.employee.dob).utc().format("YYYY-MM-DD") });
    }, [props.employee]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });

        errors[name] = false;
    };

    const handleDateChange = (date) => {
        setFormValues({
            ...formValues,
            dob: date,
        });
    };

    const handleCancel = () => {
        setFormValues({ ...props.employee, dob: moment(props.employee.dob).utc().format("YYYY-MM-DD") });
        setEditMode(false);
    };

    const handleSave = async () => {
        let errors = {
            ssn: formValues.ssn?.split('').filter(c => c !== ' ').join('').length !== 9,
            emp_phone_number: !formValues.emp_phone_number.replace(/[\s()-]/g, '').match(/^(\+?1)?(\d{10})$/),
            sec_email: !formValues.sec_email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/),
        }
        setErrors(errors);

        if(errors.ssn || errors.emp_phone_number || errors.sec_email) return;
        
        try {
            let data  = {
                ...formValues,
                emp_id: props.user_id,
                email: formValues.empEmail,
                sec_email: formValues.sec_email,
                first_name: formValues.emp_first_name,
                last_name: formValues.emp_last_name,
                address1: formValues.emp_addr_line1,
                address2: formValues.emp_addr_line2,
                city: formValues.emp_city,
                state: formValues.emp_state,                              
                zip: formValues.emp_zip, 
                phoneList: formValues.emp_phone_number,   
                join_date: formValues.emp_join_date,
                status: formValues.emp_status,
                employee_type: formValues.emp_type,  
                employee_level: formValues.emp_level,        
                vacation_days: formValues.vacationDays,    
                personal_days_off:formValues.personal_days_off,
                dob: !!formValues.dob?moment(formValues.dob).format("YYYY-MM-DD"):formValues.dob,
                vacationDays: formValues.vacationDays,
                terminationDate: !!formValues.terminationDate?moment(formValues.terminationDate).format("YYYY-MM-DD"):formValues.terminationDate,
                terminationReason: formValues.terminationReason,
                company_id:props.comp_id ,            
                emp_manager_id: formValues.emp_manager_id,
                telemedRoom: formValues.emp_doxylink,
                ssn: formValues.ssn,
                emp_gender: formValues.emp_gender
            };   
            let response = await axios.put(APIURL + '/employees', data);

            console.log(response)
            props.setEmployee({ ...props.employee, ...formValues });
            setEditMode(false);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Typography variant="h5">Personal Information</Typography>
                {editMode ? (
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <CancelButton onClick={handleCancel} />
                        <SaveButton onClick={handleSave} />
                    </Box>
                ) : (
                    <IconButton onClick={() => setEditMode(true)}>
                        <Edit />
                    </IconButton>
                )}
            </Box>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            First Name
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                name="emp_first_name"
                                value={formValues.emp_first_name || ''}
                                onChange={handleInputChange}
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                size="small"
                                sx={{ left: -14 }}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_first_name || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Last Name
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                name="emp_last_name"
                                value={formValues.emp_last_name || ''}
                                onChange={handleInputChange}
                                fullWidth
                                inputProps={{ maxLength: 50 }}
                                size="small"
                                sx={{ left: -14 }}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_last_name || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Address
                        </Typography>
                    </Grid>
                    <Grid item xs={10}>
                        {editMode ? (
                            <Box sx={{ display: 'flex', gap: 2, mr: "14px", ml: "-14px" }}>
                                <Box sx={{ flex: "8 1 0" }}>
                                    <TextField
                                        name="emp_addr_line1"
                                        value={formValues.emp_addr_line1 || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        placeholder="Address Line 1"
                                        size="small"
                                    />
                                </Box>
                                <Box sx={{ flex: "8 1 0" }}>
                                    <TextField
                                        name="emp_addr_line2"
                                        value={formValues.emp_addr_line2 || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 100 }}
                                        placeholder="Address Line 2"
                                        size="small"
                                    />
                                </Box>
                                <Box sx={{ flex: "5 1 0" }}>
                                    <Autocomplete
                                        name="emp_city"
                                        value={formValues.emp_city || ''} 
                                        onChange={(e, value) => handleInputChange({ target: { name: "emp_city", value: value } }) }
                                        fullWidth
                                        size="small"
                                        options={(formValues.emp_state ? ccs.getCities('US', normalize(formValues.emp_state, { returnType: 'name' })) : [])}
                                        renderInput={(params) => <TextField {...params} placeholder="City" />}
                                    />

                                </Box>
                                <Box sx={{ flex: "3 1 0" }}>
                                    <Autocomplete
                                        name="emp_state"
                                        value={formValues.emp_state || ''}
                                        onChange={(e, value) => handleInputChange({ target: { name: "emp_state", value: value } }) }
                                        fullWidth
                                        size="small"
                                        options={ccs.getStatesByShort('US').filter( state => { return state.length > 2 }).map((state) => normalize(state)) }
                                        renderInput={(params) => <TextField {...params} placeholder="State" />}
                                        disableClearable
                                    />
                                </Box>
                                <Box sx={{ flex: "4 1 0" }}>
                                    <TextField
                                        name="emp_zip"
                                        value={formValues.emp_zip || ''}
                                        onChange={handleInputChange}
                                        fullWidth
                                        inputProps={{ maxLength: 10 }}
                                        placeholder="ZIP Code"
                                        size="small"
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>
                                {formValues.emp_addr_line1 || ''} {formValues.emp_addr_line2 || ''} {formValues.emp_city || ''},{' '}
                                {formValues.emp_state || ''} {formValues.emp_zip || ''}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Email
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                name="sec_email"
                                value={formValues.sec_email || ''}
                                onChange={handleInputChange}
                                fullWidth
                                type="email"
                                inputProps={{ maxLength: 100 }}
                                size="small"
                                sx={{ left: -14 }}
                                error={errors.sec_email}
                                helperText={errors.sec_email ? 'Invalid Email' : ''}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.sec_email || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Phone
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                name="emp_phone_number"
                                value={formValues.emp_phone_number || ''}
                                onChange={handleInputChange}
                                fullWidth
                                type="tel"
                                inputProps={{ maxLength: 15, pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}" }}
                                size="small"
                                sx={{ left: -14 }}
                                error={errors.emp_phone_number}
                                helperText={errors.emp_phone_number ? 'Invalid Phone Number' : ''}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_phone_number || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Date of Birth
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    value={formValues.dob}
                                    onChange={handleDateChange}
                                    renderInput={(params) => <TextField {...params} fullWidth size="small" sx={{ left: -14 }} />}
                                    inputFormat="MM/DD/YYYY"
                                    disableFuture
                                />
                            </LocalizationProvider>
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>
                                {moment(formValues.dob).isValid() ? moment(formValues.dob).format('MM/DD/YYYY') : ''}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Gender
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <Select
                                name="emp_gender"
                                value={formValues.emp_gender || ''}
                                onChange={handleInputChange}
                                fullWidth
                                size="small"
                                sx={{ left: -14 }}
                            >
                                {dd.generic.gender.map((type) => (
                                    <MenuItem key={type.value} value={type.value}>
                                        {type.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_gender || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
                <Divider sx={{ my: 1 }} />
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Social Security Number
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                name="ssn"
                                value={formValues.ssn || ''}
                                onChange={handleInputChange}
                                fullWidth
                                inputProps={{ maxLength: 9, pattern: "[0-9]{3}-[0-9]{2}-[0-9]{4}" }}
                                size="small"
                                sx={{ left: -14 }}
                                error={errors.ssn}
                                helperText={errors.ssn ? 'Invalid SSN' : ''}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.ssn || ''}</Typography>
                        )}
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body1" sx={{ color: 'gray', my: 1 }}>
                            Video Calling Link
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        {editMode ? (
                            <TextField
                                name="emp_doxylink"
                                value={formValues.emp_doxylink || ''}
                                onChange={handleInputChange}
                                fullWidth
                                inputProps={{ maxLength: 200 }}
                                size="small"
                                sx={{ left: -14 }}
                            />
                        ) : (
                            <Typography variant="body1" sx={{ my: 1 }}>{formValues.emp_doxylink || ''}</Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default PersonalInformation;
