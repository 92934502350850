import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { he } from 'date-fns/locale';

const DocumentViewerModal = (props) => {
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        if (props.fileUrl && props.fileExt) {
            setDocs([{ uri: props.fileUrl, fileType: props.fileExt }]);
            console.log([{ uri: props.fileUrl, fileType: props.fileExt }]);
        }
    }, [props.fileUrl, props.fileExt]);

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="document-viewer-title"
            aria-describedby="document-viewer-description"
        >
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 800, height: '90%', bgcolor: 'background.paper', boxShadow: 24, overflow: 'scroll', p: 1 }}>
                <DocViewer
                    key={props.fileUrl}
                    pluginRenderers={DocViewerRenderers}
                    documents={docs}
                    style={{ height: '100%', width: '100%' }}
                />
            </Box>
        </Modal>
    );
}

export default DocumentViewerModal;
    