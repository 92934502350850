import React, { useState, useEffect } from "react";
import axios from 'axios';
import {APIURL} from '../../config';
import moment from "moment";
import './EmployeeHeader.css';

export default function EmployeeHeader(props) {
    const [employee, setEmployee] = useState();
    const [manager, setManager] = useState();

    useEffect(() => {
    }, [])

    return (
        <div className="employee-header-container">
            {true &&
                <div className="employee-header">
                    <div className="name-role-container">
                        <h1 className="emp-name m-0">{props.employee.emp_first_name + " " + props.employee.emp_last_name}</h1>
                        <p className="lead">{props.employee.emp_type}</p>
                    </div>
                    
                    <div className="employee-details-container d-flex justify-content-between w-50">
                        <p><span className="font-weight-bold">Login: </span>{props.employee.empEmail}</p>
                        <p><span className="font-weight-bold">Date of Hire: </span>{moment(props.employee.emp_join_date).format("L")}</p>
                    </div>
                </div>  
            }
        </div>
    )
}