import React,{Component} from 'react';
// import HeaderInfo from './HeaderInfo'
import EmpDoubleBar from './EmpDoubleBar'
import axios from 'axios';
import {APIURL} from '../../config'

  
class EmpTask extends Component{
    state = {
        empBarChart : [],
        pageNum: 10 ,
        start: 0, 
        isLoading: true
    }

    traverseGraph = async(direction, size) => {
        let start = +this.state.start 
        if(direction === 0 && start !== 0){
            console.log('to the left')
            this.setState({start: start - size})
        }else if(direction === 1 && ((start + size) < this.state.empBarChart.length)){
            console.log('to the right')
            this.setState({start: start + size})
        }
        
    }

    changeSize = async(size) => {
        this.setState({start: 0 , pageNum: parseInt(size)})
    }

    async componentDidMount(){
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods" : "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        let res = await axios.get(APIURL+'/home/empDashBoard',{params: {comp_id: this.props.comp_id}})
        this.setState({empBarChart: res.data.EmpBarChart.filter((emp)=> emp.completed !== 0 && emp.ongoing !== 0), isLoading: false})
    }


    render(){
        return(
            <div className='w-100'>
                
                <div className="d-flex flex-column justify-content-between">
                <EmpDoubleBar isLoading={this.state.isLoading} traverseGraph={this.traverseGraph} changeSize={this.changeSize} pageNum={this.state.pageNum} compdata={this.state.empBarChart.map((emp)=> (emp.completed)).slice(this.state.start,this.state.start + this.state.pageNum)} labels={this.state.empBarChart.map((emp)=> (emp.employee_name)).slice(this.state.start,this.state.start + this.state.pageNum)} ondata={this.state.empBarChart.map((emp)=>(emp.ongoing)).slice(this.state.start,this.state.start + this.state.pageNum)}/>
                </div>
                
            </div>
        )
    }
}

export default EmpTask