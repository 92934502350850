import react, { useEffect, useState } from 'react';
import { Box, Tabs, Tab, Divider, Typography, CircularProgress, Paper } from '@mui/material';
import Section from './Components/Section';
import SubSection from './Components/SubSection';
import States from './Subscription/States';
import ModulesActivated from './Subscription/ModulesActivated';
import PrimaryBusiness from './Subscription/PrimaryBusiness';
import CustomerType from './Advanced/CustomerType';
import ContactType from './Advanced/ContactType';
import LocationType from './Advanced/LocationType';
import EmployeeType from './Advanced/EmployeeType';
import EmployeeStatus from './Advanced/EmployeeStatus';
import PersonalInformation from './General/PersonalInformation';
import EmploymentInformation from './General/EmploymentInformation';
import EmployeeTaskType from './General/EmployeeTaskType';
import TaskType from './Advanced/TaskType';
import IndividualSettings from './Advanced/IndividualSettings';
import EvvProcedure from '../Settings/SettingsAndConfg/EvvProcedure/EvvProcedure';
import CompanyInformation from './Agency/CompanyInformation';
import PayerInformation from './Agency/PayerInformation';
import Billing from './Agency/BillingInformation';
import Office from './Agency/Office';
import Provider from './Agency/Provider';
import SalaryType from './Agency/SalaryType';
import PayrollFrequency from './Agency/PayrollFrequency';
import StartOfTheWeek from './Agency/StartOfTheWeek';
import Payer from './Agency/Payer';
import AgencySettings from './Agency/AgencySettings';
import Resource from './Agency/Resource';
import Scheduler from './Agency/Scheduler';
import EmpSettings from './Employee/EmpSettings';
import Onboarding from './Employee/Onboarding';
import EmployeeRelationship from './Employee/EmployeeRelationship';
import SecureCommunication from './Agency/SecureCommunication';
import SecureCommunication2 from './General/SecureCommunication2';
import Form from './Advanced/Form';
import ccs from 'countrycitystatejson';
import normalize from 'us-states-normalize';
import axios from 'axios';
import { APIURL } from '../../config';

const Settings = (props) => {
    const [value, setValue] = useState(0);

    // General states
    const [employee, setEmployee] = useState({});
    const [empList, setEmpList] = useState([]);
    const [employeeTaskTypeList, setEmployeeTaskTypeList] = useState([]);
    const [dropdowns, setDropdowns] = useState({});

    // Subscription states
    const [stateList, setStateList] = useState([]);
    const [selectedStates, setSelectedStates] = useState([]);
    const [moduleList, setModuleList] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);
    const [primaryBusinessType, setPrimaryBusinessType] = useState({});

    // Agency states
    const [companyDetail, setCompanyDetail] = useState({});
    const [payrollData, setPayrollData] = useState([]);
    const [billingDetail, setBillingDetail] = useState({});
    const [salaryTypeList, setSalaryTypeList] = useState([]);
    const [frequencyList, setFrequencyList] = useState([]);
    const [selectedFrequency, setSelectedFrequency] = useState(null);
    const [selectedDay, setSelectedDay] = useState(null);
    const [payerList, setPayerList] = useState([]);
    const [resources, setResources] = useState([]);
    const [locations, setLocations] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [schedulerHours, setSchedulerHours] = useState({});
    const [invites, setInvites] = useState([]);

    // Employee states

    // Advanced states
    const [customerTypeList, setCustomerTypeList] = useState([]);
    const [contactTypeList, setContactTypeList] = useState([]);
    const [locationTypeList, setLocationTypeList] = useState([]);
    const [employeeTypeList, setEmployeeTypeList] = useState([]);
    const [employeeStatusList, setEmployeeStatusList] = useState([]);
    const [taskTypeList, setTaskTypeList] = useState([]);
    const [procList, setProcList] = useState([]);
    const [providerList, setProviderList] = useState([]);
    const [officeList, setOfficeList] = useState([]);
    const [formList, setFormList] = useState([]);
    const [subscribedForms, setSubscribedForms] = useState([]);
    const [allForms, setAllForms] = useState([]);

    const [calendarNotifications, setCalendarNotifications] = useState(false);
    const [mtNA, setMtNA] = useState(false);
    const [mtNamingConvention, setMtNamingConvention] = useState('');
    const [trackEmployeeCost, setTrackEmployeeCost] = useState(false);
    const [identifyAnnualVisit, setIdentifyAnnualVisit] = useState(false);
    const [mtNotification, setMtNotification] = useState(false);
    const [taskNotification, setTaskNotification] = useState(false);
    const [enableAutomaticPayroll, setEnableAutomaticPayroll] = useState(false);
    const [tasksBillableByDefault, setTasksBillableByDefault] = useState(false);
    const [toleranceVal, setToleranceVal] = useState(null);

    const [procedureData, setProcedureData] = useState([]);
    const [payerDetail, setPayerDetail] = useState({});
    const [credDetail, setCredDetail] = useState({});

    const [mtSubmission, setMtSubmission] = useState(false);
    const [mtRejected, setMtRejected] = useState(false);
    const [mtApproved, setMtApproved] = useState(false);
    const [taskAssigned, setTaskAssigned] = useState(false);
    const [taskStartTimeApproaching, setTaskStartTimeApproaching] = useState(false);
    const [taskEndTimeApproaching, setTaskEndTimeApproaching] = useState(false);
    const [enableMessagingToClients, setEnableMessagingToClients] = useState(false);
    const [enableSoSMessaging, setEnableSoSMessaging] = useState(false);
    const [enableMessagingToContacts, setEnableMessagingToContacts] = useState(false);

    const [mtSubmission2, setMtSubmission2] = useState(false);
    const [mtRejected2, setMtRejected2] = useState(false);
    const [mtApproved2, setMtApproved2] = useState(false);
    const [taskAssigned2, setTaskAssigned2] = useState(false);
    const [taskStartTimeApproaching2, setTaskStartTimeApproaching2] = useState(false);
    const [taskEndTimeApproaching2, setTaskEndTimeApproaching2] = useState(false);

    const leftColumnSize = 4;
    const rightColumnSize = 8;

    const [loadingGeneral, setLoadingGeneral] = useState(true);
    const [loadingSubscription, setLoadingSubscription] = useState(true);
    const [loadingAgency, setLoadingAgency] = useState(true);
    const [loadingEmployee, setLoadingEmployee] = useState(true);
    const [loadingAdvanced, setLoadingAdvanced] = useState(true);

    useEffect(() => {
        const loadDataForTab = async () => {
            switch (value) {
                case 0:
                    if (loadingGeneral) {
                        await loadGeneralData();
                        setLoadingGeneral(false);
                    }
                    break;
                case 1:
                    if (loadingSubscription) {
                        await loadSubscriptionData();
                        setLoadingSubscription(false);
                    }
                    break;
                case 2:
                    if (loadingAgency) {
                        await loadAgencyData();
                        setLoadingAgency(false);
                    }
                    break;
                case 3:
                    if (loadingEmployee) {
                        await loadEmployeeData();
                        setLoadingEmployee(false);
                    }
                    break;
                case 4:
                    if (loadingAdvanced) {
                        await loadAdvancedData();
                        setLoadingAdvanced(false);
                    }
                    break;
                default:
                    break;
            }
        };

        loadDataForTab();
    }, [value]);

    const loadGeneralData = async () => {
        await getEmployee();
        await getEmployeeTaskTypes();
        await getSecureCommunicationData2();
        // Load more general data if required
    };

    const loadSubscriptionData = async () => {
        await getPrimaryBusinessData();
        await getStateData();
        await getModuleData();
        // Load more subscription data if required
    };

    const loadAgencyData = async () => {
        await getCompanyData();
        await geBillingData();
        await getSalaryTypeData();
        await getFrequencyData();
        await getResourceData();
        await getSchedulerHours();
        await getAgencySettings();
        await getSecureCommunicationData();
        // Load any remaining agency data
    };

    const loadEmployeeData = async () => {
        if (!empList)
            await getEmployee();
        await getInviteData();
        // Load employee data if necessary
    };

    const loadAdvancedData = async () => {
        await getTaskTypeData();
        await getContactTypeData();
        await getLocationTypeData();
        await getEmployeeTypeData();
        await getEmployeeStatusData();
        await getCustomerTypeData();
        await getIndividualSettings();
        await getProcedureData();
        await getSubscribedFormData();
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getStateData = async () => {
        try {
            let states = ccs.getStatesByShort('US').filter(state => { return state.length > 2 });
            let stateList = await axios.get(APIURL + "/dropdowns/states")
            stateList = stateList.data.states.map(state => {
                if (states.find((s) => s === state.name)) {
                    return { name: state.name, isoCode: normalize(state.isoCode) }
                } else { return null }
            }).filter(state => !!state)
            setStateList(stateList);
            let selectedStates = await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 99 } })
            selectedStates = selectedStates.data?.map(state => {
                return { name: state.feature_value, isoCode: state.state_id }
            })
            setSelectedStates(selectedStates);
        } catch (error) {
            console.error(error);
        }
    }

    const getModuleData = async () => {
        try {
            let modulesList = await axios.get(APIURL + "/config/defaults", { params: { feature_type: "functionality" } })
            setModuleList(modulesList.data);
            let selectedModules = await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_value: "functionality" } })
            selectedModules = selectedModules.data.map(module => {
                return { feature_value: modulesList.data.find((m) => m.feature_id === module.feature_id).feature_value, feature_id: module.feature_id }
            })
            setSelectedModules(selectedModules);
        } catch (error) {
            console.error(error);
        }
    }

    const getPrimaryBusinessData = async () => {
        try {
            let primaryBusinessType = await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_value: "primaryBusinessType" } });
            setPrimaryBusinessType(primaryBusinessType.data[0] || null);
            console.log(primaryBusinessType);

        } catch (error) {
            console.error(error);
        }
    }

    const getCustomerTypeData = async () => {
        try {
            let customerType = await axios.get(APIURL + "/dropdowns/dropdownType/customerTypeList", { params: { comp_id: props.comp_id } });
            setCustomerTypeList(customerType.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getContactTypeData = async () => {
        try {
            let contactType = await axios.get(APIURL + "/dropdowns/dropdownType/contactTypeList", { params: { comp_id: props.comp_id } });
            setContactTypeList(contactType.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getLocationTypeData = async () => {
        try {
            let locationType = await axios.get(APIURL + "/dropdowns/dropdownType/locTypeList", { params: { comp_id: props.comp_id } });
            setLocationTypeList(locationType.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getEmployeeTypeData = async () => {
        try {
            let employeeType = await axios.get(APIURL + "/dropdowns/dropdownType/empTypeList", { params: { comp_id: props.comp_id } });
            setEmployeeTypeList(employeeType.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getEmployeeStatusData = async () => {
        try {
            let employeeStatus = await axios.get(APIURL + "/dropdowns/dropdownType/empStatusList", { params: { comp_id: props.comp_id } });
            setEmployeeStatusList(employeeStatus.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getEmployee = async () => {
        try {
            let empList = await axios.get(APIURL + '/employees', { params: { com_id: props.comp_id } })
            empList.data = props.user.type === 'Manager' ? empList.data.filter((emp) => emp.emp_manager_id === props.user.emp_id || emp.emp_id === props.user.emp_id) : empList.data

            setEmpList(empList.data);
            setEmployee(empList.data.find((emp) => emp.emp_id === props.user.emp_id));

            let dropdowns = {
                employees: {
                    list: (await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: props.comp_id } })).data,
                    empType: (await axios.get(APIURL + '/dropdowns/dropdownType/empType', { params: { comp_id: props.comp_id } })).data,
                    // empLevel: (await axios.get(APIURL+'/dropdowns/dropdownType/empLevel', {params: {comp_id: this.props.comp_id}})).data,
                    compensationType: (await axios.get(APIURL + '/dropdowns/dropdownType/salaryType', { params: { comp_id: props.comp_id } })).data,
                    empStatus: (await axios.get(APIURL + '/dropdowns/dropdownType/empStatus', { params: { comp_id: props.comp_id } })).data
                }
            }
            setDropdowns(dropdowns.employees);
        } catch (error) {
            console.error(error);
        }
    }

    const getEmployeeTaskTypes = async () => {
        let empTasks = await axios.get(APIURL + "/employees/EmployeeTasks", {
            params: { comp_id: props.comp_id, employee_id: props.user.emp_id },
        });

        let taskTypeList = await axios.get(APIURL + "/dropdowns/dropdownType/fullTaskType", {
            params: { comp_id: props.user.comp_id },
        });

        taskTypeList.data.forEach((type) => {
            if (empTasks.data.find((task) => task.ddID === type.ddID)) {
                taskTypeList.data.find((task) => task.ddID === type.ddID).is_Active = 0
            } else {
                taskTypeList.data.find((task) => task.ddID === type.ddID).is_Active = 1
            }
        })
        setEmployeeTaskTypeList(taskTypeList.data)
    }


    const getTaskTypeData = async () => {
        try {
            let taskType = await axios.get(APIURL + "/dropdowns/dropdownType/taskTypeList", { params: { comp_id: props.comp_id } })
            setTaskTypeList(taskType.data);

            let selectedStates = await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 99 } })
            selectedStates = selectedStates.data?.map(state => {
                return { name: state.feature_value, isoCode: state.state_id }
            })
            setSelectedStates(selectedStates);

            let Procdata = await axios.post(APIURL + '/procedureCodes', { states: selectedStates.map((state) => { return { state_id: state.isoCode } }) })
            setProcList(Procdata.data)

            let subForms = await axios.get(APIURL + '/task_form/subscribed?company_id=' + props.comp_id)
            setFormList(subForms.data)

            let providerData = await axios.get(APIURL + `/company/provider`, { params: { company_id: props.comp_id } });
            setProviderList(providerData.data)

            let officeData = await axios.get(APIURL + `/company/officeLocation`, { params: { company_id: props.comp_id } });
            setOfficeList(officeData.data)

            let payerdata = await axios.get(APIURL + "/payer", { params: { comp_id: props.comp_id } })
            setPayerList(payerdata.data)
        } catch (error) {
            console.error(error);
        }
    }

    const getIndividualSettings = async () => {
        try {
            let calendarNotificationsSettingsData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 50 } })).data;
            if (calendarNotificationsSettingsData.length > 0)
                calendarNotificationsSettingsData[0].feature_value === 'true' ? setCalendarNotifications(true) : setCalendarNotifications(false);

            let mtNAData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 52 } })).data;
            if (mtNAData.length > 0)
                mtNAData[0].feature_value === 'true' ? setMtNA(true) : setMtNA(false);

            let mtNamingConventionData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 53 } })).data;
            if (mtNamingConventionData.length > 0)
                setMtNamingConvention(mtNamingConventionData[0].feature_value);

            let trackEmployeeCostData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 54 } })).data;
            if (trackEmployeeCostData.length > 0)
                trackEmployeeCostData[0].feature_value === 'true' ? setTrackEmployeeCost(true) : setTrackEmployeeCost(false);

            let identifyAnnualVisitSettingsData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 55 } })).data;
            if (identifyAnnualVisitSettingsData.length > 0)
                identifyAnnualVisitSettingsData[0].feature_value === 'true' ? setIdentifyAnnualVisit(true) : setIdentifyAnnualVisit(false);

            let mtNotificationSettingsData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 59 } })).data;
            if (mtNotificationSettingsData.length > 0)
                mtNotificationSettingsData[0].feature_value === 'true' ? setMtNotification(true) : setMtNotification(false);

            let taskNotificationSettingsData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 58 } })).data;
            if (taskNotificationSettingsData.length > 0)
                taskNotificationSettingsData[0].feature_value === 'true' ? setTaskNotification(true) : setTaskNotification(false);
        } catch (error) {
            console.error(error);
        }
    }

    const getProcedureData = async () => {
        let dataMaps = await axios.get(APIURL + "/procedureCodes/handleMappingForTaskProcCode", { params: { comp_id: props.comp_id } });
        setProcedureData(dataMaps.data);
    }

    const getCompanyData = async () => {
        let company_detail = await axios.get(APIURL + '/company', { params: { comp_id: props.comp_id } })
        let payrollData = await axios.get(APIURL + '/dropdowns/dropdownType/payrollFrequency', { params: { comp_id: props.comp_id } })
        let payerdata = await axios.get(APIURL + "/payer", { params: { comp_id: props.comp_id } })

        let Company_Dets = { ...company_detail.data[0] }
        if (Company_Dets.payerId) {
            let Payer_Dets = await axios.get(APIURL + '/payer', { params: { payer_id: Company_Dets.payerId } })
            setPayerDetail(Payer_Dets.data[0] || {})
            let Cred_Data = await axios.get(APIURL + '/creds', { params: { com_id: props.comp_id } })
            setCredDetail(Cred_Data.data[0] || {})
        }
        setCompanyDetail(Company_Dets)
        setPayrollData(payrollData.data)
        setSelectedFrequency(Company_Dets.payCycle)
        setSelectedDay(Company_Dets.startoftheWeek)
        setPayerList(payerdata.data)
    }

    const geBillingData = async () => {
        try {
            const response = await axios.get(APIURL + `/company/v2/billinginfo`, { params: { company_id: props.comp_id } });
            setBillingDetail(response.data)
        } catch (error) {
            console.error(error);
        }
    }

    const getSalaryTypeData = async () => {
        try {
            let salaryType = await axios.get(APIURL + "/dropdowns/dropdownType/salaryType", { params: { comp_id: props.comp_id } });
            setSalaryTypeList(salaryType.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getFrequencyData = async () => {
        try {
            let frequency = await axios.get(APIURL + "/config/defaults", { params: { feature_type: 'payrollFrequency' } });
            setFrequencyList(frequency.data);
        } catch (error) {
            console.error(error);
        }
    }

    const getAgencySettings = async () => {
        try {
            let enableAutomaticPayrollData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 55 } })).data;
            if (enableAutomaticPayrollData.length > 0)
                enableAutomaticPayrollData[0].feature_value === 'true' ? setEnableAutomaticPayroll(true) : setEnableAutomaticPayroll(false);

            let tasksBillableByDefaultData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 56 } })).data;
            if (tasksBillableByDefaultData.length > 0)
                tasksBillableByDefaultData[0].feature_value === 'true' ? setTasksBillableByDefault(true) : setTasksBillableByDefault(false);

            let toleranceValData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 57 } })).data;
            if (toleranceValData.length > 0)
                setToleranceVal(toleranceValData[0].feature_value);
            else {
                let addDefaultTolerance = (await axios.post(APIURL + "/config/settings", {
                    feature_value: "300",
                    feature_id: 57,
                    company_id: props.comp_id,
                }))
                let toleranceUpdateResp = (await axios.get(APIURL + "/config/settings", { params: { comp_id: this.props.comp_id, feature_id: 57 } })).data
                setToleranceVal(Number(toleranceUpdateResp[0].feature_value));
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getResourceData = async () => {
        try {
            let resources = await axios.get(APIURL + "/resources/company/" + props.comp_id)
            resources.data = resources.data.filter(resource => resource.resource_is_active === true)
            setResources(resources.data)

            let locations = await axios.get(`${APIURL}/location`, { params: { comp_id: props.comp_id } })
            setLocations(locations.data)

            let contacts = await axios.get(`${APIURL}/contacts?com_id=${props.comp_id}`)
            setContacts(contacts.data)
        } catch (error) {
            console.error(error);
        }
    }

    const getSchedulerHours = async () => {
        try {
            let schedulerHoursData = await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 80 } })
            if (schedulerHoursData.data.length > 0)
                setSchedulerHours(JSON.parse(schedulerHoursData.data[0].feature_value))
        } catch (error) {
            console.error(error);
        }
    }

    const getInviteData = async () => {
        try {
            const empInvites = await axios.get(`${APIURL}/activity_log`, {
                params: { empID: props.user.emp_id },
            });
            setInvites(empInvites.data);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getSecureCommunicationData = async () => {
        try {
            let mtSubmissionData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 60 } })).data;
            if (mtSubmissionData.length > 0)
                mtSubmissionData[0].feature_value === 'true' ? setMtSubmission(true) : setMtSubmission(false);

            let mtRejectedData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 61 } })).data;
            if (mtRejectedData.length > 0)
                mtRejectedData[0].feature_value === 'true' ? setMtRejected(true) : setMtRejected(false);

            let mtApprovedData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 62 } })).data;
            if (mtApprovedData.length > 0)
                mtApprovedData[0].feature_value === 'true' ? setMtApproved(true) : setMtApproved(false);

            let taskAssignedData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 63 } })).data;
            if (taskAssignedData.length > 0)
                taskAssignedData[0].feature_value === 'true' ? setTaskAssigned(true) : setTaskAssigned(false);

            let taskStartTimeApproachingData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 64 } })).data;
            if (taskStartTimeApproachingData.length > 0)
                taskStartTimeApproachingData[0].feature_value === 'true' ? setTaskStartTimeApproaching(true) : setTaskStartTimeApproaching(false);

            let taskEndTimeApproachingData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 65 } })).data;
            if (taskEndTimeApproachingData.length > 0)
                taskEndTimeApproachingData[0].feature_value === 'true' ? setTaskEndTimeApproaching(true) : setTaskEndTimeApproaching(false);

            let enableMessagingToClientsData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 66 } })).data;
            if (enableMessagingToClientsData.length > 0)
                enableMessagingToClientsData[0].feature_value === 'true' ? setEnableMessagingToClients(true) : setEnableMessagingToClients(false);

            let enableSoSMessagingData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 67 } })).data;
            if (enableSoSMessagingData.length > 0)
                enableSoSMessagingData[0].feature_value === 'true' ? setEnableSoSMessaging(true) : setEnableSoSMessaging(false);

            let enableMessagingToContactsData = (await axios.get(APIURL + "/config/settings", { params: { comp_id: props.comp_id, feature_id: 68 } })).data;
            if (enableMessagingToContactsData.length > 0)
                enableMessagingToContactsData[0].feature_value === 'true' ? setEnableMessagingToContacts(true) : setEnableMessagingToContacts(false);
        } catch (error) {
            console.error(error);
        }
    }

    const getSecureCommunicationData2 = async () => {
        try {
            let mtSubmissionData = (await axios.get(APIURL + "/config/settings/" + props.user.emp_id, { params: { comp_id: props.comp_id, feature_id: 1 } })).data;
            if (mtSubmissionData.length > 0)
                mtSubmissionData[0].feature_value === 'true' ? setMtSubmission2(true) : setMtSubmission2(false);

            let mtRejectedData = (await axios.get(APIURL + "/config/settings/" + props.user.emp_id, { params: { comp_id: props.comp_id, feature_id: 2 } })).data;
            if (mtRejectedData.length > 0)
                mtRejectedData[0].feature_value === 'true' ? setMtRejected2(true) : setMtRejected2(false);

            let mtApprovedData = (await axios.get(APIURL + "/config/settings/" + props.user.emp_id, { params: { comp_id: props.comp_id, feature_id: 3 } })).data;
            if (mtApprovedData.length > 0)
                mtApprovedData[0].feature_value === 'true' ? setMtApproved2(true) : setMtApproved2(false);

            let taskAssignedData = (await axios.get(APIURL + "/config/settings/" + props.user.emp_id, { params: { comp_id: props.comp_id, feature_id: 4 } })).data;
            if (taskAssignedData.length > 0)
                taskAssignedData[0].feature_value === 'true' ? setTaskAssigned2(true) : setTaskAssigned2(false);

            let taskStartTimeApproachingData = (await axios.get(APIURL + "/config/settings/" + props.user.emp_id, { params: { comp_id: props.comp_id, feature_id: 5 } })).data;
            if (taskStartTimeApproachingData.length > 0)
                taskStartTimeApproachingData[0].feature_value === 'true' ? setTaskStartTimeApproaching2(true) : setTaskStartTimeApproaching2(false);

            let taskEndTimeApproachingData = (await axios.get(APIURL + "/config/settings/" + props.user.emp_id, { params: { comp_id: props.comp_id, feature_id: 6 } })).data;
            if (taskEndTimeApproachingData.length > 0)
                taskEndTimeApproachingData[0].feature_value === 'true' ? setTaskEndTimeApproaching2(true) : setTaskEndTimeApproaching2(false);
        } catch (error) {
            console.error(error);
        }
    }

    const getSubscribedFormData = async () => {
        try {
            let subscribedForms = await axios.get(APIURL + "/task_form/subscribed", { params: { company_id: props.comp_id } });
            setSubscribedForms(subscribedForms.data);
            let allForms = await axios.get(APIURL + "/task_form/forms");
            setAllForms(allForms.data?.filter(form => form.form_company === props.comp_id || form.public_form === true));
            console.log(subscribedForms.data, allForms.data?.filter(form => form.form_company === props.comp_id || form.public_form === true))

        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Box sx={{ maxWidth: 1200, mx: 'auto' }}>
            <Typography variant="h4" gutterBottom>
                Settings
            </Typography>
            <Box>
                <Tabs value={value} onChange={handleChange}>
                    <Tab label="General" value={0} />
                    {props.user.type === 'Admin' && <Tab label="Subscription" value={1} />}
                    {props.user.type === 'Admin' && <Tab label="Agency" value={2} />}
                    {props.user.type === 'Admin' && <Tab label="Employee" value={3} />}
                    {props.user.type === 'Admin' && <Tab label="Advanced" value={4} />}
                </Tabs>
                <Divider />
                <Section value={value} index={0}>
                    {loadingGeneral ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                            <CircularProgress size={60} />
                        </Box>
                    ) : (
                        <>
                            <SubSection>
                                <PersonalInformation employee={employee} setEmployee={setEmployee} user_id={props.user.emp_id} comp_id={props.comp_id} />
                            </SubSection>
                            <SubSection>
                                <EmploymentInformation employee={employee} setEmployee={setEmployee} empList={empList} levelList={dropdowns.empType} compensationType={dropdowns.compensationType} statusList={dropdowns.empStatus} user_id={props.user.emp_id} comp_id={props.comp_id} type={props.user.type} />
                            </SubSection>
                            <SubSection>
                                <EmployeeTaskType leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} typeList={employeeTaskTypeList} />
                            </SubSection>
                            <SubSection>
                                <SecureCommunication2
                                    mtSubmission={mtSubmission2}
                                    mtRejected={mtRejected2}
                                    mtApproved={mtApproved2}
                                    taskAssigned={taskAssigned2}
                                    taskStartTimeApproaching={taskStartTimeApproaching2}
                                    taskEndTimeApproaching={taskEndTimeApproaching2}
                                    user_id={props.user.emp_id}
                                    comp_id={props.comp_id}
                                    leftColumnSize={leftColumnSize}
                                    rightColumnSize={rightColumnSize}
                                />
                            </SubSection>
                        </>
                    )}
                </Section>
                <Section value={value} index={1}>
                    {loadingSubscription ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                            <CircularProgress size={60} />
                        </Box>
                    ) : (
                        <>
                            <SubSection>
                                <PrimaryBusiness leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} type={primaryBusinessType} user_id={props.user.emp_id} comp_id={props.comp_id} />
                            </SubSection>
                            <SubSection>
                                <States leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} stateList={stateList} selectedStates={selectedStates} user_id={props.user.emp_id} comp_id={props.comp_id} />
                            </SubSection>
                            <SubSection>
                                <ModulesActivated leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} moduleList={moduleList} selectedModules={selectedModules} user_id={props.user.emp_id} comp_id={props.comp_id} />
                            </SubSection>
                        </>
                    )}
                </Section>
                <Section value={value} index={2}>
                    {loadingAgency ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                            <CircularProgress size={60} />
                        </Box>
                    ) : (
                        <>
                            <SubSection>
                                <CompanyInformation companyDetail={companyDetail} payrollData={payrollData} user_id={props.user.emp_id} comp_id={props.comp_id} />
                            </SubSection>
                            <SubSection>
                                <PayerInformation payerDetail={payerDetail} credDetail={credDetail} data={companyDetail} user_id={props.user.emp_id} comp_id={props.comp_id} />
                            </SubSection>
                            <SubSection>
                                <Billing billingDetail={billingDetail} user_id={props.user.emp_id} comp_id={props.comp_id} />
                            </SubSection>
                            <SubSection>
                                <Provider user_id={props.user.emp_id} comp_id={props.comp_id} />
                            </SubSection>
                            <SubSection>
                                <Office user_id={props.user.emp_id} comp_id={props.comp_id} />
                            </SubSection>
                            <SubSection>
                                <SalaryType leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} typeList={salaryTypeList} />
                            </SubSection>
                            <SubSection>
                                <PayrollFrequency leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} frequencyList={frequencyList} selectedFrequency={selectedFrequency} companyDetail={companyDetail} enableAutomaticPayroll={enableAutomaticPayroll} />
                            </SubSection>
                            <SubSection>
                                <StartOfTheWeek leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} selectedDay={selectedDay} companyDetail={companyDetail} />
                            </SubSection>
                            <SubSection>
                                <Payer leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} payerList={payerList} companyDetail={companyDetail} />
                            </SubSection>
                            <SubSection>
                                <AgencySettings leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} enableAutomaticPayroll={enableAutomaticPayroll} tasksBillableByDefault={tasksBillableByDefault} toleranceVal={toleranceVal} />
                            </SubSection>
                            <SubSection>
                                <Scheduler leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} schedulerHours={schedulerHours} />
                            </SubSection>
                            <SubSection>
                                <Resource leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} resources={resources} locations={locations} contacts={contacts} />
                            </SubSection>
                            {moduleList?.find((module) => module.feature_value === 'Secure Communication') && (
                                <SubSection>
                                    <SecureCommunication
                                        mtSubmission={mtSubmission}
                                        mtRejected={mtRejected}
                                        mtApproved={mtApproved}
                                        taskAssigned={taskAssigned}
                                        taskStartTimeApproaching={taskStartTimeApproaching}
                                        taskEndTimeApproaching={taskEndTimeApproaching}
                                        enableMessagingToClients={enableMessagingToClients}
                                        enableSoSMessaging={enableSoSMessaging}
                                        enableMessagingToContacts={enableMessagingToContacts}
                                        user_id={props.user.emp_id}
                                        comp_id={props.comp_id}
                                        leftColumnSize={leftColumnSize}
                                        rightColumnSize={rightColumnSize}
                                    />
                                </SubSection>
                            )}
                        </>
                    )}
                </Section>
                <Section value={value} index={3}>
                    {loadingEmployee ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                            <CircularProgress size={60} />
                        </Box>
                    ) : (
                        <>
                            <SubSection>
                                <EmpSettings
                                    leftColumnSize={leftColumnSize}
                                    rightColumnSize={rightColumnSize}
                                    user_id={props.user.emp_id}
                                    comp_id={props.comp_id}
                                    empList={empList}
                                    levelList={dropdowns.empType}
                                    compensationType={dropdowns.compensationType}
                                    statusList={dropdowns.empStatus}
                                    type={props.user.type}
                                />
                            </SubSection>
                            <SubSection>
                                <Onboarding leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user={props.user} comp_id={props.comp_id} invites={invites} />
                            </SubSection>
                            <SubSection>
                                <EmployeeRelationship leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} empList={empList} empType={dropdowns.empType} />
                            </SubSection>
                        </>
                    )}
                </Section>
                <Section value={value} index={4}>
                    {loadingAdvanced ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
                            <CircularProgress size={60} />
                        </Box>
                    ) : (
                        <>
                            <SubSection>
                                <TaskType leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} getTaskTypeData={getTaskTypeData} taskTypeList={taskTypeList} procList={procList} formList={formList} providerList={providerList} officeList={officeList} payerList={payerList} procedureData={procedureData} />
                            </SubSection>
                            <SubSection>
                                <Form leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} subscribedForms={subscribedForms} allForms={allForms} setAllForms={setAllForms} />
                            </SubSection>
                            <SubSection>
                                <CustomerType leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} typeList={customerTypeList} />
                            </SubSection>
                            <SubSection>
                                <ContactType leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} typeList={contactTypeList} />
                            </SubSection>
                            <SubSection>
                                <LocationType leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} typeList={locationTypeList} />
                            </SubSection>
                            <SubSection>
                                <EmployeeType leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} typeList={employeeTypeList} />
                            </SubSection>
                            <SubSection>
                                <EmployeeStatus leftColumnSize={leftColumnSize} rightColumnSize={rightColumnSize} user_id={props.user.emp_id} comp_id={props.comp_id} typeList={employeeStatusList} />
                            </SubSection>
                            <SubSection>
                                <IndividualSettings
                                    mtNamingConvention={mtNamingConvention}
                                    mtNA={mtNA}
                                    calendarNotifications={calendarNotifications}
                                    trackEmployeeCost={trackEmployeeCost}
                                    identifyAnnualVisit={identifyAnnualVisit}
                                    mtNotification={mtNotification}
                                    taskNotification={taskNotification}
                                    user_id={props.user.emp_id}
                                    comp_id={props.comp_id}
                                    leftColumnSize={leftColumnSize}
                                    rightColumnSize={rightColumnSize}
                                />
                            </SubSection>
                            <Box sx={{ mt: 2 }}>
                                <Paper sx={{ p: 4 }}>
                                    <Typography variant="h5" sx={{ mb: 1 }}>
                                        Task type Mapping
                                    </Typography>
                                    <Typography variant="body1" sx={{ mb: 2, color: 'gray' }}>
                                        View Mapping of task types to standard descriptions and codes
                                    </Typography>
                                    <EvvProcedure data={procedureData} user={props.user} emp_id={props.user.emp_id} comp_id={props.comp_id} />
                                </Paper>
                            </Box>
                        </>
                    )}
                </Section>
            </Box>
        </Box>
    );
}

export default Settings;