import React from 'react';
import {Form, Button} from 'react-bootstrap';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import './OtherDetails.css'
import axios from 'axios'
import {APIURL} from '../../../../config'

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#3FA7D6',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#3FA7D6',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightblue',
        },
        '&:hover fieldset': {
          borderColor: '#3FA7D6',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#3FA7D6',
        },
      },
    },
})(TextField);

const OtherDetails = (props) => {
    // let Manager = props.empList.map((emp,indx) => <option key={indx} value={emp.id}>{emp.employee_name}</option>)
    // let empStatus = props.empStatus.map((status,indx)=> <option key={indx}>{status}</option>)
    // let empType = props.empType.map((type,indx)=> <option key={indx}>{type}</option>)
    // let empLevel = props.empLevel.map((level,indx)=> <option key={indx}>{level}</option>)
    let Manager = props.empList.map((emp,indx) => {return {value:emp.id,title: emp.employee_name}})
    Manager = [{value:'',title: 'No Manager'},...Manager]
    let empStatus = props.empStatus.map((status,indx)=> {return {value: status, title: status}})
    //let empType = props.empType.map((type,indx)=> {return {value: type, title: type}})
    let empType= props.empTypes
    let empLevel = props.empLevel.map((level,indx)=> {return {value: level, title: level}})
    return (
        <div>
           {/* <Form.Row>
               <Form.Group as={Col} controlId="join_date">
               <Form.Label>JOIN DATE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
               <Form.Control value={props.formVals.join_date} type="date" onChange={(event) => props.formChangeHandler(event)} required/>
               </Form.Group>
           </Form.Row> */}
           <LocalizationProvider dateAdapter={AdapterDateFns}>
               <Grid container justifyContent="flex-start">
                   <DatePicker
                   className={'mr-2 w-75'}
                   id="join_date"
                   label="JOIN DATE"
                   value={moment(props.formVals.join_date).format('YYYY-MM-DDTHH:mm:ss')}
                   onChange={(d) => {props.formChangeHandler({target: {value: moment(d).format('YYYY-MM-DDTHH:mm:ss'),id : 'join_date'}})}}
                   renderInput={(props) => (
                        <CssTextField
                        {...props}
                        className='employeeform-otherdetails-datepicker'
                    />
                    )}
                    />
               </Grid>
           </LocalizationProvider>
           <Form.Row>
               {/* <Form.Group as={Col} controlId="status">
               <Form.Label>STATUS</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
               <Form.Control value={props.formVals.status} as="select" onChange={(event) => props.formChangeHandler(event)} required>
                   <option value="">Select Status</option>
                   {empStatus}
               </Form.Control>
               </Form.Group> */}
                <Autocomplete
                   id="status"
                   value={empStatus.find((type)=> type.title === props.formVals.status) || null}
                   className={`mx-1 my-3 w-75`}
                   onSelect={(e)=> {
                       props.formChangeHandler(e)
                   }}
                   options={empStatus}
                   getOptionLabel={(option) => option.title}
                   // style={{ width: 450 }}
                   renderInput={(params) => <CssTextField {...params} label="EMPLOYEE STATUS *"  variant="outlined" />}
               />
           </Form.Row>
           <Form.Row>
               {/* <Form.Group as={Col} controlId="employee_type">
                   <Form.Label>EMPLOYEE TYPE</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                   <Form.Control value={props.formVals.employee_type} as="select" onChange={(event) => props.formChangeHandler(event)} required>
                       <option value="">Select Type</option>
                       {empType}
                   </Form.Control>
               </Form.Group> */}
               <Autocomplete
                   id="employee_type"
                   value={props.empType.find((type)=> type.title === props.formVals.employee_type) || null}
                   className={`mx-1 my-3 w-75`}
                   onSelect={(e)=> {
                       props.formChangeHandler(e)
                   }}
                   options={props.empType}
                   getOptionLabel={(option) => option}
                   // style={{ width: 450 }}
                   renderInput={(params) => <CssTextField {...params} label="EMPLOYEE TYPE *"  variant="outlined" />}
               />
           </Form.Row>
           <Form.Row>
               {/* <Form.Group as={Col} controlId="employee_level">
                   <Form.Label>EMPLOYEE LEVEL</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                   <Form.Control value={props.formVals.employee_level} as="select" onChange={(event) => props.formChangeHandler(event)} required>
                       <option value="">Select Employee Level</option>
                       {empLevel}
                   </Form.Control>
               </Form.Group> */}
               <Autocomplete
                   id="employee_level"
                   value={empLevel.find((type)=> type.title === props.formVals.employee_level) || null}
                   className={`mx-1 my-3 w-75`}
                   onSelect={(e)=> {
                       props.formChangeHandler(e)
                   }}
                   options={empLevel}
                   getOptionLabel={(option) => option.title}
                   // style={{ width: 450 }}
                   renderInput={(params) => <CssTextField {...params} label="EMPLOYEE LEVEL *"  variant="outlined" />}
               />
           </Form.Row>
           <Form.Row>
               {/* <Form.Group as={Col} controlId="manager">
                   <Form.Label>MANAGER</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                   <Form.Control value={props.formVals.manager} as="select" onChange={(event) => props.formChangeHandler(event)} required>
                       <option value="">Select Manager</option>
                       <option value="No Manager">No Manager</option>
                       {Manager}
                   </Form.Control>
               </Form.Group> */}
                <Autocomplete
                   id="manager"
                   value={Manager.find((type)=> type.value === props.formVals.manager) || null}
                   className={`mx-1 my-3 w-75`}
                   onSelect={(e)=> {
                       let val = Manager.find((man)=> man.title === e.target.value)
                       props.formChangeHandler({target: {id: 'manager', value:!!val?val.value:''}})
                   }}
                   options={Manager}
                   getOptionLabel={(option) => option.title}
                   // style={{ width: 450 }}
                   renderInput={(params) => <CssTextField {...params} label="EMPLOYEE MANAGER *"  variant="outlined" />}
               />
           </Form.Row>
           <Form.Row>
               {/* <Form.Group as={Col} controlId="vacation_days">
               <Form.Label>VACATION DAYS</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
               <Form.Control value={props.formVals.vacation_days} type="number" onChange={(event) => props.formChangeHandler(event)} required/>
               </Form.Group> */}
               <CssTextField
               style={{width:'35%'}}
                   className={`mx-2 my-3`}
                   label="VACATION DAYS"
                   value={props.formVals.vacation_days}
                   onChange={props.formChangeHandler} 
                   variant="outlined"
                   id="vacation_days"
               />
               {/* <Form.Group as={Col} controlId="personal_days_off">
                   <Form.Label>PERSONAL DAYS OFF</Form.Label><span><i style={{margin: 5,fontSize: '0.7rem'}} className="fas fa-asterisk text-danger"></i></span>
                   <Form.Control value={props.formVals.personal_days_off} type="number" onChange={(event) => props.formChangeHandler(event)} required/>
               </Form.Group> */}
               <CssTextField
               style={{width:'35%'}}
                   className={`mx-2 my-3`}
                   label="PERSONAL DAYS OFF"
                   value={props.formVals.personal_days_off}
                   onChange={props.formChangeHandler} 
                   variant="outlined"
                   id="personal_days_off"
               />
           </Form.Row>
           
           <Button className='w-75 ml-2' variant="primary" block type="submit">
               Create Employee
           </Button>
        </div>
    );
};

export default OtherDetails;