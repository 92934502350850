import * as React from "react";
import { useState } from "react";
import { Box } from "@mui/material";
import moment from "moment";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';

export default function ActiveCustomerReport(props) {
    const [gridId, setGridId] = useState(41);
    const apiRef = useGridApiRef();

    const columns = [
        { field: "cus_id", headerName: "Customer ID", hide: true },
        { field: "cus_name", headerName: "Customer Name", width: 200 },
        { field: "gender", headerName: "Gender", width: 60 },
        {
            field: "cus_address",
            headerName: "Customer Address",
            width: 400,
        },
        { field: "cus_phone", headerName: "Customer Phone", width: 120 },
        { field: "cus_email", headerName: "Customer Email", width: 200 },
        { field: "DDDID", headerName: "DDD ID", width: 70 },
        { field: "cus_local_id", headerName: "Customer Local ID", width: 130 },
        {
            field: "cus_acquired_date",
            headerName: "Customer Acquired Date",
            width: 170,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "last_plan_approval_date",
            headerName: "Last Plan approval Date",
            width: 165,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "renewal_date",
            headerName: "Renewal Date",
            width: 100,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "medicaid_eligible", headerName: "Medicaid eligible", width: 120 },
        { field: "medicaid_id", headerName: "Medicaid ID", width: 90 },
        { field: "waiver_program", headerName: "Waiver Program", width: 120 },
        { field: "PrimaryDiagnosisCode", headerName: "Primary Diagnostic Code", width: 170 },
        {
            field: "MedicaidEndDate",
            headerName: "Medicaid End Date",
            width: 135,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        {
            field: "AgencyAssignDate",
            headerName: "Agency Assign Date",
            width: 140,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
        { field: "County", headerName: "County", width: 60 },
        {
            field: "LastAnnualVisitDate",
            headerName: "Last Annual Visit",
            width: 130,
            renderCell: (params) => {
                return (
                    <div>{moment(params.value).isValid() ? moment(params.value).utc().format('MM/DD/YYYY') : params.value}</div>
                )
            }
        },
    ]

    let rows = props?.data

    return (
        <div>
            {rows.length === 0 ?
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: "500px", marginTop: 10 }}
                >
                    <img
                        src="./nosearchresult.png"
                        alt="nosearchresult"
                        style={{ width: "25rem", height: "15rem", margin: 10 }}
                    />
                    <h5
                        style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
                        className="text-center"
                    >
                        There is currently no data available{" "}
                    </h5>
                </div>
                :
                <div style={{ margin: "auto", height: '80vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '100%', height: "calc(100vh - 210px)" }}>
                        <ControlledDataGrid
                            gridId={gridId}
                            user={props.user}
                            initialState={{ sorting: { sortModel: [{ field: "cus_name", sort: "asc" }] } }}
                            apiRef={apiRef}
                            getRowId={(row) => row.cus_id}
                            rows={rows}
                            columns={columns}
                            checkboxSelection={false}
                            disableSelectionOnClick
                        />
                    </Box>
                </div>
            }
        </div>
    );
}