import React from 'react';
import FormLibrary from './FormLibrary/FormLibrary';
import FormBuilder from './FormBuilder/FormBuilder';
import ReportBuilder from './ReportBuilder/ReportBuilder';
import FormReport from './FormReport/FormReport';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';

const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

class Forms extends React.Component {
    constructor(props) {
        super(props);
        // key = [(0: Form Library), (1: Form Builder), (2: Form Report), (3: Report Builder)]
        //this.state = {key: 0}; // By default, Form Library is shown.
        this.handleClickFormLibrary = this.handleClickFormLibrary.bind(this);
        this.handleClickFormBuilder = this.handleClickFormBuilder.bind(this);
        this.handleClickFormReport = this.handleClickFormReport.bind(this);
        this.handleClickReportBuilder = this.handleClickReportBuilder.bind(this);
    }

    state = {
        key: 0,
        showModal: false,
    }

    // Handles the onClick event on each of the SideNavBar Nav.Links [(0: Form Library), (1: Form Builder)]
    handleClickFormLibrary = () => {
        this.setState({ key: 0 });
    }

    handleClickFormBuilder = () => {
        this.setState({ key: 1 });
    }

    handleClickFormReport = () => {
        this.setState({ key: 2 });
    }

    handleClickReportBuilder = () => {
        this.setState({ key: 3 });
    }

    handleToggleModal = () => {
        this.setState({ showModal: !this.state.showModal });
    }

    render() {
        // SideNavBar Logic for: Form Library | Form Builder | Form Report
        let renderComponent = '';
        if (this.state.key === 0) {
            renderComponent = <FormLibrary checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
        } else if (this.state.key === 1) {
            renderComponent = <FormBuilder checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
        } else if (this.state.key === 2) {
            renderComponent = <FormReport checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
        } else if (this.state.key === 3) {
            renderComponent = <ReportBuilder checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;
        } else {
            renderComponent = <FormLibrary checkTokenExpiry={this.props.checkTokenExpiry} user={this.props.user} comp_id={this.props.comp_id} />;;
        }

        return (
            <div>
                <div>
                    <Row>
                        {/* Left Sub-Navbar Linking to other Form pages. */}
                        <Col lg={3} md={4} sm={6} style={{ maxWidth: '300px' }}>
                            <Tab.Container>
                                <Nav className='flex-column'>
                                    <Nav.Item>
                                        <Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickFormLibrary}>
                                            <span>
                                                <i
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        paddingRight: '23px',
                                                        paddingLeft: '0px',
                                                    }}
                                                    className='fas fa-file-alt'
                                                ></i>
                                            </span>
                                            Form Library
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickFormBuilder}>
                                            <span>
                                                <i
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        paddingRight: '23px',
                                                        paddingLeft: '0px',
                                                    }}
                                                    className='fas fa-file-alt'
                                                ></i>
                                            </span>
                                            Form Builder
                                        </Nav.Link>
                                    </Nav.Item>
                                    {this.props.user.type === 'Admin' && <Nav.Item>
                                        <Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickReportBuilder}>
                                            <span>
                                                <i
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        paddingRight: '23px',
                                                        paddingLeft: '0px',
                                                    }}
                                                    className='fas fa-file-alt'
                                                ></i>
                                            </span>
                                            Report Builder
                                        </Nav.Link>
                                    </Nav.Item>}
                                    <Nav.Item>
                                        <Nav.Link className='list-group-item list-group-item-action' style={{ outline: 'none' }} onClick={this.handleClickFormReport}>
                                            <span>
                                                <i
                                                    style={{
                                                        fontSize: '1.5rem',
                                                        paddingRight: '23px',
                                                        paddingLeft: '0px',
                                                    }}
                                                    className='fas fa-file-alt'
                                                ></i>
                                            </span>
                                            Form Reports
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                            {this.state.key === 0 && <div style={{ width: '100%', marginTop: '50px' }}>
                                <div style={{ fontFamily: 'Lato', backgroundColor: 'white', border: '1px solid rgb(204, 204, 204)', borderRadius: '15px', padding: '10px 10px 10px 15px' }}>
                                    <h6 style={{ textDecoration: 'underline' }}><b>Note:</b></h6>
                                    <div style={{ marginBottom: 10 }}>
                                        <p style={{ display: 'inline', marginRight: '55px' }}>The forms displayed here are 'View Only' and can not be submitted from this screen.</p>
                                    </div>
                                    <div>
                                        <p style={{ display: 'inline', marginRight: '10px' }}>For submitting an actual form, please follow the appropriate steps.</p>
                                    </div>
                                </div>
                            </div>}
                            {this.state.key === 1 && <div style={{ width: '100%', marginTop: '50px' }}>
                                <div style={{ fontFamily: 'Lato', backgroundColor: 'white', border: '1px solid rgb(204, 204, 204)', borderRadius: '15px', padding: '10px 10px 10px 15px' }}>
                                    <h6 style={{ textDecoration: 'underline' }}><b>Note:</b></h6>
                                    <div style={{ marginBottom: 10 }}>
                                        <p style={{ display: 'inline', marginRight: '55px' }}>Not all form components are currently supported. Following is the list of supported form components:</p>
                                    </div>
                                    <div>
                                        <p style={{ display: 'inline', marginRight: '10px' }}><strong>Basic Section: </strong>All the fields.</p>
                                    </div>
                                    <div>
                                        <p style={{ display: 'inline', marginRight: '10px' }}><strong>Advanced Section: </strong>Date/Time, Day and Time.</p>
                                    </div>
                                </div>
                            </div>}
                        </Col>
                        <Col>{renderComponent}</Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default Forms;