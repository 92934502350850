import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { APIURL } from "../../../config";
import SignatureCanvas from "react-signature-canvas";
import { useDrag } from "./useDrag";
import "./styles.css";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import * as pdfjsLib from "pdfjs-dist";
import { PDFViewer, PDFJS as PDFJSViewer } from "pdfjs-dist/web/pdf_viewer";
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument, PDFPage, PDFTextAnnotation, PDFTextField, rgb } from 'pdf-lib';
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import WebViewer from "@pdftron/webviewer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


export default function SignatureCap(props) {
  const padRef = useRef(null);
  const canvasRef = useRef(null);
  const [dataToShow,setDataToShow] = useState(null)
  const [showModal,setShowModal] = useState(false);
  const customStyles = {
    width: "800px",
  };
  const [pdfDoc, setPdfDoc] = useState('https://fieldworkblob.blob.core.windows.net/fieldworker/9000573.pdf');
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [signature,setSignature] = useState(null);
  const [positions,setPositions] = useState([])
  const [showTextLayer, setShowTextLayer] = useState(false);

  // Toggle text layer visibility
  const toggleTextLayer = () => {
    setShowTextLayer(!showTextLayer);
  };


  const addAnnotation = async (sgn) => {
    
    

    const pdfBytes = await fetch('https://fieldworkblob.blob.core.windows.net/fieldworker/9000573.pdf').then((res) => res.arrayBuffer());
    const p = await PDFDocument.load(pdfBytes);

    const pages = p.getPages();
    const firstPage = pages[0];
    console.log()

    // const textAnnotation = new PDFTextField();
    // textAnnotation.setContents('Hello, World!');
    // textAnnotation.setPosition({ left: 200, top: 600 });
    // textAnnotation.setContentsLocked(true);
    // textAnnotation.setColor([0.7, 0.1, 0.1]);
    // textAnnotation.setIcon('Comment');
    const { width, height } = firstPage.getSize()
    console.log(sgn)
    
    const pngImage = await p.embedPng(sgn)

    const pngDims = pngImage.scale(0.2)

    for(let pos of positions) {
      firstPage.drawImage(pngImage, {
        x: pos[0],
        y: pos[1],
        width: pngDims.width,
        height: pngDims.height,
        color: rgb(0, 0.53, 0.71),
      })
    }

    const newPdfBytes = await p.save();

    const pdfData = new Uint8Array(newPdfBytes);
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const snapshotFile = new File([blob], 'snapshot.pdf');
    let formData = new FormData();
    formData.append("file_name", "snapshot");
    formData.append("directory_id", null);
    formData.append("cus_id", null);
    formData.append("proj_id", null);
    formData.append("task_id", null);
    formData.append("emp_id", props.user.emp_id);
    formData.append("snapshot", snapshotFile);
    
    const resp = await axios.post(APIURL+"/artifacts", formData, { headers: { "content-type": "multipart/form-data" }})
    console.log(resp);
    const url = URL.createObjectURL(blob);
    console.log(url)
    setPdfDoc(url);
  };

  const onDocumentLoadSuccess = (pdf) => {
    let pos = []
    pdf.getPage(pageNumber).then((page) => {
      console.log(page.view)
      page.getTextContent({ normalizeWhitespace: true }).then((textContent) => {
        textContent.items.forEach((item) => {
          // console.log(item.str);
          if (item.str.includes('________')) {
            console.log(item)
            let width = item.width;
            let numOfLetters = item.str.length
            let index = item.str.indexOf('_')
            let offset = ((width*index)/numOfLetters) 
            console.log(offset)
            console.log('Position: ', item.transform[4] + offset, item.transform[5]);
            pos.push([item.transform[4] + offset, item.transform[5] + item.height/2, page.view[3], item.transform[0], item.height]);
          }
        });
      }).then(async () => {
        setPositions(pos);
      }).then(async () => {
        toggleTextLayer();
      });
    });
    setNumPages(pdf.numPages);
  };
  

  // useEffect(async () => {
  //   const viewerElement = viewer.current;
  //   let viewerInstance = null;

  //   const initializeViewer = async () => {
  //     viewerInstance = await WebViewer({
  //       path: 'webviewer/public',
  //       initialDoc: pdfDoc,
  //     }, viewerElement);

  //     // You can access the instance using viewerInstance

  //     // Example: Open a different document when props change
  //     return () => {
  //       viewerInstance.closeDocument().then(() => {
  //         viewerInstance = null;
  //       });
  //     };
  //   };

  //   initializeViewer();
  // }, [pdfDoc]);

  // return (
  //   <div>
  //     <div ref={viewer} style={{ height: '100vh' }}></div>
  //     <Button onClick={addAnnotation}>
  //       Bruh
  //     </Button>
  //   </div>
  // );

  // const viewerRef = useRef(null);

  // useEffect(() => {
  //   // Load the PDF file
  //   let eventBus = new PDFJSViewer.EventBus();
  //   pdfjsLib
  //     .getDocument(
  //       "https://fieldworkblob.blob.core.windows.net/fieldworker/9000569.pdf"
  //     )
  //     .promise.then((pdf) => {
  //       // Create the PDF viewer
  //       console.log(pdf)
  //       const viewerContainer = document.createElement("div");
  //       viewerContainer.style.width = "100%";
  //       viewerContainer.style.height = "100%";
  //       viewerRef.current.appendChild(viewerContainer);

  //       // Create the PDF viewer
  //       const viewer = new PDFViewer({
  //         container: viewerRef.current,
  //         viewer: viewerContainer,
  //       });

  //       // Set the PDF document
  //       viewer.setDocument(pdf);

  //       // Update the viewer layout
  //       viewer.resize();
  //     });
  // }, []);

  // return (
  //   <div
  //     ref={viewerRef}
  //     className="pdfViewer"
  //     style={{ position: "absolute" }}
  //   ></div>
  // );

  // // useEffect(() => {
  // //   const canvas = canvasRef.current;
  // //   const context = canvas.getContext('2d');

  // //   // Load the PDF file
  // //   pdfjsLib.getDocument('https://fieldworkblob.blob.core.windows.net/fieldworker/9000569.pdf').promise.then((pdf) => {
  // //     // Load the first page of the PDF file
  // //     pdf.getPage(1).then((page) => {
  // //       // Get the width and height of the page
  // //       const viewport = page.getViewport({ scale: 1 });
  // //       canvas.width = viewport.width;
  // //       canvas.height = viewport.height;

  // //       // Render the page into the canvas
  // //       const renderContext = {
  // //         canvasContext: context,
  // //         viewport: viewport,
  // //       };
  // //       page.render(renderContext).promise.then(() => {
  // //         // Get the text layer of the page
  // //         return page.getTextContent();
  // //       }).then((textContent) => {
  // //         // Loop through the text content and add a click event listener to certain text
  // //         textContent.items.forEach((textItem) => {
  // //           if (textItem.str.includes('NJ')) {
  // //             // const transform = pdfjsLib.Util.transform(viewport.transform, textItem.transform);
  // //             // console.log(textItem.transform)
  // //             // console.log(canvas.getBoundingClientRect())
  // //             const transform = pdfjsLib.Util.transform(
  // //               viewport.transform,
  // //               textItem.transform
  // //             );
  // //             const width = textItem.width;
  // //             const height = textItem.height;
  // //             // console.log(transform[4])
  // //             const link = document.createElement('a');
  // //             const temp = document.getElementsByClassName('col')[0]
  // //             // console.log(temp.getBoundingClientRect())
  // //             link.href = '#';
  // //             link.style.position = 'absolute';
  // //             console.log(canvas.getBoundingClientRect())
  // //             console.log(temp.getBoundingClientRect())
  // //             console.log(transform[4] + canvas.getBoundingClientRect().x - temp.getBoundingClientRect().x, transform[4])
  // //             link.style.left = `${transform[4] + canvas.getBoundingClientRect().x - temp.getBoundingClientRect().x}px`;
  // //             link.style.top = `${transform[5] - height - canvas.getBoundingClientRect().height + temp.getBoundingClientRect().height}px`;
  // //             link.style.width = `${width}px`;
  // //             link.style.height = `${height}px`;
  // //             canvas.parentNode.appendChild(link);
  // //             link.addEventListener('click', () => {
  // //               alert('You clicked me!');
  // //             });
  // //           }
  // //         });
  // //       });
  // //     });
  // //   });
  // // }, []);

  // // return (
  // //   <canvas ref={canvasRef} />
  // // );

  const handleSave = async () => {
    if (padRef.current.isEmpty()) {
      alert("No signature found. Please provide a signature to save.");
      return;
    }
    const dataURL = padRef.current.getTrimmedCanvas().toDataURL();
    const [arrayBuffer,blob] = dataURLToBlob(dataURL);
    console.log(blob);
    const formData = new FormData();
    formData.append("signature", blob, "signature.png");
    formData.append("emp_id", props.user.emp_id);
    let resp = await axios
      .post(APIURL + "/signature", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
      })
      .then(async () => {
        setSignature(arrayBuffer);
        addAnnotation(arrayBuffer);
      })
      .catch((err) => {
        console.log(err);
      });
    toggleModal();
    // .then(
    //   (response) => {
    //     const blob = new Blob([response.data], { type: 'image/png' });
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.download = 'signature.png';
    //     document.body.appendChild(link);
    //     link.click();
    // });
    // console.log(resp.data.resp)
    // const blobRec = new Blob([resp.data.resp], {
    //   type: 'image/png'
    // });
    // console.log(blobRec)
    // const url = URL.createObjectURL(blobRec);

    // // Create a link element with the URL as the `href` attribute and the `download` attribute set
    // const link = document.createElement("a");
    // link.href = url;
    // link.download = "signature.png";

    // // Click the link to initiate the download
    // document.body.appendChild(link);
    // link.click();

    // // Clean up the URL object
    // URL.revokeObjectURL(url);
    padRef.current.clear();
  };

  const handleGetMostRecent = async () => {
    const htmlData = await axios.get(APIURL + "/signature", {
      params: { url: 'https://fieldworkblob.blob.core.windows.net/fieldworker/9000573.pdf' },
    })
    console.log(htmlData)
    return 
    let signature = await axios
      .get(APIURL + "/signature", {
        params: { emp_id: props.user.emp_id },
        responseType: "arraybuffer",
      })
      .then((response) => {
        console.log(response);
        const blob = new Blob([response.data], { type: "image/png" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "signature.png";
        document.body.appendChild(link);
        link.click();
      });
  };

  const renderTextElements = () => {
    return positions.map(([ x, y, z, w, u], index) => (
      <div
        key={index}
        style={{ position: 'absolute', bottom: `${y - u}px`, left: `${x}px`, transform: `scaleX(1/${w}) scaleY(1/${w})`, }}
      >
        @SignHere@
      </div>
    ));
  };


  const toggleModal = async () => {
    setShowModal(!showModal);
  }

  const dataURLToBlob = (dataURL) => {
    const byteString = Buffer.from(dataURL.split(",")[1], "base64");
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString[i];
    }
    return [byteString,new Blob([arrayBuffer], { type: mimeString })];
  };
  const draggableRef = useRef(null);

  const { position, handleMouseDown } = useDrag({
    ref: draggableRef
  });

  return (
    <div>
      <button onClick={toggleTextLayer}>
        {showTextLayer ? 'Disable Text Layer' : 'Enable Text Layer'}
      </button>
      {pdfDoc && (<div><Document file={pdfDoc} onLoadSuccess={onDocumentLoadSuccess}>
        
        <Page pageNumber={pageNumber} renderTextLayer={false}>
        <div
        className="draggable"
        ref={draggableRef}
        style={{
          top: position.y,
          left: position.x
        }}
      >
        <div className="draggable-panel" onMouseDown={handleMouseDown}>
          Draggable panel
        </div>
        <div className="draggable-content">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book
        </div>
      </div>
          {/* <div className="pdf-text-layer" style={{ position: 'absolute', top: 0, left: 0 }}> */}
            {showTextLayer && renderTextElements()}
          {/* </div> */}
        </Page>
        
      </Document>
      <Button variant="contained" onClick={toggleModal}>
        Sign
      </Button>
      <p>
        Page {pageNumber} of {numPages}
      </p></div>)}
      <Dialog
        open={showModal}
        aria-labelledby="dialog-title"
        PaperProps={{
          style: {
            margin: "auto",
            width: "40%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <Typography variant="h5" align="center">
            Signature
          </Typography>
          <IconButton
            onClick={toggleModal}
            aria-label="close"
            color="inherit"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            paddingTop: "0px",
            height: "100%",
            width: "100%",
            alignContent: "center",
            align: "center",
          }}
        >
          <SignatureCanvas
            ref={padRef}
            style={{ position: "relative" }}
            canvasProps={{
              style: {
                height: "90%",
                width: "90%",
                border: "1px solid black",
                padding: 0,
                margin: "auto",
                display: "block",
              },
              className: "sigPad",
            }}
          />
        </DialogContent>
        <Button
          variant="contained"
          onClick={() => padRef.current.clear()}
          style={{
            backgroundColor: "white",
            color: "grey",
          }}
        >
          Clear
        </Button>
        <Box style={{ width: "30px" }}></Box>
        <Button
          variant="contained"
          onClick={handleSave}
          style={{
            backgroundColor: "green",
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          onClick={handleGetMostRecent}
          style={{
            backgroundColor: "green",
          }}
        >
          Get Most Recent Signature
        </Button>
      </Dialog>
    </div>
  );

  return (
    <div>
      {pdfDoc && (<div><Document file={pdfDoc} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} renderTextLayer={false}/>
      </Document>
      <Button variant="contained" onClick={toggleModal}>
        Sign
      </Button>
      <p>
        Page {pageNumber} of {numPages}
      </p></div>)}
      <Dialog
        open={showModal}
        aria-labelledby="dialog-title"
        PaperProps={{
          style: {
            margin: "auto",
            width: "40%",
            height: "50%",
          },
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>
          <Typography variant="h5" align="center">
            Signature
          </Typography>
          <IconButton
            onClick={toggleModal}
            aria-label="close"
            color="inherit"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            paddingTop: "0px",
            height: "100%",
            width: "100%",
            alignContent: "center",
            align: "center",
          }}
        >
          <SignatureCanvas
            ref={padRef}
            style={{ position: "relative" }}
            canvasProps={{
              style: {
                height: "90%",
                width: "90%",
                border: "1px solid black",
                padding: 0,
                margin: "auto",
                display: "block",
              },
              className: "sigPad",
            }}
          />
        </DialogContent>
        <Button
          variant="contained"
          onClick={() => padRef.current.clear()}
          style={{
            backgroundColor: "white",
            color: "grey",
          }}
        >
          Clear
        </Button>
        <Box style={{ width: "30px" }}></Box>
        <Button
          variant="contained"
          onClick={handleSave}
          style={{
            backgroundColor: "green",
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          onClick={handleGetMostRecent}
          style={{
            backgroundColor: "green",
          }}
        >
          Get Most Recent Signature
        </Button>
      </Dialog>
    </div>
  );

  // const handleClear = () => {
  //   canvasRef.current.clear();
  // };
  // return (
  //   <div style={{ border: "1px solid black", padding: "10px" }}>
  //     <SignatureCanvas
  //       penColor="black"
  //       canvasProps={{
  //         width: 500,
  //         height: 200,
  //         style: { border: "1px solid black" },
  //       }}
  //       ref={canvasRef}
  //       style={{ position: "relative" }}
  //     />
  //     <br />
  //     <button onClick={handleClear}>Clear</button>
  //   </div>
  // );
}


// -----------------------------------------------------------------------------
// import { useEffect, useRef } from "react";

// export default function PdfViewerComponent(props) {
//   const containerRef = useRef(null);

//   useEffect(() => {
//     const container = containerRef.current;
//     let PSPDFKit;

//     (async function () {
//       PSPDFKit = await import("pspdfkit");
//       const pdfResponse = await fetch('https://fieldworkblob.blob.core.windows.net/fieldworker/9000570.pdf');
//       const documentBuffer = await pdfResponse.arrayBuffer();
//       await PSPDFKit.load({
//         // Container where PSPDFKit should be mounted.
//         container,
//         // The document to open.
//         document: documentBuffer,
//         baseUrl :`${window.location.protocol}//${window.location.host}/assets/`
//         // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
//       });
//     })();

//     return () => PSPDFKit && PSPDFKit.unload(container);
//   }, [props.document]);

//   return <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />;
// }