import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const DurationPicker = (props) => {
    const value = props.value;
    const [state, setState] = useState('');
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleMenuItemClick = (event, index) => {
        let minutes = index * 15
        let formated  = (Array(5).join("0") + Math.floor(minutes / 60) + ":" + (minutes % 60 < 10 ? "0" : "") + minutes % 60).slice(-5)
        props.onChange(formated)
        setState(formated)
        setAnchorEl(null);
      };

    let onTextInput = (e) => {
        if(e.target.value.length === 0) {
            props.onChange('')
            setState('')
            return
        }

        if(!/^[0-9:]{0,5}$/.test(e.target.value) || (e.target.value.length > 2 && !e.target.value.includes(':'))){
            e.target.value = e.target.value.slice(0, -1);
            return
        }

        if(/^\d?\d?:\d\d$/.test(e.target.value)) {
            props.onChange(e.target.value.padStart(5, 0))
            setState(e.target.value.padStart(5, 0))
        }else {
            props.onChange(e.target.value)
            setState(e.target.value)
        }

    }
    return (
        <Box>
            <TextField 
                label="DURATION"
                style={props.style}
                className={props.className || props.class}
                value={value}
                placeholder="hh:mm"
                onChange={onTextInput}
                InputProps={{
                    endAdornment: 
                        <InputAdornment position="end">
                            <IconButton onClick={handleClick} sx={{ width: "28px", height: "28px" }}>
                                <ArrowDropDownIcon  />
                            </IconButton>
                        </InputAdornment>,
                    sx: { paddingRight: 1 }
                }}
            /> 
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                style={props.style}
                PaperProps={{
                    style: {
                      maxHeight: 300,
                      width: "auto",
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
            {
                [...Array(48+1).keys()].slice(1).map((i) => {
                    let minutes = i * 15;
                    return <MenuItem onClick={(event) => handleMenuItemClick(event, i)}>{(Array(5).join("0") + Math.floor(minutes / 60) + ":" + (minutes % 60 < 10 ? "0" : "") + minutes % 60).slice(-5)}</MenuItem>
                })
            }
            </Menu>
        </Box>
    )
}

export default DurationPicker;
