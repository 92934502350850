import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Typography from '@mui/material/Typography';

const UnlinkConfirmation = (props) => {

    return (
        <Modal show={props.show} onHide={props.onClose} centered>
            <Modal.Body style={{ margin: "20px 0" }}>
                <Typography>Are you sure you want to unlink this task</Typography>
            </Modal.Body>
            <Modal.Footer style={{borderTop: "0 none", paddingTop: '0', justifyContent: "space-around"}}>
            <Button style={{width: 75}} variant="primary" onClick={() => {props.onUnlink();props.onClose()}}>Yes</Button>
            <Button style={{width: 75}} variant="secondary" onClick={props.onClose}>No</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UnlinkConfirmation