import React from "react";
import NotificationTile from './NotificationTile'

class NotifDetails extends React.Component{


    render(){
        return (
            <div style={{width: 470}}>
            <div className='d-flex flex-row justify-content-between m-3'>
                <p> <strong>{this.props.selected.notif_text}</strong></p>
                <i onClick={()=>{this.props.viewOneNotif(null)}} style={{cursor:'pointer'}} className="fas fa-arrow-alt-circle-left"></i>
            </div>
            <NotificationTile data={this.props.selected}/>
            </div>
            
        )
    }
}

export default NotifDetails