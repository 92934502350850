import React, { Component } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import axios from 'axios';
import moment from "moment";
import { APIURL } from '../../../config'
import {Card, CardActions, CardContent, IconButton, Dialog, DialogActions, DialogContent, Typography} from '@mui/material'
import { NavLink } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close';
import ProjectForm from '../../../components/forms/ProjectForm/ProjectForm';

class Projects extends Component {
    state = {
        selectedTab: 0,
        searchTerm: '',
        tabs: ['Ongoing', 'Completed'],
        onGoingProjs: [],
        completedProjs: [],
        loading: true,
        displayProjects: [],
        content: [],
        contextMenu: 0,
        newProjLoading: true,
        employees: {
            list: [{}],
            empType: [],
            empLevel: [],
            empStatus: [],
            currency: [],
            frequency: [],
        },
        customers: { list: [{}], customerType: [], customerStatus: [] },
        locations: { list: [{}], states: [], locType: [], locStatus: [] },
        mapRender: "proj",
    }

    async fetchData() {
        const response = await axios.get(APIURL + `/project`, { params: { cus_id: this.props.cus_id } })
        let projectsData = response.data

        let projects = await Promise.all(projectsData.map(async (project) => {
            let completedTaskData = await axios.get(APIURL + '/locate/TimeSpent', { params: { projId: project.pro_id, comp_id: this.props.comp_id } })

            return { ...project, taskData: completedTaskData.data }
        }))
        console.log(projects)

        this.setState({ displayProjects: projects })
        this.generateProjCards(this.state.displayProjects)
        this.setState({ loading: false })
    }

    async fetchNewProjData() {
        let data = {
            employees: {
                list: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/employeeslist", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
                empType: [],
                empLevel: [],
                empStatus: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/empStatus", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
                currency: [],
                frequency: (
                    await axios.get(APIURL + "/config/defaults", {
                        params: { feature_type: "payrollFrequency" },
                    })
                ).data,
            },
            customers: {
                list: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/customerslist", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
                customerType: [],
                customerStatus: [],
            },
            locations: {
                list: (
                    await axios.get(APIURL + "/dropdowns/dropdownType/locationslist", {
                        params: { comp_id: this.props.comp_id },
                    })
                ).data,
                states: [],
                locType: [],
                locStatus: [],
            },
        };

        this.setState({ ...data });
        this.setState({ newProjLoading: false });
    }

    componentDidMount = () => {
        this.fetchData()
        this.fetchNewProjData()
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.cus_id !== this.props.cus_id) {
            this.fetchData()
        }

        // if (this.state.contextMenu === 1 && !!this.state.newProjLoading) {
        //     await this.fetchNewProjData()
        // }
    }

    generateProjCard = (project, i) => {
        let phoneNumbers = []
        let addresses = []
        try {
            phoneNumbers = this.props.cust_details.cus_phone.split(',')
            addresses = this.props.cust_details.cus_address.split(':')
        } catch (err) {
            console.log(err)
        }
        let timeSpent = this.calCumulativeTimeSpent(project.taskData)
        let taskCount = project.taskData.length

        return (
            <Card key={i} sx={{ marginTop: '20px' }}>
                <CardActions sx={{ paddingLeft: '16px', marginBottom: '10px' }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ textDecoration: 'underline', marginBottom: '5px' }}>
                            <NavLink to={{
                                pathname: '/projects',
                                // projectsProps: {
                                //     proj_id: project.pro_id,
                                //     comp_id: this.props.comp_id, 
                                //     edit: false
                                // }
                            }} className='navlink_name_color'>{project.pro_title}</NavLink>
                        </div>
                        { project.pro_starts_on && project.pro_ends_on && moment(project.pro_starts_on).isAfter("1900-01-01") && moment(project.pro_ends_on).isAfter("1900-01-01") ?
                            <div className="fa fa-calendar text-muted"> {moment(project.pro_starts_on).format('MMMM DD, YYYY')} - {moment(project.pro_ends_on).format('MMMM DD, YYYY')}</div> :
                            <div className="fa fa-calendar text-muted"> Initial Plan</div>
                        }
                    </div>
                </CardActions>
                <CardContent sx={{ paddingBottom: '0px', paddingTop: '0px' }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="text-muted">TIME SPENT ON TASKS
                            <div style={{ color: 'black' }}>{timeSpent}</div>
                            <div style={{ color: 'black' }}>spent on {taskCount} tasks</div>
                        </div>
                        <div className="text-muted">PHONE NUMBER
                            <div className="d-flex flex-column">
                                {
                                    phoneNumbers.map((number, i) => {
                                        return <Typography key={i}><i></i><span>{number}</span></Typography>
                                    })
                                }
                            </div>
                        </div>
                        <div className="text-muted">LOCATION
                            <div className="d-flex flex-column">
                                {
                                    addresses.map((address, i) => {
                                        return <Typography key={i}><i></i><span>{address}</span></Typography>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }

    generateProjCards = (projList) => {
        let onGoing = projList.filter((project) => moment(project.pro_ends_on).isSameOrAfter(new Date()) || (!project.pro_starts_on || !project.pro_ends_on || moment(project.pro_starts_on).isSameOrBefore("1900-01-01")))
        let completed = projList.filter((project) => moment(project.pro_ends_on).isBefore(new Date()) && (!!project.pro_starts_on && !!project.pro_ends_on && moment(project.pro_starts_on).isAfter("1900-01-01")))

        let onGoingCards = []
        onGoingCards = onGoing.map((project, i) => {
            return this.generateProjCard(project, i)
        })
        let completedCards = []
        completedCards = completed.map((project, i) => {
            return this.generateProjCard(project, i)
        })
        this.setState({ onGoingProjs: onGoingCards, completedProjs: completedCards })
    }

    calCumulativeTimeSpent = (data) => {
        var TotalTime = moment()
        data.forEach((taskData) => {
            var CheckInTime = moment(taskData.CheckInTime)
            var CheckoutTime = moment(taskData.CheckoutTime)
            var taskTime = moment.duration(CheckoutTime.diff(CheckInTime))
            TotalTime.add(taskTime)
        })
        return moment.duration(TotalTime.diff(moment())).humanize()
    }

    selectTaskTab(tabIndex) {
        this.setState({
            selectedTab: tabIndex
        })
    }

    navigationHandler = (contextMenu) => {
        this.setState({ contextMenu })
    }

    searchProjHandler = (e) => {
        var searchQuery = e.target.value
        console.log(searchQuery)
        this.setState({ searchTerm: e.target.value })
    }

    searchProjResults = (e) => {
        e.preventDefault()
        var searchTerm = new RegExp(this.state.searchTerm, 'i')
        console.log(searchTerm)
        
        let projSearchResults = this.state.displayProjects.map((proj) => {
            if (searchTerm.exec(proj.pro_title)) {
                return proj
            }
            else {
                return null
            }
        }).filter((proj) => !!proj)

        this.setState({ displayProjects: projSearchResults })
    }

    render() {
        let content = null;
        if (this.state.contextMenu === 1) {
            content =
                <Dialog fullWidth={true} maxWidth={'sm'} open={this.state.contextMenu === 1} onClose={() => this.navigationHandler(0)}>
                    <DialogActions>
                        <h4 style={{ marginLeft: '20px', marginTop: '10px' }}>New Project</h4>
                        <hr style={{ marginBottom: '10px', marginTop: '10px', backgroundColor: '#e2ecfa' }}></hr>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            sx={{ p: 0.25 }}
                            onClick={() => this.navigationHandler(0)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogActions>
                    <DialogContent sx={{ paddingTop: '0px' }}>
                        {!!this.state.newProjLoading &&
                            <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!this.state.newProjLoading && <ProjectForm
                            {...this.props}
                            isFormValidated={this.props.isNewFormValidated}
                            mapRender={this.state.mapRender}
                            customerList={this.state.customers.list}
                            empList={this.state.employees.list}
                            // contactList={this.state.contacts.list}
                            locList={this.state.locations.list}
                            empStatus={this.state.employees.empStatus}
                            currency={this.state.employees.currency}
                            frequency={this.state.employees.frequency}
                            comp_id={this.props.comp_id}
                            customerPage={true}
                        />}
                    </DialogContent>
                </Dialog>
        }
        else if (this.state.contextMenu === 0) {
            content = (
                <div className="TasksPage">
                    <div className="TopPanel">
                        <div style={{ marginRight: '60px' }}>
                            <Form inline style={{ width: '200%' }} onSubmit={(e)=> this.searchProjResults(e)}>
                                <FormControl onChange={(e)=> this.searchProjHandler(e)} value={this.state.searchTerm} type="text" placeholder="Search" className="form-control w-50 mr-1" />
                                <Button type="submit" variant="outline-primary" style={{ border: "solid 1px #95D0EB", marginLeft: "-5px", borderRadius: '0 5px 5px 0', paddingRight: "15px", paddingLeft: "15px" }}><i className="fas fa-search"></i></Button>
                            </Form>
                        </div>
                        <div>
                            <Button onClick={() => this.navigationHandler(1)}>+ Add Project</Button>
                        </div>
                    </div>
                    <div className='tasks_info'>
                        <div>
                            <h5>Projects</h5>
                        </div>
                        <div className='task_button_group'>
                            <div style={{ opacity: '90%' }} className={`project_button ${this.state.tabs[this.state.selectedTab] === 'Ongoing' ? 'active' : 'inactive'}`} onClick={() => this.selectTaskTab(0)}>
                                <div>On Going</div>
                                {this.state.tabs[this.state.selectedTab] === 'Ongoing' && <div className='project_button_number'>{this.state.onGoingProjs.length}</div>}
                            </div>
                            <div style={{ opacity: '90%' }} className={`project_button ${this.state.tabs[this.state.selectedTab] === 'Completed' ? 'active' : 'inactive'}`} onClick={() => this.selectTaskTab(1)}>
                                <div>Completed</div>
                                {this.state.tabs[this.state.selectedTab] === 'Completed' && <div className='project_button_number'>{this.state.completedProjs.length}</div>}
                            </div>
                        </div>
                        {!!this.state.loading &&
                            <div className='d-flex flex-row w-100 justify-content-center mt-5'>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {!this.state.loading && this.state.selectedTab === 0 && this.state.onGoingProjs.length === 0 &&
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '500px', marginTop: 10 }}>
                                <img src={'./nosearchresult.png'} alt='projectgraphic' style={{ width: '15rem', height: '10rem', margin: 10 }} />
                                <h5 style={{ fontFamily: 'Lato', marginTop: 48, marginBottom: 30 }} className='text-center'>No Projects Found</h5>
                            </div>
                        }
                        {!this.state.loading && this.state.selectedTab === 1 && this.state.completedProjs.length === 0 &&
                            <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '500px', marginTop: 10 }}>
                                <img src={'./nosearchresult.png'} alt='projectgraphic' style={{ width: '15rem', height: '10rem', margin: 10 }} />
                                <h5 style={{ fontFamily: 'Lato', marginTop: 48, marginBottom: 30 }} className='text-center'>No Projects Found</h5>
                            </div>
                        }
                        <div className="taskCardList">
                            {this.state.selectedTab === 0 ? this.state.onGoingProjs : this.state.completedProjs}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                {content}
            </div>
        )
    }
}

export default Projects;