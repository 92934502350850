import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import moment from 'moment'
import { Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Record.css'
import { getByTitle } from '@testing-library/dom';
import { alpha } from '@mui/material/styles'

const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

const columns = [
    {
        field: 'log_type',
        headerName: 'Log Type',
        width: 125,
        hide: false
    },
    {
        field: 'tas_title',
        headerName: 'Activity/CareGiver Name',
        width: 250,
        hide: false
    },
    {
        field: 'tas_type',
        headerName: 'Activity Type',
        width: 150,
        hide: false
    },
    {
        field: 'pro_title',
        headerName: 'Project Name',
        width: 250,
        hide: false
    },
    {
        field: 'errors',
        headerName: 'Error',
        width: 700,
        hide: false,
        renderCell : (params) =>{
            let val = JSON.parse(params.value)
              // console.log(val)
            let tobeDisplayed = Array.isArray(val)?val.map((text, i)=> {
              return <p style={{lineHeight: 1}} className='text-wrap mb-0' key={text.message + Math.random()}><span style={{marginRight: 10}} className="badge bg-secondary text-white">{++i}</span>{text.message}</p>
            }):[]
          return (
              // <p className='m-2'>{val.length === 0?'-':val[0].message}</p>
              <div style={{height: 70, width: '90%'}} className='d-flex flex-column overflow-auto'>
                  {tobeDisplayed.length === 0?'-':tobeDisplayed}
              </div>
        )}
    },
    {
        field: 'sched_start_date',
        headerName: 'Scheduled Start Time',
        width: 250,
        hide: false,
        sortComparator: dateComparator
    },
    {
        field: 'sched_end_date',
        headerName: 'Scheduled End Time',
        width: 250,
        hide: false,
        sortComparator: dateComparator
    },
    {
        field: 'start_date',
        headerName: 'Start Date/Time',
        width: 250,
        hide: false,
        sortComparator: dateComparator
    },
    {
        field: 'end_date',
        headerName: 'End Date/Time',
        width: 250,
        hide: false,
        sortComparator: dateComparator
    },
    {
        field: 'evvmsid',
        headerName: 'EVV MSID',
        //   width: 150,
        width: 250,
        hide: true,
    },
    {
        field: 'responseCode',
        headerName: 'Response Code',
        // width: 250,
        width: 125,
        hide: true
    },
    {
        field: 'transactionId',
        headerName: 'transaction Id',
        //   width: 250,
        width: 250,
        hide: true,
    },
    {
        field: 'version',
        headerName: 'Version',
        // width: 200,
        width: 125,
        hide: true
    },
    {
        field: 'evvId',
        headerName: 'evvID',
        // width: 250,
        width: 125,
        hide: true
    },
    {
        field: 'invTimestamp',
        headerName: 'Invocation Timestamp',
        width: 250,
        hide: true,
        renderCell : (params) =>{
        return (
            !!params.value?moment(params.value).add(moment().utcOffset(), 'minutes').format('MM-DD-YYYY, HH:mm '):'-'
        )},
        sortComparator: dateComparator
    },
];

export default function EvvRecord_v2(props) {

    const [renderTable, setRenderTable] = useState(false);

    useEffect(() => {
        
    }, []);

    var rows = [];
    if(!!props.list.evvLogList){
        rows = props.list.evvLogList.map((log, index) => {
            let filteredTasks = props.tasks.filter(t => t != undefined)
            
            try{
                return {
                    id: index,
                    log_type: !!log.externalVisitId ? 'Visit' : 'Caregiver',
                    tas_title: filteredTasks.length > 0 ? (!!log.externalVisitId ? filteredTasks.find(t => t.tas_id === log.externalVisitId).tas_title : log.emp_name) : '-',
                    tas_type: filteredTasks.length > 0 ? (!!log.externalVisitId ? filteredTasks.find(t => t.tas_id === log.externalVisitId).tas_type : '-') : '-',
                    pro_title: filteredTasks.length > 0 ? (!!log.externalVisitId ? filteredTasks.find(t => t.tas_id === log.externalVisitId).pro_title : '-') : '-',
                    errors: !!log.errors ? log.errors : [],
                    sched_start_date: filteredTasks.length > 0 ? (!!log.externalVisitId ? moment(filteredTasks.find(t => t.tas_id === log.externalVisitId).tas_start_time, "YYYY-MM-DDTHH:mm:ss").format('LLL') : '-') : '-',
                    sched_end_date: filteredTasks.length > 0 ? (!!log.externalVisitId ? moment(filteredTasks.find(t => t.tas_id === log.externalVisitId).tas_end_time, "YYYY-MM-DDTHH:mm:ss").format('LLL') : '-') : '-',
                    start_date: filteredTasks.length > 0 ? (!!log.externalVisitId ? moment(filteredTasks.find(t => t.tas_id === log.externalVisitId).empLocdate, "YYYY-MM-DDTHH:mm:ss").format('LLL') : '-') : '-',
                    end_date: filteredTasks.length > 0 ? (!!log.externalVisitId ? moment(filteredTasks.find(t => t.tas_id === log.externalVisitId).checkoutEmpDate, "YYYY-MM-DDTHH:mm:ss").format('LLL') : '-') : '-',
                    evvmsid: !!log.evvmsid ? log.evvmsid : '-',
                    responseCode: !!log.responseCode ? log.responseCode : '-',
                    transactionId: !!log.transactionId ? log.transactionId : '-',
                    version: !!log.version ? log.version : '-',
                    evvId: !!log.evvId ? log.evvId : '-',
                    invTimestamp: !!log.invocationTimestamp ? log.invocationTimestamp : '-',
                }
            } catch {
                return {
                    id: index,
                };
            }
        })
    }
    
    function getTitle(){
        let status = <p></p>;
        let numberSummary = <p>CareGiver Records Sent: {props.list.noOfCareGiverRecordsPicked} | CareGiver Records Success: {props.list.noOfCareGiverRecordsSent} | CareGiver Records Failures: {props.list.noOfFailureCareGiverRecords} | Visit Records: {props.list.noOfVisitRecordsPicked} | Visit Records Success: {props.list.noOfSuccessVisitRecords} | Visit Records Failures: {props.list.noOfFailureVisitRecords}</p>
        
        let complete = 1;
        if(!!props.list.evvLogList){
            complete = props.list.evvLogList.find(l => !(l.evvmsid === 'null' && l.evvmsid != 'N/A (Caregiver)'))
        }
        
        if(props.list.error != ''){
            status =<b><span style={{float: 'right'}}><i className='fas fa-exclamation-circle mr-2' style={{color: 'red'}}></i>ERROR: {props.list.error}</span></b>
        } else if(props.list.evvLogList === null){
            status =<b><span style={{float: 'right'}}> <i className='fas fa-times mr-2' style={{color: 'grey'}}></i>THERE WERE NO RECORDS TO SEND</span></b>
        } else if(complete === undefined){
            status =<b><span style={{float: 'right'}}> <i className='fas fa-check-circle mr-2' style={{color: 'green'}}></i>SUCCESSFUL EVV SUBMISSION</span></b>
        } else if(props.list.evvLogList != null){
            status =<b><span style={{float: 'right'}}> <i className='fas fa-question-circle mr-2' style={{color: '#ffa701'}}></i>INCOMPLETE SUBMISSIONS</span></b>
        }

        return(
            <div>
                <b>INVOCATION TIMESTAMP: {moment(props.list.invocationTimestamp).format('LLL')}</b>
                {status}
                {numberSummary}
            </div>
        )
    }

    const onToggle = (expanded) => {
        if(expanded){
            setRenderTable(true);
        } else {
            setRenderTable(false);
        }
    }

    return(
        <div style={{width: '95%', margin: 'auto', marginBottom: '20px'}}>
            <Accordion onChange={(event, expanded) => onToggle(expanded)} className={'evvLog-accordian'}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {getTitle()}
                </AccordionSummary>
                <AccordionDetails>
                    {renderTable && 
                        <DataGrid
                        rows={rows}
                        columns={columns}
                        density='compact'
                        pageSize={5}
                        rowHeight={110}
                        autoHeight={true}
                        rowsPerPageOptions={[5]}
                        disableSelectionOnClick
                        showToolbar components={{Toolbar: GridToolbar, color: '#3fadf6'}}
                        />
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    );
}