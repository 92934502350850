import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Select, MenuItem, FormControl, InputLabel, Autocomplete, Grid, ListItemText } from '@mui/material';
import axios from 'axios';
import { APIURL } from '../../../config';
import moment from 'moment';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import IconButton from '@mui/material/IconButton';
import CheckoutForms from '../../../components/Checkout/CheckoutForms';

const TaskNotesModal = (props) => {
    const [note, setNote] = useState('');
    const [taskId, setTaskId] = useState('');
    const [showCusForms, setShowCusForms] = useState(false);
    const [forms, setForms] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    
    const handleCusFormsClose = () => {
        setShowCusForms(false);
    }

    const handleNoteChange = (e) => {
        setNote(e.target.value)
    }

    useEffect(() => {
        if (props.open) {
            getTask();
            getForms();
        } else {
            setNote('');
        }
    }, [props.open])

    const getTask = async () => {
        try {
            const res = await axios.get(`${APIURL}/task/session`, {
                params: {
                    tas_type: props.schedule.task_type,
                    tas_title: props.schedule.schedule_name,
                    tas_start_date: moment(props.schedule.start_time).format('YYYY-MM-DD HH:mm:ss'),
                    tas_end_date: moment(props.schedule.end_time).format('YYYY-MM-DD HH:mm:ss'),
                    tas_com_id: props.comp_id,
                    cus_id: props.customer.cus_id
                }
            })
            console.log(res.data);
            setNote(res.data.data?.tas_notes)
            setTaskId(res.data.data?.tas_id)
        } catch (error) {
            props.onError("Error fetching task notes")
            console.log(error)
        }
    }

    const getForms = async () => {
        try {
            let taskTypes = await axios.get(`${APIURL}/dropdowns/dropdownType/fullTaskType`, { params: { comp_id: props.comp_id } })
            let taskTypeId = taskTypes.data.filter((type) => type.EntityValue === props.schedule.task_type)[0].ddID
            let forms = await axios.get(`${APIURL}/task_form`, { params: { taskType_id: taskTypeId, company_id: props.comp_id } })
            setForms(forms.data)
        } catch (error) {
            props.onError("Error fetching task forms")
            console.log(error)
        }
    }

    const handleSave = async () => {
        try {
            const res = await axios.put(`${APIURL}/task/notes`, {
                tas_id: taskId,
                tas_notes: note
            })
            props.onSuccess("Task notes updated successfully")
            props.onClose()
        } catch (error) {
            props.onError("Error updating task notes")
            console.log(error)
        }
    }

    return (
        <Box>
            <Modal
                open={props.open}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                            Task Notes
                        </Typography>
                        <IconButton onClick={() => setShowCusForms(true)}>
                            <FormatListBulletedIcon />
                        </IconButton>
                    </Box>
                    <TextField
                        id="outlined-multiline-static"
                        label="Notes"
                        multiline
                        rows={6}
                        value={note}
                        onChange={handleNoteChange}
                        fullWidth
                    />
                    <Button fullWidth onClick={() => handleSave()} variant='contained' sx={{ mt: 2 }}>Save</Button>
                </Box>
            </Modal>
            <Modal
                open={showCusForms}
                onClose={handleCusFormsClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
                        Forms
                    </Typography>
                    <CheckoutForms 
                        forms={forms}
                        onSubmit={() => {}}
                        onEdit={() => {}}
                        customer_id={props.customer?.cus_id}
                        task_id={taskId}
                        selectedForm={selectedForm}
                        setSelectedForm={setSelectedForm}
                        user_id={props.user.emp_id}
                    />
                </Box>
            </Modal>
        </Box>
    )
}

export default TaskNotesModal;
        
