import { useState, useEffect, memo } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import lodash from "lodash";

const ControlledInput = (props) => {
    const [value, setValue] = useState(null);
    const [error, setError] = useState(false);
    const [read, setRead] = useState(true);

    useEffect(() => {
        if(props.options !== undefined)
            props.options.map((option, index) => {
                if(option.value === props.value) {
                    setValue(option.value);
                }
            })
    }, [props.value]);

    useEffect(() => {
        setError(props.error);
    }, [props.error]);
    
    useEffect(() => {
        setRead(props.read);
    }, [props.read]);

    useEffect(() => {
        if(read !== true) {
            if(props.disabled === true) {
                setValue("");
                if(props.onChange !== undefined) props.onChange(null);
                if(!isValid(value)) setError(false);
            } else {
                if(!isValid(props.value)) {
                    if(props.dependentValue)
                        props.onChange(props.dependentValue);
                }
                setError(true);
            }
        }
    }, [props.disabled]);

    useEffect(() => {
        if(props.setSectionError !== undefined && props.section !== undefined) 
            error ? props.setSectionError((prev) => { 
                let newPrev = [...prev];
                newPrev[props.section].push('select');
                return newPrev;
            }) : 
            props.setSectionError((prev) => {
                let newPrev = [...prev];
                const index = newPrev[props.section].indexOf('select');
                if (index > -1) {
                    newPrev[props.section].splice(index, 1);
                }
                return newPrev;
            });
    }, [error]);

    useEffect(() => {
        if(!props.disabled) 
            setError(!isValid(value));
    }, [value, props.validate]);

    const isValid = (value) => {
        return !(value === null || value === "");
    }

    return (
        <>
        {props.value !== undefined ? 
            <FormControl error={props.error} sx={{...props.sx}}>
                <Select 
                    error={error && props.validate && !props.disabled}
                    inputProps={{...props.inputProps}}
                    value={value}
                    variant="standard" 
                    onChange={e => {setValue(e.target.value);props.onChange(e.target.value)}} 
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                >
                    {props.options?.map((option, index) => {
                        return (
                            <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                        );
                    })}
                </Select>
            </FormControl> :
            <></>
        }
        </>
        
    );
}

export default memo(ControlledInput,
    (prevProps, nextProps) => {
        if(lodash.isEqual(prevProps.sx, nextProps.sx) && lodash.isEqual(prevProps.inputProps, nextProps.inputProps) && lodash.isEqual(prevProps.value, nextProps.value) && lodash.isEqual(prevProps.disabled, nextProps.disabled) 
        && prevProps.onChange == nextProps.onChange && lodash.isEqual(prevProps.placeholder, nextProps.placeholder) && lodash.isEqual(prevProps.options, nextProps.options)
        && lodash.isEqual(prevProps.error, nextProps.error) && lodash.isEqual(prevProps.validate, nextProps.validate) && lodash.isEqual(prevProps.validQuestions, nextProps.validQuestions) && lodash.isEqual(prevProps.setValidQuestions, nextProps.setValidQuestions) && lodash.isEqual(prevProps.setSectionError, nextProps.setSectionError) && lodash.isEqual(prevProps.section, nextProps.section)) {
            return true;
        } else {
            return false;
        }
    }
);
