import React from "react";
import { createRef, useImperativeHandle } from "react";
import axios from "axios";
import { APIURL } from "../../config";
import moment from "moment";
import { jsonToCSV } from "react-papaparse";
import { CSVDownloader } from "react-papaparse";
import Tooltip from "@mui/material/Tooltip";
import { Grid, Typography, Box } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import { Button } from "react-bootstrap";
import TimesheetFilter from "./TimesheetFilter";
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';
import { AnalyticsBrowser } from '@segment/analytics-next'
import { SEGMENTAPIKEY } from '../../config';

const analytics = AnalyticsBrowser.load({ writeKey: SEGMENTAPIKEY })

const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

const columns = [
  {
    field: "TaskcreateDate",
    headerName: "Date Entered",
    flex: 1.0,
    renderCell: (params) => {
      return (
        <div>{moment(params.value).isValid() ? moment(params.value).local().format('MM/DD/YYYY') : "-"}</div>
      )
    },
  },
  {
    field: "Project",
    headerName: "Project",
    flex: 1.3,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span
            className="table-cell-trucate text-wrap"
            style={{ lineHeight: "1" }}
          >
            {params.value}
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: "TaskName",
    headerName: "Task Name",
    flex: 1.3,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span
            className="table-cell-trucate text-wrap"
            style={{ lineHeight: "1" }}
          >
            {params.value}
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: "TaskType",
    headerName: "Type",
    flex: 1.0,
  },
  {
    field: "FirstName",
    headerName: "Employee First Name",
    flex: 1.5,
    hide: true,
  },
  {
    field: "LastName",
    headerName: "Employee Last Name",
    flex: 1.5,
    hide: true,
  },
  {
    field: "empName",
    headerName: "Employee Name",
    flex: 1.5,
  },
  {
    field: "RecActivityDate",
    headerName: "Activity Date",
    flex: 1.0,
    sortComparator: dateComparator,
  },
  {
    field: "RecStartTime",
    headerName: "Start Time",
    flex: 1.0,
  },
  {
    field: "RecEndTime",
    headerName: "End Time",
    flex: 1.0,
  },
  {
    field: "WeeklyHrs",
    headerName: "Hours",
    flex: 0.53,
    sortComparator: (v1, v2) => {
      return parseFloat(v1) - parseFloat(v2);
    },
  },
  {
    field: "TaskNotes",
    headerName: "Comments",
    flex: 2.0,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span
            className="truncate-text"
            style={{
              lineHeight: "1",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              alignItems: "center",
            }}
          >
            {params.value}{" "}
          </span>
        </Tooltip>
      );
    },
  },
  {
    field: "CaseNotes",
    headerName: "Notes",
    flex: 2.0,
    renderCell: (params) => {
      return (
        <Tooltip title={params.value}>
          <span
            className="truncate-text"
            style={{
              lineHeight: "1",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              alignItems: "center",
            }}
          >
            {params.value}{" "}
          </span>
        </Tooltip>
      );
    },
  },
];

class Timesheet_Report extends React.Component {
  constructor(props) {
    super(props);
    this.apiRef = createRef();
  }

  state = {
    gridId: 45,
    loading: true,
    TimesheetsReports: [],
    isCustomReportOpen: false,
    columns: columns,
    filterResults: {},
    filterResultsOutput: "",
    loggedToSegment: false,
    page: 0,
    page_size: 50,
    count: 0,
  };

  logToSegment = async () => {
    if (this.state.loggedToSegment) {
      return;
    }
    let page = String(window.location.href).split("/").pop()
    if (page.includes("fieldworker") || page === "" || page === "#") {
      page = "Home";
    } else {
      page = String(page).charAt(0).toUpperCase() + String(page).toLowerCase().slice(1);
    }

    analytics.page(page + " : Timesheets Report", {
      title: page + " : Timesheets Report",
      path: page + " : Timesheets Report",
    }, {
      userId: String(this.props.user.emp_id),
    })

    this.setState({ loggedToSegment: true });
  }

  async componentDidMount() {
    let timesheets_reports = await axios.get(APIURL + "/reports/getTimesheets", {
      params: { comp_id: this.props.comp_id, emp_id: this.props.user.emp_id, emp_type: this.props.user.type, page: this.state.page, page_size: this.state.page_size },
    });

    this.logToSegment();
    this.setState({
      TimesheetsReports: timesheets_reports.data.timesheetsData,
      count: timesheets_reports.data.count,
      loading: false,
    });
    localStorage.removeItem("timesheetFilterData");
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page || prevState.filterResults !== this.state.filterResults) {
      let timesheets_reports = await axios.get(APIURL + "/reports/getTimesheets", {
        params: { comp_id: this.props.comp_id, emp_id: this.props.user.emp_id, emp_type: this.props.user.type, page: this.state.page, page_size: this.state.page_size, customFilter: this.state.filterResults },
      });

      this.setState({
        TimesheetsReports: timesheets_reports.data.timesheetsData,
        count: timesheets_reports.data.count,
      });
    }
  }

  getRowsData = () => {
    let arr = [];
    this.state.TimesheetsReports.map((TimesheetsReportsarray) => {
      let row = {
        id: TimesheetsReportsarray.id,
        emp_id: TimesheetsReportsarray.emp_id,
        FirstName: TimesheetsReportsarray.emp_first_name,
        LastName: TimesheetsReportsarray.emp_last_name,
        EmpTitle: !!TimesheetsReportsarray.paymentDets
          ? TimesheetsReportsarray.paymentDets.pea_status
          : "N/A",
        EmpType: TimesheetsReportsarray.emp_type,
        ProjectTime: !!TimesheetsReportsarray.pro_created_at
          ? moment(TimesheetsReportsarray.pro_created_at).format(
            "MM/DD/YYYY"
          )
          : "-",
        PaymentType: !!TimesheetsReportsarray.paymentDets
          ? TimesheetsReportsarray.paymentDets.pea_frequency
          : "N/A",
        TaskcreateDate: !!TimesheetsReportsarray.tas_created_at
          ? moment(TimesheetsReportsarray.tas_created_at)
            .local()
            .format("MM/DD/YYYY")
          : "-",
        Project: !!TimesheetsReportsarray.pro_title
          ? TimesheetsReportsarray.pro_title
          : "-",
        TaskStartDate: !!TimesheetsReportsarray.tas_start_date
          ? moment(TimesheetsReportsarray.tas_start_date)
            .local()
            .format("MM/DD/YYYY")
          : "-",
        TaskStartTime: !!TimesheetsReportsarray.tas_start_time
          ? !moment(TimesheetsReportsarray.tas_start_time).isValid()
            ? TimesheetsReportsarray.tas_start_time
            : moment(TimesheetsReportsarray.tas_start_time)
              .local()
              .format("h:mm a")
          : "-",
        RecActivityDate: moment(TimesheetsReportsarray.start_time).isValid()
          ? moment(TimesheetsReportsarray.start_time).format("MM/DD/YYYY")
          : "-",
        RecStartTime: moment(TimesheetsReportsarray.start_time).isValid()
          ? moment(TimesheetsReportsarray.start_time).local().format("h:mm a")
          : "-",
        TaskName: !!TimesheetsReportsarray.tas_title
          ? TimesheetsReportsarray.tas_title
          : "-",
        TaskType: !!TimesheetsReportsarray.tas_type
          ? TimesheetsReportsarray.tas_type
          : "-",
        RegularHrs: !!TimesheetsReportsarray.tas_start_time
          ? moment(TimesheetsReportsarray.tas_start_time).isValid()
            ? (
              moment(TimesheetsReportsarray.tas_end_time).diff(
                moment(TimesheetsReportsarray.tas_start_time),
                "m"
              ) / 60
            ).toFixed(2)
            : 0
          : 0,
        WeeklyHrs: TimesheetsReportsarray.taskHrs.toFixed(2),
        // ? moment(TimesheetsReportsarray.start_time).isValid()
        //   ? (
        //     moment(TimesheetsReportsarray.end_time).diff(
        //       moment(TimesheetsReportsarray.start_time),
        //       "m"
        //     ) / 60
        //   ).toFixed(2)
        //   : 0
        // : 0,
        OvertimeHrs:
          moment(
            !!TimesheetsReportsarray
              ? moment(TimesheetsReportsarray.end_time).diff(
                moment(TimesheetsReportsarray.start_time),
                "h"
              )
              : 0
          ).diff(
            moment(
              !!TimesheetsReportsarray.tas_start_date
                ? moment(
                  TimesheetsReportsarray.tas_start_date
                ).isValid()
                  ? moment(TimesheetsReportsarray.tas_end_date).diff(
                    moment(TimesheetsReportsarray.tas_start_date),
                    "h"
                  )
                  : 0
                : 0
            )
          ) > 0
            ? moment(
              !!TimesheetsReportsarray
                ? moment(TimesheetsReportsarray.end_time).diff(
                  moment(TimesheetsReportsarray.start_time),
                  "h"
                )
                : 0
            ).diff(
              moment(
                !!TimesheetsReportsarray.tas_start_time
                  ? moment(
                    TimesheetsReportsarray.tas_start_time
                  ).isValid()
                    ? moment(
                      TimesheetsReportsarray.tas_end_time
                    ).diff(
                      moment(
                        TimesheetsReportsarray.tas_start_time
                      ),
                      "h"
                    )
                    : 0
                  : 0
              )
            )
            : 0,
        ProjectHrs: !!TimesheetsReportsarray.pro_starts_on
          ? moment().diff(
            moment(TimesheetsReportsarray.pro_starts_on),
            "h"
          )
          : "-",
        EmployeeHrs: !!TimesheetsReportsarray
          ? moment(TimesheetsReportsarray.end_time).diff(
            moment(TimesheetsReportsarray.start_time),
            "h"
          )
          : "-",
        HourlyRate: !!TimesheetsReportsarray.paymentDets
          ? "$ " + TimesheetsReportsarray.paymentDets.pea_bill_rate
          : "$ 0",
        TotalHourlyPay:
          "$ " +
          (!!TimesheetsReportsarray && !!TimesheetsReportsarray.paymentDets
            ? (
              moment
                .duration(
                  moment(TimesheetsReportsarray.end_time).diff(
                    moment(TimesheetsReportsarray.start_time)
                  )
                )
                .asHours()
                .toFixed(2) * TimesheetsReportsarray.paymentDets.pea_bill_rate
            ).toFixed(2)
            : "-"),
        TaskEndDate: !!TimesheetsReportsarray.tas_end_date
          ? moment(TimesheetsReportsarray.tas_end_date)
            .local()
            .format("MM/DD/YYYY")
          : "-",
        TaskEndTime: !!TimesheetsReportsarray.tas_end_time
          ? !moment(TimesheetsReportsarray.tas_end_time).isValid()
            ? TimesheetsReportsarray.tas_end_time
            : moment(TimesheetsReportsarray.tas_end_time)
              .local()
              .format("h:mm a")
          : "-",
        RecEndTime: moment(TimesheetsReportsarray.end_time).isValid()
          ? moment(TimesheetsReportsarray.end_time).local().format(" h:mm a")
          : "-",
        PayrollEndDate:
          !!TimesheetsReportsarray && !!TimesheetsReportsarray.paymentDets
            ? moment(TimesheetsReportsarray.paymentDets.pea_associate_to)
              .local()
              .format("MM/DD/YYYY h:mm a")
            : "-",
        TaskNotes:
          TimesheetsReportsarray.tas_notes === null
            ? ""
            : TimesheetsReportsarray.tas_notes === "undefined" ||
              TimesheetsReportsarray.tas_notes === "N/A"
              ? ""
              : TimesheetsReportsarray.tas_notes,
        CaseNotes:
          TimesheetsReportsarray.taskComments === null
            ? ""
            : TimesheetsReportsarray.taskComments.map(comment => {
              try {
                const json = JSON.parse(comment.comment_text);
                if (json.blocks && Array.isArray(json.blocks)) {
                  return json.blocks.map(block => block.text).join(' ');
                }
              } catch (e) {
                // Not a valid JSON, continue with the text as is
              }
              return comment.comment_text
            }).join(' | '),
      };

      arr.push(row);
    });
    this.makeTableHTML(arr);
  };

  handlePrint = () => {
    this.getRowsData();
  };

  makeTableHTML = (myArray) => {
    var result = "<table>  ";
    let limit = myArray?.length;

    result += "<tr>";
    for (var c = 0; c < this.state.columns?.length; c++) {
      result +=
        "<th style='width:100%; border-bottom: 1px solid black'>" +
        this.state.columns[c].field +
        "</th>";
    }
    result += "</tr>";
    for (var i = 0; i < limit; i++) {
      result += "<tr>";
      for (var j = 0; j < this.state.columns?.length; j++) {
        result +=
          "<td style='width:100%; border-bottom: 1px solid black'>" +
          myArray[i][this.state.columns[j].field] +
          "</td>";
      }
      result += "</tr>";
    }
    result += "</table>";
    let newWin = window.open("");
    newWin.document.write(result);
    newWin.print();
    newWin.close();
  };

  openCustomReport = (e) => {
    e.preventDefault();
    let val;
    if (this.state.isCustomReportOpen) {
      val = !this.state.isCustomReportOpen;
      this.setState({
        ...this.state,
        filterResultsOutput: "",
        isCustomReportOpen: val,
      });
      localStorage.removeItem("timesheetFilterData");
    } else {
      val = !this.state.isCustomReportOpen;
      this.setState({
        ...this.state,
        isCustomReportOpen: val,
      });
    }
  };

  closeCustomReport = (e) => {
    e.preventDefault();
    let val;
    val = !this.state.isCustomReportOpen;
    this.setState({
      ...this.state,
      isCustomReportOpen: val,
    });
  };

  handleFilterForm = (langValue) => {
    const objectValues = Object.values(langValue);
    let output = [];

    objectValues.forEach((element, index) => {
      if (element) {
        output.push(element);
      }
    });

    output = output.join(", ");

    this.setState({
      ...this.state,
      filterResults: langValue,
      filterResultsOutput: output,
      isCustomReportOpen: !this.state.isCustomReportOpen,
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({
      ...this.state,
      page: newPage,
    });
  };

  render() {
    let timesheet_rows = this.state.TimesheetsReports.map(
      (TimesheetsReportsarray) => {
        let row = {
          id: TimesheetsReportsarray.id,
          emp_id: TimesheetsReportsarray.emp_id,
          empName: TimesheetsReportsarray.emp_first_name + ' ' + TimesheetsReportsarray.emp_last_name,
          FirstName: TimesheetsReportsarray.emp_first_name,
          LastName: TimesheetsReportsarray.emp_last_name,
          EmpTitle: !!TimesheetsReportsarray.paymentDets
            ? TimesheetsReportsarray.paymentDets.pea_status
            : "N/A",
          EmpType: TimesheetsReportsarray.emp_type,
          ProjectTime: !!TimesheetsReportsarray.pro_created_at
            ? moment(TimesheetsReportsarray.pro_created_at).format(
              "MM/DD/YYYY"
            )
            : "-",
          PaymentType: !!TimesheetsReportsarray.paymentDets
            ? TimesheetsReportsarray.paymentDets.pea_frequency
            : "N/A",
          TaskcreateDate: !!TimesheetsReportsarray.tas_created_at
            ? TimesheetsReportsarray.tas_created_at
            : "-",
          Project: !!TimesheetsReportsarray.pro_title
            ? TimesheetsReportsarray.pro_title
            : "-",
          TaskStartDate: !!TimesheetsReportsarray.tas_start_date
            ? moment(TimesheetsReportsarray.tas_start_date)
              .local()
              .format("MM/DD/YYYY")
            : "-",
          TaskStartTime: !!TimesheetsReportsarray.tas_start_time
            ? !moment(TimesheetsReportsarray.tas_start_time).isValid()
              ? TimesheetsReportsarray.tas_start_time
              : moment(TimesheetsReportsarray.tas_start_time)
                .local()
                .format("h:mm a")
            : "-",
          RecActivityDate: moment(TimesheetsReportsarray.start_time).isValid()
            ? moment(TimesheetsReportsarray.start_time).format("MM/DD/YYYY")
            : "-",
          RecStartTime: moment(TimesheetsReportsarray.start_time).isValid()
            ? moment(TimesheetsReportsarray.start_time.replace("Z", "")).local().format("h:mm a")
            : "-",
          TaskName: !!TimesheetsReportsarray.tas_title
            ? TimesheetsReportsarray.tas_title
            : "-",
          TaskType: !!TimesheetsReportsarray.tas_type
            ? TimesheetsReportsarray.tas_type
            : "-",
          RegularHrs: !!TimesheetsReportsarray.tas_start_time
            ? moment(TimesheetsReportsarray.tas_start_time).isValid()
              ? (
                moment(TimesheetsReportsarray.tas_end_time).diff(
                  moment(TimesheetsReportsarray.tas_start_time),
                  "m"
                ) / 60
              ).toFixed(2)
              : 0
            : 0,
          WeeklyHrs: TimesheetsReportsarray.taskHrs.toFixed(2),
          // ? moment(TimesheetsReportsarray.start_time).isValid()
          //   ? (
          //     moment(TimesheetsReportsarray.end_time).diff(
          //       moment(TimesheetsReportsarray.start_time),
          //       "m"
          //     ) / 60
          //   ).toFixed(2)
          //   : 0
          // : 0,
          OvertimeHrs:
            moment(
              !!TimesheetsReportsarray
                ? moment(TimesheetsReportsarray.end_time).diff(
                  moment(TimesheetsReportsarray.start_time),
                  "h"
                )
                : 0
            ).diff(
              moment(
                !!TimesheetsReportsarray.tas_start_date
                  ? moment(
                    TimesheetsReportsarray.tas_start_date
                  ).isValid()
                    ? moment(TimesheetsReportsarray.tas_end_date).diff(
                      moment(TimesheetsReportsarray.tas_start_date),
                      "h"
                    )
                    : 0
                  : 0
              )
            ) > 0
              ? moment(
                !!TimesheetsReportsarray
                  ? moment(TimesheetsReportsarray.end_time).diff(
                    moment(TimesheetsReportsarray.start_time),
                    "h"
                  )
                  : 0
              ).diff(
                moment(
                  !!TimesheetsReportsarray.tas_start_time
                    ? moment(
                      TimesheetsReportsarray.tas_start_time
                    ).isValid()
                      ? moment(
                        TimesheetsReportsarray.tas_end_time
                      ).diff(
                        moment(
                          TimesheetsReportsarray.tas_start_time
                        ),
                        "h"
                      )
                      : 0
                    : 0
                )
              )
              : 0,
          ProjectHrs: !!TimesheetsReportsarray.pro_starts_on
            ? moment().diff(
              moment(TimesheetsReportsarray.pro_starts_on),
              "h"
            )
            : "-",
          EmployeeHrs: !!TimesheetsReportsarray
            ? moment(TimesheetsReportsarray.end_time).diff(
              moment(TimesheetsReportsarray.start_time),
              "h"
            )
            : "-",
          HourlyRate: !!TimesheetsReportsarray.paymentDets
            ? "$ " + TimesheetsReportsarray.paymentDets.pea_bill_rate
            : "$ 0",
          TotalHourlyPay:
            "$ " +
            (!!TimesheetsReportsarray && !!TimesheetsReportsarray.paymentDets
              ? (
                moment
                  .duration(
                    moment(TimesheetsReportsarray.end_time).diff(
                      moment(TimesheetsReportsarray.start_time)
                    )
                  )
                  .asHours()
                  .toFixed(2) *
                TimesheetsReportsarray.paymentDets.pea_bill_rate
              ).toFixed(2)
              : "-"),
          TaskEndDate: !!TimesheetsReportsarray.tas_end_date
            ? moment(TimesheetsReportsarray.tas_end_date)
              .local()
              .format("MM/DD/YYYY")
            : "-",
          TaskEndTime: !!TimesheetsReportsarray.tas_end_time
            ? !moment(TimesheetsReportsarray.tas_end_time).isValid()
              ? TimesheetsReportsarray.tas_end_time
              : moment(TimesheetsReportsarray.tas_end_time)
                .local()
                .format("h:mm a")
            : "-",
          RecEndTime: moment(TimesheetsReportsarray.end_time).isValid()
            ? moment(TimesheetsReportsarray.end_time.replace("Z", "")).local().format(" h:mm a")
            : "-",
          PayrollEndDate:
            !!TimesheetsReportsarray && !!TimesheetsReportsarray.paymentDets
              ? moment(TimesheetsReportsarray.paymentDets.pea_associate_to)
                .local()
                .format("MM/DD/YYYY h:mm a")
              : "-",
          TaskNotes:
            TimesheetsReportsarray.tas_notes === null
              ? ""
              : TimesheetsReportsarray.tas_notes === "undefined" ||
                TimesheetsReportsarray.tas_notes === "N/A"
                ? ""
                : TimesheetsReportsarray.tas_notes,
          CaseNotes:
            TimesheetsReportsarray.taskComments === null
              ? ""
              : TimesheetsReportsarray.taskComments.map(comment => {
                try {
                  const json = JSON.parse(comment.comment_text);
                  if (json.blocks && Array.isArray(json.blocks)) {
                    return json.blocks.map(block => block.text).join(' ');
                  }
                } catch (e) {
                  // Not a valid JSON, continue with the text as is
                }
                return comment.comment_text
              }).join(' | '),
        };
        return row;
      }
    );

    let row_csv_timesheet = timesheet_rows.map((reptime) => {
      let new_reptime = {
        ...reptime,
        [`First Name`]: reptime.FirstName,
        [`Last Name`]: reptime.LastName,
        ["SC"]: reptime.emp_id,
        ["Title"]: reptime.EmpTitle,
        ["Classification"]: reptime.EmpType,
        ["Project Creation Date"]: reptime.ProjectTime,
        ["Payment Type"]: reptime.PaymentType,
        ["Date Entered"]: reptime.TaskcreateDate,
        ["Project"]: reptime.Project,
        ["Start Date of Service"]: reptime.TaskStartDate,
        ["Start Time of Service"]: reptime.TaskStartTime,
        ["Task Name"]: reptime.TaskName.replace(/#/g, ""),
        ["Account Code"]: reptime.TaskType,
        ["Regular Hrs"]: reptime.RegularHrs,
        ["Weekly Hrs"]: reptime.WeeklyHrs,
        ["Overtime Hrs"]: reptime.OvertimeHrs,
        ["Project (Cum Hrs)"]: reptime.ProjectHrs,
        ["Employee (Cum Hrs)"]: reptime.EmployeeHrs,
        ["Hourly Rate"]: reptime.HourlyRate,
        ["Total Hourly Pay"]: reptime.TotalHourlyPay,
        ["End Date of Service"]: reptime.TaskEndDate,
        ["End Time of Service"]: reptime.TaskEndTime,
        ["Payroll End Date"]: reptime.PayrollEndDate,
        ["Comments"]: reptime.TaskNotes,
        ["Notes"]: reptime.CaseNotes,
      };

      delete new_reptime.FirstName;
      delete new_reptime.LastName;
      delete new_reptime.emp_id;
      delete new_reptime.EmpTitle;
      delete new_reptime.EmpType;
      delete new_reptime.ProjectTime;
      delete new_reptime.PaymentType;
      delete new_reptime.TaskcreateDate;
      delete new_reptime.Project;
      delete new_reptime.TaskStartDate;
      delete new_reptime.TaskName;
      delete new_reptime.TaskType;
      delete new_reptime.RegularHrs;
      delete new_reptime.WeeklyHrs;
      delete new_reptime.OvertimeHrs;
      delete new_reptime.ProjectHrs;
      delete new_reptime.EmployeeHrs;
      delete new_reptime.HourlyRate;
      delete new_reptime.TotalHourlyPay;
      delete new_reptime.TaskEndDate;
      delete new_reptime.PayrollEndDate;
      delete new_reptime.TaskNotes;
      delete new_reptime.CaseNotes;
      return new_reptime;
    });

    const csv_timesheet = jsonToCSV(row_csv_timesheet);

    let tempObj = localStorage.getItem("timesheetFilterData");
    if (!tempObj && this.state.filterResultsOutput != "") {
      this.setState({ filterResultsOutput: "" });
    }

    return (
      <Grid>
        {this.state.loading && this.state.TimesheetsReports.length === 0 ? (
          <div className="d-flex flex-row w-100 justify-content-center mt-5">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : !this.state.loading && this.state.TimesheetsReports.length === 0 && this.state.filterResultsOutput === "" ? (
          <div
            className="d-flex flex-column justify-content-center align-items-center"
            style={{ height: "500px", marginTop: 10 }}
          >
            <img
              src="./Timesheetwhite.png"
              alt="Timesheetwhite"
              style={{ width: "20rem", height: "15rem", margin: 10 }}
            />
            <h5
              style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
              className="text-center"
            >
              No timesheets entry to show{" "}
            </h5>
            <div className="d-flex flex-row justify-content-between">
              <div className="m-2 mr-2">
                <span
                  style={{ fontFamily: "Lato", fontSize: 14, width: "70%" }}
                  className="text-muted text-center text-wrap"
                >
                  Your team haven’t completed any tasks yet. Once they have,
                  you’ll see detailed entries here.
                </span>
              </div>
            </div>
          </div>
        ) : (
          <Grid>
            {this.state.isCustomReportOpen ? (
              <TimesheetFilter
                closeCustomReport={this.closeCustomReport}
                onFormSubmission={this.handleFilterForm}
              />
            ) : null}
            <Grid container>
              <Grid item xs={4}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                    fontFamily: "Lato",
                    marginBottom: 20,
                  }}
                >
                  Time Management
                </Typography>
              </Grid>
              <Grid
                item
                xs={8}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <div style={{ display: "inline-block", width: "150px" }}>
                    <Button
                      type="submit"
                      onClick={(e) => {
                        if (this.state.isCustomReportOpen) {
                          this.handleFilterForm({});
                          localStorage.removeItem("timesheetFilterData");
                        } else {
                          this.openCustomReport(e);
                        }
                      }}
                      variant="primary"
                      className="mb-4"
                    >
                      {this.state.isCustomReportOpen
                        ? "Reset"
                        : "Custom Report"}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={9} sm={9} md={9} lg={8} alignItems="center">
                  {this.state.filterResultsOutput !== "" && (
                    <Typography
                      style={{
                        fontStyle: "italic",
                        fontFamily: "Lato",
                        color: "#626262",
                        top: " 7px",
                        position: "relative",
                        fontSize: "14px",
                      }}
                    >
                      Reports results: {this.state.filterResultsOutput}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  xs={2}
                  sm={2}
                  md={2}
                  lg={2}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                >
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info m-1"
                  >
                    <CSVDownloader
                      data={csv_timesheet}
                      filename={"timesheet report"}
                      bom={true}
                    >
                      CSV
                    </CSVDownloader>
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info m-1"
                    onClick={this.handlePrint}
                  >
                    PDF
                  </button>
                </Grid>
              </Grid>
              <Grid item xs={12} id="printTable">
                <Box sx={{ width: '100%', height: "calc(100vh - 300px)" }}>
                  <ControlledDataGrid
                    gridId={this.state.gridId}
                    user={this.props.user}
                    isRowSelectable={(params) => (params.row.id < 0)}
                    initialState={{ sorting: { sortModel: [{ field: "TaskcreateDate", sort: "asc" }] } }}
                    apiRef={this.apiRef}
                    disableColumnFilter    // To disable filter
                    hideFooter={true}
                    filterMode="client"
                    style={{ color: "#000000" }}
                    rows={timesheet_rows}
                    columns={columns}
                  />
                </Box>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                xs={15}
              >
                <TablePagination
                  component="div"
                  count={this.state.count}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  rowsPerPage={this.state.page_size}
                  rowsPerPageOptions={[]}
                />
              </Grid>
            </Grid>
          </Grid>
        )
        }
      </Grid>
    );
  }
}
const TimesheetReport = React.forwardRef((props, ref) => {
  const apiRef = useGridApiRef();
  useImperativeHandle(ref, () => apiRef.current);

  return <Timesheet_Report {...props} apiRef={apiRef} />;
});

export default TimesheetReport;
