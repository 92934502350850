import React, { Component } from 'react';
import DetailCard from './DetailCard/DetailCard';
import CustomerDetails from './CustomerDetails/CustomerDetails';
import CustomerForm from '../../../components/forms/CustomerForm/CustomerForm';
import ProjectForm from '../../../components/forms/ProjectForm/ProjectForm';
import { Col } from 'react-bootstrap';
import './Home.css';
import 'moment-timezone';
import {APIURL} from '../../../config'
import axios from 'axios';
import moment from 'moment'

class Home extends Component {
    // 0 - indicates show home page
    // 1 - indicates show customer details
    // 2 - indicates edit form for customer details
    // 3 - indicates edit project form
    state = {
        contextMenu: 0,
        customerStatus: [],
        customerType: [],
        loc_list: [],
        employee_list: [],
        project_tasks: [],
        loading: true,
    }

    async fetchData() {
        let project_tasks = await axios.get(APIURL + "/project/projTask", { params: { proj_id: this.props.id } })
        let customerStatus = await axios.get(APIURL + '/dropdowns/dropdownType/customerStatus', { params: { comp_id: this.props.comp_id } });
        let customerType = await axios.get(APIURL + '/dropdowns/dropdownType/customerType', { params: { comp_id: this.props.comp_id } });
        let loc_list = await axios.get(APIURL + '/dropdowns/dropdownType/locationslist', { params: { comp_id: this.props.comp_id } });
        let employee_list = await axios.get(APIURL + '/dropdowns/dropdownType/employeeslist', { params: { comp_id: this.props.comp_id } });
        this.setState({ 
            customerStatus: customerStatus.data, 
            customerType: customerType.data, 
            loc_list: loc_list.data, 
            employee_list: employee_list.data, 
            project_tasks: project_tasks.data, 
            loading: false 
        })
    }

    async componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        if(this.props.id !== prevProps.id) {
            this.setState({ loading: true })
            this.fetchData()
        }
    }

    navigationHandler = (contextMenu) => {
        this.setState({ contextMenu })
    }

    showProjectStatus = () => {
        if (this.props.status) {
            return "Ongoing"
        } else {
            return "Completed"
        }
    }

    getProjectLocation = () => {
        let proj_loc_list = this.props.locations
        let filtered_loc_list = []
        proj_loc_list.forEach((loc) => {
            filtered_loc_list.push(this.state.loc_list.filter((loc1) => loc1.id === loc.loc_id)[0])
        })
        console.log(this.props.project_details.locations, filtered_loc_list)
        return filtered_loc_list
    }

    render() {
        let content = null
        if (this.state.contextMenu === 1) {
            content = <CustomerDetails
                history={this.props.history}
                onClickBack={this.navigationHandler}
                onClickEdit={this.navigationHandler}
                customer_details={this.props.customer_details[0]}
                projectTitle={this.props.title} />
        } else if (this.state.contextMenu === 0) {
            content = (<div>
                <div className="CustomerHeader">
                    <span><h5>{this.props.title}</h5></span>
                    <span> <i className="fas fa-edit" style={{ marginLeft: "20px", cursor: "pointer" }} onClick={() => this.navigationHandler(3)}></i></span>
                </div>
                {this.props.start_date && this.props.end_date && moment(this.props.start_date).isAfter("1900-01-01") && moment(this.props.end_date).isAfter("1900-01-01") ?
                    <div className="SecondaryFontColor">
                        <p><span><i className="far fa-hourglass" style={{ paddingRight: "15px" }}></i></span>
                            {this.showProjectStatus()} | {moment(this.props.start_date.replace(/Z/g, "")).format('D MMM YYYY')} - {moment(this.props.end_date.replace(/Z/g, "")).format('D MMM YYYY')}</p>
                        <p><span><i className="far fa-clock" style={{ paddingRight: "13px" }}></i></span>{moment((moment().isBefore(moment(this.props.end_date))?this.props.start_date:this.props.end_date).replace(/Z/g, "")).fromNow()} since {moment().isBefore(moment(this.props.end_date))?'start of':'end of'} this Project</p>
                    </div> :
                    <div className="SecondaryFontColor">
                        <p><span><i className="far fa-hourglass" style={{ paddingRight: "15px" }}></i></span>Initial Plan</p>
                    </div>
                }
                <h6>PROJECT DESCRIPTION</h6>
                {this.props.locations && this.props.locations.length > 0 ?
                    <div className="SecondaryFontColor" style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "150px" }}>LOCATIONS</div>
                        <div className="ProjectLocation">
                            {this.props.locations.map((loc, index) => {
                                return <div key={index}>
                                    <span><i style={{ paddingRight: "10px" }} className="fas fa-map-marker-alt"></i></span>
                                    {loc.loc_addr_line1 ? loc.loc_addr_line1 : ''}  {loc.loc_addr_line2 ? loc.loc_addr_line2 : ''}  {loc.loc_city ? loc.loc_city : ''}  {loc.loc_state ? loc.loc_state : ''}  {loc.loc_zip ? loc.loc_zip : ''} 
                                </div>
                            })}
                        </div>
                    </div> : null
                }
                <hr className="HorizontalLine"></hr>
                <div className="CustomerHeader">
                    <li className="SecondaryFontColor">
                        <span><i style={{ fontSize: "1.5rem", paddingRight: "20px", paddingLeft: "0px" }} className="fas fa-briefcase"></i></span>
                        {this.props.customer_details[0].cus_name}
                    </li>

                    <li style={{ color: "#3FA7D6", cursor: "pointer" }} onClick={() => this.navigationHandler(1)} >VIEW CUSTOMER DETAILS</li>
                </div>
                <hr className="HorizontalLine"></hr>
                <DetailCard task comp_id={this.props.comp_id} projId={this.props.id} tasks={(this.props.user.type === 'Admin'|| this.props.user.type === 'Manager')?this.state.project_tasks:!this.state.project_tasks?this.state.project_tasks:this.state.project_tasks.filter((tas)=> tas.tas_emp_id === this.props.user.emp_id)} />
                <DetailCard art comp_id={this.props.comp_id} projId={this.props.id} employee={this.state.employee_list} tasks={this.state.project_tasks} />
                {/* <DetailCard />
                <DetailCard /> */}
            </div>)
        } else if (this.state.contextMenu === 2) {
            content = <Col md={10} sm={12} style={{maxWidth:'35rem', height:'900px',overflowY: 'auto' ,overflowX: 'hidden'}}>
                <h5 style={{ cursor: "pointer", marginLeft: "10px" }}><span style={{ paddingRight: 10, paddingLeft: 0, fontSize: "1.5rem" }}><i className="fas fa-arrow-left"  onClick={() => this.navigationHandler(1)}></i></span>BACK</h5>
                <CustomerForm
                    history={this.props.history}
                    isFormValidated={this.props.isFormValidated}
                    customerStatus={this.state.customerStatus}
                    customerType={this.state.customerType}
                    states={this.props.states}
                    empList={this.props.empList}
                    tasklist={this.state.project_tasks}
                    loc_list={this.state.loc_list}
                    edit
                    comp_id ={this.props.comp_id}
                    // navigationHandler={this.navigationHandler}
                    cusDets={this.props.customer_details[0]}
                    pId={this.props.id}
                    pManagerId={this.props.pManagerId}
                    user={this.props.user}
                />
            </Col>
        } else if (this.state.contextMenu === 3) {
            content = <div className="col-md-10 col-sm-12" style={{maxWidth:'35rem',height:'900px' ,overflowY: 'auto' ,overflowX: 'hidden'}}>
                <h5 style={{ cursor: "pointer", marginLeft: "5px" }}><span style={{ paddingRight: 10, paddingLeft: 0, fontSize: "1.5rem" }}><i className="fas fa-arrow-left"  onClick={() => this.navigationHandler(0)}></i></span>BACK</h5>
                <ProjectForm
                    user={this.props.user}
                    edit
                    comp_id ={this.props.comp_id}
                    history={this.props.history}
                    // navigationHandler={this.navigationHandler}
                    mapRender={"proj"}
                    isFormValidated={this.props.isFormValidated}
                    project_details={
                        {
                            ddd_id: this.props.project_details.DDD_ID,
                            active_plan_version:this.props.project_details.active_plan_version,
                            projId: this.props.project_details.pro_id,
                            pTitle: this.props.project_details.pro_title,
                            pDesc: this.props.project_details.pro_description,
                            pCustomer: this.props.project_details.pro_customer_id,
                            pManager: this.props.project_details.pro_manager_id,
                            pSponsor: this.props.project_details.pro_sponsors_id,
                            pstrtDate:this.props.project_details.pro_starts_on?.split("T")[0],
                            pEndDate: this.props.project_details.pro_ends_on?.split("T")[0],
                            pPrice: this.props.project_details.pro_price,
                            pCurrency: "USD",
                            pLoc: !!this.props.project_details.locations?this.getProjectLocation():[]
                        }
                    }
                    customerName={this.props.project_details.customer_details[0].cus_name}
                    customerList={this.props.project_details.customer_details}
                    proj_Employees={this.props.empList}
                    empList={this.state.employee_list}
                    contactList={this.props.project_details.contacts}
                    locList={this.state.loc_list}
                    taskList={this.state.project_tasks}
                    updateProject={() => {
                        this.navigationHandler(0);
                        this.props.updateProject();
                    }}
                    empStatus
                    currency
                    frequency
                />
            </div>
        }
        return (
            <div style={{ overflowY: "auto", height: "100%" }}>
                {this.state.loading &&
                    (
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem'}} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    )
                }
                {!this.state.loading && content}
            </div>
        )
    }
}

export default Home;