import React, { useState, useEffect } from "react"
// eslint-disable-next-line
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { APIURL } from '../../config'
import axios from 'axios'
import moment from "moment"
// import TextField from '@mui/material/TextField';
// import Autocomplete from '@mui/lab/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import { isArray } from "@material-ui/data-grid"
// eslint-disable-next-line
import {
    DataGrid, GridToolbar, GridApi, GridCellParams
} from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';
import "./SummaryTable.css"
import Typography from "@mui/material/Typography";

const useStyle = makeStyles({
    root: {
        "& .MuiDataGrid-columnHeaderTitle": {
            overflow: "hidden",
            lineHeight: "20px",
            whiteSpace: "normal"
        }
    }

});

export default function SummaryTable(props) {

    const classes = useStyle();
    // Declare state variables
    const [currentWeekDates, setCurrentWeekDates] = useState([moment().startOf('week').format("YYYY[-]MM[-]DD"), moment().startOf('week').add(1, 'days').format("YYYY[-]MM[-]DD"), moment().startOf('week').add(2, 'days').format("YYYY[-]MM[-]DD"), moment().startOf('week').add(3, 'days').format("YYYY[-]MM[-]DD"), moment().startOf('week').add(4, 'days').format("YYYY[-]MM[-]DD"), moment().startOf('week').add(5, 'days').format("YYYY[-]MM[-]DD"), moment().endOf('week').format("YYYY[-]MM[-]DD")]);
    const [tableRows, setTableRows] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [timeSheetData, setTimeSheetData] = useState([]);
    const [currentWeekTimeSheets, setCurrentWeekTimeSheets] = useState([]);
    const [currentSummaryData, setCurrentSummaryData] = useState([]);
    const [selected, setSelected] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [companyData, setCompanyData] = useState();

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            const res1 = await getSummaryData(currentWeekDates[0], currentWeekDates[6]);
            const res2 = await getTimeSheetData();
            const res3 = await getCompData();
            if (mounted) {
                setSummaryData(res1);
                setTimeSheetData(res2);
                setCompanyData(res3);
            }
        }
        fetchData();
        return () => { mounted = false };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        updateCurrentSummaryData();
        updateCurrentTimeSheets();
        // eslint-disable-next-line
    }, [timeSheetData, summaryData, currentWeekDates]);

    useEffect(() => {
        setTableRows(getRows());
        setTableColumns(getColumns());
        // eslint-disable-next-line
    }, [currentWeekDates, currentSummaryData, currentWeekDates]);

    async function getTimeSheetData() {
        var timeSheetApi = await axios.get(
            APIURL + '/time_sheet',
            {
                params: {
                    company_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                    type: props.user.type,
                }
            })
        let ts = [];

        for (let timesheet of timeSheetApi.data) {
            ts.push(timesheet);
        }

        return ts;

    }

    async function getSummaryData(start, end) {
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }

        let summaryDataApi = await axios.get(
            APIURL + '/timesheets',
            {
                params: {
                    comp_id: props.user.comp_id,
                    emp_id: props.user.emp_id,
                }
            })
        return summaryDataApi.data;
    }
    function updateCurrentTimeSheets() {
        let currentSheets = [];
        /*
            - Payroll types can a be different
            - We want to check if the timesheet at least partially intersects the current week
        */
        timeSheetData.forEach(timesheet => {
            if (moment(currentWeekDates[0]).add(1, 'days').isBetween((timesheet.start_date.substring(0, 10)), (timesheet.end_date.substring(0, 10))) /*|| moment(currentWeekDates[6]).add(1, 'days').isBetween((timesheet.start_date.substring(0, 10)), (timesheet.end_date.substring(0, 10)))*/)
                currentSheets.push(timesheet);
        });
        setCurrentWeekTimeSheets(currentSheets);
    }

    function updateCurrentSummaryData() {
        var newCurrentSummaryData = [];
        summaryData.forEach(record => {
            if (record.taskDets !== null) {
                if (moment(record.taskDets.tas_start_time.substring(0, 10)).isBetween(currentWeekDates[0], currentWeekDates[6])) {
                    newCurrentSummaryData.push(record);
                }
            }
        });
        setCurrentSummaryData(newCurrentSummaryData);
    }

    function processDayHours(start_time, newRow, entry) {
        let date = new Date(entry["start_time"]);
        let day = date.getUTCDay();

        if (day === 0) {
            newRow["sunday"] += parseFloat(moment.duration(moment(entry['end_time']).diff(entry['start_time'])).asHours().toFixed(2))
        }
        else if (day === 1) {
            newRow["monday"] += parseFloat(moment.duration(moment(entry['end_time']).diff(entry['start_time'])).asHours().toFixed(2))
        }
        else if (day === 2) {
            newRow["tuesday"] += parseFloat(moment.duration(moment(entry['end_time']).diff(entry['start_time'])).asHours().toFixed(2))
        }
        else if (day === 3) {
            newRow["wednesday"] += parseFloat(moment.duration(moment(entry['end_time']).diff(entry['start_time'])).asHours().toFixed(2))
        }
        else if (day === 4) {
            newRow["thursday"] += parseFloat(moment.duration(moment(entry['end_time']).diff(entry['start_time'])).asHours().toFixed(2))
        }
        else if (day === 5) {
            newRow["friday"] += parseFloat(moment.duration(moment(entry['end_time']).diff(entry['start_time'])).asHours().toFixed(2))
        }
        else if (day === 6) {
            newRow["saturday"] += parseFloat(moment.duration(moment(entry['end_time']).diff(entry['start_time'])).asHours().toFixed(2))
        }

        return newRow;
    }

    function roundDayValues(newRow) {
        newRow["sunday"] = newRow["sunday"].toFixed(2);
        newRow["monday"] = newRow["monday"].toFixed(2);
        newRow["tuesday"] = newRow["tuesday"].toFixed(2);
        newRow["wednesday"] = newRow["wednesday"].toFixed(2);
        newRow["thursday"] = newRow["thursday"].toFixed(2);
        newRow["friday"] = newRow["friday"].toFixed(2);
        newRow["saturday"] = newRow["saturday"].toFixed(2);
        newRow["totalHours"] = newRow["totalHours"].toFixed(2);
        return newRow;
    }

    function getRows() {
        let employees = [];
        var newData = [];

        if (props.user.type === 'Admin') {
            props.companyEmployeeList.forEach(emp => {
                if (emp.emp_id !== null && emp.employee_name !== null)
                    employees.push({ id: emp.id, name: emp.employee_name });
            });
        }
        else if (props.user.type === 'Manager') {
            employees.push({ id: props.user.emp_id, name: props.user.emp_name });
            props.managedEmployees.forEach(emp => {
                employees.push({ id: emp.emp_id, name: emp.emp_first_name + " " + emp.emp_last_name });
            });
        }


        for (let employee of employees) { // For the current employee (row)
            // Find this employee's timesheet
            let currentTimesheet = {};

            //NOT WORKING:
            for (let timesheet of currentWeekTimeSheets) {
                if (timesheet.emp_id === employee.id) {
                    currentTimesheet = timesheet;
                }
            }

            let newRow = {
                ts: (!currentTimesheet ? "N/A" : JSON.stringify(currentTimesheet)),
                id: employee.id,
                emp_name: employee.name,
                sunday: 0,
                monday: 0,
                tuesday: 0,
                wednesday: 0,
                thursday: 0,
                friday: 0,
                saturday: 0,
                totalHours: 0,
                status: currentTimesheet.time_sheet_status ? currentTimesheet.time_sheet_status : "N/A",
                actions: ""
            }
            // For each entry in entrydata, if the employee is the person on the entry, add the data to their row
            for (let entry of currentSummaryData) {
                if (employee.id === entry["empDets"]["emp_id"]) { //If this employee is associated with this entry
                    // Set totalHours
                    newRow["totalHours"] += parseFloat(moment.duration(moment(entry["end_time"]).diff(entry["start_time"])).asHours().toFixed(2));
                    //Set Day Hours
                    newRow = processDayHours(entry["start_time"], newRow, entry);
                }
            }
            newRow = roundDayValues(newRow);
            newData.push(newRow);
        }
        return newData
    }

    function getColumns() {
        let columns = [
            { field: 'emp_name', headerName: 'Employee Name', flex: 0.8, headerClassName: 'timesheets-summarytable-columnheader', },
            { field: 'sunday', headerName: moment(currentWeekDates[0]).format("MM[-]DD") + ' Sunday', flex: 0.6, headerClassName: 'timesheets-summarytable-columnheader', },
            { field: 'monday', headerName: moment(currentWeekDates[1]).format("MM[-]DD") + ' Monday', flex: 0.6, headerClassName: 'timesheets-summarytable-columnheader', },
            { field: 'tuesday', headerName: moment(currentWeekDates[2]).format("MM[-]DD") + ' Tuesday', flex: 0.6, headerClassName: 'timesheets-summarytable-columnheader', },
            { field: 'wednesday', headerName: moment(currentWeekDates[3]).format("MM[-]DD") + ' Wednesday', flex: 0.6, headerClassName: 'timesheets-summarytable-columnheader', },
            { field: 'thursday', headerName: moment(currentWeekDates[4]).format("MM[-]DD") + ' Thursday', flex: 0.6, headerClassName: 'timesheets-summarytable-columnheader', },
            { field: 'friday', headerName: moment(currentWeekDates[5]).format("MM[-]DD") + ' Friday', flex: 0.6, headerClassName: 'timesheets-summarytable-columnheader', },
            { field: 'saturday', headerName: moment(currentWeekDates[6]).format("MM[-]DD") + ' Saturday', flex: 0.6, headerClassName: 'timesheets-summarytable-columnheader', },
            { field: 'totalHours', headerName: 'Total Hours', flex: 0.5, headerClassName: 'timesheets-summarytable-columnheader', },
            { field: 'status', headerName: 'Status', flex: 0.5, headerClassName: 'timesheets-summarytable-columnheader', },
            {
                field: 'actions', headerName: ' ', flex: 0.5, headerClassName: 'timesheets-summarytable-columnheader',
                renderCell: (params) => {
                    // console.log('from render cell ', params.row.id)
                    return (
                        <div>
                            <button style={{ borderRadius: 20 }} className='btn btn-sm btn-info' onClick={() => setEmployee(params.id, params.emp_name)}> <i className="far fa-edit"></i> </button>

                        </div>
                    )
                }
            },
        ];
        return columns;
    }

    function previousWeek() {
        let newWeekDates = [];
        for (let i = 0; i < currentWeekDates.length; i++) {
            newWeekDates[i] = moment(currentWeekDates[i]).subtract(7, 'days').format("YYYY[-]MM[-]DD")
        }
        props.setWeek(newWeekDates);
        setCurrentWeekDates(newWeekDates);
    }

    function nextWeek() {
        let newWeekDates = [];
        for (let i = 0; i < currentWeekDates.length; i++) {
            newWeekDates[i] = moment(currentWeekDates[i]).add(7, 'days').format("YYYY[-]MM[-]DD")
        }
        props.setWeek(newWeekDates);
        setCurrentWeekDates(newWeekDates);
    }
    async function getCompData() {
        let company = await axios.get(APIURL + '/company', {
            params: {
                comp_id: props.user.comp_id
            }
        });
        return company.data[0];
    }

    function getPayrollStatus() {
        let payCycle = 'weekly'
        if (!!companyData) {
            if (!!companyData.payCycle) {
                payCycle = companyData.payCycle.toLowerCase();
            }
        }
        let week_start = moment(currentWeekDates[1])
        let currentCycle = 1;
        let startDate = undefined;
        let endDate = undefined;

        if (payCycle === "weekly") {
            if (!!props.payStubsList) {
                for (let i = 0; i < props.payStubsList.length; i++) {
                    let payroll_start = moment(props.payStubsList[i].start_date)
                    let payroll_end = moment(props.payStubsList[i].end_date)
                    if (week_start.isBetween(payroll_start, payroll_end)) {
                        currentCycle = props.payStubsList[i].payroll_number;
                        startDate = props.payStubsList[i].start_date;
                        endDate = props.payStubsList[i].end_date;
                        break;
                    }
                }
            }
        }
        else if (payCycle === "bi-weekly") {
            if (!!props.payStubsList) {
                let count = 1;
                for (let i = 0; i < props.payStubsList.length; i += 2) {
                    let payroll_start = moment(props.payStubsList[i].start_date)
                    let payroll_end = moment(props.payStubsList[i + 1].end_date)
                    if (week_start.isBetween(payroll_start, payroll_end)) {
                        currentCycle = count;
                        startDate = props.payStubsList[i].start_date;
                        endDate = props.payStubsList[i + 1].end_date;
                        break;
                    }
                    count++;
                }
            }
        }
        else if (payCycle === "semi-monthly") {
            let month = moment(week_start).month();

            if (moment(week_start).date() > 15) {
                currentCycle += (month * 2 + 1);
                startDate = moment(week_start).format("YYYY-MM-16");
                endDate = moment(week_start).endOf("month").subtract(1, "days")
            } else {
                currentCycle += (month * 2);
                startDate = moment(week_start).startOf("month");
                endDate = moment(week_start).format("YYYY-MM-15");
            }
        }
        else if (payCycle === "monthly") {
            let month = moment(week_start).month();
            currentCycle = month + 1;
            startDate = moment(week_start).startOf("month");
            endDate = moment(week_start).endOf("month").subtract(1, "days")
        }
        else {
            return <strong>{`Unactivated Pay Cycle`}</strong>
        }


        if (startDate === undefined) {
            return <strong>Could Not Find A Corresponding Payroll Entry For The Week From {moment.utc(currentWeekDates[0]).format("MMM DD YYYY")} To {moment.utc(currentWeekDates[6]).format("MMM DD YYYY")}</strong>;
        }
        /*if (moment(week_start).year() != moment().year()) {
            return <strong>Pay Cycle Could Not Be Determined</strong>;
        }*/

        return <strong>{`Pay Cycle ${currentCycle} (${moment.utc(startDate).format("MMM DD")} - ${moment.utc(endDate).format("MMM DD")})`}</strong>
    }

    async function setEmployee(id, name) {
        await props.setCurrentEmployee(name, id);
    }

    async function approveTimeSheets() {
        let successes = 0, ignored = 0;
        for (let i = 0; i < selected.length; i++) {
            if (selected[i].ts !== null) {
                if (typeof (selected[i].ts) === 'string') {
                    selected[i].ts = JSON.parse(selected[i].ts);
                }
                if (selected[i].ts.time_sheet_status === "SUBMITTED") {
                    let empTimeSheetUpdate = await axios.put(APIURL + '/time_sheet', { time_sheet_id: selected[i].ts.time_sheet_id, company_id: selected[i].ts.company_id, emp_id: selected[i].ts.emp_id, pay_period_id: selected[i].ts.pay_period_id, time_sheet_status: "APPROVED", submitted_on: (!!selected[i].ts.submitted_on ? selected[i].ts.submitted_on : new Date().toISOString()), approved_on: new Date().toISOString(), approved_by: props.user.emp_id })
                    if (empTimeSheetUpdate.data.code === 200)
                        setTimeSheetData(await getTimeSheetData());
                    updateCurrentTimeSheets();
                    selected[i].ts.time_sheet_status = "APPROVED";
                    successes++;
                }
                else ignored++;
                selected[i].ts = JSON.stringify(selected[i].ts);
            } else ignored++;
        }
        setAlertMessage(
            <div>
                <Typography>
                    {successes + " selection(s) successfully approved"}
                </Typography>
                <Typography>
                    {ignored + " invalid selection(s) ignored"}
                </Typography>
            </div>
        );
        setShowAlert(true);
    }

    async function unapproveTimeSheets() {
        let successes = 0, ignored = 0;
        for (let i = 0; i < selected.length; i++) {
            if (selected[i].ts !== null) {
                if (typeof (selected[i].ts) === 'string') {
                    selected[i].ts = JSON.parse(selected[i].ts);
                }
                if (selected[i].ts.time_sheet_status === "APPROVED") {
                    let empTimeSheetUpdate = await axios.put(APIURL + '/time_sheet', { time_sheet_id: selected[i].ts.time_sheet_id, company_id: selected[i].ts.company_id, emp_id: selected[i].ts.emp_id, pay_period_id: selected[i].ts.pay_period_id, time_sheet_status: "SUBMITTED", submitted_on: selected[i].ts.submitted_on, approved_on: "", approved_by: null })
                    if (empTimeSheetUpdate.data.code === 200)
                        setTimeSheetData(await getTimeSheetData());
                    updateCurrentTimeSheets();
                    selected[i].ts.time_sheet_status = "SUBMITTED";
                    successes++;
                } else ignored++;
                selected[i].ts = JSON.stringify(selected[i].ts);
            } else ignored++;
        }
        setAlertMessage(
            <div>
                <Typography>
                    {successes + " selection(s) successfully unapproved"}
                </Typography>
                <Typography>
                    {ignored + " invalid selection(s) ignored"}
                </Typography>
            </div>
        );
        setShowAlert(true);

    }

    async function lockTimeSheets() {
        let successes = 0, ignored = 0;
        for (let i = 0; i < selected.length; i++) {
            if (selected[i].ts !== null) {
                if (typeof (selected[i].ts) === 'string') {
                    selected[i].ts = JSON.parse(selected[i].ts);
                }
                if (selected[i].ts.time_sheet_status === "APPROVED") {
                    let empTimeSheetUpdate = await axios.put(APIURL + '/time_sheet', { time_sheet_id: selected[i].ts.time_sheet_id, company_id: selected[i].ts.company_id, emp_id: selected[i].ts.emp_id, pay_period_id: selected[i].ts.pay_period_id, time_sheet_status: "ARCHIVED", submitted_on: selected[i].ts.submitted_on, approved_on: selected[i].ts.approved_on, approved_by: selected[i].ts.approved_by })
                    if (empTimeSheetUpdate.data.code === 200)
                        setTimeSheetData(await getTimeSheetData());
                    updateCurrentTimeSheets();
                    selected[i].ts.time_sheet_status = "ARCHIVED";
                    successes++;
                } else ignored++;
                selected[i].ts = JSON.stringify(selected[i].ts);
            } else ignored++;
        }
        setAlertMessage(
            <div>
                <Typography>
                    {successes + " selection(s) successfully archived"}
                </Typography>
                <Typography>
                    {ignored + " invalid selection(s) ignored"}
                </Typography>
            </div>
        );
        setShowAlert(true);
    }

    async function unlockTimeSheets() {
        let successes = 0, ignored = 0;
        for (let i = 0; i < selected.length; i++) {
            if (selected[i].ts !== null) {
                if (typeof (selected[i].ts) === 'string') {
                    selected[i].ts = JSON.parse(selected[i].ts);
                }
                if (selected[i].ts.time_sheet_status === "ARCHIVED") {
                    let empTimeSheetUpdate = await axios.put(APIURL + '/time_sheet', { time_sheet_id: selected[i].ts.time_sheet_id, company_id: selected[i].ts.company_id, emp_id: selected[i].ts.emp_id, pay_period_id: selected[i].ts.pay_period_id, time_sheet_status: "APPROVED", submitted_on: selected[i].ts.submitted_on, approved_on: selected[i].ts.approved_on, approved_by: selected[i].ts.approved_by })
                    if (empTimeSheetUpdate.data.code === 200)
                        setTimeSheetData(await getTimeSheetData());
                    updateCurrentTimeSheets();
                    selected[i].ts.time_sheet_status = "APPROVED";
                    successes++;
                } else ignored++;
                selected[i].ts = JSON.stringify(selected[i].ts);
            } else ignored++;
        }
        setAlertMessage(
            <div>
                <Typography>
                    {successes + " selection(s) successfully removed from archive"}
                </Typography>
                <Typography>
                    {ignored + " invalid selection(s) ignored"}
                </Typography>
            </div>
        );
        setShowAlert(true);
    }
    async function selectionChange(ids) { //Gets all of the ids 
        let newSelectionArray = [];
        for (let i = 0; i < tableRows.length; i++) {
            if (ids.includes(tableRows[i].id)) {
                newSelectionArray.push(tableRows[i]);
            }
        }
        await setSelected(newSelectionArray);
    }

    // async function getReport() {
    //     axios.get("http://localhost:4000/api/fieldworker/jsreport/timesheet",
    //         {
    //             params: {
    //                 company: props.company,
    //                 payroll_data: { start_date: currentWeekDates[0], end_date: currentWeekDates[6] },
    //                 employees: [
    //                     tableRows[0],
    //                     tableRows[1],
    //                     tableRows[2],
    //                     tableRows[3],
    //                     tableRows[4]
    //                 ]
    //             },
    //             responseType: 'arraybuffer',
    //             headers: {
    //                 Accept: 'application/pdf',
    //             },
    //         }).then((response) => {
    //             const file = new Blob([response.data], { type: "application/pdf" });
    //             console.log(response.data);
    //             const fileURL = URL.createObjectURL(file);
    //             //Open the URL on new Window
    //             const pdfWindow = window.open();
    //             pdfWindow.location.href = fileURL;

    //         });
    // }
    return (
        <div id='timesheets-summarytable-main w-full h-96' >
            <div id="timesheets-summarytable-weeknavigation">
                <button id="previousweekbutton" onClick={() => previousWeek()}> Previous Week </button>
                <h2 id="current-week-display">{moment(currentWeekDates[0]).format("MMM[ ]DD")} - {moment(currentWeekDates[6]).format("MMM[ ]DD")}, {moment(currentWeekDates[0]).format("YYYY")}</h2>
                <button id="nextweekbutton" onClick={() => nextWeek()}> Next Week </button>
            </div>
            <div id="timesheets-summarytable-actions">

                {getPayrollStatus()}
                <DropdownButton id="actions-dropdown" title="Bulk Actions">
                    <Dropdown.Item onClick={() => approveTimeSheets()}>Approve </Dropdown.Item>
                    <Dropdown.Item onClick={() => lockTimeSheets()}>Lock</Dropdown.Item>
                    <Dropdown.Item onClick={() => unapproveTimeSheets()}>Unapprove</Dropdown.Item>
                    <Dropdown.Item onClick={() => unlockTimeSheets()}>Unlock</Dropdown.Item>
                </DropdownButton>
            </div>
            {/* <button className="nextweekbutton mx-2 my-2" onClick={() => { getReport() }}>Generate Report</button> */}

            {(!!tableRows !== []) &&
                <div style={{ height: '80vh', width: '100%' }} id="timesheets-summarytable-table">
                    <DataGrid
                        className={classes.root}
                        rows={tableRows}
                        columns={tableColumns}
                        showToolbar
                        components={{ Toolbar: GridToolbar, color: '#3fadf6' }}
                        checkboxSelection={true}
                        onSelectionModelChange={(ids) => {
                            selectionChange(ids);
                        }}

                        getCellClassName={(params) => {
                            if (params.field === 'status') {
                                if (params.value === "ACTIVE") {
                                    return 'active-cell';
                                }
                                else if (params.value === "SUBMITTED") {
                                    return 'submitted-cell';
                                }
                                else if (params.value === "APPROVED") {
                                    return 'approved-cell';
                                }
                                else if (params.value === "OPEN") {
                                    return 'open-cell';
                                }
                                else if (params.value === "ARCHIVED") {
                                    return 'archived-cell';
                                }
                            }
                        }
                        }
                    />
                </div>
            }
            <Snackbar message={alertMessage} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', width: 'fit-content' }} open={showAlert} autoHideDuration={6000} onClose={() => setShowAlert(false)}>
                <MuiAlert onClose={() => setShowAlert(false)} severity="success">
                    {alertMessage}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}