import * as React from "react";
import { useState } from "react";
import moment from "moment";
import { Button, Box } from '@mui/material';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import ControlledDataGrid from '../../components/DataGrid/ControlledDataGrid';

export default function SessionReport(props) {
    const [gridId, setGridId] = useState();
    const apiRef = useGridApiRef();

    let rows = props?.data.map((data, idx) => {
        let taskDuration = moment(data.tas_end_time).isValid() && moment(data.tas_start_time).isValid()
            ? (
                moment(data.tas_end_time).diff(
                    moment(data.tas_start_time),
                    "m"
                ) / 60
            ).toFixed(2)
            : 0
        let taskData = {
            id: idx,
            tas_id: data.tas_id,
            task_name: data.tas_title, 
            tas_date: moment(data.tas_start_time).format('MM/DD/YYYY'),
            task_type: data.tas_type,
            tas_time_spent: taskDuration,
            units_spent: data.unitsSpent
        };
        return taskData;
    });

    const columns = [
        { field: "id", headerName: "ID", hide: true },
        { field: "tas_id", headerName: "Task ID", hide: true },
        { field: "task_name", headerName: "Session Name", width: 250 },
        { field: "tas_date", headerName: "Session Date", width: 150 },
        { field: "task_type", headerName: "Session Task Type", width: 150 },
        { field: "tas_time_spent", headerName: "Session Duration", width: 150 },
        { field: "units_spent", headerName: "Units Spent", width: 150 },
    ]

    return (
        <div style={{ justifyContent: 'space-between' }}>
            {rows.length === 0 ?
                <div
                    className="d-flex flex-column justify-content-center align-items-center"
                    style={{ height: "500px", marginTop: 10 }}
                >
                    <img
                        src="./nosearchresult.png"
                        alt="nosearchresult"
                        style={{ width: "25rem", height: "15rem", margin: 10 }}
                    />
                    <h5
                        style={{ fontFamily: "Lato", marginTop: 48, marginBottom: 30 }}
                        className="text-center"
                    >
                        There is currently no data available{" "}
                    </h5>
                </div>
                :
                <div style={{ margin: "auto", height: '75vh', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '100%', height: "calc(100vh - 290px)" }}>
                        <DataGridPro
                            // gridId={gridId}
                            // user={props.user}
                            initialState={{ sorting: { sortModel: [{ field: "task_name", sort: "asc" }] } }}
                            // apiRef={apiRef}
                            getRowId={(row) => row.id}
                            rows={rows}
                            columns={columns}
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            checkboxSelection={false}
                            disableSelectionOnClick
                        />
                    </Box>
                </div>
            }
        </div>
    );
}