import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import FileFolderItem from '../FileFolderItem';
import InputAdornment from '@mui/material/InputAdornment';

const MoveTo = (props) => {
    const [fromFileDirectory, setFromFileDirectory] = useState(props.fromFileDirectory);
    const [toDirectory, setToDirectory] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [directories, setDirectories] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        let directories = props.rows.filter(row => row.file_name === null && row.locked !== true);
        directories.sort((a, b) => { return a.file_path[a.file_path.length - 1] > b.file_path[b.file_path.length - 1] ? 1 : -1; });
        setDirectories(directories);
    }, [props.rows]);
        
    const onSubmit = () => {
        if (fromFileDirectory === null) {
            setError(true);
        } else {
            setError(false);
            props.onMove(fromFileDirectory.file_name === null ? "folder" : "file", fromFileDirectory, toDirectory);
        }
    }

    return (
        <Box>
            <h4>Move To</h4>
            <br></br>
            <Box>
                <Autocomplete
                    value={fromFileDirectory}
                    onChange={(event, newValue) => {setFromFileDirectory(newValue);setError(false)}}
                    sx={{ marginBottom: '20px', width: '100%' }}
                    options={props.rows}
                    renderInput={(params) => <TextField
                        {...params} 
                        label="File/Folder" 
                        error={error}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: fromFileDirectory ?
                                <>
                                    {
                                        fromFileDirectory.file_name === null ? <InputAdornment position="start"><FolderIcon sx={{ color: "#007bff" }} /></InputAdornment>
                                        : <InputAdornment position="start"><InsertDriveFileOutlinedIcon /></InputAdornment>
                                    }
                                    {params.InputProps.startAdornment}
                                </> : null
                        }}
                    />}
                    getOptionLabel={(option) => option.file_path[option.file_path.length - 1]}
                    renderOption={(props, option) => <FileFolderItem listItemProps={props} row={option} />}
                />
                <List sx={{ height: "210px", overflow: "auto", border: "1px solid lightgray" }}>
                    {directories?.map((directory, index) => {
                        return (
                            <ListItemButton 
                                key={index} 
                                onClick={() => {setToDirectory(directory); setSelectedIndex(index)}} 
                                selected={selectedIndex === index}
                                sx={{ minHeight: "50px"}}
                             >
                                <ListItemIcon sx={{ minWidth: "40px" }}>
                                    <FolderIcon sx={{ color: "#007bff" }} />
                                </ListItemIcon>
                                <ListItemText 
                                    primary={directory.file_path[directory.file_path.length - 1]}
                                    secondary={directory.file_path.join("/")}
                                 />
                            </ListItemButton>
                        )
                    })}
                </List>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant="contained" sx={{ marginTop: "20px", marginRight: "10px" }} onClick={() => props.handleModalClose()} color="secondary">Cancel</Button>
                    <LoadingButton 
                        variant="contained" 
                        sx={{ marginTop: "20px" }} 
                        onClick={onSubmit} 
                        color="primary"
                        loading={props.loading}
                    >
                        <span>Move</span>
                    </LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}

export default MoveTo;