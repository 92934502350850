import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

const FileFolderItem = (props) => {

    return (
        <li {...props.listItemProps}>
            {props.row.file_name === null ? <FolderIcon sx={{ color: "#007bff" }} /> : <InsertDriveFileOutlinedIcon />}
            <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "15px" }}>
                <Typography sx={{ display: "inline-block" }}>{props.row.file_name === null ? props.row.directory_name : props.row.file_name + "." + props.row.file_ext}</Typography>
                <Typography variant="caption" sx={{ color : "gray" }}>{props.row.file_path?.join("/")}</Typography>
            </Box>
        </li>
    )

}

export default FileFolderItem;