import React, { Component } from 'react';
import { Form, Tabs, Tab, Alert, Button } from 'react-bootstrap';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import withStyles from '@mui/styles/withStyles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ccs from 'countrycitystatejson';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import OtherDetails from './OtherDetails/OtherDetails';
import MsgModalBox from '../../../container/MessageModalBox/MsgModalBox';
import axios from 'axios';
import { APIURL, BINGAPIKEY } from '../../../config'
import moment from 'moment'
import { Redirect } from 'react-router-dom';

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: '#3FA7D6',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#3FA7D6',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'lightblue',
            },
            '&:hover fieldset': {
                borderColor: '#3FA7D6',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#3FA7D6',
            },
        },
    },
})(TextField);

class LocationForm extends Component {
    state = {
        personal_details: {
            "address_line_1": "",
            "address_line_2": "",
            "city": "",
            "state": "",
            "country": "",
            "zip": "",
            "latitude": "",
            "longitude": ""
        },
        other_details: {
            "association_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
            "country_code": "+1",
            "phone": "",
            "location_status": "",
            "location_type": ""
        },
        location_details: {
            "address_line_1": "",
            "address_line_2": "",
            "city": "",
            "state": "",
            "country": "",
            "zip": "",
            "latitude": "",
            "longitude": "",
            "association_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
            "country_code": "+1",
            "phone": "",
            "location_status": "",
            "location_type": ""
        },
        key: "location_details",
        showModal: false,
        addError: false,
        personal_details_val: false,
        other_details_val: false,
        location_details_val: false,
        showAlert: false,
        addressSuggestions: []
    }

    async getlatlong(add) {
        if (add.address_line_1 !== ""
            || add.address_line_2 !== ""
            || add.city !== ""
            || add.state !== ""
            || add.country !== ""
            || add.zip !== "") {

            axios.defaults.headers = {
                'Content-Type': 'application/json',
            }

            //let geoCord = await axios.get(`https://dev.virtualearth.net/REST/v1/Locations?countryRegion=US&adminDistrict=${add.state}&locality=${add.city}&postalCode=${add.zip}&addressLine=${add.address_line_1}&key=${BINGAPIKEY}`);

            const instance = axios.create();
            instance.defaults.headers.common = {};
            instance.defaults.headers.common.accept = 'application/json';
            let geoCord = await instance.get(`https://dev.virtualearth.net/REST/v1/Locations?countryRegion=US&adminDistrict=${add.state}&locality=${add.city}&postalCode=${add.zip}&addressLine=${add.address_line_1}&key=${BINGAPIKEY}`);

            try {
                let latlongVal = geoCord.data.resourceSets[0].resources[0].point.coordinates;
                add.latitude = latlongVal[0]
                add.longitude = latlongVal[1]
            } catch (err) {
                console.log(err)
            }
        }
    }

    async addLocationtoDB(e) {
        axios.defaults.headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            "Access-Control-Allow-Headers": 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
            "ACCESS-CONTROL-ALLOW-CREDENTIAL": true,
            Authorization: 'Bearer ' + window.localStorage.getItem('Fw4_access_Token')
        }
        e.preventDefault()
        e.persist()
        const newState = {
            personal_details: {
                "address_line_1": "",
                "address_line_2": "",
                "city": "",
                "state": "",
                "country": "",
                "zip": "",
                "latitude": "",
                "longitude": ""
            },
            other_details: {
                "association_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
                "country_code": "+1",
                "phone": "",
                "location_status": "",
                "location_type": ""
            },
            location_details: {
                "address_line_1": "",
                "address_line_2": "",
                "city": "",
                "state": "",
                "country": "",
                "zip": "",
                "latitude": "",
                "longitude": "",
                "association_date": moment().format('YYYY-MM-DDTHH:mm:ss'),
                "country_code": "+1",
                "phone": "",
                "location_status": "",
                "location_type": ""
            },
        }
        var data = {
            company_id: this.props.comp_id,
            address_line_1: this.state.location_details.address_line_1,
            address_line_2: this.state.location_details.address_line_2,
            city: this.state.location_details.city,
            state: this.state.location_details.state,
            country: this.state.location_details.country,
            zip: this.state.location_details.zip,
            latitude: this.state.location_details.latitude,
            longitude: this.state.location_details.longitude,
            association_date: this.state.location_details.association_date,
            phone: this.state.location_details.country_code + this.state.location_details.phone,
            location_status: this.state.location_details.location_status,
            location_type: this.state.location_details.location_type
        };

        let msg = await axios.post(APIURL + "/location", data);
        if (msg.data.code === 200) {
            // debugger;
            e.target.reset();
            // this.setState({ personal_details: newState.personal_details })
            // this.setState({ other_details: newState.other_details })
            this.setState({ location_details: newState.location_details })
            this.setState({ showModal: true })
            this.setState({ addError: false })
        } else {
            // debugger
            // this.setState({ personal_details: this.state.personal_details })
            // this.setState({ other_details: this.state.other_details })
            this.setState({ location_details: this.state.location_details })
            this.setState({ addError: true })
            this.setState({ showModal: true })
        }
    }

    goHome = (e) => {
        this.setState({ addError: false })
        this.setState({ showModal: false })
        this.setState({ showAlert: false })
        // this.setState({ personal_details_val: false })
        // this.setState({ other_details_val: false })
        this.setState({ location_details_val: false })
        this.setState({ goHome: true })
    }

    addNew = (e) => {
        if (this.state.addError) {
            this.setState({ showModal: false })
            this.setState({ showAlert: false })
        } else {
            this.setState({ showModal: false })
            this.setState({ showAlert: false })
            this.setState({ addError: false })
            // this.setState({ personal_details_val: false })
            // this.setState({ other_details_val: false })
            this.setState({ location_details_val: false })
            this.setState({ key: "location_details" })
        }
    }

    onLocationFormChangeHandler = (event) => {
        if (event.target.id === 'address_maps') {
            let oldState = { ...this.state.location_details }

            oldState.address_line_1 = event.value.result.address_components.find(component => component.types.includes("street_number")).long_name + " " + event.value.result.address_components.find(component => component.types.includes("route")).long_name
            oldState.city = event.value.result.address_components.find(component => component.types.includes("locality")).long_name
            oldState.state = event.value.result.address_components.find(component => component.types.includes("administrative_area_level_1")).short_name
            oldState.zip = event.value.result.address_components.find(component => component.types.includes("postal_code")).long_name
            oldState.country = event.value.result.address_components.find(component => component.types.includes("country")).long_name

            oldState.latitude = event.value.result.geometry.location.lat
            oldState.longitude = event.value.result.geometry.location.lng

            this.setState({
                location_details: oldState
            })

            return;
        }

        let oldState = { ...this.state.location_details, [event.target.id]: event.target.id === 'state' ? event.target.value.toUpperCase() : event.target.value }
        this.getlatlong(oldState)
        this.setState({
            location_details: oldState
        })
    }

    onPrimaryFormChangeHandler = (event) => {
        if (event.target.id === 'address_maps') {
            let oldState = { ...this.state.personal_details }

            oldState.address_line_1 = event.value.result.address_components.find(component => component.types.includes("street_number")).long_name + " " + event.value.result.address_components.find(component => component.types.includes("route")).long_name
            oldState.city = event.value.result.address_components.find(component => component.types.includes("locality")).long_name
            oldState.state = event.value.result.address_components.find(component => component.types.includes("administrative_area_level_1")).short_name
            oldState.zip = event.value.result.address_components.find(component => component.types.includes("postal_code")).long_name
            oldState.country = event.value.result.address_components.find(component => component.types.includes("country")).long_name

            oldState.latitude = event.value.result.geometry.location.lat
            oldState.longitude = event.value.result.geometry.location.lng

            this.setState({
                personal_details: oldState
            })

            return;
        }

        let oldState = { ...this.state.personal_details, [event.target.id]: event.target.id === 'state' ? event.target.value.toUpperCase() : event.target.value }
        this.getlatlong(oldState)
        this.setState({
            personal_details: oldState
        })
    }

    onOtherDetailsFormChangeHandler = (event) => {
        let oldState = { ...this.state.other_details, [event.target.id]: event.target.value }
        this.setState({
            other_details: oldState
        })
    }

    changeTab = (key) => {
        const conversion = {
            address_line_1: 'Address line 1',
            city: 'City',
            state: 'State',
            zip: 'Zip code',
            country: 'county',
            latitude: 'Latitude',
            longitude: 'Longitude',
            association_date: 'Association date',
            phone: "Phone",
            location_status: 'Location status',
            location_type: 'Location type'


        }
        const preKey = this.state.key;
        var validation = this.props.isFormValidated(this.state[preKey])

        if (!(validation.isValidated)) {
            if (preKey === "location_details") {
                this.setState({ location_details_val: false })
                this.setState({ errorMsg: "Please enter " + conversion[validation.missingKey] })
            } else if (preKey === "personal_details") {
                this.setState({ personal_details_val: false })
                this.setState({ errorMsg: "Please enter " + conversion[validation.missingKey] })
            } else {
                this.setState({ other_details_val: false })
                this.setState({ errorMsg: "Please enter " + conversion[validation.missingKey] })
            }
            this.setState({ showAlert: true })
            this.setState({ key: preKey })
            return false
        } else {
            if (preKey === "location_details") {
                this.setState({ location_details_val: true })
            } else if (preKey === "personal_details") {
                this.setState({ personal_details_val: true })
            } else {
                this.setState({ other_details_val: true })
            }
            this.setState({ showAlert: false })
            this.setState({ key })
            return true
        }
    }

    async handleAddressSuggestions(value) {
        let newAddressSuggestions = await axios.get(APIURL + '/maps', { params: { input: value } })
        this.setState({ addressSuggestions: newAddressSuggestions.data.predictions });
    }

    async handleAddressSelect(value) {
        let newAddressDetails = await axios.get(APIURL + '/maps/details', { params: { place_id: value.place_id } })
        this.onLocationFormChangeHandler({
            target: {
                id: 'address_maps'
            },
            value: newAddressDetails.data
        })
    }

    render() {
        let locType = this.props.locType.map((type, indx) => { return { value: type, title: type } });
        let locStatus = this.props.locStatus.map((status, indx) => { return { value: status, title: status } });
        let code = ccs.getCountryByShort('US');

        return (
            <div style={{ textAlign: "left", marginLeft: "10px" }}>
                {(this.state.showModal) ? (<MsgModalBox addNew={this.addNew} goHome={this.goHome} text="Location" showError={this.state.addError} style={{ position: "absolute" }} />) : (
                    <Form onSubmit={(e) => {
                        if (this.changeTab('location_details')) { this.addLocationtoDB(e) }
                        else { e.preventDefault() }
                    }} >
                        <h4>New Location</h4>
                        {this.state.showAlert &&
                            <Alert variant="info">
                                {!this.state.errorMsg ? 'Please Fill out missing form fields' : this.state.errorMsg}
                            </Alert>}
                        <div>
                            <Form.Row>
                                <Autocomplete
                                    autoSelect
                                    selectOnFocus
                                    disablePortal
                                    id="address_maps"
                                    sx={{ width: 440 }}
                                    popupIcon={null}
                                    options={this.state.addressSuggestions}
                                    getOptionLabel={(option) => option.description}
                                    onChange={(event, value, reason) => {
                                        if (reason === 'selectOption') {
                                            this.handleAddressSelect(value)
                                        }
                                    }}
                                    onInputChange={(event, value, reason) => {
                                        if (reason === 'input') {
                                            this.handleAddressSuggestions(value)
                                        }
                                    }}
                                    renderInput={(params) => <CssTextField {...params} className={`mx-1 my-3 w-100`} label="ADDRESS *" variant="outlined" id="address_maps_TextField" />}
                                />
                            </Form.Row>
                        </div >
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid container justifyContent="flex-start">
                                    <DatePicker
                                        className={'mr-2 w-75'}
                                        id="association_date"
                                        label="ASSOCIATION DATE"
                                        value={moment(this.state.location_details.association_date).format('YYYY-MM-DDTHH:mm:ss')}
                                        onChange={(d) => { this.onLocationFormChangeHandler({ target: { value: moment(d).format('YYYY-MM-DDTHH:mm:ss'), id: 'association_date' } }) }}
                                        renderInput={(props) => (
                                            <CssTextField
                                                {...props}
                                                className='locationform-otherdetails-datepicker'
                                                style={{ width: 440 }}
                                            />
                                        )}
                                    />
                                </Grid>
                            </LocalizationProvider>
                            <Form.Row>
                                <CssTextField
                                    style={{ width: '30%' }}
                                    className={`mx-1 my-3`}
                                    label="COUNTRY CODE"
                                    value={`${code.emoji} ${code.name}(${code.phone})`}
                                    variant="outlined"
                                    required
                                    id="country_code"
                                />
                                <CssTextField
                                    style={{ width: 257 }}
                                    className={`mx-1 my-3`}
                                    label="PHONE *"
                                    value={this.state.location_details.phone}
                                    onChange={this.onLocationFormChangeHandler}
                                    variant="outlined"
                                    id="phone"
                                />
                            </Form.Row>
                            <Form.Row>
                                <Autocomplete
                                    id="location_status"
                                    value={locStatus.find((type) => type.title === this.state.location_details.location_status) || null}
                                    className={`mx-1 my-3 w-75`}
                                    onChange={(e,newValue) => {
                                        this.onLocationFormChangeHandler({target:{id:'location_status',value:newValue.title}})
                                    }}
                                    options={locStatus}
                                    getOptionLabel={(option) => option.title}
                                    renderInput={(params) => <CssTextField {...params} label="LOCATION STATUS *" variant="outlined" />}
                                />
                            </Form.Row>
                            <Form.Row>
                                <Autocomplete
                                    id="location_type"
                                    value={locType.find((type) => type.title === this.state.location_details.location_type) || null}
                                    className={`mx-1 my-3 w-75`}
                                    onChange={(e,newValue) => {
                                        this.onLocationFormChangeHandler({target:{id:'location_type',value:newValue.title}})
                                    }}
                                    options={locType}
                                    getOptionLabel={(option) => option.title}
                                    renderInput={(params) => <CssTextField {...params} label="LOCATION TYPE *" variant="outlined" />}
                                />
                            </Form.Row>
                            <Button className='w-75 ml-3' block type="submit">Create Location</Button>
                        </div>
                        {/* <Tabs activeKey={this.state.key} id="uncontrolled-tab-example" onSelect={(key) => this.changeTab(key)}>
                            <Tab eventKey="personal_details" title={(this.state.personal_details_val) ?
                                (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Personal Details</div></div>) :
                                (<div style={{ margin: "-1px" }}>Personal Details</div>)}>
                                <PersonalDetails
                                    states={this.props.states}
                                    formVals={this.state.personal_details}
                                    formChangeHandler={this.onPrimaryFormChangeHandler}
                                    onContinue={this.changeTab} />
                            </Tab>
                            <Tab eventKey="other_details" title={(this.state.other_details_val) ?
                                (<div className="d-flex flex-column"><i style={{ fontSize: "0.7rem" }} className="fas fa-check text-center"></i><div style={{ margin: "-2px", fontSize: "0.9rem" }}>Other Details</div></div>) :
                                (<div style={{ margin: "-1px" }}>Other Details</div>)}>
                                <OtherDetails
                                    locStatus={this.props.locStatus}
                                    locType={this.props.locType}
                                    // onContinue = {this.changeTab}
                                    formVals={this.state.other_details}
                                    formChangeHandler={this.onOtherDetailsFormChangeHandler} />
                            </Tab>
                        </Tabs> */}
                    </Form>
                )}
                {this.state.goHome &&
                    <Redirect to="/" />
                }
            </div>
        )
    }
};

export default LocationForm;